import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUserById } from '@redux/dashboardUsers/reducers';
import { usePageActions } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import DashboardUserTypeCard from '@cards/DashboardUsers/UserType';
import DashboardUserDetailsCard from '@cards/DashboardUsers/Details';
import DashboardUserAccessCard from '@cards/DashboardUsers/Access';
import IconButton from '@components/IconButton';
import { matchType } from '../../../propTypes';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import {
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
} from '@constants';

function DashboardUserDetails({ history, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, dashboardUser] = useSelector(selectDashboardUserById(match.params.id));

  const permissions = useHasPermissions();
  const userTypes = {
    [SUPER_ADMIN]: Permissions.ManageSuperAdmin,
    [SUPPORT_ADMIN]: Permissions.ManageSupportAdmin,
    [SYSTEM_ADMIN]: Permissions.ManageSystemAdmin,
    [HOSPITAL_ADMIN]: Permissions.ManageHospitalAdmin,
    [HOSPITAL_USER]: Permissions.ManageHospitalUser,
  };
  const requiredPermission = userTypes[dashboardUser?.userType];

  const { setActions, setTitle } = usePageActions({
    showBack: true,
    actions: [],
    title: dashboardUser?.name || '',
  });

  useEffect(() => {
    const actions =
      requiredPermission && permissions[requiredPermission]
        ? [
            <IconButton
              icon={faEdit}
              key="edit-dashboard-user"
              tooltip={t('common:buttons.edit')}
              onClick={() => history.push(`${match.url}/edit`)}
            />,
          ]
        : [];
    setActions(actions);
    setTitle(dashboardUser?.name);
  }, [dashboardUser, history, match, setActions, setTitle, t, requiredPermission, permissions]);

  useEffect(() => {
    dispatch(fetchDashboardUsers());
  }, [dispatch]);

  return (
    <ColumnGroup>
      <Column>
        <DashboardUserTypeCard dashboardUserId={match.params.id} />
        <DashboardUserDetailsCard dashboardUserId={match.params.id} />
      </Column>
      <Column>
        <DashboardUserAccessCard dashboardUserId={match.params.id} />
      </Column>
    </ColumnGroup>
  );
}

DashboardUserDetails.propTypes = {
  match: matchType.isRequired,
};

export default DashboardUserDetails;
