import { put, call, takeLatest, getContext, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import i18next from '../../i18n';
import createPipClient, { PIPObject } from '@api/pipClient';
import { selectForm } from '@redux/forms/reducers';
import { doFetchAppUsers } from '@redux/appUsers/sagas/fetch';
import { selectAppUser } from '@redux/appUsers/reducers';
import { selectAppDetails } from '@organisation/redux/selectors';
import {
  loadForms,
  formsLoaded,
  IDownloadForm,
  IDownloadFormData,
  IDownloadFormDataForAppUser,
  ICreateForm,
  formCreated,
  formUpdated,
  IUpdateForm,
  IDeleteForms,
  formsDeleted,
  IPublishForm,
  publishFormSuccess,
  publishFormFailed,
  IUnpublishForm,
  unpublishFormSuccess,
  unpublishFormFailed,
  fetchVersionFormDataSuccess,
  IFetchVersionFormData,
} from './actions';
import { IRawForm } from './types';
import takeFirst from '../takeFirst';
import { getBaseObjectTypeForForm, parseFormComputations, parseRawForm } from './utils';
import { appUserFormComputationsLoaded } from '@redux/appUsers/actions';
import { IRawFormComputation } from '@redux/appUsers/types';
import { selectOrganisationSlug } from '@organisation/redux/selectors';
import { trackDataEvent } from '../../analytics';
import { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import { dataLakeFormDataDownloadURL, dataLakeFormDataDownloadForAppUserURL } from 'settings';
import ContentRef from '@utils/contentRef';

export default function* root() {
  yield takeFirst('forms/fetch', doFetchForms);
  yield takeLatest('forms/fetch-version', doFetchVersionFormData);
  yield takeLatest('forms/download', doDownloadFile);
  yield takeLatest('forms/datadownload', doDownloadFormData);
  yield takeLatest('forms/appuserdatadownload', doDownloadFormDataForAppUser);
  yield takeLatest('forms/create', doCreateForm);
  yield takeLatest('forms/update', doUpdateForm);
  yield takeLatest('forms/delete', doDeleteForms);
  yield takeLatest('forms/publish', doPublishForm);
  yield takeLatest('forms/unpublish', doUnpublishForm);
}

function* doCreateFormsClient(): any {
  try {
    const ubiquity = yield call(doCreateUbiquityV2Client);
    const { appToken } = yield select(selectAppDetails);
    return ubiquity.forms(appToken);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function* doFetchForms(): any {
  yield put(loadForms());
  const organisationId = yield select(selectOrganisationSlug());

  try {
    const client = yield call(doCreateFormsClient);
    const pagedClient = client.pagination().begin(client.list());
    let results: IRawForm[] = [];

    while (pagedClient.hasNext()) {
      const nextData = yield call(pagedClient.next);

      results = [...results, ...nextData];
    }

    yield put(formsLoaded(results.map(form => parseRawForm(form, organisationId))));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18next.t('forms:failedLoad'));
  }
}

function* doFetchVersionFormData({
  payload: { formId, dataVersion, schemaVersion, appUserUISId },
}: IFetchVersionFormData): any {
  try {
    let [, appUser] = yield select(selectAppUser(appUserUISId));
    let [, form] = yield select(selectForm(formId));
    if (!appUser) {
      yield call(doFetchAppUsers);
      [, appUser] = yield select(selectAppUser(appUserUISId));
    }

    if (!form) {
      yield take('core/load-content-success');
      [, form] = yield select(selectForm(formId));
    }

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

    if (appUserResponse.results.length === 0) {
      yield call(message.warning, 'User could not be found');
    }

    const pipAppUser = appUserResponse.results[0];
    const objectType = yield call(getBaseObjectTypeForForm, formId);

    // fetch form schema version
    const formsClient = yield call(doCreateFormsClient);
    const version = yield call(formsClient.getVersion, form, schemaVersion);

    // fetch form data
    const { results: dataResult }: { results: PIPObject<any>[] } = yield call(
      pipClient.getObjectsForType,
      `${objectType}-data`,
      dataVersion.toString(),
      pipAppUser.uuid,
    );

    // store successfully
    const versionFormData = {
      formData: dataResult[0].json,
      schema: version.schema,
      uiSchema: version.ui_schema,
      ...(Object.keys(version.computations).length ? { computations: version.computations } : {}),
    };

    let {
      next,
      results: computations,
    }: { next: string; results: PIPObject<IRawFormComputation>[] } = yield call(
      pipClient.getObjectsForType,
      `${objectType}-computations`,
      null,
      pipAppUser.uuid,
    );

    while (next) {
      let response = yield call(fetch, next, {
        headers: pipClient.headers(),
      });
      const { next: nextUrl, results } = yield call(response.json.bind(response));

      next = nextUrl;
      computations = [...computations, ...results];
    }

    yield put(appUserFormComputationsLoaded(appUserUISId, parseFormComputations(computations)));

    yield put(fetchVersionFormDataSuccess(formId, dataVersion, versionFormData));
  } catch (err) {
    console.error(err);
  }
}

function* doDownloadFile({ payload: { fileName, formData } }: IDownloadForm) {
  const fileData = JSON.stringify(formData);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();

  yield call(message.success, i18next.t('forms:fileDownloaded'));
}

function* doDownloadFormData({ payload: { formId } }: IDownloadFormData) {
  try {
    const url = dataLakeFormDataDownloadURL.replace('FORM_ID', formId);
    const tokenPool = yield getContext('tokens');
    const token = yield call(tokenPool.get, 'data-lake');
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const resp = yield call(fetch, url, options);
    const data = yield call(resp.json.bind(resp));
    if (data.success) {
      const verifyFileResp = yield fetch(data.url, { method: 'GET' });
      if (!verifyFileResp.ok) {
        throw verifyFileResp;
      }

      const link = document.createElement('a');
      link.href = data.url;
      link.click();
    }
    yield call(message.success, i18next.t('forms:fileDownloaded'));
  } catch (e) {
    yield call(message.error, i18next.t('forms:fileDownloadFailed'), 15);
  }
}

function* doDownloadFormDataForAppUser({
  payload: { formId, appUserId },
}: IDownloadFormDataForAppUser) {
  try {
    const url = dataLakeFormDataDownloadForAppUserURL
      .replace('FORM_ID', formId)
      .replace('APP_USER_ID', appUserId);
    const tokenPool = yield getContext('tokens');
    const token = yield call(tokenPool.get, 'data-lake');
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const resp = yield call(fetch, url, options);
    const data = yield call(resp.json.bind(resp));
    if (data.success) {
      const verifyFileResp = yield fetch(data.url, { method: 'GET' });
      if (!verifyFileResp.ok) {
        throw verifyFileResp;
      }

      const link = document.createElement('a');
      link.href = data.url;
      link.click();
    }
    yield call(message.success, i18next.t('forms:fileDownloaded'));
  } catch {
    yield call(message.error, i18next.t('forms:fileDownloadFailed'), 15);
  }
}

function* doCreateForm({
  payload: {
    formTranslationKey,
    name,
    description,
    language,
    published,
    formData,
    type,
    path,
    fileName,
    audience,
  },
}: ICreateForm): any {
  try {
    const history = yield getContext('history');
    const client = yield call(doCreateFormsClient);

    const form = yield call(client.create, {
      name,
      description,
      metadata: { audience },
      audience_type: audience,
    });

    const version = yield call(client.createVersion, form, {
      ...(formData.computations ? { computations: formData.computations } : {}),
      schema: formData.schema,
      ui_schema: formData.uiSchema,
      metadata: {
        fileName,
        type,
        language,
      },
    });

    yield call(client.packageVersion, version, form);

    if (published) {
      yield call(client.makeAvailable, form);
    }

    const orgId = yield select(selectOrganisationSlug());
    const rawForm = yield call(client.get, form.uuid);
    const newForm = parseRawForm(rawForm, orgId);

    // create PIP Object Type to store App User Submissions against
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const { appToken } = yield select(selectAppDetails);
    const objectType = `${appToken}-form-${newForm.uuid}-data`;
    yield call(pipClient.createObjectType, objectType, appToken);

    yield put(formCreated(newForm));

    yield call(
      history.replace,
      ContentRef.fromString(newForm.content).toDashboardUrl(newForm.metadata.type),
    );

    trackDataEvent('Form Created', {
      formId: newForm.uuid,
      formName: newForm.name,
      formType: newForm.metadata.type,
    });
    yield call(message.success, i18next.t(`${formTranslationKey}:NewForm.successMessage`));
  } catch (err) {
    console.error(err);
  }
}

function* doUpdateForm({
  payload: {
    formTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    formData,
    type,
    fileName,
    audience,
  },
}: IUpdateForm): any {
  try {
    const client = yield call(doCreateFormsClient);
    const [, form] = yield select(selectForm(uuid));

    const rawForm = yield call(client.edit, form, { name, description, metadata: { audience } });

    const version = yield call(client.createVersion, rawForm, {
      ...(formData.computations ? { computations: formData.computations } : {}),
      schema: formData.schema,
      ui_schema: formData.uiSchema,
      metadata: {
        fileName,
        type,
        language,
      },
    });

    yield call(client.packageVersion, version, form);

    if (published) {
      yield call(client.makeAvailable, form);
    }

    const orgId = yield select(selectOrganisationSlug());
    const rawUpdatedForm = yield call(client.get, form.uuid);
    const updatedForm = parseRawForm(rawUpdatedForm, orgId);

    trackDataEvent('Form Updated', {
      formId: uuid,
      formName: name,
      formType: type,
    });

    yield put(formUpdated(updatedForm));

    const history = yield getContext('history');
    yield call(history.goBack);
    yield call(message.success, i18next.t(`${formTranslationKey}:EditForm.successMessage`));
  } catch (err) {
    console.error(err);
  }
}

function* doDeleteForms({ payload: { ids, formTranslationKey } }: IDeleteForms): any {
  try {
    const client = yield call(doCreateFormsClient);

    for (const formId of ids) {
      const [, form] = yield select(selectForm(formId));

      yield call(client.delete, form);
    }

    yield put(formsDeleted(ids));

    yield call(
      message.success,
      i18next.t(`${formTranslationKey}:DeleteForm.deleteSuccess`, { count: ids.length }),
    );
  } catch (err) {
    console.error(err);
  }
}

function* doPublishForm({ payload: { formId, formTranslationKey } }: IPublishForm): any {
  try {
    const [, form] = yield select(selectForm(formId));
    const client = yield call(doCreateFormsClient);

    yield call(client.makeAvailable, form);

    yield call(message.success, i18next.t(`${formTranslationKey}:FormDetail.publishSuccess`));
    yield put(publishFormSuccess(formId));
  } catch (err) {
    console.error(err);
    yield put(publishFormFailed());
    yield call(message.warning, i18next.t(`${formTranslationKey}:FormDetail.publishFailed`));
  }
}

function* doUnpublishForm({ payload: { formId, formTranslationKey } }: IUnpublishForm): any {
  try {
    const [, form] = yield select(selectForm(formId));
    const client = yield call(doCreateFormsClient);

    yield call(client.makeUnavailable, form);

    yield put(unpublishFormSuccess(formId));

    yield call(message.success, i18next.t(`${formTranslationKey}:FormDetail.unpublishSuccess`));
  } catch (err) {
    console.error(err);
    yield put(unpublishFormFailed());
    yield call(message.warning, i18next.t(`${formTranslationKey}:FormDetail.unpublishFailed`));
  }
}
