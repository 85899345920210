import React from 'react';
import { ColumnGroup, Column } from '@cards/Card';
import MessageDetailsCard from './DetailsCard';

import './styles.less';

const MessageDetailStep = ({ messageTranslationKey }) => {
  return (
    <ColumnGroup>
      <Column>
        <MessageDetailsCard messageTranslationKey={messageTranslationKey} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default MessageDetailStep;
