import { call, getContext, put } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../../i18n';
import { deleteRulesSuccess, IDeleteRule, IDeleteRules } from '../actions';
import doCreatePathwaysClient from '../../../../redux/doCreatePathwaysClient';

function* doDeletionOfRules(ruleIds: number[]) {
  const pathwaysClient = yield call(doCreatePathwaysClient);

  for (const ruleId of ruleIds) {
    yield call(pathwaysClient.deleteRule, ruleId);
  }
}

export function* doDeleteRule({ payload: { ruleId } }: IDeleteRule) {
  try {
    const history = yield getContext('history');

    yield call(doDeletionOfRules, [ruleId]);

    yield put(deleteRulesSuccess([ruleId]));
    yield call(history.replace, '/procedure/rules');
    yield call(message.success, i18n.t('pathways:ProcedureRules.deleteSuccess'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedureRules.deleteError'));
  }
}

export function* doDeleteRules({ payload: { ruleIds } }: IDeleteRules) {
  try {
    yield call(doDeletionOfRules, ruleIds);

    yield put(deleteRulesSuccess(ruleIds));
    yield call(message.success, i18n.t('pathways:ProcedureRules.deleteSuccessPlural'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedureRules.deleteErrorPlural'));
  }
}
