import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAppUserFiles,
  getAppUserFileDownloadUrl,
  clearAppUserFileDownloadUrl,
} from '@redux/appUsers/actions';
import { selectAppUserFiles, selectAppUserFileDownloadUrl } from '@redux/appUsers/reducers';
import createColumns from './createColumns';
import { useTranslation } from 'react-i18next';
import IconButton from '@components/IconButton';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { historyType, matchType } from '../../../propTypes';
import { setCurrentScreen, screens } from '../../../analytics';
import SearchableTable from '@components/SearchableTable';
import { Route, Switch } from 'react-router-dom';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserFile from './AppUserFile';
import '../style.less';

const filterFunc = (value, comparator) => comparator(value.name) || comparator(value.description);

function AppUserFilesTab({ history, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleViewFile = useCallback(
    file => {
      dispatch(getAppUserFileDownloadUrl(match.params.id, file));
    },
    [dispatch, match.params.id],
  );

  const { url, loading: downloadUrlLoading } = useSelector(
    selectAppUserFileDownloadUrl(match.params.id),
  );

  const columns = useMemo(() => createColumns(t, handleViewFile, downloadUrlLoading), [
    t,
    handleViewFile,
    downloadUrlLoading,
  ]);

  const { files, loading } = useSelector(selectAppUserFiles(match.params.id));

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_FILES);
  }, []);

  useEffect(() => {
    dispatch(fetchAppUserFiles(match.params.id));
  }, [dispatch, match.params.id]);

  const rowClassName = file => {
    if (file.is_deleted || file.shares[0]?.is_deleted) {
      return 'deleted';
    }
    return '';
  };

  useEffect(() => {
    if (url) {
      window.open(url, '_blank');
      dispatch(clearAppUserFileDownloadUrl(match.params.id));
    }
  }, [url, downloadUrlLoading, dispatch, match.params.id]);

  return (
    <Switch>
      <Route path={`${match.path}/:filePk`} render={() => <AppUserFile />} />
      <Route
        path={match.url}
        render={() => (
          <ColumnGroup className="app-user-files">
            <Column>
              <SearchableTable
                columns={columns}
                dataSource={files}
                filterFunc={filterFunc}
                loading={loading}
                onRowClick={({ uuid }) =>
                  history.push(`/patients/individuals/${match.params.id}/files/${uuid}`)
                }
                extra={
                  <>
                    <IconButton
                      key="add-file-button"
                      tooltip={t('common:buttons.add')}
                      icon={faPlus}
                      onClick={() => history.push(`${match.url}/add`)}
                      tooltipPlacement="right"
                    />
                  </>
                }
                rowClassName={rowClassName}
                placeholder={t('patients:Files.searchPlaceholder')}
              />
            </Column>
          </ColumnGroup>
        )}
      />
    </Switch>
  );
}

AppUserFilesTab.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default AppUserFilesTab;
