import { IFormSubmissionRaw, IFormSubmission } from './../types';
import { put, call, getContext, select, take } from 'redux-saga/effects';
import { message } from 'antd';
// import { getGlobalFormsObjectType } from '@redux/forms/utils';
import { IForm, IRawForm } from '@redux/forms/types';
import { loadForms, temporaryFormsLoaded } from '@redux/forms/actions';
import { selectAppDetails } from '@organisation/redux/selectors';
import i18n from '../../../i18n';
import {
  fetchAppUsers,
  fetchAppUsersFailed,
  loadAppUsers,
  appUsersFormsLoaded,
  appUsersLoaded,
  IFetchAppUsersForms,
  loadAppUserForms,
  // IFetchFormsForAppUserResults,
  fetchAppUserEngagementsSuccess,
  fetchAppUserEngagementsFailed,
  IFetchAppUserEngagements,
  IFetchAppUserDailySteps,
  fetchAppUserDailyStepsSuccess,
  fetchAppUserDailyStepsFailed,
  IFetchAppUserExternalFormSubmissionForms,
} from '../actions';
import { selectAppUser } from '../reducers';
import { IUISUser, APP_USERS_LOADED } from '@redux/appUsers/types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import { mapUISUserToAppUser } from '@redux/appUsers/utils';
import createPipClient, { PIPObject } from '@api/pipClient';
import { trackDataEvent, timeEvent } from '../../../analytics';
import { selectAppToken } from '@redux/appTokens/reducers';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import { saveAppToken } from '@redux/appTokens/actions';
import { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import { parseRawForm } from '@redux/forms/utils';

const BATCH_SIZE = 8;
const PAGE_SIZE = 50;

export function* doFetchAppUsers(): any {
  trackDataEvent('App Users Loading');
  timeEvent('App Users Loaded', 'Data' as any);
  timeEvent('App Users Loading Failure', 'Data' as any);
  yield put(loadAppUsers());

  try {
    const client = yield call(doCreateUISAdminClient);
    const appUsers = [];
    let page = 1;

    // temporary batching logic to improve list load speed
    // fetch first page
    const {
      count,
      next: moreThanOnePage,
      results,
    }: { count: number; next: string; results: IUISUser[] } = yield call(
      client.listAppUsersForApp,
      page,
    );
    appUsers.push(...results.map(uisUser => mapUISUserToAppUser(uisUser)));
    // page += 1;

    // if more than 1 page, break pages up in to batches of BATCH_SIZE
    if (moreThanOnePage) {
      const numberOfPages = Math.ceil(count / PAGE_SIZE);
      const pageBatches: number[][] = [];

      for (let i = page; i <= numberOfPages; i += BATCH_SIZE) {
        pageBatches.push([]);
        for (let j = 1; j < BATCH_SIZE + 1; j++) {
          if (i + j <= numberOfPages) {
            const pageBatchesIndex = i > BATCH_SIZE ? Math.floor(i / BATCH_SIZE) : 0;
            pageBatches[pageBatchesIndex] = [...pageBatches[pageBatchesIndex], i + j];
          }
        }
      }

      // iterate over batches of pages to fetch all pages
      for (const batch of pageBatches) {
        const fetchedPages = yield call(
          Promise.all.bind(Promise),
          batch.map(pageNumber => client.listAppUsersForApp(pageNumber)),
        );

        appUsers.push(
          ...fetchedPages.flatMap(
            ({ results }: { count: number; next: string; results: IUISUser[] }) =>
              results.map(uisUser => mapUISUserToAppUser(uisUser)),
          ),
        );
      }
    }
    // end temp batching logic

    // while (true) {
    //   const { next, results }: { next: string; results: IUISUser[] } = yield call(
    //     client.listAppUsersForApp,
    //     page,
    //   );
    //   appUsers.push(...results.map(uisUser => mapUISUserToAppUser(uisUser)));
    //   if (!next) break;
    //   page += 1;
    // }

    trackDataEvent('App Users Loaded');
    yield put(
      appUsersLoaded(
        appUsers.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()),
      ),
    );
  } catch (err) {
    console.error(err);
    trackDataEvent('App Users Loading Failure');
    yield put(fetchAppUsersFailed());
    yield call(message.error, i18n.t('cards:PatientList.networkError'));
  }
}

export function* doFetchAppUserForms({ payload: { appUserUISId } }: IFetchAppUsersForms): any {
  yield put(loadAppUserForms());

  let [, appUser] = yield select(selectAppUser(appUserUISId));

  if (!appUser) {
    yield put(fetchAppUsers());

    while (true) {
      const {
        payload: { appUsers },
      } = yield take(APP_USERS_LOADED);
      if (appUsers) {
        [, appUser] = yield select(selectAppUser(appUserUISId));
        break;
      }
    }
  }

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const { appToken } = yield select(selectAppDetails);

  let appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

  let pipAppUser = null;
  if (appUserResponse.results.length === 0) {
    console.info('User could not be found in PIP, attempting to create it');
    try {
      const appDetails = yield call(pipClient.getApp, appToken);
      pipAppUser = yield call(
        pipClient.createAppUser,
        appDetails['uuid'],
        appUser.ids.pip,
        'app-user',
      );
      if (!pipAppUser) throw Error('Empty response');
    } catch {
      // yield call(message.warning, 'User could not be found');
      console.error('Failed to create User in PIP. Returning empty forms data.');
      yield put(appUsersFormsLoaded(appUserUISId, []));
      return;
    }
  } else {
    pipAppUser = appUserResponse.results[0];
  }

  const [latest]: [
    PIPObject<{
      submissions: IFormSubmissionRaw[];
    }>,
  ] = yield call(
    pipClient.getObjectsForType,
    `${appToken}-form-submissions`,
    'latest',
    pipAppUser.uuid,
  );

  let formSubmissions: IFormSubmission[] = [];
  if (latest && latest.json && latest.json.submissions) {
    formSubmissions = latest.json.submissions.map(sub => {
      return {
        formId: sub.formId,
        created: sub.created,
        versions: sub.versions,
        uuid: sub.uuid,
        organisationId: sub.organisationId,
      };
    });
  }

  yield put(appUsersFormsLoaded(appUserUISId, formSubmissions));
}

// Note: the loading of global forms is disabled as the sharing of content and pathways is also disabled now.

export function* doFetchAppUserFormsForResults(/* {}: payload: { formSubmissions },IFetchFormsForAppUserResults */): any {
  try {
    // const extractedFormIds = formSubmissions.map(submission => {
    //   const extractedId = /[A-Za-z\d]{6}-form-(.*)$/.exec(submission.formObjectType);

    //   return extractedId ? extractedId[1] : null;
    // });

    // const tokens = yield getContext('tokens');
    // const client = yield call(createPipClient, tokens);
    // const GLOBAL_FORMS_OBJECT_TYPE = yield call(getGlobalFormsObjectType);

    // const [latest]: PIPObject<IForm[]>[] = yield call(
    //   client.getObjectsForType,
    //   GLOBAL_FORMS_OBJECT_TYPE,
    //   'latest',
    // );

    // const appUserResultFormDetails = latest.json.filter(form =>
    //   extractedFormIds.includes(form.uuid),
    // );

    const appUserResultFormDetails: IForm[] = [];

    yield put(temporaryFormsLoaded(appUserResultFormDetails));
  } catch (err) {
    console.error(err);
  }
}

export function* doFetchAppUserEngagements({
  payload: { appUserId, page, fromTime, toTime },
}: IFetchAppUserEngagements): any {
  try {
    const client = yield call(doCreateUISAdminClient);
    const offset = (page - 1) * 50;
    const response = yield call(
      client.getCommonMetricDataForAppUser,
      'engagement',
      appUserId,
      fromTime,
      toTime,
      offset,
    );
    if (!response.results?.length) throw Error('Empty response');

    yield put(fetchAppUserEngagementsSuccess(appUserId, response.results));
  } catch (err) {
    console.error(err);
    yield put(fetchAppUserEngagementsFailed(appUserId));
  }
}

export function* doFetchAppUserDailySteps({
  payload: { appUserId, page, fromTime, toTime },
}: IFetchAppUserDailySteps): any {
  try {
    const client = yield call(doCreateUISAdminClient);
    const offset = (page - 1) * 50;
    const response = yield call(
      client.getCommonMetricDataForAppUser,
      'daily-steps',
      appUserId,
      fromTime,
      toTime,
      offset,
    );
    if (!response.results) throw Error('Invalid response');

    yield put(fetchAppUserDailyStepsSuccess(appUserId, response.results));
  } catch (err) {
    console.error(err);
    yield put(fetchAppUserDailyStepsFailed(appUserId));
  }
}

export function* doFetchAppUserExternalFormSubmissionForms({
  payload: { formSubmissions },
}: IFetchAppUserExternalFormSubmissionForms): any {
  try {
    yield put(loadForms());

    const grouped: { [key: string]: IFormSubmission[] } = {};

    formSubmissions.forEach(submission => {
      if (submission.organisationId) {
        if (!grouped[submission.organisationId]) {
          grouped[submission.organisationId] = [];
        }
        grouped[submission.organisationId].push(submission);
      }
    });

    const groupedSubmissions = Object.values(grouped);

    let rawExternalForms: IRawForm[] = [];

    for (const submissions of groupedSubmissions) {
      let appToken = null;
      const organisationId = submissions.find(sub => sub.organisationId)?.organisationId;
      if (!organisationId) {
        console.error('No organisationId found for form submissions');
        continue;
      }
      const selectorAppToken = yield select(selectAppToken(organisationId));
      if (selectorAppToken) {
        appToken = selectorAppToken;
      } else {
        try {
          const directoryClient: OrganisationDirectoryClient = yield call(
            doCreateOrganisationDirectoryClient,
          );
          const response = yield call(directoryClient.getOrganisationBySlug, organisationId, true);
          appToken = response.results[0]?.ubiquity_app_token;
          if (appToken) {
            yield put(saveAppToken(organisationId, appToken));
          }
        } catch (error) {
          console.error('Error getting Organisation details from Directory: ', error);
        }
      }
      if (!appToken) {
        console.error('No app token found for form submissions');
        continue;
      }

      for (const submission of submissions) {
        const client = yield call(doCreateUbiquityV2Client);
        const contentClient = client['forms'](appToken);
        const content = yield call(contentClient.get, submission.formId);
        rawExternalForms.push({
          ...content,
          type: 'FORM',
          organisationId: submission.organisationId,
        });
      }
    }

    yield put(
      temporaryFormsLoaded(rawExternalForms.map(form => parseRawForm(form, form.organisationId!))),
    );
  } catch (err) {
    console.error(err);
  }
}
