import { put, call, getContext, select, takeEvery, take } from 'redux-saga/effects';
import { message } from 'antd';
import { IIDMService, IDMUser, IDMService } from '@liquid-state/idm-client';
import { organisationUrl, idmApiRoot } from 'settings';
import takeFirst from '../takeFirst';
import i18n from '../../i18n';
import {
  dashboardUsersLoaded,
  editDashboardUserFailed,
  editDashboardUserSuccess,
  loadDashboardUsers,
  loadDashboardUsersFailed,
  toggleDashboardUserActivationFailed,
  toggleDashboardUserActivationSuccess,
  ToggleDashboardUserActivation,
  EditDashboardUser,
  CreateDashboardUser,
  createDashboardUserSuccess,
  createDashboardUserFailed,
  ResendDashboardUserInvite,
  resendDashboardUserInviteFailed,
  resendDashboardUserInviteSuccess,
} from './actions';
import { selectDashboardUserById, selectUserTypeToRoleURIMap } from './reducers';
import { requiresPermissions } from '@authorisation/sagas';
import { Permissions } from '@authorisation/constants';
import { DashboardUser, InviteDashboardUserData, LanguageOptions } from './types';
import doCreateIDMService from '../doCreateIDMService';
import { determineUserTypeFromRole, getOrganisationSlugFromRoleURI } from './utils';
import { selectAppDetails } from '@organisation/redux/selectors';
import { IAppDetails } from '@organisation/redux/types';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { selectHospitals } from '@redux/hospitals/reducers';
import { SUPER_ADMIN } from '@constants';
import { IAdminHospital, LOAD_HOSPITALS_SUCCESS } from '@redux/hospitals/types';
import { fetchHospitals } from '@redux/hospitals/actions';

export default function* dashboardUsersRoot() {
  yield takeFirst('dashboardUsers/fetch', doFetchDashboardUsers);
  yield takeEvery('dashboardUsers/toggleActivation', doToggleDashboardUserActivation);
  yield takeEvery('dashboardUsers/edit', doEditDashboardUser);
  yield takeEvery('dashboardUsers/create', doCreateDashboardUser);
  yield takeEvery('dashboardUsers/resendInvite', doResendDashboardUserInvite);
}

const extractInvitationIdRegex = /.*\/invitations\/([0-9]+)\//;

const extractInviteId = (url: string): string => {
  const result = extractInvitationIdRegex.exec(url);
  if (!result) {
    throw new Error(`Invalid invitation id provided to extractInviteId: ${url}`);
  }
  return result[1];
};

export const extractDashboardUserId = (url: string): string => {
  const extractIdRegex = /\/api\/v1\/users\/([0-9]+)\//;
  const result = extractIdRegex.exec(url);
  if (!result) {
    throw new Error(`Invalid dashboard user url, ${url}`);
  }
  return result[1];
};

function* doFetchDashboardUsers(): any {
  try {
    yield put(loadDashboardUsers());

    try {
      yield requiresPermissions(Permissions.ViewDashboardUsers);

      const service = yield doCreateIDMService();
      const users = yield call(service.getAllUsers);

      const dashboardUsers: DashboardUser[] = users
        .map((user: IDMUser) => {
          const extractedId = extractDashboardUserId(user.url);
          const id = extractedId || user.username;

          return {
            activated: user.is_active,
            email: user.email,
            firstName: user.profile.firstName,
            hospitalId:
              getOrganisationSlugFromRoleURI(user.default_role_uri) || user.profile.hospitalId,
            id,
            invitations: user.invitations.map(url => extractInviteId(url)),
            lastName: user.profile.lastName,
            name: `${user.profile.firstName} ${user.profile.lastName}`,
            phoneNumber: user.profile.phoneNumber,
            userType: determineUserTypeFromRole(user.default_role_uri),
            language: user.profile.language,
            url: user.url,
            uuid: user.username,
          };
        })
        .filter((user: DashboardUser) => !!user.userType)
        .sort((a: DashboardUser, b: DashboardUser) => Number(b.id) - Number(a.id));

      yield put(dashboardUsersLoaded(dashboardUsers));
      return;
    } catch (err) {
      console.error(err);
    }

    yield put(dashboardUsersLoaded([]));
  } catch (err) {
    console.error(err);
    yield put(loadDashboardUsersFailed());
    yield call(message.error, i18n.t('dashboardUsers:Details.loadError'));
  }
}

function* doToggleDashboardUserActivation({
  payload: { id, currentActivatedStatus, name },
}: ToggleDashboardUserActivation) {
  try {
    const service: IIDMService = yield doCreateIDMService();

    yield call(service.updateUser, id, { is_active: !currentActivatedStatus });

    yield put(toggleDashboardUserActivationSuccess(id, !currentActivatedStatus));
    yield call(
      message.success,
      i18n.t(
        `dashboardUsers:Details.${
          currentActivatedStatus ? 'deactivationSuccess' : 'activationSuccess'
        }`,
        { name },
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(toggleDashboardUserActivationFailed());
    yield call(
      message.error,
      i18n.t(
        `dashboardUsers:Details.${
          currentActivatedStatus ? 'deactivationFailed' : 'activationFailed'
        }`,
      ),
    );
  }
}

function* doEditDashboardUser({ payload: { id, editedUser } }: EditDashboardUser): any {
  try {
    const history = yield getContext('history');
    const service: IIDMService = yield doCreateIDMService();

    const profile = yield select(state => state.dashboardUsers.byId[id]);

    yield call(service.updateUserProfile, id, {
      hospitalId: profile.hospitalId,
      firstName: editedUser.firstName,
      lastName: editedUser.lastName,
      language: editedUser.language,
      phoneNumber: editedUser.phoneNumber,
    });

    yield put(editDashboardUserSuccess(id, editedUser));
    yield call(history.goBack);
    yield call(message.success, i18n.t('dashboardUsers:Wizard.editSuccess'));
  } catch (err) {
    console.error(err);
    yield put(editDashboardUserFailed());
    yield call(message.error, i18n.t('dashboardUsers:Wizard.editFailed'));
  }
}

export function* inviteDashboardUser(userDetails: InviteDashboardUserData): any {
  const service: IIDMService = yield doCreateIDMService();
  let { email, userType: role, ...profile } = userDetails;
  let { organisationId, organisationSlug }: IAppDetails = yield select(selectAppDetails);
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  const mapUserTypeToRoleURI = yield select(selectUserTypeToRoleURIMap);
  let userRole = mapUserTypeToRoleURI[role];
  let hospital = null;

  if (currentDashboardUser.role === SUPER_ADMIN) {
    const [, hospitals]: [boolean, IAdminHospital[]] = yield select(selectHospitals);
    hospital = hospitals.find(({ slug }) => slug === userDetails.hospitalSlug);

    if (hospital) {
      organisationId = hospital.id;
      userRole = userRole.replace(
        `idm:${organisationSlug}:role`,
        `idm:${hospital.organisationSlug}:role`,
      );
      profile = {
        ...profile,
        hospitalId: hospital.hospitalId,
        hospitalName: hospital.name,
        hospitalSlug: hospital.slug,
      };
    }
  }

  const organisation = `${organisationUrl}${organisationId}/`;

  const idmUser = yield call(service.createUser, {
    email,
    organisation,
    profile,
    role: userRole,
  });

  const idmInvitation = yield call(service.createInvitation, {
    organisation,
    userId: idmUser.url,
  });

  const extractUserId = new RegExp(`${idmApiRoot}api/v1/users/([0-9]+)/`);
  const userIdResult = extractUserId.exec(idmUser.url);

  const dashboardUser: DashboardUser = {
    activated: true,
    email: userDetails.email,
    firstName: userDetails.firstName,
    hospitalId: hospital?.hospitalId,
    id: (userIdResult && userIdResult.length > 0 && userIdResult[1]) || idmUser.username,
    invitations: [extractInviteId(idmInvitation.url)],
    language: (userDetails.language || 'en') as LanguageOptions,
    lastName: userDetails.lastName,
    name: userDetails.name,
    phoneNumber: undefined, // this is set when the user registers, not when invited
    userType: userDetails.userType,
    url: idmUser.url,
    uuid: userDetails.email, // invited dashboard users have uuid set as email prior to user registration
  };

  return dashboardUser;
}

function* doCreateDashboardUser({ payload: { dashboardUser } }: CreateDashboardUser): any {
  try {
    const history = yield getContext('history');
    const invitedUser: DashboardUser = yield call(inviteDashboardUser, {
      ...dashboardUser,
      email: dashboardUser.email.toLowerCase(),
      name: `${dashboardUser.firstName} ${dashboardUser.lastName}`,
    });

    yield put(createDashboardUserSuccess(invitedUser));
    yield call(history.goBack);
    yield call(message.success, i18n.t('dashboardUsers:Wizard.createSuccess'));
  } catch (e) {
    const err = e as any;
    // console.error(err);
    if (err?.message) {
      let body: any = {};
      if (err?.response && err?.response?.json) {
        body = yield call(err.response.json.bind(err.response));
      }
      if (body.error_code === 'EMAIL_IN_USE') {
        yield put(createDashboardUserFailed());
        yield call(message.error, i18n.t('dashboardUsers:Wizard.userAlreadyExists'));
        return;
      }
    }
    yield put(createDashboardUserFailed());
    yield call(message.error, i18n.t('dashboardUsers:Wizard.createFailed'));
  }
}

function* doResendDashboardUserInvite({ payload: { id } }: ResendDashboardUserInvite): any {
  try {
    const service: IDMService = yield doCreateIDMService();
    const [, dashboardUser] = yield select(selectDashboardUserById(id)) as any;
    let { organisationId }: IAppDetails = yield select(selectAppDetails);
    const currentDashboardUser = yield select(selectCurrentDashboardUser);

    if (currentDashboardUser.role === SUPER_ADMIN) {
      let [, hospitals]: [boolean, IAdminHospital[]] = yield select(selectHospitals);

      if (hospitals.length === 0) {
        yield put(fetchHospitals());
        yield take(LOAD_HOSPITALS_SUCCESS);
        [, hospitals] = yield select(selectHospitals);
      }

      const hospital = hospitals.find(({ hospitalId }) => hospitalId === dashboardUser.hospitalId);

      if (hospital) {
        organisationId = hospital.id;
      }
    }

    const organisation = `${organisationUrl}${organisationId}/`;

    if (dashboardUser.invitations.length) {
      const invitationId = dashboardUser.invitations[dashboardUser.invitations.length - 1];
      yield call(service.deleteInvitation, invitationId);
    }

    const idmInvitation = yield call(service.createInvitation, {
      organisation,
      userId: dashboardUser.url,
    });

    yield put(resendDashboardUserInviteSuccess(id, extractInviteId(idmInvitation.url)));
    yield call(message.success, i18n.t('dashboardUsers:Details.resendCodeSuccess'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('dashboardUsers:Details.resendCodeError'));
    yield put(resendDashboardUserInviteFailed());
  }
}
