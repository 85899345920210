import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/sharp-solid-svg-icons';

export const FlagColoursToHex = {
  black01: '#000',
  grey01: '#bbb',
  green01: '#009F73',
  blue01: '#0072B2',
  light_blue01: '#56B4E9',
  yellow01: '#F0E441',
  light_orange01: '#E79F00',
  orange01: '#D55E00',
  pink01: '#CD79A7',
  deep_pink01: '#AA3277',
};

export const FlagColours = Object.keys(FlagColoursToHex);

function FlagIcon({ colour, size = 'xl' }) {
  return (
    <span style={{ color: FlagColoursToHex[colour] }}>
      <FontAwesomeIcon icon={faFlag} size={size} />
    </span>
  );
}

export default FlagIcon;
