import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { Form, Radio, InputNumber } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import { ONCE, REPEATING, FOREVER, UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED } from '@constants';
import { selectEngagementChecksByPathwayId } from '@pathways/redux/pathways/reducers';
import SelectEngagementCheckModal from './SelectEngagementCheckModal';
import EngagementCheckRuleDetails from '@pathways/cards/Pathways/EngagementCheckRuleDetails';

import './style.less';

function Occurrences({ pathway }) {
  const { t } = useTranslation();
  const { form, currentStepData, setCurrentStepData } = useWizard();
  const { engagements: engagementChecks } = useSelector(
    selectEngagementChecksByPathwayId(pathway?.id),
  );
  const [occurrenceTargetEngagementCheck, setOccurrenceTargetEngagementCheck] = useState();

  useEffect(() => {
    if (currentStepData?.type === UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED) {
      setOccurrenceTargetEngagementCheck(
        engagementChecks.find(
          engagementCheck =>
            engagementCheck.id.toString() ===
            currentStepData?.untilAnotherEngagementCheckPassed?.toString(),
        ),
      );
    }
  }, [currentStepData, engagementChecks]);

  const [isSelectEngagementCheckModalVisible, setIsSelectEngagementCheckModalVisible] = useState(
    false,
  );

  const handleSelection = e => {
    setCurrentStepData({ ...currentStepData, type: e.target.value });
    form.setFieldsValue({ type: e.target.value });
    if (e.target.value === UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED) {
      setIsSelectEngagementCheckModalVisible(true);
    } else {
      setCurrentStepData({ ...currentStepData, untilAnotherEngagementCheckPassed: undefined });
    }
  };

  const handleCountChange = value => {
    setCurrentStepData({ ...currentStepData, nTimes: value });
    form.setFieldsValue({ nTimes: value });
  };

  return (
    <>
      <ColumnGroup className="occurrences-step">
        <Column>
          <Card title={t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.title')}>
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.description')}
            </p>
            <Form>
              <Form.Item className="occurrences-type">
                {form.getFieldDecorator('type', {
                  initialValue: currentStepData?.type,
                  rules: [
                    {
                      required: true,
                      message: t(
                        'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.required',
                      ),
                    },
                  ],
                })(
                  <Radio.Group className="occurrence-type" onChange={handleSelection}>
                    <Radio value={ONCE}>
                      {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.once')}
                    </Radio>
                    <Radio value={REPEATING}>
                      {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.nTimes')}
                    </Radio>
                    <Radio value={FOREVER}>
                      {t(
                        'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.forever',
                      )}
                    </Radio>
                    <Radio value={UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED}>
                      {t(
                        'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.untilAnotherEngagementCheckPassed',
                      )}
                    </Radio>
                    {form.getFieldValue('type') === REPEATING && (
                      <Form.Item
                        label={t(
                          'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.nTimesLabel',
                        )}
                      >
                        {form.getFieldDecorator('nTimes', {
                          initialValue: currentStepData?.nTimes,
                        })(<InputNumber onChange={handleCountChange} />)}
                      </Form.Item>
                    )}
                  </Radio.Group>,
                )}
              </Form.Item>
            </Form>
            {occurrenceTargetEngagementCheck?.id &&
              currentStepData?.type === UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED && (
                <>
                  <h3>
                    {t(
                      'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.selectedCheck',
                    )}
                  </h3>
                  <EngagementCheckRuleDetails
                    engagementCheck={occurrenceTargetEngagementCheck}
                    pathway={pathway}
                  />
                </>
              )}
          </Card>
        </Column>
        <Column>
          <Card
            className="explanation-card"
            title={t(
              'pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanationTitle',
            )}
          >
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanation1')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanation2')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanation3')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanation4')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.explanation5')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
          </Card>
        </Column>
      </ColumnGroup>
      {isSelectEngagementCheckModalVisible && (
        <SelectEngagementCheckModal
          selectedEngagementCheck={currentStepData?.untilAnotherEngagementCheckPassed}
          onCancel={() => setIsSelectEngagementCheckModalVisible(false)}
          onOk={engagementCheck => {
            setCurrentStepData({
              ...currentStepData,
              untilAnotherEngagementCheckPassed: engagementCheck.id,
            });
            setIsSelectEngagementCheckModalVisible(false);
          }}
          engagementChecks={engagementChecks}
          pathwayName={pathway.name}
        />
      )}
    </>
  );
}

export default Occurrences;
