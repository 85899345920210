import { combineReducers } from 'redux';
import { IState } from '@redux/reducer';
import { IntegrationOrgConfig } from '@liquid-state/pephealth-client/dist/types';

import {
  // KemtaiStaffMember,
  ILoadOrgConfig,
  ILoadOrgConfigSuccess,
  ILoadOrgConfigFailure,
  ICreateOrgConfig,
  ICreateOrgConfigSuccess,
  ICreateOrgConfigFailure,
  IEditOrgConfig,
  IEditOrgConfigSuccess,
  IEditOrgConfigFailure,
} from './actions';

const initialStatusState = {
  isLoading: false,
  isSubmitting: false,
};

const status = (
  state = initialStatusState,
  action:
    | ILoadOrgConfig
    | ILoadOrgConfigSuccess
    | ILoadOrgConfigFailure
    | ICreateOrgConfig
    | ICreateOrgConfigSuccess
    | ICreateOrgConfigFailure
    | IEditOrgConfig
    | IEditOrgConfigSuccess
    | IEditOrgConfigFailure,
) => {
  switch (action.type) {
    case 'integrations/load-org-config':
      return { ...state, isLoading: true };
    case 'integrations/load-org-config-success':
    case 'integrations/load-org-config-failure':
      return { ...state, isLoading: false };
    case 'integrations/create-org-config':
    case 'integrations/edit-org-config':
      return { ...state, isSubmitting: true };
    case 'integrations/create-org-config-success':
    case 'integrations/create-org-config-failure':
    case 'integrations/edit-org-config-success':
    case 'integrations/edit-org-config-failure':
      return { ...state, isSubmitting: false };
    default:
      return state;
  }
};

const initialOrgConfigs: { [key: string]: IntegrationOrgConfig } = {};

const orgConfigs = (
  state = initialOrgConfigs,
  action: ILoadOrgConfigSuccess | ICreateOrgConfigSuccess | IEditOrgConfigSuccess,
) => {
  switch (action.type) {
    case 'integrations/load-org-config-success':
    case 'integrations/create-org-config-success':
    case 'integrations/edit-org-config-success':
      const s = { ...state };
      s[action.payload.integrationSlug] = action.payload.config;
      return s;
    default:
      return state;
  }
};

export const selectIsLoading = (state: IState) => {
  return state.integrations.status.isLoading;
};

export const selectIsSubmitting = (state: IState) => {
  return state.integrations.status.isSubmitting;
};

export const selectOrgConfig = (integrationSlug: string) => (
  state: IState,
): [boolean, IntegrationOrgConfig] => {
  return [state.integrations.status.isLoading, state.integrations.orgConfigs[integrationSlug]];
};

export default combineReducers({
  status,
  orgConfigs,
});
