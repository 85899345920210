import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPathwayWithRules,
  selectEngagementCheck,
  selectEngagementChecksLoading,
} from '@pathways/redux/pathways/reducers';
import { editEngagementCheck } from '@pathways/redux/pathways/actions';
import { usePageActions, useTitleUpdate } from '@hooks';
import EngagementCheckWizard from './EngagementCheckWizard';
import {
  selectSharedPathwayEngagementCheckById,
  selectSharedPathwayEngagementChecksLoading,
} from '@pathways/redux/sharedPathways/reducers';

function EditEngagementCheck({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setTitle } = usePageActions({
    title: t('pathways:ProcedurePathways.engagementCheckWizard.editTitle'),
  });
  useTitleUpdate(setTitle, 'pathways:ProcedurePathways.engagementCheckWizard.editTitle');

  const { id: pathwayId, engagementCheckId } = match.params;

  const engagement = useSelector(selectEngagementCheck(pathwayId, engagementCheckId));
  const sharedEngagementCheck = useSelector(
    selectSharedPathwayEngagementCheckById(pathwayId, engagementCheckId),
  );
  const engagementsLoading = useSelector(selectEngagementChecksLoading(pathwayId));
  const sharedEngagementChecksLoading = useSelector(
    selectSharedPathwayEngagementChecksLoading(pathwayId),
  );
  const [engagementCheck, setEngagementCheck] = useState();
  const [engagementChecksLoading, setEngagementChecksLoading] = useState(true);
  const [pathwaysLoading, pathway] = useSelector(selectPathwayWithRules(pathwayId));
  const isShared = match.path.includes('shared');

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    if (isShared) {
      if (sharedEngagementCheck?.id) {
        setEngagementCheck(sharedEngagementCheck);
        setEngagementChecksLoading(sharedEngagementChecksLoading);
      }
    } else {
      if (engagement?.id) {
        setEngagementCheck(engagement);
        setEngagementChecksLoading(engagementsLoading);
      }
    }
  }, [
    isShared,
    sharedEngagementCheck,
    sharedEngagementChecksLoading,
    engagement,
    engagementsLoading,
  ]);

  useEffect(() => {
    if (engagementCheck?.id) {
      setInitialData(engagementCheck);
    }
  }, [engagementCheck]);

  const onCompleted = useCallback(
    formData => {
      const {
        contentToCheckEngagement,
        typeOfCheck,
        timing,
        occurrences,
        action,
        details,
      } = formData;
      const editedEngagementCheck = {
        id: engagementCheck?.id,
        contentToCheckEngagement: {
          selectedContent: contentToCheckEngagement.selectedContent,
        },
        typeOfCheck,
        timing,
        occurrences,
        action,
        details,
        pathwayId: Number(pathwayId),
      };

      dispatch(editEngagementCheck(pathwayId, editedEngagementCheck));
    },
    [dispatch, pathwayId, engagementCheck],
  );

  return (
    <EngagementCheckWizard
      initialData={initialData}
      loading={pathwaysLoading}
      submitting={engagementChecksLoading}
      onCompleted={onCompleted}
      pathway={pathway}
    />
  );
}

export default EditEngagementCheck;
