import { combineReducers } from 'redux';
import { IIndexEvent, IIndexEventsState } from './types';
import {
  IIndexEventsLoaded,
  ILoadIndexEvents,
  ILoadIndexEventsFailed,
  ICreateIndexEvent,
  ICreateIndexEventFailed,
  ICreateIndexEventSuccess,
  IEditIndexEvent,
  IEditIndexEventFailed,
  IEditIndexEventSuccess,
  IDeleteIndexEvents,
  IDeleteIndexEventsFailed,
  IDeleteIndexEventsSuccess,
  ISwitchIndexEventOrder,
  ISwitchIndexEventOrderSuccess,
  ISwitchIndexEventOrderFailed,
} from './actions';

const creating = (
  state = false,
  action: ICreateIndexEvent | ICreateIndexEventFailed | ICreateIndexEventSuccess,
): boolean => {
  switch (action.type) {
    case 'indexEvents/createIndexEvent':
      return true;
    case 'indexEvents/createIndexEventFailed':
    case 'indexEvents/createIndexEventSuccess':
      return false;
    default:
      return state;
  }
};

const deleting = (
  state = false,
  action: IDeleteIndexEvents | IDeleteIndexEventsSuccess | IDeleteIndexEventsFailed,
) => {
  switch (action.type) {
    case 'indexEvents/deleteIndexEvents':
      return true;
    case 'indexEvents/deleteIndexEventsFailed':
    case 'indexEvents/deleteIndexEventsSuccess':
      return false;
    default:
      return state;
  }
};

const editing = (
  state = false,
  action:
    | IEditIndexEvent
    | IEditIndexEventFailed
    | IEditIndexEventSuccess
    | ISwitchIndexEventOrder
    | ISwitchIndexEventOrderSuccess
    | ISwitchIndexEventOrderFailed,
) => {
  switch (action.type) {
    case 'indexEvents/editIndexEvent':
    case 'indexEvents/switchOrder':
      return true;
    case 'indexEvents/switchOrderFailed':
    case 'indexEvents/switchOrderSuccess':
    case 'indexEvents/editIndexEventFailed':
    case 'indexEvents/editIndexEventSuccess':
      return false;
    default:
      return state;
  }
};

const switching = (
  state = false,
  action: ISwitchIndexEventOrder | ISwitchIndexEventOrderSuccess | ISwitchIndexEventOrderFailed,
) => {
  switch (action.type) {
    case 'indexEvents/switchOrder':
      return true;
    case 'indexEvents/switchOrderFailed':
    case 'indexEvents/switchOrderSuccess':
      return false;
    default:
      return state;
  }
};

const loading = (
  state = false,
  action: ILoadIndexEvents | IIndexEventsLoaded | ILoadIndexEventsFailed,
): boolean => {
  switch (action.type) {
    case 'indexEvents/load':
      return true;
    case 'indexEvents/loaded':
    case 'indexEvents/loadFailed':
      return false;
    default:
      return state;
  }
};

const data = (
  state: IIndexEvent[] = [],
  action:
    | IIndexEventsLoaded
    | IEditIndexEventSuccess
    | ICreateIndexEventSuccess
    | IDeleteIndexEventsSuccess
    | ISwitchIndexEventOrderSuccess,
): IIndexEvent[] => {
  switch (action.type) {
    case 'indexEvents/loaded':
      return action.payload.indexEvents;
    case 'indexEvents/createIndexEventSuccess':
      return [action.payload.indexEvent, ...state];
    case 'indexEvents/editIndexEventSuccess':
      return state.map(indexEvent =>
        indexEvent.id === action.payload.indexEvent.id ? action.payload.indexEvent : indexEvent,
      );
    case 'indexEvents/deleteIndexEventsSuccess':
      return state.filter(indexEvent => !action.payload.indexEventIds.includes(indexEvent.id));
    case 'indexEvents/switchOrderSuccess':
      const { id, order } = action.payload;
      const currentIndexOfIndexEvent = state.findIndex(indexEvent => indexEvent.id === id);

      if (currentIndexOfIndexEvent === -1) {
        return state;
      }

      const sortedState = [...state].sort((a, b) => a.order - b.order);
      const newIndex = Math.max(
        0,
        sortedState.findIndex(event => event.order >= Math.max(1, order)),
      );
      const [movedEvent] = sortedState.splice(currentIndexOfIndexEvent, 1);
      sortedState.splice(newIndex, 0, { ...movedEvent, order: Math.max(1, order) });

      return sortedState.map((event, index) => ({ ...event, order: index + 1 }));
    default:
      return state;
  }
};

export default combineReducers({
  creating,
  data,
  deleting,
  editing,
  loading,
  switching,
});

export const selectIndexEventCreating = (state: IIndexEventsState) => state.indexEvents.creating;
export const selectIndexEventDeleting = (state: IIndexEventsState) => [state.indexEvents.deleting];
export const selectIndexEventEditing = (state: IIndexEventsState) => state.indexEvents.editing;

export const selectIndexEvents = (state: IIndexEventsState): [boolean, IIndexEvent[]] => [
  state.indexEvents.loading,
  state.indexEvents.data,
];
export const selectIndexEvent = (id: number) => (
  state: IIndexEventsState,
): [boolean, IIndexEvent | undefined] => [
  state.indexEvents.loading,
  state.indexEvents.data.find(indexEvent => indexEvent.id === Number(id)),
];

export const selectIndexEventsSwitching = (state: IIndexEventsState) => state.indexEvents.switching;
