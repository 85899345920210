import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Checkbox } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import { Card, ColumnGroup, Column } from '@cards/Card';

import './style.less';

function SnapshotOptions() {
  const { t } = useTranslation();
  const { form, setCurrentStepData, formData } = useWizard();

  return (
    <ColumnGroup className="snapshot-options-step">
      <Column>
        <Card.Half title={t('pathways:ProcedurePathways.contentUpdates.snapshotOptions')}>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item
              label={
                <p>
                  {t('pathways:ProcedurePathways.contentUpdates.createSnapshotWithoutUpdates')
                    .split('*')
                    .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
                </p>
              }
              labelCol={{ span: 23 }}
              wrapperCol={{ span: 1 }}
              className="snapshot-checkbox-form-item"
            >
              {form.getFieldDecorator('createSnapshotWithoutUpdates', {
                initialValue: formData.snapshotOptions.createSnapshotWithoutUpdates,
                valuePropName: 'checked',
              })(
                <Checkbox
                  onChange={e => {
                    setCurrentStepData({
                      ...formData.snapshotOptions,
                      createSnapshotWithoutUpdates: e.target.checked,
                    });
                  }}
                />,
              )}
            </Form.Item>
            {form.getFieldValue('createSnapshotWithoutUpdates') && (
              <>
                <Form.Item label={t('pathways:ProcedurePathways.contentUpdates.snapshotName')}>
                  {form.getFieldDecorator('createSnapshotWithoutUpdatesName', {
                    initialValue: formData.snapshotOptions.createSnapshotWithoutUpdatesName,
                    rules: [{ required: true }],
                  })(
                    <Input
                      onChange={e =>
                        setCurrentStepData({
                          ...formData.snapshotOptions,
                          createSnapshotWithoutUpdatesName: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
                <Form.Item
                  label={t('pathways:ProcedurePathways.contentUpdates.snapshotDescription')}
                >
                  {form.getFieldDecorator('createSnapshotWithoutUpdatesDescription', {
                    initialValue: formData.snapshotOptions.createSnapshotWithoutUpdatesDescription,
                  })(
                    <Input.TextArea
                      rows={3}
                      onChange={e =>
                        setCurrentStepData({
                          ...formData.snapshotOptions,
                          createSnapshotWithoutUpdatesDescription: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
              </>
            )}
            <br />
            <Form.Item
              label={
                <p>
                  {t('pathways:ProcedurePathways.contentUpdates.createSnapshotWithUpdates')
                    .split('*')
                    .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
                </p>
              }
              labelCol={{ span: 23 }}
              wrapperCol={{ span: 1 }}
              className="snapshot-checkbox-form-item"
            >
              {form.getFieldDecorator('createSnapshotWithUpdates', {
                initialValue: formData.snapshotOptions.createSnapshotWithUpdates,
                valuePropName: 'checked',
              })(
                <Checkbox
                  onChange={e => {
                    setCurrentStepData({
                      ...formData.snapshotOptions,
                      createSnapshotWithUpdates: e.target.checked,
                      makeCurrentlyShared: !e.target.checked
                        ? false
                        : formData.snapshotOptions.makeCurrentlyShared,
                    });
                    form.setFieldsValue({
                      makeCurrentlyShared: !e.target.checked
                        ? false
                        : formData.snapshotOptions.makeCurrentlyShared,
                    });
                  }}
                />,
              )}
            </Form.Item>
            {form.getFieldValue('createSnapshotWithUpdates') && (
              <>
                <Form.Item label={t('pathways:ProcedurePathways.contentUpdates.snapshotName')}>
                  {form.getFieldDecorator('createSnapshotWithUpdatesName', {
                    initialValue: formData.snapshotOptions.createSnapshotWithUpdatesName,
                    rules: [{ required: true }],
                  })(
                    <Input
                      onChange={e =>
                        setCurrentStepData({
                          ...formData.snapshotOptions,
                          createSnapshotWithUpdatesName: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
                <Form.Item
                  label={t('pathways:ProcedurePathways.contentUpdates.snapshotDescription')}
                >
                  {form.getFieldDecorator('createSnapshotWithUpdatesDescription', {
                    initialValue: formData.snapshotOptions.createSnapshotWithUpdatesDescription,
                  })(
                    <Input.TextArea
                      rows={3}
                      onChange={e =>
                        setCurrentStepData({
                          ...formData.snapshotOptions,
                          createSnapshotWithUpdatesDescription: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>

                <Form.Item
                  label={t('pathways:ProcedurePathways.contentUpdates.makeCurrentlyShared')}
                  labelCol={{ span: 23 }}
                  wrapperCol={{ span: 1 }}
                  className="snapshot-checkbox-form-item"
                >
                  {form.getFieldDecorator('makeCurrentlyShared', {
                    initialValue: formData.snapshotOptions.makeCurrentlyShared,
                    valuePropName: 'checked',
                  })(
                    <Checkbox
                      onChange={e => {
                        setCurrentStepData({
                          ...formData.snapshotOptions,
                          makeCurrentlyShared: e.target.checked,
                        });
                      }}
                      disabled={!form.getFieldValue('createSnapshotWithUpdates')}
                    />,
                  )}
                </Form.Item>
              </>
            )}
          </Form>
        </Card.Half>
      </Column>
      <Column />
    </ColumnGroup>
  );
}

export default SnapshotOptions;
