import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { editDocument, fetchDocuments } from '@redux/documents/actions';
import { selectDocumentEditing } from '@redux/documents/reducers';
import { selectLanguage } from '@redux/core/reducers';
import { languageFromMetadata } from '@utils/contentMetadata';
import { determineSourceTypeForNewContent } from '@utils';
import { usePageActions } from '@hooks/usePageActions';
import ContentRef from '@utils/contentRef';
import useContentRefs from '@hooks/useContentRefs';
import DocumentWizard from './Wizard';
import { matchType } from '../../propTypes';

const nowMoment = moment();

function EditDocument({ match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const contentRef = ContentRef.fromUrlFragment(match.params.id);
  const { [contentRef]: document } = useContentRefs([contentRef]);

  const loading = document === undefined;

  const editing = useSelector(selectDocumentEditing);
  const findLanguage = useSelector(languageFromMetadata);
  const language = useSelector(selectLanguage(findLanguage(document)));
  const permissions = useSelector(selectPermissionsForUser);
  const [initialData, setInitialData] = useState({
    content: {
      documents: [{ uid: undefined, name: undefined }],
    },
    details: {
      name: undefined,
      description: undefined,
      language: undefined,
      audience: undefined,
    },
  });

  const { setTitle } = usePageActions({
    title: '',
    showClose: true,
  });

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch, document]);

  useEffect(() => {
    if (document && !loading) {
      setTitle(`${t('documents:Wizard.edit')} ${document.name}`);
      setInitialData({
        content: {
          documents: [{ uid: document.id, name: document.name }],
        },
        details: {
          name: document.name,
          description: document.description,
          language: language?.key,
          status: document.published ? 'published' : 'unpublished',
          latestVersion: document.latest_version.name,
          audience: document.audience_type,
        },
      });
    }
  }, [document, language, loading, setTitle, t]);

  const onCompleted = useCallback(
    formData => {
      if (formData) {
        const { content, details } = formData;

        const editedDocument = {
          description: details.description,
          latest_version: { created: nowMoment.toISOString(), name: details.name },
          published: details.status === 'published',
          name: details.name,
          metadata: {
            language: details.language || 'en',
            source: determineSourceTypeForNewContent(permissions),
            audience: details.audience,
          },
          fileData: content.documents[0],
        };

        dispatch(editDocument(document.id, editedDocument));
      }
    },
    [dispatch, document, permissions],
  );

  return (
    <DocumentWizard
      loading={loading}
      submitting={editing}
      initialData={initialData}
      onCompleted={onCompleted}
      isEdit={true}
    />
  );
}

EditDocument.propTypes = {
  match: matchType.isRequired,
};

export default EditDocument;
