import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, oneOfType, number, string } from 'prop-types';
import { selectWeblink, selectWeblinkVersion } from '@redux/weblinks/selectors';
import { fetchWeblinks, fetchWeblinkLatestVersion } from '@redux/weblinks/actions';
import WeblinkDetailsCard from './Card';

function WeblinkDetails({ weblinkId, showWarning, title, ...props }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWeblinks());
    dispatch(fetchWeblinkLatestVersion(weblinkId));
  }, [dispatch, weblinkId]);

  const [loading, weblink] = useSelector(selectWeblink(weblinkId));
  const [, weblinkVersion] = useSelector(selectWeblinkVersion(weblinkId));

  return (
    <WeblinkDetailsCard
      loading={loading}
      showWarning={showWarning}
      title={title}
      weblink={weblink}
      version={weblinkVersion}
      {...props}
    />
  );
}

WeblinkDetails.propTypes = {
  showWarning: bool,
  title: string,
  weblinkId: oneOfType([number, string]).isRequired,
};

WeblinkDetails.defaultProps = {
  showWarning: false,
  title: 'cards:DocumentDetail.title',
};

export default WeblinkDetails;
