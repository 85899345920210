import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Card, Column, ColumnGroup } from '@cards/Card';
import { submitGDPRRequestForCurrentUser } from '@redux/requests/actions';
import { isRequestSubmitting } from '@redux/requests/reducer';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { GDPR_DOWNLOAD_REQUEST } from '@constants';

function GDPRDataRequest() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSubmitting = useSelector(isRequestSubmitting);
  const dashboardUser = useSelector(selectCurrentDashboardUser);

  return (
    <ColumnGroup>
      <Column>
        <Card className="gdpr-send-personal-data" title={t('myProfile:DataDownload.title')}>
          <p>{t('myProfile:DataDownload.explain')}</p>
          <p>{t('myProfile:DataDownload.email')}</p>
          <p>
            {/* TODO: put users email here when it gets added to IDM response */}
            <strong>{dashboardUser.email}</strong>
          </p>
          <Button
            block
            loading={isSubmitting}
            type="primary"
            onClick={() => dispatch(submitGDPRRequestForCurrentUser(GDPR_DOWNLOAD_REQUEST))}
          >
            {t('myProfile:DataDownload.submit')}
          </Button>
        </Card>
      </Column>
      <Column />
    </ColumnGroup>
  );
}

export default GDPRDataRequest;
