import { useContext, useMemo, useCallback } from 'react';
import { WizardContext } from '.';

function useWizard() {
  const { currentStepName, form, formData, setFormData, enableFinalStepValidation } = useContext(
    WizardContext,
  );
  const currentStepData = useMemo(() => formData[currentStepName] || {}, [
    currentStepName,
    formData,
  ]);

  const setCurrentStepData = useCallback(
    data => setFormData({ ...formData, [currentStepName]: data }),
    [currentStepName, formData, setFormData],
  );

  return {
    currentStepData,
    setCurrentStepData,
    form,
    formData,
    setFormData,
    enableFinalStepValidation,
  };
}

export default useWizard;
