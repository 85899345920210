import React from 'react';
import { func, shape, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import WizardDetails from '@components/Wizard/Details';
import Content from './Content';
import Details from '../Wizard/Details';
import Review from './Review';
import '../Wizard/style.less';

function VideoWizard({ initialData, loading, submitting, onCompleted, isEdit }) {
  const { t } = useTranslation();

  return (
    <Wizard
      loading={loading}
      submitting={submitting}
      initialData={initialData}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('common:Wizard.details.stepName')}>
        <WizardDetails>
          <Details documentTranslationKey="videos" isEdit={isEdit} />
        </WizardDetails>
      </WizardItem>
      <WizardItem stepKey="content" title={t('documents:Wizard.content.stepName')}>
        <ColumnGroup>
          <Column>
            <Content />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      <WizardItem stepKey="review" title={t('documents:Wizard.review.stepName')}>
        <Review />
      </WizardItem>
    </Wizard>
  );
}

VideoWizard.propTypes = {
  initialData: shape({
    content: shape({}),
    details: shape({}),
  }),
  onCompleted: func.isRequired,
  isEdit: bool,
};

VideoWizard.defaultProps = {
  initialData: {},
  isEdit: false,
};

export default VideoWizard;
