import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import DetailRow from '@components/DetailRow';
import { Card } from '@cards/Card';
import Text from 'antd/lib/typography/Text';
import './style.less';

const FileDetailsCard = ({ file, createdBy, modifiedBy, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card.Half
      className="file-details"
      noPadding
      loading={loading}
      title={
        file.is_deleted || file.shares[0]?.is_deleted
          ? [
              <Text key="deleted-label" className="deleted-label">
                {t('cards:FileDetails.labels.deleted')}{' '}
              </Text>,
              <span key="file-name" className="deleted-file-name">
                {file.name}
              </span>,
            ]
          : file.name
      }
      {...props}
    >
      {file === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="vertical"
          wrapperCol={{ span: 16 }}
          className={`detail-form ${
            file.is_deleted || file.shares[0]?.is_deleted ? 'deleted' : ''
          }`}
        >
          <DetailRow label={t('cards:FileDetails.labels.name')} value={file.name} />
          <DetailRow
            label={t('cards:FileDetails.labels.description')}
            value={file.description.split('\n').map(item => (
              <span key={item}>
                {item}
                <br />
              </span>
            ))}
          />
          <DetailRow
            label={t('cards:FileDetails.labels.creationDate')}
            value={
              file.original_creation_date ? moment(file.original_creation_date).format('LL') : ''
            }
          />
          <DetailRow
            label={t('cards:FileDetails.labels.uploadDate')}
            value={moment(file.created).format('LL')}
          />
          <DetailRow label={t('cards:FileDetails.labels.fileOwner')} value={createdBy} />
          <DetailRow
            label={t('cards:FileDetails.labels.lastModified')}
            value={file.modified ? moment(file.modified).format('LL') : ''}
          />
          <DetailRow label={t('cards:FileDetails.labels.lastModifiedBy')} value={modifiedBy} />
        </Form>
      )}
    </Card.Half>
  );
};

FileDetailsCard.propTypes = {
  file: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
};

FileDetailsCard.defaultProps = {
  file: {},
  loading: false,
  createdBy: '',
  modifiedBy: '',
};

export default FileDetailsCard;
