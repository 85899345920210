import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Select } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import { WEBSITE, WEB_FORM } from '@constants';
import { faEdit, faGlobe } from '@fortawesome/pro-regular-svg-icons';

function Details({ isEdit }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();
  const contentType = form.getFieldValue('contentType');

  return (
    <>
      {isEdit ? (
        <Form.Item label={t(`weblinks:Wizard.details.status`)}>
          {form.getFieldDecorator('status', {
            initialValue: currentStepData.status || 'published',
          })(
            <Select>
              <Select.Option value="published">
                {t(`weblinks:Wizard.details.publish`)}
              </Select.Option>
              <Select.Option value="unpublished">
                {t(`weblinks:Wizard.details.unpublish`)}
              </Select.Option>
            </Select>,
          )}
        </Form.Item>
      ) : null}
      <Form.Item label={t(`weblinks:Wizard.details.audience`)}>
        {form.getFieldDecorator('audience', {
          initialValue: currentStepData.audience || 'app-user',
        })(
          <Select disabled={isEdit}>
            <Select.Option value="app-user">{t(`weblinks:Wizard.details.patient`)}</Select.Option>
            <Select.Option value="clinician">
              {t(`weblinks:Wizard.details.clinician`)}
            </Select.Option>
            {/* <Select.Option value="patient-admin">
              {t(`weblinks:Wizard.details.patientAdmin`)}
            </Select.Option> */}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label={t(`weblinks:Wizard.details.contentType`)}>
        {form.getFieldDecorator('contentType', {
          initialValue: currentStepData.contentType,
          rules: [{ required: true, message: t('weblinks:Wizard.details.validation') }],
        })(
          <Select placeholder={t('weblinks:Wizard.details.placeholder')}>
            <Select.Option value={WEB_FORM}>
              <FontAwesomeIcon icon={faEdit} />
              {t('common:contentTypes.singular.webform')}
            </Select.Option>
            <Select.Option value={WEBSITE}>
              <FontAwesomeIcon icon={faGlobe} />
              {t('common:contentTypes.singular.website')}
            </Select.Option>
          </Select>,
        )}
      </Form.Item>
      <p className="text-color-secondary">
        {!contentType ? t('weblinks:Wizard.details.explain') : null}
        {contentType === WEBSITE ? t('weblinks:Wizard.details.website') : null}
        {contentType === WEB_FORM ? t('weblinks:Wizard.details.webform') : null}
      </p>
    </>
  );
}

Details.propTypes = {
  isEdit: bool,
};

Details.defaultProps = {
  isEdit: false,
};

export default Details;
