import {
  IFetchAppUserFiles,
  fetchAppUserFilesSuccess,
  fetchAppUserFilesFailed,
  ICreateAppUserFile,
  createAppUserFileSuccess,
  createAppUserFileFailed,
  IUpdateAppUserFile,
  updateAppUserFileSuccess,
  updateAppUserFileFailed,
  IRemoveAppUserFile,
  removeAppUserFileSuccess,
  removeAppUserFileFailed,
  IGetAppUserFileDownloadUrl,
  getAppUserFileDownloadUrlSuccess,
  getAppUserFileDownloadUrlFailed,
} from '../actions';
import { track } from '../../../analytics';
import { call, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../i18n';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';

// function doCreateUISAdminClientMock() {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       const uisClient = {
//         getAppUserFiles: (appUserId: string) => {
//           const appUserFiles = [
//             // owned by user not modified not deleted
//             {
//               pk: 1,
//               created: '2022-06-27T03:26:48.476104Z',
//               modified: '',
//               modified_by: '',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: false,
//               name: 'Owned by user, not modified, not deleted',
//               description: 'Hello this is a test file by user',
//               creator: false,
//               created_by: 'cedric+20230503@liquid-state.com',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'App',
//                   app_user: null,
//                   app: 1,
//                   creator: true,
//                 },
//               ],
//             },
//             // owned by user not modified deleted
//             {
//               pk: 2,
//               created: '2022-07-30T03:26:48.476104Z',
//               modified: '',
//               modified_by: '',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: true,
//               name: 'Owned by user, not modified, deleted',
//               description: 'Hello this is a test file by user',
//               creator: false,
//               created_by: 'cedric+20230503@liquid-state.com',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'App',
//                   app_user: null,
//                   app: 1,
//                   creator: true,
//                 },
//               ],
//             },
//             // owned by user modified by user not deleted
//             {
//               pk: 3,
//               created: '2023-01-09T03:26:48.476104Z',
//               modified: '2022-06-27T03:26:48.476104Z',
//               modified_by: 'cedric+20230503@liquid-state.com',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: false,
//               name: 'Owned by user, modified by user, not deleted',
//               description: 'Hello this is a test file by user',
//               creator: false,
//               created_by: 'cedric+20230503@liquid-state.com',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'App',
//                   app_user: null,
//                   app: 1,
//                   creator: true,
//                 },
//               ],
//             },
//             // owned by admin not modified not deleted
//             {
//               pk: 4,
//               created: '2023-02-28T03:26:48.476104Z',
//               modified: '',
//               modified_by: '',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: false,
//               name: 'Owned by admin, not modified, not deleted',
//               description: 'Hello this is a test file by admin',
//               creator: true,
//               created_by: 'Hospital 1',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'AppUser',
//                   app_user: '716675e4-fc5e-4207-8d53-c1734ad3381d',
//                   app: null,
//                   creator: false,
//                 },
//               ],
//             },
//             // owned by admin not modified deleted
//             {
//               pk: 5,
//               created: '2022-09-30T03:26:48.476104Z',
//               modified: '',
//               modified_by: '',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: true,
//               name: 'Owned by admin, not modified, deleted',
//               description: 'Hello this is a test file by admin',
//               creator: true,
//               created_by: 'Hospital 1',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'AppUser',
//                   app_user: '716675e4-fc5e-4207-8d53-c1734ad3381d',
//                   app: null,
//                   creator: false,
//                 },
//               ],
//             },
//             // owned by admin modified by admin not deleted
//             {
//               pk: 6,
//               created: '2022-12-04T03:26:48.476104Z',
//               modified: '2022-06-27T03:26:48.476104Z',
//               modified_by: 'Cédric Hosp1Admin3',
//               file_creation_date: '2022-06-27T03:26:48.476104Z',
//               is_deleted: false,
//               name: 'Owned by admin, modified by admin, not deleted',
//               description: 'Hello this is a test file by admin',
//               creator: true,
//               created_by: 'Hospital 1',
//               is_ready: true,
//               shares: [
//                 {
//                   uuid: '48ad05b1-e199-4f11-8ba6-761ddd7755d1',
//                   created: '',
//                   modified: '',
//                   share_type: 'AppUser',
//                   app_user: '716675e4-fc5e-4207-8d53-c1734ad3381d',
//                   app: null,
//                   creator: false,
//                 },
//               ],
//             },
//           ];

//           return Promise.resolve(appUserFiles);
//         },
//         createAppUserFile: (appUserId: string, file: any) => {
//           return Promise.resolve(file);
//         },
//         updateAppUserFile: (pk: number, file: any) => {
//           return Promise.resolve({ ...file, modified: new Date().toISOString() });
//         },
//         deleteAppUserFile: (pk: number) => {
//           return Promise.resolve();
//         },
//         getAppUserFileDownloadUrl: (appUserId: string, id: string) => {
//           return Promise.resolve({ url: 'https://www.africau.edu/images/default/sample.pdf' });
//         },
//       };
//       resolve(uisClient);
//     }, 5000);
//   });
// }

export function* doFetchAppUserFiles({ payload: { appUserId } }: IFetchAppUserFiles): any {
  try {
    // const appUserFilesFromState = yield select(selectAppUserFiles(appUserId));
    // const uisClient = yield doCreateUISAdminClientMock();
    const uisClient = yield doCreateUISAdminClient();
    const { results } = yield call(uisClient.listAppUserFiles, appUserId);
    yield put(fetchAppUserFilesSuccess(appUserId, results));
  } catch (err) {
    console.error('Failed to fetch app user files', err);
    yield put(fetchAppUserFilesFailed(appUserId));
    yield call(message.error, 'Failed to fetch app user files');
  }
}

export function* doCreateAppUserFile({
  payload: { appUserId, fileDetails, file },
}: ICreateAppUserFile): any {
  try {
    // const uisClient = yield doCreateUISAdminClientMock();
    // const appUserFilesFromState = yield select(selectAppUserFiles(appUserId));
    // const pk = appUserFilesFromState ? appUserFilesFromState.length + 1 : 1;
    const mimeTypesWhitelist = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/jpeg',
      'image/png',
      'image/avif',
      'image/webp',
    ];
    const videoMimeTypesWhitelist = ['video/mp4', 'video/quicktime'];
    if (!mimeTypesWhitelist.includes(file.type) && !videoMimeTypesWhitelist.includes(file.type)) {
      yield call(message.error, i18n.t('patients:Files.addEditFileForm.unsupportedFileType'));
      throw new Error('Invalid file type');
    }
    if (file.size > 20000000 && mimeTypesWhitelist.includes(fileDetails.mimetype)) {
      track('Non-video filesize limit exceeded', {
        fileSize: file.size,
        fileExtension: fileDetails.mimetype.split('/')[1],
      });
      yield call(message.error, i18n.t('patients:Files.addEditFileForm.fileSizeLimit'));
      throw new Error('File size limit exceeded');
    }
    if (file.size > 100000000 && videoMimeTypesWhitelist.includes(fileDetails.mimetype)) {
      track('Video filesize limit exceeded', {
        fileSize: file.size,
        fileExtension: fileDetails.mimetype.split('/')[1],
      });
      yield call(message.error, i18n.t('patients:Files.addEditFileForm.videoSizeLimit'));
      throw new Error('Video size limit exceeded');
    }
    if (file.size === 0) {
      track('Empty file uploaded', {
        fileSize: file.size,
        fileExtension: fileDetails.mimetype.split('/')[1],
      });
      yield call(message.error, i18n.t('patients:Files.addEditFileForm.emptyFile'));
      throw new Error('Empty file');
    }
    const uisClient = yield doCreateUISAdminClient();
    const createdFile = yield call(uisClient.createAppUserFile, appUserId, fileDetails);
    const { url } = yield call(uisClient.getAppUserFileUploadUrl, createdFile);
    const response = yield call(window.fetch.bind(window), url, {
      method: 'PUT',
      headers: {
        'Content-Type': fileDetails.mimetype,
      },
      body: file,
    });
    if (!response.ok) throw new Error('Error uploading the file');
    const uploadCompleteFile = yield call(uisClient.appUserFileUploadComplete, createdFile);
    yield put(createAppUserFileSuccess(appUserId, uploadCompleteFile));
    const { hospitalName, hospitalSlug } = yield select(state => state.login.user.profile);
    track('Create App User File', {
      ownerType: 'organisation',
      fileExtension: fileDetails.mimetype.split('/')[1],
      fileSize: file.size,
      organisationName: hospitalName,
      organisationId: hospitalSlug,
      fileUuid: uploadCompleteFile.uuid,
    });
  } catch (err) {
    console.error('Failed to create app user file', err);
    yield put(createAppUserFileFailed(appUserId));
    yield call(message.error, 'Failed to create app user file');
  }
}

export function* doUpdateAppUserFile({
  // payload: { appUserId, filePk, updatedFile },
  payload: { appUserId, file, updatedDetails },
}: IUpdateAppUserFile): any {
  try {
    // const uisClient = yield doCreateUISAdminClientMock();
    const uisClient = yield doCreateUISAdminClient();
    // const updatedFileResponse = yield call(uisClient.updateAppUserFile, filePk, updatedFile);
    const updatedFileResponse = yield call(uisClient.updateAppUserFile, file, updatedDetails);
    // const usersName = yield select(state => state.login.user.name);
    // yield put(
    //   updateAppUserFileSuccess(appUserId, { ...updatedFileResponse, modified_by: usersName }),
    // );
    yield put(updateAppUserFileSuccess(appUserId, updatedFileResponse));
    const { hospitalName, hospitalSlug } = yield select(state => state.login.user.profile);
    track('Update App User File', {
      ownerType: 'organisation',
      organisationName: hospitalName,
      organisationId: hospitalSlug,
      fileUuid: updatedFileResponse.uuid,
    });
  } catch (err) {
    console.error('Error updating file', err);
    yield put(updateAppUserFileFailed(appUserId));
    yield call(message.error, 'Failed to update app user file');
  }
}

export function* doDeleteAppUserFile({ payload: { appUserId, file } }: IRemoveAppUserFile): any {
  try {
    // const uisClient = yield doCreateUISAdminClientMock();
    const uisClient = yield doCreateUISAdminClient();
    yield call(uisClient.deleteAppUserFile, file);
    yield put(removeAppUserFileSuccess(appUserId, file.uuid));
    const { hospitalName, hospitalSlug } = yield select(state => state.login.user.profile);
    track('Delete App User File', {
      ownerType: 'organisation',
      organisationName: hospitalName,
      organisationId: hospitalSlug,
      fileUuid: file.uuid,
    });
  } catch (err) {
    console.error('File deletion error', err);
    yield put(removeAppUserFileFailed(appUserId));
    yield call(message.error, 'Failed to delete app user file');
  }
}

export function* doGetAppUserFileDownloadUrl({
  payload: { appUserId, file },
}: IGetAppUserFileDownloadUrl): any {
  try {
    // const uisClient = yield doCreateUISAdminClientMock();
    // const { url } = yield call(uisClient.getAppUserFileDownloadUrl, appUserId, filePk);
    // const file = yield select(selectAppUserFileByUuid(appUserId, fileUuid));
    const uisClient = yield doCreateUISAdminClient();
    const { url } = yield call(uisClient.getAppUserFileDownloadUrl, file);
    yield put(getAppUserFileDownloadUrlSuccess(appUserId, url));
    const { hospitalName, hospitalSlug } = yield select(state => state.login.user.profile);
    track('View App User File', {
      ownerType: 'organisation',
      organisationName: hospitalName,
      organisationId: hospitalSlug,
      fileUuid: file.uuid,
    });
  } catch (err) {
    console.error('Failed to get app user file download url', err);
    yield put(getAppUserFileDownloadUrlFailed(appUserId));
    yield call(message.error, 'Failed to get app user file download url');
  }
}
