import { IReduxAction } from '@redux/types';
import {
  ICreateOrganisationData,
  Organisation,
  // IOrganisationInvites,
  // EditOrganisationData,
  FETCH_ORGANISATIONS,
  LOAD_ORGANISATIONS,
  LOAD_ORGANISATIONS_SUCCESS,
  LOAD_ORGANISATIONS_FAILED,
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILED,
  EDIT_ORGANISATION,
  EDIT_ORGANISATION_FAILED,
  EDIT_ORGANISATION_SUCCESS,
  IAdminOrganisation,
  GET_ORGANISATION_PUBLIC_CONTENT,
  GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  GET_ORGANISATION_PUBLIC_CONTENT_FAILED,
  CREATE_ORGANISATION_PUBLIC_CONTENT,
  CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  REMOVE_ORGANISATION_PUBLIC_CONTENT,
  REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  REORDER_ORGANISATION_PUBLIC_CONTENT,
  ReorderOptions,
  GET_ORGANISATION_BRANDING,
  GET_ORGANISATION_BRANDING_SUCCESS,
  GET_ORGANISATION_BRANDING_FAILED,
  CREATE_ORGANISATION_BRANDING,
  CREATE_ORGANISATION_BRANDING_SUCCESS,
  CREATE_ORGANISATION_BRANDING_FAILED,
  UPDATE_ORGANISATION_BRANDING,
  UPDATE_ORGANISATION_BRANDING_SUCCESS,
  UPDATE_ORGANISATION_BRANDING_FAILED,
  GET_ORGANISATION_INSTRUCTIONAL_SCREENS,
  GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  GET_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS,
  REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS,
  REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED,
  REMOVE_ORGANISATION_IMAGE,
} from './types';
import {
  IUpdateableOrganisationDetails,
  IGetPublicContentResponse,
  CreatePublicContent,
  PublicContent,
  Branding,
  CreateBranding,
  InstructionalScreen,
  CreateInstructionalScreen,
} from '@liquid-state/directory-client';

export type IFetchOrganisations = IReduxAction<typeof FETCH_ORGANISATIONS>;
export const fetchOrganisations = (): IFetchOrganisations => ({
  type: FETCH_ORGANISATIONS,
  payload: {},
});

export type ILoadOrganisations = IReduxAction<typeof LOAD_ORGANISATIONS>;
export const loadOrganisations = (): ILoadOrganisations => ({
  type: LOAD_ORGANISATIONS,
  payload: {},
});

export type ILoadOrganisationsSuccess = IReduxAction<
  typeof LOAD_ORGANISATIONS_SUCCESS,
  { organisations: IAdminOrganisation[] }
>;
export const loadOrganisationsSuccess = (
  organisations: IAdminOrganisation[],
): ILoadOrganisationsSuccess => ({
  type: LOAD_ORGANISATIONS_SUCCESS,
  payload: { organisations },
});

export type ILoadOrganisationsFailed = IReduxAction<typeof LOAD_ORGANISATIONS_FAILED>;
export const loadOrganisationsFailed = (): ILoadOrganisationsFailed => ({
  type: LOAD_ORGANISATIONS_FAILED,
  payload: {},
});

export type IFetchOrganisation = ReturnType<typeof fetchOrganisation>;
export const fetchOrganisation = () => ({
  type: 'organisation/fetch' as const,
  payload: {},
});

export type ILoadOrganisation = ReturnType<typeof loadOrganisation>;
export const loadOrganisation = () => ({
  type: 'organisation/load' as const,
  payload: {},
});

export type ILoadOrganisationSuccess = ReturnType<typeof loadOrganisationSuccess>;
export const loadOrganisationSuccess = (organisation: Organisation) => ({
  type: 'organisation/load-success' as const,
  payload: { organisation },
});

export type ILoadOrganisationFailed = ReturnType<typeof loadOrganisationFailed>;
export const loadOrganisationFailed = () => ({
  type: 'organisation/load-failed' as const,
  payload: {},
});

export type CreateOrganisation = IReduxAction<
  typeof CREATE_ORGANISATION,
  { organisation: ICreateOrganisationData }
>;
export const createOrganisation = (organisation: ICreateOrganisationData) => ({
  type: CREATE_ORGANISATION,
  payload: { organisation },
});

export type CreateOrganisationFailed = IReduxAction<typeof CREATE_ORGANISATION_FAILED>;
export const createOrganisationFailed = () => ({
  type: CREATE_ORGANISATION_FAILED,
  payload: {},
});

export type CreateOrganisationSuccess = IReduxAction<
  typeof CREATE_ORGANISATION_SUCCESS,
  { organisation: { name: string } }
>;
export const createOrganisationSuccess = (organisation: { name: string }) => ({
  type: CREATE_ORGANISATION_SUCCESS,
  payload: { organisation },
});

export type IUpdateOrganisation = IReduxAction<
  typeof UPDATE_ORGANISATION,
  { organisation: Organisation }
>;
export const updateOrganisation = (organisation: Organisation): IUpdateOrganisation => ({
  type: UPDATE_ORGANISATION,
  payload: { organisation },
});

export type IUpdateOrganisationSuccess = IReduxAction<
  typeof UPDATE_ORGANISATION_SUCCESS,
  { organisation: Organisation }
>;
export const updateOrganisationSuccess = (
  organisation: Organisation,
): IUpdateOrganisationSuccess => ({
  type: UPDATE_ORGANISATION_SUCCESS,
  payload: { organisation },
});

export type IUpdateOrganisationFailed = IReduxAction<typeof UPDATE_ORGANISATION_FAILED>;
export const updateOrganisationFailed = (): IUpdateOrganisationFailed => ({
  type: UPDATE_ORGANISATION_FAILED,
  payload: {},
});

export type EditOrganisation = IReduxAction<
  typeof EDIT_ORGANISATION,
  { id: string; updatedDetails: IUpdateableOrganisationDetails; editOnly?: boolean }
>;
export const editOrganisation = (
  id: string,
  updatedDetails: IUpdateableOrganisationDetails,
  editOnly?: boolean,
): EditOrganisation => ({
  type: EDIT_ORGANISATION,
  payload: { id, updatedDetails, editOnly },
});

// export type EditOrganisationSuccess = IReduxAction<
//   typeof EDIT_ORGANISATION_SUCCESS,
//   { id: string; updatedDetails: IUpdateableOrganisationDetails; }
// >;
// export const editOrganisationSuccess = (id: string, updatedDetails: IUpdateableOrganisationDetails,): EditOrganisationSuccess => ({
//   type: EDIT_ORGANISATION_SUCCESS,
//   payload: { id, updatedDetails },
// });

export type EditOrganisationSuccess = IReduxAction<
  typeof EDIT_ORGANISATION_SUCCESS,
  { organisation: Organisation }
>;
export const editOrganisationSuccess = (organisation: Organisation): EditOrganisationSuccess => ({
  type: EDIT_ORGANISATION_SUCCESS,
  payload: { organisation },
});

export type EditOrganisationFailed = IReduxAction<typeof EDIT_ORGANISATION_FAILED, {}>;
export const editOrganisationFailed = (): EditOrganisationFailed => ({
  type: EDIT_ORGANISATION_FAILED,
  payload: {},
});

export type IGetOrganisationPublicContent = IReduxAction<typeof GET_ORGANISATION_PUBLIC_CONTENT>;
export const getOrganisationPublicContent = (): IGetOrganisationPublicContent => ({
  type: GET_ORGANISATION_PUBLIC_CONTENT,
  payload: {},
});

export type IGetOrganisationPublicContentSuccess = IReduxAction<
  typeof GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  { content: IGetPublicContentResponse }
>;
export const getOrganisationPublicContentSuccess = (
  content: IGetPublicContentResponse,
): IGetOrganisationPublicContentSuccess => ({
  type: GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  payload: { content },
});

export type IGetOrganisationPublicContentFailed = IReduxAction<
  typeof GET_ORGANISATION_PUBLIC_CONTENT_FAILED
>;
export const getOrganisationPublicContentFailed = (): IGetOrganisationPublicContentFailed => ({
  type: GET_ORGANISATION_PUBLIC_CONTENT_FAILED,
  payload: {},
});

export type ICreateOrganisationPublicContent = IReduxAction<
  typeof CREATE_ORGANISATION_PUBLIC_CONTENT,
  { content: CreatePublicContent[] }
>;
export const createOrganisationPublicContent = (
  content: CreatePublicContent[],
): ICreateOrganisationPublicContent => ({
  type: CREATE_ORGANISATION_PUBLIC_CONTENT,
  payload: { content },
});

export type ICreateOrganisationPublicContentSuccess = IReduxAction<
  typeof CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  { content: PublicContent[] }
>;
export const createOrganisationPublicContentSuccess = (
  content: PublicContent[],
): ICreateOrganisationPublicContentSuccess => ({
  type: CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  payload: { content },
});

export type IRemoveOrganisationPublicContent = IReduxAction<
  typeof REMOVE_ORGANISATION_PUBLIC_CONTENT,
  { primaryKeys: number[] }
>;
export const removeOrganisationPublicContent = (
  primaryKeys: number[],
): IRemoveOrganisationPublicContent => ({
  type: REMOVE_ORGANISATION_PUBLIC_CONTENT,
  payload: { primaryKeys },
});

export type IRemoveOrganisationPublicContentSuccess = IReduxAction<
  typeof REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  { primaryKeys: number[] }
>;
export const removeOrganisationPublicContentSuccess = (
  primaryKeys: number[],
): IRemoveOrganisationPublicContentSuccess => ({
  type: REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  payload: { primaryKeys },
});

export type IReorderOrganisationPublicContent = IReduxAction<
  typeof REORDER_ORGANISATION_PUBLIC_CONTENT,
  { options: ReorderOptions }
>;
export const reorderOrganisationPublicContent = (
  options: ReorderOptions,
): IReorderOrganisationPublicContent => ({
  type: REORDER_ORGANISATION_PUBLIC_CONTENT,
  payload: { options },
});

export type IGetOrganisationBranding = IReduxAction<typeof GET_ORGANISATION_BRANDING>;
export const getOrganisationBranding = (): IGetOrganisationBranding => ({
  type: GET_ORGANISATION_BRANDING,
  payload: {},
});

export type IGetOrganisationBrandingSuccess = IReduxAction<
  typeof GET_ORGANISATION_BRANDING_SUCCESS,
  { branding: Branding }
>;
export const getOrganisationBrandingSuccess = (
  branding: Branding,
): IGetOrganisationBrandingSuccess => ({
  type: GET_ORGANISATION_BRANDING_SUCCESS,
  payload: { branding },
});

export type IGetOrganisationBrandingFailed = IReduxAction<typeof GET_ORGANISATION_BRANDING_FAILED>;
export const getOrganisationBrandingFailed = (): IGetOrganisationBrandingFailed => ({
  type: GET_ORGANISATION_BRANDING_FAILED,
  payload: {},
});

export type ICreateOrganisationBranding = IReduxAction<
  typeof CREATE_ORGANISATION_BRANDING,
  { branding: CreateBranding; logo?: File; banner?: File; splashScreenImage?: File }
>;
export const createOrganisationBranding = (
  branding: CreateBranding,
  logo?: File,
  banner?: File,
  splashScreenImage?: File,
): ICreateOrganisationBranding => ({
  type: CREATE_ORGANISATION_BRANDING,
  payload: { branding, logo, banner, splashScreenImage },
});

export type ICreateOrganisationBrandingSuccess = IReduxAction<
  typeof CREATE_ORGANISATION_BRANDING_SUCCESS,
  { branding: Branding }
>;
export const createOrganisationBrandingSuccess = (
  branding: Branding,
): ICreateOrganisationBrandingSuccess => ({
  type: CREATE_ORGANISATION_BRANDING_SUCCESS,
  payload: { branding },
});

export type ICreateOrganisationBrandingFailed = IReduxAction<
  typeof CREATE_ORGANISATION_BRANDING_FAILED
>;
export const createOrganisationBrandingFailed = (): ICreateOrganisationBrandingFailed => ({
  type: CREATE_ORGANISATION_BRANDING_FAILED,
  payload: {},
});

export type IUpdateOrganisationBranding = IReduxAction<
  typeof UPDATE_ORGANISATION_BRANDING,
  { pk: number; branding: CreateBranding; logo?: File; banner?: File; splashScreenImage?: File }
>;
export const updateOrganisationBranding = (
  pk: number,
  branding: CreateBranding,
  logo?: File,
  banner?: File,
  splashScreenImage?: File,
): IUpdateOrganisationBranding => ({
  type: UPDATE_ORGANISATION_BRANDING,
  payload: { pk, branding, logo, banner, splashScreenImage },
});

export type IUpdateOrganisationBrandingSuccess = IReduxAction<
  typeof UPDATE_ORGANISATION_BRANDING_SUCCESS,
  { branding: Branding }
>;
export const updateOrganisationBrandingSuccess = (
  branding: Branding,
): IUpdateOrganisationBrandingSuccess => ({
  type: UPDATE_ORGANISATION_BRANDING_SUCCESS,
  payload: { branding },
});

export type IUpdateOrganisationBrandingFailed = IReduxAction<
  typeof UPDATE_ORGANISATION_BRANDING_FAILED
>;
export const updateOrganisationBrandingFailed = (): IUpdateOrganisationBrandingFailed => ({
  type: UPDATE_ORGANISATION_BRANDING_FAILED,
  payload: {},
});

export type IGetOrganisationInstructionalScreens = IReduxAction<
  typeof GET_ORGANISATION_INSTRUCTIONAL_SCREENS
>;
export const getOrganisationInstructionalScreens = (): IGetOrganisationInstructionalScreens => ({
  type: GET_ORGANISATION_INSTRUCTIONAL_SCREENS,
  payload: {},
});

export type IGetOrganisationInstructionalScreensSuccess = IReduxAction<
  typeof GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  { instructionalScreens: InstructionalScreen[] }
>;

export const getOrganisationInstructionalScreensSuccess = (
  instructionalScreens: InstructionalScreen[],
): IGetOrganisationInstructionalScreensSuccess => ({
  type: GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  payload: { instructionalScreens },
});

export type IGetOrganisationInstructionalScreensFailed = IReduxAction<
  typeof GET_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED
>;
export const getOrganisationInstructionalScreensFailed = (): IGetOrganisationInstructionalScreensFailed => ({
  type: GET_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED,
  payload: {},
});

export type ICreateOrganisationInstructionalScreen = IReduxAction<
  typeof CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  { instructionalScreen: CreateInstructionalScreen; image?: File }
>;

export const createOrganisationInstructionalScreen = (
  instructionalScreen: CreateInstructionalScreen,
  image?: File,
): ICreateOrganisationInstructionalScreen => ({
  type: CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  payload: { instructionalScreen, image },
});

export type ICreateOrganisationInstructionalScreenSuccess = IReduxAction<
  typeof CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  { instructionalScreen: InstructionalScreen }
>;

export const createOrganisationInstructionalScreenSuccess = (
  instructionalScreen: InstructionalScreen,
): ICreateOrganisationInstructionalScreenSuccess => ({
  type: CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  payload: { instructionalScreen },
});

export type ICreateOrganisationInstructionalScreenFailed = IReduxAction<
  typeof CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED
>;
export const createOrganisationInstructionalScreenFailed = (): ICreateOrganisationInstructionalScreenFailed => ({
  type: CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  payload: {},
});

export type IUpdateOrganisationInstructionalScreen = IReduxAction<
  typeof UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  { pk: number; instructionalScreen: CreateInstructionalScreen; image?: File }
>;

export const updateOrganisationInstructionalScreen = (
  pk: number,
  instructionalScreen: CreateInstructionalScreen,
  image?: File,
): IUpdateOrganisationInstructionalScreen => ({
  type: UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  payload: { pk, instructionalScreen, image },
});

export type IUpdateOrganisationInstructionalScreenSuccess = IReduxAction<
  typeof UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  { instructionalScreen: InstructionalScreen }
>;

export const updateOrganisationInstructionalScreenSuccess = (
  instructionalScreen: InstructionalScreen,
): IUpdateOrganisationInstructionalScreenSuccess => ({
  type: UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  payload: { instructionalScreen },
});

export type IUpdateOrganisationInstructionalScreenFailed = IReduxAction<
  typeof UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED
>;
export const updateOrganisationInstructionalScreenFailed = (): IUpdateOrganisationInstructionalScreenFailed => ({
  type: UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  payload: {},
});

export type IRemoveOrganisationInstructionalScreens = IReduxAction<
  typeof REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS,
  { pks: number[] }
>;
export const removeOrganisationInstructionalScreens = (
  pks: number[],
): IRemoveOrganisationInstructionalScreens => ({
  type: REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS,
  payload: { pks },
});

export type IRemoveOrganisationInstructionalScreenSuccess = IReduxAction<
  typeof REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  { pk: number }
>;
export const removeOrganisationInstructionalScreenSuccess = (
  pk: number,
): IRemoveOrganisationInstructionalScreenSuccess => ({
  type: REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  payload: { pk },
});

export type IRemoveOrganisationInstructionalScreenFailed = IReduxAction<
  typeof REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED
>;
export const removeOrganisationInstructionalScreenFailed = (): IRemoveOrganisationInstructionalScreenFailed => ({
  type: REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  payload: {},
});

export type IReorderOrganisationInstructionalScreens = IReduxAction<
  typeof REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS,
  { options: ReorderOptions }
>;
export const reorderOrganisationInstructionalScreens = (
  options: ReorderOptions,
): IReorderOrganisationInstructionalScreens => ({
  type: REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS,
  payload: { options },
});

export type IReorderOrganisationInstructionalScreensSuccess = IReduxAction<
  typeof REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  { instructionalScreens: InstructionalScreen[] }
>;
export const reorderOrganisationInstructionalScreensSuccess = (
  instructionalScreens: InstructionalScreen[],
): IReorderOrganisationInstructionalScreensSuccess => ({
  type: REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  payload: { instructionalScreens },
});

export type IReorderOrganisationInstructionalScreensFailed = IReduxAction<
  typeof REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED
>;
export const reorderOrganisationInstructionalScreensFailed = (): IReorderOrganisationInstructionalScreensFailed => ({
  type: REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED,
  payload: {},
});

export type IRemoveOrganisationImage = IReduxAction<
  typeof REMOVE_ORGANISATION_IMAGE,
  { imageId: string }
>;
export const removeOrganisationImage = (imageId: string): IRemoveOrganisationImage => ({
  type: REMOVE_ORGANISATION_IMAGE,
  payload: { imageId },
});
