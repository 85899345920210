import i18n from '../i18n';

const defaultLanguageCode = 'en';
const languageCodeExtractionRegex = /([A-Za-z]+)-?/;

/**
 * returns translated name if one exists, else a default name.
 * @param {{ [languageCode]: translatedName }} translatedNames
 * @param {string} defaultName
 */
function getTranslatedName(translatedNames, defaultName) {
  const languageCode = languageCodeExtractionRegex.exec(i18n.language)[1];
  if (languageCode && languageCode !== defaultLanguageCode && translatedNames[languageCode]) {
    return translatedNames[languageCode];
  }
  return defaultName;
}

export default getTranslatedName;
