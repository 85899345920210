import React, { useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Checkbox, Radio } from 'antd';
import { Card, Column, ColumnGroup } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import FlagIcon, { FlagColours } from '@components/FlagIcon';
import { antFormType } from '../../../propTypes';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function FlagWizard({ form, initialData, submitting, title, onSubmit, isEditing }) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (err) return;
        onSubmit(values);
      });
    },
    [form, onSubmit],
  );

  const disclaimerValidator = (_, value, callback) => {
    if (value === true) {
      callback();
      return;
    }
    callback(value);
  };

  return (
    <ColumnGroup>
      <Column>
        <Card className="flag-wizard-card" title={title}>
          <Form {...formItemLayout} className="detail-form" onSubmit={handleSubmit}>
            <DetailRow
              key={'colour'}
              label={<>{t('flags:Wizard.colour')}</>}
              value={form.getFieldDecorator('colour', {
                initialValue: initialData.colour,
                rules: [{ required: true, message: t('flags:Wizard.errors.colourRequired') }],
              })(
                <Radio.Group>
                  {FlagColours.map(colour => (
                    <Radio value={colour}>{<FlagIcon colour={colour} />}</Radio>
                  ))}
                </Radio.Group>,
              )}
            />

            <DetailRow
              key={'name'}
              label={<>{t('flags:Wizard.name')}</>}
              value={form.getFieldDecorator('name', {
                initialValue: initialData.name,
                rules: [{ required: true, message: t('flags:Wizard.errors.nameRequired') }],
              })(<Input />)}
            />

            <DetailRow
              key={'description'}
              label={<>{t('flags:Wizard.description')}</>}
              value={form.getFieldDecorator('description', {
                initialValue: initialData.description,
                rules: [{ required: false }],
              })(<Input />)}
            />

            <DetailRow
              key={'isFavourite'}
              label={<>{t('flags:Wizard.isFavourite')}</>}
              value={form.getFieldDecorator('isFavourite', {
                initialValue: initialData.isFavourite,
                rules: [{ required: false }],
              })(
                <Checkbox defaultChecked={initialData.isFavourite}>
                  {t('flags:Wizard.isFavouriteHelpText')}
                </Checkbox>,
              )}
            />

            {isEditing ? (
              <DetailRow
                key={'isDisabled'}
                label={<>{t('flags:Wizard.isDisabled')}</>}
                value={form.getFieldDecorator('isDisabled', {
                  initialValue: initialData.isDisabled,
                  rules: [{ required: false }],
                })(
                  <Checkbox defaultChecked={initialData.isDisabled}>
                    {t('flags:Wizard.isDisabledHelpText')}
                  </Checkbox>,
                )}
              />
            ) : null}

            <DetailRow
              key={'disclaimer'}
              label={<>{t('flags:Wizard.disclaimer')}</>}
              value={form.getFieldDecorator('disclaimer', {
                initialValue: false,
                rules: [
                  { required: true, message: t('flags:Wizard.errors.disclaimerRequired') },
                  {
                    validator: disclaimerValidator,
                    message: t('flags:Wizard.errors.disclaimerRequired'),
                  },
                ],
                valuePropName: 'checked',
              })(<Checkbox>{t('flags:Wizard.disclaimerHelpText')}</Checkbox>)}
            />

            <Button disabled={submitting} loading={submitting} type="primary" htmlType="submit">
              {t('common:buttons.save')}
            </Button>
          </Form>
        </Card>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

FlagWizard.propTypes = {
  form: antFormType.isRequired,
  initialData: shape({}),
  submitting: bool,
  title: string.isRequired,
  onSubmit: func.isRequired,
};

FlagWizard.defaultProps = {
  initialData: {},
  submitting: false,
};

export default Form.create()(FlagWizard);
