import React from 'react';
import ReorderButton from '@components/ReorderButton';
import FlagIcon from '@components/FlagIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';

export default (t, flags, disabled, moveFlag) => {
  const cols = [
    {
      title: t('flags:List.columns.colour'),
      key: 'colour',
      render: record => <FlagIcon colour={record.colour} />,
    },
    {
      title: t('flags:List.columns.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('flags:List.columns.description'),
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: t('flags:List.columns.appUserCount'),
      key: 'appUserCount',
      dataIndex: 'appUserCount',
    },
    {
      title: t('flags:List.columns.isFavourite'),
      key: 'isFavourite',
      render: record =>
        record.isFavourite ? <PublishedStatusIcon published={record.isFavourite} /> : null,
    },
    {
      title: t('flags:List.columns.order'),
      width: 200,
      render: (text, record, index) => (
        <ReorderButton
          downDisabled={index === flags.length - 1}
          upDisabled={index === 0}
          onClickDown={event => {
            event.stopPropagation();
            return moveFlag(record, 'down');
          }}
          onClickUp={event => {
            event.stopPropagation();
            return moveFlag(record, 'up');
          }}
        />
      ),
    },
  ];
  if (disabled) {
    cols.pop();
  }
  return cols;
};
