import React from 'react';

class Error extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    return this.state.error ? null : this.props.children;
  }
}

export default Error;
