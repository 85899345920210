import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import {
  getOrganisationBranding,
  createOrganisationBranding,
  updateOrganisationBranding,
  removeOrganisationImage,
} from '@redux/organisations/actions';
import { selectOrganisationBranding, selectBrandingLoading } from '@redux/organisations/reducers';
import { Input, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import ImageUpload from '@components/ImageUpload';
import './style.less';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('organisations:EditOrganisationDetails.exitTitle'),
    content: t('organisations:EditOrganisationDetails.exitContent'),
    okText: t('organisations:EditOrganisationDetails.okText'),
    cancelText: t('organisations:EditOrganisationDetails.cancelText'),
    onOk: history.goBack,
  });
};

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

function EditSplashScreen({
  form: {
    getFieldDecorator,
    validateFields,
    getFieldError,
    setFieldsValue,
    setFields,
    getFieldsValue,
    getFieldValue,
  },
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const branding = useSelector(selectOrganisationBranding);
  const [imageList, setImageList] = useState([]);
  const [removeSplashImage, setRemoveSplashImage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const brandingLoading = useSelector(selectBrandingLoading);
  const [isBrandingLoading, setIsBrandingLoading] = useState(brandingLoading);

  useEffect(() => {
    if (submitting && !isBrandingLoading) history.goBack();
  }, [isBrandingLoading, submitting, history]);

  useEffect(() => {
    setIsBrandingLoading(brandingLoading);
  }, [brandingLoading]);

  useEffect(() => {
    dispatch(getOrganisationBranding());
    dispatch(fetchOrganisation());
  }, [dispatch]);

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  useEffect(() => {
    if (branding) {
      setFieldsValue({
        text: branding.splash_screen_text,
        textColour: branding.splash_screen_text_colour,
        backgroundColour: branding.splash_screen_background_colour,
      });
    }
  }, [branding, setFieldsValue]);

  const [, organisation] = useSelector(selectOrganisation(dashboardUserProfile.organisationId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: true,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = (organisation && organisation.name) || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle]);

  const handleSubmit = e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        setSubmitting(true);

        if (removeSplashImage) {
          dispatch(removeOrganisationImage(branding.splash_screen_image));
        }

        const { text, textColour, backgroundColour } = values;
        if (branding?.pk) {
          dispatch(
            updateOrganisationBranding(
              branding.pk,
              {
                splash_screen_text_colour: textColour,
                splash_screen_background_colour: backgroundColour,
                splash_screen_text: text,
                splash_screen_image: removeSplashImage ? null : branding.splash_screen_image,
              },
              undefined,
              undefined,
              imageList[0]?.originFileObj,
            ),
          );
        } else {
          dispatch(
            createOrganisationBranding(
              {
                splash_screen_text_colour: textColour,
                splash_screen_background_colour: backgroundColour,
                splash_screen_text: text,
              },
              undefined,
              undefined,
              imageList[0]?.originFileObj,
            ),
          );
        }
      } else {
        setSubmitting(false);
        setIsBrandingLoading(false);
      }
    });
  };

  const validateText = (rule, value, callback) => {
    const { text, textColour, backgroundColour } = getFieldsValue();
    if (
      !(
        text ||
        textColour ||
        backgroundColour ||
        imageList.length ||
        (branding?.splash_screen_image_url && !removeSplashImage)
      )
    ) {
      callback();
      return;
    }

    if (!text && !imageList.length && (!branding?.splash_screen_image_url || removeSplashImage)) {
      callback(t('organisations:Branding.splashScreen.textRequired'));
    } else {
      callback();
    }
  };

  const validateTextColour = (rule, value, callback) => {
    const { text, textColour } = getFieldsValue();

    if (text && !textColour) {
      callback(t('organisations:Branding.splashScreen.textColourRequired'));
    } else {
      callback();
    }
  };

  const validateBackgroundColour = (rule, value, callback) => {
    const { text, textColour, backgroundColour } = getFieldsValue();

    if (
      !(
        text ||
        textColour ||
        backgroundColour ||
        imageList.length ||
        (branding?.splash_screen_image_url && !removeSplashImage)
      )
    ) {
      callback();
      return;
    }

    if (!backgroundColour) {
      callback(t('organisations:Branding.splashScreen.backgroundColourRequired'));
    } else {
      callback();
    }
  };

  const validateImage = (rule, value, callback) => {
    const { text, textColour, backgroundColour } = getFieldsValue();

    if (
      !(
        text ||
        textColour ||
        backgroundColour ||
        imageList.length ||
        (branding?.splash_screen_image_url && !removeSplashImage)
      )
    ) {
      callback();
      return;
    }

    if (!text && !imageList.length && (!branding?.splash_screen_image_url || removeSplashImage)) {
      callback(t('organisations:Branding.splashScreen.imageRequired'));
    } else {
      callback();
    }
  };

  const textValue = getFieldValue('text');
  useEffect(() => {
    if (imageList.length) {
      setFields({
        text: {
          value: textValue,
          error: undefined,
        },
        image: {
          error: undefined,
        },
      });
    }

    if (textValue) {
      setFields({
        image: {
          error: undefined,
        },
      });
    }
  }, [imageList, setFields, textValue]);

  return (
    <ColumnGroup className="edit-organisation-splash-screen">
      <Column>
        <Card
          className="organisation-details-form-card"
          title={t('organisations:Branding.splashScreen.edit.title')}
        >
          <Form {...detailsLayout} onSubmit={handleSubmit}>
            <Form.Item label={t('organisations:Branding.splashScreen.text')}>
              <div className="branding-field-container">
                {getFieldDecorator('text', {
                  rules: [{ validator: validateText }],
                })(
                  <Input
                    type="text"
                    allowClear
                    // 230 characters was chosen by testing the maximum number of characters
                    // that fit on a small screen in the mobile app
                    maxLength={230}
                  />,
                )}
                {!getFieldError('text') && (
                  <Text className="help-text">
                    {t('organisations:Branding.splashScreen.textHelpText')}
                  </Text>
                )}
              </div>
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('organisations:Branding.splashScreen.textColour')}
            >
              <div className="branding-field-container">
                {getFieldDecorator('textColour', {
                  rules: [{ validator: validateTextColour }],
                })(<Input type="text" placeholder="#000000" allowClear />)}
                {!getFieldError('textColour') && (
                  <Text className="help-text">
                    {t('organisations:Branding.splashScreen.textColourHelpText')}
                  </Text>
                )}
              </div>
              <Input
                style={{ width: '25%' }}
                type="color"
                value={getFieldValue('textColour') || '#ffffff'}
                onChange={e => setFieldsValue({ textColour: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('organisations:Branding.splashScreen.backgroundColour')}
            >
              <div className="branding-field-container">
                {getFieldDecorator('backgroundColour', {
                  rules: [{ validator: validateBackgroundColour }],
                })(<Input type="text" placeholder="#ffffff" allowClear />)}
                {!getFieldError('backgroundColour') && (
                  <Text className="help-text">
                    {t('organisations:Branding.splashScreen.backgroundColourHelpText')}
                  </Text>
                )}
              </div>
              <Input
                style={{ width: '25%' }}
                type="color"
                value={getFieldValue('backgroundColour') || '#ffffff'}
                onChange={e => setFieldsValue({ backgroundColour: e.target.value })}
              />
            </Form.Item>
            <Form.Item label={t('organisations:Branding.splashScreen.image')}>
              <div className="branding-field-container">
                {getFieldDecorator('image', {
                  rules: [{ validator: validateImage }],
                })(
                  <ImageUpload
                    imageList={imageList}
                    setImageList={setImageList}
                    setRemove={setRemoveSplashImage}
                    imageUrl={branding?.splash_screen_image_url}
                  />,
                )}
                {!getFieldError('image') && (
                  <Text className="help-text">
                    {t('organisations:Branding.splashScreen.imageHelpText')}
                  </Text>
                )}
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: '10px' }}
                loading={isBrandingLoading}
                onClick={() => setIsBrandingLoading(true)}
              >
                {t('organisations:Branding.splashScreen.edit.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

const WrappedEditSplashScreen = Form.create()(EditSplashScreen);
export default WrappedEditSplashScreen;
