import React from 'react';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import './style.less';

function InputWithHelpText({
  type,
  name,
  value,
  onChange,
  placeholder,
  allowClear,
  maxLength,
  helpText,
  isTextArea,
  rows,
  autoSize,
}) {
  return (
    <>
      {isTextArea ? (
        <div className="input-and-help-text-container">
          <TextArea
            rows={rows}
            value={value}
            onChange={onChange}
            allowClear={allowClear}
            maxLength={maxLength}
            autoSize={autoSize}
          />
          <Text className="help-text">{helpText}</Text>
        </div>
      ) : (
        <div className="input-and-help-text-container">
          <Input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            allowClear={allowClear}
            maxLength={maxLength}
          />
          <Text className="help-text">{helpText}</Text>
        </div>
      )}
    </>
  );
}

export default InputWithHelpText;
