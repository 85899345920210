import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Input, Table } from 'antd';
import { useSearch } from '../../hooks';
import './style.less';

function SingleSelectModal({
  selected,
  filterFunc,
  title,
  visible,
  onCancel,
  loading,
  width,
  primaryKey,
  columns,
  dataSource,
  onSubmit,
  audienceTypesToFilter,
}) {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dataSourceWithKeys = useMemo(() => {
    return dataSource.reduce((unique, data) => {
      const key = data[primaryKey] || data['uuid'];
      if (unique.findIndex(item => item.key === key) === -1) {
        unique.push({ key, ...data });
      }
      return unique.filter(
        u =>
          !audienceTypesToFilter?.includes(u.audienceType) &&
          !audienceTypesToFilter?.includes(u.audience_type),
      );
    }, []);
  }, [dataSource, primaryKey, audienceTypesToFilter]);
  const [searchResults, searchProps] = useSearch(dataSourceWithKeys, filterFunc);

  useEffect(() => {
    if (selected) {
      setSelectedRowKeys([selected[primaryKey] || selected['uuid']]);
      setSelectedRows([selected]);
    }
  }, [selected, primaryKey]);

  const rowSelection = useMemo(
    () => ({
      type: 'radio',
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
      },
    }),
    [selectedRowKeys],
  );

  return (
    <Modal
      className="single-selection-modal"
      footer={[
        <div className="footer-left" key="footer-button-select-all" />,
        <div className="footer-right" key="footer-button-add">
          <Button
            disabled={!selectedRowKeys.length}
            type="primary"
            onClick={() => onSubmit(selectedRowKeys, selectedRows)}
          >
            {t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
      title={title}
      visible={visible}
      width={width}
      onCancel={onCancel}
    >
      <div className="modal-search">
        <Input.Search placeholder={t('common:search')} {...searchProps} />
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowSelection={rowSelection}
      />
    </Modal>
  );
}

SingleSelectModal.defaultProps = {
  width: 700,
};

export default SingleSelectModal;
