export const PASSWORD_RESET_STARTED = 'PASSWORD_RESET_STARTED';
export const PASSWORD_RESET_EMAIL_VALID = 'PASSWORD_RESET_EMAIL_VALID';
export const PASSWORD_RESET_EMAIL_INVALID = 'PASSWORD_RESET_EMAIL_INVALID';
export const PASSWORD_RESET_CODE_SUBMITTED = 'PASSWORD_RESET_CODE_SUBMITTED';
export const PASSWORD_RESET_CODE_VALID = 'PASSWORD_RESET_CODE_VALID';
export const PASSWORD_RESET_CODE_INVALID = 'PASSWORD_RESET_CODE_INVALID';
export const PASSWORD_RESET_PASSWORD_SUBMITTED = 'PASSWORD_RESET_PASSWORD_SUBMITTED';
export const PASSWORD_RESET_COMPLETE = 'PASSWORD_RESET_COMPLETE';
export const PASSWORD_RESET_PASSWORD_INVALID = 'PASSWORD_RESET_PASSWORD_INVALID';
export const PASSWORD_RESET_RESEND_CODE = 'PASSWORD_RESET_RESEND_CODE';

export const passwordResetStarted = ({ email }) => ({
  type: PASSWORD_RESET_STARTED,
  payload: {
    email,
  },
});

export const passwordResetEmailValid = () => ({
  type: PASSWORD_RESET_EMAIL_VALID,
  payload: {},
});

export const passwordResetCodeSubmitted = ({ code }) => ({
  type: PASSWORD_RESET_CODE_SUBMITTED,
  payload: {
    code,
  },
});

export const passwordResetCodeValid = () => ({
  type: PASSWORD_RESET_CODE_VALID,
  payload: {},
});

export const passwordResetCodeInvalid = error => ({
  type: PASSWORD_RESET_CODE_INVALID,
  payload: {
    error,
  },
});

export const passwordResetPasswordSubmitted = ({ password }) => ({
  type: PASSWORD_RESET_PASSWORD_SUBMITTED,
  payload: {
    password,
  },
});

export const passwordResetComplete = () => ({
  type: PASSWORD_RESET_COMPLETE,
  payload: {},
});

export const passwordResetPasswordInvalid = error => ({
  type: PASSWORD_RESET_PASSWORD_INVALID,
  payload: {
    error,
  },
});

export const passwordResetResendCode = () => ({
  type: PASSWORD_RESET_RESEND_CODE,
  payload: {},
});

export const passwordResetEmailInvalid = () => ({
  type: PASSWORD_RESET_EMAIL_INVALID,
  payload: {},
});
