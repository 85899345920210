import React from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

export default ({ active }) => {
  return active ? (
    <FontAwesomeIcon size="lg" className="active-icon active" icon={faCheckCircle} />
  ) : (
    <FontAwesomeIcon size="lg" className="active-icon" icon={faTimesCircle} />
  );
};
