import { ICreateWeblinkData } from './types';
import { Weblink, WeblinkVersion } from '@liquid-state/ubiquity-client/dist/v2/types';

export type IFetchWeblinks = ReturnType<typeof fetchWeblinks>;
export const fetchWeblinks = () => ({
  type: 'weblinks/fetch' as const,
  payload: {},
});

export type ILoadWeblinks = ReturnType<typeof loadWeblinks>;
export const loadWeblinks = () => ({
  type: 'weblinks/load' as const,
  payload: {},
});

export type IWeblinksLoaded = ReturnType<typeof weblinksLoaded>;
export const weblinksLoaded = (weblinks: Weblink[]) => ({
  type: 'weblinks/loaded' as const,
  payload: { weblinks },
});

export type IFetchWeblinkLatestVersion = ReturnType<typeof fetchWeblinkLatestVersion>;
export const fetchWeblinkLatestVersion = (weblinkId: string, force: boolean = false) => ({
  type: 'weblinks/fetch-latest-version' as const,
  payload: { id: weblinkId, force },
});

export type ILoadWeblinkLatestVersion = ReturnType<typeof loadWeblinkLatestVersion>;
export const loadWeblinkLatestVersion = (weblinkId: string) => ({
  type: 'weblinks/load-latest-version' as const,
  payload: { id: weblinkId },
});

export type IFetchWeblinkLatestVersionSuccess = ReturnType<typeof fetchWeblinkLatestVersionSuccess>;
export const fetchWeblinkLatestVersionSuccess = (weblinkId: string, version: any) => ({
  type: 'weblinks/fetch-latest-version-success' as const,
  payload: {
    id: weblinkId,
    version,
  },
});

export type IFetchWeblinkLatestVersionFailed = ReturnType<typeof fetchWeblinksLatestVersionFailed>;
export const fetchWeblinksLatestVersionFailed = (weblinkId: string, error: any) => ({
  type: 'weblinks/fetch-latest-version-failed' as const,
  payload: {
    id: weblinkId,
    error,
  },
});

export type IFetchWeblinksByIds = ReturnType<typeof fetchWeblinksByIds>;
export const fetchWeblinksByIds = (weblinkIds: string[]) => ({
  type: 'weblinks/fetch-by-ids' as const,
  payload: { weblinkIds },
});

export type IFetchWeblinksByIdsSuccess = ReturnType<typeof fetchWeblinksByIdsSuccess>;
export const fetchWeblinksByIdsSuccess = (weblinks: Weblink[]) => ({
  type: 'weblinks/fetch-by-ids-success' as const,
  payload: { weblinks },
});

export type IFetchWeblinksByIdsFailed = ReturnType<typeof fetchWeblinksByIdsFailed>;
export const fetchWeblinksByIdsFailed = () => ({
  type: 'weblinks/fetch-by-ids-failed' as const,
  payload: {},
});

export type IDeleteWeblinks = ReturnType<typeof deleteWeblinks>;
export const deleteWeblinks = (weblinkIds: string[]) => ({
  type: 'weblinks/delete' as const,
  payload: { weblinkIds },
});

export type IDeleteWeblinksSuccess = ReturnType<typeof deleteWeblinksSuccess>;
export const deleteWeblinksSuccess = (weblinkIds: string[]) => ({
  type: 'weblinks/delete-success' as const,
  payload: { weblinkIds },
});

export type IDeleteWeblinksFailed = ReturnType<typeof deleteWeblinksFailed>;
export const deleteWeblinksFailed = () => ({
  type: 'weblinks/delete-failed' as const,
  payload: {},
});

export type ICreateWeblink = ReturnType<typeof createWeblink>;
export const createWeblink = (weblink: ICreateWeblinkData) => ({
  type: 'weblinks/create' as const,
  payload: { weblink },
});

export type ICreateWeblinkSuccess = ReturnType<typeof createWeblinkSuccess>;
export const createWeblinkSuccess = (weblink: Weblink, version: WeblinkVersion) => ({
  type: 'weblinks/create-success' as const,
  payload: { weblink, version },
});

export type ICreateWeblinkFailed = ReturnType<typeof createWeblinkFailed>;
export const createWeblinkFailed = () => ({
  type: 'weblinks/create-failed' as const,
  payload: {},
});

export type IEditWeblink = ReturnType<typeof editWeblink>;
export const editWeblink = (id: string, weblink: ICreateWeblinkData) => ({
  type: 'weblinks/edit' as const,
  payload: { id, weblink },
});

export type IEditWeblinkSuccess = ReturnType<typeof editWeblinkSuccess>;
export const editWeblinkSuccess = (id: string, weblink: Weblink, version: WeblinkVersion) => ({
  type: 'weblinks/edit-success' as const,
  payload: { id, weblink, version },
});

export type IEditWeblinkFailed = ReturnType<typeof editWeblinkFailed>;
export const editWeblinkFailed = () => ({
  type: 'weblinks/edit-failed' as const,
  payload: {},
});
