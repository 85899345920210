import { take, spawn, call } from 'redux-saga/effects';

export default (actionType, saga) => {
  return spawn(waitForState, actionType, saga);
};

function* waitForState(actionType, saga) {
  const action = yield take(actionType);
  yield call(saga, action);
}
