import { IPathwaySnapshot } from '../pathways/types';

export type IFetchSharedPathwaySnapshots = ReturnType<typeof fetchSharedPathwaySnapshots>;
export const fetchSharedPathwaySnapshots = () => ({
  type: 'sharedPathways/fetchSharedPathwaySnapshots' as const,
  payload: {},
});

export type IFetchSharedPathwaySnapshotsSuccess = ReturnType<
  typeof fetchSharedPathwaySnapshotsSuccess
>;
export const fetchSharedPathwaySnapshotsSuccess = (snapshots: IPathwaySnapshot[]) => ({
  type: 'sharedPathways/fetchSharedPathwaySnapshotsSuccess' as const,
  payload: { snapshots },
});

export type IFetchSharedPathwaySnapshotsFailed = ReturnType<
  typeof fetchSharedPathwaySnapshotsFailed
>;
export const fetchSharedPathwaySnapshotsFailed = () => ({
  type: 'sharedPathways/fetchSharedPathwaySnapshotsFailed' as const,
  payload: {},
});

export type IUtiliseSharedPathwaySnapshot = ReturnType<typeof utiliseSharedPathwaySnapshot>;
export const utiliseSharedPathwaySnapshot = (
  snapshotId: number,
  indexEventTypes: { [key: string]: string },
) => ({
  type: 'sharedPathways/utiliseSharedPathwaySnapshot' as const,
  payload: { snapshotId, indexEventTypes },
});

export type IUtiliseSharedPathwaySnapshotSuccess = ReturnType<
  typeof utiliseSharedPathwaySnapshotSuccess
>;
export const utiliseSharedPathwaySnapshotSuccess = (snapshot: IPathwaySnapshot) => ({
  type: 'sharedPathways/utiliseSharedPathwaySnapshotSuccess' as const,
  payload: { snapshot },
});

export type IUtiliseSharedPathwaySnapshotFailed = ReturnType<
  typeof utiliseSharedPathwaySnapshotFailed
>;
export const utiliseSharedPathwaySnapshotFailed = () => ({
  type: 'sharedPathways/utiliseSharedPathwaySnapshotFailed' as const,
  payload: {},
});

export type IFetchSharedRulesWhatDetailSuccess = ReturnType<
  typeof fetchSharedRulesWhatDetailSuccess
>;
export const fetchSharedRulesWhatDetailSuccess = (
  sharedPathwayId: number,
  rules: { [key: number]: { [key: string]: any } },
) => ({
  type: 'sharedPathways/fetchSharedRulesWhatDetailSuccess' as const,
  payload: { sharedPathwayId, rules },
});

export type IFetchSharedEngagementChecks = ReturnType<typeof fetchSharedEngagementChecks>;
export const fetchSharedEngagementChecks = (sharedPathwayId: number) => ({
  type: 'sharedPathways/fetchSharedEngagementChecks' as const,
  payload: { sharedPathwayId },
});

export type IFetchSharedEngagementChecksSuccess = ReturnType<
  typeof fetchSharedEngagementChecksSuccess
>;
export const fetchSharedEngagementChecksSuccess = (
  sharedPathwayId: number,
  engagementChecks: any,
) => ({
  type: 'sharedPathways/fetchSharedEngagementChecksSuccess' as const,
  payload: { sharedPathwayId, engagementChecks },
});

export type IFetchSharedEngagementChecksFailed = ReturnType<
  typeof fetchSharedEngagementChecksFailed
>;
export const fetchSharedEngagementChecksFailed = (sharedPathwayId: number) => ({
  type: 'sharedPathways/fetchSharedEngagementChecksFailed' as const,
  payload: { sharedPathwayId },
});
