import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import { kemtaiPatientURL } from 'settings';
import i18n from '../../i18n';
import createKemtaiService, { Types } from './api';
import {
  ILoadStaffList,
  loadStaffListSuccess,
  loadStaffListFailure,
  ILoadPatientDetails,
  loadPatientDetailsSuccess,
  loadPatientDetailsFailure,
  ICreatePatient,
  createPatientSuccess,
  createPatientFailure,
  IViewPatientInKemtai,
} from './actions';
import { DEFAULT_API_KEY_MASKING_VALUE } from '../constants';

export default function*() {
  yield takeEvery<any>('kemtai/load-staff-list', doLoadStaffList);
  yield takeEvery<any>('kemtai/load-patient-details', doLoadPatientDetails);
  yield takeEvery<any>('kemtai/create-patient', doCreatePatient);
  yield takeEvery<any>('kemtai/view-patient-in-kemtai', doViewPatientInKemtai);
}

function* doLoadStaffList({ payload: { kemtaiAPIKey } }: ILoadStaffList): any {
  yield call(message.success, i18n.t('kemtai:organisationConfig.loadStaffListInProgress'));
  const apiKey = kemtaiAPIKey === DEFAULT_API_KEY_MASKING_VALUE ? undefined : kemtaiAPIKey;
  try {
    const service: Types.IKemtaiService = yield call(createKemtaiService);
    const result: Types.IKemtaiStaffMember[] = yield call(service.kemtaiStaffList, apiKey);
    if (result) {
      console.log('result: ', result);
      yield put(loadStaffListSuccess(result));
      yield call(message.success, i18n.t('kemtai:organisationConfig.loadStaffListSuccess'));
      // yield call(history.goBack);
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(loadStaffListFailure());
    // const data = yield call(err.response.json.bind(err.response))
    yield call(message.error, i18n.t('kemtai:organisationConfig.loadStaffListFailure'));
  }
}

function* doLoadPatientDetails({ payload: { appUserId } }: ILoadPatientDetails): any {
  yield call(message.success, i18n.t('kemtai:patient.loadPatientDetailsInProgress'));
  try {
    const service: Types.IKemtaiService = yield call(createKemtaiService);
    const result: Types.IKemtaiPatient = yield call(service.getPatient, appUserId);
    if (result) {
      console.log('result: ', result);
      yield put(loadPatientDetailsSuccess(result));
      //   yield call(message.success, i18n.t('kemtai:patient.loadPatientDetailsSuccess'));
      // yield call(history.goBack);
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(loadPatientDetailsFailure());
    if (err.response.status !== 404) {
      // const data = yield call(err.response.json.bind(err.response));
      yield call(message.error, i18n.t('kemtai:patient.loadPatientDetailsFailure'));
    }
  }
}

function* doCreatePatient({ payload: { appUserId } }: ICreatePatient): any {
  yield call(message.success, i18n.t('kemtai:patient.createPatientInProgress'));
  try {
    const service: Types.IKemtaiService = yield call(createKemtaiService);
    const result: Types.IKemtaiPatient = yield call(service.createPatient, appUserId);
    if (result) {
      console.log('result: ', result);
      yield put(createPatientSuccess(result));
      yield call(message.success, i18n.t('kemtai:patient.createPatientSuccess'));
      // yield call(history.goBack);
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(createPatientFailure());
    // const data = yield call(err.response.json.bind(err.response))
    yield call(message.error, i18n.t('kemtai:patient.createPatientFailure'));
  }
}

function doViewPatientInKemtai({ payload: { kemtaiPatient } }: IViewPatientInKemtai): any {
  const url = kemtaiPatientURL.replace('KEMTAI_ID', kemtaiPatient.kemtai_id);
  window.open(url);
}
