import { IRawRule, IRule } from './types';
import {
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
  TRANSITION_NEXT,
} from '../../../constants';

export function mapPathwaysWhatToDashboardWhat(
  what: string,
  whatDetail: { [key: string]: any },
): string {
  switch (what) {
    case FEATURE_DOCUMENT:
      return whatDetail.content_type === FEATURE_VIDEO ? FEATURE_VIDEO : what;
    case FEATURE_FORM:
      if (whatDetail.content_type === FEATURE_QUESTIONNAIRE) {
        return FEATURE_QUESTIONNAIRE;
      } else if (whatDetail.content_type === FEATURE_TASK) {
        return FEATURE_TASK;
      }
      return what;
    case MESSAGE:
      if (whatDetail.content_type === MESSAGE_ALERT) {
        return MESSAGE_ALERT;
      } else if (whatDetail.content_type === MESSAGE_REMINDER) {
        return MESSAGE_REMINDER;
      }
      return what;
    default:
      break;
  }
  return what;
}

export function mapDashboardWhatToPathwaysWhat(what: string): [string, string | null] {
  switch (what) {
    case FEATURE_VIDEO:
      return [FEATURE_DOCUMENT, FEATURE_VIDEO];
    case FEATURE_QUESTIONNAIRE:
      return [FEATURE_FORM, FEATURE_QUESTIONNAIRE];
    case FEATURE_TASK:
      return [FEATURE_FORM, FEATURE_TASK];
    case MESSAGE_ALERT:
      return [MESSAGE, MESSAGE_ALERT];
    case MESSAGE_REMINDER:
      return [MESSAGE, MESSAGE_REMINDER];
    case TRANSITION_NEXT:
      return [TRANSITION_NEXT, null];
    default:
      return [what, what];
  }
}

export function fixWhenTypeForDashboard(
  when: string,
  whenDetail: { [key: string]: any } | undefined,
) {
  if (whenDetail && [QUESTIONNAIRE_SUBMITTED, TASK_SUBMITTED].includes(whenDetail.whenType)) {
    return whenDetail.whenType;
  }

  return when;
}

export function fixWhenType(
  when: string,
  whenDetail: { [key: string]: any },
): [string, { [key: string]: any }] {
  switch (when) {
    case QUESTIONNAIRE_SUBMITTED:
    case TASK_SUBMITTED:
      whenDetail.whenType = when;
      when = FORM_SUBMITTED;
      break;
    default:
      break;
  }

  return [when, whenDetail];
}

export const mapRawRule = ({
  what_detail: whatDetail,
  when_detail: whenDetail,
  who_detail: whoDetail,
  metadata,
  when,
  owner_id: ownerId,
  audience_type: audienceType,
  ...rule
}: IRawRule): IRule => ({
  ...rule,
  metadata: metadata || { language: 'en' },
  whatDetail,
  whenDetail,
  whoDetail,
  what: mapPathwaysWhatToDashboardWhat(rule.what, whatDetail),
  when: fixWhenTypeForDashboard(when, whenDetail),
  ownerId,
  audienceType,
});
