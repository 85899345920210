import React from 'react';
import { node, oneOfType, string } from 'prop-types';
import { Form } from 'antd';
import './style.less';

function DetailRow({ className, label, value }) {
  return (
    <Form.Item className={`detail-row ${className}`} label={label}>
      <span className="ant-form-text">{value}</span>
    </Form.Item>
  );
}

DetailRow.propTypes = {
  className: string,
  label: oneOfType([node, string]).isRequired,
  value: oneOfType([node, string]),
};

DetailRow.defaultProps = {
  className: '',
  value: '',
};

export default DetailRow;
