import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_PATHWAY_TO_APP_USERS,
  FETCH_APP_USERS_PATHWAYS,
  FETCH_APP_USERS_JOURNEYS,
  PROCESS_APP_USER_PATHWAY_NOW,
  SET_APP_USER_PATHWAY_STAGE,
  SET_APP_USER_PATHWAY_ARCHIVED_STATUS,
  EDIT_APP_USER_JOURNEY,
  TRIGGER_ADHOC_RULE,
  ACTION_JOURNEY_ENTRY,
} from '../types';
import { INVITE_APP_USER_SUCCESS } from '@redux/appUsers/types';
import takeFirst from '@redux/takeFirst';

import {
  doAddPathwayToAppUsers,
  doInviteAppUserWithPathways,
  doProcessAppUserPathwayNow,
  doSetAppUserPathwayStage,
  doSetAppUserPathwayArchivedStatus,
  doEditAppUserJourney,
  doTriggerAdhocRule,
  doActionAppUserJourneyEntry,
} from './manage';
import { doFetchAppUserJourneys, doFetchAppUsersPathways } from './fetch';

export default function* root() {
  yield takeFirst(FETCH_APP_USERS_PATHWAYS, doFetchAppUsersPathways);
  yield takeEvery(FETCH_APP_USERS_JOURNEYS, doFetchAppUserJourneys);
  yield takeEvery(ADD_PATHWAY_TO_APP_USERS, doAddPathwayToAppUsers);
  yield takeEvery(PROCESS_APP_USER_PATHWAY_NOW, doProcessAppUserPathwayNow);
  yield takeLatest(SET_APP_USER_PATHWAY_STAGE, doSetAppUserPathwayStage);
  yield takeEvery(INVITE_APP_USER_SUCCESS, doInviteAppUserWithPathways);
  yield takeEvery(SET_APP_USER_PATHWAY_ARCHIVED_STATUS, doSetAppUserPathwayArchivedStatus);
  yield takeEvery(EDIT_APP_USER_JOURNEY, doEditAppUserJourney);
  yield takeEvery(TRIGGER_ADHOC_RULE, doTriggerAdhocRule);
  yield takeEvery(ACTION_JOURNEY_ENTRY, doActionAppUserJourneyEntry);
}
