import React from 'react';
import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import AttachedContent from '@cards/Messages/AttachedContent';
import Details from '@cards/Messages/Details';
import useWizard from '@components/Wizard/useWizard';
import { messageTypes } from '@utils/contentTypes';
import Timing from '../Timing/Summary';
import Audience from './Audience';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';

function getPayloadOptionsData(details) {
  switch (details.action) {
    case OPEN_CONTENT:
      return details.contentData;
    case OPEN_LINK:
      return details.websiteLink;
    default:
      return null;
  }
}

function Review({ messageType }) {
  const { t } = useTranslation();
  const { formData } = useWizard();

  const message = {
    metadata: {
      type: messageType,
      language: formData.details?.language || 'en',
    },
    title: formData.details.title,
    content: formData.details.content,
    payloadOptions: {
      data: getPayloadOptionsData(formData.details),
      action: formData.details.action,
    },
  };

  return (
    <ColumnGroup>
      <Column>
        <Details message={message} />
        <Timing
          dateSent={formData.timing.dateSent}
          frequency={formData.timing.frequency}
          repeatEvery={formData.timing.repeatEvery}
          repeatStops={formData.timing.repeatStops}
          title={t('messages:Wizard.timing.stepName')}
          when={formData.timing.when}
        />
        {message.payloadOptions.action === 'open-content' ? (
          <AttachedContent content={formData.details.contentData} />
        ) : null}
      </Column>
      <Column>
        <Audience groups={formData.audience?.groups} individuals={formData.audience?.individuals} />
      </Column>
    </ColumnGroup>
  );
}

Review.propTypes = {
  messageType: oneOf(messageTypes).isRequired,
};

export default Review;
