import React from 'react';
import { Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';

import './style.less';

const HeaderButton = ({ className, tooltip, tooltipPlacement, icon, children, ...props }) => (
  <span className={`header-button ${className}`}>
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <Button ghost type="primary" {...props}>
        {children}
      </Button>
    </Tooltip>
  </span>
);

HeaderButton.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

HeaderButton.defaultProps = {
  className: '',
  tooltip: undefined,
  tooltipPlacement: 'bottom',
};

export default HeaderButton;
