import { combineReducers } from 'redux';
import {
  ILoadMetrics,
  ILoadMetricsFailed,
  IGlobalMetricsLoaded,
  ICountryMetricsLoaded,
  IHospitalMetricsLoaded,
  IPathwayMetricsLoaded,
} from './actions';
import { IMetrics } from './types';

function country(state: { [key: string]: IMetrics } = {}, action: ICountryMetricsLoaded) {
  switch (action.type) {
    case 'metrics/country-loaded':
      return { ...state, [action.payload.countryId]: action.payload.metrics };
    default:
      return state;
  }
}

function global(state: IMetrics | null = null, action: IGlobalMetricsLoaded) {
  switch (action.type) {
    case 'metrics/global-loaded':
      return action.payload.metrics;
    default:
      return state;
  }
}

function hospital(state: { [key: string]: IMetrics } = {}, action: IHospitalMetricsLoaded) {
  switch (action.type) {
    case 'metrics/hospital-loaded':
      return { ...state, [action.payload.hospitalId]: action.payload.metrics };
    default:
      return state;
  }
}

function loading(
  state = false,
  action:
    | ILoadMetrics
    | IGlobalMetricsLoaded
    | ILoadMetricsFailed
    | ICountryMetricsLoaded
    | IHospitalMetricsLoaded
    | IPathwayMetricsLoaded,
) {
  switch (action.type) {
    case 'metrics/load':
      return true;
    case 'metrics/load-failed':
    case 'metrics/global-loaded':
    case 'metrics/country-loaded':
    case 'metrics/hospital-loaded':
    case 'metrics/pathway-loaded':
      return false;
    default:
      return state;
  }
}

function pathways(
  state: { byId: { [key: string]: IMetrics }; created: string; list: number[] } = {
    byId: {},
    created: '',
    list: [],
  },
  action: IPathwayMetricsLoaded,
) {
  switch (action.type) {
    case 'metrics/pathway-loaded':
      return action.payload.metrics;
    default:
      return state;
  }
}

export default combineReducers({ country, global, hospital, loading, pathways });
