import ContentRef from './contentRef';
import { PEPContentTypes } from './contentTypes';

interface IContentIdentifier {
  content_type: string;
  content_id: string;
}

export const getContentItemsRepresentationDetails = (
  contentItems: IContentIdentifier[],
  allContent: any[],
): any[] => {
  const details = contentItems?.reduce((acc: any[], contentItem) => {
    const matchingContent = allContent?.find(
      (content: any) =>
        content.product_id === contentItem.content_id ||
        content.id === contentItem.content_id ||
        content.uuid === contentItem.content_id,
    );

    if (matchingContent) {
      acc.push({
        ...contentItem,
        ...getBasicContentDetails(matchingContent),
      });
    }

    return acc;
  }, []);
  return details;
};

export const getBasicContentDetails = (
  content: any,
): {
  content_name: string;
  content_type: PEPContentTypes;
  id: string;
  content_ref: ContentRef | undefined;
} => {
  const contentType =
    content.content_type ||
    content.latestVersion?.metadata?.type ||
    content.metadata?.type ||
    content.type ||
    (content.metadata?.tags?.some((tag: string) => Object.values(tag).includes('VIDEO'))
      ? 'VIDEO'
      : 'DOCUMENT');
  return {
    content_name: content.name,
    content_type: contentType,
    id: content.id || content.uuid,
    content_ref: content.content ? ContentRef.fromString(content.content) : undefined,
  };
};
