import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.less';

function None() {
  const { t } = useTranslation();

  return <span className="none-container">{t('common:none')}</span>;
}

export default None;
