import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { ColumnGroup, Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import MessagePathwaysCard from '@pathways/cards/MessagePathways';
import MessageDetail from '@cards/CannedMessages/MessagesDetails';
import MessageHistoryCard from '@cards/CannedMessages/MessageHistory';
import AttachedContent from '@cards/Messages/AttachedContent';
import ContentNotFound from '@components/ContentNotFound';
import { selectContentMessagePublishing } from '@redux/contentMessages/selectors';
import {
  deleteContentMessages,
  publishContentMessage,
  unpublishContentMessage,
} from '@redux/contentMessages/actions';
import useContentRefs, { useContentRefFromUrl } from '@hooks/useContentRefs';
import { selectContentMessageDeleting } from '@redux/contentMessages/selectors';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { MESSAGE } from '@constants';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import PublishingButton from '@components/PublishingButton';
import { OPEN_CONTENT } from '@constants';
import useIsContentOwnedByCurrentOrganisation from '@hooks/useIsContentOwnedByCurrentOrganisation';

export default ({
  match: {
    params: { id: messageId },
  },
  messageTranslationKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();

  const [loading, message, contentRef] = useContentRefFromUrl(messageId);

  const [, pathways] = useSelector(
    selectPathwaysByRules(
      rule =>
        rule.what === MESSAGE &&
        rule.whatDetail.content &&
        contentRef.matches(rule.whatDetail.content, { ignoreVersion: true }),
    ),
  );
  const isPublishing = useSelector(selectContentMessagePublishing);

  const { setTitle, setActions } = usePageActions({
    showBack: true,
  });

  const isOwnedContent = useIsContentOwnedByCurrentOrganisation(message);
  const payloadContentId = message?.latestVersion?.payload?.data?.content;
  const { [payloadContentId]: payloadContent } = useContentRefs(
    payloadContentId ? [payloadContentId] : undefined,
  );

  useEffect(() => {
    document.title = message && message.name;
    setTitle(message ? message.name : undefined);
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActions([
      <PublishingButton
        key="toggle-published-status"
        isPublished={message?.published}
        isPublishing={isPublishing}
        publish={() => dispatch(publishContentMessage(message?.uuid, messageTranslationKey))}
        unpublish={() => dispatch(unpublishContentMessage(message?.uuid, messageTranslationKey))}
        disabled={!isOwnedContent}
      />,
      <IconButton
        key="delete-button"
        tooltip={t('common:buttons.delete')}
        icon={faTrashAlt}
        size="large"
        disabled={!isOwnedContent}
        onClick={() => {
          Modal.confirm({
            content: pathways.length
              ? t(`${messageTranslationKey}:MessageDetail.confirmDeleteWarning`, {
                  count: pathways.length,
                })
              : null,
            title: t(`${messageTranslationKey}:MessageDetail.confirmDelete`),
            onOk: async () => {
              dispatch(deleteContentMessages([message?.uuid], messageTranslationKey));
              await waitForStoreUpdate(store, selectContentMessageDeleting); // must return for modal to be dismissed
              history.goBack();
            },
            onCancel: () => {},
          });
        }}
      />,
      <EditButton
        key="edit-button"
        tooltip={t(`${messageTranslationKey}:MessageDetail.editButton`)}
        onClick={() => history.push(`edit/${messageId}`)}
        disabled={!isOwnedContent}
      />,
    ]);
  }, [
    dispatch,
    history,
    isPublishing,
    message,
    messageId,
    messageTranslationKey,
    setActions,
    store,
    t,
    pathways.length,
    isOwnedContent,
  ]);

  if (!message && !loading) {
    return <ContentNotFound />;
  }

  return (
    <ColumnGroup>
      <Column>
        <MessageDetail
          messageId={message?.uuid}
          title="cards:MessageDetail.title"
          messageTranslationKey={messageTranslationKey}
          payloadContent={payloadContent}
        />
        <MessagePathwaysCard
          messageId={message?.uuid}
          messageTranslationKey={messageTranslationKey}
        />
      </Column>
      <Column>
        {message?.latestVersion.payload?.action === OPEN_CONTENT && (
          <AttachedContent content={payloadContent} />
        )}
        <MessageHistoryCard messageId={message?.uuid} />
      </Column>
    </ColumnGroup>
  );
};
