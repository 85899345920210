import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { selectOrganisation } from '@redux/organisations/reducers';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { setCurrentScreen, screens } from '../../../analytics';

const OrganisationDetails = ({ history, match }) => {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_HOSPITAL_DETAILS);
  }, []);

  const { t } = useTranslation();
  // const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const [loading, organisation] = useSelector(selectOrganisation());

  const contactDetailsFields = [
    'organisation_name',
    'email',
    'phone_number',
    'website',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'suburb',
    'state',
    'postcode',
  ];

  return (
    <ColumnGroup>
      <Column>
        <Card
          className="organisation-details-card"
          loading={loading}
          title={t('organisations:OrganisationDetails.contact-details.card-title')}
        >
          {!organisation ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <DetailForm>
              {contactDetailsFields.map(field => (
                <DetailRow
                  key={field}
                  label={t(
                    `organisations:OrganisationDetails.contact-details.${field.replace(/_/g, '')}`,
                  )}
                  value={organisation[`updated_${field}`] || organisation[field]}
                ></DetailRow>
              ))}
              {/* <DetailRow
                key="open-hours"
                label={t('organisations:OrganisationDetails.contact-details.open-hours')}
                value={organisation?.updated_weekly_opening_hours || organisation?.weekly_opening_hours}
              ></DetailRow> */}
              {/* <DetailRow
                key="phone"
                label={t('organisations:OrganisationDetails.contact-details.phone')}
                value={organisation?.contactDetails.phone}
              ></DetailRow>
              <DetailRow
                key="address"
                label={t('organisations:OrganisationDetails.contact-details.address')}
                value={organisation?.contactDetails.address}
              ></DetailRow>
              <DetailRow
                key="second-line-address"
                label={t('organisations:OrganisationDetails.contact-details.second-line-address')}
                value={organisation?.contactDetails.addressSecondLine}
              ></DetailRow>
              <DetailRow
                key="type"
                label={t('organisations:OrganisationDetails.contact-details.type')}
                value={
                  organisation?.contactDetails.type
                    ? t(
                        `organisations:OrganisationDetails.contact-details.type-${organisation?.contactDetails.type}`,
                      )
                    : ''
                }
              ></DetailRow> */}
            </DetailForm>
          )}
        </Card>
        {/* <Card
          className="organisation-social-details-card"
          loading={loading}
          title={t('organisations:OrganisationDetails.social-media.card-title')}
        >
          {organisation === undefined || !Array.isArray(organisation?.socialMedia) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <DetailForm className="detail-form">
              {Array.isArray(organisation.socialMedia) &&
                organisation.socialMedia.map((sm, index) => (
                  <DetailRow
                    key={index}
                    label={t(`organisations:OrganisationDetails.social-media.${sm.type}`)}
                    value={
                      <a href={sm.link} target="_blank" rel="noopener noreferrer">
                        {sm.link}
                      </a>
                    }
                  />
                ))}
            </DetailForm>
          )}
        </Card> */}
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default OrganisationDetails;
