export const CHANGE_PASSWORD = 'CHANGE_PASSWORD' as const;
export const CHANGE_PASSWORD_COMPLETE = 'CHANGE_PASSWORD_COMPLETE' as const;
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR' as const;
export const PROFILE_REFRESH_TERMS_OF_USE = 'PROFILE_REFRESH_TERMS_OF_USE' as const;

export const EDIT_MY_PROFILE = 'EDIT_MY_PROFILE' as const;
export const EDIT_MY_PROFILE_SUCCESS = 'EDIT_MY_PROFILE_SUCCESS' as const;
export const EDIT_MY_PROFILE_ERROR = 'EDIT_MY_PROFILE_ERROR' as const;

export type EditProfileData = {
  mfaPreference: 'EMAIL_OTP' | 'SMS_MFA';
};
