import { put, call, select, takeEvery, take } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../i18n';
import {
  loadConnectionCodesFailed,
  loadConnectionCodesSucceeded,
  ICreateConnectionCode,
  createConnectionCodeSucceeded,
  IUpdateConnectionCodePathways,
  createConnectionCodeFailed,
  updateConnectionCodePathwaysSucceeded,
  updateConnectionCodePathwaysFailed,
  setConnectionCodeAsDefaultSucceeded,
  setConnectionCodeAsDefaultFailed,
  IToggleConnectionCodeIsDisabled,
  toggleConnectionCodeIsDisabledSucceeded,
  toggleConnectionCodeIsDisabledFailed,
} from './actions';
import { ConnectionCode, ConnectionCodeAPIResult } from './types';
import { fetchHospital } from '../hospitals/actions';
import { selectHospital } from '../hospitals/reducers';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
// import { take } from 'lodash';

function mapAPIResultToType(
  apiResult: ConnectionCodeAPIResult,
  orgDefaultConnectionCode?: string,
): ConnectionCode {
  return {
    id: apiResult.pk,
    isDisabled: apiResult.is_disabled,
    isDefault: apiResult.code === orgDefaultConnectionCode,
    code: apiResult.code,
    pathwayIds: apiResult.data.pathways || [],
    created: new Date(apiResult.created),
    modified: new Date(apiResult.modified),
  };
}

export default function* connectionCodesRoot() {
  yield takeEvery('connectionCodes/load', doLoadConnectionCodes);
  yield takeEvery('connectionCodes/create', doCreateConnectionCode);
  yield takeEvery('connectionCodes/updatePathways', doUpdateConnectionCodePathways);
  yield takeEvery('connectionCodes/setDefault', doSetConnectionCodeAsDefault);
  yield takeEvery('connectionCodes/toggleDisabled', doToggleConnectionCodeIsDisabled);
}

function* doLoadConnectionCodes(): any {
  try {
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);

    let apiResults: any[] = [];
    let page = 0;
    let next = true;
    while (next) {
      page += 1;
      const data = yield call(client.getConnectionCodes, { page });
      if (data.count) {
        apiResults = apiResults.concat(data.results);
      }
      next = data.next;
    }

    // ensure Org details are loaded from directory so we know what the default code is
    yield put(fetchHospital());
    yield take('hospital/load-success');
    const [, org] = yield select(selectHospital());

    const defaultCode = org.default_code;
    const codes = apiResults.map(result => mapAPIResultToType(result, defaultCode));
    yield put(loadConnectionCodesSucceeded(codes));
  } catch (err) {
    console.error(err);
    yield put(loadConnectionCodesFailed());
    yield call(message.error, i18n.t('connectionCodes:Details.loadError'));
  }
}

function* doCreateConnectionCode({ payload: { pathwayIds } }: ICreateConnectionCode): any {
  try {
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    const apiResult = yield call(client.createConnectionCode, { data: { pathways: pathwayIds } });

    // ensure Org details are loaded from directory so we know what the default code is
    yield put(fetchHospital());
    yield take('hospital/load-success');
    const [, org] = yield select(selectHospital());

    const defaultCode = org.default_code;
    const code = mapAPIResultToType(apiResult, defaultCode);
    yield put(createConnectionCodeSucceeded(code));
  } catch (err) {
    yield put(createConnectionCodeFailed());
  }
}

function* doUpdateConnectionCodePathways({
  payload: { codeId, pathwayIds },
}: IUpdateConnectionCodePathways): any {
  try {
    const code = yield select(state => state.connectionCodes.byId[codeId]);
    code.pathwayIds = pathwayIds;

    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    yield call(client.updateConnectionCodeData, code.id, { data: { pathways: pathwayIds } });

    yield put(updateConnectionCodePathwaysSucceeded(code));
  } catch (err) {
    console.error(err);
    yield put(updateConnectionCodePathwaysFailed());
  }
}

function* doSetConnectionCodeAsDefault({
  payload: { codeId },
}: IUpdateConnectionCodePathways): any {
  try {
    const currentDefaultCC = yield select(state => {
      const ccs: ConnectionCode[] = Object.values(state.connectionCodes.byId);
      return ccs.find(cc => cc.isDefault);
    });
    const newDefaultCC = yield select(state => state.connectionCodes.byId[codeId]);
    if (currentDefaultCC) {
      currentDefaultCC.isDefault = false;
    }
    newDefaultCC.isDefault = true;

    const org = (yield select(selectHospital()))[1];
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    yield call(client.setConnectionCodeAsDefault, org.id, newDefaultCC.code);

    yield put(
      setConnectionCodeAsDefaultSucceeded(
        [currentDefaultCC, newDefaultCC].filter(Boolean) as ConnectionCode[],
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(setConnectionCodeAsDefaultFailed());
  }
}

function* doToggleConnectionCodeIsDisabled({
  payload: { codeId },
}: IToggleConnectionCodeIsDisabled): any {
  try {
    const code: ConnectionCode = yield select(state => state.connectionCodes.byId[codeId]);
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    if (code.isDisabled) {
      yield call(client.enableConnectionCode, code.id);
    } else {
      yield call(client.disableConnectionCode, code.id);
    }
    code.isDisabled = !code.isDisabled;

    yield put(toggleConnectionCodeIsDisabledSucceeded(code));
  } catch (err) {
    console.error(err);
    yield put(toggleConnectionCodeIsDisabledFailed());
  }
}
