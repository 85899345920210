import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useHasPermissions } from '@authorisation/hooks';
import PathwayList from './PathwayList';
import SharedPathwaysList from './SharedPathwaysList';
import RuleList from './RuleList';
import EditPathway from './EditPathway';
import NewPathway from './NewPathway';
import NewRule from './NewRule';
import EditRule from './EditRule';
import RuleDetails from './RuleDetails';
import EngagementCheckDetails from './EngagementCheckDetails';
import PathwayDetails from './PathwayDetails';
import NewEngagementCheck from './NewEngagementCheck';
import EditEngagementCheck from './EditEngagementCheck';
import ContentUpdates from './ContentUpdates';
import DuplicateSharedPathway from './DuplicateSharedPathway';
import { Permissions } from '@authorisation/constants';

export default ({ match }) => {
  const perms = useHasPermissions();

  return (
    <Switch>
      {perms[Permissions.CreateRule] && (
        <Route path={`${match.path}/rules/new`} component={NewRule} />
      )}
      <Route path={`${match.path}/rules/:id/edit`} component={EditRule} />
      <Route path={`${match.path}/rules/:id/:pathwayId`} component={RuleDetails} />
      <Route path={`${match.path}/rules/:id`} component={RuleDetails} />
      <Route path={`${match.path}/rules`} component={RuleList} />

      <Route
        path={`${match.path}/pathways/:id/edit-engagement-check/:engagementCheckId/shared`}
        component={EditEngagementCheck}
      />
      <Route
        path={`${match.path}/pathways/:id/engagement-check/:engagementCheckId/shared`}
        component={EngagementCheckDetails}
      />
      <Route
        path={`${match.path}/pathways/:id/edit-engagement-check/:engagementCheckId`}
        component={EditEngagementCheck}
      />
      <Route
        path={`${match.path}/pathways/:id/engagement-check/:engagementCheckId`}
        component={EngagementCheckDetails}
      />
      <Route
        path={`${match.path}/pathways/:id/add-engagement-check`}
        component={NewEngagementCheck}
      />
      {perms[Permissions.CreatePathway] && (
        <Route path={`${match.path}/pathways/new`} component={NewPathway} />
      )}
      <Route path={`${match.path}/pathways/:id/:snapshot/edit`} component={EditPathway} />
      <Route
        path={`${match.path}/pathways/:id/:snapshot/manage-content-updates`}
        component={ContentUpdates}
      />
      <Route path={`${match.path}/pathways/:id/edit`} component={EditPathway} />
      <Route
        path={`${match.path}/pathways/:id/manage-content-updates`}
        component={ContentUpdates}
      />
      <Route
        path={`${match.path}/shared-pathways/:id/duplicate`}
        component={DuplicateSharedPathway}
      />
      <Route path={`${match.path}/shared-pathways/:id`} component={PathwayDetails} />
      <Route path={`${match.path}/shared-pathways`} component={SharedPathwaysList} />
      <Route path={`${match.path}/pathways/:id/:snapshot`} component={PathwayDetails} />
      <Route path={`${match.path}/pathways/:id`} component={PathwayDetails} />
      <Route path={`${match.path}/pathways`} component={PathwayList} />
      <Route path={match.path} render={() => <Redirect to={`${match.path}/pathways`} />} />
    </Switch>
  );
};
