import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input } from 'antd';
import { Card, ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import DetailForm from '@components/DetailForm';

const agreementValidator = (_, value, callback) => {
  if (value === true) {
    callback();
    return;
  }
  callback(value);
};

function Content() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <ColumnGroup>
      <Column>
        <Card className="weblink-wizard-card" title={t('weblinks:Wizard.content.stepName')}>
          <DetailForm>
            <Form.Item label={t('weblinks:Wizard.content.labels.url')}>
              {form.getFieldDecorator('url', {
                initialValue: currentStepData.url,
                rules: [
                  { required: true },
                  { pattern: /http/, message: t('weblinks:Wizard.content.validation.url') },
                ],
              })(<Input placeholder={t('weblinks:Wizard.content.placeholders.url')} />)}
            </Form.Item>
            <p className="text-color-secondary">{t('weblinks:Wizard.content.explainer')}</p>
          </DetailForm>
        </Card>
        <Card className="weblink-wizard-card" title={t('weblinks:Wizard.content.agreements.title')}>
          <DetailForm>
            <Form.Item>
              {form.getFieldDecorator('responsibility', {
                initialValue: currentStepData.responsibility,
                rules: [
                  { message: t('weblinks:Wizard.content.agreements.validation'), required: true },
                  {
                    message: t('weblinks:Wizard.content.agreements.validation'),
                    validator: agreementValidator,
                  },
                ],
                valuePropName: 'checked',
              })(<Checkbox>{t('weblinks:Wizard.content.agreements.responsibility')}</Checkbox>)}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator('gdpr', {
                initialValue: currentStepData.gdpr,
                rules: [
                  { message: t('weblinks:Wizard.content.agreements.validation'), required: true },
                  {
                    message: t('weblinks:Wizard.content.agreements.validation'),
                    validator: agreementValidator,
                  },
                ],
                valuePropName: 'checked',
              })(<Checkbox>{t('weblinks:Wizard.content.agreements.gdpr')}</Checkbox>)}
            </Form.Item>
          </DetailForm>
        </Card>
      </Column>
      <Column />
    </ColumnGroup>
  );
}

export default Content;
