import { call, put, getContext, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { Flag } from '@liquid-state/uis-client/dist/types';
import doCreateUISAdminClient from '@redux/doCreateUISAdminClient';
import UISAdminClient from '@liquid-state/uis-client';
import i18n from '../../i18n';
import {
  fetchFlags,
  loadFlags,
  flagsLoaded,
  loadFlagsFailed,
  ICreateFlag,
  createFlagFailed,
  createFlagSuccess,
  IEditFlag,
  editFlagFailed,
  editFlagSuccess,
  IMoveFlag,
  moveFlagSuccess,
  // moveFlagFailed,
} from './actions';
// import mockFlags from './mock';

export default function* root() {
  yield takeLatest('flags/fetch', doFetchFlags);
  yield takeEvery('flags/createFlag', doCreateFlag);
  yield takeEvery('flags/editFlag', doEditFlag);
  yield takeEvery('flags/moveFlag', doMoveFlag);
}

export function* doFetchFlags() {
  yield put(loadFlags());

  try {
    const uisClient: UISAdminClient = yield call(doCreateUISAdminClient);
    let flags: Flag[] = yield call(uisClient.listFlags);
    // const flags = mockFlags

    yield put(flagsLoaded(flags));
  } catch (err) {
    console.error(err);
    yield put(loadFlagsFailed());
    yield call(message.error, i18n.t('flags:List.fetchFailed'));
  }
}

function* doCreateFlag({ payload: { data } }: ICreateFlag): Generator<any, void, any> {
  try {
    const history = yield getContext('history');
    const uisClient: UISAdminClient = yield call(doCreateUISAdminClient);
    const flag: Flag = yield call(uisClient.createFlag, data);

    yield put(createFlagSuccess(flag));
    yield call(history.push, '/administration/flags');
    yield call(message.success, i18n.t('flags:Wizard.create.success'));
  } catch (err) {
    console.error(err);
    yield put(createFlagFailed());
    yield call(message.error, i18n.t('flags:Wizard.create.failed'));
  }
}

function* doEditFlag({ payload: { uuid, data } }: IEditFlag): Generator<any, void, any> {
  try {
    const history = yield getContext('history');
    const uisClient: UISAdminClient = yield call(doCreateUISAdminClient);
    const editedFlag = yield call(uisClient.updateFlag, uuid, data);

    yield put(editFlagSuccess(editedFlag));
    yield call(history.push, `/administration/flags/${uuid}`);
    yield call(message.success, i18n.t('flags:Wizard.edit.success'));
  } catch (err) {
    console.error(err);
    yield put(editFlagFailed());
    yield call(message.error, i18n.t('flags:Wizard.edit.failed'));
  }
}

function* doMoveFlag({ payload: { uuid, direction } }: IMoveFlag): Generator<any, void, any> {
  try {
    const uisClient: UISAdminClient = yield call(doCreateUISAdminClient);
    if (direction === 'up') {
      yield call(uisClient.moveFlagUp, uuid);
    } else {
      yield call(uisClient.moveFlagDown, uuid);
    }

    yield put(fetchFlags());
    yield put(moveFlagSuccess());
    yield call(message.success, i18n.t('flags:Wizard.move.success'));
  } catch (err) {
    console.error(err);
    yield put(editFlagFailed());
    yield call(message.error, i18n.t('flags:Wizard.move.failed'));
  }
}
