import { combineReducers } from 'redux';
import {
  mapUserTypeToRoleURI as mapUserTypeToRoleURIInitialState,
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
  HOSPITAL_ADMIN_URI,
  HOSPITAL_USER_URI,
  SUPER_ADMIN_URI,
  SUPPORT_ADMIN_URI,
  SYSTEM_ADMIN_URI,
} from '@constants';
import {
  LoadDashboardUsers,
  DashboardUsersLoaded,
  LoadDashboardUsersFailed,
  ToggleDashboardUserActivationSuccess,
  ToggleDashboardUserActivation,
  ToggleDashboardUserActivationFailed,
  EditDashboardUser,
  EditDashboardUserFailed,
  EditDashboardUserSuccess,
  CreateDashboardUser,
  CreateDashboardUserFailed,
  CreateDashboardUserSuccess,
  ResendDashboardUserInvite,
  ResendDashboardUserInviteFailed,
  ResendDashboardUserInviteSuccess,
} from './actions';
import { DashboardUser } from './types';
import { extractOrganisationSlug, replaceOrganisationSlug } from './utils';
import { IState } from '../reducer';
import { ISetAppDetails } from '@organisation/redux/actions';

function activating(
  state = false,
  action:
    | ToggleDashboardUserActivation
    | ToggleDashboardUserActivationSuccess
    | ToggleDashboardUserActivationFailed,
) {
  switch (action.type) {
    case 'dashboardUsers/toggleActivation':
      return true;
    case 'dashboardUsers/toggleActivationFailed':
    case 'dashboardUsers/toggleActivationSuccess':
      return false;
    default:
      return state;
  }
}

function byId(
  state: { [key: string]: DashboardUser } = {},
  action:
    | DashboardUsersLoaded
    | ToggleDashboardUserActivationSuccess
    | EditDashboardUserSuccess
    | CreateDashboardUserSuccess
    | ResendDashboardUserInviteSuccess,
): { [key: string]: DashboardUser } {
  switch (action.type) {
    case 'dashboardUsers/loaded':
      return action.payload.dashboardUsers.reduce(
        (usersById, user) => ({ ...usersById, [user.id]: user }),
        {},
      );
    case 'dashboardUsers/toggleActivationSuccess':
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], activated: action.payload.activated },
      };
    case 'dashboardUsers/editSuccess':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.editedUser,
          name: `${action.payload.editedUser.firstName} ${action.payload.editedUser.lastName}`,
        },
      };
    case 'dashboardUsers/createSuccess':
      return {
        ...state,
        [action.payload.dashboardUser.id]: action.payload.dashboardUser,
      };
    case 'dashboardUsers/resendInviteSuccess':
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          invitations: [action.payload.inviteId],
        },
      };
    default:
      return state;
  }
}

function creating(
  state = false,
  action: CreateDashboardUser | CreateDashboardUserFailed | CreateDashboardUserSuccess,
) {
  switch (action.type) {
    case 'dashboardUsers/create':
      return true;
    case 'dashboardUsers/createFailed':
    case 'dashboardUsers/createSuccess':
      return false;
    default:
      return state;
  }
}

function editing(
  state = false,
  action: EditDashboardUser | EditDashboardUserFailed | EditDashboardUserSuccess,
) {
  switch (action.type) {
    case 'dashboardUsers/edit':
      return true;
    case 'dashboardUsers/editFailed':
    case 'dashboardUsers/editSuccess':
      return false;
    default:
      return state;
  }
}

function list(
  state: string[] = [],
  action: DashboardUsersLoaded | CreateDashboardUserSuccess,
): string[] {
  switch (action.type) {
    case 'dashboardUsers/loaded':
      return action.payload.dashboardUsers.map(({ id }) => id);
    case 'dashboardUsers/createSuccess':
      return [action.payload.dashboardUser.id, ...state];
    default:
      return state;
  }
}

function loading(
  state = false,
  action: LoadDashboardUsers | DashboardUsersLoaded | LoadDashboardUsersFailed,
): boolean {
  switch (action.type) {
    case 'dashboardUsers/load':
      return true;
    case 'dashboardUsers/loaded':
    case 'dashboardUsers/loadFailed':
      return false;
    default:
      return state;
  }
}

function mapUserTypeToRoleURI(
  state: { [key: string]: string } = mapUserTypeToRoleURIInitialState,
  action: ISetAppDetails,
) {
  switch (action.type) {
    case 'organisation/set-app-details':
      return {
        [HOSPITAL_ADMIN]: HOSPITAL_ADMIN_URI.replace(
          extractOrganisationSlug,
          replaceOrganisationSlug(action.payload.appDetails.organisationSlug),
        ),
        [HOSPITAL_USER]: HOSPITAL_USER_URI.replace(
          extractOrganisationSlug,
          replaceOrganisationSlug(action.payload.appDetails.organisationSlug),
        ),
        [SUPER_ADMIN]: SUPER_ADMIN_URI.replace(
          extractOrganisationSlug,
          replaceOrganisationSlug(action.payload.appDetails.organisationSlug),
        ),
        [SUPPORT_ADMIN]: SUPPORT_ADMIN_URI.replace(
          extractOrganisationSlug,
          replaceOrganisationSlug(action.payload.appDetails.organisationSlug),
        ),
        [SYSTEM_ADMIN]: SYSTEM_ADMIN_URI.replace(
          extractOrganisationSlug,
          replaceOrganisationSlug(action.payload.appDetails.organisationSlug),
        ),
      };
    default:
      return state;
  }
}

function resendingInvite(
  state = false,
  action:
    | ResendDashboardUserInvite
    | ResendDashboardUserInviteFailed
    | ResendDashboardUserInviteSuccess,
): boolean {
  switch (action.type) {
    case 'dashboardUsers/resendInvite':
      return true;
    case 'dashboardUsers/resendInviteFailed':
    case 'dashboardUsers/resendInviteSuccess':
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  activating,
  byId,
  creating,
  editing,
  list,
  loading,
  mapUserTypeToRoleURI,
  resendingInvite,
});

export const selectDashboardUsers = (state: IState) => [
  state.dashboardUsers.loading,
  state.dashboardUsers.list.map(id => state.dashboardUsers.byId[id]),
];

export const selectDashboardUserById = (id: string) => (
  state: IState,
): [boolean, DashboardUser | undefined] => [
  state.dashboardUsers.loading,
  state.dashboardUsers.byId[id],
];

export const selectDashboardUserActivating = (state: IState) => state.dashboardUsers.activating;
export const selectDashboardUserCreating = (state: IState) => state.dashboardUsers.creating;
export const selectDashboardUserEditing = (state: IState) => state.dashboardUsers.editing;
export const selectDashboardUserResendingInvite = (state: IState) =>
  state.dashboardUsers.resendingInvite;
export const selectUserTypeToRoleURIMap = (state: IState) =>
  state.dashboardUsers.mapUserTypeToRoleURI;
export const selectRoleURIToUserTypeMap = (state: IState) =>
  Object.entries(state.dashboardUsers.mapUserTypeToRoleURI).reduce(
    (prev, [key, value]) => ({ ...prev, [value]: key }),
    {},
  );
