import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';

function SelectedGroupList({ selectedGroups, onRemoveGroups }) {
  const [selected, setSelected] = useState([]);
  const removeGroups = useCallback(() => onRemoveGroups(selected), [selected, onRemoveGroups]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelected([]);
  }, [selectedGroups]);

  return (
    <Card.Half
      className="selected-groups-card"
      extra={<FontAwesomeIcon icon={faTrashAlt} onClick={removeGroups} />}
      title={t('pathways:ProcedureRules.wizard.audience.selectedGroups')}
    >
      <Table
        columns={[
          {
            dataIndex: 'name',
            key: 'name',
            title: t('cards:GroupList.columns.title'),
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
        ]}
        dataSource={selectedGroups}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected,
          onSelect: (record, selected, selectedRows) =>
            setSelected(selectedRows.map(({ id }) => id)),
        }}
      />
    </Card.Half>
  );
}

export default SelectedGroupList;
