import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ColumnGroup, Column } from '@cards/Card';
import { Form, Radio, InputNumber, Button, Checkbox } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import Text from 'antd/lib/typography/Text';
import SelectIndexEventModal from '../RuleWizard/Timing/SelectIndexEventModal';
import SelectStageModal from './SelectStageModal';
import SelectContentModal from '@components/SelectContentModal/SingleSelect';
import SelectRulesModal from '../PathwayWizard/StagedRules/SelectRulesModal';
import {
  FROM_ENTERING_A_STAGE,
  FROM_INDEX_EVENT,
  FROM_RULE_EXECUTION,
  UNTIL_ENTERING_A_STAGE,
  UNTIL_INDEX_EVENT,
  UNTIL_RULE_EXECUTION,
  FROM_FORM_SUBMISSION,
  UNTIL_FORM_SUBMISSION,
  START_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
  START_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
  START_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
  START_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  STOP_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  STOP_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
  STOP_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
  STOP_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
  DELAY,
  STAGE_TRANSITION,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
} from '@constants';
import { contentTypesList, formTypes } from '@utils/contentTypes';
import { getTimingDescription } from './utils';

import './style.less';

function TimingDescription({ description }) {
  return (
    <div className="timing-description">
      <Text>{description}</Text>
    </div>
  );
}

function Timing({ stages }) {
  const { i18n, t } = useTranslation();
  const { currentStepData, form, setCurrentStepData } = useWizard();
  const [fromSelection, setFromSelection] = useState(currentStepData?.from?.value || null);
  const [untilSelection, setUntilSelection] = useState(currentStepData?.until?.value || null);
  const [typeOfModalVisible, setTypeOfModalVisible] = useState(null);
  const [content, setContent] = useState([]);
  const [enterableStages, setEnterableStages] = useState([]);
  const [isEventsPeriodVisible, setIsEventsPeriodVisible] = useState(
    currentStepData.start.type || currentStepData.stop.type ? true : false,
  );
  const [startEngagementSelection, setStartEngagementSelection] = useState(
    currentStepData?.start?.value || null,
  );
  const [stopEngagementSelection, setStopEngagementSelection] = useState(
    currentStepData?.stop?.value || null,
  );
  const language = i18n.languages?.[1];

  useEffect(() => {
    if (stages?.length > 0) {
      const rules = stages.reduce((acc, stage) => {
        if (stage.rules) {
          return [
            ...acc,
            ...stage.rules.filter(rule => !acc.some(existingRule => existingRule.id === rule.id)),
          ];
        }
        return acc;
      }, []);
      setContent(rules);

      const enterableStages = stages.filter(stage => !stage.isAdhoc);
      setEnterableStages(enterableStages);
    }
  }, [stages]);

  const handleTimingSelection = useCallback(
    (typeKey, value) => {
      setCurrentStepData({
        ...currentStepData,
        [typeKey]: {
          ...currentStepData[typeKey],
          value: value,
          type: typeOfModalVisible,
        },
      });

      if (typeKey === 'from') {
        setFromSelection(value);
      }
      if (typeKey === 'until') {
        setUntilSelection(value);
      }
      if (typeKey === 'start') {
        setStartEngagementSelection(value);
      }
      if (typeKey === 'stop') {
        setStopEngagementSelection(value);
      }

      setTypeOfModalVisible(null);
    },
    [currentStepData, setCurrentStepData, typeOfModalVisible],
  );

  const selectionMap = {
    from: fromSelection,
    until: untilSelection,
    start: startEngagementSelection,
    stop: stopEngagementSelection,
  };

  const timingOptionTypeFromOption = option => {
    if (option?.includes('stage')) return 'enteringStage';
    if (option?.includes('index')) return 'indexEvent';
    if (option?.includes('rule')) return 'ruleExecution';
    if (option?.includes('form')) return 'formSubmission';
  };

  function getCommonModalProps(modalType, selection) {
    const baseProps = {
      visible: true,
      onCancel: () => setTypeOfModalVisible(null),
    };

    switch (modalType) {
      case 'indexEvent':
        return {
          ...baseProps,
          selectedIndexEvent: selection,
          onOk: (_, selected) =>
            handleTimingSelection(typeOfModalVisible.split('-')[0], { ...selected[0] }),
        };
      case 'enteringStage':
        return {
          ...baseProps,
          selectedStage: selection,
          onOk: selected => handleTimingSelection(typeOfModalVisible.split('-')[0], selected),
          stages: enterableStages,
        };
      case 'ruleExecution':
        return {
          ...baseProps,
          selectedRules: selection ? [selection] : undefined,
          defaultFilteredValue: { language: [language || 'en'] },
          onOk: selected =>
            handleTimingSelection(typeOfModalVisible.split('-')[0], { ...selected[0] }),
          filters: [
            DELAY,
            STAGE_TRANSITION,
            FORM_SUBMITTED,
            QUESTIONNAIRE_SUBMITTED,
            TASK_SUBMITTED,
          ],
          customRules: content,
          singleSelect: true,
          modalTitle: t(
            'pathways:ProcedurePathways.engagementCheckWizard.timingStep.ruleExecutionSelectionModalTitle',
          ),
          audienceTypesToFilter: ['clinician'],
        };
      case 'formSubmission':
        return {
          ...baseProps,
          selectedContent: selection?.whatDetail ? selection.whatDetail : selection,
          contentTypes: modalType === 'ruleExecution' ? contentTypesList : formTypes,
          defaultFilteredValue: { language: [language || 'en'] },
          onSubmit: (_, selected) =>
            handleTimingSelection(typeOfModalVisible.split('-')[0], { ...selected[0] }),
          customContent: content?.map(c => c.whatDetail),
          modalTitle: t(
            'pathways:ProcedurePathways.engagementCheckWizard.timingStep.formSubmissionSelectionModalTitle',
          ),
        };
      default:
        return {};
    }
  }

  const renderModal = () => {
    const timingType = typeOfModalVisible?.split('-')[0];
    const modalType = timingOptionTypeFromOption(typeOfModalVisible);
    const selection = selectionMap[timingType];
    const commonProps = getCommonModalProps(modalType, selection);

    switch (modalType) {
      case 'indexEvent':
        return <SelectIndexEventModal {...commonProps} />;
      case 'enteringStage':
        return <SelectStageModal {...commonProps} />;
      case 'ruleExecution':
        return <SelectRulesModal {...commonProps} />;
      case 'formSubmission':
        return <SelectContentModal {...commonProps} />;
      default:
        return null;
    }
  };

  const requiredTimingTypes = ['from', 'until'];
  const optionalTimingTypes = ['start', 'stop'];
  const fromTypes = [
    FROM_ENTERING_A_STAGE,
    FROM_INDEX_EVENT,
    FROM_RULE_EXECUTION,
    FROM_FORM_SUBMISSION,
  ];
  const untilTypes = [
    UNTIL_ENTERING_A_STAGE,
    UNTIL_INDEX_EVENT,
    UNTIL_RULE_EXECUTION,
    UNTIL_FORM_SUBMISSION,
  ];
  const startTypes = [
    START_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
    START_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
    START_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
    START_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  ];
  const stopTypes = [
    STOP_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
    STOP_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
    STOP_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
    STOP_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  ];
  const timingOptions = { from: fromTypes, until: untilTypes, start: startTypes, stop: stopTypes };

  const renderRadioOptions = (type, options) => {
    return options.map(option => {
      return (
        <Radio value={option} key={option}>
          {t(
            `pathways:ProcedurePathways.engagementCheckWizard.${timingOptionTypeFromOption(
              option,
            )}`,
          )}
          {form.getFieldValue(`${type}Type`) === option && (
            <Button onClick={() => setTypeOfModalVisible(option)} className="edit-button">
              {t('common:buttons.edit')}
            </Button>
          )}
        </Radio>
      );
    });
  };

  const renderDaysOffsetFormItems = (type, option) => {
    const selection = selectionMap[type];
    return (
      form.getFieldValue(`${type}Type`) === option && (
        <Form.Item
          label={t(
            `pathways:ProcedurePathways.engagementCheckWizard.timingStep.${timingOptionTypeFromOption(
              option,
            )}NumberOfDaysDescription`,
            {
              [`${timingOptionTypeFromOption(option)}Name`]:
                selection?.translatedNames?.[i18n.language] || selection?.name,
            },
          )}
        >
          {form.getFieldDecorator(`${type}DaysOffset`, {
            initialValue: currentStepData[type].daysOffset,
          })(
            <InputNumber
              onChange={value =>
                setCurrentStepData({
                  ...currentStepData,
                  [type]: { ...currentStepData[type], daysOffset: value },
                })
              }
              min={timingOptionTypeFromOption(option) === 'indexEvent' ? undefined : 0}
            />,
          )}
        </Form.Item>
      )
    );
  };

  const handleRadioChange = (e, type) => {
    setTypeOfModalVisible(e.target.value);
    form.setFieldsValue({ [`${type}Type`]: e.target.value });
  };

  return (
    <ColumnGroup className="timing-step">
      <Column>
        <Card title={t('pathways:ProcedurePathways.engagementCheckWizard.timing')}>
          <Form>
            {requiredTimingTypes.map(type => (
              <React.Fragment key={type}>
                <Text strong>
                  {t(`pathways:ProcedurePathways.engagementCheckWizard.${type}Type`)}
                </Text>
                <Form.Item className="timing-type">
                  {form.getFieldDecorator(`${type}Type`, {
                    initialValue: currentStepData[type]?.type,
                    rules: [
                      {
                        required: true,
                        message: t(
                          `pathways:ProcedurePathways.engagementCheckWizard.timingStep.${type}.required`,
                        ),
                      },
                    ],
                  })(
                    <Radio.Group onChange={e => handleRadioChange(e, type)}>
                      {renderRadioOptions(type, timingOptions[type])}
                      {timingOptions[type].map(option => renderDaysOffsetFormItems(type, option))}
                      {(selectionMap[type]?.id || selectionMap[type]?.uuid) && (
                        <TimingDescription
                          description={getTimingDescription(
                            currentStepData[type]?.type,
                            currentStepData[type]?.daysOffset,
                            selectionMap[type]?.translatedNames?.[i18n.language] ||
                              selectionMap[type]?.name,
                            t,
                          )}
                        />
                      )}
                    </Radio.Group>,
                  )}
                </Form.Item>
              </React.Fragment>
            ))}
            <br />
            <div>
              <Checkbox
                checked={isEventsPeriodVisible}
                onChange={e => setIsEventsPeriodVisible(e.target.checked)}
              >
                {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.eventsPeriod')}
              </Checkbox>
            </div>
            <br />
            {isEventsPeriodVisible &&
              optionalTimingTypes.map(type => (
                <React.Fragment key={type}>
                  <Text strong>
                    {t(`pathways:ProcedurePathways.engagementCheckWizard.${type}Type`)}
                  </Text>
                  <Form.Item className="timing-type">
                    {form.getFieldDecorator(`${type}Type`, {
                      initialValue: currentStepData[type]?.type,
                    })(
                      <Radio.Group onChange={e => handleRadioChange(e, type)}>
                        {renderRadioOptions(type, timingOptions[type])}
                        {timingOptions[type].map(option => renderDaysOffsetFormItems(type, option))}
                        {(selectionMap[type]?.id || selectionMap[type]?.uuid) && (
                          <TimingDescription
                            description={getTimingDescription(
                              currentStepData[type]?.type,
                              currentStepData[type]?.daysOffset,
                              selectionMap[type]?.translatedNames?.[i18n.language] ||
                                selectionMap[type]?.name,
                              t,
                            )}
                          />
                        )}
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </React.Fragment>
              ))}
          </Form>
        </Card>
      </Column>
      <Column>
        <Card
          className="explanation-card"
          title={t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanationTitle')}
        >
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanation1')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanation2')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanation3')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanation4')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.explanation5')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
        </Card>
      </Column>
      {renderModal()}
    </ColumnGroup>
  );
}

export default Timing;
