import React, { createContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import FormDetails from './FormDetail';
import NewForm from './NewForm';
import EditForm from './EditForm';

import FormList from './FormList';

export const FormSelectorContext = createContext();

export default ({ match, formTranslationKey, selector, type }) => (
  <FormSelectorContext.Provider value={selector}>
    <Switch>
      <Route
        exact
        path={match.path}
        render={props => (
          <FormList {...props} formTranslationKey={formTranslationKey} type={type} />
        )}
      />
      <Route
        exact
        path={`${match.path}/new`}
        render={props => <NewForm type={type} formTranslationKey={formTranslationKey} {...props} />}
      />
      <Route
        exact
        path={`${match.path}/edit/:id`}
        render={props => (
          <EditForm type={type} formTranslationKey={formTranslationKey} {...props} />
        )}
      />
      <Route
        path={`${match.path}/:id`}
        render={props => <FormDetails {...props} formTranslationKey={formTranslationKey} />}
      />
    </Switch>
  </FormSelectorContext.Provider>
);
