import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './redux/reducer';
import rootSaga from './redux/saga';
import TokenPool from './redux/TokenPool';

const create = (context = {}) => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      ...context,
      tokens: new TokenPool(),
    },
  });
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
};

export default create;
