import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageActions } from '@hooks';
import { useTranslation } from 'react-i18next';
import WeblinkWizard from './Wizard';
import { editWeblink, fetchWeblinks } from '@redux/weblinks/actions';
import { selectWeblinksEditing } from '@redux/weblinks/selectors';
import { useContentRefFromUrl } from '@hooks/useContentRefs';

function EditWeblink({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, weblink] = useContentRefFromUrl(match.params.weblinkId);

  const editing = useSelector(selectWeblinksEditing);
  const { setTitle } = usePageActions({});

  useEffect(() => {
    dispatch(fetchWeblinks());
  }, [dispatch, match.params.weblinkId]);

  useEffect(() => {
    if (weblink) setTitle(t('weblinks:Wizard.edit', { name: weblink.name }));
  }, [setTitle, t, weblink]);

  const initialData = useMemo(
    () =>
      weblink
        ? {
            content: { url: weblink.latest_version.content_url },
            details: {
              description: weblink.description,
              name: weblink.name,
              status: weblink.is_available ? 'published' : 'unpublished',
              contentType: weblink.type,
              audience: weblink.audience_type,
              langauge: weblink.latest_version?.metadata.langauge || 'en',
            },
          }
        : undefined,
    [weblink],
  );

  const onCompleted = useCallback(
    formData => {
      dispatch(
        editWeblink(weblink?.uuid, {
          contentType: formData.details.contentType,
          description: formData.details.description,
          published: formData.details.status,
          name: formData.details.name,
          url: formData.content.url,
          audience: formData.details.audience,
          langugage: formData.details.language,
        }),
      );
    },
    [dispatch, weblink],
  );

  return (
    <WeblinkWizard
      initialData={initialData}
      isEdit
      loading={loading}
      submitting={editing}
      onCompleted={onCompleted}
    />
  );
}

export default EditWeblink;
