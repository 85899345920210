import PEPHealthClient from '@liquid-state/pephealth-client';
import { pepHealthApiRoot } from 'settings';

async function createPEPHealthClient(jwt) {
  try {
    const client = await new PEPHealthClient(jwt, { baseUrl: pepHealthApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createPEPHealthClient;
