import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Column, ColumnGroup } from '@cards/Card';
import ViewControlledDocuments from '@components/ViewControlledDocuments';
import ControlledDocumentAcceptanceHistory from '@components/ControlledDocumentAcceptanceHistory';
import { selectTermsAndConditions, selectAcceptancesByIds } from '@redux/termsOfUse/reducers';
import { profileRefreshTermsOfUse } from '@redux/myProfile/actions';

function TermsAndConditions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(profileRefreshTermsOfUse());
  }, [dispatch]);

  const termsAndConditions = useSelector(selectTermsAndConditions);
  const termsAndConditionsIds = useMemo(() => {
    const ids = new Set();

    termsAndConditions.forEach(terms => ids.add(terms.slug));

    return Array.from(ids);
  }, [termsAndConditions]);

  const acceptances = useSelector(selectAcceptancesByIds(termsAndConditionsIds));
  const acceptancesLoading = useSelector(state => state.termsOfUse.acceptancesLoading);

  const acceptancesWithType = useMemo(
    () =>
      acceptances.map(acceptance => {
        return {
          ...acceptance,
          type: t(
            `common:source.${acceptance.version.url.includes('-global') ? 'global' : 'hospital'}`,
          ),
        };
      }),
    [acceptances, t],
  );

  return (
    <ColumnGroup>
      <Column>
        <ViewControlledDocuments
          controlledDocuments={termsAndConditions}
          title={t('myProfile:TermsAndConditions.title')}
        />
      </Column>
      <Column>
        <ControlledDocumentAcceptanceHistory
          acceptances={acceptancesWithType}
          loading={acceptancesLoading}
        />
      </Column>
    </ColumnGroup>
  );
}

export default TermsAndConditions;
