import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, Select, Input, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { usePageActions } from '@hooks';
import { selectOrganisation } from '@redux/organisations/reducers';

import { loadOrgConfig, editOrgConfig } from '@integrations/redux/actions';
import { loadStaffList } from '@integrations/kemtai/actions';
import { selectIsSubmitting, selectOrgConfig } from '@integrations/redux/reducers';
import { selectStaffList } from '@integrations/kemtai/reducers';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('organisations:EditOrganisationDetails.exitTitle'),
    content: t('organisations:EditOrganisationDetails.exitContent'),
    okText: t('organisations:EditOrganisationDetails.okText'),
    cancelText: t('organisations:EditOrganisationDetails.cancelText'),
    onOk: history.goBack,
  });
};

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

function OrganisationConfig({ form: { getFieldDecorator, validateFieldsAndScroll } }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);

  const [, orgConfig] = useSelector(selectOrgConfig('kemtai'));
  const [isLoadingStaffList, staffList] = useSelector(selectStaffList);
  const isSubmitting = useSelector(selectIsSubmitting);

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  const [, organisation] = useSelector(selectOrganisation(dashboardUserProfile.organisationId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: false,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = (organisation && organisation.name) || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle, orgConfig]);

  useEffect(() => {
    if (hasLoaded) {
      return;
    }
    dispatch(loadOrgConfig('kemtai'));
    setHasLoaded(true);
  }, [dispatch, hasLoaded, setHasLoaded]);

  useEffect(() => {
    if (orgConfig && !isLoadingStaffList && !staffList.length) {
      dispatch(loadStaffList());
    }
  }, [dispatch, orgConfig, isLoadingStaffList, staffList]);

  const updateStaffListAfterChangingAPIKey = apiKey => {
    if (apiKey && apiKey.length > 64) {
      // setIsLoading(true);
      dispatch(loadStaffList(apiKey));
    }
  };

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const data = {
            api_key: values.kemtaiAPIKey,
            default_staff_owner_id: values.defaultPatientOwnerId,
          };
          dispatch(editOrgConfig('kemtai', values.isEnabled, data));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  console.log('orgConfig: ', orgConfig);

  return (
    <ColumnGroup className="edit-organisation-branding-details">
      <Column>
        <Card
          className="organisation-details-form-card"
          title={t('kemtai:organisationConfig.title')}
        >
          <Form {...detailsLayout} onSubmit={handleSubmit}>
            <Form.Item
              className="details-item"
              label={t('kemtai:organisationConfig.tokenLabel')}
              help={t('kemtai:organisationConfig.tokenHelpText')}
              defaultValue={orgConfig && orgConfig.data && orgConfig.data.api_key}
            >
              {getFieldDecorator('kemtaiAPIKey', {
                rules: [
                  {
                    required: true,
                    message: t('kemtai:organisationConfig.tokenRequired'),
                  },
                ],
                initialValue: orgConfig && orgConfig.data && orgConfig.data.api_key,
              })(
                <Input
                  type="text" // TODO: change to password type
                  placeholder="API token"
                  value={token}
                  onChange={e => {
                    setToken(e.target.value);
                    updateStaffListAfterChangingAPIKey(e.target.value);
                  }}
                  allowClear={true}
                />,
              )}
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('kemtai:organisationConfig.defaultStaffLabel')}
              help={t('kemtai:organisationConfig.defaultStaffHelpText')}
            >
              {getFieldDecorator('defaultPatientOwnerId', {
                rules: [
                  {
                    required: true,
                    message: t('kemtai:organisationConfig.defaultPatientOwnerIdRequired'),
                  },
                ],
                initialValue:
                  staffList.length && orgConfig && orgConfig.data
                    ? orgConfig.data.default_staff_owner_id || staffList[0]['id']
                    : 'Loading...',
              })(
                <Select>
                  {staffList.map(s => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.fullName}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('kemtai:organisationConfig.isEnabled')}
              help={t('kemtai:organisationConfig.isEnabledHelpText')}
            >
              {getFieldDecorator('isEnabled', {
                rules: [],
                initialValue: orgConfig.isEnabled,
              })(<Checkbox style={{ marginLeft: '0' }} defaultChecked={orgConfig.isEnabled} />)}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: '10px' }}
                loading={isSubmitting}
                disabled={!hasLoaded || !staffList.length}
              >
                {t('organisations:Branding.details.edit.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

const WrappedOrganisationConfig = Form.create()(OrganisationConfig);

export default WrappedOrganisationConfig;
