import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Email from './Email';
import Code from './Code';
import Password from './Password';

const RecoveryIndex = () => {
  return (
    <Switch>
      <Route exact path="/auth/recovery" render={() => <Redirect to="/auth/recovery/1" />} />
      <Route path="/auth/recovery/1" component={Email} />
      <Route path="/auth/recovery/2" component={Code} />
      <Route path="/auth/recovery/3" component={Password} />
    </Switch>
  );
};

export default RecoveryIndex;
