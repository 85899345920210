import React from 'react';
import { Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import DashboardUserTypeIcon from '@components/DashboardUserTypeIcon';

export default (t, hospitalIndex = {}, displayHospitalColumn) => [
  {
    title: t('cards:DashboardUsersList.labels.userType'),
    key: 'type',
    render: user => <DashboardUserTypeIcon userType={user.userType} />,
    sorter: (a, b) => a.userType.localeCompare(b.userType),
    onFilter: (value, record) => record.userType === value,
    width: 200,
  },
  ...(displayHospitalColumn
    ? [
        {
          title: t('cards:DashboardUsersList.labels.hospital'),
          key: 'hospital',
          render: user => hospitalIndex[user.hospitalId] || '-',
          sorter: (a, b) =>
            (hospitalIndex[a.hospitalId] || '').localeCompare(hospitalIndex[b.hospitalId] || ''),
          width: 350,
        },
      ]
    : []),
  {
    title: t('cards:DashboardUsersList.labels.fullName'),
    key: 'name',
    render: user => user.name,
    sorter: (a, b) => a.name.localeCompare(b.name),
    onFilter: (value, record) => record.name === value,
    width: 200,
  },
  {
    title: t('cards:DashboardUsersList.labels.contact'),
    key: 'contact',
    render: user => (
      <Tooltip placement="bottom" title={t('cards:DashboardUsersList.email')}>
        <Button
          type="link"
          onClick={e => e.stopPropagation()}
          href={`mailto:${user.email}`}
          className="contact-button"
        >
          <FontAwesomeIcon size="lg" icon={faEnvelope} />
        </Button>
      </Tooltip>
    ),
    width: 190,
    fixed: 'right',
  },
  {
    dataIndex: 'activated',
    title: t('cards:DashboardUsersList.labels.activeAccounts'),
    key: 'active',
    render: activated => <PublishedStatusIcon published={activated} />,
    width: 180,
    fixed: 'right',
  },
];
