import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import WizardDetails from '@components/Wizard/Details';
import Details from './Details';
import Content from './Content';
import Review from './Review';
import './style.less';

function WeblinkWizard({ initialData, isEdit, loading, submitting, onCompleted }) {
  const { t } = useTranslation();
  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('common:Wizard.details.stepName')}>
        <WizardDetails>
          <Details isEdit={isEdit} />
        </WizardDetails>
      </WizardItem>
      <WizardItem stepKey="content" title={t('weblinks:Wizard.content.stepName')}>
        <Content />
      </WizardItem>
      <WizardItem stepKey="review" title={t('common:Wizard.review.stepName')}>
        <Review isEdit={isEdit} />
      </WizardItem>
    </Wizard>
  );
}

WeblinkWizard.propTypes = {
  initialData: shape({}),
  isEdit: bool,
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
};

WeblinkWizard.defaultProps = {
  initialData: undefined,
  isEdit: false,
  loading: false,
  submitting: false,
};

export default WeblinkWizard;
