import React from 'react';
import { bool, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { WEBSITE, WEB_FORM } from '@constants';
import './style.less';

function WeblinkTypeIcon({ type, showText }) {
  const { t } = useTranslation();
  switch (type) {
    case WEBSITE:
      return (
        <>
          <FontAwesomeIcon className="weblink-type-icon" icon={faGlobe} />
          {showText ? t('common:contentTypes.singular.website') : null}
        </>
      );
    case WEB_FORM:
      return (
        <>
          <FontAwesomeIcon className="weblink-type-icon" icon={faEdit} />
          {showText ? t('common:contentTypes.singular.webform') : null}
        </>
      );
    default:
      return null;
  }
}

WeblinkTypeIcon.propTypes = {
  type: oneOf([WEBSITE, WEB_FORM]).isRequired,
  showText: bool,
};

WeblinkTypeIcon.defaultProps = {
  showText: false,
};

export default WeblinkTypeIcon;
