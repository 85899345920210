import React from 'react';
import cn from 'classnames';

import PermissionsError from './PermissionsError';

const Column = ({ children, className, size = 'auto', ...props }) => {
  return (
    <div className={cn('grid-column', className, size)} {...props}>
      {React.Children.map(children, c => (
        <PermissionsError>{c}</PermissionsError>
      ))}
    </div>
  );
};

export default Column;
export const Half = props => <Column size="half" {...props} />;
export const Third = props => <Column size="third" {...props} />;
export const TwoThirds = props => <Column size="two-thirds" {...props} />;
