import { call, getContext } from 'redux-saga/effects';
import { IIDMClient } from '@liquid-state/idm-client';
import createIDMClient from '@api/idmClient';

function* doCreateIDMClient(): any {
  const tokens = yield getContext('tokens');
  const token = yield call(tokens.get, 'idm');
  const client: IIDMClient = yield call(createIDMClient, token);

  return client;
}

export default doCreateIDMClient;
