import React from 'react';
import { DatePicker, Button } from 'antd';
import i18n from '../../i18n';
import moment from 'moment';
import './style.less';

const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <div className="date-filter-dropdown">
      <div>
        <DatePicker
          onChange={(_, date) => {
            setSelectedKeys(date ? [date] : []);
          }}
          value={selectedKeys.length ? moment(selectedKeys[0]) : null}
        />
      </div>
      <div className="buttons">
        <Button
          type="link"
          onClick={() => {
            confirm();
          }}
          size="small"
        >
          {i18n.t('common:DateFilter.ok')}
        </Button>
        <Button onClick={() => clearFilters()} type="link" size="small">
          {i18n.t('common:DateFilter.reset')}
        </Button>
      </div>
    </div>
  );
};

const onFilter = (value, recordValue) => {
  const d1 = new Date(value);
  const d2 = new Date(recordValue);
  return d1.getDate() === d2.getDate();
};

export default { filterDropdown, onFilter };
