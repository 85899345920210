import React from 'react';
import moment from 'moment';
import { Tooltip, Button } from 'antd';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardUserTypeIcon from '@components/DashboardUserTypeIcon';
import { userTypesWithLabel, APP_USER } from '@constants';

export default (t, action, appUsers, dashboardUsers, dashboardUserHospital) => [
  {
    title: t('cards:RequestList.columns.date'),
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => a.created.localeCompare(b.created),
    render: created => moment(created).format('L'),
    width: 160,
    defaultSortOrder: 'descend',
  },
  {
    title: t('cards:RequestList.columns.userType'),
    key: 'userType',
    render: item => {
      if (item.userType === APP_USER) {
        return (
          <Tooltip title={t('cards:RequestList.userTypes.appUser')}>
            <FontAwesomeIcon icon={faUser} />
          </Tooltip>
        );
      }

      return <DashboardUserTypeIcon userType={item.userType} tooltip label={false} />;
    },
    filters: userTypesWithLabel.map(u => ({
      text: t(u.label),
      value: u.value,
    })),
    onFilter: (value, record) => {
      if (appUsers.find(user => record.userId === user.ids.pip)) {
        return value === 'app-user';
      }
      const dashboardUser = dashboardUsers.find(user => user.uuid === record.userId);
      return value === dashboardUser.userType;
    },
    width: 150,
  },
  {
    title: t('cards:RequestList.columns.requestId'),
    dataIndex: 'uuid',
    key: 'uuid',
    width: 180,
  },
  {
    title: t('cards:RequestList.columns.userName'),
    key: 'name',
    render: item => {
      const appUser = appUsers.find(user => item.userId === user.ids.pip);
      if (appUser) return `${appUser.firstName} ${appUser.lastName}`;
      const dashboardUser = dashboardUsers.find(user => item.userId === user.uuid);
      if (dashboardUser) return `${dashboardUser.firstName} ${dashboardUser.lastName}`;
      return '-';
    },
    sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
    width: 200,
  },
  ...(action
    ? [
        {
          className: 'action-column',
          title: t('cards:RequestList.columns.action'),
          key: 'action',
          render: item => {
            const disabled =
              item.history &&
              item.history.find(
                h =>
                  h.action === 'transition' && h.data.previousAssignee.id === dashboardUserHospital,
              );

            return (
              <Button
                type="primary"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  action(item);
                }}
                disabled={disabled}
              >
                {t('cards:RequestList.actionButtonHospitalAdmin')}
              </Button>
            );
          },
        },
      ]
    : []),
  {
    title: t('cards:RequestList.columns.dateSent'),
    key: 'dateSent',
    render: item => {
      if (item.history && item.history.length) {
        const historyItem = item.history.find(
          h => h.action === 'transition' && h.data.previousAssignee.id === dashboardUserHospital,
        );
        if (historyItem) {
          return <span>{moment(historyItem.data.timestamp).format('L')}</span>;
        }
      }
      return (
        <span className="date-sent-urgent">{t('cards:RequestList.columns.dateSentDefault')}</span>
      );
    },
    width: 250,
  },
];
