import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { fetchIndexEvents } from '../../../redux/indexEvents/actions';
import { selectIndexEvent } from '../../../redux/indexEvents/reducers';
import IndexEventDetailsCard from './Card';

function IndexEventDetails({ indexEventId, title, ...props }) {
  const dispatch = useDispatch();
  const [loading, indexEvent] = useSelector(selectIndexEvent(indexEventId));

  useEffect(() => {
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  return (
    <IndexEventDetailsCard indexEvent={indexEvent} loading={loading} title={title} {...props} />
  );
}

IndexEventDetails.propTypes = {
  title: string,
  indexEventId: string.isRequired,
};

IndexEventDetails.defaultProps = {
  title: 'indexEvents:Details.title',
};

export default IndexEventDetails;
