import React from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { procedurePathway, procedureRule } from '../../../assets/icons';
import { historyType } from '../../../propTypes';

const menu = (t, history) => (
  <Menu className="new-pathway-dropdown">
    <Menu.Item onClick={() => history.push('/procedure/pathways/new')}>
      <Icon component={procedurePathway} />
      {t('pathways:ProcedurePathways.newPathway')}
    </Menu.Item>
    <Menu.Item onClick={() => history.push('/procedure/rules/new')}>
      <Icon component={procedureRule} />
      {t('pathways:ProcedurePathways.newRule')}
    </Menu.Item>
    {/* <Menu.Item>
      <i className="anticon">
        <FontAwesomeIcon icon={faCalendarStar} />
      </i>
      {t('pathways:ProcedurePathways.newIndexEvent')}
    </Menu.Item> */}
  </Menu>
);

function NewDropdown({ history }) {
  const { t } = useTranslation();

  return (
    <Dropdown.Button
      className="new-pathway-button"
      icon={<FontAwesomeIcon icon={faAngleDown} />}
      overlay={menu(t, history)}
      type="primary"
      onClick={() => history.push(`${history.location.pathname}/new`)}
    >
      {t('common:buttons.new')}
    </Dropdown.Button>
  );
}

NewDropdown.propTypes = {
  history: historyType.isRequired,
};

export default NewDropdown;
