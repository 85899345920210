import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks/usePageActions';
import { createPathway, fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathwayCreating } from '@pathways/redux/pathways/reducers';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { fetchRules } from '@pathways/redux/rules/actions';
import PathwayWizard from './PathwayWizard';
import './style.less';

function NewPathway() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creating = useSelector(selectPathwayCreating);

  usePageActions({
    title: t('pathways:ProcedurePathways.wizard.header'),
  });

  document.title = t('pathways:ProcedurePathways.wizard.header');

  const onCompleted = useCallback(
    formData => {
      const pathwayData = {
        ...formData.details,
        language: formData.details.language || 'en',
        ...formData.stagedRules,
        ...formData.indexEvents,
        ...formData.indexEventRules,
        isActive: true,
        isDeleted: false,
      };

      dispatch(createPathway(pathwayData));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchRules());
  }, [dispatch]);

  return <PathwayWizard isPathwayInUse={false} submitting={creating} onCompleted={onCompleted} />;
}

export default NewPathway;
