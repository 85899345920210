import React from 'react';
import { Alert } from 'antd';
import { withTranslation } from 'react-i18next';

class Error extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    if (error.name === 'Access Denied') {
      throw error;
    }
    return { error: true };
  }

  componentDidCatch(error) {
    console.error('Error trap caught', error);
  }

  renderError(t) {
    return <Alert type="error" message={t('cards:defaultError')} />;
  }

  render() {
    return this.state.error ? this.renderError(this.props.t) : this.props.children;
  }
}

export default withTranslation()(Error);
