import { Flag, CreateFlagData, UpdateFlagData } from '@liquid-state/uis-client/dist/types';

export type IFetchFlags = ReturnType<typeof fetchFlags>;
export const fetchFlags = () => ({
  type: 'flags/fetch' as const,
  payload: {},
});

export type ILoadFlags = ReturnType<typeof loadFlags>;
export const loadFlags = () => ({
  type: 'flags/load' as const,
  payload: {},
});

export type IFlagsLoaded = ReturnType<typeof flagsLoaded>;
export const flagsLoaded = (flags: Flag[]) => ({
  type: 'flags/loaded' as const,
  payload: { flags },
});

export type ILoadFlagsFailed = ReturnType<typeof loadFlagsFailed>;
export const loadFlagsFailed = () => ({
  type: 'flags/loadFailed' as const,
  payload: {},
});

export type ICreateFlag = ReturnType<typeof createFlag>;
export const createFlag = (data: CreateFlagData) => ({
  type: 'flags/createFlag' as const,
  payload: { data },
});

export type ICreateFlagSuccess = ReturnType<typeof createFlagSuccess>;
export const createFlagSuccess = (flag: Flag) => ({
  type: 'flags/createFlagSuccess' as const,
  payload: { flag },
});

export type ICreateFlagFailed = ReturnType<typeof createFlagFailed>;
export const createFlagFailed = () => ({
  type: 'flags/createFlagFailed' as const,
  payload: {},
});

export type IEditFlag = ReturnType<typeof editFlag>;
export const editFlag = (uuid: string, data: UpdateFlagData) => ({
  type: 'flags/editFlag' as const,
  payload: { uuid, data },
});

export type IEditFlagSuccess = ReturnType<typeof editFlagSuccess>;
export const editFlagSuccess = (flag: Flag) => ({
  type: 'flags/editFlagSuccess' as const,
  payload: { flag },
});

export type IEditFlagFailed = ReturnType<typeof editFlagFailed>;
export const editFlagFailed = () => ({
  type: 'flags/editFlagFailed' as const,
  payload: {},
});

export type IMoveFlag = ReturnType<typeof moveFlag>;
export const moveFlag = (uuid: string, direction: 'up' | 'down') => ({
  type: 'flags/moveFlag' as const,
  payload: { uuid, direction },
});

export type IMoveFlagSuccess = ReturnType<typeof moveFlagSuccess>;
export const moveFlagSuccess = () => ({
  type: 'flags/moveFlagSuccess' as const,
  payload: {},
});

export type IMoveFlagFailed = ReturnType<typeof moveFlagFailed>;
export const moveFlagFailed = () => ({
  type: 'flags/moveFlagFailed' as const,
  payload: {},
});

// export type IDeleteFlags = ReturnType<typeof deleteFlags>;
// export const deleteFlags = (flagIds: number[]) => ({
//   type: 'flags/deleteFlags' as const,
//   payload: { flagIds },
// });

// export type IDeleteFlagsSuccess = ReturnType<typeof deleteFlagsSuccess>;
// export const deleteFlagsSuccess = (flagIds: number[]) => ({
//   type: 'flags/deleteFlagsSuccess' as const,
//   payload: { flagIds },
// });

// export type IDeleteFlagsFailed = ReturnType<typeof deleteFlagsFailed>;
// export const deleteFlagsFailed = () => ({
//   type: 'flags/deleteFlagsFailed' as const,
//   payload: {},
// });

export type ISwitchFlagOrder = ReturnType<typeof switchFlagOrder>;
export const switchFlagOrder = (uuid: string, order: number) => ({
  type: 'flags/switchOrder' as const,
  payload: { uuid, order },
});

export type ISwitchFlagOrderSuccess = ReturnType<typeof switchFlagOrderSuccess>;
export const switchFlagOrderSuccess = (uuid: string, order: number) => ({
  type: 'flags/switchOrderSuccess' as const,
  payload: { uuid, order },
});

export type ISwitchFlagOrderFailed = ReturnType<typeof switchFlagOrderFailed>;
export const switchFlagOrderFailed = () => ({
  type: 'flags/switchOrderFailed' as const,
  payload: {},
});
