import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import {
  selectPathway,
  selectEngagementCheck,
  selectEngagementChecksLoading,
} from '@pathways/redux/pathways/reducers';
import { Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import { waitForStoreUpdate } from '@utils';
import {
  fetchEngagementChecks,
  deleteEngagementCheck,
  fetchPathways,
} from '@pathways/redux/pathways/actions';
import EngagementCheckRuleDetails from '@pathways/cards/Pathways/EngagementCheckRuleDetails';
import confirmModal from '@pathways/cards/Pathways/PathwayList/confirmModal';
import { historyType, matchType } from '../../../propTypes';
import {
  selectSharedPathwaysLoading,
  selectSharedPathwayEngagementCheckById,
} from '@pathways/redux/sharedPathways/reducers';
import { fetchSharedPathwaySnapshots } from '@pathways/redux/sharedPathways/actions';

function EngagementCheckDetails({ history, match }) {
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  const { id, engagementCheckId } = match.params;
  const engagement = useSelector(selectEngagementCheck(id, engagementCheckId));
  const engagementChecksLoading = useSelector(selectEngagementChecksLoading(id));
  const sharedEngagementCheck = useSelector(
    selectSharedPathwayEngagementCheckById(id, engagementCheckId),
  );
  const [engagementCheck, setEngagementCheck] = useState();
  const [pathwaysLoading, pathway] = useSelector(selectPathway(id));
  const sharedPathwaysLoading = useSelector(selectSharedPathwaysLoading);
  const isShared = match.path.includes('shared');

  const loading = engagementChecksLoading || pathwaysLoading || sharedPathwaysLoading;

  const { setActions, setTitle } = usePageActions({
    actions: [],
    showBack: true,
  });

  useEffect(() => {
    if (isShared) {
      dispatch(fetchSharedPathwaySnapshots());
    } else {
      dispatch(fetchPathways());
    }
    dispatch(fetchEngagementChecks(id, isShared));
  }, [dispatch, id, isShared]);

  useEffect(() => {
    if (isShared) {
      if (sharedEngagementCheck?.id) {
        setEngagementCheck(sharedEngagementCheck);
      }
    } else {
      if (engagement?.id) {
        setEngagementCheck(engagement);
      }
    }
  }, [isShared, sharedEngagementCheck, engagement, engagementChecksLoading]);

  const onDeleteEngagementCheck = useCallback(() => {
    dispatch(deleteEngagementCheck(Number(id), engagementCheckId));
    return waitForStoreUpdate(store, selectEngagementCheck(id, engagementCheckId)).then(() =>
      history.push(`/procedure/pathways/${id}`),
    );
  }, [dispatch, id, engagementCheckId, history, store]);

  useEffect(() => {
    if (engagementCheck) {
      setTitle(engagementCheck.details.name);
      setActions([
        <IconButton
          icon={faTrashAlt}
          key="delete-engagement-check"
          tooltip={t('common:buttons.delete')}
          onClick={() =>
            confirmModal(
              t('pathways:ProcedurePathways.engagementChecks.confirmDelete'),
              onDeleteEngagementCheck,
            )
          }
        />,
        <EditButton
          key="edit-engagementCheck"
          onClick={() =>
            history.push(`/procedure/pathways/${id}/edit-engagement-check/${engagementCheckId}`)
          }
        />,
      ]);
    }
  }, [
    engagementCheck,
    t,
    onDeleteEngagementCheck,
    history,
    setActions,
    setTitle,
    id,
    engagementCheckId,
  ]);

  return (
    <Column>
      <EngagementCheckRuleDetails
        engagementCheck={engagementCheck}
        pathway={pathway}
        loading={loading}
      />
    </Column>
  );
}

EngagementCheckDetails.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default EngagementCheckDetails;
