import React, { createContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import MessageList from './List';
import MessageDetail from './Detail';
import NewMessage from './NewMessage';
import EditMessage from './EditMessage';

export const MessageSelectorContext = createContext();

export default ({ match, messageTranslationKey, selector, type }) => (
  <MessageSelectorContext.Provider value={selector}>
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={props => (
          <MessageList {...props} type={type} messageTranslationKey={messageTranslationKey} />
        )}
      />
      <Route
        path={`${match.path}/new`}
        render={props => (
          <NewMessage {...props} type={type} messageTranslationKey={messageTranslationKey} />
        )}
      />
      <Route
        path={`${match.path}/edit/:id`}
        render={props => (
          <EditMessage {...props} type={type} messageTranslationKey={messageTranslationKey} />
        )}
      />
      <Route
        path={`${match.path}/:id`}
        render={props => (
          <MessageDetail {...props} type={type} messageTranslationKey={messageTranslationKey} />
        )}
      />
    </Switch>
  </MessageSelectorContext.Provider>
);
