import mixpanel from 'mixpanel-browser';
import {
  envName,
  appToken,
  mixpanelToken,
  mixpanelOptions,
  analyticsHiddenSuperProperties,
} from 'settings';

let currentScreen = null;

function defaultSuperProperties() {
  return {
    env: envName,
    app_token: appToken,
  };
}

export function initialise() {
  mixpanel.init(mixpanelToken, mixpanelOptions);
  mixpanel.register({ ...defaultSuperProperties() });
}

export function buildScreenName(section, screen) {
  return `${section} - ${screen}`;
}

export function buildEventName(screenName, event) {
  return `${screenName} - ${event}`;
}

export const sections = {
  AUTHENTICATION: 'Authentication',
  HOME: 'Home',
  APP_USERS: 'App Users',
  ADHOC_MESSAGES: 'Adhoc Messages',
  CONTENT: 'Content',
  PATHWAYS: 'Pathways',
  ADMINISTRATION: 'Administration',
  SETTINGS: 'Settings',
};

export const screens = {
  AUTH_LOGIN: buildScreenName(sections.AUTHENTICATION, 'Login'),
  HOME_HOME: buildScreenName(sections.HOME, 'Home'),
  APP_USERS_LIST: buildScreenName(sections.APP_USERS, 'List'),
  APP_USERS_CREATION: buildScreenName(sections.APP_USERS, 'Creation'),
  APP_USERS_EDIT: buildScreenName(sections.APP_USERS, 'Edit'),
  APP_USER_OVERVIEW: buildScreenName(sections.APP_USERS, 'Overview'),
  APP_USER_FILES: buildScreenName(sections.APP_USERS, 'Files'),
  APP_USER_FORM_RESULTS: buildScreenName(sections.APP_USERS, 'Form Results'),
  APP_USER_QUESTIONNAIRE_RESULTS: buildScreenName(sections.APP_USERS, 'Questionnaire Results'),
  APP_USER_TASK_RESULTS: buildScreenName(sections.APP_USERS, 'Task Results'),
  APP_USER_JOURNEY: buildScreenName(sections.APP_USERS, 'Journey'),
  APP_USER_PATHWAY: buildScreenName(sections.APP_USERS, 'Pathway'),
  APP_USER_APP_DETAILS: buildScreenName(sections.APP_USERS, 'App Details'),
  APP_USER_APP_ACCESS: buildScreenName(sections.APP_USERS, 'App Access'),
  APP_USER_TERMS_CONDITIONS: buildScreenName(sections.APP_USERS, 'Terms & Conditions'),
  APP_USER_PRIVACY_POLICY: buildScreenName(sections.APP_USERS, 'Privacy Policy'),
  ADHOC_MESSAGES_SENT_LIST: buildScreenName(sections.ADHOC_MESSAGES, 'Adhoc Messages Sent List'),
  ADHOC_MESSAGES_SCHEDULED_LIST: buildScreenName(
    sections.ADHOC_MESSAGES,
    'Adhoc Messages Scheduled List',
  ),
  CONTENT_DOCUMENTS_LIST: buildScreenName(sections.CONTENT, 'Documents List'),
  CONTENT_VIDEOS_LIST: buildScreenName(sections.CONTENT, 'Videos List'),
  CONTENT_FORMS_LIST: buildScreenName(sections.CONTENT, 'Forms List'),
  CONTENT_QUESTIONNAIRES_LIST: buildScreenName(sections.CONTENT, 'Questionnaires List'),
  CONTENT_TASKS_LIST: buildScreenName(sections.CONTENT, 'Tasks List'),
  CONTENT_MESSAGES_LIST: buildScreenName(sections.CONTENT, 'Messages List'),
  CONTENT_ALERTS_LIST: buildScreenName(sections.CONTENT, 'Alerts List'),
  CONTENT_REMINDERS_LIST: buildScreenName(sections.CONTENT, 'Reminders List'),
  PATHWAYS_LIST: buildScreenName(sections.PATHWAYS, 'Pathways List'),
  SHARED_PATHWAYS_LIST: buildScreenName(sections.PATHWAYS, 'Shared Pathways List'),
  PATHWAYS_RULES_LIST: buildScreenName(sections.PATHWAYS, 'Rules List'),
  ADMINISTRATION_CODES_LIST: buildScreenName(sections.ADMINISTRATION, 'Codes List'),
  ADMINISTRATION_USERS_LIST: buildScreenName(sections.ADMINISTRATION, 'Dashboard Users List'),
  ADMINISTRATION_REQUESTS_LIST: buildScreenName(sections.ADMINISTRATION, 'Requests List'),
  ADMINISTRATION_HOSPITALS_LIST: buildScreenName(sections.ADMINISTRATION, 'Hospitals List'),
  ADMINISTRATION_HOSPITAL_DETAILS: buildScreenName(sections.ADMINISTRATION, 'Hospital Details'),
  ADMINISTRATION_HOSPITAL_CONTENT: buildScreenName(sections.ADMINISTRATION, 'Hospital Content'),
};

export function setIdentity(identity) {
  mixpanel.identify(identity);
}

export function setSuperProperties(superProperties) {
  const allSP = { ...defaultSuperProperties(), ...superProperties };
  analyticsHiddenSuperProperties.forEach(sp => (allSP[sp] = undefined));
  mixpanel.register(allSP);
}

export function removeSuperProperty(superProperty) {
  mixpanel.unregister(superProperty);
}

export function reset() {
  mixpanel.reset();
}

export function trackScreenEvent(screen, event = 'View', properties = {}) {
  const eventName = buildEventName(screen, event);
  mixpanel.track(eventName, properties, { send_immediately: true });
}

export function track(event = 'View', properties = {}) {
  trackScreenEvent(currentScreen, event, properties);
}

export function trackApplicationEvent(event, properties = {}) {
  trackScreenEvent('Application', event, properties);
}

export function trackDataEvent(event, properties = {}) {
  trackScreenEvent('Data', event, properties);
}

export function trackAndPushHistory(history, route, event, properties = {}) {
  track(event, properties);
  history.push(route);
}

export function trackMainMenu(event, properties = {}) {
  trackScreenEvent('Main Menu', event, properties);
}

export function timeEvent(event, screen = undefined) {
  const e = screen ? buildEventName(screen, event) : event;
  mixpanel.time_event(e);
}

export function setCurrentScreen(screenName, trackViewEvent = true) {
  currentScreen = screenName;
  if (trackViewEvent) {
    track();
  }
}
