import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { Button, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import {
  whatTypes,
  whatLabelTypes,
  whoTypes,
  whenTypes,
  DELAY,
  GROUP,
  INDEX_EVENT,
  INDEX_EVENT_DELAY,
} from '@constants';
import { contentTypesList } from '@utils/contentTypes';
import { selectRule } from '@pathways/redux/rules/reducers';
import { fetchRules } from '@pathways/redux/rules/actions';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
// import LanguageIcon from '@components/LanguageIcon';
import None from '@components/None';
import ContentPreview from './ContentPreview';
import './style.less';

const isNewOrEditTest = pathname => !pathname.includes('/new') && !pathname.includes('/edit');

const WHAT = 'what';
const WHEN = 'when';

function ProcedureRuleDetails({ ruleData, ruleId, title, ...props }) {
  const [rule, setRule] = useState(null);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [previewType, setPreviewType] = useState(WHAT);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, storedRuleData] = useSelector(selectRule(ruleId));

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  useEffect(() => {
    if (ruleData) {
      setRule(ruleData);
    } else if (storedRuleData) {
      setRule(storedRuleData);
    }
  }, [storedRuleData, ruleData]);

  const audienceType = rule?.audienceType || rule?.whatDetail?.audienceType;

  return (
    <>
      <Card.Half
        className="procedure-rule-details"
        loading={isNewOrEditTest(history.location.pathname) && loading}
        title={title}
        {...props}
      >
        {rule ? (
          <DetailForm>
            <h3>{t('cards:ProcedureRuleDetails.title')}</h3>

            <DetailRow label={t('cards:ProcedureRuleDetails.name')} value={rule.name} />
            <DetailRow
              label={t('cards:ProcedureRuleDetails.description')}
              value={rule.description || <None />}
            />
            {/* <DetailRow
              label={t('cards:ProcedureRuleDetails.language')}
              value={<LanguageIcon language={rule.language || rule.metadata.language} showText />}
            /> */}
            <h3>{t('cards:ProcedureRuleDetails.what.title')}</h3>
            <DetailRow
              label={t('cards:ProcedureRuleDetails.what.action')}
              value={t(whatTypes[rule.what])}
            />
            {rule.whatDetail &&
            (rule.whatDetail.json?.name || rule.whatDetail.name || rule.whatDetail.title) ? (
              <>
                <DetailRow
                  label={t(whatLabelTypes[rule.what])}
                  value={
                    rule.whatDetail.title || rule.whatDetail.json?.name || rule.whatDetail.name
                  }
                />
                <DetailRow
                  label=" "
                  value={
                    <Button
                      onClick={() => {
                        setPreviewType(WHAT);
                        setPreviewVisible(true);
                      }}
                    >
                      {t('common:buttons.preview')}
                    </Button>
                  }
                />
                {audienceType && (
                  <DetailRow
                    className="what-audience"
                    label={`${t(whatLabelTypes[rule.what])} ${t(
                      'cards:ProcedureRuleDetails.what.audience.title',
                    )}`}
                    value={t(`cards:ProcedureRuleDetails.what.audience.${audienceType}`)}
                  />
                )}
              </>
            ) : null}
            <h3>{t('cards:ProcedureRuleDetails.who.title')}</h3>
            <DetailRow
              label={t('cards:ProcedureRuleDetails.who.audience')}
              value={
                rule.who === GROUP ? (
                  <>
                    <p>
                      <strong>{t(whoTypes[rule.who])}</strong>
                    </p>
                    {rule.whoDetail.map(detail => (
                      <p key={detail.id}>{detail.name}</p>
                    ))}
                  </>
                ) : (
                  t(whoTypes[rule.who])
                )
              }
            />
            <h3>{t('cards:ProcedureRuleDetails.when.title')}</h3>
            <DetailRow
              label={t('cards:ProcedureRuleDetails.when.title')}
              value={
                <>
                  {rule.when === DELAY
                    ? `${rule.whenDetail.days} ${t(whenTypes[rule.when])}`
                    : null}
                  {(rule.when === INDEX_EVENT || rule.when === INDEX_EVENT_DELAY) &&
                  rule.whenDetail.days
                    ? t(whenTypes[INDEX_EVENT_DELAY], {
                        context: Number(rule.whenDetail.days) > 0 ? '' : 'negative',
                        days: Math.abs(Number(rule.whenDetail.days)),
                      })
                    : null}
                  {rule.when !== DELAY &&
                  rule.when !== INDEX_EVENT_DELAY &&
                  !(rule.when === INDEX_EVENT && rule.whenDetail.days)
                    ? t(whenTypes[rule.when])
                    : null}
                </>
              }
            />
            {rule.whenDetail?.name &&
            (contentTypesList.includes(rule.whenDetail?.type) ||
              contentTypesList.includes(rule.whenDetail?.metadata?.type)) ? (
              <>
                <DetailRow label={t('common:Menu.content.title')} value={rule.whenDetail.name} />
                <DetailRow
                  label=" "
                  value={
                    <Button
                      onClick={() => {
                        setPreviewType(WHEN);
                        setPreviewVisible(true);
                      }}
                    >
                      {t('common:buttons.preview')}
                    </Button>
                  }
                />
              </>
            ) : null}
          </DetailForm>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card.Half>
      <ContentPreview
        previewType={previewType}
        rule={rule}
        visible={isPreviewVisible}
        onCancel={() => setPreviewVisible(false)}
      />
    </>
  );
}

ProcedureRuleDetails.propTypes = {
  ruleData: shape({}),
  ruleId: string,
};

ProcedureRuleDetails.defaultProps = {
  ruleData: null,
  ruleId: null,
};

export default ProcedureRuleDetails;
