import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Button, Input, Icon, Form } from 'antd';
import OnboardingContainer from '@components/OnboardingContainer';
import { validatePasswordComplexity } from '@utils';
import { passwordResetPasswordSubmitted } from '@redux/passwordRecovery/actions';
import { isLoading } from '@redux/passwordRecovery/reducer';

import './style.less';

const PasswordRecovery = ({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldError,
    getFieldValue,
    validateFields,
  },
}) => {
  const isStarted = useSelector(state => state.passwordRecovery.started);
  const [confirmDirty, updateDirty] = useState(false);
  const [hidden, toggleHidden] = useState(true);
  const { t } = useTranslation();
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const result = { password: values.confirmPassword };
          dispatch(passwordResetPasswordSubmitted(result));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  const toggleVisibility = e => {
    toggleHidden(!hidden);
  };

  const validateWithConfirmPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(t('common:validation.Password.inconsistentError'));
    } else {
      callback();
    }
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    updateDirty(confirmDirty || !!value);
  };

  const disabledSubmit =
    typeof getFieldError('password') !== 'undefined' ||
    !getFieldValue('password') ||
    typeof getFieldError('confirmPassword') !== 'undefined' ||
    !getFieldValue('confirmPassword');

  if (!isStarted) {
    return <Redirect to="/auth/recovery" />;
  }

  return (
    <OnboardingContainer>
      <Card
        title={<h1>{t('passwordRecovery:title')}</h1>}
        actions={[
          <Button
            type="primary"
            disabled={disabledSubmit}
            onClick={handleSubmit}
            htmlType="submit"
            loading={loading}
          >
            {t('passwordRecovery:Password.submit')}
          </Button>,
        ]}
        className="login-card"
      >
        <p>{t('passwordRecovery:Password.description')}</p>
        <p>{t('passwordRecovery:Password.passwordHelp')}</p>
        <Button type="link" onClick={toggleVisibility}>
          {t('passwordRecovery:Password.showPassword')}
        </Button>
        <Form onSubmit={handleSubmit}>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Password.emptyError'),
                },
                { validator: validatePasswordComplexity },
                { validator: validateWithConfirmPassword },
              ],
            })(
              <Input
                type={hidden ? 'password' : 'text'}
                prefix={<Icon type="lock" />}
                placeholder={t('common:placeholder.password')}
              />,
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Password.emptyErrorConfirmField'),
                },
                { validator: compareToFirstPassword },
              ],
            })(
              <Input
                onBlur={handleConfirmBlur}
                type={hidden ? 'password' : 'text'}
                prefix={<Icon type="lock" />}
                placeholder={t('common:placeholder.confirmPassword')}
              />,
            )}
          </Form.Item>
        </Form>
      </Card>
    </OnboardingContainer>
  );
};

PasswordRecovery.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedPasswordRecovery = Form.create()(PasswordRecovery);

export { WrappedPasswordRecovery };
export default WrappedPasswordRecovery;
