import { CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { channel } from 'redux-saga';
import { put, call, take, spawn, takeLatest, getContext, cps, select } from 'redux-saga/effects';
import { userPoolId, userPoolAppClient, idmLogin, carbonURL, ubiquityURL } from 'settings';
import { doFetchHospitals } from '@redux/hospitals/sagas';
import { selectHospital } from '@redux/hospitals/reducers';
import { setAppDetails } from '@organisation/redux/actions';
import CognitoUser from '../customCognitoUser';

import {
  LOGIN_SUBMITTED,
  authenticationRequired,
  authenticationFailed,
  AUTHENTICATION_SUBMITTED,
  loginRequired,
  loginFailed,
  sessionEstablished,
  LOGOUT,
  logoutSuccesful,
  GO_TO_PLATFORM_APP,
} from './actions';
import i18n from '../../i18n';
import {
  setIdentity,
  setSuperProperties,
  track,
  trackApplicationEvent,
  reset as analyticsReset,
} from '../../analytics';
import { selectRoleURIToUserTypeMap } from '@redux/dashboardUsers/reducers';
import { selectAppDetails } from '@organisation/redux/selectors';
import CustomCognitoUser from '../customCognitoUser';

export default function* loginSaga() {
  yield spawn(doCheckSession);
  yield takeLatest(LOGIN_SUBMITTED, onLoginSubmitted);
  yield takeLatest(LOGOUT, doLogout);
  yield takeLatest(GO_TO_PLATFORM_APP, doGoToPlatformApp);
}

export const getUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: userPoolAppClient,
  });
};

export function* establishSession(user) {
  const tokens = yield getContext('tokens');
  yield call(tokens.updateUser, user);
  const hospitalData = {};

  if (!tokens.loginProfileData.hospitalSlug) {
    yield call(doFetchHospitals);
    const [, hospital] = yield select(selectHospital(tokens.loginProfileData.hospitalId));

    if (hospital) {
      const { name: hospitalName, slug: hospitalSlug } = hospital;
      hospitalData.hospitalName = hospitalName;
      hospitalData.hospitalSlug = hospitalSlug;
    }
  }

  const attributes = yield cps(user.getUserAttributes.bind(user));
  const id = attributes.find(({ Name }) => Name === 'sub').Value;

  const firstName = tokens.loginProfileData.firstName || '';
  const lastName = tokens.loginProfileData.lastName || '';

  const storedLanguagePreference = localStorage.getItem(`${tokens.email}-language`);
  if (storedLanguagePreference) {
    i18n.changeLanguage(storedLanguagePreference);
  }

  yield put(
    setAppDetails({
      appId: tokens.solutionConfiguration.ubiquity_app_id,
      appToken: tokens.solutionConfiguration.app_token,
      companyId: tokens.solutionConfiguration.ubiquity_organisation_id,
      organisationId: tokens.organisation.id,
      organisationSlug: tokens.organisation.slug,
      solutionConfiguration: tokens.solutionConfiguration,
    }),
  );

  const mapRoleURIToUserType = yield select(selectRoleURIToUserTypeMap);

  const applicationRole = mapRoleURIToUserType[tokens.role];

  trackApplicationEvent('Login success');
  const analyticsProperties = {
    role: tokens.role,
    application_role: applicationRole,
    ...hospitalData,
    ...tokens.loginProfileData,
  };

  const appDetails = yield select(selectAppDetails);
  analyticsProperties.app_token = appDetails.appToken;

  setIdentity(id);
  setSuperProperties(analyticsProperties);
  trackApplicationEvent('Session started');

  // Create a cps compatible function for getUserData which has the callback and the params in the wrong order.
  // Node style callback functions are always supposed to have the callback as the last parameter.
  const getUserData = callback => {
    // Always get the latest user data in case it has changed.
    return user.getUserData(callback, { bypassCache: true });
  };

  const cognitoUserData = yield cps(getUserData);

  yield put(
    sessionEstablished(id, {
      name: `${firstName} ${lastName}`,
      role: applicationRole,
      email: tokens.email,
      profile: {
        ...hospitalData,
        ...tokens.loginProfileData,
      },
      preferredMfaSetting: cognitoUserData.PreferredMfaSetting ?? 'SMS_MFA',
    }),
  );
}

function* doCheckSession() {
  const userPool = getUserPool();
  const user = userPool.getCurrentUser();
  if (!user) {
    yield put(loginRequired());
    return;
  }
  try {
    yield cps(user.getSession.bind(user));
    yield call(establishSession, user);
  } catch (err) {
    // Invalid session
    console.error(err);
    yield put(loginRequired());
    return;
  }
}

export function* doCognitoLogin(user, email, password) {
  const callbackChan = channel();
  yield call(
    user.authenticateUser.bind(user),
    new AuthenticationDetails({
      Username: email.replace('@', '*'),
      Password: password,
    }),
    {
      onFailure: () => callbackChan.put({ status: 'FAILURE' }),
      onSuccess: () => callbackChan.put({ status: 'SUCCESS' }),
      newPasswordRequired: () => callbackChan.put({ status: 'NEW_PASSWORD_REQUIRED' }),
      mfaRequired: (challengeType, { CODE_DELIVERY_DESTINATION: codeDestination }) =>
        callbackChan.put({ status: 'MFA', codeDestination, challengeType }),
    },
  );
  const result = yield take(callbackChan);

  return result;
}

function* onLoginSubmitted({ payload: { email, password } }) {
  const userPool = getUserPool();
  const user = new CognitoUser({ Username: email.replace('@', '*').toLowerCase(), Pool: userPool });
  const result = yield call(doCognitoLogin, user, email.toLowerCase(), password);

  // eslint-disable-next-line default-case
  switch (result.status) {
    case 'SUCCESS':
      yield spawn(establishSession, user);
      return;
    // case "NEW_PASSWORD_REQUIRED":
    //   yield put(changePasswordRequired(user));
    //   return user;
    case 'FAILURE':
      trackApplicationEvent('Login failure');
      yield put(loginFailed());
      return;
    case 'MFA':
      // It is likely that the phone number that the message was sent to
      // Is likely in the challengeParameters returned from mfaRequired()
      // But the documentation isn't great we'll likely need to look at the
      // Call in the debugger.
      trackApplicationEvent('MFA required');
      yield put(authenticationRequired(result.challengeType, result.codeDestination));
      yield call(doMFAValidation, user, result.challengeType);
      return;
  }
}

function* doMFAValidation(user, challengeType) {
  const history = yield getContext('history');
  yield call(history.replace, '/auth/login/verify');

  while (true) {
    const {
      payload: { code },
    } = yield take(AUTHENTICATION_SUBMITTED);
    const chan = channel();
    track('MFA submitted');
    yield call(
      user.sendMFACode.bind(user),
      code,
      {
        onSuccess: () => chan.put('SUCCESS'),
        onFailure: () => chan.put('FAILURE'),
      },
      challengeType,
    );
    const result = yield take(chan);
    if (result === 'SUCCESS') {
      track('Login success');
      yield call(establishSession, user);
      break;
    } else {
      track('Login failure');
      yield put(authenticationFailed());
    }
  }
}

function* doLogout() {
  const tokens = yield getContext('tokens');
  const pool = getUserPool();
  const user = yield call(pool.getCurrentUser.bind(pool));
  yield call(() => Promise.all([tokens.logout(), user.signOut()]));
  trackApplicationEvent('Logout');
  analyticsReset();
  window.localStorage.clear();
  yield put(logoutSuccesful());
  window.location.reload();
}

function* doGoToPlatformApp({ payload: { platformAppId } }) {
  const tokenPool = yield getContext('tokens');
  const jwt = tokenPool.user.signInUserSession.idToken.jwtToken;
  console.log(jwt);

  let url = null;
  switch (platformAppId) {
    case 'carbon':
      url = carbonURL;
      break;
    case 'ubiquity':
      url = ubiquityURL;
      break;
    default:
      break;
  }

  // build a form to POST to IDM by navigating in the browser, so IDM can
  // establish an authenticated session and set a cookie for the user
  document.getElementById('idm-login-form-container').innerHTML = `
    <form id="idm-login-form" method="post" action="${idmLogin}">
      <input name="jwt" type="hidden" value="Bearer ${jwt}" />
      ${url ? `<input name="redirect_url" type="hidden" value="${url}" />` : ''}
    </form>`;
  const f = document.getElementById('idm-login-form');
  if (f) {
    f.submit();
  }
}

export function* setPreferredMfaSettings(preferredMethod, cognitoUser = null) {
  let user = cognitoUser;
  if (!user) {
    const pool = getUserPool();
    user = yield call(pool.getCurrentUser.bind(pool));
  }

  // Needed to support the wrapped setUserMfaPreference
  user = CustomCognitoUser.fromStandardUser(user);
  yield cps(user.getSession.bind(user));

  const smsMfaSettings = { Enabled: true, PreferredMfa: false };
  const emailMfaSettings = { Enabled: true, PreferredMfa: false };
  if (preferredMethod === 'SMS_MFA') {
    smsMfaSettings.PreferredMfa = true;
  } else {
    emailMfaSettings.PreferredMfa = true;
  }

  yield cps(user.setUserMfaPreference.bind(user), smsMfaSettings, null, emailMfaSettings);
}
