import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  fetchAppUsersForms,
  fetchFormsForAppUserResults,
  clearFormsForAppUserResults,
} from '@redux/appUsers/actions';
import {
  selectAppUserForms,
  selectAppUserFormSubmissions,
  selectAppUserResultFormsLoading,
} from '@redux/appUsers/reducers';
import { fetchAppUserExternalFormSubmissionForms } from '@redux/appUsers/actions';
import { fetchForms } from '@redux/forms/actions';
import { selectFormIdList } from '@redux/forms/reducers';
import IconButton from '@components/IconButton';
import { Card } from '@cards/Card';
import { SubmitFormModals, VISIBLE_MODAL } from '@cards/AppUsers/SubmitForm';
import { useSearch } from '@hooks';
import createColumns from './createColumns';
import './style.less';
import { selectOrganisationSlug } from '@organisation/redux/selectors';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const FormListCard = ({ appUserId, type, formTranslationKey, formSelector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const orgSlug = useSelector(selectOrganisationSlug());

  const [visibleModal, setVisibleModal] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [externalFormSubmissions, setExternalFormSubs] = useState();

  const columns = useMemo(() => createColumns(t), [t]);
  const [allFormSubmissionsLoading, allFormSubmissions] = useSelector(
    selectAppUserFormSubmissions(appUserId),
  );
  const formIdList = useSelector(selectFormIdList);
  const [formIdListIncludingExternalForms, setFormIdListIncludingExternalForms] = useState([]);

  useEffect(() => {
    if (formIdList.length > 0 && externalFormSubmissions?.length > 0) {
      setFormIdListIncludingExternalForms([
        ...formIdList,
        ...externalFormSubmissions.map(sub => sub.formId),
      ]);
    }
  }, [formIdList, externalFormSubmissions]);

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchAppUsersForms(appUserId));
  }, [dispatch, appUserId]);

  useEffect(() => {
    if (allFormSubmissions) {
      dispatch(fetchFormsForAppUserResults(allFormSubmissions));
    }
    // allFormSubmissions is new object every render so re-run this effect based on loading state
  }, [dispatch, allFormSubmissionsLoading, formIdList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formIdListIncludingExternalForms.length > 0) {
      return () => dispatch(clearFormsForAppUserResults(formIdListIncludingExternalForms));
    }
  }, [dispatch, formIdListIncludingExternalForms]);

  const appUserResultFormsLoading = useSelector(selectAppUserResultFormsLoading);
  const [formsLoading, forms] = useSelector(formSelector);

  const appUserFormsSelector = useCallback(selectAppUserForms(appUserId, forms), [
    appUserId,
    forms,
  ]);
  let [submissionsLoading, formSubmissions] = useSelector(appUserFormsSelector);

  useEffect(() => {
    if (allFormSubmissions?.length > 0 && orgSlug) {
      const externalFormSubs = allFormSubmissions.filter(
        formSub => formSub.organisationId !== orgSlug,
      );
      setExternalFormSubs(externalFormSubs);
    }
  }, [allFormSubmissions, orgSlug]);

  useEffect(() => {
    if (externalFormSubmissions?.length > 0) {
      dispatch(fetchAppUserExternalFormSubmissionForms(externalFormSubmissions));
    }
  }, [dispatch, externalFormSubmissions]);

  const [searchResults, searchProps] = useSearch(formSubmissions, filterFunc);

  const loading =
    formsLoading || submissionsLoading || allFormSubmissionsLoading || appUserResultFormsLoading;

  return (
    <Card.Half className="form-submission-list-card" noPadding>
      <div className="header-container">
        <h3>{t(`cards:PatientFormList.title.${formTranslationKey}`)}</h3>
        <div className="card-buttons">
          <IconButton
            key="add-form-submission-button"
            tooltip={t('cards:PatientFormList.submitTooltip')}
            icon={faPlus}
            onClick={() => setVisibleModal(VISIBLE_MODAL.SELECT)}
            tooltipPlacement="right"
          />
        </div>
      </div>
      <div className="search-container">
        <Input.Search placeholder="Search" {...searchProps}></Input.Search>
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey={record => `${record.uuid}-${record.submission.created}`}
        onRow={form => ({
          onClick: () => {
            history.push(`${history.location.pathname}/${form.uuid}/${form.submission.uuid}`);
          },
        })}
      />
      <SubmitFormModals
        appUserId={appUserId}
        formType={type}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        selectedForm={selectedForm}
        setSelectedForm={setSelectedForm}
        type={type}
        audienceTypesToFilter={['clinician']}
      />
    </Card.Half>
  );
};

export default FormListCard;
