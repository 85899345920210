import React from 'react';
import cn from 'classnames';
import { selectFlags } from '@redux/flags/reducers';
import { Statistic } from 'antd';
import { useSelector } from 'react-redux';
import { Card } from '@cards/Card';
import FlagIcon from '@components/FlagIcon';
import useFilters from '@hooks/useFilters';
import FlagPopover from '@components/FlagPopover';

export const FlagSummaryCard = ({ flag, selected, onSelect }) => {
  const className = cn('flag-summary-card', { selected });
  return (
    <FlagPopover flag={flag} noTitle={true}>
      <Card className={className} onClick={() => onSelect(flag)}>
        <Statistic
          title={flag.name}
          value={flag.appUserCount}
          prefix={<FlagIcon colour={flag.colour} size="md" />}
        />
      </Card>
    </FlagPopover>
  );
};

const FlagSummary = () => {
  const [, flags] = useSelector(selectFlags);

  const [currentFilters, , setFilter] = useFilters();

  const onSelect = flag => {
    if (currentFilters.flags?.includes(flag.uuid)) {
      setFilter(
        'flags',
        currentFilters.flags.filter(f => f !== flag.uuid),
      );
    } else {
      setFilter('flags', [flag.uuid]);
    }
  };

  return (
    <div className="appuser-list-flag-summary">
      {flags
        ?.filter(f => f.isFavourite && !f.isDisabled)
        .map(flag => (
          <>
            <FlagSummaryCard
              flag={flag}
              selected={currentFilters.flags?.includes(flag.uuid)}
              onSelect={onSelect}
            />
          </>
        ))}
    </div>
  );
};

export default FlagSummary;
