import {
  HOSPITAL_USER,
  HOSPITAL_ADMIN,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
} from '@constants';
import { UserType } from './types';

const userTypes: { [key: string]: UserType } = {
  [HOSPITAL_ADMIN]: HOSPITAL_ADMIN,
  [HOSPITAL_USER]: HOSPITAL_USER,
  [SUPER_ADMIN]: SUPER_ADMIN,
  [SUPPORT_ADMIN]: SUPPORT_ADMIN,
  [SYSTEM_ADMIN]: SYSTEM_ADMIN,
};

export function determineUserTypeFromRole(roleURI: string): UserType | undefined {
  try {
    const [, userType] = Object.entries(userTypes).find(([key]) => roleURI.includes(key))!;
    // TODO: remove default value when stuff is good;
    return userType || HOSPITAL_USER;
  } catch (err) {
    console.error('Missing role URI: ', roleURI);
    return;
  }
}

export const extractOrganisationSlug = /(.*:idm:)(.+)(:role.*)/;

export const getOrganisationSlugFromRoleURI = (roleURI: string) => {
  const match = extractOrganisationSlug.exec(roleURI);
  if (match && match?.length > 2) return match[2];
  return undefined;
};

export const replaceOrganisationSlug = (organisationSlug: string) => (
  match: string,
  p1: string,
  p2: string,
  p3: string,
) => `${p1}${organisationSlug}${p3}`;
