import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { Card, Column, ColumnGroup } from '@cards/Card';
import './style.less';

function ContentNotFound() {
  const { t } = useTranslation();

  return (
    <ColumnGroup>
      <Column>
        <Card className="content-not-found-card" title={t('common:contentNotFound.title')}>
          <Result
            status="404"
            subTitle={t('common:contentNotFound.explanation')}
            title={t('common:contentNotFound.subtitle')}
          />
        </Card>
      </Column>
      <Column />
    </ColumnGroup>
  );
}

ContentNotFound.propTypes = {};

export default ContentNotFound;
