import React from 'react';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import MapIndexEvents from './MapIndexEvents';

function DuplicateSharedPathwayWizard({
  match,
  onCompleted,
  initialData,
  loading,
  sharedPathway,
  indexEventsLocalisedNames,
  sharedIndexEventsLocalisedNames,
}) {
  const { t } = useTranslation();

  return (
    <Wizard loading={loading} onCompleted={onCompleted} enableFinalStepValidation={true}>
      <WizardItem
        stepKey="indexEvents"
        title={t('pathways:SharedPathways.duplicateWizard.mapIndexEvents')}
      >
        <MapIndexEvents
          match={match}
          initialData={initialData}
          sharedPathway={sharedPathway}
          indexEventsLocalisedNames={indexEventsLocalisedNames}
          sharedIndexEventsLocalisedNames={sharedIndexEventsLocalisedNames}
        />
      </WizardItem>
    </Wizard>
  );
}

export default DuplicateSharedPathwayWizard;
