import { put, call, takeEvery, getContext } from 'redux-saga/effects';
import { message } from 'antd';
import { IMessagingClient } from '@liquid-state/messaging-client';
import { doDetermineOwnerForContent } from '@redux/doDetermineOwnerIdForContent';
import i18n from '../../i18n';
import {
  deleteScheduledMessagesSuccess,
  deleteScheduledMessagesFailed,
  loadMessages,
  messagesLoaded,
  loadMessagesFailed,
  createScheduledMessageFailed,
  createScheduledMessageSuccess,
  IDeleteScheduledMessages,
  IDeleteScheduledMessage,
  ICreateScheduledMessage,
} from './actions';
import { IMessage } from './types';
import takeFirst from '../takeFirst';
import doCreateMessagingClient from '../doCreateMessagingClient';

export default function* root() {
  yield takeFirst('messages/fetch', doFetchMessages);
  yield takeEvery('messages/delete-many-scheduled', doDeleteScheduledMessages);
  yield takeEvery('messages/delete-scheduled', doDeleteScheduledMessage);
  yield takeEvery('messages/create-scheduled', doCreateScheduledMessage);
}

function ensureMessageData(message: IMessage) {
  return {
    ...message,
    title: message.title || '',
    metadata: { language: 'en', type: 'message', ...(message.metadata || {}) },
  };
}

function* doFetchMessages(): any {
  yield put(loadMessages());
  try {
    const client: IMessagingClient = yield call(doCreateMessagingClient);
    const ownerId = yield call(doDetermineOwnerForContent);
    let messages: IMessage[] = [];
    let page = 1;

    while (true) {
      const { nextPage, result } = yield call(client.listMessages, page, ownerId);
      messages = [...messages, ...result];
      if (!nextPage) {
        break;
      }
      page = nextPage;
    }

    // backend ISO date string has T omitted
    const nowISO = new Date().toISOString().replace('T', ' ');

    const { scheduled, sent } = messages.reduce(
      (
        prev: { scheduled: IMessage[]; sent: IMessage[] },
        message: IMessage,
      ): { scheduled: IMessage[]; sent: IMessage[] } => {
        if (message.isDeleted) return prev;

        if (message.lastSentDatetime) {
          return {
            scheduled: [
              ...prev.scheduled,
              ...(message.nextSendDatetime > nowISO ? [ensureMessageData(message)] : []),
            ],
            sent: [...prev.sent, ensureMessageData(message)],
          };
        }

        return { ...prev, scheduled: [...prev.scheduled, ensureMessageData(message)] };
      },
      { scheduled: [], sent: [] },
    );

    yield put(messagesLoaded(sent, scheduled));
  } catch (err) {
    console.error(err);
    yield put(loadMessagesFailed());
    yield call(message.error, i18n.t('messages:MessagesList.loadMessagesNetworkError'));
  }
}

function* doDeleteScheduledMessages({ payload: { messageIds } }: IDeleteScheduledMessages): any {
  try {
    const client = yield call(doCreateMessagingClient);

    for (const id of messageIds) {
      yield call(client.deleteMessage, id);
    }

    yield put(deleteScheduledMessagesSuccess(messageIds));
    yield call(message.success, i18n.t('messages:Scheduled.deleteSuccess'));
  } catch (err) {
    console.error(err);
    yield put(deleteScheduledMessagesFailed());
    yield call(message.error, i18n.t('messages:Scheduled.deleteFailure'));
  }
}

function* doDeleteScheduledMessage({ payload: { messageId } }: IDeleteScheduledMessage): any {
  const history = yield getContext('history');
  try {
    const client = yield call(doCreateMessagingClient);

    yield call(client.deleteMessage, messageId);

    yield put(deleteScheduledMessagesSuccess([messageId]));
    yield call(history.goBack);
    yield call(message.success, i18n.t('messages:Scheduled.deleteSuccess'));
  } catch (err) {
    console.error(err);
    yield put(deleteScheduledMessagesFailed());
    yield call(message.error, i18n.t('messages:Scheduled.deleteFailure'));
  }
}

function* doCreateScheduledMessage({ payload: { messageData } }: ICreateScheduledMessage): any {
  const history = yield getContext('history');

  try {
    const client = yield call(doCreateMessagingClient);
    const ownerId = yield call(doDetermineOwnerForContent);
    const newMessage = yield call(client.createMessage, { ...messageData, ownerId });

    yield put(createScheduledMessageSuccess(newMessage));
    yield call(history.push, '/messages/scheduled');
    yield call(message.success, i18n.t('messages:Scheduled.createSuccess'));
  } catch (err) {
    console.error(err);
    yield put(createScheduledMessageFailed());
    yield call(message.error, i18n.t('messages:Scheduled.createFailure'));
  }
}
