import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageActions } from '@hooks';
import { useTranslation } from 'react-i18next';
import WeblinkWizard from './Wizard';
import { createWeblink } from '@redux/weblinks/actions';
import { selectWeblinksCreating } from '@redux/weblinks/selectors';

function NewWeblink() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creating = useSelector(selectWeblinksCreating);

  usePageActions({ title: t('weblinks:Wizard.title') });

  const onCompleted = useCallback(
    formData => {
      dispatch(
        createWeblink({
          contentType: formData.details.contentType,
          description: formData.details.description,
          language: formData.details.language,
          name: formData.details.name,
          audience: formData.details.audience,
          url: formData.content.url,
        }),
      );
    },
    [dispatch],
  );

  return <WeblinkWizard submitting={creating} onCompleted={onCompleted} />;
}

export default NewWeblink;
