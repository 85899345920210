import React from 'react';
import Reorderer from '@components/Reorderer';
import ColourHexAndPreview from '@components/ColourHexAndPreview';
import ImageWithFallback from '@components/ImageWithFallback';
import { reorderOrganisationInstructionalScreens } from '@redux/organisations/actions';
import Text from 'antd/lib/typography/Text';
import './style.less';

const createColumns = (t, maxOrder, dispatch) => [
  {
    title: t('organisations:Branding.instructionalScreens.columns.text'),
    dataIndex: 'text',
    key: 'text',
    width: 150,
  },
  {
    title: t('organisations:Branding.instructionalScreens.columns.textColour'),
    dataIndex: 'textColour',
    key: 'textColour',
    render: textColour => <ColourHexAndPreview hex={textColour} />,
    width: 120,
  },
  {
    key: 'backgroundColour',
    dataIndex: 'backgroundColour',
    title: t('organisations:Branding.instructionalScreens.columns.backgroundColour'),
    render: backgroundColour => <ColourHexAndPreview hex={backgroundColour} />,
    width: 120,
  },
  {
    title: t('organisations:Branding.instructionalScreens.columns.image'),
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: imageUrl => {
      return imageUrl ? (
        <div className="image-upload">
          <ImageWithFallback className="image-upload" src={imageUrl} />
        </div>
      ) : (
        <Text>{t('organisations:Branding.instructionalScreens.noImage')}</Text>
      );
    },
    width: 150,
  },
  {
    title: t('organisations:Branding.instructionalScreens.columns.order'),
    dataIndex: 'order',
    key: 'order',
    width: 100,
    onCell: () => {
      return {
        onClick: event => {
          event.stopPropagation();
        },
      };
    },
    render: (order, { pk }) => {
      return (
        <Reorderer
          pk={pk}
          order={order}
          maxOrder={maxOrder}
          reorderer={options => dispatch(reorderOrganisationInstructionalScreens(options))}
        />
      );
    },
  },
];

export default createColumns;
