import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserFormListCard from '@cards/AppUsers/FormList';
import { selectFormsByType } from '@redux/forms/reducers';
import AppUserFormDetail from './AppUserFormDetail';
import { setCurrentScreen, screens } from '../../analytics';

const AppUserFormListTab = ({ type, formTranslationKey, match }) => {
  useEffect(() => {
    const screenName = `APP_USER_${type.toUpperCase()}_RESULTS`;
    setCurrentScreen(screens[screenName]);
  }, [type]);

  return (
    <Switch>
      <Route
        path={`${match.path}/:formId/:submissionId`}
        render={props => <AppUserFormDetail type={type} {...props} />}
      />
      <Route
        path={match.url}
        render={() => (
          <ColumnGroup>
            <Column>
              <AppUserFormListCard
                appUserId={match.params.id}
                type={type}
                formTranslationKey={formTranslationKey}
                formSelector={selectFormsByType(type)}
              />
            </Column>
          </ColumnGroup>
        )}
      />
    </Switch>
  );
};

export default AppUserFormListTab;
