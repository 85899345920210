import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUserById } from '@redux/dashboardUsers/reducers';
import UserTypeCard from './Card';

function DashboardUserUserType({ dashboardUserId }) {
  const dispatch = useDispatch();
  const [loading, dashboardUser] = useSelector(selectDashboardUserById(dashboardUserId));

  useEffect(() => {
    dispatch(fetchDashboardUsers());
  }, [dispatch]);

  return <UserTypeCard loading={loading} userType={dashboardUser?.userType} />;
}

DashboardUserUserType.propTypes = {
  dashboardUserId: string.isRequired,
};

export default DashboardUserUserType;
