import React from 'react';
import { shape, string } from 'prop-types';
import { Form } from 'antd';
import './style.less';

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

function DetailForm({ className, children, formProps }) {
  return (
    <Form className={`${className} detail-form`} {...formLayout} {...formProps}>
      {children}
    </Form>
  );
}

DetailForm.propTypes = {
  className: string,
  formProps: shape({}),
};

DetailForm.defaultProps = {
  className: '',
  formProps: {},
};

export default DetailForm;
