import { IDMService } from '@liquid-state/idm-client';
import doCreateIDMClient from './doCreateIDMClient';

function* doCreateIDMService() {
  const client = yield doCreateIDMClient();
  const service = new IDMService(client);

  return service;
}

export default doCreateIDMService;
