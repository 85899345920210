import React, { useState, useCallback } from 'react';
import { array, func } from 'prop-types';
import { Upload, Icon, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageWithFallback from '@components/ImageWithFallback';
import './style.less';

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

function ImageUpload({ imageList, setImageList, setRemove, imageUrl, imageSpecs }) {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState(imageUrl);
  const [uid, setUid] = useState(0);

  const disabled = imageList.length > 0;

  const onChange = useCallback(
    info => {
      setImageList(info.fileList);
      handlePreview(info.file);
      setRemove(false);
    },
    [setImageList, setRemove],
  );

  const handleRemove = useCallback(() => {
    setImageList(imageList.filter(image => image.uid !== uid));
    setPreviewImage('');
    setRemove(true);
  }, [imageList, setImageList, uid, setRemove]);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      setPreviewImage(file.url || file.preview);
      setUid(file.uid);
    }
  };

  return (
    <div className="image-uploader-with-preview">
      <Upload
        accept=".jpg, .png, .jpeg, .webp, .svg"
        disabled={imageList.length > 0}
        fileList={imageList}
        name="avatar"
        listType="picture-card"
        className="image-uploader"
        showUploadList={false}
        onChange={onChange}
      >
        <div className="upload-button">
          <Icon height="3em" type="cloud-upload" width="3em" />
          {disabled ? null : <p>{t('organisations:Branding.upload.help')}</p>}
          <p className="supported-upload">{t('organisations:Branding.upload.supported')}</p>
          <p className="supported-upload">{imageSpecs}</p>
        </div>
      </Upload>
      <div className="image-preview">
        {previewImage && (
          <Tag closable onClose={handleRemove}>
            <ImageWithFallback src={previewImage} />
          </Tag>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  imageList: array.isRequired,
  setImageList: func.isRequired,
};

export default ImageUpload;
