import React from 'react';
import { Modal, Form, Input, Checkbox } from 'antd';
import { func, bool, string } from 'prop-types';
import { restrictSharedSnapshotsCreation } from 'settings';
import './style.less';

const CreateSnapshotModal = ({
  t,
  onOk,
  onCancel,
  form: { getFieldDecorator, validateFieldsAndScroll },
  visible,
  primaryOrgSlug,
  orgSlug,
}) => {
  const handleCreateSnapshot = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        onOk(values);
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={handleCreateSnapshot}
      cancelText={t('pathways:ProcedurePathways.createSnapshot.cancel')}
      okText={t('pathways:ProcedurePathways.createSnapshot.ok')}
      title={t('pathways:ProcedurePathways.createSnapshot.title')}
      width={634}
    >
      <>
        <Form onSubmit={handleCreateSnapshot}>
          <h3>{t('pathways:ProcedurePathways.createSnapshot.helpText')}</h3>
          <p>{t('pathways:ProcedurePathways.createSnapshot.explanation')}</p>
          <br />
          <Form.Item label={t('pathways:ProcedurePathways.createSnapshot.name')}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('pathways:ProcedurePathways.createSnapshot.validation.name'),
                },
                {
                  min: 3,
                  message: t('pathways:ProcedurePathways.createSnapshot.validation.min'),
                },
                {
                  max: 300,
                  message: t('pathways:ProcedurePathways.createSnapshot.validation.max'),
                },
              ],
            })(
              <Input
                placeholder={t('pathways:ProcedurePathways.createSnapshot.namePlaceholder')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('pathways:ProcedurePathways.createSnapshot.description')}>
            {getFieldDecorator(
              'description',
              {},
            )(
              <Input.TextArea
                placeholder={t('pathways:ProcedurePathways.createSnapshot.descriptionPlaceholder')}
                autoSize={{ minRows: 3 }}
              />,
            )}
          </Form.Item>
          {!(restrictSharedSnapshotsCreation && orgSlug !== primaryOrgSlug) && (
            <Form.Item>
              {getFieldDecorator('makeShared', {
                valuePropName: 'checked',
              })(<Checkbox>{t('pathways:ProcedurePathways.createSnapshot.makeShared')}</Checkbox>)}
            </Form.Item>
          )}
        </Form>
      </>
    </Modal>
  );
};

CreateSnapshotModal.propTypes = {
  t: func.isRequired,
  onOk: func.isRequired,
  onCancel: func.isRequired,
  form: func.isRequired,
  visible: bool.isRequired,
  primaryOrgSlug: string,
  orgSlug: string,
};

const WrappedCreateSnapshotModal = Form.create()(CreateSnapshotModal);

export default WrappedCreateSnapshotModal;
