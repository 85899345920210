import React, { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Column, ColumnGroup } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { usePageActions } from '@hooks/usePageActions';

import FormDetailsStep from './FormDetailsStep';
import UploadFormStep from './UploadFormStep';
import ReviewStepColumns from './ReviewStepColumns';

import './style.less';

export const FormWizardContext = createContext();

function FormWizard({
  title,
  type,
  initialForm,
  initialFormData,
  onCompleted,
  loading,
  submitting,
  exitMessage,
  formTranslationKey,
  isEdit,
}) {
  const { t } = useTranslation();

  usePageActions({
    title,
  });

  document.title = title;

  const initialWizardData = useMemo(
    () => ({
      details: {
        name: initialForm.name,
        description: initialForm.description,
        language: initialForm.language,
        status: initialForm.published === 'notPublish' ? 'notPublish' : 'publish',
        audience: initialForm.audienceType,
      },
      content: {
        fileList:
          initialForm.name && initialFormData
            ? [
                {
                  uid: 'rc-upload-1585299106361-2',
                  lastModified: 1584490873633,
                  name: initialForm.metadata.fileName || 'form.json',
                  type: 'application/json',
                  percent: 0,
                },
              ]
            : [],
        json: initialFormData,
      },
    }),
    [initialForm, initialFormData],
  );

  return (
    <FormWizardContext.Provider value={type}>
      <Wizard
        onCompleted={onCompleted}
        loading={loading}
        submitting={submitting}
        initialData={initialWizardData}
        exitModalTitle={exitMessage}
      >
        <WizardItem stepKey="details" title={t('forms:FormWizard.steps.details.title')}>
          <ColumnGroup>
            <Column>
              <FormDetailsStep formTranslationKey={formTranslationKey} isEdit={isEdit} />
            </Column>
            <Column></Column>
          </ColumnGroup>
        </WizardItem>
        <WizardItem stepKey="content" title={t('forms:FormWizard.steps.content.title')}>
          <ColumnGroup>
            <Column>
              <UploadFormStep />
            </Column>
            <Column></Column>
          </ColumnGroup>
        </WizardItem>
        <WizardItem stepKey="review" title={t('forms:FormWizard.steps.review.title')}>
          <ColumnGroup>
            <ReviewStepColumns />
          </ColumnGroup>
        </WizardItem>
      </Wizard>
    </FormWizardContext.Provider>
  );
}

FormWizard.propTypes = {
  type: PropTypes.string.isRequired,
  onCompleted: PropTypes.func.isRequired,
  initialForm: PropTypes.object,
  initialFormData: PropTypes.object,
  exitMessage: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

FormWizard.defaultProps = {
  initialForm: {},
  initialFormData: undefined,
  isEdit: false,
};

export default FormWizard;
