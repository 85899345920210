import en from './en.json';
import es from './es.json';
import de from './de.json';
import cs from './cs.json';
import ja from './ja.json';
import sk from './sk.json';

export default {
  en: {
    ...en,
  },
  es,
  de,
  cs,
  ja,
  sk,
};
