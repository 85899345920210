import { IntegrationOrgConfig } from '@liquid-state/pephealth-client/dist/types';

export type ILoadOrgConfig = ReturnType<typeof loadOrgConfig>;
export const loadOrgConfig = (integrationSlug: string) => ({
  type: 'integrations/load-org-config' as const,
  payload: {
    integrationSlug,
  },
});

export type ILoadOrgConfigSuccess = ReturnType<typeof loadOrgConfigSuccess>;
export const loadOrgConfigSuccess = (integrationSlug: string, config: IntegrationOrgConfig) => ({
  type: 'integrations/load-org-config-success' as const,
  payload: {
    integrationSlug,
    config,
  },
});

export type ILoadOrgConfigFailure = ReturnType<typeof loadOrgConfigFailure>;
export const loadOrgConfigFailure = () => ({
  type: 'integrations/load-org-config-failure' as const,
  payload: {},
});

export type ICreateOrgConfig = ReturnType<typeof createOrgConfig>;
export const createOrgConfig = (integrationSlug: string, configData: object) => ({
  type: 'integrations/create-org-config' as const,
  payload: {
    integrationSlug,
    configData,
  },
});

export type ICreateOrgConfigSuccess = ReturnType<typeof createOrgConfigSuccess>;
export const createOrgConfigSuccess = (integrationSlug: string, config: IntegrationOrgConfig) => ({
  type: 'integrations/create-org-config-success' as const,
  payload: {
    integrationSlug,
    config,
  },
});

export type ICreateOrgConfigFailure = ReturnType<typeof createOrgConfigFailure>;
export const createOrgConfigFailure = () => ({
  type: 'integrations/create-org-config-failure' as const,
  payload: {},
});

export type IEditOrgConfig = ReturnType<typeof editOrgConfig>;
export const editOrgConfig = (integrationSlug: string, isEnabled: boolean, configData: object) => ({
  type: 'integrations/edit-org-config' as const,
  payload: {
    integrationSlug,
    isEnabled,
    configData,
  },
});

export type IEditOrgConfigSuccess = ReturnType<typeof editOrgConfigSuccess>;
export const editOrgConfigSuccess = (integrationSlug: string, config: IntegrationOrgConfig) => ({
  type: 'integrations/edit-org-config-success' as const,
  payload: {
    integrationSlug,
    config,
  },
});

export type IEditOrgConfigFailure = ReturnType<typeof editOrgConfigFailure>;
export const editOrgConfigFailure = () => ({
  type: 'integrations/edit-org-config-failure' as const,
  payload: {},
});
