import {
  FROM_ENTERING_A_STAGE,
  FROM_INDEX_EVENT,
  FROM_RULE_EXECUTION,
  UNTIL_ENTERING_A_STAGE,
  UNTIL_INDEX_EVENT,
  UNTIL_RULE_EXECUTION,
  FROM_FORM_SUBMISSION,
  UNTIL_FORM_SUBMISSION,
  START_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
  START_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
  START_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
  START_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  STOP_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION,
  STOP_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION,
  STOP_ENGAGEMENT_CHECK_FROM_INDEX_EVENT,
  STOP_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY,
} from '@constants';

export function getTimingDescription(type, daysOffset, name, t) {
  const i18nKeyBase = 'pathways:ProcedurePathways.engagementCheckWizard.timingStep';

  let i18nKey;
  let params;

  switch (type) {
    case FROM_INDEX_EVENT:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.from.indexEventDescriptionDaysAfter`;
      } else if (daysOffset < 0) {
        i18nKey = `${i18nKeyBase}.from.indexEventDescriptionDaysBefore`;
      } else {
        i18nKey = `${i18nKeyBase}.from.indexEventDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        indexEventName: name,
      };
      break;

    case FROM_ENTERING_A_STAGE:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.from.enteringStageDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.from.enteringStageDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        stageName: name,
      };
      break;

    case FROM_RULE_EXECUTION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.from.ruleExecutionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.from.ruleExecutionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        ruleName: name,
      };
      break;

    case UNTIL_INDEX_EVENT:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.until.indexEventDescriptionDaysAfter`;
      } else if (daysOffset < 0) {
        i18nKey = `${i18nKeyBase}.until.indexEventDescriptionDaysBefore`;
      } else {
        i18nKey = `${i18nKeyBase}.until.indexEventDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        indexEventName: name,
      };
      break;

    case UNTIL_ENTERING_A_STAGE:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.until.enteringStageDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.until.enteringStageDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        stageName: name,
      };
      break;

    case UNTIL_RULE_EXECUTION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.until.ruleExecutionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.until.ruleExecutionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        ruleName: name,
      };
      break;

    case FROM_FORM_SUBMISSION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.from.formSubmissionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.from.formSubmissionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        formName: name,
      };
      break;

    case UNTIL_FORM_SUBMISSION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.until.formSubmissionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.until.formSubmissionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        formName: name,
      };
      break;

    case START_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.start.stageEntryDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.start.stageEntryDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        stageName: name,
      };
      break;

    case START_ENGAGEMENT_CHECK_FROM_INDEX_EVENT:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.start.indexEventDescriptionDaysAfter`;
      } else if (daysOffset < 0) {
        i18nKey = `${i18nKeyBase}.start.indexEventDescriptionDaysBefore`;
      } else {
        i18nKey = `${i18nKeyBase}.start.indexEventDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        indexEventName: name,
      };
      break;

    case START_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.start.ruleExecutionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.start.ruleExecutionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        ruleName: name,
      };
      break;

    case START_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.start.formSubmissionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.start.formSubmissionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        formName: name,
      };
      break;

    case STOP_ENGAGEMENT_CHECK_FROM_STAGE_ENTRY:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.stop.stageEntryDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.stop.stageEntryDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        stageName: name,
      };
      break;

    case STOP_ENGAGEMENT_CHECK_FROM_INDEX_EVENT:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.stop.indexEventDescriptionDaysAfter`;
      } else if (daysOffset < 0) {
        i18nKey = `${i18nKeyBase}.stop.indexEventDescriptionDaysBefore`;
      } else {
        i18nKey = `${i18nKeyBase}.stop.indexEventDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        indexEventName: name,
      };
      break;

    case STOP_ENGAGEMENT_CHECK_FROM_RULE_EXECUTION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.stop.ruleExecutionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.stop.ruleExecutionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        ruleName: name,
      };
      break;

    case STOP_ENGAGEMENT_CHECK_FROM_FORM_SUBMISSION:
      if (daysOffset > 0) {
        i18nKey = `${i18nKeyBase}.stop.formSubmissionDescriptionDaysAfter`;
      } else {
        i18nKey = `${i18nKeyBase}.stop.formSubmissionDescriptionNoOffset`;
      }
      params = {
        daysOffset: Math.abs(daysOffset),
        formName: name,
      };
      break;

    default:
      break;
  }

  return t(i18nKey, params);
}
