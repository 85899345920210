import { selectDocument } from '../documents/reducers';
import { selectForm } from '../forms/reducers';
import { IState } from '../reducer';

export const selectPayloadContent = (messagePayload: { type: string; id: string }) => (
  state: IState,
) => {
  if (!messagePayload) return '';
  const { type, id } = messagePayload;

  switch (type) {
    case 'DOC':
      const document = selectDocument(id)(state);
      return document;
    case 'FORM':
      const form = selectForm(id)(state);
      return form;
    default:
      return '';
  }
};

export const selectMessagesLoading = (state: IState) => state.messages.loading;

export const selectSentMessages = (state: IState) => [state.messages.loading, state.messages.sent];

export const selectScheduledMessages = (state: IState) => [
  state.messages.loading,
  state.messages.scheduled,
];

export const selectSentMessage = (messageId: string) => (state: IState) => {
  const message = state.messages.sent.find(message => message.id === Number(messageId));

  return [state.messages.loading, message || {}];
};

export const selectScheduledMessage = (messageId: string) => (state: IState) => {
  const message = state.messages.scheduled.find(message => message.id === Number(messageId));

  return [state.messages.loading, message || {}];
};
