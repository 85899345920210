import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { arrayOf, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faExternalLinkSquareAlt, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button, Table } from 'antd';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
// import LanguageIcon from '@components/LanguageIcon';
import IconButton from '@components/IconButton';
import SelectPathwayModal from '@components/SelectPathwayModal';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import useWizard from '@components/Wizard/useWizard';
import { pathwayType } from '../../../../../propTypes';
import { track } from '../../../../../analytics';

// TODO: This might need to be extended to take a selector as a prop after the introduction of campaign pathways
function AddPathways({ addButtonText, detailsPath, pathways, title, addPathway, removePathway }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, allPathways] = useSelector(selectPathways);
  const [pathwayTemplates, setPathwayTemplates] = useState([]);
  const { formData } = useWizard();

  const addPathways = useCallback(
    selectedIds => {
      addPathway(selectedIds.map(id => pathwayTemplates.find(pathway => pathway.id === id)));
      setShowModal(false);
    },
    [pathwayTemplates, addPathway],
  );

  useEffect(() => {
    if (allPathways?.length > 0)
      setPathwayTemplates(allPathways.filter(pathway => !pathway.isSnapshot));
  }, [allPathways]);

  const columns = useMemo(
    () => [
      // {
      //   dataIndex: ['metadata', 'language'],
      //   key: 'language',
      //   title: t('patients:NewPatient.journeys.pathways.columns.language'),
      //   render: language => <LanguageIcon language={language} />,
      //   width: 120,
      // },
      {
        dataIndex: 'name',
        key: 'name',
        title: t('patients:NewPatient.journeys.pathways.columns.title'),
      },
      {
        dataIndex: 'isActive',
        key: 'published',
        title: t('patients:NewPatient.journeys.pathways.columns.published'),
        render: isActive => <PublishedStatusIcon published={isActive} />,
        width: 120,
      },
      {
        dataIndex: 'id',
        key: 'details',
        title: t('patients:NewPatient.journeys.pathways.columns.details'),
        render: id => (
          <Link to={`${detailsPath}/${id}`} target="_blank">
            <IconButton
              icon={faExternalLinkSquareAlt}
              tooltip={t('patients:NewPatient.journeys.pathways.columns.details')}
            />
          </Link>
        ),
        width: 120,
      },
      ...(removePathway
        ? [
            {
              dataIndex: 'id',
              key: 'delete',
              title: t('patients:NewPatient.journeys.pathways.columns.delete'),
              render: id => (
                <IconButton
                  icon={faTrashAlt}
                  tooltip={t('patients:NewPatient.journeys.pathways.columns.delete')}
                  onClick={() => removePathway(id)}
                />
              ),
              width: 120,
            },
          ]
        : []),
    ],
    [detailsPath, t, removePathway],
  );

  return (
    <>
      <div className="add-pathways-to-journey-header">
        <h4>{title}</h4>
        {addPathway ? (
          <Button
            icon="plus"
            onClick={() => {
              track('Add Pathway');
              setShowModal(true);
            }}
          >
            {addButtonText}
          </Button>
        ) : null}
      </div>

      <Table columns={columns} dataSource={pathways} pagination={false} rowKey="id" />
      <SelectPathwayModal
        defaultFilters={{ language: [formData.details.language] }}
        loading={loading}
        pathways={pathwayTemplates}
        selectedRowKeys={pathways.map(({ id }) => id)}
        visible={showModal}
        onSubmit={addPathways}
        onCancel={() => setShowModal(false)}
        clearOnClose
      />
    </>
  );
}

AddPathways.propTypes = {
  addButtonText: string.isRequired,
  detailsPath: string.isRequired,
  pathways: arrayOf(pathwayType).isRequired,
  title: string.isRequired,
  addPathway: func,
  removePathway: func,
};

export default AddPathways;
