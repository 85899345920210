import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTitleUpdate, usePageActions } from '@hooks';
// import { createHospital } from '@redux/hospitals/actions';
import { createOrganisation } from '@redux/organisations/actions';
import { selectOrganisationCreating } from '@redux/organisations/reducers';
import OrganisationWizard from './Wizard';

function NewOrganisation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setTitle } = usePageActions({ title: t('organisations:Wizard.title') });
  useTitleUpdate(setTitle, 'organisations:Wizard.title');
  const creating = useSelector(selectOrganisationCreating);

  const onCompleted = useCallback(
    formData => {
      dispatch(
        createOrganisation({
          ...formData.details,
          organisationName: formData.details.hospitalName,
          pathways: formData.pathways?.pathways.map(({ id }) => id) || [],
        }),
      );
    },
    [dispatch],
  );

  return <OrganisationWizard submitting={creating} onCompleted={onCompleted} />;
}

export default NewOrganisation;
