import { IState } from '@redux/reducer';
import { ISaveAppToken } from './actions';

type IAppTokensState = {
  [orgSlug: string]: string;
};

export default function appTokens(state: IAppTokensState = {}, action: ISaveAppToken) {
  switch (action.type) {
    case 'appTokens/saveAppToken':
      return {
        ...state,
        [action.payload.orgSlug]: action.payload.appToken,
      };
    default:
      return state;
  }
}

export const selectAppToken = (orgSlug: string) => (state: IState) => state.appTokens[orgSlug];
