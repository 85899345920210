import PropTypes from 'prop-types';
import { useEvaluatePermissions } from '@authorisation/hooks';

const WithPermissions = ({ permissions, children }) => {
  const permissionsOk = useEvaluatePermissions(...permissions);

  if (!permissionsOk) {
    return null;
  }

  return children;
};

WithPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default WithPermissions;
