import { select } from 'redux-saga/effects';
import {
  GLOBAL,
  HOSPITAL,
  whatTypesToContentType,
  getUnderlyingContentType,
  TRANSITION_NEXT,
} from '@constants';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { EngagementCheck } from '@liquid-gears/schemas/dist/types/engagement-check';
import {
  mapDashboardWhatToPathwaysWhat,
  mapPathwaysWhatToDashboardWhat,
} from '@pathways/redux/rules/utils';

export function* doDetermineOwnerForPathway(): any {
  const dashboardUser = yield select(selectCurrentDashboardUser);

  if (dashboardUser.profile.hospitalId) {
    return `${HOSPITAL}/${dashboardUser.profile.hospitalId}`;
  }

  // TODO: add handling for country and pathway admins

  return GLOBAL;
}

export const determineEngagementTimingType = (timingOption: string) => {
  if (timingOption?.includes('stage')) return 'stage';
  if (timingOption?.includes('index')) return 'index_event';
  if (timingOption?.includes('rule')) return 'rule_execution';
  if (timingOption?.includes('form')) return 'form_submission';
  return undefined;
};

export const mapEngagementCheckWhatToAction = (
  check: EngagementCheck,
): { what: string; whatDetail: any } => {
  const key = Object.keys(check.what)[0];
  const details = check.what[key] as { [key: string]: any };
  const mappedWhat = mapPathwaysWhatToDashboardWhat(key, details);

  return {
    what: mappedWhat,
    whatDetail: details,
  };
};

export const mapEngagementActionToWhat = (action: {
  what: string;
  whatDetail: { [key: string]: any };
}): [string, { [key: string]: any } | null] => {
  const [mappedWhat, mappedContentType] = mapDashboardWhatToPathwaysWhat(action.what);
  if (mappedWhat === TRANSITION_NEXT) {
    return [mappedWhat, null];
  }
  const whatDetail = {
    ...action.whatDetail,
    content_type: mappedContentType,
  };
  return [mappedWhat, whatDetail];
};

export const contentToEngagementEvents = (content: any) => {
  const contentArray = content.map((item: any) => {
    const ct = whatTypesToContentType[item.what as keyof typeof whatTypesToContentType];
    return {
      // content_type: engagementContentTypeMapping[item.metadata?.type as keyof typeof engagementContentTypeMapping],
      content_type: getUnderlyingContentType(ct).toLowerCase(),
      content_id:
        item.whatDetail?.content_id ||
        item.whatDetail?.product_id ||
        item.whatDetail?.id ||
        item.whatDetail?.uuid ||
        item.content_id ||
        item.product_id ||
        item.id ||
        item.uuid,
    };
  });
  return contentArray;
};
