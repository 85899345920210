import React from 'react';
import useWizard from '@components/Wizard/useWizard';
import EngagementCheckRuleDetails from '@pathways/cards/Pathways/EngagementCheckRuleDetails';
import { ColumnGroup, Column } from '@cards/Card';

import './style.less';

function Review({ pathway }) {
  const { formData } = useWizard();

  return (
    <ColumnGroup className="review-step">
      <Column>
        <EngagementCheckRuleDetails engagementCheck={formData} pathway={pathway} />
      </Column>
      <Column />
    </ColumnGroup>
  );
}

export default Review;
