import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Column, ColumnGroup } from '@cards/Card';
import DetailsCard from '@cards/Messages/Details';
import { selectSentMessage } from '@redux/messages/selectors';
import { usePageActions } from '@hooks';
import TimingCard from '@cards/Messages/Timing';
import AttachedContentCard from '@cards/Messages/AttachedContent';
import IndividualsCard from '@cards/Messages/Individuals';
import { OPEN_CONTENT } from '@constants';
import { matchType } from '../../propTypes';

function Details({ match }) {
  const selector = useCallback(selectSentMessage(match.params.id), [match.params.id]);
  const [loading, message] = useSelector(selector);
  const { setTitle } = usePageActions({ title: ' ', showBack: true });

  useEffect(() => {
    setTitle(message.title);
  }, [message.title, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard loading={loading} message={message} />
        {message?.payloadOptions?.action === OPEN_CONTENT ? (
          <AttachedContentCard content={message.payloadOptions.data} loading={loading} />
        ) : null}
      </Column>
      <Column>
        <TimingCard loading={loading} message={message} />
        <IndividualsCard userIds={message.users} />
      </Column>
    </ColumnGroup>
  );
}

Details.propTypes = {
  match: matchType.isRequired,
};

export default Details;
