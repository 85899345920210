import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { Card } from '@cards/Card';

import { selectWeblinkVersion } from '@redux/weblinks/selectors';
import { fetchWeblinkLatestVersion } from '@redux/weblinks/actions';

export default ({ weblinkId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWeblinkLatestVersion(weblinkId));
  }, [dispatch, weblinkId]);

  const [, version] = useSelector(selectWeblinkVersion(weblinkId));

  return (
    <Card
      title={t('weblinks:Wizard.content.stepName')}
      extra={
        <FontAwesomeIcon
          className="clickable-icon"
          icon={faExternalLinkSquare}
          onClick={() => window.open(version?.content_url)}
        />
      }
    >
      <DetailForm>
        <DetailRow label={t('weblinks:Wizard.review.content.url')} value={version?.content_url} />
        <DetailRow
          label={t('weblinks:Wizard.review.content.agreements')}
          value={t('weblinks:Wizard.review.content.accepted')}
        />
      </DetailForm>
    </Card>
  );
};
