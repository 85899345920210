import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons';
import { getTimingDescription } from '@pathways/pages/Pathways/EngagementCheckWizard/utils';

const createColumns = (t, actionButtonsVisible) => {
  const columns = [
    {
      title: t('pathways:ProcedurePathways.engagementChecks.name'),
      dataIndex: 'details.name',
      key: 'name',
      render: name => <span>{name}</span>,
      width: 300,
    },
    {
      title: t('pathways:ProcedurePathways.engagementChecks.contentItems'),
      dataIndex: 'contentToCheckEngagement.selectedContent',
      key: 'selectedContent',
      render: selectedContent => {
        if (selectedContent.length === 1) {
          return (
            <span>
              {selectedContent[0].whatDetail?.title ||
                selectedContent[0].title ||
                selectedContent[0].whatDetail?.name ||
                selectedContent[0].name}
            </span>
          );
        } else {
          return (
            <ul>
              {selectedContent.map(content => {
                return (
                  <li key={content.id}>
                    {content.whatDetail?.title ||
                      content.title ||
                      content.whatDetail?.name ||
                      content.name}
                  </li>
                );
              })}
            </ul>
          );
        }
      },
      width: 300,
    },
    {
      title: t('pathways:ProcedurePathways.engagementChecks.checkType'),
      dataIndex: 'typeOfCheck.checkType',
      key: 'typeOfCheck.checkType',
      render: (checkType, record) =>
        t(`pathways:ProcedurePathways.engagementChecks.checkDescriptions.${checkType}`, {
          numberOfContents: record.typeOfCheck.numberOfContents,
        }),
      width: 150,
    },
    {
      title: t('pathways:ProcedurePathways.engagementChecks.checkDescriptions.from.title'),
      dataIndex: 'timing.from',
      key: 'from',
      render: from => {
        if (!from) return '';
        return getTimingDescription(from.type, from.daysOffset, from.value?.name, t);
      },
      width: 300,
    },
    {
      title: t('pathways:ProcedurePathways.engagementChecks.checkDescriptions.until.title'),
      dataIndex: 'timing.until',
      key: 'until',
      render: until => {
        if (!until) return '';
        return getTimingDescription(until.type, until.daysOffset, until.value?.name, t);
      },
      width: 300,
    },
  ];

  if (actionButtonsVisible) {
    columns.push({
      title: t('common:buttons.preview'),
      dataIndex: 'onView',
      key: 'view',
      width: 90,
      render: onView => (
        <div className="view-button-container">
          <Button onClick={onView}>
            <FontAwesomeIcon icon={faExternalLinkSquare} />
          </Button>
        </div>
      ),
      fixed: 'right',
    });
  }

  return columns;
};

export default createColumns;
