import React from 'react';
import { bool, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import './style.less';

function UserDetailsCard({ details, loading }) {
  const { t } = useTranslation();
  return (
    <Card
      className="dashboard-users-user-details"
      loading={loading}
      title={t('cards:DashboardUsersDetails.title')}
    >
      <DetailForm>
        <DetailRow label={t('cards:DashboardUsersDetails.labels.name')} value={details.name} />
        <DetailRow
          label={t('cards:DashboardUsersDetails.labels.email')}
          value={
            <a href={`mailto:${details.email}`} rel="noopener noreferrer" target="_blank">
              {details.email}
            </a>
          }
        />
      </DetailForm>
    </Card>
  );
}

UserDetailsCard.propTypes = {
  details: shape({
    name: string,
    email: string,
  }),
  loading: bool.isRequired,
};

UserDetailsCard.defaultProps = {
  details: {},
};

export default UserDetailsCard;
