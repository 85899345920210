import { combineReducers } from 'redux';
import { IState } from '@redux/reducer';

import {
  KemtaiStaffMember,
  ILoadStaffList,
  ILoadStaffListSuccess,
  ILoadStaffListFailure,
  ILoadPatientDetails,
  ILoadPatientDetailsSuccess,
  ILoadPatientDetailsFailure,
  ICreatePatient,
  ICreatePatientSuccess,
  ICreatePatientFailure,
} from './actions';

const initialStatusState = {
  isLoading: false,
  isSubmitting: false,
};

const status = (
  state = initialStatusState,
  action:
    | ILoadStaffList
    | ILoadStaffListSuccess
    | ILoadStaffListFailure
    | ILoadPatientDetails
    | ILoadPatientDetailsSuccess
    | ILoadPatientDetailsFailure
    | ICreatePatient
    | ICreatePatientSuccess
    | ICreatePatientFailure,
) => {
  switch (action.type) {
    case 'kemtai/load-staff-list':
    case 'kemtai/load-patient-details':
      return { ...state, isLoading: true };
    case 'kemtai/load-staff-list-success':
    case 'kemtai/load-staff-list-failure':
    case 'kemtai/load-patient-details-success':
    case 'kemtai/load-patient-details-failure':
      return { ...state, isLoading: false };
    case 'kemtai/create-patient':
      return { ...state, isSubmitting: true };
    case 'kemtai/create-patient-success':
    case 'kemtai/create-patient-failure':
      return { ...state, isSubmitting: false };
    default:
      return state;
  }
};

const initialStaffList: KemtaiStaffMember[] = [];

const staffList = (state = initialStaffList, action: ILoadStaffListSuccess) => {
  switch (action.type) {
    case 'kemtai/load-staff-list-success':
      return action.payload.staffList;
    default:
      return state;
  }
};

const initialPatientDetails: any = {};

const patientDetails = (
  state = initialPatientDetails,
  action: ILoadPatientDetailsSuccess | ICreatePatientSuccess,
) => {
  switch (action.type) {
    case 'kemtai/load-patient-details-success':
    case 'kemtai/create-patient-success':
      const s = { ...state };
      s[action.payload.patient.app_user_id] = action.payload.patient;
      return s;
    default:
      return state;
  }
};

export const selectIsLoading = (state: IState) => {
  return state.kemtai.status.isLoading;
};

export const selectIsSubmitting = (state: IState) => {
  return state.kemtai.status.isSubmitting;
};

export const selectStaffList = (state: IState): [boolean, KemtaiStaffMember[]] => {
  return [state.kemtai.status.isLoading, state.kemtai.staffList];
};

export const selectPatientDetailsForAppUserId = (appUserId: string) => (state: IState): any => {
  if (!appUserId) return undefined;
  return state.kemtai.patientDetails[appUserId];
};

export default combineReducers({
  status,
  staffList,
  patientDetails,
});
