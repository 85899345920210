import { call } from 'redux-saga/effects';

export default function* doEnsurePipObjectType(
  client: { [key: string]: any }, // PIP Admin Client
  objectTypeSlug: string,
  appToken: string,
) {
  try {
    yield call(client.getObjectType, objectTypeSlug);
  } catch (err) {
    console.error("Object type doesn't exist: ", err);
    yield call(client.createObjectType, objectTypeSlug, appToken);
  }
}
