import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import Compose from './Compose';
import Summary from './Summary';
import './style.less';

function Timing() {
  const { t } = useTranslation();
  const { currentStepData, form, setCurrentStepData } = useWizard();
  const { dateSent, frequency, repeatEvery, repeatStops, when } = currentStepData;

  useEffect(() => {
    setCurrentStepData(form.getFieldsValue());
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ColumnGroup>
      <Column>
        <Compose />
      </Column>
      <Column>
        <Summary
          dateSent={dateSent}
          frequency={frequency}
          repeatEvery={repeatEvery}
          repeatStops={repeatStops}
          title={t('messages:Wizard.timing.summary.title')}
          when={when}
        />
      </Column>
    </ColumnGroup>
  );
}

export default Timing;
