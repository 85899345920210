import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { createLanguageFilters } from '@utils/supportedLanguages';
import { contentTypes, documentTypes, DOCUMENT } from '@utils/contentTypes';
import DocumentPublishedStatusColumn from '@cards/Documents/List/PublishedStatusColumn';
import { FEATURE_WEBLINK, WEBLINK } from '@constants';
import PublishedStatusIcon from '../PublishedStatusIcon';
// import LanguageIcon from '../LanguageIcon';

const createColumns = (t, dataSourceContentTypes, defaultFilteredValue) => [
  // {
  //   title: t('cards:DocumentList.columns.language.title'),
  //   defaultFilteredValue: defaultFilteredValue?.language || [],
  //   key: 'language',
  //   render: data => <LanguageIcon language={data.metadata.language || data.language} />,
  //   width: 150,
  //   sorter: (a, b) => (a.metadata.language || a.language) > (b.metadata.language || b.language),
  //   filters: createLanguageFilters(t),
  //   onFilter: (value, record) => {
  //     const lang = record.metadata.language || record.language || '';
  //     return lang.toLowerCase() === value;
  //   },
  //   fixed: 'left',
  // },
  ...(dataSourceContentTypes.length > 1
    ? [
        {
          title: t('common:modals.SelectContent.columns.contentType'),
          dataIndex: '',
          key: 'type',
          render: record => {
            const { metadata, whatDetail } = record;
            let type =
              metadata?.type || record.type || whatDetail?.metadata?.type || whatDetail?.type;
            if (!type && (record.metadata?.tags || whatDetail?.metadata?.tags)) {
              const tag =
                whatDetail?.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                ) ||
                record.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                );
              type = tag?.term;
            }
            if (!type && record.what === FEATURE_WEBLINK) {
              type = WEBLINK;
            }

            type = type || DOCUMENT;

            return (
              <span className="fa-icon-with-text">
                <FontAwesomeIcon icon={contentTypes[type]?.icon} />
                {t(contentTypes[type]?.label)}
              </span>
            );
          },
          width: 150,
          sorter: (a, b) => {
            let typeA =
              a.metadata?.type || a.type || a.whatDetail?.metadata?.type || a.whatDetail?.type;
            let typeB =
              b.metadata?.type || b.type || b.whatDetail?.metadata?.type || b.whatDetail?.type;
            if (!typeA && (a.metadata?.tags || a.whatDetail?.metadata?.tags)) {
              const tag =
                a.whatDetail?.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                ) ||
                a.metadata?.tags?.find(tag => contentTypes[tag.term] || documentTypes[tag.term]);
              typeA = tag ? tag.term : DOCUMENT;
            }
            if (!typeB && (b.metadata?.tags || b.whatDetail?.metadata?.tags)) {
              const tag =
                b.whatDetail?.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                ) ||
                b.metadata?.tags?.find(tag => contentTypes[tag.term] || documentTypes[tag.term]);
              typeB = tag ? tag.term : DOCUMENT;
            }

            if (!typeA && a.what === FEATURE_WEBLINK) {
              typeA = WEBLINK;
            }
            if (!typeB && b.what === FEATURE_WEBLINK) {
              typeB = WEBLINK;
            }

            typeA = typeA || DOCUMENT;
            typeB = typeB || DOCUMENT;

            return typeA.localeCompare(typeB);
          },
          filters: dataSourceContentTypes.map(type => ({
            text: t(contentTypes[type]?.label),
            value: type,
          })),
          onFilter: (value, record) => {
            let type =
              record.metadata?.type ||
              record.type ||
              record.whatDetail?.metadata?.type ||
              record.whatDetail?.type;
            if (!type && (record.metadata?.tags || record.whatDetail?.metadata?.tags)) {
              const tag =
                record.whatDetail?.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                ) ||
                record.metadata?.tags?.find(
                  tag => contentTypes[tag.term] || documentTypes[tag.term],
                );
              type = tag?.term;
            }
            if (!type && record.what === FEATURE_WEBLINK) {
              type = WEBLINK;
            }

            type = type || DOCUMENT;

            return type === value;
          },
        },
      ]
    : []),
  {
    title: t('common:modals.SelectContent.columns.title'),
    dataIndex: '',
    key: 'title',
    width: 150,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: ({ name, title }) => title || name,
  },
  {
    title: t('common:modals.SelectContent.columns.published'),
    key: 'published',
    render: (item, { whatDetail, id, metadata: { published, type } = {} }) => {
      const isPublished =
        item.published || whatDetail?.published || whatDetail?.metadata?.published || published;
      const contentId = whatDetail?.id || id;
      const contentType = whatDetail?.metadata?.type || whatDetail?.type || type;
      if (isPublished === undefined) return '';
      if (documentTypes.includes(contentType)) {
        return <DocumentPublishedStatusColumn documentId={contentId} />;
      }
      return <PublishedStatusIcon published={isPublished} />;
    },
    width: 150,
    sorter: (a, b) => {
      const isPublishedA =
        a.published ||
        a.whatDetail?.published ||
        a.whatDetail?.metadata?.published ||
        a.metadata?.published;
      const isPublishedB =
        b.published ||
        b.whatDetail?.published ||
        b.whatDetail?.metadata?.published ||
        b.metadata?.published;
      return isPublishedA - isPublishedB;
    },
  },
];

export default createColumns;
