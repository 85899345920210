import { UbiquityAdminClient } from '@liquid-state/ubiquity-client';
import { AdminClient } from '@liquid-state/ubiquity-client/dist/v2';
import { ubiquityApiRoot } from 'settings';

async function createUbiquityClient(tokens) {
  const jwt = await tokens.get('ubiquity');
  const client = new UbiquityAdminClient({ jwt }, { baseUrl: ubiquityApiRoot });

  return client;
}

async function createUbiquityV2Client(tokens) {
  const jwt = await tokens.get('ubiquity');
  const client = new AdminClient({ jwt }, { baseUrl: ubiquityApiRoot });

  return client;
}

export default createUbiquityClient;
export { createUbiquityV2Client };
