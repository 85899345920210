import React from 'react';
import { Modal } from 'antd';

const PagePreviewModal = ({ title, visible, url, handleCloseModal }) => {
  return (
    <Modal
      className="document-preview"
      title={title}
      visible={visible}
      onCancel={handleCloseModal}
      centered
      width="1024"
      footer={null}
    >
      <div className="iframe-container">
        <iframe width="1024" src={url} title={title} />
      </div>
    </Modal>
  );
};

export default PagePreviewModal;
