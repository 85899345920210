import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from '../../cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import MessageListCard from '@cards/CannedMessages/MessagesList';
import NewContentDropdown from '@components/NewContentDropdown';
import { setCurrentScreen, screens } from '../../analytics';

export default ({ match, messageTranslationKey, type }) => {
  useEffect(() => {
    const screenType = `CONTENT_${type.toUpperCase()}S_LIST`;
    setCurrentScreen(screens[screenType]);
  }, [type]);

  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t(`${messageTranslationKey}:MessagesList.header`),
    actions: [<NewContentDropdown key="messages-list" primaryKey={type} />],
  });
  useTitleUpdate(setTitle, `${messageTranslationKey}:MessagesList.header`);
  document.title = t(`${messageTranslationKey}:MessagesList.header`);

  return (
    <Column>
      <MessageListCard match={match} messageTranslationKey={messageTranslationKey} />
    </Column>
  );
};
