import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, string, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchWeblinks } from '@redux/weblinks/actions';
import { selectWeblinks } from '@redux/weblinks/selectors';
import createColumns from './createWeblinkColumns';
import ContentModal from './Modal';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function SelectWeblinkModal({ defaultFilteredValue, onCancel, onOk, audienceTypesToFilter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, weblinks] = useSelector(selectWeblinks);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue), [defaultFilteredValue, t]);
  useEffect(() => {
    dispatch(fetchWeblinks());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={weblinks.filter(weblink => !audienceTypesToFilter.includes(weblink.audience_type))}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.weblink')}
      filterFunc={filterFunc}
      onCancel={onCancel}
      onOk={weblink =>
        onOk({
          name: weblink.name,
          type: weblink.type,
          uuid: weblink.uuid,
        })
      }
    />
  );
}

SelectWeblinkModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
  audienceTypesToFilter: arrayOf(string),
};

SelectWeblinkModal.defaultProps = {
  audienceTypesToFilter: [],
};

export default SelectWeblinkModal;
