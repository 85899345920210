import { Column, ColumnGroup, Card } from '@cards/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popover } from 'antd';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import IconButton from '@components/IconButton';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import ColourHexAndPreview from '@components/ColourHexAndPreview';
import ImageWithFallback from '@components/ImageWithFallback';
import Text from 'antd/lib/typography/Text';
import { getOrganisationBranding } from '@redux/organisations/actions';
import { selectOrganisationBranding } from '@redux/organisations/reducers';
import { useTranslation } from 'react-i18next';
import './style.less';

function SplashScreen({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const branding = useSelector(selectOrganisationBranding);

  useEffect(() => {
    dispatch(getOrganisationBranding());
  }, [dispatch]);

  return (
    <ColumnGroup className="organisation-splash-screen">
      <Column>
        <Card
          className="organisation-details-card"
          title={t('organisations:Branding.splashScreen.title')}
          extra={
            <>
              <Popover
                overlayClassName="app-organisation-branding-extra-popover"
                content={<p>{t('organisations:Branding.splashScreen.popover.body')}</p>}
                placement="bottomRight"
                title={t('organisations:Branding.splashScreen.popover.title')}
                trigger="click"
              >
                <IconButton
                  icon={faInfoCircle}
                  tooltip={t('common:buttons.info')}
                  onClick={() => {}}
                />
              </Popover>
              <Icon
                type="edit"
                onClick={() => history.push('/administration/organisation/splash-screen/edit')}
              />
            </>
          }
        >
          {branding?.splash_screen_text ||
          branding?.splash_screen_background_colour ||
          branding?.splash_screen_text_colour ||
          branding?.splash_screen_image_url ? (
            <DetailForm>
              <DetailRow
                label={t('organisations:Branding.splashScreen.text')}
                value={branding.splash_screen_text}
              />
              <DetailRow
                label={t('organisations:Branding.splashScreen.textColour')}
                value={<ColourHexAndPreview hex={branding.splash_screen_text_colour} />}
              />
              <DetailRow
                label={t('organisations:Branding.splashScreen.backgroundColour')}
                value={<ColourHexAndPreview hex={branding.splash_screen_background_colour} />}
              />
              <DetailRow
                label={t('organisations:Branding.splashScreen.image')}
                value={
                  branding?.splash_screen_image_url ? (
                    <ImageWithFallback src={branding.splash_screen_image_url} />
                  ) : (
                    <Text>{t('organisations:Branding.splashScreen.noImage')}</Text>
                  )
                }
              />
            </DetailForm>
          ) : (
            <Text>{t('organisations:Branding.splashScreen.noSplashScreen')}</Text>
          )}
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default SplashScreen;
