import { FEATURES as featureConfig } from 'settings';

const useFeature = (...requestedFeatures) =>
  requestedFeatures.reduce(
    (features, feat) => ({
      ...features,
      [feat]: (featureConfig && featureConfig[feat]) || false,
    }),
    {},
  );

export default useFeature;
