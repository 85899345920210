import { Weblink } from '@liquid-state/ubiquity-client/dist/v2/types';
import {
  put,
  call,
  take,
  select,
  spawn,
  takeEvery,
  takeLatest,
  getContext,
} from 'redux-saga/effects';
import { message } from 'antd';
import { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import i18n from '../../i18n';
import takeFirst from '../takeFirst';
import {
  createWeblinkFailed,
  createWeblinkSuccess,
  deleteWeblinksSuccess,
  editWeblinkFailed,
  editWeblinkSuccess,
  ICreateWeblink,
  IDeleteWeblinks,
  IEditWeblink,
  loadWeblinks,
  weblinksLoaded,
  IFetchWeblinkLatestVersion,
  fetchWeblinkLatestVersionSuccess,
  fetchWeblinksLatestVersionFailed,
  // IFetchWeblinksByIds,
  // fetchWeblinksByIds,
  // fetchWeblinksByIdsSuccess,
  // fetchWeblinksByIdsFailed,
  loadWeblinkLatestVersion,
  ILoadWeblinkLatestVersion,
} from './actions';
import { selectAppDetails } from '@organisation/redux/selectors';
import ContentRef from '@utils/contentRef';

export default function* root() {
  yield takeFirst('weblinks/fetch', doFetchWeblinks);
  yield takeLatest('weblinks/load-latest-version', doLoadLatestVersion);
  yield takeEvery('weblinks/delete', doDeleteWeblinks);
  yield takeEvery('weblinks/create', doCreateWeblink);
  yield takeEvery('weblinks/edit', doEditWeblink);
  // yield takeEvery('weblinks/fetch-by-ids', doFetchWeblinksByIds);

  yield spawn(doFetchLatestVersion);
}
function* doFetchWeblinks(): any {
  try {
    yield put(loadWeblinks());
    const { appToken, organisationSlug } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).weblinks(appToken);
    const pagedClient = client.pagination().begin(client.list());
    let results: Weblink[] = [];
    while (pagedClient.hasNext()) {
      const nextData = yield call(pagedClient.next);
      results = results.concat(nextData);
    }

    const weblinks = results.map(weblink => {
      // FIXME: should add version to content ref here
      const contentRef = new ContentRef(organisationSlug, 'weblink', weblink.uuid);
      return { ...weblink, content: contentRef.toString() };
    });

    yield put(weblinksLoaded(weblinks));
  } catch (err) {
    console.error(err);
    yield put(weblinksLoaded([]));
    yield call(message.error, i18n.t('weblinks:List.loadFailed'));
  }
}

function* doFetchLatestVersion() {
  const uniqueIds = new Set();
  while (true) {
    const next: IFetchWeblinkLatestVersion = yield take('weblinks/fetch-latest-version');
    if (!next.payload.force && uniqueIds.has(next.payload.id)) {
      continue;
    }
    uniqueIds.add(next.payload.id);
    yield put(loadWeblinkLatestVersion(next.payload.id));
  }
}

function* doLoadLatestVersion({ payload: { id } }: ILoadWeblinkLatestVersion): any {
  try {
    const client = yield call(doCreateUbiquityV2Client);
    const { appToken } = yield select(selectAppDetails);
    const weblinkVersion = yield call(client.weblinks(appToken).getVersion, id, 'latest');

    yield put(fetchWeblinkLatestVersionSuccess(id, weblinkVersion));
  } catch (err) {
    yield put(fetchWeblinksLatestVersionFailed(id, err));
  }
}

// function* doFetchWeblinksByIds({ payload: { weblinkIds } }: IFetchWeblinksByIds): any {
//   try {
//     const client = yield call(doCreateUbiquityV2Client);
//     const { appToken } = yield select(selectAppDetails);
//     const

function* doDeleteWeblinks({ payload: { weblinkIds } }: IDeleteWeblinks): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).weblinks(appToken);
    for (const id of weblinkIds) {
      try {
        yield call(client.delete, id);
      } catch (err) {
        console.log(err);
      }
    }
    yield put(deleteWeblinksSuccess(weblinkIds));
  } catch (err) {
    console.error(err);
  }
}

function* doCreateWeblink({ payload: { weblink: weblinkData } }: ICreateWeblink): any {
  try {
    const ubiquity = yield call(doCreateUbiquityV2Client);
    const { appToken, organisationSlug } = yield select(selectAppDetails);
    const weblinksClient = ubiquity.weblinks(appToken);

    const weblink = yield call(weblinksClient.create, {
      name: weblinkData.name,
      description: weblinkData.description,
      audience_type: weblinkData.audience,
      metadata: { language: weblinkData.language },
    });

    const version = yield call(weblinksClient.createVersion, weblink, {
      type: weblinkData.contentType,
      content_url: weblinkData.url,
      metadata: { language: weblinkData.language },
    });

    yield call(weblinksClient.packageVersion, version);
    yield call(weblinksClient.makeAvailable, weblink);

    const contentRef = new ContentRef(organisationSlug, 'weblink', weblink.uuid);
    const finalWeblink = {
      ...weblink,
      content: contentRef.toString(),
      // these two properties are unavailable at the time we create the original weblink object, but we know what they are, so update them here.
      is_available: true,
      type: weblinkData.contentType,
      latest_version: {
        metadata: {
          language: weblinkData.language,
        },
      },
    };

    yield put(createWeblinkSuccess(finalWeblink, version));

    const history = yield getContext('history');
    yield call(history.goBack);
  } catch (err) {
    console.error(err);
    yield put(createWeblinkFailed());
  }
}

function* doEditWeblink({ payload: { id, weblink: weblinkData } }: IEditWeblink): any {
  try {
    const ubiquity = yield call(doCreateUbiquityV2Client);
    const { appToken, organisationSlug } = yield select(selectAppDetails);
    const weblinks = ubiquity.weblinks(appToken);

    const updated = yield call(weblinks.edit, id, {
      name: weblinkData.name,
      description: weblinkData.description,
      metadata: { langauge: weblinkData.language },
    });

    const version = yield call(weblinks.createVersion, updated, {
      type: weblinkData.contentType,
      content_url: weblinkData.url,
      metadata: { langauge: weblinkData.language },
    });

    yield call(weblinks.packageVersion, version);
    const fn =
      weblinkData.published === 'published' ? weblinks.makeAvailable : weblinks.makeUnavailable;
    yield call(fn, updated);

    const contentRef = new ContentRef(organisationSlug, 'weblink', updated.uuid);

    const finalWeblink = {
      ...updated,
      content: contentRef.toString(),
      is_available: weblinkData.published === 'published',
      type: weblinkData.contentType,
      latest_version: {
        metadata: {
          language: weblinkData.language,
        },
      },
    };

    yield put(editWeblinkSuccess(id, finalWeblink, version));

    const history = yield getContext('history');
    yield call(history.goBack);
  } catch (err) {
    console.error(err);
    yield put(editWeblinkFailed());
  }
}
