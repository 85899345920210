import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Divider, Form, Modal, Radio } from 'antd';
import { usePageActions, useTitleUpdate } from '@hooks';
import { Card, Column, ColumnGroup } from '@cards/Card';
import { isRequestSubmitting } from '@redux/requests/reducer';
import {
  submitGDPRRequestForAppUser,
  submitGDPRRequestForDashboardUser,
} from '@redux/requests/actions';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { GDPR_DELETE_REQUEST } from '@constants';
import { antFormType } from '../../../../propTypes';
import SelectAppUser from '../CreateDataRequest/SelectAppUser';
import SelectDashboardUser from '../CreateDataRequest/SelectDashboardUser';

const USER_TYPE_FIELD = 'userType';
const APP_USER = 'app-user';
const DASHBOARD_USER = 'dashboard-user';

function CreateDeletionRequest({ form }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const permissions = useHasPermissions();
  const [selectionModalType, setSelectionModalType] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const isSubmitting = useSelector(isRequestSubmitting);

  const { setTitle } = usePageActions({
    title: t('dataRequests:CreateDeletionRequest.title'),
    showClose: true,
  });
  useTitleUpdate(setTitle, 'dataRequests:CreateDeletionRequest.title');

  const onCancel = () => setSelectionModalType(null);
  const onSelectUser = ([userId], [user]) => {
    setUserInfo(user);
    setSelectionModalType(null);
  };

  const onSubmit = event => {
    event.preventDefault();
    Modal.confirm({
      content: t('dataRequests:CreateDeletionRequest.modal.description', {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
      }),
      okType: 'danger',
      title: t('dataRequests:CreateDeletionRequest.modal.title'),
      onOk: () => {
        switch (form.getFieldValue(USER_TYPE_FIELD)) {
          case APP_USER:
            dispatch(submitGDPRRequestForAppUser(GDPR_DELETE_REQUEST, userInfo));
            return;
          case DASHBOARD_USER:
            dispatch(submitGDPRRequestForDashboardUser(GDPR_DELETE_REQUEST, userInfo));
            return;
          default:
            return;
        }
      },
    });
  };

  return (
    <>
      <ColumnGroup>
        <Column>
          <Card className="create-gdpr-request">
            <h3>{t('dataRequests:CreateDataRequest.about')}</h3>
            <Alert
              description={t('dataRequests:CreateDeletionRequest.alert.description')}
              message={t('dataRequests:CreateDeletionRequest.alert.title')}
              showIcon
              type="warning"
            />
            <DetailForm formProps={{ onSubmit }}>
              {permissions[Permissions.ViewPatients] ? (
                <>
                  <h3>{t('dataRequests:CreateDataRequest.userType')}</h3>
                  {form.getFieldDecorator(USER_TYPE_FIELD)(
                    <Radio.Group>
                      <Radio value={APP_USER}>{t('dataRequests:CreateDataRequest.appUser')}</Radio>
                      <Radio value={DASHBOARD_USER}>
                        {t('dataRequests:CreateDataRequest.dashboardUser')}
                      </Radio>
                    </Radio.Group>,
                  )}
                </>
              ) : (
                form.getFieldDecorator(USER_TYPE_FIELD, { initialValue: DASHBOARD_USER })(<span />)
              )}
              <h3>{t('dataRequests:CreateDataRequest.profile')}</h3>
              <Button
                disabled={!form.getFieldValue(USER_TYPE_FIELD)}
                onClick={() => setSelectionModalType(form.getFieldValue(USER_TYPE_FIELD))}
              >
                {t('dataRequests:CreateDataRequest.select')}
              </Button>
              {userInfo ? (
                <>
                  <Divider className="top-divider" />
                  <DetailRow
                    label={t('common:name')}
                    value={`${userInfo.firstName} ${userInfo.lastName}`}
                  />
                  <DetailRow label={t('common:email')} value={userInfo.email} />
                  <Divider className="bottom-divider" />
                </>
              ) : null}
              <Button disabled={!userInfo} htmlType="submit" loading={isSubmitting} type="danger">
                {t('dataRequests:CreateDeletionRequest.submit')}
              </Button>
            </DetailForm>
          </Card>
        </Column>
        <Column />
      </ColumnGroup>
      <SelectAppUser
        visible={selectionModalType === APP_USER}
        onCancel={onCancel}
        onSubmit={onSelectUser}
      />
      <SelectDashboardUser
        visible={selectionModalType === DASHBOARD_USER}
        onCancel={onCancel}
        onSubmit={onSelectUser}
      />
    </>
  );
}

CreateDeletionRequest.propTypes = {
  form: antFormType.isRequired,
};

export default Form.create()(CreateDeletionRequest);
