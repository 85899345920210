import { combineReducers } from 'redux';
import {
  appId,
  appToken,
  ubiquityCompanyId,
  idmOrganisation,
  organisationSlug,
  videoUbiquityExportDetails,
} from 'settings';
import { ISetAppDetails } from './actions';
import { IAppDetails } from './types';

const appDetailsInitialState: IAppDetails = {
  appId,
  appToken,
  companyId: ubiquityCompanyId,
  organisationId: idmOrganisation,
  organisationSlug,
  solutionConfiguration: videoUbiquityExportDetails,
};
function appDetails(state: IAppDetails = appDetailsInitialState, action: ISetAppDetails) {
  switch (action.type) {
    case 'organisation/set-app-details':
      return action.payload.appDetails;
    default:
      return state;
  }
}

export default combineReducers({ appDetails });
