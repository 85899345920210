import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
// import Groups from './Groups';
import Individuals from './Individuals';
import './style.less';

function Audience() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { currentStepData, form } = useWizard();
  const validateAudience = useCallback(
    (rule, value, callback) => {
      if (currentStepData?.groups?.length || currentStepData?.individuals?.length) {
        setError('');
        return callback();
      }

      setError(rule.message);
      return callback(rule.message);
    },
    [currentStepData],
  );

  return (
    <>
      <ColumnGroup>
        {/* Disabled Groups for now */}
        {/* <Column>
          <Groups error={error} setError={setError} />
        </Column>
        <Column>
          <Individuals error={error} setError={setError} />
        </Column> */}
        <Column>
          <Individuals error={error} setError={setError} />
        </Column>
      </ColumnGroup>
      {form.getFieldDecorator('message-audience', {
        rules: [{ message: t('messages:Wizard.audience.required'), validator: validateAudience }],
      })(<span />)}
    </>
  );
}

export default Audience;
