import { takeEvery } from 'redux-saga/effects';
import {
  CREATE_PATHWAY,
  DELETE_PATHWAYS,
  DELETE_PATHWAY,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAYS,
  EDIT_PATHWAY,
  FETCH_PATHWAYS,
  PUBLISH_PATHWAYS,
  UNPUBLISH_PATHWAYS,
  FETCH_ENGAGEMENT_CHECKS,
  ADD_ENGAGEMENT_CHECK,
  EDIT_ENGAGEMENT_CHECK,
  DELETE_ENGAGEMENT_CHECK,
  CREATE_PATHWAY_SNAPSHOT,
  SHARE_PATHWAY_SNAPSHOT,
  UNSHARE_PATHWAY_SNAPSHOT,
  FETCH_PATHWAY_SNAPSHOTS,
} from '../types';
import takeFirst from '../../../../redux/takeFirst';
import { doDeletePathway, doDeletePathways, doDeleteEngagementCheck } from './delete';
import { doDuplicatePathway, doDuplicatePathways } from './duplicate';
import { doFetchPathways, doFetchEngagementChecks, doFetchPathwaySnapshots } from './fetch';
import { doPublishPathways, doUnpublishPathways } from './publish';
import {
  doCreatePathway,
  doEditPathway,
  doAddEngagementCheck,
  doEditEngagementCheck,
  doCreatePathwaySnapshot,
  doSharePathwaySnapshot,
  doUnsharePathwaySnapshot,
} from './save';

export default function* root() {
  yield takeEvery(CREATE_PATHWAY, doCreatePathway);
  yield takeEvery(DELETE_PATHWAYS, doDeletePathways);
  yield takeEvery(DELETE_PATHWAY, doDeletePathway);
  yield takeEvery(DUPLICATE_PATHWAY, doDuplicatePathway);
  yield takeEvery(DUPLICATE_PATHWAYS, doDuplicatePathways);
  yield takeEvery(EDIT_PATHWAY, doEditPathway);
  yield takeFirst(FETCH_PATHWAYS, doFetchPathways);
  yield takeEvery(FETCH_PATHWAY_SNAPSHOTS, doFetchPathwaySnapshots);
  yield takeEvery(PUBLISH_PATHWAYS, doPublishPathways);
  yield takeEvery(UNPUBLISH_PATHWAYS, doUnpublishPathways);
  yield takeEvery(ADD_ENGAGEMENT_CHECK, doAddEngagementCheck);
  yield takeEvery(EDIT_ENGAGEMENT_CHECK, doEditEngagementCheck);
  yield takeEvery(DELETE_ENGAGEMENT_CHECK, doDeleteEngagementCheck);
  yield takeEvery(FETCH_ENGAGEMENT_CHECKS, doFetchEngagementChecks);
  yield takeEvery(CREATE_PATHWAY_SNAPSHOT, doCreatePathwaySnapshot);
  yield takeEvery(SHARE_PATHWAY_SNAPSHOT, doSharePathwaySnapshot);
  yield takeEvery(UNSHARE_PATHWAY_SNAPSHOT, doUnsharePathwaySnapshot);
}
