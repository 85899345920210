import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { Card } from '@cards/Card';
import LanguageFormItem from '@components/LanguageFormItem';
import useWizard from '@components/Wizard/useWizard';

function DetailsCard({ messageTranslationKey }) {
  const { currentStepData, form } = useWizard();
  const { t } = useTranslation();

  return (
    <>
      <Card
        className="content-message-wizards-details"
        title={t('messages:Wizard.details.stepName')}
      >
        <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
          <Form.Item label={t('messages:Wizard.details.title.label')}>
            {form.getFieldDecorator('name', {
              initialValue: currentStepData.name,
              rules: [
                {
                  required: true,
                  message: t('messages:Wizard.details.title.required'),
                },
                {
                  max: 60,
                  message: t('common:Wizard.details.title.max60'),
                },
                {
                  pattern: /[A-Za-z]+/,
                  message: t('common:Wizard.details.title.pattern'),
                },
              ],
            })(<Input placeholder={t('messages:Wizard.details.title.label')} />)}
          </Form.Item>
          <LanguageFormItem currentLanguage={currentStepData.language} form={form} />

          <Form.Item label={t('cards:FormDetail.labels.status')}>
            {form.getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: t('forms:FormWizard.steps.details.feedback.statusRequired'),
                },
              ],
              initialValue: currentStepData.status || 'publish',
            })(
              <Select>
                <Select.Option key="publish">
                  {t(
                    `messages:ContentMessageWizard.statusOptions.${messageTranslationKey}.willPublish`,
                  )}
                </Select.Option>
                <Select.Option key="notPublish">
                  {t(
                    `messages:ContentMessageWizard.statusOptions.${messageTranslationKey}.willNotPublish`,
                  )}
                </Select.Option>
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default DetailsCard;
