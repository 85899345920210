import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Popover } from 'antd';

import './styles.less';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const AppUserNameOrEmail = ({
  appUser,
  forceEmailValue = false,
  displayInline = false,
  linkEmailValue = true,
}) => {
  const { t } = useTranslation();
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const obfuscatePIValue = '******';
  const nameValue = forceEmailValue ? appUser.email : appUser.displayName;
  const isEmailValue = forceEmailValue || nameValue === appUser.email;
  const displayMailToLink = isEmailValue && linkEmailValue && canViewPII && appUser.emailVerified;

  return (
    <div class="app-user-email">
      <p>
        {displayMailToLink ? (
          <a href={`mailto:${nameValue}`}>{nameValue}</a>
        ) : canViewPII ? (
          nameValue
        ) : (
          obfuscatePIValue
        )}
        {isEmailValue ? (
          <div className={`tag-container ${displayInline ? 'inline' : ''}`}>
            <Popover
              content={
                <>
                  <p>
                    {t(
                      `cards:PatientDetails.${
                        appUser.emailVerified ? 'emailVerifiedPopover' : 'emailUnverifiedPopover'
                      }.explanation1`,
                    )}
                  </p>
                  <p>
                    {t(
                      `cards:PatientDetails.${
                        appUser.emailVerified ? 'emailVerifiedPopover' : 'emailUnverifiedPopover'
                      }.explanation2`,
                    )}
                  </p>
                </>
              }
              title={t(
                `cards:PatientDetails.${
                  appUser.emailVerified ? 'emailVerifiedPopover' : 'emailUnverifiedPopover'
                }.title`,
              )}
              trigger="hover"
              placement="top"
              overlayClassName="email-verification-tag-popover"
            >
              {appUser.emailVerified ? (
                <Tag color="green">{t('cards:PatientDetails.labels.verified')}</Tag>
              ) : (
                <Tag color="red">{t('cards:PatientDetails.labels.unverified')}</Tag>
              )}
            </Popover>
          </div>
        ) : null}
      </p>
    </div>
  );
};

export default AppUserNameOrEmail;
