import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Icon, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  faFileAlt,
  faPlayCircle,
  faQuestionCircle,
  faFileEdit,
  faTasks,
  faCommentAltLines,
  faExclamationTriangle,
  faBell,
  faLink,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import { pathway } from '@assets/icons';
import {
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  TRANSITION_NEXT,
  FEATURE_WEBLINK,
} from '@constants';
import ContentPreview from '../../../../cards/Pathways/RuleDetails/ContentPreview';
import SelectDocumentModal from './SelectModals/SelectDocument';
import SelectVideoModal from './SelectModals/SelectVideo';
import SelectFormModal from './SelectModals/SelectForm';
import SelectQuestionnaireModal from './SelectModals/SelectQuestionnaire';
import SelectTaskModal from './SelectModals/SelectTask';
import SelectMessageModal from './SelectModals/SelectMessage';
import SelectAlertModal from './SelectModals/SelectAlert';
import SelectReminderModal from './SelectModals/SelectReminder';
import SelectWeblinkModal from './SelectModals/SelectWeblink';

const contentOptions = [
  {
    faIcon: faFileAlt,
    text: 'pathways:ProcedureRules.wizard.content.document',
    value: FEATURE_DOCUMENT,
  },
  {
    faIcon: faPlayCircle,
    text: 'pathways:ProcedureRules.wizard.content.video',
    value: FEATURE_VIDEO,
  },
  { lineBreak: true },
  {
    faIcon: faFileEdit,
    text: 'pathways:ProcedureRules.wizard.content.form',
    value: FEATURE_FORM,
  },
  {
    faIcon: faQuestionCircle,
    text: 'pathways:ProcedureRules.wizard.content.questionnaire',
    value: FEATURE_QUESTIONNAIRE,
  },
  {
    faIcon: faTasks,
    text: 'pathways:ProcedureRules.wizard.content.task',
    value: FEATURE_TASK,
  },
  { lineBreak: true },
  {
    faIcon: faLink,
    text: 'pathways:ProcedureRules.wizard.content.weblink',
    value: FEATURE_WEBLINK,
  },
  { lineBreak: true },
  {
    faIcon: faCommentAltLines,
    text: 'pathways:ProcedureRules.wizard.content.message',
    value: MESSAGE,
  },
  {
    faIcon: faExclamationTriangle,
    text: 'pathways:ProcedureRules.wizard.content.alert',
    value: MESSAGE_ALERT,
  },
  {
    faIcon: faBell,
    text: 'pathways:ProcedureRules.wizard.content.reminder',
    value: MESSAGE_REMINDER,
  },
  { lineBreak: true },
  {
    icon: pathway,
    text: 'pathways:ProcedureRules.wizard.content.stageTransition',
    value: TRANSITION_NEXT,
  },
];

function Content() {
  const [whatModalType, setWhatModalType] = useState(null);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [whatDetail, setWhatDetail] = useState(null);
  const { t } = useTranslation();
  const { currentStepData, form, formData, setCurrentStepData } = useWizard();

  useEffect(() => {
    if (currentStepData.whatDetail) {
      setWhatDetail(currentStepData.whatDetail);
      form.setFieldsValue({ whatDetail: currentStepData.whatDetail });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelection = useCallback(
    ({ target: { value } }) => {
      setWhatModalType(value);

      if (value === TRANSITION_NEXT) {
        setCurrentStepData({ what: null, whatDetail: null });
        setWhatDetail(null);
        form.setFieldsValue({ whatDetail: null });
      }
    },
    [form, setCurrentStepData],
  );
  const onCancel = useCallback(
    data => {
      setWhatModalType(null);
      setWhatDetail(null);
      form.setFieldsValue({ what: null, whatDetail: null });
      setCurrentStepData({ what: null, whatDetail: null });
    },
    [form, setCurrentStepData],
  );
  const onOk = useCallback(
    data => {
      setCurrentStepData({ what: whatModalType, whatDetail: data });
      setWhatModalType(null);
      setWhatDetail(data);
      form.setFieldsValue({ whatDetail: data });
    },
    [form, whatModalType, setCurrentStepData],
  );
  // const onClose = useCallback(() => {
  //   setWhatModalType(null);
  //   setWhatDetail(null);
  //   form.setFieldsValue({ what: null, whatDetail: null });
  // }, [form]);

  return (
    <>
      <Card.Full className="procedure-rule-card">
        <Form>
          <fieldset>
            <legend>{t('pathways:ProcedureRules.wizard.content.header')}</legend>

            <p className="info-text">{t('pathways:ProcedureRules.wizard.content.info')}</p>

            <Form.Item>
              {form.getFieldDecorator('what', {
                initialValue: currentStepData.what,
                rules: [
                  {
                    required: true,
                    message: t('pathways:ProcedureRules.wizard.content.validationError'),
                  },
                ],
              })(
                <Radio.Group onChange={handleSelection}>
                  {contentOptions.map((option, index) =>
                    option.lineBreak ? (
                      <br key={`line-break-${index}`} />
                    ) : (
                      <Radio key={option.value} value={option.value}>
                        {option.faIcon ? (
                          <FontAwesomeIcon icon={option.faIcon} />
                        ) : (
                          <Icon component={option.icon} />
                        )}{' '}
                        {t(option.text)}
                        {currentStepData.what === option.value &&
                        currentStepData.what !== TRANSITION_NEXT ? (
                          <Button
                            className="change-content-button"
                            onClick={() => setWhatModalType(option.value)}
                          >
                            {t('common:buttons.edit')}
                          </Button>
                        ) : null}
                      </Radio>
                    ),
                  )}
                </Radio.Group>,
              )}
            </Form.Item>
            {/* sneaky form field to store content data */}
            {form.getFieldDecorator('whatDetail')(<span />)}
          </fieldset>
          {whatModalType === FEATURE_DOCUMENT ? (
            <SelectDocumentModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === FEATURE_VIDEO ? (
            <SelectVideoModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === FEATURE_FORM ? (
            <SelectFormModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === FEATURE_QUESTIONNAIRE ? (
            <SelectQuestionnaireModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === FEATURE_TASK ? (
            <SelectTaskModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === FEATURE_WEBLINK ? (
            <SelectWeblinkModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === MESSAGE ? (
            <SelectMessageModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === MESSAGE_ALERT ? (
            <SelectAlertModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatModalType === MESSAGE_REMINDER ? (
            <SelectReminderModal
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
          {whatDetail && Object.values(whatDetail).length ? (
            <Button onClick={() => setPreviewModalVisible(true)}>
              {t('common:buttons.preview')}
            </Button>
          ) : null}
        </Form>
      </Card.Full>
      <ContentPreview
        rule={{ what: currentStepData.what, whatDetail: whatDetail || currentStepData.whatDetail }}
        visible={previewModalVisible}
        onCancel={() => setPreviewModalVisible(false)}
      />
    </>
  );
}

export default Content;
