import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import DetailRow from '@components/DetailRow';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import LanguageIcon from '@components/LanguageIcon';
import IconButton from '@components/IconButton';
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
} from '@fortawesome/sharp-solid-svg-icons';
import { Card } from '../../Card';
import { ColumnGroup, Column } from '@cards/Card';
import moment from 'moment';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import AppUserNameOrEmail from '../AppUserNameOrEmail';
import './styles.less';

const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AppUserDetailsCard = ({ appUser, title, loading, ...props }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const obfuscatePIValue = '******';

  return (
    <Card
      className="app-user-details-card"
      noPadding
      loading={loading}
      title={t(title)}
      extra={[
        <IconButton
          icon={isExpanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
          tooltip={t(
            isExpanded
              ? 'cards:PatientDetails.compressTooltip'
              : 'cards:PatientDetails.expandTooltip',
          )}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />,
      ]}
      {...props}
    >
      {appUser === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <ColumnGroup>
            <Column>
              <DetailRow
                label={t('cards:PatientDetails.labels.dateOfBirth')}
                value={
                  canViewPII
                    ? appUser.dateOfBirth
                      ? moment(appUser.dateOfBirth).format('L')
                      : ''
                    : obfuscatePIValue
                }
              />
              <DetailRow
                label={t('cards:PatientDetails.labels.mobile')}
                value={canViewPII ? appUser.phone : obfuscatePIValue}
              />

              {isExpanded ? (
                <>
                  <DetailRow
                    label={t('cards:PatientDetails.labels.address')}
                    value={
                      canViewPII
                        ? `${appUser.address ? `${appUser.address}, ` : ''}${
                            appUser.city ? `${appUser.city} ` : ''
                          }${appUser.postcode || ''}`
                        : obfuscatePIValue
                    }
                  />

                  <DetailRow
                    label={t('cards:PatientDetails.labels.sex')}
                    value={
                      canViewPII
                        ? appUser.sex
                          ? t(`cards:PatientDetails.labels.gender.${appUser.sex}`)
                          : ''
                        : obfuscatePIValue
                    }
                  />

                  {/* <DetailRow
                    label={t('cards:PatientDetails.labels.title')}
                    value={canViewPII ? appUser.title : obfuscatePIValue}
                  /> */}
                </>
              ) : null}
            </Column>

            <Column>
              <DetailRow label={t('cards:PatientDetails.labels.doctor')} value={appUser.doctor} />
              <DetailRow
                className="email"
                label={t('cards:PatientDetails.labels.email')}
                value={
                  <AppUserNameOrEmail
                    appUser={appUser}
                    forceEmailValue={true}
                    displayInline={true}
                  />
                }
              />

              {isExpanded ? (
                <>
                  <DetailRow
                    label={t('cards:PatientDetails.labels.language')}
                    value={<LanguageIcon showText language={appUser.language} />}
                  />

                  <DetailRow label={t('cards:PatientDetails.labels.userId')} value={appUser.id} />
                </>
              ) : null}
            </Column>
          </ColumnGroup>
        </Form>
      )}
    </Card>
  );
};

AppUserDetailsCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserDetailsCard.defaultProps = {
  title: 'cards:PatientDetails.title',
};

const AppUserDetailByIdCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  return <AppUserDetailsCard appUser={appUser} loading={loading} {...props} />;
};

export { AppUserDetailsCard };

export default AppUserDetailByIdCard;
