import { put, call, getContext, takeEvery } from 'redux-saga/effects';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
import {
  fetchSharedPathwaySnapshotsSuccess,
  fetchSharedPathwaySnapshotsFailed,
  utiliseSharedPathwaySnapshotSuccess,
  utiliseSharedPathwaySnapshotFailed,
  IUtiliseSharedPathwaySnapshot,
} from './actions';
import { IRawSharedPathwaySnapshot } from '../pathways/types';
import { mapRawSharedPathwaySnapshot } from '../pathways/utils';

export default function* sharedPathwaysRoot() {
  yield takeEvery('sharedPathways/fetchSharedPathwaySnapshots', doFetchSharedPathwaySnapshots);
  yield takeEvery('sharedPathways/utiliseSharedPathwaySnapshot', doUseSharedPathwaySnapshot);
}

export function* doFetchSharedPathwaySnapshots(): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    let page = 1;
    let next = true;
    let results: any[] = [];
    while (next) {
      const data = yield call(pathwaysClient.listSharedPathwaySnapshots, page);
      if (data.count) {
        results = results.concat(data.results);
      }
      next = data.next;
      page += 1;
    }

    const formattedSharedPathwaySnapshots = results.map((snapshot: IRawSharedPathwaySnapshot) =>
      mapRawSharedPathwaySnapshot(snapshot),
    );

    const directoryClient: OrganisationDirectoryClient = yield call(
      doCreateOrganisationDirectoryClient,
    );

    const organisations = yield Promise.all(
      formattedSharedPathwaySnapshots.map(snapshot =>
        directoryClient.getOrganisationBySlug(snapshot.parentOrganisationSlug, true),
      ),
    );

    formattedSharedPathwaySnapshots.forEach((snapshot, index) => {
      snapshot.parentName = organisations[index]?.results?.[0]?.organisation_name;
    });

    yield put(fetchSharedPathwaySnapshotsSuccess(formattedSharedPathwaySnapshots));
  } catch (err) {
    console.error(err);
    yield put(fetchSharedPathwaySnapshotsFailed());
  }
}

export function* doUseSharedPathwaySnapshot({
  payload: { snapshotId, indexEventTypes },
}: IUtiliseSharedPathwaySnapshot): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const snapshot = yield call(
      pathwaysClient.useSharedPathwaySnapshot,
      snapshotId,
      indexEventTypes,
    );
    yield put(utiliseSharedPathwaySnapshotSuccess(mapRawSharedPathwaySnapshot(snapshot)));

    const history = yield getContext('history');
    yield call(history.push, `/procedure/pathway/${snapshot.id}`);
  } catch (err) {
    console.error(err);
    yield put(utiliseSharedPathwaySnapshotFailed());
  }
}
