import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTitleUpdate, usePageActions } from '@hooks';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { editHospital, fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitalEditing, selectHospital } from '@redux/hospitals/reducers';
import HospitalWizard from '../Wizard';

function EditHospital({ match }) {
  const dispatch = useDispatch();
  const selector = useCallback(selectHospital(match.params.id), [match]);
  const [loading, hospital] = useSelector(selector);
  const [pathwaysLoading, pathways] = useSelector(selectPathways);
  const { t } = useTranslation();

  const interpolation = useMemo(() => ({ hospitalName: hospital?.name || '' }), [hospital]);
  const { setTitle } = usePageActions({
    title: t('hospitals:Wizard.editTitle', interpolation),
  });

  useTitleUpdate(setTitle, 'hospitals:Wizard.editTitle', interpolation);
  const editing = useSelector(selectHospitalEditing);

  useEffect(() => {
    dispatch(fetchHospitals());
    dispatch(fetchPathways());
  }, [dispatch]);

  const initialData = useMemo(() => {
    return {
      details: { hospitalName: hospital?.name },
      pathways: {
        pathways:
          hospital?.pathways
            .map(pathwayId =>
              /**
               * pathwayId coercion shouldn't be needed here. Somehow a hospital ended up with a pathwayId as a string.
               * I've been unable to track down the root cause of this bug. As far as I can see it's only affecting one hospital
               * in staging at this point so it's intermittent and likely will be hard to track down.
               *  */
              pathways.find(({ id }) => id === Number(pathwayId)),
            )
            .filter(Boolean) || [],
      },
    };
  }, [hospital, pathways]);

  const onCompleted = useCallback(
    formData => {
      dispatch(
        editHospital(match.params.id, {
          ...formData.details,
          pathways: formData.pathways?.pathways.map(({ id }) => id) || [],
        }),
      );
    },
    [dispatch, match],
  );

  return (
    <HospitalWizard
      editing
      initialData={initialData}
      loading={loading || pathwaysLoading}
      submitting={editing}
      onCompleted={onCompleted}
    />
  );
}

export default EditHospital;
