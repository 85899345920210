import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '../IconButton';
import './style.less';

function PublishingButton({ isPublished, isPublishing, publish, unpublish, disabled }) {
  const { t } = useTranslation();

  if (isPublishing) {
    return (
      <span className="publishing-spinner">
        <Icon type="loading" />
      </span>
    );
  }

  return (
    <IconButton
      key="toggle-published-status"
      icon={isPublished ? faTimesCircle : faCheckCircle}
      size="large"
      tooltip={isPublished ? t('common:buttons.unpublish') : t('common:buttons.publish')}
      onClick={isPublished ? unpublish : publish}
      disabled={disabled}
    />
  );
}

PublishingButton.propTypes = {
  isPublished: bool.isRequired,
  isPublishing: bool,
  publish: func.isRequired,
  unpublish: func.isRequired,
  disabled: bool,
};

PublishingButton.defaultProps = {
  isPublishing: false,
  disabled: false,
};

export default PublishingButton;
