import React, { useCallback, useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';

const stageNameTest = /stage-[0-9]{2}--name/;
const stageNumberTest = /stage-[0-9]{2}/;

function reorderStagesWithRules(indexA, indexB, stages) {
  if (indexA > indexB) {
    return [
      ...stages.slice(0, indexB),
      { ...stages[indexA], stageKey: stages[indexB].stageKey },
      { ...stages[indexB], stageKey: stages[indexA].stageKey },
      ...stages.slice(indexA + 1),
    ];
  }

  return [
    ...stages.slice(0, indexA),
    { ...stages[indexB], stageKey: stages[indexA].stageKey },
    { ...stages[indexA], stageKey: stages[indexB].stageKey },
    ...stages.slice(indexB + 1),
  ];
}

const generateNextStageKey = (prevStageKey = 'stage-0') =>
  `stage-${`0${Number(prevStageKey.replace('stage-', '')) + 1}`.slice(-2)}`;
const getNextStageNumber = (prevStageKey = 'stage-0') =>
  Number(prevStageKey.replace('stage-', '')) + 1;

function Stages({ isPathwayInUse }) {
  const [stages, setStages] = useState([]);
  const { t } = useTranslation();
  const { currentStepData, form, formData, setCurrentStepData } = useWizard();

  useEffect(() => {
    const existingStages = Object.keys(currentStepData)
      .filter(key => key.match(stageNameTest))
      .map(stage => stage.match(stageNumberTest)[0]);

    if (existingStages.length) {
      setStages(existingStages);
    } else {
      setStages([]);
    }

    if (formData.stagedRules) {
      form.setFieldsValue({ stages: formData.stagedRules.stages });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addStage = useCallback(() => {
    if (setStages.length >= 99) return;
    const existingStages = form.getFieldValue('stages');
    if (existingStages) {
      form.setFieldsValue({
        stages: [
          ...existingStages.slice(0, existingStages.length - 1),
          {
            isAdhoc: false,
            number: getNextStageNumber(stages[stages.length - 1]),
            stageKey: generateNextStageKey(stages[stages.length - 1]),
            rules: [],
            name: '',
            description: '',
          },
          existingStages[existingStages.length - 1],
        ],
      });
    }
    setStages([
      ...stages,
      `stage-${(
        '0' +
        (stages[stages.length - 1]
          ? Number(stages[stages.length - 1].replace('stage-', '')) + 1
          : 1)
      ).slice(-2)}`,
    ]);
  }, [form, stages]);

  const deleteStage = useCallback(
    (stage, index) => {
      const updatedStages = Object.entries(currentStepData).reduce((updated, [key, data]) => {
        if ([`${stage}--name`, `${stage}--description`].includes(key)) return updated;
        return { ...updated, [key]: data };
      }, {});
      setCurrentStepData(updatedStages);
      const existingStages = form.getFieldValue('stages');

      if (existingStages) {
        form.setFieldsValue({
          stages: [...existingStages.slice(0, index), ...existingStages.slice(index + 1)],
        });
      }
      setStages([...stages.slice(0, index), ...stages.slice(index + 1)]);
    },
    [currentStepData, form, stages, setCurrentStepData],
  );

  const switchStageOrder = useCallback(
    (indexA, indexB) => {
      const stageNameA = stages[indexA];
      const stageNameB = stages[indexB];
      if (form.getFieldValue('stages')) {
        form.setFieldsValue({
          stages: reorderStagesWithRules(indexA, indexB, form.getFieldValue('stages')),
        });
      }
      form.setFieldsValue({
        [`${stageNameB}--name`]: form.getFieldValue(`${stageNameA}--name`),
        [`${stageNameB}--description`]: form.getFieldValue(`${stageNameA}--description`),
        [`${stageNameA}--name`]: form.getFieldValue(`${stageNameB}--name`),
        [`${stageNameA}--description`]: form.getFieldValue(`${stageNameB}--description`),
      });
    },
    [form, stages],
  );

  return (
    <>
      <Card.Full className="stages-header-card">
        <h3>{t('pathways:ProcedurePathways.wizard.stages.header')}</h3>
        <Button icon="plus" type="dashed" onClick={addStage}>
          {t('pathways:ProcedurePathways.wizard.stages.newStage')}
        </Button>
      </Card.Full>
      {form.getFieldDecorator('stages')(<span />)}
      {stages.map((stage, index) => (
        <Card.Full className="pathway-stage-card" key={stage}>
          <div className="stage-sorter">
            <Button
              disabled={index === 0}
              icon="up"
              type="link"
              onClick={() => switchStageOrder(index - 1, index)}
            />
            <h2>{index + 1}</h2>
            <Button
              disabled={index === stages.length - 1}
              icon="down"
              type="link"
              onClick={() => switchStageOrder(index, index + 1)}
            />
          </div>
          <div className="stage-content-input">
            <Form.Item label={t('pathways:ProcedurePathways.wizard.stages.title')}>
              {form.getFieldDecorator(`${stage}--name`, {
                initialValue: currentStepData[`${stage}--name`],
                rules: [
                  {
                    required: true,
                    message: t('pathways:ProcedurePathways.wizard.stages.titleError'),
                  },
                ],
              })(
                <Input
                  placeholder={t('pathways:ProcedurePathways.wizard.stages.titlePlaceholder')}
                />,
              )}
            </Form.Item>
            <Form.Item label={t('pathways:ProcedurePathways.wizard.stages.description')}>
              {form.getFieldDecorator(`${stage}--description`, {
                initialValue: currentStepData[`${stage}--description`],
              })(
                <Input
                  placeholder={t('pathways:ProcedurePathways.wizard.stages.descriptionPlaceholder')}
                />,
              )}
            </Form.Item>
          </div>
          {isPathwayInUse ? null : (
            <div className="stage-deleter">
              <Button type="link" onClick={() => deleteStage(stage, index)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          )}
        </Card.Full>
      ))}
    </>
  );
}

Stages.propTypes = {
  isPathwayInUse: bool.isRequired,
};

export default Stages;
