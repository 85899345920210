import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions, useTitleUpdate } from '@hooks';
import { createFlag } from '@redux/flags/actions';
import { selectFlagCreating } from '@redux/flags/reducers';
import FlagWizard from './Wizard';

function NewFlag() {
  const creating = useSelector(selectFlagCreating);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({ showClose: true, title: t('flags:Wizard.title') });
  useTitleUpdate(setTitle, 'flags:Wizard.newTitle');

  const onSubmit = useCallback(
    data => {
      dispatch(createFlag(data));
    },
    [dispatch],
  );

  return (
    <FlagWizard
      submitting={creating}
      title={t('flags:Wizard.newDetailsTitle')}
      onSubmit={onSubmit}
    />
  );
}

NewFlag.propTypes = {};

export default NewFlag;
