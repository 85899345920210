import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Skeleton, Pagination } from 'antd';
import { clearDocumentPreview } from '@redux/documents/actions';
import { selectDocumentPreview } from '@redux/documents/reducers';

import './styles.less';

function DocumentPreview({ document, visible, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const preview = useSelector(selectDocumentPreview);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    return () => dispatch(clearDocumentPreview());
  }, [dispatch, document]);

  return (
    <Modal
      className="rule-document-preview-modal"
      title={document.name}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onCancel}>
          {t('common:buttons.close')}
        </Button>
      }
    >
      {preview.loading ? (
        <Skeleton />
      ) : (
        <div className="iframe-container">
          <iframe src={document.first_page_redirect_url} title={document.name} />
        </div>
      )}
      {preview.pages.length > 1 ? (
        <Pagination
          current={currentPage}
          defaultPageSize={1}
          total={preview.pages.length}
          onChange={page => {
            setCurrentPage(page);
          }}
        />
      ) : null}
    </Modal>
  );
}

export default DocumentPreview;
