import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectContentQuestionnaires } from '@redux/forms/reducers';
import { fetchForms } from '@redux/forms/actions';
import createColumns from './createFormColumns';
import ContentModal from './Modal';
import { FEATURE_QUESTIONNAIRE, whatTypeToTranslationKey } from '../../../../../../constants';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function SelectQuestionnaireModal({ defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, forms] = useSelector(selectContentQuestionnaires);
  const contentTypeTranslationKey = whatTypeToTranslationKey(FEATURE_QUESTIONNAIRE);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue, contentTypeTranslationKey), [
    defaultFilteredValue,
    t,
    contentTypeTranslationKey,
  ]);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={forms}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.questionnaire')}
      filterFunc={filterFunc}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}

SelectQuestionnaireModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectQuestionnaireModal;
