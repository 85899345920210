export default stages => {
  if (stages) {
    const rules = stages.flatMap(stage =>
      stage.rules.map(rule => ({ ...rule, stageSlug: stage.slug || stage.stageKey })),
    );

    const rulesWithOrder = rules.reduce(
      (acc, rule) => {
        const stageOrders = acc.stageOrders || {};
        const order = stageOrders[rule.stageSlug] || 0;

        stageOrders[rule.stageSlug] = order + 1;

        acc.result.push({
          ...rule,
          order,
        });

        acc.stageOrders = stageOrders;
        return acc;
      },
      { result: [], stageOrders: {} },
    ).result;

    const rulesByOrder = rulesWithOrder
      .reduce((acc, rule) => {
        const orderGroup = acc.find(group => group.order === rule.order);

        if (orderGroup) {
          orderGroup.rules.push(rule);
        } else {
          acc.push({ order: rule.order, rules: [rule] });
        }

        return acc;
      }, [])
      .sort((a, b) => a.order - b.order);

    return rulesByOrder;
  }

  return [];
};
