import React, { useState, useEffect } from 'react';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

function ImageWithFallback({ src }) {
  const [hasError, setHasError] = useState(false);
  const [imageSrc, setImageSrc] = useState(src);
  const { t } = useTranslation();

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  if (hasError) {
    return <Text>{t('common:ImageWithFallback.processing')}</Text>;
  }

  return (
    <img src={imageSrc} onError={() => setHasError(true)} alt="" style={{ maxWidth: '100%' }} />
  );
}

export default ImageWithFallback;
