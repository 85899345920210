import React from 'react';
import { bool, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Empty, Form } from 'antd';
import moment from 'moment';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import None from '@components/None';
import WeblinkTypeIcon from '@components/WeblinkTypeIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { Card } from '../../Card';
import './style.less';
import SourceIcon from '@components/SourceIcon';
import { HOSPITAL } from '@constants';

function WeblinkDetailsCard({ loading, showWarning, title, weblink, version, ...props }) {
  const { t } = useTranslation();

  return (
    <Card.Half className="weblinks-details-card" loading={loading} title={t(title)} {...props}>
      {weblink === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <DetailForm>
          {showWarning ? (
            <Alert
              description={t('cards:WeblinkDetails.alert.description', {
                contentType: t(`common:contentTypes.singular.${weblink.type.replace('_', '')}`),
              })}
              message={t('cards:WeblinkDetails.alert.message')}
              type="warning"
              showIcon
            />
          ) : null}
          <DetailRow
            label={t('cards:WeblinkDetails.labels.type')}
            value={<WeblinkTypeIcon showText type={weblink.type} />}
          />

          <DetailRow label={t('cards:WeblinkDetails.labels.title')} value={weblink.name} />

          <DetailRow label={t('cards:WeblinkDetails.labels.version')} value={version?.name} />

          <DetailRow
            label={t('cards:WeblinkDetails.labels.status')}
            value={<PublishedStatusIcon published={weblink.is_available} showText />}
          />

          <DetailRow
            label={t('cards:WeblinkDetails.labels.created')}
            value={moment(weblink.latest_version?.created).format('L')}
          />

          <DetailRow
            label={t('cards:WeblinkDetails.labels.description')}
            value={weblink.description || <None />}
          />

          <DetailRow
            label={t('cards:FormDetail.labels.source')}
            value={<SourceIcon source={weblink.owner || HOSPITAL} showText />}
          />

          <DetailRow
            label={t('cards:WeblinkDetails.labels.audience')}
            value={
              weblink.audience_type ? t(`cards:WeblinkDetails.labels.${weblink.audience_type}`) : ''
            }
          />
        </DetailForm>
      )}
    </Card.Half>
  );
}

WeblinkDetailsCard.propTypes = {
  loading: bool,
  showWarning: bool,
  title: string,
  weblink: shape({}),
  version: shape({}),
};

WeblinkDetailsCard.defaultProps = {
  loading: false,
  showWarning: false,
  title: 'cards:WeblinkDetails.title',
  weblink: undefined,
  version: undefined,
};

export default Form.create({ name: 'weblinkDetails' })(WeblinkDetailsCard);
