import { DashboardUser } from '@redux/dashboardUsers/types';

import { Organisation as DirectoryOrganisation } from '@liquid-state/directory-client';

export const FETCH_ORGANISATIONS = 'FETCH_ORGANISATIONS';
export const LOAD_ORGANISATIONS = 'LOAD_ORGANISATIONS';
export const LOAD_ORGANISATIONS_SUCCESS = 'LOAD_ORGANISATIONS_SUCCESS';
export const LOAD_ORGANISATIONS_FAILED = 'LOAD_ORGANISATIONS_FAILED';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAILED = 'CREATE_ORGANISATION_FAILED';
export const EDIT_ORGANISATION = 'EDIT_ORGANISATION';
export const EDIT_ORGANISATION_SUCCESS = 'EDIT_ORGANISATION_SUCCESS';
export const EDIT_ORGANISATION_FAILED = 'EDIT_ORGANISATION_FAILED';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAILED = 'UPDATE_ORGANISATION_FAILED';
export const GET_ORGANISATION_PUBLIC_CONTENT = 'GET_ORGANISATION_PUBLIC_CONTENT';
export const GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS = 'GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS';
export const GET_ORGANISATION_PUBLIC_CONTENT_FAILED = 'GET_ORGANISATION_PUBLIC_CONTENT_FAILED';
export const CREATE_ORGANISATION_PUBLIC_CONTENT = 'CREATE_ORGANISATION_PUBLIC_CONTENT';
export const CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS =
  'CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS';
export const REMOVE_ORGANISATION_PUBLIC_CONTENT = 'REMOVE_ORGANISATION_PUBLIC_CONTENT';
export const REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS =
  'REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS';
export const REORDER_ORGANISATION_PUBLIC_CONTENT = 'REORDER_ORGANISATION_PUBLIC_CONTENT';
export const REORDER_ORGANISATION_PUBLIC_CONTENT_SUCCESS =
  'REORDER_ORGANISATION_PUBLIC_CONTENT_SUCCESS';
export const GET_ORGANISATION_BRANDING = 'GET_ORGANISATION_BRANDING';
export const GET_ORGANISATION_BRANDING_SUCCESS = 'GET_ORGANISATION_BRANDING_SUCCESS';
export const GET_ORGANISATION_BRANDING_FAILED = 'GET_ORGANISATION_BRANDING_FAILED';
export const CREATE_ORGANISATION_BRANDING = 'CREATE_ORGANISATION_BRANDING';
export const CREATE_ORGANISATION_BRANDING_SUCCESS = 'CREATE_ORGANISATION_BRANDING_SUCCESS';
export const CREATE_ORGANISATION_BRANDING_FAILED = 'CREATE_ORGANISATION_BRANDING_FAILED';
export const UPDATE_ORGANISATION_BRANDING = 'UPDATE_ORGANISATION_BRANDING';
export const UPDATE_ORGANISATION_BRANDING_SUCCESS = 'UPDATE_ORGANISATION_BRANDING_SUCCESS';
export const UPDATE_ORGANISATION_BRANDING_FAILED = 'UPDATE_ORGANISATION_BRANDING_FAILED';
export const GET_ORGANISATION_INSTRUCTIONAL_SCREENS = 'GET_ORGANISATION_INSTRUCTIONAL_SCREENS';
export const GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS =
  'GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS';
export const GET_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED =
  'GET_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED';
export const CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN = 'CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN';
export const CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS =
  'CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS';
export const CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED =
  'CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED';
export const UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN = 'UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN';
export const UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS =
  'UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS';
export const UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED =
  'UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED';
export const REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS =
  'REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS';
export const REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS =
  'REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS';
export const REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED =
  'REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED';
export const REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS =
  'REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS';
export const REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS =
  'REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS';
export const REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED =
  'REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS_FAILED';
export const REMOVE_ORGANISATION_IMAGE = 'REMOVE_ORGANISATION_IMAGE';

// export type IOrganisation = {
//   uuid: string;
//   slug: string;
//   country?: string;
//   language: string;
//   name: string;
//   created: string;
//   contactDetails: {
//     email?: string;
//     openHours?: string;
//     phone?: string;
//     address?: string;
//     addressSecondLine?: string;
//     type?: 'public' | 'private';
//   };
//   socialMedia: {
//     type: 'facebook' | 'twitter' | 'website';
//     link: string;
//   }[];
//   content?: { [key: string]: any }[];
//   defaultConnectionCode: string;
//   admin: {
//     id: string;
//     name: string;
//     email: string;
//   };
// };

export type Organisation = DirectoryOrganisation;

export type IAdminOrganisation = {
  appToken: string;
  organisationId: string;
  id: number;
  name: string;
  organisationSlug: string;
  slug: string;
  ubiquity: {
    appId: number;
    organisationId: number;
    organisationSlug: string;
  };
};

export type ISolutionConfiguration = {
  body: {
    app_token: string;
    organisation_id: string;
    organisation_slug: string;
    ubiquity_app_id: number;
    ubiquity_organisation_id: number;
    ubiquity_organisation_slug: string;
  };
  organisation: {
    pk: number;
    slug: string;
    name: string;
  };
  solution_name: string;
};

export type IOrganisationInvites = {
  [key: string]: number;
};

export type ICreateOrganisationData = {
  email: string;
  firstName: string;
  organisationName: string;
  language: string;
  lastName: string;
  pathways: number[];
  organisationPathways?: number[];
  content?: { [key: string]: any }[];
  users?: DashboardUser[];
};

export type EditOrganisationData = {
  email?: string;
  firstName?: string;
  organisationName?: string;
  language?: string;
  lastName?: string;
  pathways?: number[];
  organisationPathways?: number[];
  content?: { [key: string]: any }[];
  users?: DashboardUser[];
};

export type ReorderOptions = {
  primaryKey: number;
  newOrder: number;
};
