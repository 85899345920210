import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrgConfig } from '@integrations/redux/actions';
import { selectOrgConfig } from '@integrations/redux/reducers';

const useIntegrationConfiguration = (integrationSlug: string) => {
  const dispatch = useDispatch();
  // const { t } = useTranslation()
  const [didRun, setDidRun] = useState(false);
  const [, kemtaiOrgConfig] = useSelector(selectOrgConfig(integrationSlug));

  useEffect(() => {
    if (!didRun) {
      setDidRun(true);
      dispatch(loadOrgConfig(integrationSlug));
    }
  }, [didRun, integrationSlug, dispatch]);

  return kemtaiOrgConfig;
};

export default useIntegrationConfiguration;
