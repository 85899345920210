import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions, useTitleUpdate } from '@hooks';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { createScheduledMessage } from '@redux/messages/actions';
import { selectMessagesLoading } from '@redux/messages/selectors';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import { convertISOStringToPythonISOString } from '@utils';
import Details from './Details';
import Timing from './Timing';
import Audience from './Audience';
import Review from './Review';
import { NOW } from './Timing/Compose';
import { historyType } from '../../../propTypes';
import { DOCUMENT, VIDEO } from '@utils/contentTypes';

function getPayloadOptionsData(details) {
  switch (details.action) {
    case OPEN_CONTENT:
      return details.contentData;
    case OPEN_LINK:
      return details.websiteLink;
    default:
      return null;
  }
}

function NewMessage({ history, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({
    showClose: true,
  });

  const messageType = useMemo(() => {
    return history.location.search ? history.location.search.replace('?type=', '') : 'MESSAGE';
  }, [history.location.search]);

  useTitleUpdate(setTitle, `messages:Wizard.title.${messageType.toLowerCase()}`);

  const messageLoading = useSelector(selectMessagesLoading);

  const onCompleted = useCallback(
    wizardData => {
      const { audience, details, timing } = wizardData;

      const messageData = {
        audienceType: 'segmented',
        content: details.content,
        metadata: {
          type: messageType,
          source: 'global',
          language: details.language || 'en',
        },
        payloadOptions: {
          action: details.action,
          data: getPayloadOptionsData(details),
        },
        scheduledDatetime: convertISOStringToPythonISOString(
          timing.when === NOW ? new Date().toISOString() : timing.dateSent.toISOString(),
        ),
        scheduleType: 'scheduled',
        title: details.title,
        ...(audience.individuals?.length
          ? {
              users: audience.individuals.reduce(
                (prev, { ids, userUrl }) => (userUrl ? [...prev, ids.ubiquity] : prev),
                [],
              ),
            }
          : {}),
        ...(audience.groups?.length ? { groups: audience.groups.map(({ id }) => id) } : {}),
        ...(timing.frequency === 'repeat'
          ? {
              isRecurring: true,
              recurringOffset: timing.repeatEvery.number,
              recurringUnit: timing.repeatEvery.units,
              recurringEndDatetime:
                timing.repeatStops === 'on-date'
                  ? convertISOStringToPythonISOString(timing.repeatStops?.stopDate)
                  : null,
            }
          : {}),
      };

      if (
        messageData.payloadOptions.data?.metadata &&
        [DOCUMENT, VIDEO].includes(messageData.payloadOptions.data.metadata.type)
      ) {
        const {
          id,
          latest_version,
          metadata: { language, tags, type },
          name,
          content,
          owner_id,
          product_id,
          slug,
          token,
        } = messageData.payloadOptions.data;

        messageData.payloadOptions.data = {
          id,
          latest_version: { name: latest_version.name, description: latest_version.description },
          metadata: { language, tags, type },
          name,
          content,
          owner_id,
          product_id,
          slug,
          token,
        };
      }

      dispatch(createScheduledMessage(messageData));
    },
    [dispatch, messageType],
  );

  const initialContentId = useMemo(() => {
    const { content } = match.params;
    return isNaN(content) ? content : parseInt(content);
  }, [match.params]);

  return (
    <Wizard submitting={messageLoading} onCompleted={onCompleted}>
      <WizardItem stepKey="details" title={t('messages:Wizard.details.stepName')}>
        <Details initialContentId={initialContentId} />
      </WizardItem>
      <WizardItem stepKey="timing" title={t('messages:Wizard.timing.stepName')}>
        <Timing />
      </WizardItem>
      <WizardItem stepKey="audience" title={t('messages:Wizard.audience.stepName')}>
        <Audience />
      </WizardItem>
      <WizardItem stepKey="review" title={t('messages:Wizard.review.stepName')}>
        <Review messageType={messageType} />
      </WizardItem>
    </Wizard>
  );
}

NewMessage.propTypes = {
  history: historyType.isRequired,
};

export default NewMessage;
