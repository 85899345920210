import { put, call, getContext, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';
import { inviteAppUserSuccess } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { selectPathway } from '@pathways/redux/pathways/reducers';
import { mapSnakeCaseAppUserPathwayToCamelCase } from '../utils';
import i18n from '../../../../i18n';
import { APP_USER_JOURNEYS_LOADED, INVITE_APP_USER_WITH_PATHWAYS_SUCCESS } from '../types';
import {
  addPathwayToAppUsersSuccess,
  appUserPathwayStageUpdated,
  fetchAppUserJourneys,
  inviteAppUserWithPathwaysFailed,
  inviteAppUserWithPathwaysSuccess,
  setAppUserPathwayArchivedStatusFailed,
  setAppUserPathwayArchivedStatusSuccess,
  editAppUserJourneyFailed,
  editAppUserJourneySuccess,
  triggerAdhocRuleFailed,
  triggerAdhocRuleSuccess,
  actionJourneyEntryFailed,
  actionJourneyEntrySuccess,
} from '../actions';
import { selectJourneysForAppUser } from '../reducers';
import { doFetchAppUserJourneys, doFetchAppUsersPathways } from './fetch';
import { doDetermineOwnerForPathway } from '@pathways/redux/pathways/sagas/utils';

function* doTransitionAppUserToPathwayStage(appUserId, appUserPathwayId, stageSlug) {
  const pathwaysClient = yield call(doCreatePathwaysClient);

  yield call(
    pathwaysClient.transitionAppUserToPathwayStage,
    appUserId,
    appUserPathwayId,
    stageSlug,
  );
}

export function* doAddPathwayToAppUsers({ payload: { appUsers, pathwayId } }) {
  const appUsersPathways = {};
  const pathwaysClient = yield call(doCreatePathwaysClient);
  for (const id of appUsers) {
    let [, appUserData] = yield select(selectAppUser(id));
    let [loading, journeys] = yield select(selectJourneysForAppUser(appUserData));
    if (!loading || journeys === undefined) {
      yield put(fetchAppUserJourneys(id));
      while (true) {
        const {
          payload: { appUserId, journeys: appUserJourneys },
        } = yield take(APP_USER_JOURNEYS_LOADED);
        if (id === appUserId) {
          journeys = appUserJourneys;
          break;
        }
      }
    }
    const ownerId = yield call(doDetermineOwnerForPathway);
    const appUserPathwayData = {
      journeyId: journeys[0].id,
      originalPathwayId: pathwayId,
      currentStageSlug: null,
      disabledRuleIds: [],
      ownerId,
    };
    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUserData.ids.pathways,
    );
    const pathwaysAppUser = listPathwaysAppUsersResp.results[0];
    const np = yield call(
      pathwaysClient.createAppUserPathway,
      pathwaysAppUser.id,
      appUserPathwayData,
    );

    const newAppUserPathway = mapSnakeCaseAppUserPathwayToCamelCase(np);
    appUsersPathways[appUserData.ids.pathways] = newAppUserPathway;

    // now transition the user to the first stage of the pathway
    let [, pathway] = yield select(selectPathway(pathwayId));
    if (pathway.stages.length) {
      yield call(
        pathwaysClient.transitionAppUserToPathwayStage,
        pathwaysAppUser.id,
        newAppUserPathway.id,
        pathway.stages[0].slug,
      );
      // ensure this is saved in Redux
      appUsersPathways[appUserData.ids.pathways].currentStageSlug = pathway.stages[0].slug;
    }
  }

  yield put(addPathwayToAppUsersSuccess(appUsersPathways));
  yield call(message.success, i18n.t('patients:addPathwaySuccess'));
}

export function* doProcessAppUserPathwayNow({ payload: { appUserId, pathwayId } }) {
  try {
    const [, appUser] = yield select(selectAppUser(appUserId));
    if (!appUser) {
      return;
    }
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUser.ids.pathways,
    );
    const pathwaysAppUser = listPathwaysAppUsersResp.results[0];
    yield call(pathwaysClient.processAppUserPathway, pathwaysAppUser.id, pathwayId);
    yield call(message.success, i18n.t('patients:Pathways.processPathway.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('patients:Pathways.processPathway.error'));
  }
}

export function* doSetAppUserPathwayStage({ payload: { appUserId, stageSlug, appUserPathwayId } }) {
  try {
    const [, appUser] = yield select(selectAppUser(appUserId));
    const pathwaysClient = yield call(doCreatePathwaysClient);

    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUser.ids.pathways,
    );
    const pathwaysAppUser = listPathwaysAppUsersResp.results[0];

    yield call(
      pathwaysClient.transitionAppUserToPathwayStage,
      pathwaysAppUser.id,
      appUserPathwayId,
      stageSlug,
    );

    yield put(appUserPathwayStageUpdated(appUser.ids.pathways, appUserPathwayId, stageSlug));
    yield call(message.success, i18n.t('patients:transitionPatientPathwaySuccess'));
  } catch (err) {
    console.error(err);
  }
}

function* doCreateAppUserJourney(appUserId, indexEventSlugs = [], indexEventDates = {}) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    const startDate = moment().format('YYYY-MM-DD');
    const newJourney = yield call(pathwaysClient.createAppUserJourney, appUserId, startDate);

    for (const indexEventTypeSlug of indexEventSlugs) {
      if (indexEventDates[indexEventTypeSlug]) {
        yield call(
          pathwaysClient.createAppUserJourneyIndexEvent,
          appUserId,
          newJourney.id,
          indexEventTypeSlug,
          indexEventDates[indexEventTypeSlug]?.toISOString() || undefined,
        );
      }
    }

    return newJourney;
  } catch (err) {
    console.error(err);
  }
}

function* doCreateAppUserPathway(
  pathwaysClient,
  journeyId,
  originalPathwayId,
  pathwaysAppUserIntegerId,
) {
  const ownerId = yield call(doDetermineOwnerForPathway);
  const appUserPathwayData = {
    journeyId,
    originalPathway: originalPathwayId,
    currentStageSlug: null,
    disabledRuleIds: [],
    ownerId,
  };
  const np = yield call(
    pathwaysClient.createAppUserPathway,
    pathwaysAppUserIntegerId,
    appUserPathwayData,
  );
  const newAppUserPathway = { id: np.id, url: np.url, ...appUserPathwayData };

  // now transition the user to the first stage of the pathway
  let [, pathwayTemplate] = yield select(selectPathway(originalPathwayId));
  if (pathwayTemplate.stages.length) {
    const firstStage = pathwayTemplate.stages[0];

    yield call(
      doTransitionAppUserToPathwayStage,
      pathwaysAppUserIntegerId,
      newAppUserPathway.id,
      firstStage.slug,
    );
    // ensure this is saved in Redux
    newAppUserPathway.currentStageSlug = firstStage.slug;
  }

  return newAppUserPathway;
}

export function* doInviteAppUserWithPathways({ payload: { appUser, journeys } }) {
  try {
    if (!journeys || journeys.length === 0) {
      yield put(inviteAppUserWithPathwaysSuccess(appUser.ids.pathways, []));
      return;
    }

    const pathwaysClient = yield call(doCreatePathwaysClient);

    // create App User in Pathways
    yield call(pathwaysClient.createAppUser, appUser.ids.pathways);
    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUser.ids.pathways,
    );
    const newPathwaysAppUser = listPathwaysAppUsersResp.results[0];

    let newAppUserPathways = [];

    for (const journey of journeys) {
      const pathways = [...journey.procedures, ...journey.campaigns];
      const indexEventSlugs = new Set();
      pathways.forEach(({ indexEvents }) =>
        indexEvents.forEach(({ eventTypeSlug }) => indexEventSlugs.add(eventTypeSlug)),
      );

      const newJourney = yield call(
        doCreateAppUserJourney,
        newPathwaysAppUser.id,
        Array.from(indexEventSlugs),
        journey.indexEventDates,
      );

      // Create App User Pathway as part of Journey

      for (let pathway of pathways) {
        const newAppUserPathway = yield call(
          doCreateAppUserPathway,
          pathwaysClient,
          newJourney.id,
          pathway.id,
          newPathwaysAppUser.id,
        );

        newAppUserPathways.push({ ...newAppUserPathway, isActive: true });
      }
    }

    // TODO: once details UI for journeys is added this will need to send journeys
    yield put(inviteAppUserWithPathwaysSuccess(appUser.ids.pathways, newAppUserPathways));
  } catch (err) {
    console.error(err);
    yield put(inviteAppUserWithPathwaysFailed());
  }
}

export function* doSetAppUserPathwayArchivedStatus({
  payload: { appUserId, appUserPathwayId, isActive },
}) {
  try {
    // const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, appUser] = yield select(selectAppUser(appUserId));

    // yield call(
    //   pathwaysClient.patchAppUserPathway,
    //   appUser.ids.pathways,
    //   appUserPathwayId,
    //   null,
    //   null,
    //   isActive,
    // );

    yield put(
      setAppUserPathwayArchivedStatusSuccess(appUser.ids.pathways, appUserPathwayId, isActive),
    );
    yield call(message.success, i18n.t('cards:JourneyDetails.edit.success'));
  } catch (err) {
    console.error(err);
    yield put(setAppUserPathwayArchivedStatusFailed());
    yield call(message.error, i18n.t('cards:JourneyDetails.edit.failed'));
  }
}

export function* doEditAppUserJourney({ payload: { appUserId, journeyId, updatedJourney } }) {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const { indexEventDates, newPathways, updatedPathways } = updatedJourney;

    const [, appUser] = yield select(selectAppUser(appUserId));

    let journeyIdToUse = journeyId;
    if (!journeyId) {
      // The App User doesn't have a journey yet. This might be a related App User for example.
      // The same thing needs to happen for them as when they have just been invited: creation of journey, optionally with pathways.
      const payload = {
        appUser,
        journeys: [
          { procedures: newPathways.map(np => np.originalPathway), campaigns: [], indexEventDates },
        ],
      };
      yield put(inviteAppUserSuccess(appUser.id, appUser, payload.journeys));
      // yield call(doInviteAppUserWithPathways, { payload });
      while (true) {
        const {
          payload: { appUserId, appUserPathways },
        } = yield take(INVITE_APP_USER_WITH_PATHWAYS_SUCCESS);
        if (appUserId === appUser.ids.pathways) {
          journeyIdToUse = appUserPathways[0].journeyId;
          break;
        }
      }

      // const result = yield take(INVITE_APP_USER_WITH_PATHWAYS_SUCCESS);
      // journeyIdToUse = appUserPathways[0].journeyId;
      // const newJourney = yield call(doCreateAppUserJourney, appUser.ids.pathways);
      // journeyIdToUse = newJourney.id;
    } else {
      // The App User already has a Journey: edit all relevant data

      const [, appUserJourneys] = yield select(selectJourneysForAppUser(appUser));
      const originalJourney = appUserJourneys.find(({ id }) => id === journeyIdToUse);

      // to get numeric id for pathways app user
      const listPathwaysAppUsersResp = yield call(
        pathwaysClient.listAppUsers,
        undefined,
        appUser.ids.pathways,
      );
      const pathwaysAppUser = listPathwaysAppUsersResp.results[0];

      // update existing index event dates and create new ones if previously undefined
      const { indexEventsToUpdate, indexEventsToCreate, indexEventsToDelete } = Object.keys(
        indexEventDates,
      ).reduce(
        (prev, slug) => {
          const existingIndexEvent = originalJourney.indexEvents.find(
            ({ eventTypeSlug }) => eventTypeSlug === slug,
          );

          if (existingIndexEvent?.value && !indexEventDates[slug]) {
            return {
              ...prev,
              indexEventsToDelete: [...prev.indexEventsToDelete, existingIndexEvent],
            };
          }

          if (!indexEventDates[slug]) return prev;

          if (existingIndexEvent) {
            // adding "000" to make new value consistent with those from pathways api
            const newValue = `${indexEventDates[slug].toISOString().slice(0, -1)}000Z`;

            if (newValue === existingIndexEvent.value) {
              return prev;
            }

            return {
              ...prev,
              indexEventsToUpdate: [
                ...prev.indexEventsToUpdate,
                { ...existingIndexEvent, value: newValue },
              ],
            };
          }

          return {
            ...prev,
            indexEventsToCreate: [
              ...prev.indexEventsToCreate,
              { slug, value: indexEventDates[slug].toISOString() },
            ],
          };
        },
        { indexEventsToUpdate: [], indexEventsToCreate: [], indexEventsToDelete: [] },
      );

      for (const indexEvent of indexEventsToUpdate) {
        yield call(
          pathwaysClient.patchAppUserJourneyIndexEvent,
          pathwaysAppUser.id,
          journeyIdToUse,
          indexEvent.id,
          indexEvent.value,
        );
      }

      for (const indexEvent of indexEventsToCreate) {
        if (indexEvent.value) {
          yield call(
            pathwaysClient.createAppUserJourneyIndexEvent,
            pathwaysAppUser.id,
            journeyId,
            indexEvent.slug,
            indexEvent.value,
          );
        }
      }

      for (const indexEvent of indexEventsToDelete) {
        yield call(
          pathwaysClient.deleteAppUserJourneyIndexEvent,
          pathwaysAppUser.id,
          journeyIdToUse,
          indexEvent.id,
        );
      }

      // add new pathways to journey
      for (const pathway of newPathways) {
        yield call(
          doCreateAppUserPathway,
          pathwaysClient,
          journeyIdToUse,
          pathway.originalPathway.id,
          pathwaysAppUser.id,
        );
      }

      // update active status of app user pathway
      for (const pathway of updatedPathways) {
        yield call(
          pathwaysClient.patchAppUserPathway,
          pathwaysAppUser.id,
          pathway.id,
          undefined,
          undefined,
          pathway.isActive,
        );
      }
    }

    // refetch app users pathway and journey data
    yield call(doFetchAppUsersPathways);
    yield call(doFetchAppUserJourneys, { payload: { appUserId } });

    yield put(editAppUserJourneySuccess());
    yield call(history.goBack);
    yield call(message.success, i18n.t('cards:JourneyDetails.edit.success'));
  } catch (err) {
    console.error(err);
    yield put(editAppUserJourneyFailed());
    yield call(message.error, i18n.t('cards:JourneyDetails.edit.failed'));
  }
}

export function* doTriggerAdhocRule({ payload: { appUserId, appUserPathwayId, ruleId } }) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, appUser] = yield select(selectAppUser(appUserId));

    // // to get numeric id for pathways app user
    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUser.ids.pathways,
    );
    const pathwaysAppUser = listPathwaysAppUsersResp.results[0];

    yield call(pathwaysClient.triggerAdhocRule, pathwaysAppUser.id, appUserPathwayId, ruleId);

    yield call(doFetchAppUserJourneys, { payload: { appUserId } });
    yield put(triggerAdhocRuleSuccess());
    yield call(message.success, i18n.t('cards:PathwayStagesTable.adhocRules.success'));
  } catch (err) {
    console.error(err);
    yield put(triggerAdhocRuleFailed());
    yield call(message.error, i18n.t('cards:PathwayStagesTable.adhocRules.failed'));
  }
}

export function* doActionAppUserJourneyEntry({ payload: { appUserId, journeyId, entryId } }) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, appUser] = yield select(selectAppUser(appUserId));
    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUser.ids.pathways,
    );
    const pathwaysAppUser = listPathwaysAppUsersResp.results[0];
    yield call(pathwaysClient.actionJourneyEntry, pathwaysAppUser.id, journeyId, entryId);
    yield put(actionJourneyEntrySuccess(appUserId, journeyId, entryId));
  } catch (err) {
    console.error(err);
    yield put(actionJourneyEntryFailed());
  }
}
