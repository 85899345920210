import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import ContentUpdates from './ContentUpdates';
import SnapshotOptions from './SnapshotOptions';
import PropagateUpdates from './PropagateUpdates';

function ContentUpdatesWizard({ initialData, loading, submitting, onCompleted, pathway }) {
  const { t } = useTranslation();

  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem
        stepKey="contentUpdates"
        title={t('pathways:ProcedurePathways.contentUpdates.contentUpdates')}
      >
        <ContentUpdates pathway={pathway} />
      </WizardItem>
      <WizardItem
        stepKey="snapshotOptions"
        title={t('pathways:ProcedurePathways.contentUpdates.snapshotOptions')}
      >
        <SnapshotOptions />
      </WizardItem>
      <WizardItem
        stepKey="propagateUpdates"
        title={t('pathways:ProcedurePathways.contentUpdates.propagateUpdates')}
      >
        <PropagateUpdates />
      </WizardItem>
    </Wizard>
  );
}

ContentUpdatesWizard.propTypes = {
  initialData: shape({}),
  loading: bool,
  submitting: bool.isRequired,
  onCompleted: func.isRequired,
};

ContentUpdatesWizard.defaultProps = {
  initialData: undefined,
  loading: false,
};

export default ContentUpdatesWizard;
