import React, { useEffect, useState, useCallback } from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Input, Table, Divider } from 'antd';
import { useSearch } from '@hooks';
import './styles.less';

const SelectionModal = ({
  columns,
  dataSource,
  displayKeys,
  loading,
  primaryKey,
  submitting,
  title,
  visible,
  width,
  onCancel,
  onSubmit,
  selectedRowKeys: selectedRowKeysProp,
  onSelectRowKeys,
  clearOnClose,
  refreshList,
  addButtonText,
  isSelectionOptional,
}) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const filterFunc = useCallback(
    (value, comparator) => displayKeys.find(key => comparator(value[key] || '')),
    [displayKeys],
  );

  useEffect(() => {
    console.log('useEffect selectedRowKeysProp: ', selectedRowKeysProp);
    if (selectedRowKeysProp) setSelectedRowKeys(selectedRowKeysProp);
  }, [selectedRowKeysProp]);

  const [searchResults, searchProps] = useSearch(dataSource, filterFunc);

  const setSelectedRows = useCallback(
    keys => {
      if (onSelectRowKeys) {
        onSelectRowKeys(keys);
      } else {
        setSelectedRowKeys(keys);
      }
    },
    [onSelectRowKeys, setSelectedRowKeys],
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => {
        onCancel();
        if (clearOnClose) setSelectedRows([]);
      }}
      width={width}
      footer={[
        <div className="footer-left" key="footer-button-select-all">
          {selectedRowKeys.length !== dataSource.length || selectedRowKeys.length === 0 ? (
            <Button
              type="link"
              onClick={() => setSelectedRows(dataSource.map(data => data[primaryKey]))}
              disabled={loading || submitting}
            >
              {t('common:selectionModal.selectAll')}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => setSelectedRows([])}
              disabled={loading || submitting}
            >
              {t('common:selectionModal.deselectAll')}
            </Button>
          )}
          {refreshList ? (
            <>
              <Divider type="vertical" />
              <Button type="link" onClick={() => refreshList()} disabled={loading || submitting}>
                {t('common:selectionModal.refreshList')}
              </Button>
            </>
          ) : null}
        </div>,
        <div className="footer-right" key="footer-button-add">
          <Button
            disabled={
              (submitting || !isSelectionOptional) && (!selectedRowKeys || !selectedRowKeys.length)
            }
            loading={submitting}
            type="primary"
            onClick={() => {
              onSubmit(selectedRowKeys); // preserve order plz
              if (clearOnClose) setSelectedRows([]);
            }}
          >
            {addButtonText || t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
      className="selection-modal"
    >
      <div className="modal-search">
        <div className="row-key-count">{selectedRowKeys ? selectedRowKeys.length : 0}</div>
        <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        rowKey={primaryKey}
        loading={loading}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: setSelectedRows,
        }}
        pagination={{ position: 'bottom' }}
      />
    </Modal>
  );
};
SelectionModal.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  dataSource: arrayOf(shape({})).isRequired,
  displayKeys: arrayOf(string).isRequired,
  loading: bool,
  primaryKey: oneOfType([func, string]),
  submitting: bool,
  title: string,
  visible: bool,
  width: number,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  clearOnClose: bool,
  refreshList: func,
  addButtonText: string,
  isSelectionOptional: bool,
};

SelectionModal.defaultProps = {
  loading: false,
  submitting: false,
  visible: false,
  width: 700,
  clearOnClose: false,
  refreshList: undefined,
  addButtonText: undefined,
  isSelectionOptional: false,
};

export default SelectionModal;
