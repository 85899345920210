import React, { useState, useCallback } from 'react';
import { Card } from '@cards/Card';
import { Button, Input, Icon, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { validatePasswordComplexity } from '@utils';
import './style.less';
import { changeCurrentPassword } from '../../redux/myProfile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from '../../redux/myProfile/reducers';

const ChangePassword = ({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldError,
    getFieldValue,
    validateFields,
  },
}) => {
  const dispatch = useDispatch();
  const [confirmDirty, updateDirty] = useState(false);
  const [hidden, toggleHidden] = useState(true);
  const { t } = useTranslation();
  const loading = useSelector(isLoading);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const currentPwd = values.currentPassword;
          const newPwd = values.confirmPassword;

          dispatch(changeCurrentPassword(currentPwd, newPwd));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  const toggleVisibility = e => {
    toggleHidden(!hidden);
  };

  const validateWithConfirmPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(t('common:validation.Password.inconsistentError'));
    } else {
      callback();
    }
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    updateDirty(confirmDirty || !!value);
  };

  const disabledSubmit =
    typeof getFieldError('password') !== 'undefined' ||
    !getFieldValue('password') ||
    typeof getFieldError('confirmPassword') !== 'undefined' ||
    !getFieldValue('confirmPassword');

  return (
    <Card title={t('passwordRecovery:Password.title')} className="change-password-card">
      <p>{t('passwordRecovery:Password.description')}</p>
      <p>{t('passwordRecovery:Password.passwordHelp')}</p>
      <Button type="link" onClick={toggleVisibility}>
        {t('passwordRecovery:Password.showPassword')}
      </Button>
      <Form onSubmit={handleSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator('currentPassword', {
            rules: [
              {
                required: true,
                message: t('cards:ChangePassword.validation.emptyError'),
              },
            ],
          })(
            <Input
              type={hidden ? 'password' : 'text'}
              prefix={<Icon type="lock" />}
              placeholder={t('cards:ChangePassword.placeholder.currentPassword')}
            />,
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: t('common:validation.Password.emptyError'),
              },
              { validator: validatePasswordComplexity },
              { validator: validateWithConfirmPassword },
            ],
          })(
            <Input
              type={hidden ? 'password' : 'text'}
              prefix={<Icon type="lock" />}
              placeholder={t('cards:ChangePassword.placeholder.newPassword')}
            />,
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                required: true,
                message: t('common:validation.Password.emptyErrorConfirmField'),
              },
              { validator: compareToFirstPassword },
            ],
          })(
            <Input
              onBlur={handleConfirmBlur}
              type={hidden ? 'password' : 'text'}
              prefix={<Icon type="lock" />}
              placeholder={t('cards:ChangePassword.placeholder.confirmNewPassword')}
            />,
          )}
        </Form.Item>
        <Button
          type="primary"
          disabled={disabledSubmit}
          onClick={handleSubmit}
          htmlType="submit"
          loading={loading}
        >
          {t('cards:ChangePassword.submit')}
        </Button>
      </Form>
    </Card>
  );
};

const WrappedChangedPassword = Form.create()(ChangePassword);
export default WrappedChangedPassword;
