import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions, useTitleUpdate } from '@hooks';
import { selectDashboardUserCreating } from '@redux/dashboardUsers/reducers';
import { createDashboardUser } from '@redux/dashboardUsers/actions';
import { selectDashboardUserHospital } from '@redux/login/reducer';
import { Column, ColumnGroup } from '@cards/Card';
import { matchType } from '../../../propTypes';
import DashboardUserWizard from './Wizard';

function NewDashboardUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creating = useSelector(selectDashboardUserCreating);
  const dashboardUserHospital = useSelector(selectDashboardUserHospital);
  const { setTitle } = usePageActions({ title: t('dashboardUsers:Wizard.title'), showClose: true });
  useTitleUpdate(setTitle, 'dashboardUsers:Wizard.title');

  const onSubmit = useCallback(
    dashboardUser => {
      const { hospitalId, hospitalName, hospitalSlug } = dashboardUserHospital;
      dispatch(createDashboardUser({ hospitalId, hospitalName, hospitalSlug, ...dashboardUser }));
    },
    [dashboardUserHospital, dispatch],
  );

  return (
    <ColumnGroup>
      <Column>
        <DashboardUserWizard
          submitting={creating}
          submitButtonText={t('dashboardUsers:Wizard.button.new')}
          onSubmit={onSubmit}
        />
      </Column>
      <Column />
    </ColumnGroup>
  );
}

NewDashboardUser.propTypes = {
  match: matchType.isRequired,
};

export default NewDashboardUser;
