import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import moment from 'moment';
import './style.less';

const { Option } = Select;

const DATE = 'date';
const MONTH = 'month';
const YEAR = 'year';

const dates = new Array(31).fill(undefined).map((ele, index) => index + 1);
const years = (function generateYearList() {
  const years = [];
  const startDate = moment('1900-01-01');
  const endDate = moment();

  while (endDate.diff(startDate, years) >= 0) {
    years.push(startDate.format('YYYY'));
    startDate.add(1, 'year');
  }

  return years.reverse();
})();

/**
 * For use with antd Forms
 * @param {{ value: string, onChange: func }} props
 * @param {ref} ref
 */
function DateSelect({ value, onChange }, ref) {
  const [dateMoment, setDateMoment] = useState(value ? moment(value) : undefined);
  const triggerChange = useCallback(
    (field, value) => {
      switch (field) {
        case DATE:
          return onChange(
            moment(dateMoment)
              .date(value)
              .toISOString(),
          );
        case MONTH:
          return onChange(
            moment(dateMoment)
              .month(value)
              .toISOString(),
          );
        case YEAR:
          return onChange(
            moment(dateMoment)
              .year(value)
              .toISOString(),
          );
        default:
          throw new Error('Invalid field type');
      }
    },
    [dateMoment, onChange],
  );

  useEffect(() => {
    if (value) {
      setDateMoment(moment(value));
    }
  }, [value]);

  return (
    <div className="dropdown-date-selectors" ref={ref}>
      <Select
        showSearch
        value={dateMoment ? dateMoment.format('DD') : ''}
        onChange={value => triggerChange(DATE, value)}
      >
        {dates.map(date => (
          <Option key={`date-${date}`} value={date}>
            {date}
          </Option>
        ))}
      </Select>
      <Select
        showSearch
        value={dateMoment ? dateMoment.format('MMMM') : ''}
        onChange={value => triggerChange(MONTH, value)}
      >
        {moment.months().map(month => (
          <Option key={`month-${month}`} value={month}>
            {month}
          </Option>
        ))}
      </Select>
      <Select
        showSearch
        value={dateMoment ? dateMoment.format('YYYY') : ''}
        onChange={value => triggerChange(YEAR, value)}
      >
        {years.map(year => (
          <Option key={`year-${year}`} value={year}>
            {year}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default forwardRef(DateSelect);
