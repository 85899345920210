import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_DOCUMENTS_TO_GROUPS,
  ADD_FORMS_TO_GROUPS,
  ADD_USERS_TO_GROUPS,
  FETCH_GROUPS,
  SEND_MESSAGE_TO_GROUPS,
  loadGroups,
  groupsLoaded,
} from './actions';
import { message } from 'antd';
import i18n from '../../i18n';
import takeFirst from '../takeFirst';
import mockData from '../../mockData';

export default function* root() {
  yield takeFirst(FETCH_GROUPS, doFetchGroups);
  yield takeEvery(ADD_DOCUMENTS_TO_GROUPS, doAddDocumentsToGroups);
  yield takeEvery(ADD_FORMS_TO_GROUPS, doAddFormsToGroups);
  yield takeEvery(SEND_MESSAGE_TO_GROUPS, doSendMessageToGroups);
  yield takeEvery(ADD_USERS_TO_GROUPS, doAddUsersToGroups);
}

function* doAddUsersToGroups() {
  yield call(message.success, i18n.t('groups:addUserSuccess'));
}

function* doFetchGroups() {
  yield put(loadGroups());
  yield put(groupsLoaded(mockData.groups));
}

function* doAddDocumentsToGroups({ payload: { documentIds, groupIds } }) {
  // TODO: integration stuff

  yield call(
    message.success,
    i18n.t('cards:GroupList.documentAccessModal.addDocumentsToGroupsSuccess'),
  );
}

function* doAddFormsToGroups({ payload: { formIds, groupIds } }) {
  // TODO: integration stuff

  yield call(message.success, i18n.t('cards:GroupList.formAccessModal.addFormsToGroupsSuccess'));
}

function* doSendMessageToGroups({ payload: { groupIds, message: messageData } }) {
  // TODO: integration stuff

  yield call(
    message.success,
    i18n.t('cards:GroupList.sendMessageModal.sendMessageToGroupsSuccess'),
  );
}
