import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import WeblinkTypeIcon from '@components/WeblinkTypeIcon';
import moment from 'moment';
import PubslishedStatusIcon from '@components/PublishedStatusIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons';

function Review({ isEdit }) {
  const { t } = useTranslation();
  const { formData } = useWizard();

  return (
    <ColumnGroup>
      <Column>
        <Card className="weblink-wizard-card" title={t('common:Wizard.details.header')}>
          <DetailForm>
            <DetailRow
              label={t('weblinks:Wizard.review.details.type')}
              value={<WeblinkTypeIcon showText type={formData.details.contentType} />}
            />
            <DetailRow
              label={t('weblinks:Wizard.review.details.title')}
              value={formData.details.name}
            />
            <DetailRow
              label={t('weblinks:Wizard.review.details.version')}
              value={Number(formData.details.version) + 1 || 1}
            />
            {isEdit ? (
              <DetailRow
                label={t('weblinks:Wizard.details.status')}
                value={
                  <PubslishedStatusIcon
                    published={formData.details.status === 'published'}
                    showText
                  />
                }
              />
            ) : null}
            <DetailRow
              label={t('weblinks:Wizard.review.details.created')}
              value={moment().format('l')}
            />
            <DetailRow
              label={t('weblinks:Wizard.review.details.description')}
              value={formData.details.description}
            />
            <DetailRow
              label={t('weblinks:Wizard.review.details.audience')}
              value={t(`weblinks:Wizard.review.details.${formData.details.audience}`)}
            />
          </DetailForm>
        </Card>
      </Column>
      <Column>
        <Card
          className="weblink-wizard-card"
          extra={
            <FontAwesomeIcon
              className="clickable-icon"
              icon={faExternalLinkSquare}
              onClick={() => window.open(formData.content.url)}
            />
          }
          title={t('weblinks:Wizard.content.stepName')}
        >
          <DetailForm>
            <DetailRow
              label={t('weblinks:Wizard.review.content.url')}
              value={formData.content.url}
            />
            <DetailRow
              label={t('weblinks:Wizard.review.content.agreements')}
              value={t('weblinks:Wizard.review.content.accepted')}
            />
          </DetailForm>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

Review.propTypes = {
  isEdit: bool,
};

Review.defaultProps = {
  isEdit: false,
};

export default Review;
