import React from 'react';
import { bool, string } from 'prop-types';
import { Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '@redux/core/reducers';
import './style.less';

function LanguageIcon({ language, showText }) {
  const languageDetails = useSelector(selectLanguage(language.toLowerCase()));
  const { t } = useTranslation();

  if (!language || !languageDetails) return null;
  const { name, icon } = languageDetails;

  if (showText) {
    return (
      <>
        <Icon className="language-icon" component={icon} />
        {t(name)}
      </>
    );
  }

  return (
    <Tooltip placement="bottom" title={t(name)}>
      <Icon className="language-icon" component={icon} />
    </Tooltip>
  );
}

LanguageIcon.propTypes = {
  language: string,
  showText: bool,
};

LanguageIcon.defaultProps = {
  language: 'en',
  showText: false,
};

export default LanguageIcon;
