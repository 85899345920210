import React from 'react';
import cn from 'classnames';

const ColumnGroup = ({ children, className, ...props }) => {
  return (
    <div className={cn('grid-column-group', className)} {...props}>
      {children}
    </div>
  );
};

export default ColumnGroup;
