export class DuplicateHospitalError extends Error {
  constructor() {
    super();
    this.message = 'Hospital already exists';
  }
}

export class HospitalNotFoundError extends Error {
  constructor() {
    super();
    this.message = 'Hospital not found';
  }
}
