import React, { useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { Card, Column, ColumnGroup } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import LanguageIcon from '@components/LanguageIcon';
import supportedLanguages from '@utils/supportedLanguages';
import { antFormType } from '../../../propTypes';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const languageCodes = Object.keys(supportedLanguages);

function IndexEventWizard({ form, initialData, submitting, title, onSubmit }) {
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (err) return;
        onSubmit(values);
      });
    },
    [form, onSubmit],
  );

  return (
    <ColumnGroup>
      <Column>
        <Card className="index-event-wizard-card" title={title}>
          <Form {...formItemLayout} className="detail-form" onSubmit={handleSubmit}>
            {languageCodes.map(code => (
              <DetailRow
                key={`${code}-name`}
                label={
                  <>
                    <LanguageIcon language={code} />
                    {t(`indexEvents:Details.names.${code}`)}
                  </>
                }
                value={form.getFieldDecorator(code, {
                  initialValue: initialData[code],
                  rules: [{ required: code === 'en', message: t('indexEvents:Wizard.required') }],
                })(<Input />)}
              />
            ))}
            <Button disabled={submitting} loading={submitting} type="primary" htmlType="submit">
              {t('common:buttons.save')}
            </Button>
          </Form>
        </Card>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

IndexEventWizard.propTypes = {
  form: antFormType.isRequired,
  initialData: shape({}),
  submitting: bool,
  title: string.isRequired,
  onSubmit: func.isRequired,
};

IndexEventWizard.defaultProps = {
  initialData: {},
  submitting: false,
};

export default Form.create()(IndexEventWizard);
