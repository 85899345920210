const documents = [
  {
    id: 1,
    name: 'Document 1',
    slug: 'document-1-slug',
    app: 1,
    linked_document_set: null,
    parent: null,
    variation: null,
    token: '123a12',
    product_id: 'mock',
    is_deleted: false,
    deletion_datetime: null,
    app_user_access_type: 'selected',
    owner_id: null,
    app_id: 1,
    app_name: 'Mock AppName',
    thumbnail_url: 'mock thumb url',
    has_variations: false,
    title: 'Document 1',
    description: '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    published: true,
    metadata: {
      tags: [
        {
          term: 'pathway:Inguinal Hernia',
          label: 'pathway:Inguinal Hernia',
          scheme: '',
        },
        {
          term: 'stage:Waiting period',
          label: 'stage:Waiting period',
          scheme: '',
        },
      ],
      readonly: {
        ls: {
          carbon: {
            name: 'Document 1',
            slug: 'document-1-slug',
            uuid: '34c07626-33ec-431f-b206-082315eebbef',
            space: 'hernia-inguinal',
            created: {
              at: '2020-02-02 15:44:25Z',
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
            },
            imports: [
              {
                at: '2020-02-02 15:44:26Z',
                by: '8513bb60-034e-4478-acd7-3550e7b10928',
                pages: 1,
                metadata: {
                  name: 'L2.2_ES: ¿Qué es una hernia inguinal?',
                  slug: 'lectura-2-que-es-una-hernia-inguinal-1',
                  uuid: '5c8efa74-2d7c-4d0a-b511-403428a46b4a',
                  space: 'ecoach-content-bank',
                  created: {
                    at: '2019-08-12 08:08:22Z',
                    by: 'joelibarra',
                  },
                  imports: [
                    {
                      at: '2019-08-12 08:08:23Z',
                      by: 'joelibarra',
                      pages: 1,
                      metadata: {
                        name: 'Lectura 2: ¿Qué es una hernia inguinal? Page',
                        slug: '017_l2.2_es_lectura_2-docs',
                        uuid: 'ed2104d9-3d57-7875-2531-8561927783f1',
                        space: 'default-space',
                        created: {
                          at: '2019-08-12:15:43:30Z',
                          by: 'joel.ibarra',
                        },
                        version: {},
                        language: 'en-gb',
                        categories: ['all-documents', 'guides'],
                        linking_id: '017_l2.2_es_lectura_2-docs',
                        organisation: '',
                      },
                    },
                  ],
                  version: {
                    by: '8513bb60-034e-4478-acd7-3550e7b10928',
                    name: 'CE Export to new space',
                    number: 4,
                    created: '2020-02-02 15:43:02Z',
                  },
                  language: 'es',
                  categories: ['all-documents', 'hernia-inguinal'],
                  linking_id: 'lectura-2-que-es-una-hernia-inguinal-1',
                  organisation: 'philip-ecoach',
                },
              },
            ],
            version: {
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
              name: 'metadata export',
              number: 2,
              created: '2020-02-03 04:04:12Z',
            },
            language: 'en-gb',
            categories: ['all-documents', 'spanish'],
            linking_id: 'l22_es-que-es-una-hernia-inguinal',
            organisation: 'b-braun',
          },
        },
      },
      'source-url': '',
      'content-provider': 'eCoach',
      'original-authors': '',
      source: 'global',
      language: 'es',
    },
    latest_version: {
      name: 'Version 2',
      description: 'v2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      created: '2020-02-10 05:17:06Z',
    },
    links: {
      self: '/api/core/v1/apps/1/documents/1/',
      versions: '/api/core/v1/apps/1/documents/1/versions/',
      publishing_status: '/api/core/v1/apps/1/documents/1/channels/Public/',
      variations: '/api/core/v1/apps/1/documents/1/variations/',
    },
  },
  {
    id: 2,
    name: 'Document 2',
    slug: 'document-2-slug',
    app: 1,
    linked_document_set: null,
    parent: null,
    variation: null,
    token: '123a12',
    product_id: 'mock',
    is_deleted: false,
    deletion_datetime: null,
    app_user_access_type: 'selected',
    // old metadata. keeping for now as reference
    // metadata: {
    //   type: 'DOC',
    //   language: 'de',
    //   source: 'global',
    //   published: true,
    //   version: 1,
    //   created: '2020-03-01T12:30:00.000Z',
    // },
    owner_id: null,
    app_id: 1,
    app_name: 'Mock AppName',
    thumbnail_url: 'mock thumb url',
    has_variations: false,
    title: 'Document 2',
    description: '#2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    published: false,
    metadata: {
      tags: [
        {
          term: 'pathway:Inguinal Hernia',
          label: 'pathway:Inguinal Hernia',
          scheme: '',
        },
        {
          term: 'stage:Waiting period',
          label: 'stage:Waiting period',
          scheme: '',
        },
      ],
      readonly: {
        ls: {
          carbon: {
            name: 'Document 1',
            slug: 'document-1-slug',
            uuid: '34c07626-33ec-431f-b206-082315eebbef',
            space: 'hernia-inguinal',
            created: {
              at: '2020-02-02 15:44:25Z',
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
            },
            imports: [
              {
                at: '2020-02-02 15:44:26Z',
                by: '8513bb60-034e-4478-acd7-3550e7b10928',
                pages: 1,
                metadata: {
                  name: 'L2.2_ES: ¿Qué es una hernia inguinal?',
                  slug: 'lectura-2-que-es-una-hernia-inguinal-1',
                  uuid: '5c8efa74-2d7c-4d0a-b511-403428a46b4a',
                  space: 'ecoach-content-bank',
                  created: {
                    at: '2019-08-12 08:08:22Z',
                    by: 'joelibarra',
                  },
                  imports: [
                    {
                      at: '2019-08-12 08:08:23Z',
                      by: 'joelibarra',
                      pages: 1,
                      metadata: {
                        name: 'Lectura 2: ¿Qué es una hernia inguinal? Page',
                        slug: '017_l2.2_es_lectura_2-docs',
                        uuid: 'ed2104d9-3d57-7875-2531-8561927783f1',
                        space: 'default-space',
                        created: {
                          at: '2019-08-12:15:43:30Z',
                          by: 'joel.ibarra',
                        },
                        version: {},
                        language: 'en-gb',
                        categories: ['all-documents', 'guides'],
                        linking_id: '017_l2.2_es_lectura_2-docs',
                        organisation: '',
                      },
                    },
                  ],
                  version: {
                    by: '8513bb60-034e-4478-acd7-3550e7b10928',
                    name: 'CE Export to new space',
                    number: 4,
                    created: '2020-02-02 15:43:02Z',
                  },
                  language: 'es',
                  categories: ['all-documents', 'hernia-inguinal'],
                  linking_id: 'lectura-2-que-es-una-hernia-inguinal-1',
                  organisation: 'philip-ecoach',
                },
              },
            ],
            version: {
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
              name: 'metadata export',
              number: 2,
              created: '2020-02-03 04:04:12Z',
            },
            language: 'en-gb',
            categories: ['all-documents', 'spanish'],
            linking_id: 'l22_es-que-es-una-hernia-inguinal',
            organisation: 'b-braun',
          },
        },
      },
      'source-url': '',
      'content-provider': 'eCoach',
      'original-authors': '',
      source: 'global',
      language: 'es',
    },
    latest_version: {
      name: 'Version 2',
      description: 'v2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      created: '2020-02-10 05:17:06Z',
    },
    links: {
      self: '/api/core/v1/apps/1/documents/1/',
      versions: '/api/core/v1/apps/1/documents/1/versions/',
      publishing_status: '/api/core/v1/apps/1/documents/1/channels/Public/',
      variations: '/api/core/v1/apps/1/documents/1/variations/',
    },
  },
  {
    id: 3,
    name: 'Document 3',
    slug: 'document-3-slug',
    app: 1,
    linked_document_set: null,
    parent: null,
    variation: null,
    token: '123a12',
    product_id: 'mock',
    is_deleted: false,
    deletion_datetime: null,
    app_user_access_type: 'selected',
    // old metadata. keeping for now as reference
    // metadata: {
    //   type: 'DOC',
    //   language: 'es',
    //   source: 'global',
    //   published: true,
    //   version: 1,
    //   created: '2020-03-01T12:30:00.000Z',
    // },
    owner_id: null,
    app_id: 1,
    app_name: 'Mock AppName',
    thumbnail_url: 'mock thumb url',
    has_variations: false,
    title: 'Document 3',
    description: '#3 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    published: false,
    metadata: {
      tags: [
        {
          term: 'pathway:Inguinal Hernia',
          label: 'pathway:Inguinal Hernia',
          scheme: '',
        },
        {
          term: 'stage:Waiting period',
          label: 'stage:Waiting period',
          scheme: '',
        },
      ],
      readonly: {
        ls: {
          carbon: {
            name: 'Document 1',
            slug: 'document-1-slug',
            uuid: '34c07626-33ec-431f-b206-082315eebbef',
            space: 'hernia-inguinal',
            created: {
              at: '2020-02-02 15:44:25Z',
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
            },
            imports: [
              {
                at: '2020-02-02 15:44:26Z',
                by: '8513bb60-034e-4478-acd7-3550e7b10928',
                pages: 1,
                metadata: {
                  name: 'L2.2_ES: ¿Qué es una hernia inguinal?',
                  slug: 'lectura-2-que-es-una-hernia-inguinal-1',
                  uuid: '5c8efa74-2d7c-4d0a-b511-403428a46b4a',
                  space: 'ecoach-content-bank',
                  created: {
                    at: '2019-08-12 08:08:22Z',
                    by: 'joelibarra',
                  },
                  imports: [
                    {
                      at: '2019-08-12 08:08:23Z',
                      by: 'joelibarra',
                      pages: 1,
                      metadata: {
                        name: 'Lectura 2: ¿Qué es una hernia inguinal? Page',
                        slug: '017_l2.2_es_lectura_2-docs',
                        uuid: 'ed2104d9-3d57-7875-2531-8561927783f1',
                        space: 'default-space',
                        created: {
                          at: '2019-08-12:15:43:30Z',
                          by: 'joel.ibarra',
                        },
                        version: {},
                        language: 'en-gb',
                        categories: ['all-documents', 'guides'],
                        linking_id: '017_l2.2_es_lectura_2-docs',
                        organisation: '',
                      },
                    },
                  ],
                  version: {
                    by: '8513bb60-034e-4478-acd7-3550e7b10928',
                    name: 'CE Export to new space',
                    number: 4,
                    created: '2020-02-02 15:43:02Z',
                  },
                  language: 'es',
                  categories: ['all-documents', 'hernia-inguinal'],
                  linking_id: 'lectura-2-que-es-una-hernia-inguinal-1',
                  organisation: 'philip-ecoach',
                },
              },
            ],
            version: {
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
              name: 'metadata export',
              number: 2,
              created: '2020-02-03 04:04:12Z',
            },
            language: 'en-gb',
            categories: ['all-documents', 'spanish'],
            linking_id: 'l22_es-que-es-una-hernia-inguinal',
            organisation: 'b-braun',
          },
        },
      },
      'source-url': '',
      'content-provider': 'eCoach',
      'original-authors': '',
      source: 'global',
      language: 'es',
    },
    latest_version: {
      name: 'Version 2',
      description: 'v2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      created: '2020-02-10 05:17:06Z',
    },
    links: {
      self: '/api/core/v1/apps/1/documents/1/',
      versions: '/api/core/v1/apps/1/documents/1/versions/',
      publishing_status: '/api/core/v1/apps/1/documents/1/channels/Public/',
      variations: '/api/core/v1/apps/1/documents/1/variations/',
    },
  },
  {
    id: 4,
    name: 'Document 4',
    slug: 'document-4-slug',
    app: 1,
    linked_document_set: null,
    parent: null,
    variation: null,
    token: '123a12',
    product_id: 'mock',
    is_deleted: false,
    deletion_datetime: null,
    app_user_access_type: 'selected',
    // old metadata. keeping for now as reference
    // metadata: {
    //   type: 'DOC',
    //   language: 'fr',
    //   source: 'global',
    //   published: true,
    //   version: 1,
    //   created: '2020-03-01T12:30:00.000Z',
    // },
    owner_id: null,
    app_id: 1,
    app_name: 'Mock AppName',
    thumbnail_url: 'mock thumb url',
    has_variations: false,
    title: 'Document 4',
    description: '#4 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    published: true,
    metadata: {
      tags: [
        {
          term: 'pathway:Inguinal Hernia',
          label: 'pathway:Inguinal Hernia',
          scheme: '',
        },
        {
          term: 'stage:Waiting period',
          label: 'stage:Waiting period',
          scheme: '',
        },
      ],
      readonly: {
        ls: {
          carbon: {
            name: 'Document 1',
            slug: 'document-1-slug',
            uuid: '34c07626-33ec-431f-b206-082315eebbef',
            space: 'hernia-inguinal',
            created: {
              at: '2020-02-02 15:44:25Z',
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
            },
            imports: [
              {
                at: '2020-02-02 15:44:26Z',
                by: '8513bb60-034e-4478-acd7-3550e7b10928',
                pages: 1,
                metadata: {
                  name: 'L2.2_ES: ¿Qué es una hernia inguinal?',
                  slug: 'lectura-2-que-es-una-hernia-inguinal-1',
                  uuid: '5c8efa74-2d7c-4d0a-b511-403428a46b4a',
                  space: 'ecoach-content-bank',
                  created: {
                    at: '2019-08-12 08:08:22Z',
                    by: 'joelibarra',
                  },
                  imports: [
                    {
                      at: '2019-08-12 08:08:23Z',
                      by: 'joelibarra',
                      pages: 1,
                      metadata: {
                        name: 'Lectura 2: ¿Qué es una hernia inguinal? Page',
                        slug: '017_l2.2_es_lectura_2-docs',
                        uuid: 'ed2104d9-3d57-7875-2531-8561927783f1',
                        space: 'default-space',
                        created: {
                          at: '2019-08-12:15:43:30Z',
                          by: 'joel.ibarra',
                        },
                        version: {},
                        language: 'en-gb',
                        categories: ['all-documents', 'guides'],
                        linking_id: '017_l2.2_es_lectura_2-docs',
                        organisation: '',
                      },
                    },
                  ],
                  version: {
                    by: '8513bb60-034e-4478-acd7-3550e7b10928',
                    name: 'CE Export to new space',
                    number: 4,
                    created: '2020-02-02 15:43:02Z',
                  },
                  language: 'es',
                  categories: ['all-documents', 'hernia-inguinal'],
                  linking_id: 'lectura-2-que-es-una-hernia-inguinal-1',
                  organisation: 'philip-ecoach',
                },
              },
            ],
            version: {
              by: '8513bb60-034e-4478-acd7-3550e7b10928',
              name: 'metadata export',
              number: 2,
              created: '2020-02-03 04:04:12Z',
            },
            language: 'en-gb',
            categories: ['all-documents', 'spanish'],
            linking_id: 'l22_es-que-es-una-hernia-inguinal',
            organisation: 'b-braun',
          },
        },
      },
      'source-url': '',
      'content-provider': 'eCoach',
      'original-authors': '',
      source: 'global',
      language: 'es',
    },
    latest_version: {
      name: 'Version 2',
      description: 'v2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      created: '2020-02-10 05:17:06Z',
    },
    links: {
      self: '/api/core/v1/apps/1/documents/1/',
      versions: '/api/core/v1/apps/1/documents/1/versions/',
      publishing_status: '/api/core/v1/apps/1/documents/1/channels/Public/',
      variations: '/api/core/v1/apps/1/documents/1/variations/',
    },
  },
];

const mockFormPreview = {
  schema: {
    title: 'Cuestionario 4: Introducir la fecha de las visitas del preoperatorio',
    description:
      'Entering important dates around the surgery helps to improve and personalize the information. If you remember the important dates, indicate them in the calendar.',
    definitions: {
      simpleAnswer: {
        type: 'number',
        enumNames: ['Yes', 'No'],
        enum: [1, 0],
      },
    },
    type: 'object',
    properties: {
      date: {
        title: 'Do you know the date and time of the pre-op visit?',
        $ref: '#/definitions/simpleAnswer',
        default: 0,
      },
      preOp: {
        type: 'string',
        format: 'date',
        title: 'If you answered yes, enter the date and time in the calendar',
      },
      intervencion: {
        title: 'Conoce la fecha y hora de la intervencion quirirgica?',
        $ref: '#/definitions/simpleAnswer',
        default: 0,
      },
      operation: {
        type: 'string',
        format: 'date',
        title: 'If you answered yes, enter the date and time in the calendar',
      },
    },
  },
  uiSchema: {
    date: {
      'ui:widget': 'radio',
      'ui:options': {
        inline: true,
      },
      'ui:title': 'Do you know the date and time of the pre-op visit?',
    },
    intervencion: {
      'ui:widget': 'radio',
      'ui:options': {
        inline: true,
      },
      'ui:title': 'Conoce la fecha y hora de la intervencion quirirgica?',
    },
  },
};

const documentVersions = [
  {
    id: '3',
    name: 'Version 2',
    version_number: 2,
    links: {
      configuration: '/api/core/v1/apps/1/documents/2/versions/3/configuration/',
      self: '/api/core/v1/apps/1/documents/2/versions/3/',
      pages: '/api/core/v1/apps/1/documents/2/versions/3/pages/',
    },
  },
  {
    id: '2',
    name: 'Version 1',
    version_number: 1,
    links: {
      configuration: '/api/core/v1/apps/1/documents/2/versions/2/configuration/',
      self: '/api/core/v1/apps/1/documents/2/versions/2/',
      pages: '/api/core/v1/apps/1/documents/2/versions/2/pages/',
    },
  },
];

const documentPages = [
  {
    id: '2',
    name: 'Introduction',
    source_page_number: 1,
  },
  {
    id: '3',
    name: 'Details',
    source_page_number: 2,
  },
  {
    id: '4',
    name: 'Causes',
    source_page_number: 3,
  },
  {
    id: '5',
    name: 'Prevention',
    source_page_number: 4,
  },
  {
    id: '6',
    name: 'Cures',
    source_page_number: 5,
  },
  {
    id: '7',
    name: 'Fin',
    source_page_number: 6,
  },
];

const forms = [
  {
    uuid: '736ac14d-4922-4990-9809-7bec665d6145',
    version: 2,
    name: 'Form 1',
    description:
      '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    language: 'de',
    source: 'global',
    json: {
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 1-app', ...mockFormPreview },
    },
  },
  {
    uuid: 'b1245566-8a62-42cc-ae28-821c48d33b92',
    version: 2,
    name: 'Form 2',
    description: '#2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    language: 'es',
    source: 'country',
    json: {
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 2-app', ...mockFormPreview },
    },
  },
  {
    uuid: '357e97c3-af3e-46b0-a759-50c92d65aa23',
    version: 4,
    name: 'Form 3',
    description:
      '#3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    language: 'en',
    source: 'hospital',
    json: {
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: false,
      form: { title: 'Form 3-app', ...mockFormPreview },
    },
  },
  {
    uuid: '48fa25e5-72ef-4ab6-ba61-e8141c0bb3e4',
    version: 1,
    name: 'Form 4',
    description: '#4 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    language: 'fr',
    source: 'hospital',
    json: {
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 4-app', ...mockFormPreview },
    },
  },
];

const messages = [
  {
    source: 'global',
    id: 'f7ec077d-0c71-4f88-8bd5-80c0e90121dd',
    created: '2020-01-10T12: 30: 00.000Z',
    version: 1,
    title: 'Message #1',
    published: true,
    payload: {},
    body:
      '1Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    language: 'en',
  },
  {
    source: 'global',
    id: 'c3013421-9729-4262-a114-b973a101be31',
    created: '2020-01-10T12: 30: 00.000Z',
    version: 2,
    title: 'Message #2',
    published: true,
    payload: {},
    body:
      '2Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    language: 'es',
  },
  {
    source: 'global',
    id: 'a3d2c3bc-703b-4a09-b68d-19e3f658e6fb',
    created: '2020-01-10T12: 30: 00.000Z',
    version: 1,
    title: 'Message #3',
    published: false,
    payload: {},
    body:
      '3Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    language: 'de',
  },
  {
    source: 'global',
    id: 'f7a2b8f5-ee43-4215-8dd1-766df00a0da9',
    created: '2020-01-10T12: 30: 00.000Z',
    version: 1,
    title: 'Message #4',
    published: true,
    payload: {},
    body:
      '4Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    language: 'fr',
  },
];

const procedurePathways = [
  {
    name: 'Procedure Induction',
    id: 1,
    url: 'https://pathways.com/82fc7d35-094a-43ee-bd3f-422221233c90',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    isActive: true,
    isDeleted: false,
    language: 'de',
    source: 'global',
    stages: [
      {
        number: 1,
        name: 'Start of the process',
        slug: 'stage-1-slug',
        id: '1',
        url: '<string>',
        description: '<string>',
        isAdhoc: false,
        rules: [
          {
            name: 'In the Beginning',
            what: 'FEATURE_DOCUMENT',
            when: 'INDEX_EVENT',
            who: 'ALL',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            name: 'Start Form',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'ALL',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '736ac14d-4922-4990-9809-7bec665d6145',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
      {
        number: 2,
        name: 'Knowing your pathology',
        slug: 'stage-2-slug',
        id: '2',
        url: '<string>',
        description: '<string>',
        isAdhoc: false,
        rules: [
          {
            name: 'Top 10 pathology facts',
            what: 'FEATURE_DOCUMENT',
            when: 'STAGE_TRANSITION',
            who: 'ALL',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            name: 'Hello message',
            what: 'MESSAGE',
            when: 'STAGE_TRANSITION',
            who: 'ALL',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: 'f9360684-d002-4eb1-8e28-5d773378b6da',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
      {
        number: 99,
        name: 'MANUAL RULES',
        slug: 'stage-99-slug',
        id: '345234562',
        url: '<string>',
        description: '<string>',
        isAdhoc: true,
        rules: [
          {
            name: 'DocuRule',
            what: 'FEATURE_DOCUMENT',
            when: 'STAGE_TRANSITION',
            who: 'GROUP',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            name: 'Message1',
            what: 'MESSAGE',
            when: 'STAGE_TRANSITION',
            who: 'ALL',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: 'f9360684-d002-4eb1-8e28-5d773378b6da',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
    ],
    indexEvents: [
      {
        eventTypeSlug: 'admission-sluggy',
        id: 123,
        url: '<string>',
        rules: [
          {
            name: 'In the Beginning',
            what: 'FEATURE_DOCUMENT',
            when: 'INDEX_EVENT',
            who: 'GROUP',
            id: '3453451111',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: 1,
            },
            whoDetail: {},
          },
          {
            name: 'Cool Form',
            what: 'FEATURE_FORM',
            when: 'INDEX_EVENT',
            who: 'ALL',
            id: '363454325',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: -2,
            },
            whoDetail: {},
          },
        ],
      },
      {
        eventTypeSlug: 'surgery-sluggy',
        id: 456,
        url: '<string>',
        rules: [
          {
            name: 'Hey there buddy',
            what: 'MESSAGE',
            when: 'INDEX_EVENT',
            who: 'do dolor ex dolore amet',
            id: '234123534',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: -3,
            },
            whoDetail: {},
          },
          {
            name: 'README.md',
            what: 'FEATURE_DOCUMENT',
            when: 'INDEX_EVENT',
            who: 'do dolor ex dolore amet',
            id: '3456467',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: 3,
            },
            whoDetail: {},
          },
        ],
      },
      {
        eventTypeSlug: 'discharge-sluggy',
        id: 789,
        url: '<string>',
        rules: [
          {
            name: 'Game of Thrones',
            what: 'FEATURE_DOCUMENT',
            when: 'INDEX_EVENT',
            who: 'do dolor ex dolore amet',
            id: '23421432',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: 8,
            },
            whoDetail: {},
          },
          {
            name: 'Dune',
            what: 'FEATURE_DOCUMENT',
            when: 'INDEX_EVENT',
            who: 'do dolor ex dolore amet',
            id: '7896578',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {
              days: -2,
            },
            whoDetail: {},
          },
        ],
      },
    ],
    metadata: {
      language: 'es',
    },
  },
  {
    name: 'Internal Sphincterotomy',
    id: 2,
    url: '<string>',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    isActive: false,
    isDeleted: false,
    language: 'en',
    source: 'country',
    stages: [
      {
        number: '<integer>',
        name: 'Stage #1',
        slug: 'stage-1-slug',
        id: '3',
        url: '<string>',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'FEATURE_DOCUMENT',
            when: 'STAGE_TRANSITION',
            who: 'do dolor ex dolore amet',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'FEATURE_DOCUMENT',
            when: 'STAGE_TRANSITION',
            who: 'in elit',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'in elit',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '736ac14d-4922-4990-9809-7bec665d6145',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
      {
        number: '<integer>',
        name: 'Stage #2',
        slug: 'stage-2-slug',
        id: '4',
        url: '<string>',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'adipisicing in proident aliqua culpa',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: 'b1245566-8a62-42cc-ae28-821c48d33b92',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'FEATURE_DOCUMENT',
            when: 'STAGE_TRANSITION',
            who: 'incididunt exercitation',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '1',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
    ],
    metadata: {
      language: 'en',
    },
  },
  {
    name: 'Inflammatory bowel disease',
    id: 3,
    url: '<string>',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    isActive: true,
    isDeleted: false,
    language: 'es',
    source: 'hospital',
    stages: [
      {
        number: '<integer>',
        name: 'Stage #1',
        slug: 'stage-1-slug',
        id: '5',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'do dolor ex dolore amet',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: 'b1245566-8a62-42cc-ae28-821c48d33b92',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'in elit',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '357e97c3-af3e-46b0-a759-50c92d65aa23',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
      {
        number: '<integer>',
        name: 'Stage #2',
        slug: 'stage-2-slug',
        id: '6',
        url: '<string>',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'adipisicing in proident aliqua culpa',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '48fa25e5-72ef-4ab6-ba61-e8141c0bb3e4',
            },
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
    ],
    metadata: {
      language: 'de',
    },
  },
  {
    name: 'Colon and rectal cancer',
    id: 4,
    url: '<string>',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    isActive: true,
    isDeleted: false,
    language: 'fr',
    source: 'country',
    stages: [
      {
        number: '<integer>',
        name: 'Stage #1',
        slug: 'stage-1-slug',
        id: '7',
        url: '<string>',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'FEATURE_FORM',
            when: 'STAGE_TRANSITION',
            who: 'do dolor ex dolore amet',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {
              id: '357e97c3-af3e-46b0-a759-50c92d65aa23',
            },
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'aute reprehenderit ex deserunt dolore',
            when: 'STAGE_TRANSITION',
            who: 'in elit',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {},
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
      {
        number: '<integer>',
        name: 'Stage #2',
        slug: 'stage-2-slug',
        id: '8',
        url: '<string>',
        description: '<string>',
        isAdhoc: '<boolean>',
        rules: [
          {
            title: '<string>',
            what: 'anim in aute',
            when: 'STAGE_TRANSITION',
            who: 'adipisicing in proident aliqua culpa',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {},
            whenDetail: {},
            whoDetail: {},
          },
          {
            title: '<string>',
            what: 'proident ad ex mollit dolor',
            when: 'STAGE_TRANSITION',
            who: 'incididunt exercitation',
            id: '<integer>',
            url: '<string>',
            description: '<string>',
            whatDetail: {},
            whenDetail: {},
            whoDetail: {},
          },
        ],
      },
    ],
    metadata: {
      language: 'fr',
    },
  },
];

const procedureRules = [
  {
    name: 'Rule 1',
    what: 'FEATURE_DOCUMENT',
    when: 'STAGE_TRANSITION',
    who: 'ALL',
    id: 'sdw34435',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      id: '1',
      title: 'Document 1',
      description: '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      metadata: {
        type: 'DOC',
        language: 'de',
        source: 'global',
        published: true,
        version: 1,
        created: '2020-03-01T12:30:00.000Z',
      },
    },
    whenDetail: {},
    whoDetail: {},
    language: 'fr',
    source: 'country',
  },
  {
    name: 'Rule 2',
    what: 'MESSAGE',
    when: 'DELAY',
    who: 'GROUP',
    id: 'asdfklh4384587',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      id: 'f9360684-d002-4eb1-8e28-5d773378b6da',
      title: 'Message 1',
      version: 1,
      description:
        '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      created: '2020-03-01T12:30:00.000Z',
      metadata: {
        tags: [
          {
            term: '',
            label: '',
          },
        ],
        language: 'de',
        source: 'global',
        published: true,
      },
      payload: {
        action: 'Open Content',
        type: 'DOC',
        id: 1,
      },
    },
    whenDetail: {
      days: 2,
    },
    whoDetail: [
      {
        id: 'a367bdf9-4f13-43a7-8df9-4120fb1230b8',
        title: 'Group 1',
        description: 'group 1 description.',
        language: 'en',
        source: 'global',
      },
    ],
    language: 'en',
    source: 'global',
  },
  {
    name: 'Rule 3',
    what: 'FEATURE_FORM',
    when: 'INDEX_EVENT',
    who: 'ALL',
    id: 'asdfklh4384sadf7',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      indexEvent: 'Surgery',
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
  {
    name: 'Rule 123',
    what: 'FEATURE_FORM',
    when: 'STAGE_TRANSITION',
    who: 'ALL',
    id: '912734986129346978234',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      indexEvent: 'Surgery',
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
  {
    name: 'Rule fgh',
    what: 'FEATURE_FORM',
    when: 'DELAY',
    who: 'ALL',
    id: '29436129386498276459',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      days: 1,
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
  {
    name: 'Rule TEST',
    what: 'FEATURE_FORM',
    when: 'INDEX_EVENT',
    who: 'ALL',
    id: 'asdfklh4xvzxcv384sadf7',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      days: 0,
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
  {
    name: 'Rule BEFORE',
    what: 'FEATURE_DOCUMENT',
    when: 'INDEX_EVENT',
    who: 'ALL',
    id: 'asdasdf3423fklh4xvzxcv384sadf7',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      days: -2,
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
  {
    name: 'Rule asd',
    what: 'FEATURE_FORM',
    when: 'MANUAL',
    who: 'ALL',
    id: '92386498172364986123984569',
    url: '<string>',
    description: '<string>',
    whatDetail: {
      title: 'Formy',
    },
    whenDetail: {
      days: 2,
    },
    whoDetail: {},
    language: 'de',
    source: 'hospital',
  },
];

const messagesHistory = [
  {
    title: 'Hello',
    body: 'World',
    metadata: {
      original: {
        id: 'f9360684-d002-4eb1-8e28-5d773378b6da',
        version: 1,
      },
      //delivery here
      delivery: 'Pathway',
    },
    payload: {
      action: 'Open Content',
      type: 'document',
      id: '525f5b34-a8e3-40ae-b7e4-4babe3ed2904',
    },
    //userids array
    users: ['0150e161-ccf5-406b-89e7-24f9ee89f302'],
    sentAt: '2020-06-01T12:40:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World2',
    metadata: {
      original: {
        id: '92611bd8-c4f9-47ad-9b34-6cba1f6aebf8',
        version: 1,
      },
      //delivery here
      delivery: 'Ad-hoc',
    },
    payload: {
      action: 'Open URL',
      link: 'www.youtube.com',
    },
    //userids array
    users: ['d92836d9-7c03-41e7-8c4e-94098ff1c10a'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World3',
    metadata: {
      original: {
        id: 'f0dc4755-85ec-4f04-8669-88f1dc6c52a9',
        version: 1,
      },
      //delivery here
      delivery: 'Pathway',
    },
    payload: {
      action: 'Open Content',
      type: 'form',
      id: '736ac14d-4922-4990-9809-7bec665d6145',
    },
    //userids array
    users: ['1199e3a8-2e23-4913-b371-4274b8c4710c'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World4',
    metadata: {
      original: {
        id: 'adfd6dda-c1c3-4204-b220-f4e57045ca81',
        version: 1,
      },
      //delivery here
      delivery: 'Pathway',
    },
    //userids array
    users: ['0150e161-ccf5-406b-89e7-24f9ee89f302'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World5',
    metadata: {
      original: {
        id: 'f9360684-d002-4eb1-8e28-5d773378b6da',
        version: 3,
      },
      //delivery here
      delivery: 'Ad-hoc',
    },
    payload: {
      action: 'Open Content',
      type: 'document',
      id: '525f5b34-a8e3-40ae-b7e4-4babe3ed2904',
    },
    //userids array
    users: ['1199e3a8-2e23-4913-b371-4274b8c4710c'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World6',
    metadata: {
      original: {
        id: '92611bd8-c4f9-47ad-9b34-6cba1f6aebf8',
        version: 1,
      },
      //delivery here
      delivery: 'Pathway',
    },
    payload: {
      action: 'Open URL',
      link: 'www.youtube.com',
    },
    //userids array
    users: ['0150e161-ccf5-406b-89e7-24f9ee89f302'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World7',
    metadata: {
      original: {
        id: 'f0dc4755-85ec-4f04-8669-88f1dc6c52a9',
        version: 1,
      },
      //delivery here
      delivery: 'Ad-hoc',
    },
    payload: {
      action: 'Open Content',
      type: 'form',
      id: '736ac14d-4922-4990-9809-7bec665d6145',
    },
    //userids array
    users: ['0150e161-ccf5-406b-89e7-24f9ee89f302'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
  {
    title: 'Hello',
    body: 'World8',
    metadata: {
      original: {
        id: 'adfd6dda-c1c3-4204-b220-f4e57045ca81',
        version: 1,
      },
      //delivery here
      delivery: 'Ad-hoc',
    },
    //userids array
    users: ['d92836d9-7c03-41e7-8c4e-94098ff1c10a'],
    sentAt: '2020-03-01T12:30:00.000Z',
  },
];

const indexEvents = [
  {
    name: 'Admission Date',
    slug: '<string>',
    id: 123,
    translated_names: {},
  },
  {
    name: 'Surgery Date',
    slug: '<string>',
    id: 456,
    translated_names: {},
  },
  {
    name: 'Discharge Date',
    slug: '<string>',
    id: 789,
    translated_names: {},
  },
];

const appUsers = [
  {
    age: null,
    city: 'Test City',
    email: 'test@liquid-state.com',
    title: 'Test title',
    doctor: 'test doctor',
    sex: 'Test Gender',
    address: 'Test Street, Test City, 3000',
    hospital: 'Hospital de Barcelona',
    language: 'en',
    lastName: 'Patient',
    postcode: '3000',
    firstName: 'Test',
    dateOfBirth: '13.01.1990',
    yearOfBirth: null,
    id: '36ee0aed-0c04-4826-a491-7b361b4370c0',
    uuid: '36ee0aed-0c04-4826-a491-7b361b4370c0',
    url: 'https://uis.ecoach.health/app-users/36ee0aed-0c04-4826-a491-7b361b4370c0/',
    ids: {
      pathways: '5a963dc6-0bc6-4937-9cd6-086d13abbf85',
      pip: '5a963dc6-0bc6-4937-9cd6-086d13abbf85',
      'document-search': '5a963dc6-0bc6-4937-9cd6-086d13abbf85',
      ubiquity: '5a963dc6-0bc6-4937-9cd6-086d13abbf85',
    },
    pathways: [],
    accessCode: 'NC1701',
    isActive: true,
  },
  {
    age: null,
    city: 'Test ',
    email: 'karel+new1@liquid-state.com',
    title: 'Test ',
    doctor: 'Dr One',
    sex: 'Test ',
    address: 'Test , Test , Test ',
    hospital: 'Hospital de Barcelona',
    language: 'en',
    lastName: 'Patient 1',
    postcode: 'Test ',
    firstName: 'New',
    dateOfBirth: 'Test ',
    yearOfBirth: null,
    id: '298b4216-e243-486c-9db1-f157b593a620',
    uuid: '298b4216-e243-486c-9db1-f157b593a620',
    url: 'https://uis.ecoach.health/app-users/298b4216-e243-486c-9db1-f157b593a620/',
    ids: {
      pathways: '6da7de99-1d20-4668-b119-e428e5b7931c',
      pip: '6da7de99-1d20-4668-b119-e428e5b7931c',
      'document-search': '6da7de99-1d20-4668-b119-e428e5b7931c',
      ubiquity: '6da7de99-1d20-4668-b119-e428e5b7931c',
    },
    pathways: [],
    accessCode: 'NC1701',
    isActive: true,
  },
];

const appUserPathways = {
  '5a963dc6-0bc6-4937-9cd6-086d13abbf85': [
    {
      id: 122,
      url: 'https://pathways.ecoach.health/v1/apps/f39e5e/appusers/134/pathways/122/',
      originalPathway: 1,
      currentStageSlug: 'stage-2-slug',
      disabledRuleIds: [],
    },
    {
      id: 118,
      url: 'https://pathways.ecoach.health/v1/apps/f39e5e/appusers/134/pathways/118/',
      originalPathway: 1,
      currentStageSlug: 'stage-1-slug',
      disabledRuleIds: [],
    },
  ],
};

const formSubmissions = [
  {
    uuid: '7c7994bf-25a1-4766-a531-1274417254bd',
    version: 1,
    userId: appUsers[0].id,
    originalFormType: '736ac14d-4922-4990-9809-7bec665d6145',
    submittedAt: '2020-01-05T12:30:00.000Z',
    formData: {
      date: 1,
      intervencion: 1,
      preOp: '2020-02-05',
      operation: '2020-02-10',
    },
    json: {
      description:
        '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      name: 'Form 1',
      language: 'de',
      source: 'global',
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 1-app', ...mockFormPreview },
    },
  },
  {
    uuid: '11c3bbd8-195a-4307-99dc-23ebc40887c4',
    version: 2,
    userId: appUsers[0].id,
    originalFormType: '736ac14d-4922-4990-9809-7bec665d6145',
    submittedAt: '2020-01-05T15:30:00.000Z',
    formData: {
      date: 1,
      intervencion: 1,
      preOp: '2020-02-15',
      operation: '2020-02-20',
    },
    json: {
      description:
        '#1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      name: 'Form 1',
      language: 'de',
      source: 'global',
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 1-app', ...mockFormPreview },
    },
  },
  {
    uuid: '9220b097-2ee4-46bd-a37d-03daa6d21919',
    version: 1,
    userId: appUsers[1].id,
    originalFormType: 'b1245566-8a62-42cc-ae28-821c48d33b92',
    submittedAt: '2020-01-06T09:30:00.000Z',
    formData: {
      date: 1,
      intervencion: 1,
      preOp: '2020-02-15',
      operation: '2020-02-20',
    },
    json: {
      description: '#2 Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      name: 'Form 2',
      language: 'es',
      source: 'country',
      type: 'FORM',
      created: '2020-03-04T12:30:00.000Z',
      published: true,
      form: { title: 'Form 2-app', ...mockFormPreview },
    },
  },
];

const groups = [
  {
    id: '33d2a408-02ae-42ac-a513-f0a10a3ddea4',
    description: 'testing',
    membership_mode: 'manual',
    owner_id: null,
    name: 'Test',
    users: [appUsers[0].id, appUsers[1].id],
    language: 'en',
    source: 'global',
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
  },
  {
    id: 'd00aa26f-d71c-4df7-958e-7b905196085b',
    name: 'test2',
    description: 'testing',
    membership_mode: 'automatic',
    owner_id: null,
    source: 'global',
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
    users: [appUsers[0].id, appUsers[1].id],
    language: 'de',
  },
  {
    id: 'a367bdf9-4f13-43a7-8df9-4120fb1230b8',
    name: 'Group 1',
    description: 'group 1 description.',
    language: 'en',
    rules: [],
    source: 'global',
    membership_mode: 'automatic',
    users: [appUsers[0].id, appUsers[1].id],
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
  },
  {
    id: '91c6083b-3747-45db-b791-c49cfb7c2c6c',
    name: 'Group 2',
    description: 'group 2 description.',
    language: 'es',
    rules: [],
    source: 'country',
    membership_mode: 'automatic',
    users: [appUsers[0].id, appUsers[1].id],
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
  },
  {
    id: '2a45f448-4a36-4b0a-9841-0800695c6903',
    name: 'Group 3',
    description: 'group 3 description.',
    language: 'de',
    rules: [],
    source: 'hospital',
    membership_mode: 'manual',
    users: [],
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
  },
  {
    id: 'ad009f80-d13e-4c0c-9d0c-e6f205ff2cef',
    name: 'Group 4',
    description: 'group 4 description.',
    language: 'de',
    rules: [],
    source: 'hospital',
    membership_mode: 'manual',
    users: [appUsers[0].id],
    ruleset: {
      pk: 1,
      keys: [],
      operators: [
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'not_equals',
          name: 'Not Equal',
        },
        {
          key: 'is_one_of',
          name: 'Is one of',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'starts_with',
          name: 'Starts with',
        },
        {
          key: 'equals',
          name: 'Equal',
        },
        {
          key: 'greater_than',
          name: 'Greater than',
        },
        {
          key: 'greater_than_equals',
          name: 'Greater than or equal',
        },
        {
          key: 'less_than',
          name: 'Less than',
        },
        {
          key: 'less_than_equals',
          name: 'Less than or equal',
        },
        {
          key: 'equal',
          name: 'Equal',
        },
        {
          key: 'before',
          name: 'Before',
        },
        {
          key: 'after',
          name: 'After',
        },
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'not_contains',
          name: 'Does not contain',
        },
        {
          key: 'is',
          name: 'Is',
        },
        {
          key: 'is_not',
          name: 'Is Not',
        },
      ],
      rules: [
        {
          pk: 1,
          predicates: [
            {
              pk: 1,
              key: 'user__date_joined',
              operator: 'equal',
              value: '2020-02-03T04:26:54.480Z',
            },
            {
              pk: 2,
              key: 'user__app_user__delegated_auth_id',
              operator: 'contains',
              value: 'abc123',
            },
          ],
        },
        {
          pk: 2,
          predicates: [
            {
              pk: 3,
              key: 'user__last_login',
              operator: 'equal',
              value: '2020-02-03T04:27:09.391Z',
            },
          ],
        },
      ],
    },
  },
];

export default {
  documents,
  documentVersions,
  documentPages,
  groups,
  appUsers,
  appUserPathways,
  messages,
  forms,
  formSubmissions,
  procedurePathways,
  procedureRules,
  messagesHistory,
  indexEvents,
};
