import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import SelectContentModal from '@components/SelectContentModal';
import { NOTIFICATION_ONLY, OPEN_CONTENT, OPEN_LINK } from '@constants';
import { documentTypes, FORM, formTypes, WEBSITE, WEB_FORM } from '@utils/contentTypes';
import ContentInformation from '@cards/Messages/ContentInformation';

const contentTypes = [...documentTypes, ...formTypes, WEB_FORM, WEBSITE];

function prepareContentForMessage(content) {
  if (content.type === FORM) {
    const { latestVersion, ...dataForMessage } = content;
    return dataForMessage;
  }

  return content;
}

function ContentCard() {
  const { currentStepData, form, formData, setCurrentStepData } = useWizard();
  const [actionType, setActionType] = useState(currentStepData.action || null);
  const [isSelectContentVisible, setSelectContentVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setActionType(currentStepData.action);
  }, [currentStepData.action]);

  const onCancelSelectContent = useCallback(() => {
    form.setFieldsValue({ action: null });
    setActionType(null);
    setSelectContentVisible(false);
    const { contentTitle, contentType, ...rest } = currentStepData;
    setCurrentStepData(rest);
  }, [currentStepData, form, setActionType, setCurrentStepData]);

  const onSubmitSelectContent = useCallback(
    (_, [content]) => {
      const contentData = prepareContentForMessage(content);

      setCurrentStepData({
        ...currentStepData,
        action: actionType,
        contentTitle: content.title || content.name,
        contentType: content.metadata.type,
        contentData,
      });
      setSelectContentVisible(false);
    },
    [actionType, currentStepData, setCurrentStepData],
  );

  const onChangeContent = useCallback(() => {
    setSelectContentVisible(true);
  }, []);

  useEffect(() => {
    const action = form.getFieldValue('action');

    if (action !== actionType && action === OPEN_CONTENT) {
      setSelectContentVisible(true);
    }

    setActionType(action);
  }, [actionType, form]);

  return (
    <>
      <Card
        className="content-message-wizards-content"
        title={t('messages:ContentMessageWizard.steps.content')}
      >
        <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
          <Form.Item label={t('messages:Wizard.details.body.label')}>
            {form.getFieldDecorator('content', {
              initialValue: currentStepData.content,
              rules: [{ required: true, message: t('messages:Wizard.details.body.required') }],
            })(<Input placeholder={t('messages:Wizard.details.body.label')} />)}
          </Form.Item>
          <Form.Item label={t('messages:Wizard.details.actions.label')}>
            {form.getFieldDecorator('action', {
              initialValue: currentStepData.action,
              rules: [{ required: true, message: t('messages:Wizard.details.actions.required') }],
            })(
              <Select placeholder={t('messages:Wizard.details.actions.placeholder')}>
                <Select.Option value={NOTIFICATION_ONLY}>
                  {t('messages:Wizard.details.actions.notification')}
                </Select.Option>
                <Select.Option value={OPEN_CONTENT}>
                  {t('messages:Wizard.details.actions.content')}
                </Select.Option>
                <Select.Option value={OPEN_LINK}>
                  {t('messages:Wizard.details.actions.link')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item>
          {actionType === OPEN_LINK ? (
            <Form.Item label={t('messages:Wizard.details.websiteLink.label')}>
              {form.getFieldDecorator('websiteLink', {
                initialValue: currentStepData.websiteLink,
                rules: [
                  { required: true, message: t('messages:Wizard.details.websiteLink.required') },
                  {
                    pattern: /(http:\/\/|https:\/\/).*/,
                    message: t('messages:Wizard.details.websiteLink.pattern'),
                  },
                ],
              })(<Input />)}
            </Form.Item>
          ) : null}
          {actionType === OPEN_CONTENT && currentStepData.contentType ? (
            <ContentInformation
              title={currentStepData.contentTitle}
              type={currentStepData.contentType}
              onChangeContent={onChangeContent}
            />
          ) : null}
        </Form>
      </Card>
      {isSelectContentVisible ? (
        <SelectContentModal
          contentTypes={contentTypes}
          defaultFilteredValue={{ language: [formData.details.language] }}
          visible={isSelectContentVisible}
          onCancel={onCancelSelectContent}
          onSubmit={onSubmitSelectContent}
          audienceTypesToFilter={['clinician']}
        />
      ) : null}
    </>
  );
}

export default ContentCard;
