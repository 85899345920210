import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Card } from '../../Card';
import { fetchForms, downloadFormDataForAppUser } from '../../../redux/forms/actions';
import { selectForm } from '../../../redux/forms/reducers';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FormDataDownload = ({ appUserId, form, formData, loading, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDownload = useCallback(
    () => dispatch(downloadFormDataForAppUser(form.uuid, appUserId)),
    [appUserId, dispatch, form],
  );

  return (
    <Card.Half
      className="form-content"
      noPadding
      title={t('cards:FormDataDownload.title')}
      loading={loading}
      {...props}
    >
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <Form.Item label={t('cards:FormDataDownload.allDataLabel')}>
            <Button onClick={() => handleDownload()}>
              {t('cards:FormDataDownload.allDataDownloadText')}
            </Button>
          </Form.Item>
          <Text type="secondary">{t('cards:FormDataDownload.explain')}</Text>
        </Form>
      )}
    </Card.Half>
  );
};

const FormDataDownloadbyId = ({ appUserId, formId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch, formId]);

  const [loading, form] = useSelector(selectForm(formId));

  return <FormDataDownload appUserId={appUserId} form={form} loading={loading} {...props} />;
};

export { FormDataDownload };

export default FormDataDownloadbyId;
