import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { Form } from 'antd';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitalFromList } from '@redux/hospitals/reducers';
import { Card } from '@cards/Card';
import LanguageIcon from '@components/LanguageIcon';
// import DetailRow from '@components/DetailRow';
import { useTranslation } from 'react-i18next';

const AppAdminDetailsCard = ({ hospitalId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [loading, hospital] = useSelector(selectHospitalFromList(hospitalId));

  return (
    <Card.Half
      className="app-admin-details"
      loading={loading}
      title={t('organisations:OrganisationDetails.DashboardSetup.AppAdminDetailsCard.title')}
    >
      <p>{hospital?.name}</p>
      <LanguageIcon language={hospital?.language} showText />
      {/* <Form
        labelCol={{ span: 10 }}
        layout="vertical"
        wrapperCol={{ span: 14 }}
        className="detail-form"
      > */}
      {/* <DetailRow
          label={t('hospitals:HospitalDetails.DashboardSetup.AppAdminDetailsCard.name')}
          value={hospital && hospital.admin.name}
        />
        <DetailRow
          label={t('hospitals:HospitalDetails.DashboardSetup.AppAdminDetailsCard.email')}
          value={hospital && hospital.admin.email}
        /> */}
      {/* </Form> */}
    </Card.Half>
  );
};

AppAdminDetailsCard.propTypes = {
  hospitalId: PropTypes.string,
};

AppAdminDetailsCard.defaultProps = {};

export default AppAdminDetailsCard;
