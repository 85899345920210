import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Modal, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { languageFromMetadata, sourceFromMetadata } from '@utils/contentMetadata';
import { fetchDocuments, deleteDocuments } from '@redux/documents/actions';
import { selectDocumentDeleting } from '@redux/documents/reducers';
import { SelectorContext } from '@pages/Documents';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import createColumns from './createColumns';
import searchDocuments from '@utils/searchDocuments';
import IconButton from '@components/IconButton';
import MessageButton from '@components/MessageButton';
import SearchableTable from '@components/SearchableTable';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';
import ContentRef from '@utils/contentRef';

const confirmDelete = (onOk, t, documentTranslationKey) => {
  Modal.confirm({
    title: t(`${documentTranslationKey}:DocumentDetail.confirmDelete`),
    onOk,
    onCancel: () => {},
  });
};

const DocumentListCard = ({ showTitle, documentTranslationKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const selector = useContext(SelectorContext);

  const selectLanguage = useSelector(languageFromMetadata);
  const selectSource = useSelector(sourceFromMetadata);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsContent, setSelectedRowsContent] = useState([]);
  const columns = useMemo(
    () => createColumns(t, selectLanguage, selectSource, documentTranslationKey),
    [t, selectLanguage, selectSource, documentTranslationKey],
  );

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  const [loading, documents] = useSelector(selector);

  const { [Permissions.ViewPatients]: canViewPatients } = useHasPermissions(
    Permissions.ViewPatients,
  );

  return (
    <SearchableTable
      columns={columns}
      dataSource={documents}
      rowKey="content"
      extra={
        <>
          {canViewPatients ? (
            <>
              <MessageButton
                disabled={
                  selectedRows.length !== 1 ||
                  selectedRowsContent.map(content => content.audience_type).includes('clinician')
                }
                onClick={() => {
                  const ref = ContentRef.fromString(selectedRows[0]);
                  const doc = documents.find(doc =>
                    ref.matches(doc.content, { ignoreVersion: true }),
                  );
                  history.push(`/messages/new/${doc.id}`);
                }}
              />
              <Divider key="divider" type="vertical" />
            </>
          ) : null}
          <IconButton
            disabled={!selectedRows.length}
            icon={faTrashAlt}
            tooltip={t('common:buttons.delete')}
            onClick={() =>
              confirmDelete(
                () => {
                  const documentIds = selectedRows
                    .map(content => {
                      const ref = ContentRef.fromString(content);
                      return documents.find(d => ref.matches(d.content, { ignoreVersion: true }))
                        ?.id;
                    })
                    .filter(Boolean);
                  dispatch(deleteDocuments(documentIds, documentTranslationKey));
                  return waitForStoreUpdate(store, selectDocumentDeleting); // must return for modal to be dismissed
                },
                t,
                documentTranslationKey,
              )
            }
          />
        </>
      }
      filterFunc={searchDocuments}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? t('cards:DocumentList.title') : null}
      onRowSelectionChange={(selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRowKeys);
        setSelectedRowsContent(selectedRows);
      }}
    />
  );
};

export default DocumentListCard;
