import React, { useState } from 'react';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from '@cards/Card';
import {
  whatTypes,
  whatLabelTypes,
  ALL_ENGAGED,
  AT_LEAST_N,
  ZERO,
  ONCE,
  REPEATING,
  FOREVER,
  UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED,
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  FEATURE_WEBLINK,
} from '@constants';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { selectEngagementCheck } from '@pathways/redux/pathways/reducers';
import ContentPreview from '../RuleDetails/ContentPreview';
import { getTimingDescription } from '../../../pages/Pathways/EngagementCheckWizard/utils';
import './style.less';

const featureTypes = [
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  FEATURE_WEBLINK,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
];

const WHAT = 'what';

function EngagementCheckRuleDetails({ engagementCheck, loading, pathway }) {
  const { i18n, t } = useTranslation();

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [content, setContent] = useState(null);

  const selectedContent = engagementCheck?.contentToCheckEngagement?.selectedContent || [];

  const occurrenceTargetEngagementCheck = useSelector(
    selectEngagementCheck(
      pathway?.id,
      engagementCheck?.occurrences?.untilAnotherEngagementCheckPassed,
    ),
  );

  if (!engagementCheck && !loading) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const formatContentAsRule = c => {
    const type = c.metadata?.type || c.type;
    return {
      what:
        featureTypes.find(feature =>
          type ? feature.split('_')[1] === type : feature === c.what,
        ) || (c.what === MESSAGE ? MESSAGE : FEATURE_WEBLINK),
      whatDetail: c,
    };
  };

  return (
    <>
      <Card className="engagement-check-details" loading={loading}>
        <DetailForm>
          <h3>{t('cards:EngagementCheckRuleDetails.details')}</h3>
          <DetailRow
            label={t('cards:EngagementCheckRuleDetails.name')}
            value={engagementCheck?.details?.name}
          />
          {engagementCheck?.details?.description && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.description')}
              value={engagementCheck?.details.description.split('\n').map(line => (
                <p key={line}>
                  {line}
                  <br />
                </p>
              ))}
            />
          )}
          <h3>{t('cards:EngagementCheckRuleDetails.pathway')}</h3>
          <DetailRow label={t('cards:EngagementCheckRuleDetails.name')} value={pathway?.name} />

          <h3>{t('cards:EngagementCheckRuleDetails.contentItems')}</h3>
          {selectedContent.length > 0 &&
            selectedContent.map((content, index) => (
              <React.Fragment key={index}>
                <DetailRow
                  label={t('cards:EngagementCheckRuleDetails.contentItemNumber', {
                    number: index + 1,
                  })}
                  value={
                    content.whatDetail?.title ||
                    content.whatDetail?.name ||
                    content.title ||
                    content.name ||
                    content.json.title ||
                    content.json?.name
                  }
                />
                <DetailRow
                  label=" "
                  value={
                    <Button
                      onClick={() => {
                        setContent(content.whatDetail ? content : formatContentAsRule(content));
                        setPreviewVisible(true);
                      }}
                    >
                      {t('common:buttons.preview')}
                    </Button>
                  }
                />
              </React.Fragment>
            ))}

          <h3>{t('cards:EngagementCheckRuleDetails.checkType')}</h3>
          {engagementCheck?.typeOfCheck?.checkType === ALL_ENGAGED && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.checkTypeAtLeastReview')}
              value={t('cards:EngagementCheckRuleDetails.checkTypeAll')}
            />
          )}
          {engagementCheck?.typeOfCheck?.checkType === AT_LEAST_N && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.checkTypeAtLeastReview')}
              value={engagementCheck?.typeOfCheck?.numberOfContents}
            />
          )}
          {engagementCheck?.typeOfCheck?.checkType === ZERO && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.checkTypeAtLeastReview')}
              value={t('cards:EngagementCheckRuleDetails.checkTypeZero')}
            />
          )}

          <h3>{t('cards:EngagementCheckRuleDetails.timing')}</h3>
          {engagementCheck?.timing?.from?.type && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.fromType')}
              value={getTimingDescription(
                engagementCheck?.timing.from.type,
                engagementCheck?.timing.from.daysOffset,
                engagementCheck?.timing.from.value?.translatedNames?.[i18n.language] ||
                  engagementCheck?.timing.from.value?.name,
                t,
              )}
            />
          )}
          {engagementCheck?.timing?.until?.type && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.untilType')}
              value={getTimingDescription(
                engagementCheck?.timing.until.type,
                engagementCheck?.timing.until.daysOffset,
                engagementCheck?.timing.until.value?.translatedNames?.[i18n.language] ||
                  engagementCheck?.timing.until.value?.name,
                t,
              )}
            />
          )}
          {engagementCheck?.timing?.start?.type && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.startType')}
              value={getTimingDescription(
                engagementCheck?.timing.start.type,
                engagementCheck?.timing.start.daysOffset,
                engagementCheck?.timing.start.value?.translatedNames?.[i18n.language] ||
                  engagementCheck?.timing.start.value?.name,
                t,
              )}
            />
          )}
          {engagementCheck?.timing?.stop?.type && (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.stopType')}
              value={getTimingDescription(
                engagementCheck?.timing.stop.type,
                engagementCheck?.timing.stop.daysOffset,
                engagementCheck?.timing.stop.value?.translatedNames?.[i18n.language] ||
                  engagementCheck?.timing.stop.value?.name,
                t,
              )}
            />
          )}

          <h3>{t('cards:EngagementCheckRuleDetails.action')}</h3>
          <DetailRow
            label={t('cards:EngagementCheckRuleDetails.action')}
            value={t(whatTypes[engagementCheck?.action?.what])}
          />
          {engagementCheck?.action?.whatDetail &&
          (engagementCheck?.action?.whatDetail.json?.name ||
            engagementCheck?.action?.whatDetail.name ||
            engagementCheck?.action?.whatDetail.title) ? (
            <>
              <DetailRow
                label={t(whatLabelTypes[engagementCheck?.action.what])}
                value={
                  engagementCheck?.action.whatDetail.title ||
                  engagementCheck?.action.whatDetail.json?.name ||
                  engagementCheck?.action.whatDetail.name
                }
              />
              <DetailRow
                label=" "
                value={
                  <Button
                    onClick={() => {
                      setContent(engagementCheck?.action);
                      setPreviewVisible(true);
                    }}
                  >
                    {t('common:buttons.preview')}
                  </Button>
                }
              />
            </>
          ) : null}

          <h3>{t('cards:EngagementCheckRuleDetails.occurrences')}</h3>
          {engagementCheck?.occurrences?.type === ONCE ? (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.occurrencesType')}
              value={t('cards:EngagementCheckRuleDetails.once')}
            />
          ) : engagementCheck?.occurrences?.type === REPEATING ? (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.occurrencesType')}
              value={t('cards:EngagementCheckRuleDetails.nTimesCount', {
                count: engagementCheck?.occurrences?.nTimes,
              })}
            />
          ) : engagementCheck?.occurrences?.type === FOREVER ? (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.occurrencesType')}
              value={t('cards:EngagementCheckRuleDetails.forever')}
            />
          ) : engagementCheck?.occurrences?.type === UNTIL_ANOTHER_ENGAGEMENT_CHECK_PASSED ? (
            <DetailRow
              label={t('cards:EngagementCheckRuleDetails.occurrencesType')}
              value={t('cards:EngagementCheckRuleDetails.untilAnotherEngagementCheckPassed')}
            />
          ) : null}
          {occurrenceTargetEngagementCheck?.id && (
            <>
              <h3>{t('cards:EngagementCheckRuleDetails.selectedCheck')}</h3>
              <EngagementCheckRuleDetails
                engagementCheck={occurrenceTargetEngagementCheck}
                pathway={pathway}
              />
            </>
          )}
        </DetailForm>
      </Card>
      <ContentPreview
        previewType={WHAT}
        rule={content}
        visible={isPreviewVisible}
        onCancel={() => setPreviewVisible(false)}
      />
    </>
  );
}

export default EngagementCheckRuleDetails;
