import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { Column } from '@cards/Card';
import DashboardUserListCard from '@cards/DashboardUsers/List';
import HeaderButton from '@components/HeaderButton';
import { historyType, matchType } from '../../../propTypes';
import { setCurrentScreen, screens } from '../../../analytics';

function DashboardUserDetails({ history, match }) {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_USERS_LIST);
  }, []);

  const { t } = useTranslation();
  usePageActions({
    actions: [
      <HeaderButton key="new-dashboard-user" onClick={() => history.push(`${match.path}/new`)}>
        {t('dashboardUsers:List.new')}
      </HeaderButton>,
    ],
    title: t('dashboardUsers:List.title'),
  });

  return (
    <Column>
      <DashboardUserListCard />
    </Column>
  );
}

DashboardUserDetails.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default DashboardUserDetails;
