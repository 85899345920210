import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import LanguageSelect from '@components/LanguageSelect';
import { selectOrganisationLanguage } from '@organisation/redux/selectors';

function LanguageFormItem({ form, currentLanguage }) {
  const { t } = useTranslation();
  const orgLanguage = useSelector(selectOrganisationLanguage);

  return (
    <Form.Item label={t('common:Wizard.details.language.label')}>
      {form.getFieldDecorator('language', {
        initialValue: currentLanguage || orgLanguage,
        rules: [
          {
            required: true,
            message: t('common:Wizard.details.language.required'),
          },
        ],
      })(<LanguageSelect />)}
    </Form.Item>
  );
}

LanguageFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string,
};

LanguageFormItem.defaultProps = {
  currentLanguage: undefined,
};

export default LanguageFormItem;
