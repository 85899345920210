import { combineReducers } from 'redux';
import { IForm } from '@redux/forms/types';
import { ITemporaryFormsLoaded } from '@redux/forms/actions';
import { IState } from '@redux/reducer';
import {
  ACTIVATE_APP_USER,
  APP_USER_ACTIVATION_FAILED,
  APP_USER_DEACTIVATION_FAILED,
  LOAD_APP_USERS_FORMS,
  APP_USERS_FORMS_LOADED,
  APP_USERS_LOADED,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS_FAILED,
  INVITE_APP_USER,
  INVITE_APP_USER_SUCCESS,
  LOAD_APP_USERS,
  IAppUsersDataState,
  IAppUsersFormsState,
  IAppUser,
  INVITE_APP_USER_FAILED,
  IFormSubmission,
  APP_USER_SUBMIT_FORM_SUCCESS,
  APP_USER_SUBMIT_FORM_FAILED,
  UPDATE_APP_USER,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_FAILED,
  APP_USER_SUBMIT_FORM,
  RESEND_APP_USER_INVITE,
  RESEND_APP_USER_INVITE_FAILED,
  RESEND_APP_USER_INVITE_SUCCESS,
  APP_USER_ACTIVATED,
  APP_USER_DEACTIVATED,
  APP_USER_FORM_COMPUTATIONS_LOADED,
  FETCH_APP_USER_FILES,
  FETCH_APP_USER_FILES_SUCCESS,
  FETCH_APP_USER_FILES_FAILED,
  CREATE_APP_USER_FILE,
  CREATE_APP_USER_FILE_SUCCESS,
  CREATE_APP_USER_FILE_FAILED,
  UPDATE_APP_USER_FILE,
  UPDATE_APP_USER_FILE_SUCCESS,
  UPDATE_APP_USER_FILE_FAILED,
  REMOVE_APP_USER_FILE,
  REMOVE_APP_USER_FILE_SUCCESS,
  REMOVE_APP_USER_FILE_FAILED,
  GET_APP_USER_FILE_DOWNLOAD_URL,
  GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS,
  GET_APP_USER_FILE_DOWNLOAD_URL_FAILED,
  CLEAR_APP_USER_FILE_DOWNLOAD_URL,
  FETCH_APP_USER_ENGAGEMENTS,
  FETCH_APP_USER_ENGAGEMENTS_SUCCESS,
  FETCH_APP_USER_ENGAGEMENTS_FAILED,
  FETCH_APP_USER_DAILY_STEPS,
  FETCH_APP_USER_DAILY_STEPS_SUCCESS,
  FETCH_APP_USER_DAILY_STEPS_FAILED,
  ADD_APP_USER_VIEWED_CONTENT_ID,
} from './types';
import {
  IInviteAppUser,
  ILoadAppUsers,
  IFetchAppUsersFailed,
  IInviteAppUserSuccess,
  IAppUsersLoaded,
  IDeleteAppUsers,
  IActivateAppUser,
  IAppUserActivationFailed,
  IDeactivateAppUser,
  IAppUserDeactivationFailed,
  IAppUsersFormsLoaded,
  IFetchAppUsersForms,
  IInviteAppUserFailed,
  ISubmitFormAsAppUser,
  ISubmitFormAsAppUserSuccess,
  ISubmitFormAsAppUserFailed,
  ILoadAppUserForms,
  IUpdateAppUser,
  IUpdateAppUserSuccess,
  IUpdateAppUserFailed,
  IResendAppUserInvite,
  IResendAppUserInviteFailed,
  IResendAppUserInviteSuccess,
  IAppUserActivated,
  IAppUserDeactivated,
  IFetchFormsForAppUserResults,
  IAppUserFormComputationsLoaded,
  IFetchAppUserFiles,
  IFetchAppUserFilesSuccess,
  IFetchAppUserFilesFailed,
  ICreateAppUserFile,
  ICreateAppUserFileSuccess,
  ICreateAppUserFileFailed,
  IUpdateAppUserFile,
  IUpdateAppUserFileSuccess,
  IUpdateAppUserFileFailed,
  IRemoveAppUserFile,
  IRemoveAppUserFileSuccess,
  IRemoveAppUserFileFailed,
  IGetAppUserFileDownloadUrl,
  IGetAppUserFileDownloadUrlSuccess,
  IGetAppUserFileDownloadUrlFailed,
  IClearAppUserFileDownloadUrl,
  IFetchAppUserEngagements,
  IFetchAppUserEngagementsSuccess,
  IFetchAppUserEngagementsFailed,
  IFetchAppUserDailySteps,
  IFetchAppUserDailyStepsSuccess,
  IFetchAppUserDailyStepsFailed,
  IAddAppUserViewedContentId,
} from './actions';

const creating = (
  state = false,
  action: IInviteAppUser | IInviteAppUserSuccess | IInviteAppUserFailed,
) => {
  switch (action.type) {
    case INVITE_APP_USER:
      return true;
    case INVITE_APP_USER_FAILED:
    case INVITE_APP_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

const loading = (
  state = false,
  action: ILoadAppUsers | IFetchAppUsersFailed | IAppUsersLoaded,
): boolean => {
  switch (action.type) {
    case LOAD_APP_USERS:
      return true;
    case FETCH_APP_USERS_FAILED:
    case APP_USERS_LOADED:
      return false;
    default:
      return state;
  }
};

const resendingInvite = (
  state = false,
  action: IResendAppUserInvite | IResendAppUserInviteFailed | IResendAppUserInviteSuccess,
): boolean => {
  switch (action.type) {
    case RESEND_APP_USER_INVITE:
      return true;
    case RESEND_APP_USER_INVITE_FAILED:
    case RESEND_APP_USER_INVITE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const togglingAccess = (
  state = false,
  action:
    | IActivateAppUser
    | IAppUserActivated
    | IAppUserActivationFailed
    | IDeactivateAppUser
    | IAppUserDeactivated
    | IAppUserDeactivationFailed,
) => {
  switch (action.type) {
    case ACTIVATE_APP_USER:
    case DEACTIVATE_APP_USER:
      return true;
    case APP_USER_ACTIVATED:
    case APP_USER_ACTIVATION_FAILED:
    case APP_USER_DEACTIVATED:
    case APP_USER_DEACTIVATION_FAILED:
      return false;
    default:
      return state;
  }
};

const updating = (
  state = false,
  action: IUpdateAppUser | IUpdateAppUserSuccess | IUpdateAppUserFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_APP_USER:
      return true;
    case UPDATE_APP_USER_FAILED:
    case UPDATE_APP_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

const dataInitialState = {
  list: [],
  byId: {},
};

const data = (
  state: IAppUsersDataState = dataInitialState,
  action:
    | IFetchAppUsersFailed
    | IAppUsersLoaded
    | IDeleteAppUsers
    | IInviteAppUserSuccess
    | IActivateAppUser
    | IAppUserActivationFailed
    | IDeactivateAppUser
    | IAppUserDeactivationFailed
    | IUpdateAppUserSuccess
    | IFetchAppUserFiles
    | IFetchAppUserFilesSuccess
    | IFetchAppUserFilesFailed
    | ICreateAppUserFile
    | ICreateAppUserFileSuccess
    | ICreateAppUserFileFailed
    | IUpdateAppUserFile
    | IUpdateAppUserFileSuccess
    | IUpdateAppUserFileFailed
    | IRemoveAppUserFile
    | IRemoveAppUserFileSuccess
    | IRemoveAppUserFileFailed
    | IGetAppUserFileDownloadUrl
    | IGetAppUserFileDownloadUrlSuccess
    | IGetAppUserFileDownloadUrlFailed
    | IClearAppUserFileDownloadUrl
    | IFetchAppUserEngagements
    | IFetchAppUserEngagementsSuccess
    | IFetchAppUserEngagementsFailed
    | IFetchAppUserDailySteps
    | IFetchAppUserDailyStepsSuccess
    | IFetchAppUserDailyStepsFailed
    | IAddAppUserViewedContentId,
): IAppUsersDataState => {
  switch (action.type) {
    case FETCH_APP_USERS_FAILED:
      return dataInitialState;
    case APP_USERS_LOADED:
      return {
        ...state,
        list: action.payload.appUsers.map(appUser => appUser.id),
        byId: action.payload.appUsers.reduce(
          (acc, appUser) => ({
            ...acc,
            [appUser.id]: {
              ...appUser,
              files: state.byId[appUser.id] ? state.byId[appUser.id].files : [],
              engagements: state.byId[appUser.id] ? state.byId[appUser.id].engagements : [],
              healthData: state.byId[appUser.id]
                ? state.byId[appUser.id].healthData
                : { dailySteps: [] },
            },
          }),
          {},
        ),
      };
    case DELETE_APP_USERS:
      return {
        ...state,
        list: state.list.filter(id => !action.payload.appUserIds.includes(id)),
      };

    case INVITE_APP_USER_SUCCESS:
      return {
        ...state,
        list: [action.payload.id, ...state.list],
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload.appUser,
        },
      };
    case ACTIVATE_APP_USER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: true,
          },
        },
      };
    case APP_USER_ACTIVATION_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: false,
          },
        },
      };
    case DEACTIVATE_APP_USER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: false,
          },
        },
      };
    case APP_USER_DEACTIVATION_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            isActive: true,
          },
        },
      };
    case UPDATE_APP_USER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: action.payload.appUser,
        },
      };
    case FETCH_APP_USER_FILES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId]?.files?.files, loading: true },
          },
        },
      };
    case FETCH_APP_USER_FILES_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: {
              files: action.payload.files,
              loading: false,
            },
          },
        },
      };
    case FETCH_APP_USER_FILES_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId]?.files?.files, loading: false },
          },
        },
      };
    case CREATE_APP_USER_FILE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: true },
          },
        },
      };
    case CREATE_APP_USER_FILE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: {
              files: [
                ...(state.byId[action.payload.appUserId]?.files?.files || []),
                action.payload.file,
              ],
              loading: false,
            },
          },
        },
      };
    case CREATE_APP_USER_FILE_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: false },
          },
        },
      };
    case UPDATE_APP_USER_FILE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: true },
          },
        },
      };
    case UPDATE_APP_USER_FILE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: {
              files: state.byId[action.payload.appUserId]?.files?.files?.map((file: any) =>
                file.uuid === action.payload.file.uuid ? action.payload.file : file,
              ),
              loading: false,
            },
          },
        },
      };
    case UPDATE_APP_USER_FILE_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: false },
          },
        },
      };
    case REMOVE_APP_USER_FILE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: true },
          },
        },
      };
    case REMOVE_APP_USER_FILE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: {
              files: state.byId[action.payload.appUserId]?.files?.files?.map((file: any) =>
                action.payload.fileUuid === file.uuid ? { ...file, is_deleted: true } : file,
              ),
              loading: false,
            },
          },
        },
      };
    case REMOVE_APP_USER_FILE_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            files: { files: state.byId[action.payload.appUserId].files?.files, loading: false },
          },
        },
      };
    case GET_APP_USER_FILE_DOWNLOAD_URL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            downloadUrl: { url: '', loading: true },
          },
        },
      };
    case GET_APP_USER_FILE_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            downloadUrl: { url: action.payload.url, loading: false },
          },
        },
      };
    case GET_APP_USER_FILE_DOWNLOAD_URL_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            downloadUrl: { url: '', loading: false },
          },
        },
      };
    case CLEAR_APP_USER_FILE_DOWNLOAD_URL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId],
            downloadUrl: { url: '', loading: false },
          },
        },
      };
    case FETCH_APP_USER_ENGAGEMENTS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            engagements: {
              engagements: [
                ...(state.byId[action.payload.appUserId]?.engagements?.engagements || []),
              ],
              loading: true,
            },
          },
        },
      };
    case FETCH_APP_USER_ENGAGEMENTS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            engagements: {
              engagements: action.payload.engagements,
              loading: false,
            },
          },
        },
      };
    case FETCH_APP_USER_ENGAGEMENTS_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            engagements: {
              engagements: [
                ...(state.byId[action.payload.appUserId]?.engagements?.engagements || []),
              ],
              loading: false,
            },
          },
        },
      };
    case FETCH_APP_USER_DAILY_STEPS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            healthData: {
              ...state.byId[action.payload.appUserId]?.healthData,
              dailySteps: {
                dailySteps: [
                  ...(state.byId[action.payload.appUserId]?.healthData?.dailySteps.dailySteps ||
                    []),
                ],
                loading: true,
              },
            },
          },
        },
      };
    case FETCH_APP_USER_DAILY_STEPS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            healthData: {
              ...state.byId[action.payload.appUserId]?.healthData,
              dailySteps: {
                dailySteps: action.payload.dailySteps,
                loading: false,
              },
            },
          },
        },
      };
    case FETCH_APP_USER_DAILY_STEPS_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            healthData: {
              ...state.byId[action.payload.appUserId]?.healthData,
              dailySteps: {
                dailySteps: [
                  ...(state.byId[action.payload.appUserId]?.healthData?.dailySteps.dailySteps ||
                    []),
                ],
                loading: false,
              },
            },
          },
        },
      };
    case ADD_APP_USER_VIEWED_CONTENT_ID:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.appUserId]: {
            ...state.byId[action.payload.appUserId]!,
            viewedContentIds: [
              ...(state.byId[action.payload.appUserId]?.viewedContentIds || []),
              action.payload.contentId,
            ],
          },
        },
      };
    default:
      return state;
  }
};

const forms = (
  state: IAppUsersFormsState = { computations: {}, data: {}, loading: false, submitting: false },
  action:
    | IFetchAppUsersForms
    | IAppUsersFormsLoaded
    | ILoadAppUserForms
    | ISubmitFormAsAppUser
    | ISubmitFormAsAppUserSuccess
    | ISubmitFormAsAppUserFailed
    | IAppUserFormComputationsLoaded,
) => {
  switch (action.type) {
    case LOAD_APP_USERS_FORMS:
      return {
        ...state,
        loading: true,
      };
    case APP_USERS_FORMS_LOADED:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload.appUserUISId]: action.payload.formSubmissions,
        },
      };
    case APP_USER_SUBMIT_FORM:
      return {
        ...state,
        submitting: true,
      };
    case APP_USER_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: {
          ...state.data,
          [action.payload.appUserUISId]: action.payload.formSubmissions,
        },
      };
    case APP_USER_SUBMIT_FORM_FAILED:
      return {
        ...state,
        submitting: false,
      };
    case APP_USER_FORM_COMPUTATIONS_LOADED:
      return {
        ...state,
        computations: {
          ...state.computations,
          [action.payload.appUserUISId]: action.payload.computations,
        },
      };
    default:
      return state;
  }
};

function appUserResultFormsLoading(
  state = false,
  action: IFetchFormsForAppUserResults | ITemporaryFormsLoaded,
) {
  switch (action.type) {
    case 'appUsers/fetch-app-user-forms-for-results':
      return true;
    case 'forms/temporary-loaded':
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  appUserResultFormsLoading,
  creating,
  loading,
  resendingInvite,
  togglingAccess,
  updating,
  data,
  forms,
});

export const selectAppUsers = (state: IState) => [
  state.appUsers.loading,
  state.appUsers.data.list.map((id: string) => state.appUsers.data.byId[id]),
];

export const selectAppUsersByIds = (appUserIds: string[]) => (state: IState) => [
  state.appUsers.loading,
  state.appUsers.data.list.reduce(
    (prev: IAppUser[], id) =>
      appUserIds.includes(state.appUsers.data.byId[id].ids.ubiquity)
        ? [...prev, state.appUsers.data.byId[id]]
        : prev,
    [],
  ),
];

export const selectAppUsersCreating = (state: IState) => state.appUsers.creating;
export const selectAppUsersLoading = (state: IState) => state.appUsers.loading;
export const selectResendingAppUserInvite = (state: IState) => state.appUsers.resendingInvite;
export const selectAppUsersUpdating = (state: IState) => state.appUsers.updating;
export const selectAppUsersTogglingAccess = (state: IState) => state.appUsers.togglingAccess;

export const selectAppUser = (appUserId: string) => (state: IState) => {
  return [state.appUsers.loading, state.appUsers.data.byId[appUserId]];
};

export const selectAppUsersById = (idType: string, appUserIds: string[]) => (
  state: IState,
): [boolean, IAppUser[]] => {
  return [
    state.appUsers.loading,
    appUserIds
      .map(id => Object.values(state.appUsers.data.byId).find(user => user.ids[idType] === id))
      .filter(au => au !== undefined) as IAppUser[],
  ];
};

export const selectAppUserFormSubmitting = (state: IState) => state.appUsers.forms.submitting;

export const selectAppUserFormSubmissions = (appUserUISId: string) => (
  state: IState,
): [boolean, IFormSubmission[]?] => [
  state.appUsers.forms.loading,
  state.appUsers.forms.data[appUserUISId],
];

export const selectAppUserFormComputations = (
  appUserUISId: string,
  formId: string,
  dataVersion: number,
) => (state: IState) => [
  (state.appUsers.forms.loading ||
    state.forms.versionFormData[`${formId}_${dataVersion}`]?.loading) ??
    false,
  state.appUsers.forms.computations[appUserUISId],
];

interface ISubmittedForm extends IForm {
  submission: IFormSubmission;
}

export const selectAppUserForms = (appUserUISId: string, forms: IForm[]) => (
  state: IState,
): [boolean, ISubmittedForm[]] => {
  let submissions = state.appUsers.forms.data[appUserUISId] || [];
  let formSubmissions = submissions.reduce(
    (acc: ISubmittedForm[], sub: IFormSubmission, index: number): ISubmittedForm[] => {
      const form = forms.find(form => form.uuid === sub.formId);
      if (form) {
        return [
          ...acc,
          {
            ...form,
            submission: sub,
          },
        ];
      }

      return acc;
    },
    [],
  ) as ISubmittedForm[];

  formSubmissions.sort((a, b) => b.submission.created.localeCompare(a.submission.created));

  return [state.appUsers.forms.loading, formSubmissions];
};

export const selectAppUserResultFormsLoading = (state: IState) =>
  state.appUsers.appUserResultFormsLoading;

export const selectAppUserFiles = (appUserUISId: string) => (state: IState) =>
  state.appUsers.data.byId[appUserUISId]?.files ?? {};

export const selectAppUserFileByUuid = (appUserUISId: string, fileUuid: string) => (
  state: IState,
) => state.appUsers.data.byId[appUserUISId]?.files?.files?.find(file => file.uuid === fileUuid);

export const selectAppUserFileDownloadUrl = (appUserUISId: string) => (state: IState) =>
  state.appUsers.data.byId[appUserUISId]?.downloadUrl ?? {};

export const selectAppUserEngagements = (appUserUISId: string) => (state: IState) =>
  state.appUsers.data.byId[appUserUISId]?.engagements ?? {};

export const selectAppUserDailySteps = (appUserUISId: string) => (state: IState) =>
  state.appUsers.data.byId[appUserUISId]?.healthData?.dailySteps ?? {};

export const selectAppUserViewedContentIds = (appUserUISId: string) => (state: IState) =>
  state.appUsers.data.byId[appUserUISId]?.viewedContentIds ?? [];
