import React from 'react';
import { Tooltip } from 'antd';
// import { createLanguageFilters } from '@utils/supportedLanguages';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
// import LanguageIcon from '@components/LanguageIcon';
import './style.less';

export default (t, defaultFilteredValue, contentTypeTranslationKey) => [
  // {
  //   title: t('cards:FormList.columns.language.title'),
  //   dataIndex: 'language',
  //   defaultFilteredValue: defaultFilteredValue.language || [],
  //   key: 'language',
  //   render: language => <LanguageIcon language={language} />,
  //   width: 150,
  //   sorter: (a, b) => a.language.localeCompare(b.language),
  //   filters: createLanguageFilters(t),
  //   onFilter: (value, record) => {
  //     return record.language === value;
  //   },
  // },
  {
    title: t(`cards:FormList.columns.${contentTypeTranslationKey}.title`),
    dataIndex: 'name',
    key: 'title',
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('cards:FormList.columns.published.title'),
    defaultFilteredValue: defaultFilteredValue.published || [],
    key: 'published',
    render: ({ published }) => (
      <Tooltip
        placement="bottom"
        title={
          published
            ? t('cards:FormList.columns.published.published')
            : t('cards:FormList.columns.published.unpublished')
        }
      >
        <PublishedStatusIcon published={published} />
      </Tooltip>
    ),
    width: 150,
    fixed: 'right',
    sorter: ({ published: a }, { published: b }) => a - b,
    filters: [
      {
        text: t('cards:FormList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:FormList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.published === value,
  },
];
