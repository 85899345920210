import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Table, Button } from 'antd';
import createColumns from '@pathways/cards/Pathways/EngagementChecks/createColumns';

import './style.less';

function SelectEngagementCheckModal({
  selectedEngagementCheck,
  onCancel,
  onOk,
  engagementChecks,
  pathwayName,
}) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selectedEngagementCheck) setSelected(selectedEngagementCheck);
  }, [selectedEngagementCheck]);

  const columns = useMemo(() => createColumns(t, false), [t]);

  return (
    <Modal
      className="select-engagement-check-modal single-selection-modal"
      title={t('pathways:ProcedurePathways.engagementCheckWizard.selectEngagementCheckModal.title')}
      visible
      width={800}
      onCancel={onCancel}
      footer={[
        <div className="footer-left" key="footer-button-select-all" />,
        <div className="footer-right" key="footer-button-add">
          <Button disabled={!selected} type="primary" onClick={() => onOk(selected)}>
            {t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
    >
      <Table
        columns={columns}
        dataSource={engagementChecks}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected ? [selected.id] : [],
          type: 'radio',
          onSelect: record => setSelected(record),
        }}
        locale={{
          emptyText: t(
            'pathways:ProcedurePathways.engagementCheckWizard.selectEngagementCheckModal.empty',
            { pathwayName },
          ),
        }}
      />
    </Modal>
  );
}

export default SelectEngagementCheckModal;
