import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table, Select, Divider, Form } from 'antd';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { createIndexEvent } from '@pathways/redux/indexEvents/actions';
import useWizard from '@components/Wizard/useWizard';

import './styles.less';

function MapIndexEvents({
  initialData,
  sharedPathway,
  indexEventsLocalisedNames,
  sharedIndexEventsLocalisedNames,
}) {
  const [mappedIndexEvents, setMappedIndexEvents] = useState([]);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setCurrentStepData, form } = useWizard();

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  useEffect(() => {
    if (mappedIndexEvents?.length >= sharedIndexEventsLocalisedNames?.length) {
      const formatted = mappedIndexEvents.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
      setCurrentStepData(formatted);
    }
  }, [mappedIndexEvents, sharedIndexEventsLocalisedNames]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialData?.length > 0 && mappedIndexEvents.length === 0)
      setMappedIndexEvents(initialData);
  }, [initialData, mappedIndexEvents]);

  useEffect(() => {
    if (
      (mappedIndexEvents.length > 0 &&
        mappedIndexEvents.length >= sharedIndexEventsLocalisedNames?.length) ||
      !sharedIndexEventsLocalisedNames?.length
    ) {
      form.setFieldsValue({ indexEvents: 'Valid' });
    } else {
      form.setFieldsValue({ indexEvents: undefined });
    }
  }, [mappedIndexEvents, sharedIndexEventsLocalisedNames]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateIndexEvent = useCallback(
    eventTypeSlug => {
      const parentIndexEventNames = sharedPathway?.parentIndexEventTypes[eventTypeSlug];
      dispatch(
        createIndexEvent(
          parentIndexEventNames.name,
          eventTypeSlug,
          parentIndexEventNames.translated_names,
          true,
        ),
      );
    },
    [sharedPathway, dispatch],
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        title: t('pathways:SharedPathways.duplicateWizard.columns.sharedIndexEventName'),
        render: (_, record) => {
          const parentIndexEventNames = sharedPathway?.parentIndexEventTypes[record.eventTypeSlug];
          const sharedIndexEventName =
            parentIndexEventNames?.translated_names[languageCode] || parentIndexEventNames?.name;
          return <p>{sharedIndexEventName}</p>;
        },
      },
      {
        title: t('pathways:SharedPathways.duplicateWizard.columns.correspondingIndexEvent'),
        dataIndex: 'eventTypeSlug',
        key: 'eventTypeSlug',
        render: (_, record) => {
          const defaultIndexEvent = indexEventsLocalisedNames?.find(
            ({ translatedName }) => translatedName === record.translatedName,
          );

          return (
            <Select
              showSearch
              className="corresponding-index-event-select"
              placeholder={t('pathways:SharedPathways.duplicateWizard.columns.selectIndexEvent')}
              defaultValue={defaultIndexEvent?.translatedName}
              onChange={value => {
                const correspondingIndexEvent = indexEventsLocalisedNames?.find(
                  ({ name: indexEventName }) => indexEventName === value,
                );
                if (correspondingIndexEvent?.slug && mappedIndexEvents?.length > 0) {
                  const indexEventToModify = mappedIndexEvents.find(
                    mIe => Object.keys(mIe)[0] === record.eventTypeSlug,
                  );
                  const indexOfIndexEventToModify = mappedIndexEvents.findIndex(
                    mIe => Object.keys(mIe)[0] === record.eventTypeSlug,
                  );
                  if (indexEventToModify) {
                    const updatedMappedIndexEvents = mappedIndexEvents.map((mIe, idx) => {
                      if (idx === indexOfIndexEventToModify) {
                        return { [record.eventTypeSlug]: correspondingIndexEvent.slug };
                      } else {
                        return mIe;
                      }
                    });
                    setMappedIndexEvents(updatedMappedIndexEvents);
                  } else {
                    setMappedIndexEvents(
                      mappedIndexEvents.concat({
                        [record.eventTypeSlug]: correspondingIndexEvent.slug,
                      }),
                    );
                  }
                }
              }}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider className="add-index-event-divider" />
                  <Button
                    icon="plus"
                    disabled={defaultIndexEvent}
                    className="add-index-event-button"
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => handleCreateIndexEvent(record.eventTypeSlug)}
                  >
                    {t('pathways:SharedPathways.duplicateWizard.columns.addIndexEvent')}
                  </Button>
                </div>
              )}
            >
              {indexEventsLocalisedNames?.map(({ translatedName }) => (
                <Select.Option key={translatedName}>{translatedName}</Select.Option>
              ))}
            </Select>
          );
        },
      },
    ],
    [
      t,
      sharedPathway,
      languageCode,
      mappedIndexEvents,
      indexEventsLocalisedNames,
      handleCreateIndexEvent,
    ],
  );

  return (
    <ColumnGroup>
      <Column>
        <Form>
          <Form.Item name="indexEvents">
            {form.getFieldDecorator('indexEvents', {
              rules: [
                {
                  required: true,
                  message: t('pathways:SharedPathways.duplicateWizard.indexEventsRequired'),
                },
              ],
            })(
              <Card.Half
                className="pathway-index-events-ordering"
                title={t('pathways:ProcedurePathways.wizard.indexEvents.header')}
              >
                <Table
                  columns={columns}
                  dataSource={sharedIndexEventsLocalisedNames}
                  pagination={false}
                  rowKey="id"
                  locale={{
                    emptyText: t('pathways:SharedPathways.duplicateWizard.noSharedIndexEvents'),
                  }}
                />
              </Card.Half>,
            )}
          </Form.Item>
        </Form>
      </Column>
      <Column>
        {sharedIndexEventsLocalisedNames?.length > 0 ? (
          <Card
            title={t('pathways:SharedPathways.duplicateWizard.explanationTitle')}
            className="map-index-events-explanation"
          >
            <p>{t('pathways:SharedPathways.duplicateWizard.explanation1')}</p>
            <p>{t('pathways:SharedPathways.duplicateWizard.explanation2')}</p>
            <p>{t('pathways:SharedPathways.duplicateWizard.explanation3')}</p>
          </Card>
        ) : (
          <Card
            title={t('pathways:SharedPathways.duplicateWizard.explanationTitle')}
            className="map-index-events-explanation"
          >
            <p>{t('pathways:SharedPathways.duplicateWizard.noSharedIndexEventsExplanation')}</p>
          </Card>
        )}
      </Column>
    </ColumnGroup>
  );
}

export default MapIndexEvents;
