import { IMetrics, IPathwayMetricsState } from './types';

export type IFetchGlobalMetrics = ReturnType<typeof fetchGlobalMetrics>;
export const fetchGlobalMetrics = () => ({
  type: 'metrics/fetch-global' as const,
  payload: {},
});

export type IFetchCountryMetrics = ReturnType<typeof fetchCountryMetrics>;
export const fetchCountryMetrics = (countryId: number) => ({
  type: 'metrics/fetch-country' as const,
  payload: { countryId },
});

export type IFetchPathwayMetrics = ReturnType<typeof fetchPathwayMetrics>;
export const fetchPathwayMetrics = () => ({
  type: 'metrics/fetch-pathway' as const,
  payload: {},
});

export type IFetchHospitalMetrics = ReturnType<typeof fetchHospitalMetrics>;
export const fetchHospitalMetrics = (hospitalId: string) => ({
  type: 'metrics/fetch-hospital' as const,
  payload: { hospitalId },
});

export type ILoadMetrics = ReturnType<typeof loadMetrics>;
export const loadMetrics = () => ({
  type: 'metrics/load' as const,
  payload: {},
});

export type IGlobalMetricsLoaded = ReturnType<typeof globalMetricsLoaded>;
export const globalMetricsLoaded = (metrics: IMetrics) => ({
  type: 'metrics/global-loaded' as const,
  payload: { metrics },
});

export type ICountryMetricsLoaded = ReturnType<typeof countryMetricsLoaded>;
export const countryMetricsLoaded = (countryId: number, metrics: IMetrics) => ({
  type: 'metrics/country-loaded' as const,
  payload: { countryId, metrics },
});

export type IPathwayMetricsLoaded = ReturnType<typeof pathwayMetricsLoaded>;
export const pathwayMetricsLoaded = (metrics: IPathwayMetricsState) => ({
  type: 'metrics/pathway-loaded' as const,
  payload: { metrics },
});

export type IHospitalMetricsLoaded = ReturnType<typeof hospitalMetricsLoaded>;
export const hospitalMetricsLoaded = (hospitalId: string, metrics: IMetrics) => ({
  type: 'metrics/hospital-loaded' as const,
  payload: { hospitalId, metrics },
});

export type ILoadMetricsFailed = ReturnType<typeof loadMetricsFailed>;
export const loadMetricsFailed = () => ({
  type: 'metrics/load-failed' as const,
  payload: {},
});
