import React from 'react';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import DetailRow from '@components/DetailRow';
import FlagIcon from '@components/FlagIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { Card } from '@cards/Card';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function FlagDetailsCard({ flag, loading, title, ...props }) {
  const { t } = useTranslation();

  return (
    <Card.Half loading={loading} title={t(title)} className="flag-details-card" {...props}>
      {flag === null ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <DetailRow label={<>{t('cards:FlagDetails.name')}</>} value={flag.name} />
          <DetailRow label={<>{t('cards:FlagDetails.description')}</>} value={flag.description} />
          <DetailRow
            label={<>{t('cards:FlagDetails.colour')}</>}
            value={<FlagIcon colour={flag.colour} />}
          />
          <DetailRow
            label={<>{t('cards:FlagDetails.isFavourite')}</>}
            value={
              flag.isFavourite ? (
                <span>
                  <PublishedStatusIcon published={flag.isFavourite} />
                </span>
              ) : (
                t('cards:FlagDetails.no')
              )
            }
          />
          <DetailRow
            label={<>{t('cards:FlagDetails.isDisabled')}</>}
            value={
              flag.isDisabled ? (
                <span>
                  <PublishedStatusIcon published={flag.isDisabled} />
                </span>
              ) : (
                t('cards:FlagDetails.no')
              )
            }
          />
        </Form>
      )}
    </Card.Half>
  );
}

FlagDetailsCard.propTypes = {
  flag: shape({
    id: number,
    name: string,
  }),
  title: string,
};

FlagDetailsCard.defaultProps = {
  flag: null,
  title: 'cards:FlagDetails.title',
};

export default FlagDetailsCard;
