import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/sharp-solid-svg-icons';
import { selectFlags } from '@redux/flags/reducers';
import FlagIcon from '@components/FlagIcon';
import FlagPopover from '@components/FlagPopover';
import { getSortedAppUserFlags } from '@redux/appUsers/utils';

export default ({ appuser: { flags } }) => {
  const [, orgFlags] = useSelector(selectFlags);

  return (
    <>
      {getSortedAppUserFlags(flags, orgFlags).map(flag => {
        return (
          <span className="flag-column-flag-wrapper">
            <FlagPopover flag={flag}>
              <span>
                <FlagIcon colour={flag?.colour} />
              </span>
            </FlagPopover>
          </span>
        );
      })}
      <span className="flag-column-flag-wrapper edit-icon">
        <FontAwesomeIcon icon={faPenToSquare} size="xl" />
      </span>
    </>
  );
};
