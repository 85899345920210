import React, { useCallback, useState, useMemo } from 'react';
import FormUpload from '@cards/Forms/FormUpload';
import useWizard from '@components/Wizard/useWizard';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { currentStepData, setCurrentStepData, form } = useWizard();

  const defaultFileList = useMemo(() => currentStepData?.fileList || [], []); // eslint-disable-line react-hooks/exhaustive-deps

  const [fileList, setFileList] = useState(defaultFileList);
  const [error, setError] = useState('');

  const onSubmit = useCallback(
    (newFileList, json) => {
      form.resetFields(['content-upload-hidden-validator']);
      setError('');
      setFileList(newFileList);
      setCurrentStepData({
        fileList: newFileList,
        json,
      });
    },
    [setCurrentStepData, form],
  );

  const validateFile = useCallback(
    (rule, value, callback) => {
      if (currentStepData?.json) {
        setError('');
        return callback();
      }

      setError(rule.message);
      return callback(rule.message);
    },
    [currentStepData],
  );

  return (
    <>
      <FormUpload onSubmit={onSubmit} fileList={fileList} setFileList={setFileList} error={error} />
      {form.getFieldDecorator('content-upload-hidden-validator', {
        rules: [
          {
            message: t('forms:FormWizard.steps.content.uploadRequired'),
            validator: validateFile,
          },
        ],
      })(<span />)}
    </>
  );
};
