import React from 'react';
import { Button } from 'antd';
import RuleTag from '@components/RuleTag';
import { contentTypesList, contentTypes } from '@utils';

// assumes stage can't be adhoc AND be the current stage
function generateColumnClassName(isCurrentStage, isAdhocStage, isAppUserPathway) {
  if (isCurrentStage) return 'current-stage';
  if (isAdhocStage && isAppUserPathway) return 'manual-rules-column';

  return '';
}

export default (
  t,
  stages,
  currentStageSlug,
  triggeredRules,
  triggerAdhocRule,
  pathwayId,
  filters,
  setFilters,
) => {
  const adhocStage = (stages || []).find(({ isAdhoc }) => isAdhoc);

  return [
    ...(stages || []).map(stage => ({
      className: generateColumnClassName(
        stage.slug === currentStageSlug,
        stage.isAdhoc,
        !!triggeredRules,
      ),
      dataIndex: 'rules',
      key: stage.slug,
      title: `${
        stage.isAdhoc
          ? ''
          : `${t('cards:PathwayStagesTable.columns.stage', {
              stageNumber: stage.number,
            })}: `
      }${stage.name}`,
      ...(stage.isAdhoc ? { fixed: 'right', width: '200px' } : {}),
      render: (rules = []) => {
        return rules.map(rule => {
          if (rule.stageSlug !== stage.slug) {
            return null;
          }

          const audienceTypes = ['app-user', 'clinician'];
          const audienceTypeFilters = filters[stage.slug]?.filter(filter =>
            audienceTypes.includes(filter),
          );
          const contentTypeFilters = filters[stage.slug]?.filter(
            filter => !audienceTypes.includes(filter),
          );

          const contentType =
            rule.whatDetail.type ||
            rule.whatDetail.metadata?.type ||
            rule.whatDetail.content_type?.split('_')?.[1];
          if (contentTypeFilters?.length > 0 && !contentTypeFilters.includes(contentType)) {
            return null;
          }
          if (audienceTypeFilters?.length > 0 && !audienceTypeFilters.includes(rule.audienceType)) {
            return null;
          }

          return (
            <RuleTag
              rule={rule}
              triggered={triggeredRules?.some(
                ({ id, stageSlug }) => id === rule.id && stageSlug === rule.stageSlug,
              )}
              key={rule.id}
            />
          );
        });
      },
      filters: [
        ...contentTypesList.map(what => ({
          text: t(contentTypes[what].label),
          value: what,
        })),
        {
          text: t('cards:PathwayStagesTable.columns.patient'),
          value: 'app-user',
        },
        {
          text: t('cards:PathwayStagesTable.columns.clinician'),
          value: 'clinician',
        },
      ],
    })),
    ...(triggeredRules && adhocStage
      ? [
          {
            className: 'manual-rules-column',
            dataIndex: 'rules',
            fixed: 'right',
            key: 'action',
            title: t('cards:PathwayStagesTable.columns.action'),
            width: '100px',
            render: (rules = []) =>
              rules
                .filter(rule => rule.stageSlug === adhocStage.slug)
                .map(rule => (
                  <Button type="link" onClick={() => triggerAdhocRule(rule.id)} key={rule.id}>
                    {t('cards:PathwayStagesTable.buttons.trigger')}
                  </Button>
                )),
          },
        ]
      : []),
  ];
};
