import React from 'react';
import { Form, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import DateTimePicker from '@components/DateTimePicker';
import SelectTimeInterval from '@components/SelectTimeInterval';
import SelectRepeatStops from './SelectRepeatStops';

export const NOW = 'now';
const SCHEDULE = 'schedule';

function Compose() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  const nowMoment = moment();

  return (
    <Card title={t('messages:Wizard.timing.compose')}>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <Form.Item label={t('messages:Wizard.timing.when.label')}>
          {form.getFieldDecorator('when', {
            initialValue: NOW,
          })(
            <Radio.Group>
              <Radio value={NOW}>{t('messages:Wizard.timing.when.now')}</Radio>
              <Radio value={SCHEDULE}>{t('messages:Wizard.timing.when.schedule')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        {form.getFieldValue('when') === SCHEDULE ? (
          <>
            {' '}
            <Form.Item label={t('messages:Wizard.timing.dateSent.label')}>
              {form.getFieldDecorator('dateSent', {
                initialValue: currentStepData.dateSent || nowMoment,
                rules: [{ required: true, message: t('messages:Wizard.timing.dateSent.required') }],
              })(<DateTimePicker />)}
            </Form.Item>
            <Form.Item label={t('messages:Wizard.timing.frequency.label')}>
              {form.getFieldDecorator('frequency', {
                initialValue: currentStepData.frequency,
                rules: [
                  { required: true, message: t('messages:Wizard.timing.frequency.required') },
                ],
              })(
                <Select>
                  <Select.Option value="scheduled">
                    {t('messages:Wizard.timing.frequency.oneTime')}
                  </Select.Option>
                  <Select.Option value="repeat">
                    {t('messages:Wizard.timing.frequency.repeat')}
                  </Select.Option>
                </Select>,
              )}
            </Form.Item>
          </>
        ) : null}
        {form.getFieldValue('frequency') === 'repeat' ? (
          <>
            <Form.Item label={t('messages:Wizard.timing.repeatsEvery.label')}>
              {form.getFieldDecorator('repeatEvery', {
                initialValue: currentStepData.repeatEvery,
                rules: [
                  { required: true, message: t('messages:Wizard.timing.repeatsEvery.required') },
                ],
              })(<SelectTimeInterval />)}
            </Form.Item>
            <Form.Item label={t('messages:Wizard.timing.repeatStops.label')}>
              {form.getFieldDecorator('repeatStops', {
                initialValue: currentStepData.repeatStops || {
                  stop: '',
                  stopDate: nowMoment.toISOString(),
                },
                rules: [
                  { required: true, message: t('messages:Wizard.timing.repeatStops.required') },
                ],
              })(<SelectRepeatStops />)}
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Card>
  );
}

export default Compose;
