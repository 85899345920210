import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.less';

function PubslishedStatusIcon({ published, showText }) {
  const { t } = useTranslation();

  if (published) {
    return (
      <>
        <FontAwesomeIcon size="lg" className="published-check" icon={faCheckCircle} />
        {showText ? t('common:published') : null}
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon size="lg" className="published-cross" icon={faTimesCircle} />
      {showText ? t('common:unpublished') : null}
    </>
  );
}

PubslishedStatusIcon.propTypes = {
  published: bool,
  showText: bool,
};

PubslishedStatusIcon.defaultProps = {
  published: false,
  showText: false,
};

export default PubslishedStatusIcon;
