import {
  ADD_PATHWAY_TO_APP_USERS,
  ADD_PATHWAY_TO_APP_USERS_SUCCESS,
  APP_USER_JOURNEYS_LOADED,
  APP_USER_PATHWAY_STAGE_UPDATED,
  APP_USERS_PATHWAYS_LOADED,
  FETCH_APP_USERS_JOURNEYS,
  FETCH_APP_USERS_PATHWAYS,
  PROCESS_APP_USER_PATHWAY_NOW,
  SET_APP_USER_PATHWAY_STAGE,
  INVITE_APP_USER_WITH_PATHWAYS_FAILED,
  INVITE_APP_USER_WITH_PATHWAYS_SUCCESS,
  SET_APP_USER_PATHWAY_ARCHIVED_STATUS,
  SET_APP_USER_PATHWAY_ARCHIVED_STATUS_FAILED,
  SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS,
  EDIT_APP_USER_JOURNEY,
  EDIT_APP_USER_JOURNEY_FAILED,
  EDIT_APP_USER_JOURNEY_SUCCESS,
  TRIGGER_ADHOC_RULE,
  TRIGGER_ADHOC_RULE_FAILED,
  TRIGGER_ADHOC_RULE_SUCCESS,
  ACTION_JOURNEY_ENTRY,
  ACTION_JOURNEY_ENTRY_FAILED,
  ACTION_JOURNEY_ENTRY_SUCCESS,
} from './types';

export const addPathwayToAppUsers = (appUsers, pathwayId) => ({
  type: ADD_PATHWAY_TO_APP_USERS,
  payload: { appUsers, pathwayId },
});

export const addPathwayToAppUsersSuccess = appUsersPathways => ({
  type: ADD_PATHWAY_TO_APP_USERS_SUCCESS,
  payload: { appUsersPathways },
});

export const appUserJourneysLoaded = (appUserId, journeys) => ({
  type: APP_USER_JOURNEYS_LOADED,
  payload: {
    appUserId,
    journeys,
  },
});

export const appUsersPathwaysLoaded = appUsers => ({
  type: APP_USERS_PATHWAYS_LOADED,
  payload: {
    appUsers,
  },
});

export const appUserPathwayStageUpdated = (appUserPathwaysId, appUserPathwayId, stageSlug) => ({
  type: APP_USER_PATHWAY_STAGE_UPDATED,
  payload: {
    appUserPathwaysId,
    appUserPathwayId,
    stageSlug,
  },
});

export const fetchAppUserJourneys = appUserId => ({
  type: FETCH_APP_USERS_JOURNEYS,
  payload: { appUserId },
});

export const fetchAppUsersPathways = () => ({
  type: FETCH_APP_USERS_PATHWAYS,
  payload: {},
});

export const processAppUserPathwayNow = (appUserId, pathwayId) => ({
  type: PROCESS_APP_USER_PATHWAY_NOW,
  payload: { appUserId, pathwayId },
});

export const setAppUserPathwayStage = (appUserId, stageSlug, appUserPathwayId) => ({
  type: SET_APP_USER_PATHWAY_STAGE,
  payload: {
    appUserId,
    stageSlug,
    appUserPathwayId,
  },
});

export const inviteAppUserWithPathwaysFailed = () => ({
  type: INVITE_APP_USER_WITH_PATHWAYS_FAILED,
  payload: {},
});

export const inviteAppUserWithPathwaysSuccess = (appUserId, appUserPathways) => ({
  type: INVITE_APP_USER_WITH_PATHWAYS_SUCCESS,
  payload: { appUserId, appUserPathways },
});

export const setAppUserPathwayArchivedStatus = (appUserId, appUserPathwayId, isActive) => ({
  type: SET_APP_USER_PATHWAY_ARCHIVED_STATUS,
  payload: { appUserId, appUserPathwayId, isActive },
});

export const setAppUserPathwayArchivedStatusFailed = () => ({
  type: SET_APP_USER_PATHWAY_ARCHIVED_STATUS_FAILED,
  payload: {},
});

export const setAppUserPathwayArchivedStatusSuccess = (appUserId, appUserPathwayId, isActive) => ({
  type: SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS,
  payload: { appUserId, appUserPathwayId, isActive },
});

export const editAppUserJourney = (appUserId, journeyId, updatedJourney) => ({
  type: EDIT_APP_USER_JOURNEY,
  payload: { appUserId, journeyId, updatedJourney },
});

export const editAppUserJourneyFailed = () => ({
  type: EDIT_APP_USER_JOURNEY_FAILED,
  payload: {},
});

export const editAppUserJourneySuccess = () => ({
  type: EDIT_APP_USER_JOURNEY_SUCCESS,
  payload: {},
});

export const triggerAdhocRule = (appUserId, appUserPathwayId, ruleId) => ({
  type: TRIGGER_ADHOC_RULE,
  payload: { appUserId, appUserPathwayId, ruleId },
});

export const triggerAdhocRuleFailed = () => ({
  type: TRIGGER_ADHOC_RULE_FAILED,
  payload: {},
});

export const triggerAdhocRuleSuccess = () => ({
  type: TRIGGER_ADHOC_RULE_SUCCESS,
  payload: {},
});

export const actionJourneyEntry = (appUserId, journeyId, entryId) => ({
  type: ACTION_JOURNEY_ENTRY,
  payload: { appUserId, journeyId, entryId },
});

export const actionJourneyEntryFailed = () => ({
  type: ACTION_JOURNEY_ENTRY_FAILED,
  payload: {},
});

export const actionJourneyEntrySuccess = (appUserId, journeyId, entryId) => ({
  type: ACTION_JOURNEY_ENTRY_SUCCESS,
  payload: { appUserId, journeyId, entryId },
});
