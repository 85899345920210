import { takeLatest, takeEvery, getContext, select, cps, put, call } from 'redux-saga/effects';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { userPoolId, userPoolAppClient } from 'settings';
import { message } from 'antd';
import i18n from '../../i18n';
import {
  changePasswordComplete,
  IChangePassword,
  changePasswordError,
  IEditMyProfile,
  editMyProfileSuccess,
  editMyProfileError,
} from './actions';
import { CHANGE_PASSWORD, EDIT_MY_PROFILE, PROFILE_REFRESH_TERMS_OF_USE } from './types';
import { refreshTermsOfUse, fetchUserAcceptances } from '../termsOfUse/actions';
import { setPreferredMfaSettings } from '@redux/login/saga';

export default function* root() {
  yield takeLatest(CHANGE_PASSWORD, doChangePassword);
  yield takeEvery(PROFILE_REFRESH_TERMS_OF_USE, doRefreshTermsOfUse);
  yield takeEvery(EDIT_MY_PROFILE, doEditMyProfile);
}

export const getUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: userPoolAppClient,
  });
};

function* doChangePassword({ payload: { oldPassword, newPassword } }: IChangePassword) {
  const userPool = getUserPool();
  const user = userPool.getCurrentUser();
  try {
    if (!user) {
      throw new Error('User not defined');
    }
    yield cps(user.getSession.bind(user));
    // couldn't work out how to use cps with arguments in typescript because multiple type definitions are exported from the same file
    // and it imports the first one which only takes one argument
    const changePassword = () =>
      new Promise((resolve, reject) =>
        user.changePassword(oldPassword, newPassword, (err, data) => {
          err ? reject(err) : resolve(data);
        }),
      );
    yield call(changePassword);
    yield put(changePasswordComplete());
    message.success(i18n.t('passwordRecovery:Password.success'));
  } catch (err) {
    message.error(i18n.t('passwordRecovery:Password.failed'));
    yield put(changePasswordError());
  }
}

function* doRefreshTermsOfUse() {
  const tokens = yield getContext('tokens');
  const token = yield call(tokens.get, 'pip');
  const { id, profile } = yield select(state => state.login.user);
  const languages = [...navigator.languages];
  if (profile.language) {
    languages.unshift(profile.language);
  }
  yield put(refreshTermsOfUse(languages, token, profile.hospitalId));
  yield put(fetchUserAcceptances(id, profile.hospitalId));
}

function* doEditMyProfile({ payload }: IEditMyProfile) {
  try {
    yield call(setPreferredMfaSettings, payload.mfaPreference);
    message.success(i18n.t('myProfile:EditMyProfile.success'));
    yield put(editMyProfileSuccess(payload));
  } catch (e) {
    console.log(e);
    message.error(i18n.t('myProfile:EditMyProfile.error'));
    yield put(editMyProfileError());
  }
}
