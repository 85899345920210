import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import LanguageFormItem from '@components/LanguageFormItem';
import useWizard from '@components/Wizard/useWizard';

export default function() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half title={t('patients:NewPatient.basicDetails.app.title')}>
      <Form layout="vertical" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label={t('patients:NewPatient.basicDetails.app.firstName.label')}>
          {form.getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: t('patients:NewPatient.basicDetails.app.firstName.required'),
              },
            ],
            initialValue: currentStepData.firstName,
          })(<Input placeholder={t('patients:NewPatient.basicDetails.app.firstName.label')} />)}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.app.lastName.label')}>
          {form.getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                message: t('patients:NewPatient.basicDetails.app.firstName.required'),
              },
            ],
            initialValue: currentStepData.lastName,
          })(<Input placeholder={t('patients:NewPatient.basicDetails.app.lastName.label')} />)}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.app.email.label')}>
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                type: 'email',
                message: t('patients:NewPatient.basicDetails.app.email.invalid'),
              },
            ],
            initialValue: currentStepData.email,
          })(<Input placeholder={t('patients:NewPatient.basicDetails.app.email.placeholder')} />)}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.app.phone.label')} hasFeedback>
          {form.getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                pattern: /\+[\d\s]+/,
                message: t('patients:NewPatient.basicDetails.app.phone.invalid'),
              },
            ],
            initialValue: currentStepData.phone,
          })(
            <Input
              placeholder={t('patients:NewPatient.basicDetails.app.phone.placeholder')}
              onChange={e => {
                const { value } = e.target;
                if (value.startsWith('04')) {
                  e.target.value = `+61${value.slice(1)}`;
                }
              }}
            />,
          )}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.app.doctor.label')}>
          {form.getFieldDecorator('doctor', {
            rules: [
              {
                required: true,
                message: t('patients:NewPatient.basicDetails.app.doctor.required'),
              },
            ],
            initialValue: currentStepData.doctor,
          })(<Input placeholder={t('patients:NewPatient.basicDetails.app.doctor.label')} />)}
        </Form.Item>
        <LanguageFormItem currentLanguage={currentStepData.language} form={form} />
      </Form>
    </Card.Half>
  );
}
