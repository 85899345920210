import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useFilters = () => {
  const history = useHistory();
  const location = useLocation();
  const [currentFilters, setCurrentFilters] = useState({});

  useEffect(() => {
    /*
    queryString.parse(location.search) = { filters__pathwayName: '["Covid-19 declaration pathway"]', page: '1' }
    currentFiltersFromUrl = excludes non filter keys and JSON.parses the values and strips filters__ prefix
    */
    const currentFiltersFromUrl = Object.fromEntries(
      Object.entries(queryString.parse(location.search))
        .filter(([key]) => key.startsWith('filters__'))
        .map(([key, value]) => [key.replace('filters__', ''), JSON.parse(value)]),
    );
    setCurrentFilters(currentFiltersFromUrl);
  }, [location.search]);

  const setFilters = filters => {
    const queryParametersWithoutFilters = Object.fromEntries(
      Object.entries(queryString.parse(location.search)).filter(([key]) => {
        return !key.startsWith('filters__');
      }),
    );

    /*
    filters = {filterName: ['filterValue', ...]}
    updatedFilters = ['filters__filterName', filter value as JSON]
    */
    const updatedFilters = Object.fromEntries(
      Object.entries(filters)
        .filter(([, value]) => value.length > 0)
        .map(([key, value]) => {
          return [`filters__${key}`, JSON.stringify(value)];
        }),
    );

    const filtersAndOtherParams = { ...updatedFilters, ...queryParametersWithoutFilters };
    const searchAndFilterString = queryString.stringify(filtersAndOtherParams);
    history.push(`${location.pathname}?${searchAndFilterString}`);
  };

  const setIndividualFilter = (key, values) => {
    const newFilters = {
      ...currentFilters,
      [key]: values,
    };
    setFilters(newFilters);
  };

  return [currentFilters, setFilters, setIndividualFilter];
};

export default useFilters;
