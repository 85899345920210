import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Details from './Details';
import Invitation from './InvitationCode';

const RegistrationIndex = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/details`} component={Details} />
      <Route path={match.path} component={Invitation} />
    </Switch>
  );
};

export default RegistrationIndex;
