import React from 'react';
import { Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './style.less';

const IconButton = ({ tooltip, tooltipPlacement, icon, ...props }) => (
  <span className="icon-button">
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <Button {...props}>
        <FontAwesomeIcon icon={icon} />
      </Button>
    </Tooltip>
  </span>
);

IconButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  icon: PropTypes.object.isRequired,
};

IconButton.defaultProps = {
  tooltipPlacement: 'bottom',
};

export default IconButton;
