import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editDashboardUser, fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import {
  selectDashboardUserById,
  selectDashboardUserEditing,
} from '@redux/dashboardUsers/reducers';
import { selectDashboardUserHospital } from '@redux/login/reducer';
import { usePageActions } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import { matchType } from '../../../propTypes';
import DashboardUserWizard from './Wizard';

function EditDashboardUser({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editing = useSelector(selectDashboardUserEditing);
  const selector = useCallback(selectDashboardUserById(match.params.id), [match]);
  const [loading, dashboardUser] = useSelector(selector);
  const dashboardUserHospital = useSelector(selectDashboardUserHospital);
  const { setTitle } = usePageActions({ title: dashboardUser?.name, showClose: true });

  useEffect(() => {
    if (dashboardUser) {
      setTitle(dashboardUser.name);
    }
  }, [dashboardUser, setTitle]);

  useEffect(() => {
    dispatch(fetchDashboardUsers());
  }, [dispatch]);

  const onSubmit = useCallback(
    dashboardUser => {
      const { hospitalId, hospitalName, hospitalSlug } = dashboardUserHospital;
      dispatch(
        editDashboardUser(match.params.id, {
          hospitalId,
          hospitalName,
          hospitalSlug,
          ...dashboardUser,
        }),
      );
    },
    [dashboardUserHospital, dispatch, match],
  );

  return (
    <ColumnGroup>
      <Column>
        <DashboardUserWizard
          dashboardUser={dashboardUser}
          isEdit
          loading={loading}
          submitting={editing}
          submitButtonText={t('dashboardUsers:Wizard.button.edit')}
          onSubmit={onSubmit}
        />
      </Column>
      <Column />
    </ColumnGroup>
  );
}

EditDashboardUser.propTypes = {
  match: matchType.isRequired,
};

export default EditDashboardUser;
