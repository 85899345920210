import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Card, Button, Input, Icon, Form, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import OnboardingContainer from '@components/OnboardingContainer';
import { passwordResetCodeSubmitted } from '@redux/passwordRecovery/actions';
import { isLoading, hasError as error } from '@redux/passwordRecovery/reducer';

import './style.less';

const CodeRecovery = ({
  form: { getFieldDecorator, validateFieldsAndScroll, getFieldError, getFieldValue },
}) => {
  const hasStarted = useSelector(state => state.passwordRecovery.started);
  const hasError = useSelector(error);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          dispatch(passwordResetCodeSubmitted(values));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  if (!hasStarted) {
    return <Redirect to="/auth/recovery" />;
  }

  const disabledSubmit = typeof getFieldError('code') !== 'undefined' || !getFieldValue('code');
  return (
    <OnboardingContainer>
      <Card
        title={<h1>{t('passwordRecovery:title')}</h1>}
        actions={[
          <Button
            type="primary"
            disabled={disabledSubmit}
            onClick={handleSubmit}
            htmlType="submit"
            loading={loading}
          >
            {t('common:buttons.verify')}
          </Button>,
        ]}
        className="login-card"
      >
        <p>{t('passwordRecovery:Code.description')}</p>
        {hasError && (
          <Alert
            message={t('passwordRecovery:Code.alert.message')}
            description={t('passwordRecovery:Code.alert.description')}
            type="error"
            showIcon
          />
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Item hasFeedback>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Code.emptyVerificationCode'),
                },
                {
                  len: 6,
                  pattern: /^[0-9]*$/,
                  message: t('common:validation.Code.invalidVerificationCode'),
                },
              ],
            })(
              <Input
                maxLength={6}
                inputMode="numeric"
                prefix={<Icon type="lock" />}
                placeholder={t('common:placeholder.code')}
              />,
            )}
          </Form.Item>
        </Form>
      </Card>
    </OnboardingContainer>
  );
};

CodeRecovery.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedCodeRecovery = Form.create()(CodeRecovery);

export { WrappedCodeRecovery };
export default WrappedCodeRecovery;
