import { useSelector } from 'react-redux';
import { selectOrganisationSlug } from '@organisation/redux/selectors';
import ContentRef from '@utils/contentRef';

const useIsContentOwnedByCurrentOrganisation = (content: { content?: string } | undefined) => {
  const currentOrganisationSlug = useSelector(selectOrganisationSlug());

  let isOwnedByThisOrganisation = true;
  if (content?.content) {
    const parsedRef =
      typeof content.content === 'string'
        ? ContentRef.fromString(content.content)
        : content.content;
    isOwnedByThisOrganisation = parsedRef.organisationId === currentOrganisationSlug;
  }

  return isOwnedByThisOrganisation;
};

export default useIsContentOwnedByCurrentOrganisation;
