import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from './Menu';
import Header from './Header';

import { PageActionsProvider } from '../../hooks';

import './style.less';

export default ({ children, title, showClose }) => {
  const { i18n } = useTranslation();
  return (
    <div className={`container ${i18n.language.split('-')[0]}`}>
      <Menu />
      <div className="content-page">
        <PageActionsProvider>
          <Header title={title} showClose={showClose} />
          <div className="content">{children}</div>
        </PageActionsProvider>
      </div>
    </div>
  );
};
