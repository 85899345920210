// import React from 'react';
// import { Progress } from 'antd';

import './style.less';

// export default (t, formTranslationKey) => [
export default t => [
  // {
  //   title: t('cards:HospitalList.columns.country.title'),
  //   dataIndex: 'country',
  //   key: 'country',
  //   render: country => country,
  //   width: 200,
  //   sorter: (a, b) => a.country.localeCompare(b.country),
  // },
  {
    title: t('cards:HospitalList.columns.hospital.title'),
    dataIndex: 'name',
    key: 'name',
    render: name => name,
    width: 300,
    sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
  },
  // {
  //   title: t('cards:HospitalList.columns.patients.title'),
  //   key: 'invites',
  //   sorter: (a, b) => a.name.localeCompare(b.name),
  //   render: hospital => {
  //     /**
  //      * TODO - currently MOCKED
  //      * invites mocked as number 0 - 100 in redux
  //      * needs to be reworked to show percentage of invites accepted out of users invited to hospital
  //      */
  //     return <Progress percent={hospital.invites} format={percent => hospital.invites} />;
  //   },
  // },
  // {
  //   title: t('cards:HospitalList.columns.staff.title'),
  //   key: 'staff',
  //   render: hospital => '',
  // },
  // {
  //   title: t('cards:HospitalList.columns.procedurePathways.title'),
  //   key: 'procedurePathways',
  //   render: hospital => hospital.pathways.length,
  //   sorter: (a, b) => a > b,
  // },
];
