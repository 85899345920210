import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderButton from '@components/HeaderButton';
import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import IndexEventsListCard from '../../cards/IndexEvents/List';
import { historyType, matchType } from '../../../propTypes';

function List({ history, match }) {
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('indexEvents:List.title'),
    actions: [
      <HeaderButton
        key="messages-list"
        tooltip={t('indexEvents:List.new')}
        onClick={() => history.push(`${match.path}/new`)}
      >
        {t('indexEvents:List.new')}
      </HeaderButton>,
    ],
  });
  document.title = t('indexEvents:List.title');
  useTitleUpdate(setTitle, 'indexEvents:List.title');

  return (
    <Column>
      <IndexEventsListCard />
    </Column>
  );
}

List.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default List;
