import React from 'react';
import { element, string } from 'prop-types';
import './style.less';

function SubNavMenuContainer({ className, children, menu }) {
  return (
    <div className={`${className} page-with-submenu-container`}>
      {menu}
      <div className="display-container">{children}</div>
    </div>
  );
}

SubNavMenuContainer.propTypes = {
  className: string,
  menu: element.isRequired,
};

SubNavMenuContainer.defaultProps = {
  className: '',
};

export default SubNavMenuContainer;
