import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, message, Icon } from 'antd';
import PropTypes from 'prop-types';
import { Card } from '@cards/Card';

import './style.less';

const { Dragger } = Upload;

const FormUploadCard = ({ fileList, onSubmit, error }) => {
  const { t } = useTranslation();

  const draggerProps = useMemo(() => {
    return {
      fileList,
      name: 'file',
      multiple: false,
      accept: '.json',
      onRemove: () => {
        onSubmit([], undefined);
      },
      beforeUpload: file => false,
      onChange: ({ fileList }) => {
        const file = fileList.length > 0 && fileList[fileList.length - 1].originFileObj;

        if (file) {
          try {
            const reader = new FileReader();

            reader.onload = e => {
              try {
                const content = e.target.result;
                const json = JSON.parse(content);

                onSubmit([file], json);
              } catch (err) {
                message.error(t('cards:FormUpload.jsonError'));
              }
            };

            reader.readAsText(file);
          } catch (err) {
            message.error(t('cards:FormUpload.fileError'));
          }
        }
      },
    };
  }, [fileList, onSubmit, t]);

  return (
    <Card.Half className="form-upload-card" title={t('cards:FormUpload.title')}>
      <div className="description">{t('cards:FormUpload.description')}</div>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <Icon type="cloud-upload" />
        </p>
        <p className="ant-upload-text">{t('cards:FormUpload.text')}</p>
        <p className="ant-upload-hint">{t('cards:FormUpload.hint')}</p>
      </Dragger>
      {error ? <p className="form-upload-error">{error}</p> : null}
    </Card.Half>
  );
};

FormUploadCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  error: PropTypes.string,
};

FormUploadCard.defaultProps = {
  error: '',
};

export default FormUploadCard;
