import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchOrganisations, editOrganisation } from '@redux/organisations/actions';
import { selectOrganisation, selectOrganisationUpdating } from '@redux/organisations/reducers';
import { Card } from '@cards/Card';
// import i18n from '../../../i18n';

import './style.less';

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

// const validateUrl = (_, value, callback) => {
//   if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
//     callback(i18n.t('cards:OrganisationDetailForm.socialForm.linkFormatError'));
//   }
//   callback();
// };

const orgFieldDisplayValue = (organisation, field) => {
  return organisation['updated_' + field] || organisation[field] || '';
};

const OrganisationDetailForm = ({ id, form: { getFieldDecorator, validateFields } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganisations());
  }, [dispatch]);

  const [loading, organisation] = useSelector(selectOrganisation(id));
  const updating = useSelector(selectOrganisationUpdating);

  const contactDetailsFields = [
    'organisation_name',
    'email',
    'phone_number',
    'website',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'suburb',
    'state',
    'postcode',
  ];

  const initialValues = useMemo(() => {
    if (organisation) {
      return {
        country: organisation.country,
        contactDetails: contactDetailsFields.reduce(
          (res, field) => ({ ...res, [field]: orgFieldDisplayValue(organisation, field) }),
          {},
        ),
        // contactDetails: {
        //   name: orgFieldDisplayValue(organisation, 'organisation_name'),
        //   email: orgFieldDisplayValue(organisation, 'email'),
        //   openHours: orgFieldDisplayValue(organisation, 'weekly_opening_hours'),
        //   phone: organisation.contactDetails.phone,
        //   address: organisation.contactDetails.address,
        //   addressSecondLine: organisation.contactDetails.addressSecondLine,
        //   type: organisation.contactDetails.type,
        // },
        socialMedia:
          organisation.socialMedia && Array.isArray(organisation.socialMedia)
            ? organisation.socialMedia.map(social => ({ ...social }))
            : [],
      };
    }
    return { country: undefined, contactDetails: {}, socialMedia: [] };
  }, [organisation, contactDetailsFields]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          // const socialMedia = [
          //   ...(values['first-social-type'] && values['first-social-link']
          //     ? [
          //         {
          //           type: values['first-social-type'],
          //           link: values['first-social-link'],
          //         },
          //       ]
          //     : []),
          //   ...(values['second-social-type'] && values['second-social-link']
          //     ? [
          //         {
          //           type: values['second-social-type'],
          //           link: values['second-social-link'],
          //         },
          //       ]
          //     : []),
          //   ...(values['third-social-type'] && values['third-social-link']
          //     ? [
          //         {
          //           type: values['third-social-type'],
          //           link: values['third-social-link'],
          //         },
          //       ]
          //     : []),
          // ];

          // const editedOrganisation = {
          //   ...organisation,
          //   country: values.country,
          //   contactDetails: {
          //     ...(organisation?.contactDetails || {}),
          //     openHours: values.hours,
          //     phone: values.phone,
          //     address: values.address,
          //     addressSecondLine: values.secondLineAddress,
          //     type: values.type,
          //   },
          //   socialMedia,
          // };

          dispatch(editOrganisation(organisation.id, values));
        }
      });
    },
    [validateFields, organisation, dispatch],
  );

  console.log(initialValues);

  return (
    <>
      <Card.Half
        className="organisation-details-form-card"
        loading={loading}
        title={t('cards:OrganisationDetailForm.detailsTitle')}
        onSubmit={onSubmit}
      >
        <Form {...detailsLayout}>
          {contactDetailsFields.map(field => (
            <Form.Item
              label={t(
                `cards:OrganisationDetailForm.detailsForm.labels.${field.replace(/_/g, '')}`,
              )}
            >
              {getFieldDecorator(`updated_${field}`, {
                rules:
                  field === 'name'
                    ? [
                        {
                          required: true,
                          message: t('cards:OrganisationDetailForm.detailsForm.required'),
                        },
                      ]
                    : [],
                initialValue: initialValues.contactDetails[field],
              })(
                <Input
                // placeholder={t(`cards:OrganisationDetailForm.detailsForm.placeholders.${field}`)}
                />,
              )}
            </Form.Item>
          ))}
        </Form>

        <Form {...detailsLayout}>
          {/* 
          <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.openHours')}>
            {getFieldDecorator('hours', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.openHours,
            })(
              <Input
                placeholder={t('cards:OrganisationDetailForm.detailsForm.placeholders.openHours')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.phone')}>
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.phone,
            })(
              <Input placeholder={t('cards:OrganisationDetailForm.detailsForm.placeholders.phone')} />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.address')}>
            {getFieldDecorator('address', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.address,
            })(
              <Input
                placeholder={t('cards:OrganisationDetailForm.detailsForm.placeholders.address')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.secondLineAddress')}>
            {getFieldDecorator('secondLineAddress', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.addressSecondLine,
            })(
              <Input
                placeholder={t(
                  'cards:OrganisationDetailForm.detailsForm.placeholders.secondLineAddress',
                )}
              />,
            )}
          </Form.Item> */}

          {/* <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.country')}>
            {getFieldDecorator('country', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.country,
            })(
              <Select placeholder={t('cards:OrganisationDetailForm.detailsForm.placeholders.country')}>
                <Select.Option value="germany">
                  {t('cards:OrganisationDetailForm.detailsForm.countries.germany')}
                </Select.Option>
                <Select.Option value="spain">
                  {t('cards:OrganisationDetailForm.detailsForm.countries.spain')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item> */}

          {/* <Form.Item label={t('cards:OrganisationDetailForm.detailsForm.labels.type')}>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: t('cards:OrganisationDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.type,
            })(
              <Select placeholder={t('cards:OrganisationDetailForm.detailsForm.placeholders.type')}>
                <Select.Option value="public">
                  {t('cards:OrganisationDetailForm.detailsForm.organisationTypes.public')}
                </Select.Option>
                <Select.Option value="private">
                  {t('cards:OrganisationDetailForm.detailsForm.organisationTypes.private')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item> */}

          {/* <h3>{t('cards:OrganisationDetailForm.socialTitle')}</h3>
          <div className="form-column-labels">
            <Form.Item label={t('cards:OrganisationDetailForm.socialForm.order')}>
              <div className="form-field-labels">
                <div>{t('cards:OrganisationDetailForm.socialForm.type')}</div>
                <div>{t('cards:OrganisationDetailForm.socialForm.link')}</div>
              </div>
            </Form.Item>
          </div>

          <Form.Item label="1">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('first-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[0]
                    ? initialValues.socialMedia[0].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:OrganisationDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:OrganisationDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:OrganisationDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:OrganisationDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('first-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[0]
                    ? initialValues.socialMedia[0].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:OrganisationDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="2">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('second-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[1]
                    ? initialValues.socialMedia[1].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:OrganisationDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:OrganisationDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:OrganisationDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:OrganisationDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('second-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[1]
                    ? initialValues.socialMedia[1].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:OrganisationDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="3">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('third-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[2]
                    ? initialValues.socialMedia[2].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:OrganisationDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:OrganisationDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:OrganisationDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:OrganisationDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('third-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[2]
                    ? initialValues.socialMedia[2].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:OrganisationDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={updating}>
              {t('common:buttons.save')}
            </Button>
          </Form.Item>
        </Form>
      </Card.Half>
    </>
  );
};

const WrappedOrganisationDetailForm = Form.create()(OrganisationDetailForm);

export default WrappedOrganisationDetailForm;
