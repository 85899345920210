import {
  INVALID_MOBILE,
  REGISTRATION_SUCCESS,
  REGISTRATION_SUBMITTED,
  INVITATION_CODE_SUBMITTED,
  INVITATION_CODE_FAILED,
  INVITATION_CODE_SUCCESS,
  UNHANDLED_REGISTRATION_ERROR,
  USERNAME_ALREADY_EXISTS,
} from './types';

const initialState = {
  submitting: false,
  error: false,
  credentails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UNHANDLED_REGISTRATION_ERROR:
    case USERNAME_ALREADY_EXISTS:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
      };
    case INVALID_MOBILE:
      return {
        ...state,
        submitting: false,
        error: true,
      };
    case REGISTRATION_SUBMITTED:
      return {
        ...state,
        credentails: {
          ...state.credentails,
          ...action.payload,
        },
        submitting: true,
      };
    case INVITATION_CODE_SUBMITTED:
      return {
        ...state,
        submitting: true,
        error: false,
      };
    case INVITATION_CODE_FAILED:
      return {
        ...state,
        submitting: false,
        error: true,
      };

    case REGISTRATION_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case INVITATION_CODE_SUCCESS:
      return {
        ...state,
        credentails: {
          ...state.credentails,
          email: action.payload.email,
        },
        submitting: false,
      };

    default:
      return state;
  }
};

export const isSubmitting = state => state.registration.submitting;
export const hasError = state => state.registration.error;
