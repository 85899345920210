import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';
import './style.less';

const numberRe = /\d+/;

function SelectTimeInterval({ value, onChange }, ref) {
  const { t } = useTranslation();
  const [timeInterval, setTimeInterval] = useState({ number: null, units: '' });

  const triggerNumberChange = useCallback(
    e => {
      if (numberRe.test(e.target.value) || e.target.value === '') {
        const updatedTimeInterval = { ...timeInterval, number: e.target.value };
        setTimeInterval(updatedTimeInterval);
        onChange(updatedTimeInterval);
      }
    },
    [timeInterval, onChange],
  );
  const triggerUnitChange = useCallback(
    value => {
      const updatedTimeInterval = { ...timeInterval, units: value };
      setTimeInterval(updatedTimeInterval);
      onChange(updatedTimeInterval);
    },
    [timeInterval, onChange],
  );

  useEffect(() => {
    if (value) {
      setTimeInterval(value);
    }
  }, [value]);

  return (
    <div className="select-time-interval" ref={ref}>
      <Input maxLength={3} value={timeInterval.number} onChange={triggerNumberChange} />
      <Select value={timeInterval.units} onChange={triggerUnitChange}>
        <Select.Option value="minutes">{t('common:timeIntervals.minutes')}</Select.Option>
        <Select.Option value="hours">{t('common:timeIntervals.hours')}</Select.Option>
        <Select.Option value="days">{t('common:timeIntervals.days')}</Select.Option>
        <Select.Option value="weeks">{t('common:timeIntervals.weeks')}</Select.Option>
        <Select.Option value="months">{t('common:timeIntervals.months')}</Select.Option>
        <Select.Option value="years">{t('common:timeIntervals.years')}</Select.Option>
      </Select>
    </div>
  );
}

export default forwardRef(SelectTimeInterval);
