import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { Column } from '../../../cards/Card';
import { usePageActions, useTitleUpdate } from '../../../hooks';
import ProcedureRuleListCard from '../../cards/Pathways/RuleList';
import NewDropdown from './NewDropdown';
import { historyType } from '../../../propTypes';
import { setCurrentScreen, screens } from '../../../analytics';
import './style.less';

function RuleList({ history }) {
  useEffect(() => {
    setCurrentScreen(screens.PATHWAYS_RULES_LIST);
  }, []);

  const perms = useHasPermissions();
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('pathways:ProcedureRules.header'),
    actions: [
      perms[Permissions.CreateRule] && <NewDropdown key="new-pathway-dropdown" history={history} />,
    ],
  });
  document.title = t('pathways:ProcedureRules.header');
  useTitleUpdate(setTitle, 'pathways:ProcedureRules.header');

  return (
    <Column>
      <ProcedureRuleListCard />
    </Column>
  );
}

RuleList.propTypes = {
  history: historyType.isRequired,
};

export default RuleList;
