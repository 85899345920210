import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectContentMessages } from '@redux/contentMessages/selectors';
import { fetchContentMessages } from '@redux/contentMessages/actions';
import createColumns from './createMessageColumns';
import ContentModal from './Modal';
import { MESSAGE, whatTypeToTranslationKey } from '../../../../../../constants';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function SelectMessageModal({ defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, data] = useSelector(selectContentMessages);
  const contentTypeTranslationKey = whatTypeToTranslationKey(MESSAGE);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue, contentTypeTranslationKey), [
    defaultFilteredValue,
    t,
    contentTypeTranslationKey,
  ]);

  useEffect(() => {
    dispatch(fetchContentMessages());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={data}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.message')}
      filterFunc={filterFunc}
      onCancel={onCancel}
      onOk={data => {
        const {
          latestVersion: { name, number, url, ...latestVersion },
          ...message
        } = data;
        onOk({ ...message, ...latestVersion });
      }}
    />
  );
}

SelectMessageModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectMessageModal;
