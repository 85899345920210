import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { List, Empty, Avatar } from 'antd';
import { Card } from '@cards/Card';
import { useTranslation } from 'react-i18next';
import { fetchFlags } from '@redux/flags/actions';
import { updateAppUserFlags } from '@redux/appUsers/actions';
import { selectFlags } from '@redux/flags/reducers';
import { selectAppUser } from '@redux/appUsers/reducers';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import FlagIcon from '@components/FlagIcon';
import FlagModal from '@components/FlagModal';
import './style.less';

const AppUserFlagsCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingFlags, flags] = useSelector(selectFlags);
  const [loadingAppUser, appUser] = useSelector(selectAppUser(appUserId));
  const [appUserFlags, setAppUserFlags] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchFlags());
  }, [dispatch]);

  useEffect(() => {
    if (flags && appUser && appUser.flags.length) {
      const aufs = flags.filter(f => appUser.flags.includes(f.uuid));
      setAppUserFlags(aufs);
    } else {
      setAppUserFlags([]);
    }
  }, [flags, appUser]);

  const hideFlagModal = () => setShowModal(false);

  const saveNewAppUserFlags = newFlags => {
    dispatch(updateAppUserFlags(appUserId, newFlags));
    hideFlagModal();
  };

  return (
    <Card
      className="app-user-details-card app-user-flags-card"
      loading={loadingFlags || loadingAppUser}
      title={t('flags:AppUser.title')}
      extra={[
        <IconButton
          icon={faEdit}
          tooltip={t('flags:AppUser.editTooltip')}
          onClick={() => {
            setShowModal(true);
          }}
        />,
      ]}
      {...props}
    >
      <FlagModal
        visible={showModal}
        organisationFlags={flags}
        selected={(appUser && appUser.flags) || []}
        onCancel={hideFlagModal}
        onOk={saveNewAppUserFlags}
      />
      {appUserFlags.length ? (
        <List
          itemLayout="horizontal"
          dataSource={appUserFlags}
          renderItem={flag => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar>
                    <FlagIcon colour={flag.colour} />
                  </Avatar>
                }
                title={flag.name}
                description={flag.description}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty description={t('flags:AppUser.no-flag')} image={null} />
      )}
    </Card>
  );
};

AppUserFlagsCard.propTypes = {
  appUserId: string.isRequired,
};

AppUserFlagsCard.defaultProps = {};

export default AppUserFlagsCard;
