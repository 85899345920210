import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Switch } from 'antd';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { toggleDashboardUserActivation } from '@redux/dashboardUsers/actions';
import { selectDashboardUserActivating } from '@redux/dashboardUsers/reducers';
import './style.less';

function UserAccessCard({
  activating,
  dashboardUserId,
  hasAccess,
  loading,
  resending,
  resendInvite,
  userName,
  userEmail,
  userUUID,
}) {
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  return (
    <Card
      className="dashboard-users-user-access"
      loading={loading}
      title={t('cards:DashboardUsersAccess.title')}
    >
      <DetailForm>
        <DetailRow
          label={t('cards:DashboardUsersAccess.labels.activate')}
          value={
            <Switch
              checked={hasAccess}
              loading={activating}
              onClick={() =>
                Modal.confirm({
                  title: t(
                    `cards:DashboardUsersAccess.modals.${
                      hasAccess ? 'deactivate' : 'activate'
                    }.title`,
                    { name: userName },
                  ),
                  okText: t(
                    `cards:DashboardUsersAccess.modals.${hasAccess ? 'deactivate' : 'activate'}.ok`,
                  ),
                  okType: hasAccess ? 'danger' : undefined,
                  onOk: () => {
                    dispatch(toggleDashboardUserActivation(dashboardUserId, hasAccess, userName));
                    return waitForStoreUpdate(store, state => [
                      selectDashboardUserActivating(state),
                    ]);
                  },
                })
              }
            />
          }
        />
      </DetailForm>
      {userEmail === userUUID ? (
        <Button disabled={resending} type="primary" onClick={resendInvite}>
          {t('cards:DashboardUsersAccess.resendCode')}
        </Button>
      ) : null}
    </Card>
  );
}

UserAccessCard.propTypes = {
  activating: bool,
  dashboardUserId: string,
  hasAccess: bool,
  loading: bool.isRequired,
  resending: bool,
  resendInvite: func.isRequired,
  userName: string,
};

UserAccessCard.defaultProps = {
  activating: false,
  resending: false,
  hasAccess: undefined,
};

export default UserAccessCard;
