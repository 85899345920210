import { select } from 'redux-saga/effects';
import { GLOBAL, HOSPITAL } from '@constants';
import { selectCurrentDashboardUser } from './login/reducer';

export function* doDetermineOwnerForContent(): any {
  const dashboardUser = yield select(selectCurrentDashboardUser);

  if (dashboardUser.profile.hospitalId) {
    return `${HOSPITAL}/${dashboardUser.profile.hospitalId}`;
  }

  // TODO: add handling for country and pathway admins

  return GLOBAL;
}
