export default (stages, journey) => {
  if (!stages?.length) return [];

  function organiseRules(stages, journey) {
    if (!journey?.entries?.length) {
      return stages.reduce((result, stage) => {
        result[stage.slug] = stage.rules.map((rule, index) => ({
          ...rule,
          order: index,
          stageSlug: stage.slug,
        }));
        return result;
      }, {});
    } else {
      const sortedEntries = [...journey.entries].sort(
        (a, b) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime(),
      );

      const ruleJourneyMap = new Map();
      sortedEntries.forEach(entry => {
        const ruleId = entry.data.original_rule_id;
        const stageSlug = entry.data.execution_details?.current_stage_slug;
        const key = `${ruleId}-${stageSlug}`;
        ruleJourneyMap.set(key, {
          executionIndex: ruleJourneyMap.has(key)
            ? ruleJourneyMap.get(key).executionIndex
            : sortedEntries.indexOf(entry),
          isActioned: entry.is_actioned,
        });
      });

      const ruleExecutionOrder = new Map();
      sortedEntries.forEach((entry, index) => {
        const ruleId = entry.data.original_rule_id;
        const stageSlug = entry.data.execution_details?.current_stage_slug;
        const key = `${ruleId}-${stageSlug}`;
        if (!ruleExecutionOrder.has(key)) {
          ruleExecutionOrder.set(key, index);
        }
      });

      return stages.reduce((result, stage) => {
        const rulesWithOrder = stage.rules.map(rule => {
          const key = `${rule.id}-${stage.slug}`;
          const journeyData = ruleJourneyMap.get(key);
          return {
            executionIndex: ruleExecutionOrder.has(key) ? ruleExecutionOrder.get(key) : Infinity,
            rule: {
              ...rule,
              stageSlug: stage.slug,
              ...(journeyData?.isActioned !== undefined && {
                isActioned: journeyData.isActioned,
              }),
            },
          };
        });

        rulesWithOrder.sort((a, b) => {
          if (a.executionIndex === Infinity && b.executionIndex === Infinity) {
            return 0;
          }
          return a.executionIndex - b.executionIndex;
        });

        result[stage.slug] = rulesWithOrder.map((item, index) => ({
          ...item.rule,
          order: index,
        }));

        return result;
      }, {});
    }
  }

  const rulesByStage = organiseRules(stages, journey);
  const allRules = Object.values(rulesByStage).flat();

  return allRules.reduce((groups, rule) => {
    const existingGroup = groups.find(group => group.order === rule.order);

    if (existingGroup) {
      existingGroup.rules.push(rule);
    } else {
      groups.push({
        order: rule.order,
        rules: [rule],
      });
    }

    groups.sort((a, b) => a.order - b.order);

    return groups;
  }, []);
};
