import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import LanguageFormItem from '@components/LanguageFormItem';

function DetailsCard({ children }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <fieldset>
          <legend>{t('common:Wizard.details.header')}</legend>
          <Form.Item label={t('common:Wizard.details.title.label')}>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('common:Wizard.details.title.required'),
                },
                {
                  max: 60,
                  message: t('common:Wizard.details.title.max60'),
                },
                {
                  pattern: /[A-Za-z]+/,
                  message: t('common:Wizard.details.title.pattern'),
                },
              ],
              initialValue: currentStepData.name || '',
            })(<Input placeholder={t('common:Wizard.details.title.placeholder')} />)}
          </Form.Item>
          <Form.Item label={t('common:Wizard.details.description.label')}>
            {form.getFieldDecorator('description', {
              initialValue: currentStepData.description || '',
              rules: [
                {
                  max: 200,
                  message: t('common:Wizard.details.description.max200'),
                },
              ],
            })(
              <Input.TextArea
                autoSize
                placeholder={t('common:Wizard.details.description.placeholder')}
              />,
            )}
          </Form.Item>
          <LanguageFormItem currentLanguage={currentStepData.language} form={form} />
          {children}
        </fieldset>
      </Form>
    </Card.Half>
  );
}

export default DetailsCard;
