import React from 'react';
import { Tooltip, Icon } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@components/IconButton';

import { faCheck, faTimesCircle, faCopy } from '@fortawesome/pro-regular-svg-icons';

// export default (t, hospitalIndex = {}, displayHospitalColumn) => [
export default (t, pathwaysByIds = {}, isLoadingPathways) => [
  {
    title: t('cards:ConnectionCodesList.labels.code'),
    key: 'code',
    render: record => (
      <>
        {record.code}
        <IconButton
          // disabled={selectedRows.length != 1}
          icon={faCopy}
          size="small"
          tooltip={t('common:buttons.copyToClipboard')}
          onClick={() => navigator.clipboard.writeText(record.code)}
        />
      </>
    ),
    sorter: (a, b) => a.code.localeCompare(b.code),
    onFilter: (value, record) => record.code.indexOf(value) > -1,
    width: 200,
  },
  {
    title: (
      <>
        {t('cards:ConnectionCodesList.labels.pathways')}&nbsp;
        <Tooltip title={t('cards:ConnectionCodesList.labels.pathwaysInfo')}>
          <Icon type="info-circle" />
        </Tooltip>
      </>
    ),
    key: 'pathways',
    render: code =>
      isLoadingPathways
        ? t('cards:ConnectionCodesList.labels.loadingPathways')
        : code.pathwayIds.map(pId => pathwaysByIds[pId]?.name).join('; '),
    // onFilter: (value, record) => record.pathwayIds.filter(pn => pn.indexOf(value) > -1).length > 1,
    // width: 200,
  },
  {
    title: (
      <>
        {t('cards:ConnectionCodesList.labels.isDefault')}&nbsp;
        <Tooltip title={t('cards:ConnectionCodesList.labels.isDefaultInfo')}>
          <Icon type="info-circle" />
        </Tooltip>
      </>
    ),
    key: 'isDefault',
    render: code => (code.isDefault ? <FontAwesomeIcon icon={faCheck} /> : null),
    // fixed: 'right',
    // width: 200,
  },
  {
    title: (
      <>
        {t('cards:ConnectionCodesList.labels.isDisabled')}&nbsp;
        <Tooltip title={t('cards:ConnectionCodesList.labels.isDisabledInfo')}>
          <Icon type="info-circle" />
        </Tooltip>
      </>
    ),
    key: 'isDisabled',
    render: code => (code.isDisabled ? <FontAwesomeIcon icon={faTimesCircle} /> : null),
    // fixed: 'right',
    // width: 200,
  },
  {
    title: t('cards:ConnectionCodesList.labels.lastModified'),
    key: 'modified',
    render: code => moment(code.modified).format('LLL'),
    fixed: 'right',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.modified > b.modified,
    // width: 200,
  },
  // {
  //   title: t('cards:DashboardUsersList.labels.contact'),
  //   key: 'contact',
  //   render: user => (
  //     <Tooltip placement="bottom" title={t('cards:DashboardUsersList.email')}>
  //       <Button
  //         type="link"
  //         onClick={e => e.stopPropagation()}
  //         href={`mailto:${user.email}`}
  //         className="contact-button"
  //       >
  //         <FontAwesomeIcon size="lg" icon={faEnvelope} />
  //       </Button>
  //     </Tooltip>
  //   ),
  //   width: 100,
  //   fixed: 'right',
  // },
  // {
  //   dataIndex: 'activated',
  //   title: t('cards:DashboardUsersList.labels.activeAccounts'),
  //   key: 'active',
  //   render: activated => c,
  //   width: 150,
  //   fixed: 'right',
  // },
];
