import { Permissions } from '@authorisation/constants';
import { GLOBAL, HOSPITAL } from '@constants';

function determineSourceTypeForNewContent(permissions: string[]) {
  if (permissions.includes(Permissions.ManagePatients)) {
    return HOSPITAL;
  }

  return GLOBAL;
}

export default determineSourceTypeForNewContent;
