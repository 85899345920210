import React, { useCallback, useEffect } from 'react';
// import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
// import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import AddedJourneyCard from './AddedJourneyCard';
import './style.less';

export default () => {
  const { t } = useTranslation();
  const {
    currentStepData: { journeys = [] },
    setCurrentStepData,
  } = useWizard();
  const addNewJourney = useCallback(() => {
    setCurrentStepData({
      journeys: [
        ...journeys,
        {
          id: journeys.length + 1,
          name: t('patients:NewPatient.journeys.defaultName'),
          procedures: [],
          campaigns: [],
          indexEventDates: {},
        },
      ],
    });
  }, [journeys, setCurrentStepData, t]);

  useEffect(() => {
    // currently only 1 journey supported and all users must have 1
    if (journeys.length === 0) {
      addNewJourney();
    }
  }, [journeys, addNewJourney]);

  const editJourney = useCallback(
    (id, editedJourney) => {
      setCurrentStepData({
        journeys: journeys.map(journey =>
          journey.id === id ? { ...journey, ...editedJourney } : journey,
        ),
      });
    },
    [journeys, setCurrentStepData],
  );

  // const removeJourney = useCallback(
  //   id => {
  //     setCurrentStepData({ journeys: journeys.filter(journey => journey.id !== id) });
  //   },
  //   [journeys, setCurrentStepData],
  // );

  return (
    <>
      {/* <Card.Full className="new-app-user-add-pathways-step">
        <div className="header">
          <h1>{t('patients:NewPatient.journeys.title')}</h1>
          <Button type="dashed" icon="plus" onClick={addNewJourney}>
            {t('patients:NewPatient.journeys.newButton')}
          </Button>
        </div>
      </Card.Full> */}

      {journeys.map(journey => (
        <AddedJourneyCard
          journey={journey}
          key={journey.id}
          editJourney={editJourney}
          // removeJourney={removeJourney}
        />
      ))}
    </>
  );
};
