import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Input, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@hooks';
import './style.less';

function SelectContentModal({ columns, data, loading, title, filterFunc, onCancel, onOk }) {
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const [searchResults, searchProps] = useSearch(data, filterFunc);

  return (
    <Modal
      className="choose-content-modal"
      okButtonProps={{ disabled: !selected }}
      title={title}
      visible
      width={800}
      onCancel={onCancel}
      onOk={() => onOk(selected)}
    >
      <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey={record => record.id || record.uuid}
        rowSelection={{ type: 'radio', onSelect: record => setSelected(record) }}
      />
    </Modal>
  );
}

SelectContentModal.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  title: string.isRequired,
  filterFunc: func.isRequired,
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectContentModal;
