import React from 'react';
import ChangePasswordCard from '@cards/ChangePassword';
import { ColumnGroup, Column } from '@cards/Card';

const MyProfileChangePasswordTab = () => {
  return (
    <ColumnGroup>
      <Column>
        <ChangePasswordCard />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default MyProfileChangePasswordTab;
