import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import useWizard from '@components/Wizard/useWizard';

function Details({ documentTranslationKey, isEdit }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <>
      <Form.Item label={t(`${documentTranslationKey}:Wizard.details.status`)}>
        {form.getFieldDecorator('status', {
          initialValue: currentStepData.status || 'published',
        })(
          <Select>
            <Select.Option value="published">
              {t(`${documentTranslationKey}:Wizard.details.publish`)}
            </Select.Option>
            <Select.Option value="unpublished">
              {t(`${documentTranslationKey}:Wizard.details.unpublish`)}
            </Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label={t(`${documentTranslationKey}:Wizard.details.audience`)}>
        {form.getFieldDecorator('audience', {
          initialValue: currentStepData.audience || 'app-user',
        })(
          <Select disabled={isEdit}>
            <Select.Option value="app-user">
              {t(`${documentTranslationKey}:Wizard.details.patient`)}
            </Select.Option>
            <Select.Option value="clinician">
              {t(`${documentTranslationKey}:Wizard.details.clinician`)}
            </Select.Option>
            {/* <Select.Option value="patient-admin">
              {t(`${documentTranslationKey}:Wizard.details.patientAdmin`)}
            </Select.Option> */}
          </Select>,
        )}
      </Form.Item>
    </>
  );
}

export default Details;
