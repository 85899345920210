/*
Components which compose the Form generation provided by react-jsonschema-form with translations

These components ensure that the translation t function is exposed to the formContext.
*/

import React from 'react';
import Form from '@liquid-state/ui-kit-react-jsonschema-form';
import { withTranslation } from 'react-i18next';

export const TranslatedForm = ({ t, formContext = {}, schema, uiSchema, ...props }) => {
  return (
    <Form {...props} formContext={{ t: t, ...formContext }} schema={schema} uiSchema={uiSchema}>
      {props.children}
    </Form>
  );
};

export const NamespacedTranslatedForm = ({ namespace = '', ...props }) => {
  // This will ensure we inject the correct t object.
  let Translated = withTranslation(namespace)(TranslatedForm);
  return <Translated {...props} />;
};

export default NamespacedTranslatedForm;
