import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Column, ColumnGroup } from '@cards/Card';
import { usePageActions } from '@hooks';
import EditButton from '@components/EditButton';
import DetailsCard from '@cards/Flags/Details/Card';
import { selectFlag } from '@redux/flags/reducers';
import { historyType, matchType } from '../../../propTypes';

function Details({ history, match }) {
  const selector = useCallback(selectFlag(match.params.id), [match]);
  const [loading, flag] = useSelector(selector);

  const { setTitle } = usePageActions({
    actions: [
      <EditButton key="edit-index-event" onClick={() => history.push(`${match.url}/edit`)} />,
    ],
    showBack: true,
    title: ' ',
  });

  useEffect(() => {
    setTitle(flag?.name);
  }, [flag, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard flag={flag} loading={loading} />
      </Column>
      <Column />
    </ColumnGroup>
  );
}

Details.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default Details;
