import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Menu } from 'antd';
import {
  faKey,
  faUserShield,
  faDatabase,
  faUserSlash,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { usePageActions, useTitleUpdate } from '@hooks';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { matchType } from '../../propTypes';
import MyProfileChangePasswordTab from './MyProfileChangePasswordTab';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import GDPRDataRequest from './GDPRDataRequest';
import GDPRDeletionRequest from './GDPRDeletionRequest';
import EditMyProfile from './EditMyProfile';

const routes = {
  changePassword: 'change-password',
  editMyProfile: 'edit-profile',
  termsAndConditions: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  gdprDataRequest: 'gdpr-request-data',
  gdprDeleteRequest: 'gdpr-delete-data',
};

const MyProfileContent = ({ match }) => {
  const { t } = useTranslation();

  const { setTitle } = usePageActions({
    showBack: false,
  });

  useEffect(() => {
    setTitle(t('myProfile:title'));
    document.title = t('myProfile:title');
  }, [t, setTitle]);

  useTitleUpdate(setTitle, 'myProfile:title');

  return (
    <SubNavMenuContainer
      menu={
        <SubNavMenu>
          <Menu.Item key={routes.editMyProfile}>
            <FontAwesomeIcon icon={faUser} className="tab-item-icon" />
            {t('myProfile:Tabs.editMyProfile')}
          </Menu.Item>
          <Menu.Item key={routes.changePassword}>
            <FontAwesomeIcon icon={faKey} className="tab-item-icon" />
            {t('myProfile:Tabs.changePassword')}
          </Menu.Item>
          <Menu.Item key={routes.termsAndConditions}>
            <FontAwesomeIcon icon={faKey} className="tab-item-icon" />
            {t('myProfile:Tabs.termsCondition')}
          </Menu.Item>
          <Menu.Item key={routes.privacyPolicy}>
            <FontAwesomeIcon icon={faUserShield} className="tab-item-icon" />
            {t('myProfile:Tabs.privacyPolicy')}
          </Menu.Item>
          <Menu.Item key={routes.gdprDataRequest}>
            <FontAwesomeIcon icon={faDatabase} className="tab-item-icon" />
            {t('myProfile:Tabs.dataDownload')}
          </Menu.Item>
          <Menu.Item key={routes.gdprDeleteRequest}>
            <FontAwesomeIcon icon={faUserSlash} className="tab-item-icon" />
            {t('myProfile:Tabs.dataDeletion')}
          </Menu.Item>
        </SubNavMenu>
      }
    >
      <Switch>
        <Route path={`${match.path}/${routes.editMyProfile}`} component={EditMyProfile} />
        <Route
          path={`${match.path}/${routes.changePassword}`}
          component={MyProfileChangePasswordTab}
        />
        <Route path={`${match.path}/${routes.termsAndConditions}`} component={TermsAndConditions} />
        <Route path={`${match.path}/${routes.privacyPolicy}`} component={PrivacyPolicy} />
        <Route path={`${match.path}/${routes.gdprDataRequest}`} component={GDPRDataRequest} />
        <Route path={`${match.path}/${routes.gdprDeleteRequest}`} component={GDPRDeletionRequest} />
        <Route
          path={match.path}
          render={() => <Redirect to={`${match.path}/${routes.changePassword}`} />}
        />
      </Switch>
    </SubNavMenuContainer>
  );
};

MyProfileContent.propTypes = {
  match: matchType.isRequired,
};

export default MyProfileContent;
