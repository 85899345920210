import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import {
  removeAppUserFile,
  getAppUserFileDownloadUrl,
  clearAppUserFileDownloadUrl,
} from '@redux/appUsers/actions';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { selectAppUserFiles, selectAppUserFileDownloadUrl } from '@redux/appUsers/reducers';
import { organisationDirectoryApiRoot } from 'settings';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
import FileDetailsCard from '@cards/Files/FileDetails';
import { ColumnGroup, Column } from '@cards/Card';
import IconButton from '@components/IconButton';
import { useTranslation } from 'react-i18next';
import { faPen, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import { historyType, matchType } from '../../../propTypes';
import '../style.less';

function AppUserFile({ history, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [createdByName, setCreatedByName] = useState('');
  const [modifiedByName, setModifiedByName] = useState('');

  const { url, loading: downloadUrlLoading } = useSelector(
    selectAppUserFileDownloadUrl(match.params.id),
  );

  const { files, loading } = useSelector(selectAppUserFiles(match.params.id));
  const file = files?.find(f => f.uuid === match.params.uuid);

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  const handleViewFile = useCallback(() => {
    dispatch(getAppUserFileDownloadUrl(match.params.id, file));
  }, [dispatch, match.params.id, file]);

  const handleRemoveFile = useCallback(() => {
    dispatch(removeAppUserFile(match.params.id, file));
  }, [dispatch, file, match.params.id]);

  const showDeleteWarningModal = useCallback(() => {
    Modal.confirm({
      title: t('patients:Files.removeModal.title'),
      content: t('patients:Files.removeModal.content'),
      okText: t('patients:Files.removeModal.okText'),
      okType: 'danger',
      cancelText: t('patients:Files.removeModal.cancelText'),
      onOk: handleRemoveFile,
      onCancel() {
        return;
      },
    });
  }, [handleRemoveFile, t]);

  useEffect(() => {
    if (url) {
      window.open(url, '_blank');
      dispatch(clearAppUserFileDownloadUrl(match.params.id));
    }
  }, [url, downloadUrlLoading, dispatch, match.params.id]);

  const [, currentOrg] = useSelector(selectOrganisation());

  const getOrgName = useCallback(
    async id => {
      const splitId = id.split('/');
      if (splitId[0] === 'appuser') {
        return t('patients:Files.patient');
      } else if (splitId[0] === 'organisation') {
        if (splitId[1] === currentOrg?.organisation_slug) {
          return currentOrg.updated_organisation_name || currentOrg.organisation_name || '';
        }
        const client = new OrganisationDirectoryClient({ baseUrl: organisationDirectoryApiRoot });
        const response = await client.getOrganisationBySlug(splitId[1], true);
        const org = response.results.find(o => o.organisation_slug === splitId[1]);
        return org?.updated_organisation_name || org?.organisation_name || '';
      }
      return '';
    },
    [currentOrg, t],
  );

  useEffect(() => {
    if (file) {
      getOrgName(file.created_by).then(name => setCreatedByName(name));
      getOrgName(file.modified_by).then(name => setModifiedByName(name));
    }
  }, [file, getOrgName]);

  return (
    <>
      <ColumnGroup>
        <Column>
          {file && (
            <FileDetailsCard
              file={file}
              loading={loading}
              createdBy={createdByName}
              modifiedBy={modifiedByName}
              extra={[
                <IconButton
                  key="remove-file-button"
                  tooltip={t('common:buttons.remove')}
                  icon={faTrash}
                  onClick={showDeleteWarningModal}
                  tooltipPlacement="right"
                  disabled={file.is_deleted || !file.is_editable || file.shares[0]?.is_deleted}
                />,
                <IconButton
                  className="view-file-button"
                  key="view-file-button"
                  tooltip={t('cards:FileDetails.viewFileTooltip')}
                  icon={downloadUrlLoading ? <></> : faExternalLink}
                  onClick={() => handleViewFile(file.uuid)}
                  disabled={file.is_deleted || !file.is_ready || file.shares[0]?.is_deleted}
                  loading={downloadUrlLoading}
                  style={{ padding: 0 }}
                />,
                <IconButton
                  key="edit-file-button"
                  tooltip={
                    file.is_editable
                      ? t('cards:FileDetails.editFileTooltip')
                      : t('cards:FileDetails.notCreator')
                  }
                  icon={faPen}
                  onClick={() => history.push(`${match.url}/edit`)}
                  tooltipPlacement="right"
                  disabled={file.is_deleted || !file.is_editable || file.shares[0]?.is_deleted}
                />,
              ]}
            />
          )}
        </Column>
        <Column />
      </ColumnGroup>
    </>
  );
}

AppUserFile.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default AppUserFile;
