import { combineReducers } from 'redux';
import { Flag } from '@liquid-state/uis-client/dist/types';
import { IFlagsState } from './types';
import {
  IFlagsLoaded,
  ILoadFlags,
  ILoadFlagsFailed,
  ICreateFlag,
  ICreateFlagFailed,
  ICreateFlagSuccess,
  IEditFlag,
  IEditFlagFailed,
  IEditFlagSuccess,
  IMoveFlag,
  IMoveFlagSuccess,
  IMoveFlagFailed,
} from './actions';

const creating = (
  state = false,
  action: ICreateFlag | ICreateFlagFailed | ICreateFlagSuccess,
): boolean => {
  switch (action.type) {
    case 'flags/createFlag':
      return true;
    case 'flags/createFlagFailed':
    case 'flags/createFlagSuccess':
      return false;
    default:
      return state;
  }
};

const editing = (
  state = false,
  action:
    | IEditFlag
    | IEditFlagFailed
    | IEditFlagSuccess
    | IMoveFlag
    | IMoveFlagSuccess
    | IMoveFlagFailed,
) => {
  switch (action.type) {
    case 'flags/editFlag':
    case 'flags/moveFlag':
      return true;
    case 'flags/editFlagFailed':
    case 'flags/editFlagSuccess':
    case 'flags/moveFlagFailed':
    case 'flags/moveFlagSuccess':
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action: ILoadFlags | IFlagsLoaded | ILoadFlagsFailed): boolean => {
  switch (action.type) {
    case 'flags/load':
      return true;
    case 'flags/loaded':
    case 'flags/loadFailed':
      return false;
    default:
      return state;
  }
};

const data = (
  state: Flag[] = [],
  action: IFlagsLoaded | IEditFlagSuccess | IMoveFlagSuccess | ICreateFlagSuccess,
): Flag[] => {
  switch (action.type) {
    case 'flags/loaded':
      return action.payload.flags;
    case 'flags/createFlagSuccess':
      return [action.payload.flag, ...state];
    case 'flags/editFlagSuccess':
      return state.map(flag =>
        flag.uuid === action.payload.flag.uuid ? action.payload.flag : flag,
      );
    default:
      return state;
  }
};

export default combineReducers({
  creating,
  data,
  editing,
  loading,
});

export const selectFlagCreating = (state: IFlagsState) => state.flags.creating;
export const selectFlagEditing = (state: IFlagsState) => state.flags.editing;

export const selectFlags = (state: IFlagsState): [boolean, Flag[]] => [
  state.flags.loading,
  state.flags.data,
];

export const selectEnabledFlags = (state: IFlagsState): [boolean, Flag[]] => [
  state.flags.loading,
  state.flags.data.filter(flag => !flag.isDisabled),
];

export const selectDisabledFlags = (state: IFlagsState): [boolean, Flag[]] => [
  state.flags.loading,
  state.flags.data.filter(flag => flag.isDisabled),
];

export const selectFlag = (uuid: string) => (state: IFlagsState): [boolean, Flag | undefined] => [
  state.flags.loading,
  state.flags.data.find(flag => flag.uuid === uuid),
];

export const selectFlagsSwitching = (state: IFlagsState) => state.flags.switching;
