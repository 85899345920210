import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OrganisationList from './HospitalList';
import OrganisationDetails from './HospitalDetails';
import NewOrganisation from './New';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/new`} component={NewOrganisation} />
    <Route path={`${match.path}/:id`} component={OrganisationDetails} />
    <Route path={`${match.path}`} component={OrganisationList} />
  </Switch>
);
