import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import SingleSelectModal from '@components/SingleSelectModal';

function SelectAppUser({ visible, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, appUsers] = useSelector(selectAppUsers);

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const filterFunc = (value, comparator) =>
    comparator(`${value.firstName} ${value.lastName}`) || comparator(value.email);

  const columns = useMemo(
    () => [
      {
        title: t('common:name'),
        render: record => `${record.firstName} ${record.lastName}`,
      },
      {
        title: t('common:email'),
        render: record => record.email,
      },
    ],
    [t],
  );

  return (
    <SingleSelectModal
      columns={columns}
      dataSource={appUsers}
      filterFunc={filterFunc}
      loading={loading}
      primaryKey="id"
      title={t('common:modals.SelectUser.title')}
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

SelectAppUser.propTypes = {
  visible: bool,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

SelectAppUser.defaultProps = {
  visible: false,
};

export default SelectAppUser;
