import React from 'react';
import RuleTag from '@components/RuleTag';

export default (stages, t) => {
  return [
    ...(stages || []).map(stage => ({
      dataIndex: 'rules',
      key: stage.slug,
      title: stage.isAdhoc
        ? stage.name
        : `${t('pathways:ProcedurePathways.wizard.review.stage')} ${stage.number}: ${stage.name}`,
      ...(stage.isAdhoc ? { fixed: 'right', width: '300px' } : {}),
      render: (rules = []) => {
        return rules.map(rule => {
          if (rule.stageSlug !== stage.slug && rule.stageSlug !== stage.stageKey) {
            return null;
          }

          return <RuleTag rule={rule} key={rule.id} disableLink />;
        });
      },
    })),
  ];
};
