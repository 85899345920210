import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAppUsers,
  fetchAppUserFiles,
  createAppUserFile,
  updateAppUserFile,
} from '@redux/appUsers/actions';
import { selectAppUser, selectAppUserFiles } from '@redux/appUsers/reducers';
import { usePageActions } from '@hooks';
import { Form, Upload, Icon, Input, Button, Tag, Modal, DatePicker } from 'antd';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import '../style.less';

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

function AddEditAppUserFile({
  history,
  match,
  form: { getFieldDecorator, validateFields, setFields },
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { files, loading: filesLoading } = useSelector(selectAppUserFiles(match.params.id));
  const fileToEdit = files?.find(f => f.uuid === match.params.uuid);

  const [uploadedFilesList, setUploadedFilesList] = useState(fileToEdit ? [fileToEdit] : []);
  const [isFileModified, setIsFileModified] = useState(false);

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUserFiles(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (fileToEdit) {
      setUploadedFilesList([fileToEdit]);
    }
  }, [fileToEdit]);

  const [loading, appUser] = useSelector(selectAppUser(match.params.id));
  const [submitting, setSubmitting] = useState(false);
  const [isFilesLoading, setIsFileLoading] = useState(filesLoading);

  useEffect(() => {
    if (submitting && !isFilesLoading) history.goBack();
  }, [isFilesLoading, submitting, history]);

  useEffect(() => {
    setIsFileLoading(filesLoading);
  }, [filesLoading]);

  const { setTitle } = usePageActions({
    actions: [],
    showBack: true,
  });

  useEffect(() => {
    if (!loading && appUser) {
      setTitle(
        t(
          `${
            fileToEdit
              ? 'patients:Files.addEditFileForm.editTitle'
              : 'patients:Files.addEditFileForm.addTitle'
          }`,
          {
            patientName: appUser?.displayName,
          },
        ),
      );
    }
  }, [setTitle, loading, appUser, t, fileToEdit]);

  const onChange = useCallback(({ fileList }) => {
    setIsFileModified(true);
    setUploadedFilesList(fileList);
  }, []);

  const validateCreationDate = useCallback(
    (rule, value, callback) => {
      if (!value) {
        callback();
        return;
      }

      const currentDate = new Date();
      const creationDate = new Date(value);

      if (
        creationDate < currentDate ||
        creationDate.toDateString() === currentDate.toDateString()
      ) {
        callback();
        return;
      }

      callback(t('patients:Files.addEditFileForm.creationDateError'));
    },
    [t],
  );

  const validateFile = useCallback(
    (rule, value, callback) => {
      if (uploadedFilesList.length > 0 || fileToEdit) {
        callback();
        return;
      }

      callback(t('patients:Files.addEditFileForm.fileRequired'));
    },
    [uploadedFilesList, t, fileToEdit],
  );

  const handleSubmit = useCallback(
    values => {
      setIsFileLoading(true);
      setSubmitting(true);
      if (!fileToEdit) {
        dispatch(
          createAppUserFile(
            match.params.id,
            {
              name: values.name,
              description: values.description,
              mimetype: uploadedFilesList[0].type,
              original_creation_date: values.creationDate
                ? moment(values.creationDate).format('YYYY-MM-DD')
                : undefined,
            },
            uploadedFilesList[0].originFileObj,
          ),
        );
      } else {
        dispatch(
          updateAppUserFile(match.params.id, fileToEdit, {
            name: values.name,
            description: values.description,
            original_creation_date: values.creationDate
              ? moment(values.creationDate).format('YYYY-MM-DD')
              : undefined,
          }),
        );
      }
    },
    [dispatch, match.params.id, fileToEdit, uploadedFilesList],
  );

  const handleValidation = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          if (!fileToEdit) {
            Modal.confirm({
              title: t('patients:Files.addEditFileForm.confirmModal.title'),
              content: (
                <>
                  <Text>{t('patients:Files.addEditFileForm.confirmModal.content1')}</Text>
                  <br />
                  <br />
                  <Text>
                    {t('patients:Files.addEditFileForm.confirmModal.content2', {
                      patientName: appUser?.displayName,
                    })}
                  </Text>
                </>
              ),
              okText: t('patients:Files.addEditFileForm.confirmModal.okText'),
              cancelText: t('patients:Files.addEditFileForm.confirmModal.cancelText'),
              onOk: () => handleSubmit(values),
            });
          } else {
            handleSubmit(values);
          }
        }
      });
    },
    [validateFields, t, handleSubmit, appUser, fileToEdit],
  );

  useEffect(() => {
    if (uploadedFilesList.length > 0) {
      setFields({
        file: {
          error: undefined,
        },
      });
    } else if (isFileModified) {
      setFields({
        file: {
          errors: [new Error(t('patients:Files.addEditFileForm.fileRequired'))],
        },
      });
    }
  }, [uploadedFilesList, setFields, t, isFileModified]);

  return (
    <ColumnGroup className="add-edit-file">
      <Column>
        <Card>
          <Form {...detailsLayout} onSubmit={handleValidation}>
            {!fileToEdit && (
              <Form.Item label={t('patients:Files.addEditFileForm.file')} required>
                {getFieldDecorator('file', {
                  rules: [{ validator: validateFile }],
                })(
                  <>
                    <Upload
                      accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .avif, .webp, .mp4, .mov"
                      fileList={uploadedFilesList}
                      disabled={uploadedFilesList.length > 0}
                      name={t('patients:Files.addEditFileForm.file')}
                      listType="picture-card"
                      showUploadList={false}
                      onChange={onChange}
                      className="file-uploader"
                    >
                      <div className="upload-button">
                        <Icon height="3em" type="cloud-upload" width="3em" />
                        {uploadedFilesList.length > 0 ? null : (
                          <p>{t('documents:Wizard.content.upload.help')}</p>
                        )}
                        <p className="supported-upload">
                          {t('patients:Files.addEditFileForm.supportedFileTypes')}
                        </p>
                      </div>
                    </Upload>
                    {uploadedFilesList.length > 0 && (
                      <div className="file-upload-list">
                        {uploadedFilesList.map(file => (
                          <Tag closable key={file.uid} onClose={() => setUploadedFilesList([])}>
                            <span>
                              <FontAwesomeIcon icon={faPaperclip} />
                              {file.name}
                            </span>
                          </Tag>
                        ))}
                      </div>
                    )}
                  </>,
                )}
              </Form.Item>
            )}
            <Form.Item label={t('patients:Files.addEditFileForm.name')}>
              {getFieldDecorator('name', {
                initialValue: fileToEdit?.name,
                rules: [
                  {
                    message: t('patients:Files.addEditFileForm.nameRequired'),
                    required: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('patients:Files.addEditFileForm.creationDate')}>
              {getFieldDecorator('creationDate', {
                initialValue: fileToEdit?.original_creation_date
                  ? moment(fileToEdit?.original_creation_date)
                  : undefined,
                rules: [{ validator: validateCreationDate }],
              })(<DatePicker className="creation-date-picker" format="LL" />)}
            </Form.Item>
            <Form.Item label={t('patients:Files.addEditFileForm.description')}>
              {getFieldDecorator('description', {
                initialValue: fileToEdit?.description,
              })(<TextArea />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isFilesLoading}>
                {t('patients:Files.addEditFileForm.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

const WrappedAddEditAppUserFile = Form.create()(AddEditAppUserFile);
export default WrappedAddEditAppUserFile;
