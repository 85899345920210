import IDMClient from '@liquid-state/idm-client';
import { idmApiRoot } from 'settings';

async function createIDMClient(jwt) {
  try {
    const client = await new IDMClient(jwt, { baseUrl: idmApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createIDMClient;
