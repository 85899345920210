import { put, call, take, select, takeLatest, getContext } from 'redux-saga/effects';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { message } from 'antd';
import i18n from '../../i18n';
import {
  PASSWORD_RESET_STARTED,
  PASSWORD_RESET_CODE_SUBMITTED,
  PASSWORD_RESET_PASSWORD_SUBMITTED,
  passwordResetComplete,
  passwordResetEmailValid,
  passwordResetEmailInvalid,
  passwordResetCodeValid,
  passwordResetCodeInvalid,
  passwordResetPasswordInvalid,
} from './actions';

import { getUserPool } from '../login/saga';

export default function* passwordRecoverySaga() {
  yield takeLatest(PASSWORD_RESET_STARTED, onPasswordReset);
}

function* onPasswordReset({ payload: { email } }) {
  const history = yield getContext('history');
  const userPool = getUserPool();
  const user = new CognitoUser({
    Username: email.replace('@', '*').toLowerCase(),
    Pool: userPool,
  });
  try {
    yield call(
      () =>
        new Promise((resolve, reject) => {
          user.forgotPassword({
            onSuccess: resolve,
            onFailure: reject,
            inputVerificationCode: resolve,
          });
        }),
    );
  } catch (err) {
    console.error(err);
    if (err.code === 'UserNotFoundException') {
      message.error(i18n.t('registration:PasswordReset.error'));
    }
    yield put(passwordResetEmailInvalid());
    return;
  }
  yield put(passwordResetEmailValid());
  yield call(history.replace, '/auth/recovery/2');
  yield call(onResetCompletion);

  yield put(passwordResetComplete());
  yield call(history.replace, '/auth/login');
}

function* onResetCompletion() {
  const i18n = yield getContext('i18n');
  const history = yield getContext('history');
  const email = yield select(state => state.passwordRecovery.email);
  const codeSubmitted = yield take(PASSWORD_RESET_CODE_SUBMITTED);
  yield put(passwordResetCodeValid());
  yield call(history.replace, '/auth/recovery/3');
  const passwordSubmitted = yield take(PASSWORD_RESET_PASSWORD_SUBMITTED);

  let {
    payload: { code },
  } = codeSubmitted;
  let {
    payload: { password },
  } = passwordSubmitted;

  const userPool = getUserPool();
  const user = new CognitoUser({
    Username: email.replace('@', '*'),
    Pool: userPool,
  });
  while (true) {
    try {
      yield call(
        (code, password) =>
          new Promise((resolve, reject) => {
            user.confirmPassword(code, password, {
              onSuccess: resolve,
              onFailure: reject,
            });
          }),
        code,
        password,
      );
      yield put(passwordResetComplete());
      yield call(history.replace, '/auth');
      yield call(message.success, i18n.t('passwordRecovery:success'));
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        yield put(passwordResetCodeInvalid('code'));
        yield call(history.replace, '/auth/recovery/2');
        code = (yield take(PASSWORD_RESET_CODE_SUBMITTED)).payload.code;
        yield put(passwordResetCodeValid());
        yield call(history.replace, '/auth/recovery/3');
        password = (yield take(PASSWORD_RESET_PASSWORD_SUBMITTED)).payload.password;
      } else {
        yield put(passwordResetPasswordInvalid('password'));
        password = (yield take(PASSWORD_RESET_PASSWORD_SUBMITTED)).payload.password;
      }
    }
  }
}
