import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUsers } from '@redux/dashboardUsers/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitals } from '@redux/hospitals/reducers';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import SearchableTable from '@components/SearchableTable';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.name);
};

const AppUserListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { [Permissions.AdministerAllHospitals]: displayHospitalColumn } = useHasPermissions(
    Permissions.AdministerAllHospitals,
  );

  useEffect(() => {
    dispatch(fetchDashboardUsers());
    if (displayHospitalColumn) dispatch(fetchHospitals());
  }, [dispatch, displayHospitalColumn]);

  const [hospitalsloading, hospitalList] = useSelector(selectHospitals);
  const [dashboardUsersLoading, dashboardUsers] = useSelector(selectDashboardUsers);

  const hospitalIndex = useMemo(
    () =>
      hospitalList.reduce(
        (list, hospital) => ({
          ...list,
          [hospital.hospitalId]: hospital.name,
        }),
        {},
      ),
    [hospitalList],
  );
  const loading = hospitalsloading || dashboardUsersLoading;

  const columns = useMemo(() => createColumns(t, hospitalIndex, displayHospitalColumn), [
    t,
    hospitalIndex,
    displayHospitalColumn,
  ]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={dashboardUsers}
      filterFunc={filterFunc}
      loading={loading}
      hasRoutedPagination
      title={showTitle ? <h3>{t('cards:DocumentList.title')}</h3> : null}
    />
  );
};

export default AppUserListCard;
