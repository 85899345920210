import { IState } from '@redux/reducer';
import { UserType } from './types';

export const selectPermissionsMap = (state: IState): { [key: string]: any } =>
  state.authorisation.permissionsMap;

export const selectUserRole = (state: any): UserType => state.login.user?.role;

export const selectUserHospital = (state: any): UserType => state.login.user?.profile.hospitalId;

export const selectPermissionsForUser = (state: IState) => {
  const result = selectPermissionsMap(state)[selectUserRole(state)];
  if (!result) {
    return [];
  }
  return result;
};
