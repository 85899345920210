import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Card, ColumnGroup, Column } from '@cards/Card';
import { Form, Button, Table, Modal, Checkbox, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import useWizard from '@components/Wizard/useWizard';
import ContentPreview from '@pathways/cards/Pathways/RuleDetails/ContentPreview';
import {
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  FEATURE_WEBLINK,
} from '@constants';

import './style.less';

const featureTypes = [
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  FEATURE_WEBLINK,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
];

function ContentUpdates({ pathway }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setCurrentStepData, formData, form } = useWizard();

  const [rules, setRules] = useState([]);
  const [contentFromRules, setContentFromRules] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isContentPreviewVisible, setIsContentPreviewVisible] = useState(false);
  const [contentToPreview, setContentToPreview] = useState(null);

  const previewType = 'what';

  useEffect(() => {
    if (pathway?.stages?.length > 0) setRules(pathway.stages.flatMap(stage => stage.rules));
  }, [pathway]);

  useEffect(() => {
    if (rules.length === 0 && !pathway) {
      dispatch(fetchPathways());
    }
  }, [dispatch, rules, pathway]);

  useEffect(() => {
    if (rules.length > 0) {
      const contentFromRules = rules.map(rule => {
        return { ...rule.whatDetail, what: rule.what };
      });
      const uniqueTracker = {};
      const uniqueContentFromRules = [];
      contentFromRules.forEach(item => {
        const uniqueId = item.uuid || item.id;
        if (uniqueId && !uniqueTracker[uniqueId]) {
          uniqueTracker[uniqueId] = true;
          const randomVersion = (n =>
            n % 2 === 0 ? 'Version 3' : n <= 5 ? 'Version 1' : 'Version 2')(
            Math.floor(Math.random() * 10) + 1,
          );
          const randomDate = new Date(
            new Date().getTime() - Math.floor(Math.random() * 10000000000),
          ).toLocaleDateString();
          uniqueContentFromRules.push({
            ...item,
            version: randomVersion,
            version_creation_date: randomDate,
            latest_version: (n => (n % 2 === 0 ? 'Version 4' : n <= 5 ? 'Version 5' : 'Version 6'))(
              Math.floor(Math.random() * 10) + 1,
            ),
            latest_version_creation_date: new Date(
              new Date().getTime() - Math.floor(Math.random() * 10000000000),
            ).toLocaleDateString(),
            latest_version_name: Math.random() > 0.5 ? `${item.title || item.name} - Latest` : null,
          });
        }
      });
      setContentFromRules(uniqueContentFromRules);
    }
  }, [rules]);

  useEffect(() => {
    if (formData?.contentUpdates?.selectedContent?.length > 0 && contentFromRules.length > 0) {
      form.setFieldsValue({ selectedContent: formData.contentUpdates.selectedContent });
      setSelectedRowKeys(
        formData.contentUpdates.selectedContent.map(sc =>
          contentFromRules.findIndex(cfr => cfr.uuid === sc.uuid || cfr.id === sc.id),
        ),
      );
    }
  }, [formData, contentFromRules]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatContentAsRule = c => {
    const type = c.metadata?.type || c.type;
    return {
      what:
        featureTypes.find(feature =>
          type ? feature.split('_')[1] === type : feature === c.what,
        ) || (c.what === MESSAGE ? MESSAGE : FEATURE_WEBLINK),
      whatDetail: c,
    };
  };

  const columns = [
    {
      title: t('pathways:ProcedurePathways.contentUpdates.contentName'),
      dataIndex: '',
      key: 'name',
      ellipsis: true,
      render: ({ name, title }) => title || name,
      sorter: (a, b) => {
        const aTitle = a.title || a.name;
        const bTitle = b.title || b.name;
        return aTitle.localeCompare(bTitle);
      },
    },
    {
      title: t('pathways:ProcedurePathways.contentUpdates.currentVersion'),
      dataIndex: '',
      key: 'version',
      render: record => (
        <div className="version-column">
          <div>{record.version}</div>
          <div className="version-creation-date-text">{record.version_creation_date}</div>
          <div className="preview-button-container">
            <Button
              onClick={() => {
                setContentToPreview(formatContentAsRule(record));
                setIsContentPreviewVisible(true);
              }}
            >
              {t('common:buttons.preview')}
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: t('pathways:ProcedurePathways.contentUpdates.latestVersion'),
      dataIndex: '',
      key: 'latest_version',
      render: (
        record,
        { latest_version, latest_version_creation_date, latest_version_name, title, name },
      ) => (
        <div className="version-column">
          <div>
            {latest_version}
            {latest_version_name && (
              <Popover
                content={
                  <div>
                    <p className="previous-content-title-text">{title || name}</p>
                    <p className="latest-content-title-text">{`${name || title} - Latest`}</p>
                  </div>
                }
                title={t('pathways:ProcedurePathways.contentUpdates.updatedContentTitle')}
                trigger="hover"
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color="#ffaa00"
                  className="new-version-name-icon"
                />
              </Popover>
            )}
          </div>
          <div className="version-creation-date-text">{latest_version_creation_date}</div>
          <div className="preview-button-container">
            <Button
              onClick={() => {
                setContentToPreview(formatContentAsRule(record));
                setIsContentPreviewVisible(true);
              }}
            >
              {t('common:buttons.preview')}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleSelectContent = selected => {
    form.setFieldsValue({ selectedContent: selected });
    setCurrentStepData({
      selectedContent: selected,
    });
  };

  const validateContentSelection = (_, value, callback) => {
    if (value || selectedRowKeys.length > 0) {
      callback();
    } else {
      callback(t('pathways:ProcedurePathways.contentUpdates.contentItemsRequired'));
    }
  };

  const selectAllRows = () => {
    setSelectedRowKeys(Array.from({ length: contentFromRules.length }, (_, i) => i));
    setCurrentStepData({
      selectedContent: contentFromRules,
    });
    form.setFieldsValue({ selectedContent: contentFromRules });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      handleSelectContent(selectedRows);
    },
    getCheckboxProps: record => ({
      name: record.name,
    }),
    columnTitle: (
      <>
        <Checkbox
          checked={selectedRowKeys.length === contentFromRules.length}
          indeterminate={
            selectedRowKeys.length > 0 && selectedRowKeys.length < contentFromRules.length
          }
          onChange={e => {
            if (e.target.checked) {
              Modal.confirm({
                title: t('pathways:ProcedurePathways.contentUpdates.selectAllContentModal.title'),
                content: t(
                  'pathways:ProcedurePathways.contentUpdates.selectAllContentModal.content',
                ),
                onOk: selectAllRows,
              });
            } else {
              setSelectedRowKeys([]);
            }
          }}
        />
      </>
    ),
  };

  return (
    <>
      <ColumnGroup>
        <Column>
          <Form className="content-updates-step">
            <Form.Item name="selectedContent" rules={[{ validator: validateContentSelection }]}>
              {form.getFieldDecorator('selectedContent', {
                rules: [{ validator: validateContentSelection }],
              })(
                <Card
                  className="staged-rules-card"
                  title={t('pathways:ProcedurePathways.contentUpdates.pathwayContent')}
                  extra={
                    <>
                      <Button
                        icon="reconciliation"
                        type="dashed"
                        onClick={() => {
                          return Modal.confirm({
                            title: t('pathways:ProcedurePathways.contentUpdates.useLatestForAll'),
                            content: t(
                              'pathways:ProcedurePathways.contentUpdates.useLatestForAllContent',
                            ),
                            onOk: selectAllRows,
                          });
                        }}
                      >
                        {t('pathways:ProcedurePathways.contentUpdates.useLatestForAll')}
                      </Button>
                    </>
                  }
                >
                  <div className="description-container">
                    <p>
                      {t('pathways:ProcedurePathways.contentUpdates.contentUpdatesDescription')}
                    </p>
                    <p>
                      {t('pathways:ProcedurePathways.contentUpdates.contentItemsSelected', {
                        selected: selectedRowKeys.length,
                        total: contentFromRules.length,
                        pathwayName: pathway?.name,
                      })
                        .split('*')
                        .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
                    </p>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={contentFromRules}
                    pagination={false}
                    locale={{
                      emptyText: t('pathways:ProcedurePathways.contentUpdates.noContentItems'),
                    }}
                    rowSelection={rowSelection}
                  />
                </Card>,
              )}
            </Form.Item>
          </Form>
        </Column>
        <ContentPreview
          previewType={previewType}
          rule={contentToPreview}
          visible={isContentPreviewVisible}
          onCancel={() => setIsContentPreviewVisible(false)}
        />
      </ColumnGroup>
    </>
  );
}

export default ContentUpdates;
