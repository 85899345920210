import { spawn, call, put, select, takeEvery, getContext } from 'redux-saga/effects';
import { AccessDenied } from './index';
import { selectPermissionsForUser, selectPermissionsMap, selectUserRole } from './selectors';
import { setPermissionsMap } from './actions';
import { permissionsMap } from './constants';
import { SESSION_ESTABLISHED } from '@redux/login/actions';

import { idmApiRoot } from 'settings';

export default function*() {
  yield spawn(doLoadPermissionsMap);
  yield takeEvery(SESSION_ESTABLISHED, doUpdatePermissionsFromPolicy);
}

export function* requiresPermissions(...permissions: string[]): any {
  const userPermissions = yield select(selectPermissionsForUser);
  const ok = permissions.every(p => userPermissions.includes(p));
  if (!ok) {
    throw new AccessDenied();
  }
}

function* doLoadPermissionsMap() {
  yield put(setPermissionsMap(permissionsMap));
}

function* doUpdatePermissionsFromPolicy(): any {
  const tokens = yield getContext('tokens');
  const jwt = yield call(tokens.getRootJWT);
  const profileResp = yield call(window.fetch.bind(window), `${idmApiRoot}api/profile/`, {
    headers: { Authorization: `Bearer ${jwt}` },
    credentials: 'include',
  });
  const profile = yield call(profileResp.json.bind(profileResp));
  console.log(profile);

  const permissionsToAdd = [];
  const permissionsToRemove = [];

  for (const policy of profile.policies) {
    for (const statement of policy.statements) {
      for (const resource of statement.resources) {
        const [, , product, , permission] = resource.split(':');
        if (product !== 'admin-dashboard') {
          continue;
        }
        // Once this is further developed we should establish a proper resource / action / permission mapping
        // For now this just uses the permission name as the resource.
        // And ignores conditions.
        statement.effect === 'allow'
          ? permissionsToAdd.push(permission)
          : permissionsToRemove.push(permission);
      }
    }
  }

  const wholeMap = yield select(selectPermissionsMap);
  const userRole = yield select(selectUserRole);

  const updatedRoleMap = new Set(wholeMap[userRole]);
  for (const permission of permissionsToAdd) {
    updatedRoleMap.add(permission);
  }
  for (const permission of permissionsToRemove) {
    updatedRoleMap.delete(permission);
  }

  const finalMap = {
    ...wholeMap,
    [userRole]: Array.from(updatedRoleMap),
  };

  yield put(setPermissionsMap(finalMap));
}
