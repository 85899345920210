import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@hooks';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitals } from '@redux/hospitals/reducers';
import { Card } from '@cards/Card';
import createColumns from './createColumns';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const HospitalListCard = ({ showTitle, match, onRowClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [loading, hospitals] = useSelector(selectHospitals);
  const [searchResults, searchProps] = useSearch(hospitals, filterFunc);

  return (
    <>
      <Card.Full className="hospital-list-card" noPadding>
        <div className="search-container">
          {showTitle ? <h3>{t('cards:HospitalList.title')}</h3> : null}
          <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
          <div className="card-buttons"></div>
        </div>
        <Table
          columns={columns}
          dataSource={searchResults}
          loading={loading}
          pagination={{ position: 'bottom' }}
          rowKey="id"
          scroll={{ x: 1000 }}
          onRow={({ id }) => ({
            onClick: onRowClick ? () => onRowClick(id) : () => history.push(`${match.url}/${id}`),
          })}
        />
      </Card.Full>
    </>
  );
};

export default HospitalListCard;
