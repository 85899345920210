import { IIndexEvent } from './types';

export type IFetchIndexEvents = ReturnType<typeof fetchIndexEvents>;
export const fetchIndexEvents = () => ({
  type: 'indexEvents/fetch' as const,
  payload: {},
});

export type ILoadIndexEvents = ReturnType<typeof loadIndexEvents>;
export const loadIndexEvents = () => ({
  type: 'indexEvents/load' as const,
  payload: {},
});

export type IIndexEventsLoaded = ReturnType<typeof indexEventsLoaded>;
export const indexEventsLoaded = (indexEvents: IIndexEvent[]) => ({
  type: 'indexEvents/loaded' as const,
  payload: { indexEvents },
});

export type ILoadIndexEventsFailed = ReturnType<typeof loadIndexEventsFailed>;
export const loadIndexEventsFailed = () => ({
  type: 'indexEvents/loadFailed' as const,
  payload: {},
});

export type ICreateIndexEvent = ReturnType<typeof createIndexEvent>;
export const createIndexEvent = (
  name: string,
  slug: string,
  translatedNames: { [key: string]: string },
  disableNavigate?: boolean,
) => ({
  type: 'indexEvents/createIndexEvent' as const,
  payload: { name, slug, translatedNames, disableNavigate },
});

export type ICreateIndexEventSuccess = ReturnType<typeof createIndexEventSuccess>;
export const createIndexEventSuccess = (indexEvent: IIndexEvent) => ({
  type: 'indexEvents/createIndexEventSuccess' as const,
  payload: { indexEvent },
});

export type ICreateIndexEventFailed = ReturnType<typeof createIndexEventFailed>;
export const createIndexEventFailed = () => ({
  type: 'indexEvents/createIndexEventFailed' as const,
  payload: {},
});

export type IEditIndexEvent = ReturnType<typeof editIndexEvent>;
export const editIndexEvent = (indexEvent: IIndexEvent) => ({
  type: 'indexEvents/editIndexEvent' as const,
  payload: { indexEvent },
});

export type IEditIndexEventSuccess = ReturnType<typeof editIndexEventSuccess>;
export const editIndexEventSuccess = (indexEvent: IIndexEvent) => ({
  type: 'indexEvents/editIndexEventSuccess' as const,
  payload: { indexEvent },
});

export type IEditIndexEventFailed = ReturnType<typeof editIndexEventFailed>;
export const editIndexEventFailed = () => ({
  type: 'indexEvents/editIndexEventFailed' as const,
  payload: {},
});

export type IDeleteIndexEvents = ReturnType<typeof deleteIndexEvents>;
export const deleteIndexEvents = (indexEventIds: number[]) => ({
  type: 'indexEvents/deleteIndexEvents' as const,
  payload: { indexEventIds },
});

export type IDeleteIndexEventsSuccess = ReturnType<typeof deleteIndexEventsSuccess>;
export const deleteIndexEventsSuccess = (indexEventIds: number[]) => ({
  type: 'indexEvents/deleteIndexEventsSuccess' as const,
  payload: { indexEventIds },
});

export type IDeleteIndexEventsFailed = ReturnType<typeof deleteIndexEventsFailed>;
export const deleteIndexEventsFailed = () => ({
  type: 'indexEvents/deleteIndexEventsFailed' as const,
  payload: {},
});

export type ISwitchIndexEventOrder = ReturnType<typeof switchIndexEventOrder>;
export const switchIndexEventOrder = (id: number, order: number) => ({
  type: 'indexEvents/switchOrder' as const,
  payload: { id, order },
});

export type ISwitchIndexEventOrderSuccess = ReturnType<typeof switchIndexEventOrderSuccess>;
export const switchIndexEventOrderSuccess = (id: number, order: number) => ({
  type: 'indexEvents/switchOrderSuccess' as const,
  payload: { id, order },
});

export type ISwitchIndexEventOrderFailed = ReturnType<typeof switchIndexEventOrderFailed>;
export const switchIndexEventOrderFailed = () => ({
  type: 'indexEvents/switchOrderFailed' as const,
  payload: {},
});
