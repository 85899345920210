import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import {
  deletePathwayFailure,
  deletePathwaySuccess,
  deletePathwaysFailure,
  deletePathwaysSuccess,
  IDeletePathway,
  IDeletePathways,
  IDeleteEngagementCheck,
  deleteEngagementCheckSuccess,
  deleteEngagementCheckFailed,
} from '../actions';
import { selectPathway } from '../reducers';
import i18n from '../../../../i18n';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';

function* doDeletionOfPathways(pathwayIds: number[]): any {
  const pathwaysClient = yield call(doCreatePathwaysClient);

  for (const pathwayId of pathwayIds) {
    // remove this when patch implemented better in pathwaysClient
    const [, pathway] = yield select(selectPathway(pathwayId));

    yield call(pathwaysClient.patchPathway, pathwayId, {
      description: pathway.description,
      name: pathway.name,
      isActive: pathway.isActive,
      isDeleted: true,
    });
  }
}

export function* doDeletePathway({ payload: { pathwayId } }: IDeletePathway): any {
  const history = yield getContext('history');
  try {
    yield call(doDeletionOfPathways, [pathwayId]);

    yield put(deletePathwaySuccess(pathwayId));
    yield call(history.push, '/procedure/pathways');
    yield call(message.success, i18n.t('cards:ProcedurePathwayDetails.delete.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayDetails.delete.error'));
    yield put(deletePathwayFailure());
  }
}

export function* doDeletePathways({ payload: { pathwayIds } }: IDeletePathways) {
  try {
    yield call(doDeletionOfPathways, pathwayIds);

    yield put(deletePathwaysSuccess(pathwayIds));
    yield call(message.success, i18n.t('cards:ProcedurePathwayDetails.delete.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayDetails.delete.error'));
    yield put(deletePathwaysFailure());
  }
}

export function* doDeleteEngagementCheck({
  payload: { pathwayId, engagementCheckId },
}: IDeleteEngagementCheck): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    yield call(pathwaysClient.deleteEngagementCheck, pathwayId, engagementCheckId);
    yield put(deleteEngagementCheckSuccess(pathwayId, engagementCheckId));
    yield call(message.success, i18n.t('pathways:ProcedurePathways.deleteEngagementCheckSuccess'));
  } catch (err) {
    console.error(err);
    yield put(deleteEngagementCheckFailed(pathwayId));
    yield call(message.error, i18n.t('pathways:ProcedurePathways.deleteEngagementCheckError'));
  }
}
