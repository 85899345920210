import React from 'react';
import { PageHeader, Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePageActionsConsumer } from '../../../hooks';

import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

export default ({ title: titleOverride, showClose: showCloseOverride }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { actions, title, showClose, showBack, onBack, onClose } = usePageActionsConsumer();

  const onClick = onClose || history.goBack;

  return (
    <PageHeader
      ghost={false}
      className="header-bar"
      onBack={showBack ? onBack || history.goBack : undefined}
      backIcon={
        <Tooltip key="back-button" title={t('common:buttons.back')} placement="bottom">
          <FontAwesomeIcon icon={faChevronLeft} />
        </Tooltip>
      }
      title={titleOverride || title}
      extra={[
        ...actions,
        ...(showCloseOverride || showClose
          ? [
              <Tooltip key="close-button" title={t('common:buttons.close')} placement="bottom">
                <Button
                  size="large"
                  shape="circle"
                  onClick={onClick}
                  className="close-button"
                  icon="close"
                />
              </Tooltip>,
            ]
          : []),
      ]}
    />
  );
};
