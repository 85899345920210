import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import SelectGroupsModal from './SelectGroupsModal';
import SelectedGroupList from './SelectedGroupList';
import './style.less';

function Audience() {
  const [whoModalType, setWhoModalType] = useState(null);
  const [whoDetail, setWhoDetail] = useState(null);
  const { t } = useTranslation();
  const { currentStepData, form, formData } = useWizard();

  useEffect(() => {
    if (currentStepData.whoDetail) {
      setWhoDetail(currentStepData.whoDetail);
      form.setFieldsValue({ whoDetail: currentStepData.whoDetail });
    } else {
      form.setFieldsValue({ who: 'ALL' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelection = useCallback(e => {
    setWhoModalType(e.target.value);
  }, []);
  const onCancel = useCallback(() => {
    setWhoModalType(null);
    if (!whoDetail) {
      form.setFieldsValue({ who: null });
    }
  }, [whoDetail, form]);
  const onOk = useCallback(
    data => {
      setWhoModalType(null);
      setWhoDetail(data);
      form.setFieldsValue({ whoDetail: data });
    },
    [form],
  );

  const onRemoveGroups = useCallback(
    toRemove => {
      const newSelectedGroups = whoDetail.filter(({ id }) => !toRemove.includes(id));

      if (newSelectedGroups.length) {
        setWhoDetail(newSelectedGroups);
        form.setFieldsValue({ whoDetail: newSelectedGroups });
        return;
      }

      setWhoDetail(null);
      form.setFieldsValue({ who: null, whoDetail: null });
    },
    [whoDetail, form],
  );

  return (
    <>
      <Card.Half className="procedure-rule-card select-audience">
        <Form>
          <fieldset>
            <legend>{t('pathways:ProcedureRules.wizard.audience.header')}</legend>

            {/* <p className="info-text">{t('pathways:ProcedureRules.wizard.audience.info')}</p> */}

            <Form.Item>
              {form.getFieldDecorator('who', {
                initialValue: currentStepData.who,
                rules: [
                  {
                    required: true,
                    message: t('pathways:ProcedureRules.wizard.audience.validationError'),
                  },
                ],
              })(
                <Radio.Group onChange={handleSelection}>
                  <Radio value="ALL">{t('pathways:ProcedureRules.wizard.audience.all')}</Radio>
                  {/* <Radio value="GROUP">{t('pathways:ProcedureRules.wizard.audience.group')}</Radio> */}
                </Radio.Group>,
              )}
            </Form.Item>
            {/* sneaky form field to store audience data */}
            {form.getFieldDecorator('whoDetail')(<span />)}
          </fieldset>
          {whoModalType === 'GROUP' ? (
            <SelectGroupsModal
              audienceData={whoDetail}
              defaultFilteredValue={{ language: [formData.details.language] }}
              onCancel={onCancel}
              onOk={onOk}
            />
          ) : null}
        </Form>
        {form.getFieldValue('who') === 'GROUP' ? (
          <div className="add-button-container">
            <Button icon="plus" onClick={() => setWhoModalType('GROUP')}>
              {t('pathways:ProcedureRules.wizard.audience.addGroups')}
            </Button>
          </div>
        ) : null}
      </Card.Half>
      {whoDetail && form.getFieldValue('who') === 'GROUP' ? (
        <SelectedGroupList selectedGroups={whoDetail} onRemoveGroups={onRemoveGroups} />
      ) : null}
    </>
  );
}

export default Audience;
