import { put, call, select, take } from 'redux-saga/effects';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { appUserJourneysLoaded } from '../actions';
import { APP_USERS_LOADED } from '@redux/appUsers/types';
import { selectAppUser } from '@redux/appUsers/reducers';
import { appUsersPathwaysLoaded } from '../actions';
import { mapSnakeCaseAppUserPathwayToCamelCase } from '../utils';

export function* doFetchAppUserJourneys({ payload: { appUserId } }) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    let [, appUserData] = yield select(selectAppUser(appUserId));

    if (!appUserData) {
      yield take(APP_USERS_LOADED);
      [, appUserData] = yield select(selectAppUser(appUserId));
    }

    const listPathwaysAppUsersResp = yield call(
      pathwaysClient.listAppUsers,
      undefined,
      appUserData.ids.pathways,
    );

    let journeys = [];
    if (listPathwaysAppUsersResp.results.length) {
      const pathwaysAppUser = listPathwaysAppUsersResp.results[0];
      if (pathwaysAppUser && pathwaysAppUser.journeys) {
        journeys = pathwaysAppUser.journeys;
      }
      for (const journey of journeys) {
        const entries = yield call(pathwaysClient.listEntriesForJourney, journey);
        const indexEvents = [];

        if (Array.isArray(journey.index_events)) {
          journey.index_events.forEach(
            ({ event_type_slug: eventTypeSlug, updated_on: updatedOn, ...indexEvent }) => {
              indexEvents.push({
                ...indexEvent,
                updatedOn,
                eventTypeSlug,
              });
            },
          );
        } else {
          const rawIndexEvents = yield call(pathwaysClient.listIndexEventsForJourney, journey);
          rawIndexEvents.forEach(
            ({ event_type_slug: eventTypeSlug, updated_on: updatedOn, ...indexEvent }) => {
              indexEvents.push({
                ...indexEvent,
                updatedOn,
                eventTypeSlug,
              });
            },
          );
        }

        journey.entries = entries;
        journey.indexEvents = indexEvents;
      }

      if (!journeys.length) {
        // no journey created for this app user yet, make one
        const startDate = new Date().toISOString().split('T')[0];
        const newJourney = yield call(
          pathwaysClient.createAppUserJourney,
          pathwaysAppUser.id,
          startDate,
        );
        journeys.push(newJourney);
      }
    }
    yield put(appUserJourneysLoaded(appUserData.ids.pathways, journeys));
  } catch (err) {
    console.error(err);
  }
}

const BATCH_SIZE = 20;
const PAGE_SIZE = 20;

export function* doFetchAppUsersPathways() {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    let appUsers = [];
    let page = 1;

    // temporary batching logic to improve list load speed
    // fetch first page
    const { count, next: moreThanOnePage, results } = yield call(pathwaysClient.listAppUsers, page);
    appUsers = [...results];

    // if more than 1 page, break pages up in to batches of BATCH_SIZE
    if (moreThanOnePage) {
      const numberOfPages = Math.ceil(count / PAGE_SIZE);
      const pageBatches = [];
      for (let i = page; i <= numberOfPages; i += BATCH_SIZE) {
        pageBatches.push([]);
        for (let j = 1; j < BATCH_SIZE + 1; j++) {
          if (i + j <= numberOfPages) {
            const pageBatchesIndex = i > BATCH_SIZE ? Math.floor(i / BATCH_SIZE) : 0;
            pageBatches[pageBatchesIndex] = [...pageBatches[pageBatchesIndex], i + j];
          }
        }
      }

      // iterate over batches of pages to fetch all pages
      for (const batch of pageBatches) {
        const fetchedPages = yield call(
          Promise.all.bind(Promise),
          batch.map(pageNumber => pathwaysClient.listAppUsers(pageNumber)),
        );

        appUsers = [...appUsers, ...fetchedPages.flatMap(({ results }) => results)];
      }
    }
    // end temp batching logic

    // while (true) {
    //   const { next, results } = yield call(pathwaysClient.listAppUsers, page);

    //   appUsers = [...appUsers, ...results];
    //   if (!next) break;
    //   page += 1;
    // }

    yield put(
      appUsersPathwaysLoaded(
        appUsers.map(appUser => ({
          ...appUser,
          pathways: appUser.pathways.map(pathway => mapSnakeCaseAppUserPathwayToCamelCase(pathway)),
        })),
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(appUsersPathwaysLoaded([]));
  }
}
