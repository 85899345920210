import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker /* , Input */ } from 'antd';
// import useWizard from '@components/Wizard/useWizard';
import { Card } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import AddPathways from './AddPathways';
import { pathwayType } from '../../../../../propTypes';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 16 },
  },
};

function AddedJourneyCard({ journey, editJourney /** , removeJourney */ }) {
  const { i18n, t } = useTranslation();
  const [, indexEventTypes] = useSelector(selectIndexEvents);
  // const { currentStepData, form } = useWizard();

  const indexEvents = useMemo(() => {
    const allPathways = [...journey.campaigns, ...journey.procedures];
    const indexEventSlugs = new Set();
    allPathways.forEach(({ indexEvents }) =>
      indexEvents.forEach(({ eventTypeSlug }) => indexEventSlugs.add(eventTypeSlug)),
    );

    return indexEventTypes.filter(({ slug }) => indexEventSlugs.has(slug));
  }, [indexEventTypes, journey]);

  const addPathways = useCallback(
    (pathwayType, pathways) => {
      editJourney(journey.id, { [pathwayType]: pathways });
    },
    [journey, editJourney],
  );

  const removePathway = useCallback(
    (pathwayType, id) => {
      editJourney(journey.id, {
        [pathwayType]: journey[pathwayType].filter(pathway => id !== pathway.id),
      });
    },
    [journey, editJourney],
  );

  const onIndexDateChanged = (slug, value) => {
    editJourney(journey.id, { indexEventDates: { ...journey.indexEventDates, [slug]: value } });
  };

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <>
      <Card.Half
        title={journey.name}
        // onClose={removeJourney ? () => removeJourney(journey.id) : null}
        className="added-journey-card"
      >
        {/* TODO: uncomment below code once journey names are added to pathway backends */}
        {/* <Form {...formItemLayout}>
          {editJourney ? (
            <Form.Item label={t('patients:NewPatient.journeys.fields.title')}>
              {form.getFieldDecorator(`${journey.id}-name`, {
                initialValue: currentStepData[`${journey.id}-name`],
                rules: [
                  { required: true, message: t('patients:NewPatient.journeys.fields.required') },
                ],
              })(<Input />)}
            </Form.Item>
          ) : (
            <DetailRow
              label={t('patients:NewPatient.journeys.fields.title')}
              value={journey.name}
            />
          )}
        </Form> */}
        <AddPathways
          addButtonText={t('patients:NewPatient.journeys.pathways.procedures.add')}
          detailsPath="/procedure/pathways"
          pathways={journey.procedures}
          title={t('patients:NewPatient.journeys.pathways.procedures.title')}
          addPathway={editJourney ? selectedIds => addPathways('procedures', selectedIds) : null}
          removePathway={editJourney ? id => removePathway('procedures', id) : null}
        />
        {/* TODO: uncomment below code when campaigns are added. */}
        {/* <AddPathways
          addButtonText={t('patients:NewPatient.journeys.pathways.campaigns.add')}
          detailsPath="/campaign/pathways"
          pathways={journey.campaigns}
          title={t('patients:NewPatient.journeys.pathways.campaigns.title')}
          addPathway={selectedIds => addPathways('campaigns', selectedIds)}
          removePathway={id => removePathway('campaigns', id)}
        /> */}

        {indexEvents.length ? (
          <>
            <h4 className="index-event-dates">
              {t('patients:NewPatient.journeys.indexEventsLabel')}
            </h4>
            <Form {...formItemLayout}>
              {indexEvents.map(indexEvent =>
                editJourney ? (
                  <Form.Item
                    className="add-patient-journey-form-item"
                    key={indexEvent.id}
                    label={indexEvent.translatedNames[languageCode] || indexEvent.name}
                  >
                    <DatePicker
                      format="LL"
                      onChange={value => onIndexDateChanged(indexEvent.slug, value)}
                      value={journey.indexEventDates[indexEvent.slug]}
                    />
                  </Form.Item>
                ) : (
                  <DetailRow
                    className="add-patient-journey-form-item"
                    key={indexEvent.id}
                    label={indexEvent.translatedNames[languageCode] || indexEvent.name}
                    value={
                      journey.indexEventDates[indexEvent.slug]?.format('LL') ||
                      t('patients:NewPatient.journeys.noDate')
                    }
                  />
                ),
              )}
            </Form>
          </>
        ) : null}
      </Card.Half>
    </>
  );
}

AddedJourneyCard.propTypes = {
  journey: shape({
    campaigns: arrayOf(pathwayType),
    name: string,
    procedures: arrayOf(pathwayType),
    indexEventDates: shape({}),
  }).isRequired,
  editJourney: func,
  removeJourney: func,
};

export default AddedJourneyCard;
