import { ICreateMessageInput } from '@liquid-state/messaging-client/dist/types';
import { IMessage } from './types';

export type IFetchMessages = ReturnType<typeof fetchMessages>;
export const fetchMessages = () => ({
  type: 'messages/fetch' as const,
  payload: {},
});

export type IMessagesLoaded = ReturnType<typeof messagesLoaded>;
export const messagesLoaded = (sent: IMessage[], scheduled: IMessage[]) => ({
  type: 'messages/loaded' as const,
  payload: {
    scheduled,
    sent,
  },
});

export type ILoadMessages = ReturnType<typeof loadMessages>;
export const loadMessages = () => ({
  type: 'messages/load' as const,
  payload: {},
});

export type ILoadMessagesFailed = ReturnType<typeof loadMessagesFailed>;
export const loadMessagesFailed = () => ({
  type: 'messages/load-failed' as const,
  payload: {},
});

export type IDeleteScheduledMessage = ReturnType<typeof deleteScheduledMessage>;
export const deleteScheduledMessage = (messageId: number) => ({
  type: 'messages/delete-scheduled' as const,
  payload: {
    messageId,
  },
});

export type IDeleteScheduledMessages = ReturnType<typeof deleteScheduledMessages>;
export const deleteScheduledMessages = (messageIds: number[]) => ({
  type: 'messages/delete-many-scheduled' as const,
  payload: {
    messageIds,
  },
});

export type IDeleteScheduledMessagesSuccess = ReturnType<typeof deleteScheduledMessagesSuccess>;
export const deleteScheduledMessagesSuccess = (messageIds: number[]) => ({
  type: 'messages/delete-scheduled-success' as const,
  payload: {
    messageIds,
  },
});

export type IDeleteScheduledMessagesFailed = ReturnType<typeof deleteScheduledMessagesFailed>;
export const deleteScheduledMessagesFailed = () => ({
  type: 'messages/delete-scheduled-failed' as const,
  payload: {},
});

export type ICreateScheduledMessage = ReturnType<typeof createScheduledMessage>;
export const createScheduledMessage = (messageData: ICreateMessageInput) => ({
  type: 'messages/create-scheduled' as const,
  payload: { messageData },
});

export type ICreateScheduledMessageFailed = ReturnType<typeof createScheduledMessageFailed>;
export const createScheduledMessageFailed = () => ({
  type: 'messages/create-scheduled-failed' as const,
  payload: {},
});

export type ICreateScheduledMessageSuccess = ReturnType<typeof createScheduledMessageSuccess>;
export const createScheduledMessageSuccess = (newMessage: IMessage) => ({
  type: 'messages/create-scheduled-success' as const,
  payload: { newMessage },
});
