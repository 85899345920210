import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Radio, Button, Select } from 'antd';
import DateSelect from '@components/DateSelect';
import { fetchAppUsers, updateAppUser } from '@redux/appUsers/actions';
import { selectAppUser, selectAppUsersUpdating } from '@redux/appUsers/reducers';
import languages from '@utils/supportedLanguages';
import moment from 'moment';
import { Card } from '../../Card';
import i18n from '../../../i18n';

import './styles.less';

const { Option } = Select;

const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const validateMinimumAge = (rule, value, callback) => {
  if (value) {
    const date = moment(value);
    const now = moment();
    const difference = now.diff(date, 'years');
    if (difference < 18) {
      callback(i18n.t('cards:PatientEditDetails.dateOfBirth.minimum'));
    }
  }
  callback();
};

const AppUserEditDetailsCard = ({
  appUser,
  title,
  loading,
  updating,
  form: { getFieldDecorator, validateFields },
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          const profile = {
            hospital: appUser.hospital,
            hospitalSlug: appUser.hospitalSlug,
            hospitalId: appUser.hospitalId,
            address: values.address,
            city: values.city,
            dateOfBirth: values.dateOfBirth,
            doctor: values.doctor,
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            language: values.language || 'en',
            lastName: values.lastName,
            phone: values.phone,
            postcode: values.postcode,
            sex: values.sex,
            title: values.title,
            _pep: appUser._pep,
          };
          dispatch(updateAppUser(appUser.id, profile));
        }
      });
    },
    [
      appUser._pep,
      appUser.hospital,
      appUser.hospitalId,
      appUser.hospitalSlug,
      appUser.id,
      dispatch,
      validateFields,
    ],
  );

  return (
    <Card
      className="app-user-edit-details-card"
      noPadding
      loading={loading}
      title={t(title)}
      onSubmit={onSubmit}
      {...props}
    >
      <Form {...formItemLayout} className="detail-form">
        <Form.Item label={t('cards:PatientEditDetails.firstName.label')}>
          {getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: t('cards:PatientEditDetails.firstName.required'),
              },
            ],
            initialValue: appUser.firstName,
          })(<Input placeholder={t('cards:PatientEditDetails.firstName.label')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.lastName.label')}>
          {getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                message: t('cards:PatientEditDetails.firstName.required'),
              },
            ],
            initialValue: appUser.lastName,
          })(<Input placeholder={t('cards:PatientEditDetails.lastName.label')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.email.label')}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                type: 'email',
                message: t('cards:PatientEditDetails.email.invalid'),
              },
            ],
            initialValue: appUser.email,
          })(<Input placeholder={t('cards:PatientEditDetails.email.placeholder')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.phone.label')} hasFeedback>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                pattern: /\+[\d\s]+/,
                message: t('cards:PatientEditDetails.phone.invalid'),
              },
            ],
            initialValue: appUser.phone,
          })(
            <Input
              placeholder={t('cards:PatientEditDetails.phone.placeholder')}
              onChange={e => {
                const { value } = e.target;
                if (value.startsWith('04')) {
                  e.target.value = `+61${value.slice(1)}`;
                }
              }}
            />,
          )}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.doctor.label')}>
          {getFieldDecorator('doctor', {
            rules: [
              {
                required: true,
                message: t('cards:PatientEditDetails.doctor.required'),
              },
            ],
            initialValue: appUser.doctor,
          })(<Input placeholder={t('cards:PatientEditDetails.doctor.label')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.language.label')}>
          {getFieldDecorator('language', {
            rules: [
              {
                required: true,
                message: t('cards:PatientEditDetails.language.required'),
              },
            ],
            initialValue: appUser.language,
          })(
            <Select
              className="language-select-option"
              placeholder={t('cards:PatientEditDetails.language.label')}
            >
              {Object.values(languages).map(({ icon: Icon, key, name }) => (
                <Option className="language-select-option" key={key}>
                  <Icon /> {t(name)}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <h3 className="subtitle">{t('cards:PatientEditDetails.subtitle')}</h3>

        <Form.Item label={t('cards:PatientEditDetails.address.label')}>
          {getFieldDecorator('address', {
            rules: [
              {
                message: t('cards:PatientEditDetails.address.required'),
              },
            ],
            initialValue: appUser.address,
          })(<Input placeholder={t('cards:PatientEditDetails.address.placeholder')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.city.label')}>
          {getFieldDecorator('city', {
            rules: [
              {
                message: t('cards:PatientEditDetails.city.required'),
              },
            ],
            initialValue: appUser.city,
          })(<Input placeholder={t('cards:PatientEditDetails.address.placeholder')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.postcode.label')}>
          {getFieldDecorator('postcode', {
            rules: [
              {
                message: t('cards:PatientEditDetails.postcode.required'),
              },
            ],
            initialValue: appUser.postcode,
          })(<Input placeholder={t('cards:PatientEditDetails.address.placeholder')} />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.dateOfBirth.label')}>
          {getFieldDecorator('dateOfBirth', {
            rules: [
              {
                message: t('cards:PatientEditDetails.dateOfBirth.required'),
              },
              { validator: validateMinimumAge },
            ],
            initialValue: appUser.dateOfBirth,
          })(<DateSelect />)}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.sex.label')}>
          {getFieldDecorator('sex', {
            rules: [
              {
                message: t('cards:PatientEditDetails.sex.required'),
              },
            ],
            initialValue: appUser.sex,
          })(
            <Radio.Group>
              <Radio value="male">{t('cards:PatientEditDetails.sex.male')}</Radio>
              <Radio value="female">{t('cards:PatientEditDetails.sex.female')}</Radio>
              <Radio value="other">{t('cards:PatientEditDetails.sex.other')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label={t('cards:PatientEditDetails.titleField.label')}>
          {getFieldDecorator('title', {
            rules: [
              {
                message: t('cards:PatientEditDetails.titleField.required'),
              },
            ],
            initialValue: appUser.title,
          })(<Input placeholder={t('cards:PatientEditDetails.titleField.label')} />)}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updating}>
            {t('common:buttons.save')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

AppUserEditDetailsCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserEditDetailsCard.defaultProps = {
  title: 'cards:PatientEditDetails.title',
  appUser: {},
};

const WrappedAppUserEditDetailsCard = Form.create()(AppUserEditDetailsCard);

const AppUserEditDetailsByIdCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const updating = useSelector(selectAppUsersUpdating);
  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  return (
    <WrappedAppUserEditDetailsCard
      appUser={appUser}
      loading={loading}
      updating={updating}
      {...props}
    />
  );
};

export { WrappedAppUserEditDetailsCard };

export default AppUserEditDetailsByIdCard;
