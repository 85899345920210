import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'antd';
import '../style.less';

const createColumns = (t, handleViewFile, downloadUrlLoading) => [
  {
    title: t('patients:Files.date'),
    dataIndex: 'created',
    key: 'created',
    width: 250,
    sorter: (a, b) => moment(a.created).toDate() - moment(b.created).toDate(),
    render: created => moment(created).format('LL'),
  },
  {
    title: t('patients:Files.name'),
    dataIndex: 'name',
    key: 'name',
    width: 250,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('patients:Files.description'),
    dataIndex: 'description',
    key: 'description',
    width: 250,
  },
  {
    title: t('patients:Files.view'),
    dataIndex: 'uuid',
    key: 'uuid',
    width: 250,
    render: (uuid, file) => (
      <Button
        onClick={e => {
          e.stopPropagation();
          handleViewFile(file);
        }}
        className="view-file-button"
        disabled={file.is_deleted || !file.is_ready || file.shares[0]?.is_deleted}
        loading={downloadUrlLoading}
      >
        {!downloadUrlLoading && <FontAwesomeIcon icon={faExternalLink} />}
      </Button>
    ),
  },
];

export default createColumns;
