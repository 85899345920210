import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPathwayWithRules,
  selectEngagementChecksLoading,
} from '@pathways/redux/pathways/reducers';
import { addEngagementCheck } from '@pathways/redux/pathways/actions';
import { usePageActions, useTitleUpdate } from '@hooks';
import EngagementCheckWizard from './EngagementCheckWizard';

function NewEngagementCheck({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setTitle } = usePageActions({
    title: t('pathways:ProcedurePathways.engagementCheckWizard.newTitle'),
  });
  useTitleUpdate(setTitle, 'pathways:ProcedurePathways.engagementCheckWizard.newTitle');

  const { id: pathwayId } = match.params;

  const engagementChecksLoading = useSelector(selectEngagementChecksLoading(pathwayId));
  const [pathwaysLoading, pathway] = useSelector(selectPathwayWithRules(pathwayId));

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    setInitialData({
      contentToCheckEngagement: {
        selectedContent: [],
      },
      typeOfCheck: {
        checkType: null,
        numberOfContents: null,
      },
      timing: {
        from: {
          type: null,
          value: null,
          daysOffset: null,
        },
        until: {
          type: null,
          value: null,
          daysOffset: null,
        },
        start: {
          type: null,
          value: null,
          daysOffset: null,
        },
        stop: {
          type: null,
          value: null,
          daysOffset: null,
        },
      },
      occurrences: {
        type: null,
        nTimes: null,
        untilAnotherEngagementCheckPassed: null,
      },
      action: {
        what: null,
        whatDetail: null,
      },
      details: {
        name: null,
        description: null,
      },
    });
  }, []);

  const onCompleted = useCallback(
    formData => {
      const {
        contentToCheckEngagement,
        typeOfCheck,
        timing,
        occurrences,
        action,
        details,
      } = formData;
      const engagementCheck = {
        id: Math.floor(Math.random() * 10000000000),
        contentToCheckEngagement,
        typeOfCheck,
        timing,
        occurrences,
        action,
        details,
        pathwayId: Number(pathwayId),
      };

      dispatch(addEngagementCheck(pathwayId, engagementCheck));
    },
    [dispatch, pathwayId],
  );

  return (
    <EngagementCheckWizard
      initialData={initialData}
      loading={pathwaysLoading}
      submitting={engagementChecksLoading}
      onCompleted={onCompleted}
      pathway={pathway}
    />
  );
}

export default NewEngagementCheck;
