import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { getTranslatedName } from '@utils';
// import './style.less';

function SelectIndexEventsModal({ selectedIndexEvents, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ selectedRowKeys, selectedRows }, setSelected] = useState({
    selectedRowKeys: [],
    selectedRows: [],
  });
  const [loading, indexEvents] = useSelector(selectIndexEvents);
  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        title: t('pathways:ProcedurePathways.wizard.indexEvents.modal.columns.name'),
        ellipsis: true,
        sorter: (a, b) => a.name > b.name,
        render: (text, record) => getTranslatedName(record.translatedNames, text),
      },
    ],
    [t],
  );
  const onOkClick = useCallback(() => {
    onOk(selectedRows);
    onCancel();
  }, [selectedRows, onCancel, onOk]);

  useEffect(() => {
    if (selectedIndexEvents) {
      setSelected({
        selectedRowKeys: selectedIndexEvents.map(({ id }) => id),
        selectedRows: [...selectedIndexEvents],
      });
    }
  }, [selectedIndexEvents]);

  useEffect(() => {
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  return (
    <Modal
      className="choose-content-modal"
      title={t('pathways:ProcedurePathways.wizard.indexEvents.modal.header')}
      visible
      width={850}
      onCancel={onCancel}
      onOk={onOkClick}
    >
      <Table
        columns={columns}
        dataSource={indexEvents}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) =>
            setSelected({ selectedRowKeys, selectedRows }),
        }}
      />
    </Modal>
  );
}

SelectIndexEventsModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectIndexEventsModal;
