import React from 'react';
import { Popover, Typography } from 'antd';
import cn from 'classnames';

import './styles.less';

export default ({ children, flag, noTitle }) => {
  const hasDescription = Boolean(flag?.description);
  const popOverClassName = cn('flag-popover', { 'has-description': hasDescription });
  return (
    <Popover
      title={noTitle ? null : <Typography.Text strong>{flag?.name}</Typography.Text>}
      content={flag?.description}
      overlayClassName={popOverClassName}
      placement="bottom"
    >
      {children}
    </Popover>
  );
};
