import React from 'react';
import { Form, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import DateSelect from '@components/DateSelect';
import moment from 'moment';
import i18n from '../../../i18n';

const validateMinimumAge = (rule, value, callback) => {
  if (value) {
    const date = moment(value);
    const now = moment();
    const difference = now.diff(date, 'years');
    if (difference < 18) {
      callback(i18n.t('patients:NewPatient.basicDetails.admin.dateOfBirth.minimum'));
    }
  }
  callback();
};

export default function() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half title={t('patients:NewPatient.basicDetails.admin.title')}>
      <Form layout="vertical" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.address.label')}>
          {form.getFieldDecorator('address', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.address.required'),
              },
            ],
            initialValue: currentStepData.address,
          })(
            <Input placeholder={t('patients:NewPatient.basicDetails.admin.address.placeholder')} />,
          )}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.city.label')}>
          {form.getFieldDecorator('city', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.city.required'),
              },
            ],
            initialValue: currentStepData.city,
          })(
            <Input placeholder={t('patients:NewPatient.basicDetails.admin.address.placeholder')} />,
          )}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.postcode.label')}>
          {form.getFieldDecorator('postcode', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.postcode.required'),
              },
            ],
            initialValue: currentStepData.postcode,
          })(
            <Input placeholder={t('patients:NewPatient.basicDetails.admin.address.placeholder')} />,
          )}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.dateOfBirth.label')}>
          {form.getFieldDecorator('dateOfBirth', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.dateOfBirth.required'),
              },
              { validator: validateMinimumAge },
            ],
            initialValue: currentStepData.dateOfBirth || undefined,
          })(<DateSelect />)}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.sex.label')}>
          {form.getFieldDecorator('sex', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.sex.required'),
              },
            ],
            initialValue: currentStepData.sex,
          })(
            <Radio.Group>
              <Radio value="male">{t('patients:NewPatient.basicDetails.admin.sex.male')}</Radio>
              <Radio value="female">{t('patients:NewPatient.basicDetails.admin.sex.female')}</Radio>
              <Radio value="other">{t('patients:NewPatient.basicDetails.admin.sex.other')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label={t('patients:NewPatient.basicDetails.admin.titleField.label')}>
          {form.getFieldDecorator('title', {
            rules: [
              {
                message: t('patients:NewPatient.basicDetails.admin.titleField.required'),
              },
            ],
            initialValue: currentStepData.title,
          })(<Input placeholder={t('patients:NewPatient.basicDetails.admin.titleField.label')} />)}
        </Form.Item>
      </Form>
    </Card.Half>
  );
}
