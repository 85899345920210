import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { Steps, Row, Col } from 'antd';

import './style.less';

const { Step } = Steps;

const headerColProps = [
  {
    sm: 19,
    md: 19,
  },
  {
    sm: 5,
    md: 5,
  },
];

const rowColProps = [
  {
    sm: 19,
    md: 19,
  },
  {
    sm: 5,
    md: 5,
  },
];

function StageSteps({ columns, current, dataSource, loading }) {
  return (
    <div className="stage-steps">
      <div className="title">
        <Row key="header">
          {columns.map((column, index) => (
            <Col key={index} {...headerColProps[index]}>
              {column.title}
            </Col>
          ))}
        </Row>
      </div>
      <Steps direction="vertical" current={current} status={loading ? 'wait' : 'process'}>
        {dataSource.map((data, index) => {
          const title = columns.map((col, index) => (
            <Col key={index} {...rowColProps[index]}>
              {data[col.key]}
            </Col>
          ));

          return <Step key={index} title={<Row>{title}</Row>} />;
        })}
      </Steps>
    </div>
  );
}

StageSteps.propTypes = {
  columns: arrayOf(
    shape({
      key: string,
      title: string,
    }),
  ).isRequired,
  current: number.isRequired,
  dataSource: arrayOf(shape({})).isRequired,
};

export default StageSteps;
