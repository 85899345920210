import React, { useCallback, useMemo, useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { Card } from '@cards/Card';
import { pathwayRuleType, whenType } from '../../../../../propTypes';
import SelectRulesModal from './SelectRulesModal';
import createColumns from './createColumns';

function AddRulesToStage({
  defaultFilteredValue,
  filters,
  selectedRules,
  title,
  addRules,
  removeRule,
  reorderRule,
}) {
  const [isSelectRuleVisible, setIsSelectRuleVisible] = useState(false);
  const { t } = useTranslation();

  const columns = useMemo(() => createColumns(t, selectedRules.length - 1, reorderRule), [
    t,
    selectedRules.length,
    reorderRule,
  ]);

  const dataSource = useMemo(
    () => selectedRules.map(rule => ({ ...rule, onDelete: () => removeRule(rule.id) })),
    [selectedRules, removeRule],
  );
  const toggleSelectRule = useCallback(() => setIsSelectRuleVisible(!isSelectRuleVisible), [
    isSelectRuleVisible,
  ]);

  return (
    <>
      <Card.Full
        className="staged-rules-card"
        extra={
          <>
            <Button icon="plus" type="dashed" onClick={toggleSelectRule}>
              {t('pathways:ProcedurePathways.wizard.stagedRules.addRule')}
            </Button>
            {/* <Button icon="plus" type="dashed">
              {t('pathways:ProcedurePathways.wizard.stagedRules.createARule')}
            </Button> */}
          </>
        }
        title={title}
      >
        <Table columns={columns} dataSource={dataSource} pagination={false} rowKey="id" />
      </Card.Full>
      {isSelectRuleVisible ? (
        <SelectRulesModal
          defaultFilteredValue={defaultFilteredValue}
          filters={filters}
          selectedRules={selectedRules}
          onCancel={toggleSelectRule}
          onOk={addRules}
        />
      ) : null}
    </>
  );
}

AddRulesToStage.propTypes = {
  filters: arrayOf(whenType),
  selectedRules: arrayOf(pathwayRuleType),
  title: string.isRequired,
  addRules: func.isRequired,
};

AddRulesToStage.defaultProps = {
  filters: [],
  selectedRules: [],
};

export default AddRulesToStage;
