import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const SharedPathwaysListCard = ({ sharedPathways, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleUseSharedPathway = useCallback(
    id => {
      history.push(`/procedure/shared-pathways/${id}/duplicate`);
    },
    [history],
  );

  const columns = useMemo(() => createColumns(t, handleUseSharedPathway), [
    t,
    handleUseSharedPathway,
  ]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={sharedPathways}
      filterFunc={filterFunc}
      loading={loading}
      onRowClick={row => history.push(`/procedure/shared-pathways/${row.id}`)}
    />
  );
};

export default SharedPathwaysListCard;
