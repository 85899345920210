import { PathwaysAdminClient } from '@liquid-state/pathways-client';
import { pathwaysApiRoot } from 'settings';

// TODO: update to use PathwaysAdminService
async function createPathwaysClient(jwt, appToken) {
  try {
    const client = await new PathwaysAdminClient(appToken, jwt, { baseUrl: pathwaysApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createPathwaysClient;
