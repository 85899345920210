import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import {
  getOrganisationBranding,
  createOrganisationBranding,
  updateOrganisationBranding,
  removeOrganisationImage,
} from '@redux/organisations/actions';
import { selectOrganisationBranding, selectBrandingLoading } from '@redux/organisations/reducers';
import { Input, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import ImageUpload from '@components/ImageUpload';
import InputWithHelpText from '@components/InputWithHelpText';
import './style.less';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('organisations:EditOrganisationDetails.exitTitle'),
    content: t('organisations:EditOrganisationDetails.exitContent'),
    okText: t('organisations:EditOrganisationDetails.okText'),
    cancelText: t('organisations:EditOrganisationDetails.cancelText'),
    onOk: history.goBack,
  });
};

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

function EditBranding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const branding = useSelector(selectOrganisationBranding);
  const [colour, setColour] = useState(branding?.colour || '#ffffff');
  const [tagline, setTagline] = useState(branding?.tagline || '');
  const [description, setDescription] = useState(branding?.description || '');
  const [logoList, setLogoList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [removeBanner, setRemoveBanner] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const brandingLoading = useSelector(selectBrandingLoading);
  const [isBrandingLoading, setIsBrandingLoading] = useState(brandingLoading);

  useEffect(() => {
    setIsBrandingLoading(brandingLoading);
    if (submitting && !isBrandingLoading) history.goBack();
  }, [brandingLoading, isBrandingLoading, history, submitting]);

  useEffect(() => {
    dispatch(getOrganisationBranding());
  }, [dispatch]);

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  useEffect(() => {
    if (branding) {
      setColour(branding.colour);
      setTagline(branding.tagline);
      setDescription(branding.description);
    }
  }, [branding]);

  const [, organisation] = useSelector(selectOrganisation(dashboardUserProfile.organisationId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: true,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = (organisation && organisation.name) || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle]);

  const handleSubmit = () => {
    setSubmitting(true);

    if (removeLogo) {
      dispatch(removeOrganisationImage(branding.image_logo));
    }
    if (removeBanner) {
      dispatch(removeOrganisationImage(branding.image_banner));
    }

    if (branding?.pk) {
      dispatch(
        updateOrganisationBranding(
          branding.pk,
          {
            colour,
            tagline,
            description,
            image_logo: removeLogo ? null : branding.image_logo,
            image_banner: removeBanner ? null : branding.image_banner,
          },
          logoList[0]?.originFileObj,
          bannerList[0]?.originFileObj,
        ),
      );
    } else {
      dispatch(
        createOrganisationBranding(
          { colour, tagline, description },
          logoList[0]?.originFileObj,
          bannerList[0]?.originFileObj,
        ),
      );
    }
  };

  return (
    <ColumnGroup className="edit-organisation-branding-details">
      <Column>
        <Card
          className="organisation-details-form-card"
          title={t('organisations:Branding.details.edit.title')}
        >
          <Form {...detailsLayout}>
            <Form.Item className="details-item" label={t('organisations:Branding.details.colour')}>
              <InputWithHelpText
                type="text"
                placeholder="#000000"
                value={colour}
                onChange={e => setColour(e.target.value)}
                allowClear={true}
                helpText={t('organisations:Branding.details.colourHelpText')}
              />
              <Input
                style={{ width: '25%' }}
                type="color"
                value={colour || '#ffffff'}
                onChange={e => setColour(e.target.value)}
              />
            </Form.Item>
            <Form.Item label={t('organisations:Branding.details.tagline')}>
              <InputWithHelpText
                type="text"
                value={tagline}
                onChange={e => setTagline(e.target.value)}
                allowClear={true}
                maxLength={150}
                helpText={t('organisations:Branding.details.taglineHelpText')}
              />
            </Form.Item>
            <Form.Item label={t('organisations:Branding.details.description')}>
              <InputWithHelpText
                isTextArea={true}
                rows={4}
                value={description}
                onChange={e => setDescription(e.target.value.substring(0, 32767))}
                allowClear={true}
                autoSize={true}
                helpText={t('organisations:Branding.details.descriptionHelpText')}
              />
            </Form.Item>
            <Form.Item label={t('organisations:Branding.details.logo')}>
              <ImageUpload
                imageList={logoList}
                setImageList={setLogoList}
                setRemove={setRemoveLogo}
                imageUrl={branding?.image_logo_url}
                imageSpecs={t('organisations:Branding.details.logoSpecs')}
              />
              <Text className="image-upload-help-text">
                {t('organisations:Branding.details.logoHelpText')}
              </Text>
            </Form.Item>
            <Form.Item label={t('organisations:Branding.details.banner')}>
              <ImageUpload
                imageList={bannerList}
                setImageList={setBannerList}
                setRemove={setRemoveBanner}
                imageUrl={branding?.image_banner_url}
                imageSpecs={t('organisations:Branding.details.bannerSpecs')}
              />
              <Text className="image-upload-help-text">
                {t('organisations:Branding.details.bannerHelpText')}
              </Text>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ marginRight: '10px' }}
                loading={isBrandingLoading}
                onClick={() => {
                  setIsBrandingLoading(true);
                  handleSubmit();
                }}
              >
                {t('organisations:Branding.details.edit.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default EditBranding;
