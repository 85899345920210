import React, { useEffect } from 'react';
import { ColumnGroup, Column } from '@cards/Card';
import EditDetailsCard from '@cards/AppUsers/EditDetails';
import { usePageActions } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { setCurrentScreen, screens } from '../../analytics';

const AppUserEdit = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentScreen(screens.APP_USERS_EDIT);
  }, []);

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(id));

  const { setTitle } = usePageActions({
    actions: [],
    showClose: true,
  });

  useEffect(() => {
    if (!loading && appUser) {
      setTitle(
        t('patients:EditPatient.title', {
          firstName: appUser.firstName,
          lastName: appUser.lastName,
        }),
      );
    }
  }, [setTitle, loading, appUser, t]);

  return (
    <ColumnGroup>
      <Column>
        <EditDetailsCard appUserId={id} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default AppUserEdit;
