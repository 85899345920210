import {
  PASSWORD_RESET_CODE_SUBMITTED,
  PASSWORD_RESET_PASSWORD_SUBMITTED,
  PASSWORD_RESET_EMAIL_VALID,
  PASSWORD_RESET_EMAIL_INVALID,
  PASSWORD_RESET_CODE_VALID,
  PASSWORD_RESET_COMPLETE,
  PASSWORD_RESET_CODE_INVALID,
  PASSWORD_RESET_PASSWORD_INVALID,
  PASSWORD_RESET_STARTED,
  PASSWORD_RESET_RESEND_CODE,
} from './actions';

const initialState = {
  started: false,
  loading: false,
  error: false,
  email: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_CODE_SUBMITTED:
    case PASSWORD_RESET_PASSWORD_SUBMITTED:
      return { ...state, loading: true, error: false };

    case PASSWORD_RESET_EMAIL_VALID:
    case PASSWORD_RESET_CODE_VALID:
      return { ...state, loading: false, error: false };
    case PASSWORD_RESET_COMPLETE:
      return { ...state, loading: false, error: false, started: false };
    case PASSWORD_RESET_EMAIL_INVALID:
      return { ...state, loading: false };
    case PASSWORD_RESET_CODE_INVALID:
    case PASSWORD_RESET_PASSWORD_INVALID:
      return { ...state, loading: false, error: true };

    case PASSWORD_RESET_STARTED:
      return {
        ...state,
        started: true,
        loading: true,
        email: action.payload.email,
      };

    case PASSWORD_RESET_RESEND_CODE:
      return { ...initialState };

    default:
      return state;
  }
};

export const isLoading = state => state.passwordRecovery.loading;
export const hasError = state => state.passwordRecovery.error;
