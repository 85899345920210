import { idmLogin, idmLogout, idmOrganisation } from 'settings';

export default class TokenPool {
  constructor() {
    this.user = null;
    this.tokenMap = new Map();
    this.refresh = null;
    this.registrationProfileData = null;
    this.loginProfileData = null;
    this.role = null;
    this.email = null;
    this.organisation = {};
    this.solutionConfiguration = {};
  }

  updateUser = user => {
    this.user = user;
    return this._refreshTokens();
  };

  updateRegistrationProfileData = data => {
    this.registrationProfileData = data;
  };

  get = async tokenId => {
    if (!this.refresh || this.refresh < Date.now()) {
      await this._refreshTokens();
    }
    return this.tokenMap.get(tokenId);
  };

  logout = () => {
    return this._logout();
  };

  getRootJWT = async () => {
    const session = await new Promise((resolve, reject) => {
      this.user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(session);
      });
    });
    return session.getIdToken().getJwtToken();
  };

  _refreshTokens = async () => {
    this.tokenMap = new Map();
    const session = await new Promise((resolve, reject) => {
      this.user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(session);
      });
    });

    const cache = this._getCachedTokens();
    if (cache) {
      this.tokenMap = new Map(Object.entries(cache.tokens));
      this.refresh = cache.refresh;
      this.loginProfileData = cache.profile;
      this.role = cache.role;
      this.email = cache.email;
      this.organisation = cache.organisation;
      this.solutionConfiguration = cache.solutionConfiguration;

      if (this.solutionConfiguration) return;
    }

    const rootJwt = session.getIdToken().getJwtToken();
    await this._login(rootJwt);
  };

  _login = async jwt => {
    const attributes = await new Promise((resolve, reject) => {
      this.user.getUserAttributes((err, data) => {
        if (err) {
          return reject(err);
        }
        resolve(data);
      });
    });
    const firstName = attributes.reduce(
      (acc, { Name, Value }) => (Name === 'given_name' ? Value : acc),
      'John',
    );
    const lastName = attributes.reduce(
      (acc, { Name, Value }) => (Name === 'family_name' ? Value : acc),
      'Doe',
    );
    const email = attributes.reduce((acc, { Name, Value }) => (Name === 'email' ? Value : acc), '');

    const fd = new FormData();
    fd.append('first_name', firstName);
    fd.append('last_name', lastName);
    fd.append('email', email);
    fd.append('organisation', idmOrganisation);
    if (this.registrationProfileData) {
      fd.append('profile', JSON.stringify(this.registrationProfileData));
    }

    const resp = await fetch(idmLogin, {
      method: 'POST',
      headers: { Authorization: `Bearer ${jwt}` },
      credentials: 'include',
      body: fd,
    });
    if (!resp.ok) {
      throw new Error(`Failed IDM Login ${resp.status} ${resp.statusText}`);
    }
    const respData = await resp.json();

    this.loginProfileData = respData.profile;
    this.role = respData.role;
    this.email = respData.email;
    this.organisation = { id: respData.organisation_id, slug: respData.organisation_slug };
    this.solutionConfiguration = {
      ...respData.solution.body,
      language: respData.solution.body.language ?? 'en',
    };
    const tokens = respData.tokens;

    // const tokens = await fetch(idmTokens, {
    //   credentials: 'include',
    // }).then(r => {
    //   if (!r.ok) {
    //     throw new Error(`Failed IDM Tokens ${r.status} ${r.statusText}`);
    //   }
    //   return r.json();
    // });
    this.tokenMap = new Map(Object.entries(tokens));
    this.refresh = Date.now() + 2700000;
    this._cacheTokens(
      tokens,
      this.refresh,
      this.loginProfileData,
      this.role,
      this.email,
      this.organisation,
      this.solutionConfiguration,
    );
  };

  _logout = () => {
    return fetch(idmLogout);
  };

  _getCachedTokens = () => {
    try {
      const sub = this.user.signInUserSession.idToken.payload.sub;
      const email = this.user.signInUserSession.idToken.payload.email;
      window.FS.identify(sub, { email });
    } catch (e) {
      console.error(e);
    }

    const refresh = localStorage.getItem(`${this.user.username}-refreshTime`);
    const tokens = localStorage.getItem(`${this.user.username}-tokens`);
    const profile = localStorage.getItem(`${this.user.username}-profile`) || {};
    const role = localStorage.getItem(`${this.user.username}-role`);
    const email = localStorage.getItem(`${this.user.username}-email`);
    const organisation = localStorage.getItem(`${this.user.username}-organisation`) || {};
    const config = localStorage.getItem(`${this.user.username}-solution-configuration`) || {};
    const apiKeys = sessionStorage.getItem(`${this.user.username}-api-keys` || {});

    if (!refresh || Number.parseInt(refresh) < Date.now()) {
      return undefined;
    } else if (!tokens) {
      return undefined;
    }
    try {
      if (!apiKeys) throw new Error('API Keys not in session storage');
      const solutionConfiguration = { ...JSON.parse(config), ...JSON.parse(apiKeys) };

      return {
        tokens: JSON.parse(tokens),
        refresh,
        profile: JSON.parse(profile),
        role,
        email,
        organisation: JSON.parse(organisation),
        solutionConfiguration,
      };
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  _cacheTokens = (tokens, refresh, profile, role, email, organisation, solutionConfiguration) => {
    localStorage.setItem(`${this.user.username}-refreshTime`, refresh);
    localStorage.setItem(`${this.user.username}-tokens`, JSON.stringify(tokens));
    localStorage.setItem(`${this.user.username}-profile`, JSON.stringify(profile));
    localStorage.setItem(`${this.user.username}-role`, role);
    localStorage.setItem(`${this.user.username}-email`, email);
    localStorage.setItem(`${this.user.username}-organisation`, JSON.stringify(organisation));
    const { ce_api_key, ubiquity_api_key, ...config } = solutionConfiguration;
    localStorage.setItem(`${this.user.username}-solution-configuration`, JSON.stringify(config));
    sessionStorage.setItem(
      `${this.user.username}-api-keys`,
      JSON.stringify({ ce_api_key, ubiquity_api_key }),
    );
  };
}
