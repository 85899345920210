import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MyProfileContent from './MyProfileContent';
import './style.less';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={MyProfileContent} />
  </Switch>
);
