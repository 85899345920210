import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from '../../cards/Card';
import { usePageActions, useTitleUpdate } from '../../hooks';
import FormListCard from '../../cards/Forms/FormList';
import NewContentDropdown from '../../components/NewContentDropdown';
import { setCurrentScreen, screens } from '../../analytics';

export default ({ match, formTranslationKey, type }) => {
  useEffect(() => {
    const screenType = `CONTENT_${type.toUpperCase()}S_LIST`;
    setCurrentScreen(screens[screenType]);
  }, [type]);

  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t(`${formTranslationKey}:FormList.header`),
    actions: [<NewContentDropdown key="new-content" primaryKey={type} />],
  });
  useTitleUpdate(setTitle, `${formTranslationKey}:FormList.header`);
  document.title = t(`${formTranslationKey}:FormList.header`);

  return (
    <Column>
      <FormListCard match={match} formTranslationKey={formTranslationKey} />
    </Column>
  );
};
