import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ColumnGroup, Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import OrganisationDetailForm from '@cards/Organisations/OrganisationDetailForm';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('organisations:EditOrganisationDetails.exitTitle'),
    content: t('organisations:EditOrganisationDetails.exitContent'),
    okText: t('organisations:EditOrganisationDetails.okText'),
    cancelText: t('organisations:EditOrganisationDetails.cancelText'),
    onOk: history.goBack,
  });
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  const [, organisation] = useSelector(selectOrganisation(dashboardUserProfile.organisationId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: true,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = organisation?.updated_organisation_name || organisation?.organisation_name || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <OrganisationDetailForm id={dashboardUserProfile.organisationId} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};
