import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import DashboardUserTypeIcon from '@components/DashboardUserTypeIcon';
import './style.less';

function UserTypeCard({ loading, userType }) {
  const { t } = useTranslation();
  return (
    <Card
      className="dashboard-users-user-type"
      loading={loading}
      title={t('cards:DashboardUsersUserType.title')}
    >
      <DetailForm>
        <DetailRow
          label={t('cards:DashboardUsersUserType.title')}
          value={<DashboardUserTypeIcon userType={userType} />}
        />
      </DetailForm>
    </Card>
  );
}

UserTypeCard.propTypes = {
  loading: bool.isRequired,
  userType: string,
};

UserTypeCard.defaultProps = {
  userType: undefined,
};

export default UserTypeCard;
