import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Icon, Modal } from 'antd';
import { Card, Column, ColumnGroup } from '@cards/Card';
import { submitGDPRRequestForCurrentUser } from '@redux/requests/actions';
import { isRequestSubmitting } from '@redux/requests/reducer';
import { GDPR_DELETE_REQUEST } from '@constants';

function GDPRDeletionRequest() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSubmitting = useSelector(isRequestSubmitting);

  const onDeleteClick = useCallback(() => {
    Modal.confirm({
      cancelText: t('myProfile:DataDeletion.modal.cancel'),
      content: t('myProfile:DataDeletion.modal.body'),
      icon: <Icon type="info-circle" theme="filled" />,
      okText: t('myProfile:DataDeletion.modal.ok'),
      okType: 'danger',
      title: t('myProfile:DataDeletion.modal.title'),
      onOk: () => {
        dispatch(submitGDPRRequestForCurrentUser(GDPR_DELETE_REQUEST));
      },
    });
  }, [dispatch, t]);

  return (
    <ColumnGroup>
      <Column>
        <Card className="gdpr-delete-personal-data" title={t('myProfile:DataDeletion.title')}>
          <Alert
            description={t('myProfile:DataDeletion.explain')}
            message={t('myProfile:DataDeletion.alertTitle')}
            showIcon
            type="warning"
          />
          <Button block loading={isSubmitting} type="danger" onClick={onDeleteClick}>
            {t('myProfile:DataDeletion.submit')}
          </Button>
        </Card>
      </Column>
      <Column />
    </ColumnGroup>
  );
}

export default GDPRDeletionRequest;
