import ContentRef from '@utils/contentRef';
import { IContentMessage, IRawContentMessage } from './types';
import { MESSAGE } from '@utils/contentTypes';

export function mapRawContentMessageToContentMessage(
  rawMessage: IRawContentMessage,
  organisationId: string,
): IContentMessage | undefined {
  const contentRef = new ContentRef(
    organisationId,
    'message',
    rawMessage.uuid,
    rawMessage.latest_version?.number.toString(),
  );
  if (!rawMessage.latest_version) {
    return undefined;
  }
  return {
    body: '',
    created: rawMessage.created,
    id: rawMessage.uuid,
    language: rawMessage.latest_version?.metadata?.language || 'en',
    latestVersion: rawMessage.latest_version,
    metadata: {
      type: MESSAGE,
    },
    name: rawMessage.name,
    payload: rawMessage.latest_version?.payload,
    published: rawMessage.is_available,
    uuid: rawMessage.uuid,
    url: rawMessage.url,
    version: 1,
    content: contentRef.toString(),
  };
}
