import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';

function Content() {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <fieldset>
          <legend>{t('common:Wizard.details.video')}</legend>
          <Form.Item label={t('common:Wizard.details.title.videoLabel')}>
            {form.getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: t('common:Wizard.details.title.required'),
                },
              ],
              initialValue: currentStepData.title || '',
            })(<Input placeholder="Title on page" />)}
          </Form.Item>
          <Form.Item label={t('common:Wizard.details.description.label')}>
            {form.getFieldDecorator('videoDescription', {
              initialValue: currentStepData.videoDescription || '',
            })(
              <Input.TextArea
                autoSize
                placeholder={t('common:Wizard.details.description.placeholder')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('common:Wizard.details.link.label')}>
            {form.getFieldDecorator('link', {
              rules: [
                {
                  required: true,
                  message: t('common:Wizard.details.link.required'),
                },
                {
                  pattern: /^https:\/\/(www\.youtube.com|youtu\.be)\/*/,
                  message: t('common:Wizard.details.link.invalidURL'),
                },
              ],
              initialValue: currentStepData.link || '',
            })(<Input placeholder={t('common:Wizard.details.link.placeholder')} />)}
          </Form.Item>
          <Form.Item label={t('videos:Wizard.details.publishDate')}>
            {form.getFieldDecorator('publishDate', {
              rules: [],
              initialValue: currentStepData.publishDate || '',
            })(<Input placeholder={t('videos:Wizard.details.optional')} />)}
          </Form.Item>
          <Form.Item label={t('videos:Wizard.details.runTime')}>
            {form.getFieldDecorator('runTime', {
              rules: [],
              initialValue: currentStepData.runTime || '',
            })(<Input placeholder={t('videos:Wizard.details.optional')} />)}
          </Form.Item>
        </fieldset>
      </Form>
    </Card.Half>
  );
}

export default Content;
