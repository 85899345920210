import React from 'react';
import { ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import ContentCard from './ContentCard';
import AttachedContent from '@cards/Messages/AttachedContent';
import PreviewCard from '@cards/Messages/Preview';
import { OPEN_CONTENT } from '@constants';
import './styles.less';

const MessageDetailStep = () => {
  const { currentStepData, form, formData } = useWizard();
  const formFields = form.getFieldsValue();
  const { contentTitle } = currentStepData;

  const previewData =
    formFields.content === undefined
      ? {
          action: currentStepData.action,
          content: currentStepData.content,
          title: formData.details.name,
          websiteLink: currentStepData.websiteLink,
        }
      : {
          action: formFields.action,
          content: formFields.content,
          title: formData.details.name,
          websiteLink: formFields.websiteLink,
        };

  return (
    <ColumnGroup>
      <Column>
        <ContentCard />
        {previewData.action === OPEN_CONTENT && (
          <AttachedContent content={currentStepData.contentData} />
        )}
      </Column>
      <Column>
        <PreviewCard
          action={previewData.action}
          content={previewData.content}
          title={previewData.title}
          websiteLink={previewData.websiteLink}
          contentTitle={contentTitle}
        />
      </Column>
    </ColumnGroup>
  );
};

export default MessageDetailStep;
