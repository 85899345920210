import React, { useState, useEffect, useMemo, cloneElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Pagination } from 'antd';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { fetchAppUserDailySteps } from '@redux/appUsers/actions';
import { selectAppUserDailySteps } from '@redux/appUsers/reducers';
import { historyType, matchType } from '../../propTypes';

import './style.less';

const filterFunc = (value, comparator) => comparator(value.content_name);

function AppUserHealthData({ history, match }) {
  const appUserId = match.params.id;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dailySteps, loading } = useSelector(selectAppUserDailySteps(appUserId));
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchAppUserDailySteps());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && dailySteps?.length < 50) {
      setHasMoreItems(false);
    }
  }, [loading, dailySteps]);

  const createColumns = t => [
    {
      title: t('patients:Engagements.when'),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: time => moment(time).format('L'),
    },
    {
      title: t('patients:HealthData.dailySteps.value'),
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => parseInt(a.value, 10) - parseInt(b.value, 10),
      render: value => {
        return <span class="text-center">{value}</span>;
      },
      // render: contentType => {
      //   return (
      //     <>
      //       <FontAwesomeIcon
      //         size="lg"
      //         icon={selectContentTypeIcon(
      //           contentType.toUpperCase().endsWith('S')
      //             ? contentType.toUpperCase().slice(0, -1)
      //             : contentType.toUpperCase(),
      //         )}
      //       />{' '}
      //       {t(
      //         `common:contentType.${
      //           contentType.toUpperCase().endsWith('S')
      //             ? contentType.toUpperCase().slice(0, -1)
      //             : contentType.toUpperCase()
      //         }`,
      //       )}
      //     </>
      //   );
      // },
    },
  ];

  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchAppUserDailySteps(appUserId, 1));
  }, [dispatch, appUserId]);

  const handleChangePage = page => {
    setPage(page);
    dispatch(fetchAppUserDailySteps(appUserId, page));
  };

  return (
    <ColumnGroup className="app-user-engagements">
      <Column>
        <div>
          <Card noPadding className="table-card">
            <div class="header-container">
              <h3>{t('patients:HealthData.dailySteps.title')}</h3>
            </div>
            <Table
              columns={columns}
              dataSource={dailySteps}
              loading={loading}
              // onRowClick={onRowClick}
              rowKey="date"
              filterFunc={filterFunc}
              pagination={false}
            />
          </Card>
          <Card className="pagination-card">
            <Pagination
              current={page}
              onChange={handleChangePage}
              total={50}
              itemRender={(_, type, originalElement) => {
                if (type === 'next' && !hasMoreItems) {
                  return cloneElement(originalElement, {
                    className: 'next-button-disabled ant-pagination-next ant-pagination-item-link',
                    onClick: event => event.preventDefault(),
                  });
                }
                return originalElement;
              }}
            />
          </Card>
        </div>
      </Column>
    </ColumnGroup>
  );
}

AppUserHealthData.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default AppUserHealthData;
