import React from 'react';
import { ColumnGroup, Column } from '@cards/Card';
import DetailsCard from './Card';

function Details({ children }) {
  return (
    <ColumnGroup>
      <Column>
        <DetailsCard>{children}</DetailsCard>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

export default Details;
