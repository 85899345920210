import i18n from '../i18n';

// for use with rc-form validators
const validatePasswordComplexity = (rule, value, callback) => {
  if (!value) {
    callback();
    return;
  }
  if (value.length < 8) {
    callback(
      i18n.t('registration:PasswordRegistration.newPassword.validation.insufficientLengthError'),
    );
    return;
  }
  // These should be extended with Unicode character classes.
  const passwordTest = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]).{8,}$/;
  const valid = passwordTest.test(value);
  if (!valid) {
    callback(callback(i18n.t('common:validation.Password.insufficientComplexity')));
    return;
  }
  callback();
};

export default validatePasswordComplexity;
