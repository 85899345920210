import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import { Button } from 'antd';

import './styles.less';

const EveregCard = ({ title, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card.Half className="evereg-card" loading={loading} title={t(title)} {...props}>
      <p>{t('cards:PatientEvereg.description.intro')}</p>
      <p>{t('cards:PatientEvereg.description.tracking')}</p>
      <p>{t('cards:PatientEvereg.description.submitted')}</p>
      <p>{t('cards:PatientEvereg.description.review')}</p>
      <div className="buttons">
        <a href="https://www.evereg.es/" target="_blank" rel="noopener noreferrer">
          <Button type="primary">{t('cards:PatientEvereg.link')}</Button>
        </a>
      </div>
    </Card.Half>
  );
};

EveregCard.propTypes = {
  title: string,
};

EveregCard.defaultProps = {
  title: 'cards:PatientEvereg.title',
};

export default EveregCard;
