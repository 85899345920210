import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import Login2FA from './LoginAuthentication';

const LoginIndex = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={Login} />
      <Route path="/auth/login/verify" component={Login2FA} />
    </Switch>
  );
};

export default LoginIndex;
