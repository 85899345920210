import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import SelectIndexEventModal from './SelectIndexEventModal';

function IndexEvents() {
  const { i18n, t } = useTranslation();
  const [isSelectIndexEventVisible, setIsSelectIndexEventVisible] = useState(false);
  const [orderedIndexEvents, setOrderedIndexEvents] = useState([]);
  const { currentStepData, form } = useWizard();

  useEffect(() => {
    const { indexEventTypes } = currentStepData;
    if (indexEventTypes) {
      setOrderedIndexEvents(indexEventTypes);
      form.setFieldsValue({ indexEventTypes });
    } else {
      form.setFieldsValue({ indexEventTypes: [] });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleSelectIndexEvent = useCallback(
    () => setIsSelectIndexEventVisible(!isSelectIndexEventVisible),
    [isSelectIndexEventVisible],
  );

  const onOk = useCallback(
    selected => {
      setOrderedIndexEvents(selected);
      form.setFieldsValue({ indexEventTypes: selected });
      toggleSelectIndexEvent();
    },
    [form, toggleSelectIndexEvent],
  );

  const columns = useMemo(
    () => [
      {
        title: t('pathways:ProcedurePathways.wizard.indexEvents.columns.order'),
        key: 'order',
        render: (text, record, index) => `${index + 1}.`,
      },
      {
        title: t('pathways:ProcedurePathways.wizard.indexEvents.columns.event'),
        key: 'name',
        render: record => record.translatedNames[i18n.language] || record.name,
      },
    ],
    [i18n, t],
  );

  return (
    <>
      <Card.Half
        className="pathway-index-events-ordering"
        extra={
          <Button icon="plus" type="dashed" onClick={toggleSelectIndexEvent}>
            {t('pathways:ProcedurePathways.wizard.indexEvents.addIndexEvent')}
          </Button>
        }
        title={t('pathways:ProcedurePathways.wizard.indexEvents.header')}
      >
        {form.getFieldDecorator('indexEventTypes')(<span />)}
        {form.getFieldError('indexEventTypes') && (
          <p className="form-error-message">
            {t('pathways:ProcedurePathways.wizard.indexEvents.indexEventsError')}
          </p>
        )}
        <Table columns={columns} dataSource={orderedIndexEvents} pagination={false} rowKey="id" />
      </Card.Half>
      {isSelectIndexEventVisible ? (
        <SelectIndexEventModal
          selectedIndexEvents={orderedIndexEvents}
          onCancel={toggleSelectIndexEvent}
          onOk={onOk}
        />
      ) : null}
    </>
  );
}

export default IndexEvents;
