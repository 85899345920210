import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import i18n from '../../i18n';
import doCreatePEPHealthService from '@redux/doCreatePEPHealthService';
import { PEPHealthService } from '@liquid-state/pephealth-client';
import { IntegrationOrgConfig } from '@liquid-state/pephealth-client/dist/types';
import {
  ILoadOrgConfig,
  loadOrgConfigSuccess,
  loadOrgConfigFailure,
  IEditOrgConfig,
  createOrgConfigSuccess,
  createOrgConfigFailure,
  editOrgConfigSuccess,
  editOrgConfigFailure,
} from './actions';
import { DEFAULT_API_KEY_MASKING_VALUE } from '../constants';

export default function*() {
  yield takeEvery<any>('integrations/load-org-config', doLoadOrgConfig);
  yield takeEvery<any>('integrations/create-org-config', doCreateOrgConfig);
  yield takeEvery<any>('integrations/edit-org-config', doEditOrgConfig);
}

function maskAPIKeyInOrgConfigData(orgConfigData: { api_key?: string }) {
  if (orgConfigData && orgConfigData['api_key']) {
    return { ...orgConfigData, api_key: DEFAULT_API_KEY_MASKING_VALUE };
  }
  return orgConfigData;
}

function cleanOrgConfigUpdateData(orgConfigData: any) {
  const result = { ...orgConfigData };
  if (
    orgConfigData &&
    (orgConfigData['api_key'] as string) &&
    orgConfigData['api_key'] === DEFAULT_API_KEY_MASKING_VALUE
  ) {
    delete result['api_key'];
  }
  return result;
}

function* doLoadOrgConfig({ payload: { integrationSlug } }: ILoadOrgConfig): any {
  try {
    const service: PEPHealthService = yield call(doCreatePEPHealthService);
    const result: IntegrationOrgConfig = yield call(
      service.getIntegrationOrgConfig,
      integrationSlug,
    );
    if (result && result.organisationId) {
      const finalResult = { ...result, data: maskAPIKeyInOrgConfigData(result.data) };
      console.log('org config: ', finalResult);
      yield put(loadOrgConfigSuccess(integrationSlug, finalResult));
      // yield call(message.success, i18n.t('kemtai:organisationConfig.loadOrgConfig'));
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(loadOrgConfigFailure());
    if (err.response.status !== 404) {
      // const data = yield call(err.response.json.bind(err.response));
      yield call(message.error, i18n.t('integrations:organisationConfig.loadingError'));
    }
  }
}

function* doCreateOrgConfig({ payload: { integrationSlug, configData } }: IEditOrgConfig): any {
  try {
    const service: PEPHealthService = yield call(doCreatePEPHealthService);

    const data = cleanOrgConfigUpdateData(configData);
    const result: IntegrationOrgConfig = yield call(
      service.createIntegrationOrgConfig,
      integrationSlug,
      data,
    );
    if (result) {
      const finalResult = { ...result, data: maskAPIKeyInOrgConfigData(result.data) };
      console.log('org config: ', finalResult);
      yield put(createOrgConfigSuccess(integrationSlug, finalResult));
      yield call(message.success, i18n.t('integrations:organisationConfig.createSuccess'));
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(createOrgConfigFailure());
    // const data = yield call(err.response.json.bind(err.response));
    yield call(message.error, i18n.t('integrations:organisationConfig.createError'));
  }
}

function* doEditOrgConfig({
  payload: { integrationSlug, isEnabled, configData },
}: IEditOrgConfig): any {
  try {
    const service: PEPHealthService = yield call(doCreatePEPHealthService);

    const data = cleanOrgConfigUpdateData(configData);
    const result: IntegrationOrgConfig = yield call(
      service.updateIntegrationOrgConfig,
      integrationSlug,
      isEnabled,
      data,
    );
    if (result) {
      const finalResult = { ...result, data: maskAPIKeyInOrgConfigData(result.data) };
      console.log('org config: ', finalResult);
      yield put(editOrgConfigSuccess(integrationSlug, finalResult));
      yield call(message.success, i18n.t('integrations:organisationConfig.editSuccess'));
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(editOrgConfigFailure());
    // const data = yield call(err.response.json.bind(err.response));
    yield call(message.error, i18n.t('integrations:organisationConfig.editError'));
  }
}
