import React from 'react';
import { Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import './style.less';

function ColourHexAndPreview({ hex }) {
  return (
    <div className="colour-hex-and-preview">
      {hex ? (
        <>
          <Text>{hex}</Text>
          <Input type="color" value={hex} disabled />
        </>
      ) : null}
    </div>
  );
}

export default ColourHexAndPreview;
