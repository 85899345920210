import { IForm } from './types';

export type IFetchForms = ReturnType<typeof fetchForms>;
export const fetchForms = () => ({
  type: 'forms/fetch' as const,
  payload: {},
});

export type ILoadForms = ReturnType<typeof loadForms>;
export const loadForms = () => ({
  type: 'forms/load' as const,
  payload: {},
});

export type IFormsLoaded = ReturnType<typeof formsLoaded>;
export const formsLoaded = (forms: IForm[]) => ({
  type: 'forms/loaded' as const,
  payload: {
    forms,
  },
});

export type IFetchVersionFormData = ReturnType<typeof fetchVersionFormData>;
export const fetchVersionFormData = (
  formId: string,
  dataVersion: number,
  schemaVersion: number,
  appUserUISId: string,
) => ({
  type: 'forms/fetch-version' as const,
  payload: {
    formId,
    schemaVersion,
    dataVersion,
    appUserUISId,
  },
});

export type IFetchVersionFormDataSuccess = ReturnType<typeof fetchVersionFormDataSuccess>;
export const fetchVersionFormDataSuccess = (formId: string, dataVersion: number, data: any) => ({
  type: 'forms/fetch-version-success' as const,
  payload: {
    formId,
    dataVersion,
    data,
  },
});

export type IFetchVersionFormDataFailed = ReturnType<typeof fetchVersionFormDataFailed>;
export const fetchVersionFormDataFailed = (formId: string, dataVersion: number, error: string) => ({
  type: 'forms/fetch-version-failed' as const,
  payload: {
    formId,
    dataVersion,
    error,
  },
});

export type IDownloadForm = ReturnType<typeof downloadForm>;
export const downloadForm = (fileName: string, formData: object) => ({
  type: 'forms/download' as const,
  payload: { fileName, formData },
});

export type IDownloadFormData = ReturnType<typeof downloadFormData>;
export const downloadFormData = (formId: string) => ({
  type: 'forms/datadownload' as const,
  payload: { formId },
});

export type IDownloadFormDataForAppUser = ReturnType<typeof downloadFormDataForAppUser>;
export const downloadFormDataForAppUser = (formId: string, appUserId: string) => ({
  type: 'forms/appuserdatadownload' as const,
  payload: { formId, appUserId },
});

export type IPublishForm = ReturnType<typeof publishForm>;
export const publishForm = (formId: string, formTranslationKey: string) => ({
  type: 'forms/publish' as const,
  payload: {
    formId,
    formTranslationKey,
  },
});

export type IPublishFormSuccess = ReturnType<typeof publishFormSuccess>;
export const publishFormSuccess = (formId: string) => ({
  type: 'forms/publish-success' as const,
  payload: { formId },
});

export type IPublishFormFailed = ReturnType<typeof publishFormFailed>;
export const publishFormFailed = () => ({
  type: 'forms/publish-failed' as const,
  payload: {},
});

export type IUnpublishForm = ReturnType<typeof unpublishForm>;
export const unpublishForm = (formId: string, formTranslationKey: string) => ({
  type: 'forms/unpublish' as const,
  payload: {
    formId,
    formTranslationKey,
  },
});

export type IUnpublishFormSuccess = ReturnType<typeof unpublishFormSuccess>;
export const unpublishFormSuccess = (formId: string) => ({
  type: 'forms/unpublish-success' as const,
  payload: { formId },
});

export type IUnpublishFormFailed = ReturnType<typeof unpublishFormFailed>;
export const unpublishFormFailed = () => ({
  type: 'forms/unpublish-failed' as const,
  payload: {},
});

export type ICreateForm = ReturnType<typeof createForm>;
export const createForm = (
  formTranslationKey: string,
  name: string,
  description: string,
  language: string,
  published: boolean,
  formData: { schema: object; uiSchema: object; computations?: object },
  type: string,
  path: string,
  fileName: string,
  audience?: string,
) => ({
  type: 'forms/create' as const,
  payload: {
    formTranslationKey,
    name,
    description,
    language,
    published,
    formData,
    type,
    path,
    fileName,
    audience,
  },
});

export type IFormCreated = ReturnType<typeof formCreated>;
export const formCreated = (form: IForm) => ({
  type: 'forms/created' as const,
  payload: { form },
});

export type IUpdateForm = ReturnType<typeof updateForm>;
export const updateForm = (
  formTranslationKey: string,
  uuid: string,
  name: string,
  description: string,
  language: string,
  published: boolean,
  formData: { schema: object; uiSchema: object; computations?: object },
  type: string,
  fileName: string,
  audience?: string,
) => ({
  type: 'forms/update' as const,
  payload: {
    formTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    formData,
    type,
    fileName,
    audience,
  },
});

export type IFormUpdated = ReturnType<typeof formUpdated>;
export const formUpdated = (updatedForm: IForm) => ({
  type: 'forms/updated' as const,
  payload: { updatedForm },
});

export type IDeleteForms = ReturnType<typeof deleteForms>;
export const deleteForms = (ids: string[], formTranslationKey: string) => ({
  type: 'forms/delete' as const,
  payload: { ids, formTranslationKey },
});

export type IFormsDeleted = ReturnType<typeof formsDeleted>;
export const formsDeleted = (ids: string[]) => ({
  type: 'forms/deleted' as const,
  payload: { ids },
});

export type IRemoveFormData = ReturnType<typeof removeFormData>;
export const removeFormData = (formId: string) => ({
  type: 'forms/remove-data' as const,
  payload: { formId },
});

export type ITemporaryFormsLoaded = ReturnType<typeof temporaryFormsLoaded>;
export const temporaryFormsLoaded = (forms: IForm[]) => ({
  type: 'forms/temporary-loaded' as const,
  payload: { forms },
});
