import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import HeaderButton from '../HeaderButton';

const EditButton = ({ tooltip, ...props }) => {
  const { t } = useTranslation();
  return (
    <HeaderButton tooltip={tooltip} {...props}>
      <FontAwesomeIcon icon={faEdit} />
      <span>{t('common:buttons.edit')}</span>
    </HeaderButton>
  );
};

export default EditButton;
