/**
 * trims a string to the next space character after the specified length
 * arguments ("abc 123 def", 5) returns "abc 123..."
 */
function trimTextToApproximateLength(string, length = 50) {
  if (typeof string !== 'string') return '';

  if (string.length > length) {
    return `${string.slice(0, string.slice(length).search(/\s/) + length)}...`;
  }

  return string;
}

export default trimTextToApproximateLength;
