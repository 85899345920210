import { createUniqueArray } from '@utils';
import { LOAD_GROUPS, GROUPS_LOADED, ADD_USERS_TO_GROUPS } from './actions';

const initialState = {
  loading: false,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case GROUPS_LOADED:
      return {
        ...state,
        loading: false,
        list: action.payload.groups,
      };
    case ADD_USERS_TO_GROUPS:
      return {
        ...state,
        list: state.list.map(group =>
          action.payload.groupIds.includes(group.id)
            ? {
                ...group,
                users: createUniqueArray([...group.users, ...action.payload.userIds]),
              }
            : group,
        ),
      };
    default:
      return state;
  }
};

export const selectGroups = state => [state.groups.loading, state.groups.list];

export const selectGroupsByIds = groupIds => state => [
  state.groups.loading,
  state.groups.list.filter(group => groupIds.includes(group.id)),
];

export const selectGroup = groupId => state => [
  state.groups.loading,
  state.groups.list.find(({ id }) => `${id}` === `${groupId}`),
];

export const selectGroupMembers = groupId => state => {
  const group = state.groups.list.find(({ id }) => `${id}` === `${groupId}`) || { users: [] };
  return [
    state.groups.loading || state.appUsers.loading,
    group.users.reduce((prev, id) => {
      if (state.appUsers.data.byId[id]) {
        return [...prev, state.appUsers.data.byId[id]];
      }
      return prev;
    }, []),
  ];
};
