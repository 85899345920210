import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';

function SelectStageModal({ selectedStage, onCancel, onOk, stages }) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);
  const [sortedStages, setSortedStages] = useState([]);

  useEffect(() => {
    setSortedStages(stages?.sort((a, b) => a.number - b.number));
  }, [stages]);

  useEffect(() => {
    if (selectedStage) {
      setSelected(selectedStage);
    }
  }, [selectedStage]);

  return (
    <Modal
      className="choose-content-modal"
      title={t('pathways:ProcedurePathways.engagementCheckWizard.selectStageModal.columns.title')}
      visible
      width={800}
      onCancel={onCancel}
      onOk={() => onOk(selected)}
    >
      <Table
        columns={[
          {
            title: t(
              'pathways:ProcedurePathways.engagementCheckWizard.selectStageModal.columns.name',
            ),
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ellipsis: true,
            sorter: (a, b) => a.name > b.name,
            render: (_, record) => record.name,
          },
        ]}
        dataSource={sortedStages}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected ? [selected.id] : [],
          type: 'radio',
          onSelect: record => setSelected(record),
        }}
      />
    </Modal>
  );
}

export default SelectStageModal;
