import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Select } from 'antd';
import moment from 'moment';

function SelectRepeatStops({ value, onChange }, ref) {
  const { t } = useTranslation();
  const [repeat, setRepeat] = useState({ stop: '', stopDate: value?.stopDate || undefined });

  const onStopChange = useCallback(
    stop => {
      const updatedRepeat = { ...repeat, stop };
      setRepeat(updatedRepeat);
      onChange(updatedRepeat);
    },
    [repeat, onChange],
  );
  const onDateChange = useCallback(
    dateMoment => {
      const updatedRepeat = { ...repeat, stopDate: dateMoment.toISOString() };
      setRepeat(updatedRepeat);
      onChange(updatedRepeat);
    },
    [repeat, onChange],
  );

  useEffect(() => {
    if (value) {
      setRepeat(value);
    }
  }, [value]);

  return (
    <div className="select-repeat-stops" ref={ref}>
      <Select value={repeat.stop} onChange={onStopChange}>
        <Select.Option value="never">{t('messages:Wizard.timing.repeatStops.never')}</Select.Option>
        <Select.Option value="on-date">
          {t('messages:Wizard.timing.repeatStops.onDate')}
        </Select.Option>
      </Select>
      {repeat.stop === 'on-date' ? (
        <DatePicker
          allowClear={false}
          format="ll"
          value={moment(repeat.stopDate)}
          onChange={onDateChange}
        />
      ) : null}
    </div>
  );
}

export default forwardRef(SelectRepeatStops);
