import { INDEX_EVENT, INDEX_EVENT_DELAY, MANUAL, STAGE_TRANSITION } from '@constants';

function assignRealWhenType(when) {
  switch (when) {
    case INDEX_EVENT_DELAY:
      return INDEX_EVENT;
    case MANUAL:
      return STAGE_TRANSITION;
    default:
      return when;
  }
}

export default assignRealWhenType;
