import { combineReducers } from 'redux';
import {
  ILoadConnectionCodes,
  ILoadConnectionCodesSucceeded,
  ILoadConnectionCodesFailed,
  ICreateConnectionCodeSuccess,
  IUpdateConnectionCodePathways,
  IUpdateConnectionCodePathwaysSuccess,
  IUpdateConnectionCodePathwaysFailed,
  ISetConnectionCodeAsDefault,
  ISetConnectionCodeAsDefaultSuccess,
  ISetConnectionCodeAsDefaultFailed,
  IToggleConnectionCodeIsDisabled,
  IToggleConnectionCodeIsDisabledSuccess,
  IToggleConnectionCodeIsDisabledFailed,
} from './actions';
import { ConnectionCode } from './types';
import { IState } from '../reducer';

function loading(
  state = false,
  action:
    | ILoadConnectionCodes
    | ILoadConnectionCodesSucceeded
    | ILoadConnectionCodesFailed
    | IUpdateConnectionCodePathways
    | IUpdateConnectionCodePathwaysSuccess
    | IUpdateConnectionCodePathwaysFailed
    | ISetConnectionCodeAsDefault
    | ISetConnectionCodeAsDefaultSuccess
    | ISetConnectionCodeAsDefaultFailed
    | IToggleConnectionCodeIsDisabled
    | IToggleConnectionCodeIsDisabledSuccess
    | IToggleConnectionCodeIsDisabledFailed,
): boolean {
  switch (action.type) {
    case 'connectionCodes/load':
    case 'connectionCodes/updatePathways':
    case 'connectionCodes/setDefault':
    case 'connectionCodes/toggleDisabled':
      return true;
    case 'connectionCodes/loadSucceeded':
    case 'connectionCodes/loadFailed':
    case 'connectionCodes/updatePathwaysSucceeded':
    case 'connectionCodes/setDefaultSucceeded':
    case 'connectionCodes/setDefaultFailed':
    case 'connectionCodes/toggleDisabledSucceeded':
    case 'connectionCodes/toggleDisabledFailed':
      return false;
    default:
      return state;
  }
}

function list(
  state: number[] = [],
  action: ILoadConnectionCodesSucceeded | ICreateConnectionCodeSuccess,
): number[] {
  switch (action.type) {
    case 'connectionCodes/loadSucceeded':
      return action.payload.connectionCodes.map(({ id }) => id);
    case 'connectionCodes/createSucceeded':
      return [action.payload.connectionCode.id, ...state];
    default:
      return state;
  }
}

function byId(
  state: { [key: number]: ConnectionCode } = {},
  action:
    | ILoadConnectionCodesSucceeded
    | ICreateConnectionCodeSuccess
    | IUpdateConnectionCodePathwaysSuccess
    | ISetConnectionCodeAsDefaultSuccess
    | IToggleConnectionCodeIsDisabledSuccess,
): { [key: number]: ConnectionCode } {
  switch (action.type) {
    case 'connectionCodes/loadSucceeded':
      return action.payload.connectionCodes.reduce(
        (codesById, code) => ({ ...codesById, [code.id]: code }),
        {},
      );
    case 'connectionCodes/createSucceeded':
      return {
        ...state,
        [action.payload.connectionCode.id]: action.payload.connectionCode,
      };
    case 'connectionCodes/updatePathwaysSucceeded':
    case 'connectionCodes/toggleDisabledSucceeded':
      return {
        ...state,
        [action.payload.connectionCode.id]: action.payload.connectionCode,
      };
    case 'connectionCodes/setDefaultSucceeded':
      // turn array of modified codes into an object of objects with id as keys then spread it on top of the existing state
      return {
        ...state,
        ...action.payload.modifiedConnectionCodes.reduce(
          (ccs, modCC) => ({ ...ccs, [modCC.id]: modCC }),
          {},
        ),
      };
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  list,
  byId,
});

export const selectConnectionCodes = (state: IState) => [
  state.connectionCodes.loading,
  state.connectionCodes.list.map(id => state.connectionCodes.byId[id]),
];

export const selectConnectionCodeById = (id: number) => (
  state: IState,
): [boolean, ConnectionCode | undefined] => [
  state.connectionCodes.loading,
  state.connectionCodes.byId[id],
];
