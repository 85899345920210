import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, ColumnGroup, Column } from '@cards/Card';
import { Form, Button, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import { fetchContentMessages } from '@redux/contentMessages/actions';
import { contentTypesList } from '@utils/contentTypes';
import useWizard from '@components/Wizard/useWizard';
import SelectContentModal from '@components/SelectContentModal';
import Text from 'antd/lib/typography/Text';

import './style.less';

function ContentItems({ pathway }) {
  const { i18n, t } = useTranslation();
  const { setCurrentStepData, formData, form } = useWizard();
  const [rules, setRules] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [isSelectRuleVisible, setIsSelectRuleVisible] = useState(false);
  const [contentFromRules, setContentFromRules] = useState([]);
  const language = i18n.languages?.[1];

  useEffect(() => {
    if (pathway?.stages?.length > 0) setRules(pathway.stages.flatMap(stage => stage.rules));
  }, [pathway]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (rules.length === 0 && !pathway) {
      dispatch(fetchDocuments());
      dispatch(fetchForms());
      dispatch(fetchContentMessages());
      dispatch(fetchPathways());
    }
  }, [dispatch, rules, pathway]);

  useEffect(() => {
    if (rules.length > 0) {
      const contentFromRules = rules
        .map(rule => {
          return { ...rule.whatDetail, what: rule.what };
        })
        .filter(
          rule =>
            rule.audience_type === 'app-user' ||
            rule.audienceType === 'app-user' ||
            (!rule.audience_type && !rule.audienceType),
        );
      const uniqueTracker = {};
      const uniqueContentFromRules = [];
      contentFromRules.forEach(item => {
        const uniqueId = item.uuid || item.id;
        if (uniqueId && !uniqueTracker[uniqueId]) {
          uniqueTracker[uniqueId] = true;
          uniqueContentFromRules.push(item);
        }
      });
      setContentFromRules(uniqueContentFromRules);
    }
  }, [rules]);

  useEffect(() => {
    if (formData?.contentToCheckEngagement?.selectedContent?.length > 0) {
      setSelectedContent(
        formData.contentToCheckEngagement.selectedContent.map(sc => sc.whatDetail || sc),
      );
      form.setFieldsValue({ contentItems: formData.contentToCheckEngagement.selectedContent });
    }
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: t('pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.name'),
      dataIndex: '',
      key: 'name',
      ellipsis: true,
      render: ({ name, title }) => title || name,
      sorder: (a, b) => {
        const aTitle = a.title || a.name;
        const bTitle = b.title || b.name;
        if (aTitle > bTitle) return -1;
        if (aTitle < bTitle) return 1;
        return 0;
      },
    },
    {
      title: t('common:buttons.remove'),
      dataIndex: 'onDelete',
      key: 'remove',
      width: 150,
      render: onDelete => (
        <Button onClick={onDelete}>
          <FontAwesomeIcon icon={faMinusCircle} /> {t('common:buttons.remove')}
        </Button>
      ),
    },
  ];

  const dataSource = useMemo(
    () =>
      selectedContent.map((rule, index) => ({
        ...rule,
        onDelete: () => {
          const newRules = [...selectedContent];
          newRules.splice(index, 1);
          setSelectedContent(newRules);
          form.setFieldsValue({ contentItems: newRules });
          setCurrentStepData({
            selectedContent: newRules,
          });
        },
      })),
    [selectedContent, form, setCurrentStepData],
  );

  const handleSelectContent = (_, selected) => {
    setSelectedContent(selected);
    form.setFieldsValue({ contentItems: selected });
    setCurrentStepData({
      selectedContent: selected,
    });
    setIsSelectRuleVisible(false);
  };

  const validateContentSelection = (rule, value, callback) => {
    if (value && value.length > 0) {
      callback();
    } else {
      callback(
        t('pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.contentItemsRequired'),
      );
    }
  };

  return (
    <>
      <ColumnGroup>
        <Column>
          <Form className="content-items-step">
            <Form.Item name="contentItems" rules={[{ validator: validateContentSelection }]}>
              {form.getFieldDecorator('contentItems', {
                rules: [{ validator: validateContentSelection }],
              })(
                <Card
                  className="staged-rules-card"
                  extra={
                    <>
                      <Button
                        icon="plus"
                        type="dashed"
                        onClick={() => setIsSelectRuleVisible(true)}
                      >
                        {t('pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.add')}
                      </Button>
                    </>
                  }
                  title={t(
                    'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.title',
                  )}
                >
                  <div className="description-container">
                    <Text>
                      {t(
                        'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.description',
                      )}
                    </Text>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    rowKey="id"
                    locale={{
                      emptyText: t(
                        'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.noContentSelected',
                      ),
                    }}
                  />
                </Card>,
              )}
            </Form.Item>
          </Form>
        </Column>
        <Column>
          <Card
            className="explanation-card"
            title={t(
              'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationTitle',
            )}
          >
            <p>
              {t('pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanation')
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <p>
              {t(
                'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationListDescription',
              )
                .split('*')
                .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
            </p>
            <ul>
              <li>
                {t(
                  'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationList1',
                )}
              </li>
              <li>
                {t(
                  'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationList2',
                )}
              </li>
              <li>
                {t(
                  'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationList3',
                )}
              </li>
              <li>
                {t(
                  'pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.explanationList4',
                )}
              </li>
            </ul>
          </Card>
        </Column>
      </ColumnGroup>
      {isSelectRuleVisible && (
        <SelectContentModal
          multiSelect={true}
          contentTypes={contentTypesList}
          defaultFilteredValue={{ language: [language || 'en'] }}
          visible={isSelectRuleVisible}
          onCancel={() => setIsSelectRuleVisible(false)}
          onSubmit={handleSelectContent}
          contentIdsToFilter={selectedContent.map(
            content => content.id || content.uuid || content.product_id,
          )}
          customContent={contentFromRules}
          existingSelectedRowKeys={selectedContent.map(
            content =>
              content.whatDetail?.uuid || content.whatDetail?.id || content.uuid || content.id,
          )}
        />
      )}
    </>
  );
}

export default ContentItems;
