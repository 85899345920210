import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { /* Icon, */ Menu } from 'antd';
import { usePageActions } from '@hooks';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitalFromList } from '@redux/hospitals/reducers';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
// import HeaderButton from '@components/HeaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/pro-regular-svg-icons';
// import { procedurePathway } from '@assets/icons';
import DashboardSetup from './DashboardSetup';
// import ProcedurePathways from './ProcedurePathways';
import EditHospital from './Edit';

const routes = {
  edit: 'edit',
  setup: 'setup',
  procedurePathways: 'procedure-pathways',
};

const HospitalDetails = ({ /* history, */ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showBack: true,
  });

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [, hospital] = useSelector(selectHospitalFromList(match.params.id));

  useEffect(() => {
    const name = (hospital && hospital.name) || '';
    setTitle(name);
    document.title = name;
  }, [hospital, setTitle]);

  // useEffect(() => {
  //   if (history.location.pathname.endsWith(routes.edit)) {
  //     setActions([]);
  //   } else {
  //     setActions([
  //       <HeaderButton onClick={() => history.push(`${match.url}/${routes.edit}`)}>
  //         {t('common:buttons.edit')}
  //       </HeaderButton>,
  //     ]);
  //   }
  // }, [match, history, t, setActions]);

  return (
    <Switch>
      <Route path={`${match.path}/${routes.edit}`} component={EditHospital} />
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key="setup">
              <FontAwesomeIcon icon={faDesktop} />
              {t('organisations:OrganisationDetails.menu.dashboardSetup')}
            </Menu.Item>
            {/* <Menu.Item key="procedure-pathways">
              <Icon component={procedurePathway} />
              {t('hospitals:HospitalDetails.menu.procedurePathways')}
            </Menu.Item> */}
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.setup}`} component={DashboardSetup} />
          {/* <Route path={`${match.path}/${routes.procedurePathways}`} component={ProcedurePathways} /> */}
          <Route
            path={match.path}
            render={() => <Redirect to={`${match.url}/${routes.setup}`} />}
          />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
};

export default HospitalDetails;
