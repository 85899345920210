import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectNonVideoDocuments } from '@redux/documents/reducers';
import { fetchDocuments } from '@redux/documents/actions';
import { languageFromMetadata, sourceFromMetadata } from '@utils/contentMetadata';
import searchDocuments from '@utils/searchDocuments';
import createColumns from './createDocumentColumns';
import ContentModal from './Modal';
import { FEATURE_DOCUMENT, whatTypeToTranslationKey } from '../../../../../../constants';
import './style.less';

function SelectDocumentModal({ defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, documents] = useSelector(selectNonVideoDocuments);
  const selectLanguage = useSelector(languageFromMetadata);
  const selectSource = useSelector(sourceFromMetadata);
  const contentTypeTranslationKey = whatTypeToTranslationKey(FEATURE_DOCUMENT);
  const columns = useMemo(
    () =>
      createColumns(
        t,
        defaultFilteredValue,
        selectLanguage,
        selectSource,
        contentTypeTranslationKey,
      ),
    [defaultFilteredValue, selectLanguage, selectSource, t, contentTypeTranslationKey],
  );

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={documents}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.document')}
      filterFunc={searchDocuments}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}

SelectDocumentModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectDocumentModal;
