import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { Column } from '@cards/Card';
import ConnectionCodesListCard from '@cards/ConnectionCodes/List';
import HeaderButton from '@components/HeaderButton';
import SelectPathwayModal from '@components/SelectPathwayModal';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import {
  createConnectionCode,
  updateConnectionCodePathways,
  setConnectionCodeAsDefault,
  toggleConnectionCodeIsDisabled,
} from '@redux/connectionCodes/actions';
import { historyType, matchType } from '../../../propTypes';
import { setCurrentScreen, screens } from '../../../analytics';

function ConnectionCodesList() {
  const dispatch = useDispatch();
  const [isAddModalVisible, setAddModalVisibility] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisibility] = useState(false);
  const [pathwayIdsForSelectedCode, setPathwayIdsForSelectedCode] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_CODES_LIST);
  }, []);

  useEffect(() => {
    dispatch(fetchPathways());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [pathwaysLoading, pathways] = useSelector(selectPathways);

  const { t } = useTranslation();
  usePageActions({
    actions: [
      <HeaderButton key="new-code" onClick={() => setAddModalVisibility(true)}>
        {t('connectionCodes:List.new')}
      </HeaderButton>,
    ],
    title: t('connectionCodes:List.title'),
  });

  const handleSelectedRowsChanged = useCallback(newSelectedRowIds => {
    setSelectedRowIds(newSelectedRowIds);
  }, []);

  const handleAddCodeWithPathways = useCallback(
    selectedPathwayIds => {
      dispatch(createConnectionCode(selectedPathwayIds));
      setAddModalVisibility(false);
    },
    [dispatch],
  );

  const handleEdit = useCallback(selectedPathwayIds => {
    setPathwayIdsForSelectedCode(selectedPathwayIds);
    setUpdateModalVisibility(true);
  }, []);

  const handleUpdatePathways = useCallback(
    selectedPathwayIds => {
      // console.log(selectedRowIds[0], selectedPathwayIds);
      dispatch(updateConnectionCodePathways(selectedRowIds[0], selectedPathwayIds));
      setUpdateModalVisibility(false);
    },
    [dispatch, selectedRowIds],
  );

  const handleSetAsDefault = useCallback(() => {
    console.log('selectedRowIds: ', selectedRowIds);
    dispatch(setConnectionCodeAsDefault(selectedRowIds[0]));
  }, [dispatch, selectedRowIds]);

  const handleToggleDisabled = useCallback(() => {
    console.log('selectedRowIds: ', selectedRowIds);
    dispatch(toggleConnectionCodeIsDisabled(selectedRowIds[0]));
  }, [dispatch, selectedRowIds]);

  return (
    <Column>
      <ConnectionCodesListCard
        isLoadingPathways={pathwaysLoading}
        handleSelectionChanged={handleSelectedRowsChanged}
        handleEdit={handleEdit}
        handleSetAsDefault={handleSetAsDefault}
        handleToggleDisabled={handleToggleDisabled}
      />
      <SelectPathwayModal
        customTitle={t('connectionCodes:List.pathwaysModalTitle')}
        pathways={pathways}
        // selectedRowKeys={selectedPathwayIds}
        visible={isAddModalVisible}
        onSubmit={handleAddCodeWithPathways}
        onCancel={() => setAddModalVisibility(false)}
        addButtonText={t('connectionCodes:List.addButtontext')}
        isSelectionOptional={true}
        clearOnClose
      />
      <SelectPathwayModal
        customTitle={t('connectionCodes:List.pathwaysModalTitle')}
        pathways={pathways}
        // selectedRowKeys={selectedPathwayIds}
        visible={isUpdateModalVisible}
        onSubmit={handleUpdatePathways}
        onCancel={() => setUpdateModalVisibility(false)}
        addButtonText={t('connectionCodes:List.saveButtontext')}
        isSelectionOptional={true}
        selectedRowKeys={pathwayIdsForSelectedCode}
        clearOnClose
      />
    </Column>
  );
}

ConnectionCodesList.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default ConnectionCodesList;
