import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './Login';
// import Onboarding from './Onboarding';
import Registration from './Registration';
import PasswordRecovery from './PasswordRecovery';

import './style.less';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/login`} component={Login} />
    {/* <Route path="/auth/onboarding" component={Onboarding} /> */}
    <Route path={`${match.path}/registration`} component={Registration} />
    <Route path={`${match.path}/recovery`} component={PasswordRecovery} />
    <Route path={match.path} render={() => <Redirect to="/auth/login" />} />
  </Switch>
);
