import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import { Card, ColumnGroup, Column } from '@cards/Card';

import './style.less';

function PropagateUpdates() {
  const { t } = useTranslation();
  const { form, setCurrentStepData, formData } = useWizard();

  return (
    <ColumnGroup className="propagate-updates-step">
      <Column>
        <Card.Half title={t('pathways:ProcedurePathways.contentUpdates.propagateUpdates')}>
          <Form>
            <Form.Item>
              {form.getFieldDecorator('propagateUpdates', {
                initialValue: formData.propagateUpdates.propagateUpdates,
                rules: [
                  {
                    required: true,
                    message: t('pathways:ProcedurePathways.contentUpdates.required'),
                  },
                ],
              })(
                <Radio.Group
                  onChange={e => {
                    setCurrentStepData({
                      propagateUpdates: e.target.value,
                    });
                  }}
                >
                  <Radio value={true}>
                    {t('pathways:ProcedurePathways.contentUpdates.propagate')}
                  </Radio>
                  <Radio value={false}>
                    {t('pathways:ProcedurePathways.contentUpdates.doNotPropagate')}
                  </Radio>
                </Radio.Group>,
              )}
            </Form.Item>
          </Form>
        </Card.Half>
      </Column>
      <Column>
        <Card title="Explanation">
          {t('pathways:ProcedurePathways.contentUpdates.explanation')}
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default PropagateUpdates;
