import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Button, Input, Icon, Form } from 'antd';
import OnboardingContainer from '@components/OnboardingContainer';
import { passwordResetStarted } from '@redux/passwordRecovery/actions';
import { isLoading } from '@redux/passwordRecovery/reducer';

import './style.less';

const EmailRecovery = ({
  form: { getFieldDecorator, validateFieldsAndScroll, getFieldError, getFieldValue },
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          dispatch(passwordResetStarted(values));
        }
      });
    },
    [dispatch, validateFieldsAndScroll],
  );

  const { t } = useTranslation();

  const disabledSubmit = typeof getFieldError('email') !== 'undefined' || !getFieldValue('email');
  return (
    <OnboardingContainer>
      <Card
        title={<h1>{t('passwordRecovery:title')}</h1>}
        actions={[
          <Button
            type="primary"
            disabled={disabledSubmit}
            onClick={handleSubmit}
            htmlType="submit"
            loading={loading}
          >
            {t('common:buttons.submit')}
          </Button>,
        ]}
        className="login-card"
      >
        <p>{t('passwordRecovery:Email.description')}</p>
        <Form onSubmit={handleSubmit}>
          <Form.Item hasFeedback>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: t('common:validation.Email.emptyError'),
                },
                {
                  type: 'email',
                  message: t('common:validation.Email.invalidError'),
                },
              ],
            })(
              <Input
                type="email"
                prefix={<Icon type="mail" />}
                placeholder={t('common:placeholder.email')}
              />,
            )}
          </Form.Item>
        </Form>
      </Card>
    </OnboardingContainer>
  );
};

EmailRecovery.propTypes = {
  form: PropTypes.object.isRequired,
};

const WrappedEmailRecovery = Form.create()(EmailRecovery);

export { WrappedEmailRecovery };
export default WrappedEmailRecovery;
