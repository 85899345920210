import { select } from 'redux-saga/effects';
import { selectAppDetails } from '@organisation/redux/selectors';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { IAppDetails } from '@organisation/redux/types';
import { IAdminOrganisation, ISolutionConfiguration } from './types';

export function* doGetOrganisationObjectType(): any {
  const { appToken }: IAppDetails = yield select(selectAppDetails);
  const {
    profile: { organisationId },
  } = yield select(selectCurrentDashboardUser);

  return `${appToken}-organisation-${organisationId}`;
}

export const parseSolutionConfigurationDetails = (
  solutionConfiguration: ISolutionConfiguration,
): IAdminOrganisation => ({
  appToken: solutionConfiguration.body.app_token,
  organisationId: solutionConfiguration.body.organisation_id,
  id: solutionConfiguration.organisation.pk,
  name: solutionConfiguration.organisation.name,
  organisationSlug: solutionConfiguration.body.organisation_slug,
  slug: solutionConfiguration.organisation.slug,
  ubiquity: {
    appId: solutionConfiguration.body.ubiquity_app_id,
    organisationId: solutionConfiguration.body.ubiquity_organisation_id,
    organisationSlug: solutionConfiguration.body.ubiquity_organisation_slug,
  },
});
