import { call, put, select } from 'redux-saga/effects';
import { message } from 'antd';
// import { IPathwaysAdminClient } from '@liquid-state/pathways-client';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { selectPathway } from '../reducers';
import {
  publishPathwaysSuccess,
  unpublishPathwaysSuccess,
  IPublishPathways,
  IUnpublishPathways,
} from '../actions';
import i18n from '../../../../i18n';

function* doSetPathwayActiveStatus(
  // update client type to IPathwaysAdminClient type after new version published
  client: { [key: string]: any },
  pathwayId: number,
  isActive: boolean,
): any {
  const [, pathway] = yield select(selectPathway(pathwayId));

  yield call(client.patchPathway, pathwayId, {
    description: pathway.description,
    name: pathway.name,
    isActive,
    isDeleted: pathway.isDeleted,
  });
}

export function* doPublishPathways({ payload: { pathwayIds } }: IPublishPathways) {
  try {
    const client = yield call(doCreatePathwaysClient);
    for (const pathwayId of pathwayIds) {
      yield call(doSetPathwayActiveStatus, client, pathwayId, true);
    }

    yield put(publishPathwaysSuccess(pathwayIds));
    yield call(message.success, i18n.t('pathways:ProcedurePathways.details.modals.publishSuccess'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.details.modals.publishFailed'));
  }
}

export function* doUnpublishPathways({ payload: { pathwayIds } }: IUnpublishPathways) {
  try {
    const client = yield call(doCreatePathwaysClient);
    for (const pathwayId of pathwayIds) {
      yield call(doSetPathwayActiveStatus, client, pathwayId, false);
    }

    yield put(unpublishPathwaysSuccess(pathwayIds));
    yield call(
      message.success,
      i18n.t('pathways:ProcedurePathways.details.modals.unpublishSuccess'),
    );
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.details.modals.unpublishFailed'));
  }
}
