import { Column, ColumnGroup, Card } from '@cards/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { getOrganisationBranding } from '@redux/organisations/actions';
import { selectOrganisationInstructionalScreenByPk } from '@redux/organisations/reducers';
import { useTranslation } from 'react-i18next';
import ColourHexAndPreview from '@components/ColourHexAndPreview';
import './style.less';

function InstructionalScreen({ history, match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const instructionalScreen = useSelector(
    selectOrganisationInstructionalScreenByPk(match.params.pk),
  );

  useEffect(() => {
    dispatch(getOrganisationBranding());
  }, [dispatch]);

  return (
    <ColumnGroup className="organisation-branding-details">
      <Column>
        <Card
          className="organisation-details-card"
          title={t('organisations:Branding.instructionalScreens.title')}
          extra={
            <Icon
              type="edit"
              onClick={() => history.push(`${match.path}/${match.params.pk}/edit`)}
            />
          }
        >
          {(instructionalScreen?.text ||
            instructionalScreen?.text_colour ||
            instructionalScreen?.background_colour) && (
            <DetailForm>
              <DetailRow
                label={t('organisations:Branding.instructionalScreens.text')}
                value={instructionalScreen.text}
              />
              <DetailRow
                label={t('organisations:Branding.instructionalScreens.textColour')}
                value={<ColourHexAndPreview hex={instructionalScreen.text_colour} />}
              />
              <DetailRow
                label={t('organisations:Branding.instructionalScreens.backgroundColour')}
                value={<ColourHexAndPreview hex={instructionalScreen.background_colour} />}
              />
            </DetailForm>
          )}
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default InstructionalScreen;
