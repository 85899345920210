import OrganisationDirectoryClient from '@liquid-state/directory-client';
import { organisationDirectoryApiRoot } from 'settings';

async function createOrganisationDirectoryClient(tokens) {
  const jwt = await tokens.get('orgdir');
  const client = new OrganisationDirectoryClient({ jwt, baseUrl: organisationDirectoryApiRoot });

  return client;
}

export default createOrganisationDirectoryClient;
