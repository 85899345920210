import { combineReducers } from 'redux';
import {
  Organisation,
  // IOrganisationInvites,
  LOAD_ORGANISATIONS,
  LOAD_ORGANISATIONS_SUCCESS,
  LOAD_ORGANISATIONS_FAILED,
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILED,
  EDIT_ORGANISATION,
  EDIT_ORGANISATION_FAILED,
  EDIT_ORGANISATION_SUCCESS,
  IAdminOrganisation,
  GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS,
  GET_ORGANISATION_BRANDING_SUCCESS,
  CREATE_ORGANISATION_BRANDING_SUCCESS,
  UPDATE_ORGANISATION_BRANDING_SUCCESS,
  GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS,
  CREATE_ORGANISATION_BRANDING,
  CREATE_ORGANISATION_BRANDING_FAILED,
  UPDATE_ORGANISATION_BRANDING,
  UPDATE_ORGANISATION_BRANDING_FAILED,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED,
} from './types';
import {
  ILoadOrganisations,
  ILoadOrganisationsSuccess,
  ILoadOrganisationsFailed,
  CreateOrganisation,
  CreateOrganisationFailed,
  CreateOrganisationSuccess,
  IUpdateOrganisation,
  IUpdateOrganisationSuccess,
  IUpdateOrganisationFailed,
  EditOrganisation,
  EditOrganisationFailed,
  EditOrganisationSuccess,
  ILoadOrganisation,
  ILoadOrganisationFailed,
  ILoadOrganisationSuccess,
  IGetOrganisationPublicContentSuccess,
  ICreateOrganisationPublicContentSuccess,
  IRemoveOrganisationPublicContentSuccess,
  IGetOrganisationBrandingSuccess,
  ICreateOrganisationBrandingSuccess,
  IUpdateOrganisationBrandingSuccess,
  IGetOrganisationInstructionalScreensSuccess,
  ICreateOrganisationInstructionalScreenSuccess,
  IUpdateOrganisationInstructionalScreen,
  IUpdateOrganisationInstructionalScreenSuccess,
  IUpdateOrganisationInstructionalScreenFailed,
  IRemoveOrganisationInstructionalScreenSuccess,
  IReorderOrganisationInstructionalScreensSuccess,
  ICreateOrganisationBranding,
  ICreateOrganisationBrandingFailed,
  IUpdateOrganisationBranding,
  IUpdateOrganisationBrandingFailed,
  ICreateOrganisationInstructionalScreen,
  ICreateOrganisationInstructionalScreenFailed,
} from './actions';
import { IState } from '@redux/reducer';
import { PublicContent, Branding, InstructionalScreen } from '@liquid-state/directory-client';

function creating(
  state = false,
  action: CreateOrganisation | CreateOrganisationFailed | CreateOrganisationSuccess,
) {
  switch (action.type) {
    case CREATE_ORGANISATION:
      return true;
    case CREATE_ORGANISATION_FAILED:
    case CREATE_ORGANISATION_SUCCESS:
      return false;
    default:
      return state;
  }
}

function editing(
  state = false,
  action: EditOrganisation | EditOrganisationFailed | EditOrganisationSuccess,
) {
  switch (action.type) {
    case EDIT_ORGANISATION:
      return true;
    case EDIT_ORGANISATION_FAILED:
    case EDIT_ORGANISATION_SUCCESS:
      return false;
    default:
      return state;
  }
}

const loading = (
  state: boolean = false,
  action:
    | ILoadOrganisations
    | ILoadOrganisationsSuccess
    | ILoadOrganisationsFailed
    | ILoadOrganisation
    | ILoadOrganisationFailed
    | ILoadOrganisationSuccess,
): boolean => {
  switch (action.type) {
    case LOAD_ORGANISATIONS:
    case 'organisation/load':
      return true;
    case LOAD_ORGANISATIONS_SUCCESS:
    case LOAD_ORGANISATIONS_FAILED:
    case 'organisation/load-failed':
    case 'organisation/load-success':
      return false;
    default:
      return state;
  }
};

const updating = (
  state: boolean = false,
  action: IUpdateOrganisation | IUpdateOrganisationSuccess | IUpdateOrganisationFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_ORGANISATION:
      return true;
    case UPDATE_ORGANISATION_SUCCESS:
    case UPDATE_ORGANISATION_FAILED:
      return false;
    default:
      return state;
  }
};

const organisation = (
  state: Organisation | null = null,
  action: IUpdateOrganisationSuccess | EditOrganisationSuccess | ILoadOrganisationSuccess,
  // action: EditOrganisationSuccess,
): Organisation | null => {
  switch (action.type) {
    case UPDATE_ORGANISATION_SUCCESS:
    case EDIT_ORGANISATION_SUCCESS:
    case 'organisation/load-success':
      return action.payload.organisation;
    default:
      return state;
  }
};

function list(state: IAdminOrganisation[] = [], action: ILoadOrganisationsSuccess) {
  switch (action.type) {
    case LOAD_ORGANISATIONS_SUCCESS:
      return action.payload.organisations;
    default:
      return state;
  }
}

function publicContent(
  state: PublicContent[] = [],
  action:
    | IGetOrganisationPublicContentSuccess
    | ICreateOrganisationPublicContentSuccess
    | IRemoveOrganisationPublicContentSuccess,
) {
  switch (action.type) {
    case GET_ORGANISATION_PUBLIC_CONTENT_SUCCESS:
      return action.payload.content?.results;
    case CREATE_ORGANISATION_PUBLIC_CONTENT_SUCCESS:
      return [...state, ...action.payload.content];
    case REMOVE_ORGANISATION_PUBLIC_CONTENT_SUCCESS:
      return state.filter(content => !action.payload.primaryKeys.includes(content.pk));
    default:
      return state;
  }
}

function branding(
  state: Branding | null = null,
  action:
    | IGetOrganisationBrandingSuccess
    | ICreateOrganisationBrandingSuccess
    | IUpdateOrganisationBrandingSuccess,
) {
  switch (action.type) {
    case GET_ORGANISATION_BRANDING_SUCCESS:
      return action.payload.branding;
    case CREATE_ORGANISATION_BRANDING_SUCCESS:
      return action.payload.branding;
    case UPDATE_ORGANISATION_BRANDING_SUCCESS:
      return action.payload.branding;
    default:
      return state;
  }
}

function instructionalScreens(
  state: InstructionalScreen[] = [],
  action:
    | IGetOrganisationInstructionalScreensSuccess
    | ICreateOrganisationInstructionalScreenSuccess
    | IUpdateOrganisationInstructionalScreenSuccess
    | IRemoveOrganisationInstructionalScreenSuccess
    | IReorderOrganisationInstructionalScreensSuccess,
) {
  switch (action.type) {
    case GET_ORGANISATION_INSTRUCTIONAL_SCREENS_SUCCESS:
      return action.payload.instructionalScreens;
    case CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS:
      return [...state, action.payload.instructionalScreen];
    case UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS:
      return state.map(screen => {
        if (screen.pk === action.payload.instructionalScreen.pk) {
          return action.payload.instructionalScreen;
        }
        return screen;
      });
    case REMOVE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS:
      return state.filter(screen => screen.pk !== action.payload.pk);
    default:
      return state;
  }
}

function brandingLoading(
  state: boolean = false,
  action:
    | ICreateOrganisationBranding
    | ICreateOrganisationBrandingSuccess
    | ICreateOrganisationBrandingFailed
    | IUpdateOrganisationBranding
    | IUpdateOrganisationBrandingSuccess
    | IUpdateOrganisationBrandingFailed
    | ICreateOrganisationInstructionalScreen
    | ICreateOrganisationInstructionalScreenSuccess
    | ICreateOrganisationInstructionalScreenFailed
    | IUpdateOrganisationInstructionalScreen
    | IUpdateOrganisationInstructionalScreenSuccess
    | IUpdateOrganisationInstructionalScreenFailed,
) {
  switch (action.type) {
    case CREATE_ORGANISATION_BRANDING:
      return true;
    case UPDATE_ORGANISATION_BRANDING:
      return true;
    case CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN:
      return true;
    case UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN:
      return true;
    case CREATE_ORGANISATION_BRANDING_SUCCESS:
      return false;
    case CREATE_ORGANISATION_BRANDING_FAILED:
      return false;
    case UPDATE_ORGANISATION_BRANDING_SUCCESS:
      return false;
    case UPDATE_ORGANISATION_BRANDING_FAILED:
      return false;
    case CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS:
      return false;
    case CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED:
      return false;
    case UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_SUCCESS:
      return false;
    case UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN_FAILED:
      return false;
    default:
      return state;
  }
}

// This functionality was mocked so removed it for now
// const invites = (state: IOrganisationInvites = {}, action: ILoadOrganisationsSuccess): IOrganisationInvites => {
//   switch (action.type) {
//     case LOAD_ORGANISATIONS_SUCCESS:
//       return action.payload.invites;
//     default:
//       return state;
//   }
// };

export default combineReducers({
  creating,
  editing,
  organisation,
  list,
  publicContent,
  branding,
  instructionalScreens,
  loading,
  updating,
  brandingLoading,
});

export const selectOrganisation = () => (state: IState): [boolean, Organisation | null] => [
  state.organisations.loading,
  state.organisations.organisation,
];

export const selectOrganisations = (state: IState): [boolean, IAdminOrganisation[]] => [
  state.organisations.loading,
  state.organisations.list,
];

export const selectOrganisationFromList = (organisationId: number) => (
  state: IState,
): [boolean, IAdminOrganisation | undefined] => [
  state.organisations.loading,
  state.organisations.list.find(organisation => organisation.id === Number(organisationId)),
];

// TODO: use this once organisations list working again
// export const selectOrganisationsInAlphabeticalOrder = (
//   state: IState,
// ): [boolean, IOrganisation[]] => [
//   state.organisations.loading,
//   state.organisations.organisations.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
// ];
export const selectOrganisationsInAlphabeticalOrder = (
  state: IState,
): [boolean, IAdminOrganisation[]] => [state.organisations.loading, state.organisations.list];

// TODO: use this once organisations list working again
// export const selectOrganisationsById = (
//   state: IState,
// ): [boolean, { [key: string]: IOrganisation }] => [
//   state.organisations.loading,
//   state.organisations.organisations.reduce(
//     (organisationsById, organisation) => ({ ...organisationsById, [organisation.uuid]: organisation }),
//     {},
//   ),
// ];
export const selectOrganisationsById = (): [boolean, { [key: string]: Organisation }] => [
  false,
  {},
];

// This functionality was mocked so removed it for now
// export const selectOrganisationInvites = (state: IState): [boolean, IOrganisationInvites] => [
//   state.organisations.loading,
//   state.organisations.invites,
// ];

export const selectOrganisationCreating = (state: IState): boolean => state.organisations.creating;
export const selectOrganisationUpdating = (state: IState): boolean => state.organisations.updating;
export const selectOrganisationEditing = (state: IState): boolean => state.organisations.editing;

export const selectOrganisationPublicContent = (state: IState): PublicContent[] =>
  state.organisations.publicContent;
export const selectOrganisationPublicContentByProductId = (productId: string) => (
  state: IState,
): PublicContent | undefined =>
  state.organisations.publicContent.find(content => content.product_id === productId);

export const selectOrganisationBranding = (state: IState): Branding | null =>
  state.organisations.branding;

export const selectOrganisationInstructionalScreens = (state: IState): InstructionalScreen[] =>
  state.organisations.instructionalScreens;

export const selectOrganisationInstructionalScreenByPk = (pk: number) => (
  state: IState,
): InstructionalScreen | undefined =>
  state.organisations.instructionalScreens.find(screen => screen.pk === pk);

export const selectBrandingLoading = (state: IState): boolean =>
  state.organisations.brandingLoading;
