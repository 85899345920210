import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import ProcedurePathwaysListCard from '../../cards/Pathways/PathwayList';
import NewDropdown from './NewDropdown';
import { historyType } from '../../../propTypes';
import './style.less';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { setCurrentScreen, screens } from '../../../analytics';

function PathwayList({ history }) {
  useEffect(() => {
    setCurrentScreen(screens.PATHWAYS_LIST);
  }, []);

  const { t } = useTranslation();
  const perms = useHasPermissions();
  const { setTitle } = usePageActions({
    title: t('pathways:ProcedurePathways.header'),
    actions: [
      perms[Permissions.CreatePathway] && (
        <NewDropdown key="new-pathway-dropdown" history={history} />
      ),
    ],
  });
  document.title = t('pathways:ProcedurePathways.header');
  useTitleUpdate(setTitle, 'pathways:ProcedurePathways.header');

  return (
    <Column>
      <ProcedurePathwaysListCard />
    </Column>
  );
}

PathwayList.propTypes = {
  history: historyType,
};

export default PathwayList;
