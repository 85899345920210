import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { usePageActions } from '@hooks/usePageActions';
import { matchType } from '../../../propTypes';
import DuplicateSharedPathwayWizard from './DuplicateSharedPathwayWizard';
import { utiliseSharedPathwaySnapshot } from '@pathways/redux/sharedPathways/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { fetchSharedPathwaySnapshots } from '@pathways/redux/sharedPathways/actions';
import {
  selectSharedPathway,
  selectSharedPathwaysLoading,
} from '@pathways/redux/sharedPathways/reducers';
import { useTitleUpdate } from '@hooks';

function DuplicateSharedPathway({ match }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { setTitle } = usePageActions({
    title: t('pathways:SharedPathways.duplicateWizard.title'),
  });
  document.title = t('pathways:SharedPathways.duplicateWizard.title');
  useTitleUpdate(setTitle, 'pathways:SharedPathways.duplicateWizard.title');

  const sharedPathway = useSelector(selectSharedPathway(Number(match.params.id)));
  const sharedPathwaysLoading = useSelector(selectSharedPathwaysLoading);
  const [indexEventsLoading, indexEvents] = useSelector(selectIndexEvents);

  const [indexEventsLocalisedNames, setindexEventsLocalisedNames] = useState();
  const [sharedIndexEventsLocalisedNames, setsharedIndexEventsLocalisedNames] = useState();
  const [initialData, setInitialData] = useState();

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  const loading = sharedPathwaysLoading || indexEventsLoading;

  useEffect(() => {
    if (!sharedPathway?.id) dispatch(fetchSharedPathwaySnapshots());
    if (!indexEvents?.length) dispatch(fetchIndexEvents());
  }, [dispatch, sharedPathway, indexEvents]);

  useEffect(() => {
    if (indexEvents?.length > 0) {
      setindexEventsLocalisedNames(
        indexEvents.map(indexEvent => ({
          ...indexEvent,
          translatedName: indexEvent.translatedNames[languageCode] || indexEvent.name,
        })),
      );
    }

    if (sharedPathway?.indexEvents?.length > 0) {
      setsharedIndexEventsLocalisedNames(
        sharedPathway.indexEvents.map(indexEvent => ({
          ...indexEvent,
          translatedName:
            sharedPathway.parentIndexEventTypes[indexEvent.eventTypeSlug].translated_names[
              languageCode
            ] || sharedPathway.parentIndexEventTypes[indexEvent.eventTypeSlug].name,
        })),
      );
    }
  }, [indexEvents, sharedPathway, languageCode]);

  useEffect(() => {
    if (sharedIndexEventsLocalisedNames && indexEventsLocalisedNames) {
      const indexEventMappings = sharedIndexEventsLocalisedNames.map(sharedIe => {
        const matchingSlug = indexEventsLocalisedNames.find(
          ie => ie.translatedName === sharedIe.translatedName,
        )?.slug;
        if (matchingSlug) {
          return {
            [sharedIe.eventTypeSlug]: matchingSlug,
          };
        } else {
          return {
            [sharedIe.eventTypeSlug]: undefined,
          };
        }
      });
      setInitialData(indexEventMappings);
    }
  }, [sharedIndexEventsLocalisedNames, indexEventsLocalisedNames]);

  const onCompleted = useCallback(
    formData => {
      const { indexEvents } = formData;
      dispatch(utiliseSharedPathwaySnapshot(Number(match.params.id), indexEvents || {}));
    },
    [match, dispatch],
  );

  return (
    <DuplicateSharedPathwayWizard
      match={match}
      onCompleted={onCompleted}
      initialData={initialData}
      loading={loading}
      sharedPathway={sharedPathway}
      indexEventsLocalisedNames={indexEventsLocalisedNames}
      sharedIndexEventsLocalisedNames={sharedIndexEventsLocalisedNames}
    />
  );
}

DuplicateSharedPathway.propTypes = {
  match: matchType.isRequired,
};

export default DuplicateSharedPathway;
