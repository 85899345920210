import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import slug from 'slug';
import { usePageActions, useTitleUpdate } from '@hooks';
import { createIndexEvent } from '../../redux/indexEvents/actions';
import { selectIndexEventCreating } from '../../redux/indexEvents/reducers';
import IndexEventWizard from './Wizard';
import { historyType } from '../../../propTypes';

function NewIndexEvent({ history }) {
  const creating = useSelector(selectIndexEventCreating);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({ showClose: true, title: t('indexEvents:Wizard.title') });
  useTitleUpdate(setTitle, 'indexEvents:Wizard.title');

  const onSubmit = useCallback(
    names => {
      const { en: indexEventName, ...translatedNames } = names;
      dispatch(
        createIndexEvent(
          indexEventName,
          slug(indexEventName),
          Object.entries(translatedNames).reduce(
            (prev, [key, name]) => (name === undefined ? prev : { ...prev, [key]: name }),
            {},
          ),
        ),
      );
    },
    [dispatch],
  );

  return (
    <IndexEventWizard
      submitting={creating}
      title={t('indexEvents:Wizard.details')}
      onSubmit={onSubmit}
    />
  );
}

NewIndexEvent.propTypes = {
  history: historyType.isRequired,
};

export default NewIndexEvent;
