import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default (setTitle, translationString, interpolation) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
      setTitle(t(translationString, interpolation));
    }
    setTitle(t(translationString, interpolation));
  }, [language, setTitle, t, translationString, i18n.language, interpolation]);
};
