import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { FEATURE_DOCUMENT, FEATURE_VIDEO } from '@constants';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { Card } from '../../../cards/Card';

import './styles.less';
import { fetchRules } from '@pathways/redux/rules/actions';

const pageSize = 10;

const createColumns = t => [
  {
    title: t('cards:DocumentPathways.columns.title'),
    dataIndex: 'name',
    key: 'title',
    sorter: (a, b) => a.name.localeCompare(b.name),
    align: 'left',
  },
  {
    title: t('cards:DocumentPathways.columns.status'),
    dataIndex: '',
    key: 'status',
    width: 100,
    render: isActive => (
      <Tooltip
        placement="bottom"
        title={
          isActive ? t('cards:DocumentPathways.published') : t('cards:DocumentPathways.unpublished')
        }
      >
        {isActive ? (
          <FontAwesomeIcon size="lg" className="published-check" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon size="lg" className="published-cross" icon={faTimesCircle} />
        )}
      </Tooltip>
    ),
    sorter: ({ isActive: a }, { isActive: b }) => {
      if (a === b) return 0;
      if (a < b) return 1;
      if (a > b) return -1;
    },
  },
];

const DocumentPathwaysCard = ({ documentId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchRules());
  }, [dispatch]);

  const [loading, pathways] = useSelector(
    selectPathwaysByRules(
      rule =>
        (rule.what === FEATURE_DOCUMENT || rule.what === FEATURE_VIDEO) &&
        rule.whatDetail.id === Number(documentId),
    ),
  );

  return (
    <>
      <Card.Half
        className="document-pathways-card"
        title={t('cards:DocumentPathways.title')}
        noPadding
        loading={loading}
        {...props}
      >
        <Table
          columns={columns}
          dataSource={pathways}
          pagination={{ position: 'bottom', pageSize }}
          rowKey="id"
          onRow={({ id }) => ({
            onClick: () => history.push(`/procedure/pathways/${id}`),
          })}
        />
      </Card.Half>
    </>
  );
};

export default DocumentPathwaysCard;
