import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector /* useStore */ } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchableTable from '@components/SearchableTable';
import { Empty } from 'antd';
import { Card } from '@cards/Card';
import { moveFlag, fetchFlags } from '@redux/flags/actions';
import { selectEnabledFlags, selectDisabledFlags, selectFlagEditing } from '@redux/flags/reducers';
import createColumns from './createColumns';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name);
};

function FlagsList({ disabled }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, flags] = useSelector(disabled ? selectDisabledFlags : selectEnabledFlags);
  const flagsMoving = useSelector(selectFlagEditing);
  const [sortedFlags, setSortedFlags] = useState([]);

  const editing = useSelector(selectFlagEditing);
  const columns = useMemo(
    () =>
      createColumns(t, flags, disabled, (editedFlag, direction) =>
        dispatch(moveFlag(editedFlag.uuid, direction)),
      ),
    [dispatch, t, flags, disabled],
  );

  useEffect(() => {
    dispatch(fetchFlags());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !flagsMoving && !editing && flags?.length > 0)
      setSortedFlags(flags.sort((a, b) => a.order - b.order));
  }, [flags, loading, editing, flagsMoving]);

  return sortedFlags.length ? (
    <SearchableTable
      className="flags-list"
      columns={columns}
      dataSource={sortedFlags}
      rowKey="uuid"
      loading={editing || loading || flagsMoving}
      filterFunc={filterFunc}
      onRowClick={record => history.push(`/administration/flags/${record.uuid}`)}
      pagination={false}
    />
  ) : (
    <Card>
      <Empty description={t('flags:List.noFlagFound')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Card>
  );
}

export default FlagsList;
