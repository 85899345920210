import MessagingClient from '@liquid-state/messaging-client';
import { messagingApiRoot } from 'settings';

async function createMessagingClient(appId, apiKey) {
  try {
    const baseUrl = `${messagingApiRoot}${appId}/`;

    const client = await new MessagingClient({ apiKey }, { baseUrl });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createMessagingClient;
