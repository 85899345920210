import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import { selectWeblinkVersion } from '@redux/weblinks/selectors';
import { fetchWeblinkLatestVersion } from '@redux/weblinks/actions';

function WeblinkPreview({ weblink, visible, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWeblinkLatestVersion(weblink?.uuid));
  }, [dispatch, weblink]);

  const [, weblinkVersion] = useSelector(selectWeblinkVersion(weblink?.uuid));

  if (!weblink || !weblinkVersion) return null;

  const weblinkToUse = weblink.content_url ? weblink : weblinkVersion;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button
            type="primary"
            onClick={() => {
              window.open(weblinkToUse.content_url);
              onCancel();
            }}
          >
            {t('common:buttons.open')}
          </Button>
          <Button type="primary" onClick={onCancel}>
            {t('common:buttons.close')}
          </Button>
        </>
      }
    >
      <p>{t('pathways:ProcedureRules.wizard.content.weblinkPreview')}</p>
      <a href={weblinkToUse.content_url} rel="noopener noreferrer" target="_blank">
        {weblinkToUse.content_url}
      </a>
    </Modal>
  );
}

export default WeblinkPreview;
