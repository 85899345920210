import React, { useMemo } from 'react';
import useWizard from '@components/Wizard/useWizard';
import AddedJourneyCard from './Journeys/AddedJourneyCard';

export default () => {
  const { formData } = useWizard();
  const journeys = useMemo(
    () =>
      formData.journeys?.journeys.map(journey => ({
        ...journey,
        // TODO: once journey names are added to pathways backend this can be uncommented
        // name: formData.journeys[`${journey.id}-name`],
      })) || [],
    [formData],
  );

  return (
    <>
      {journeys.map(journey => (
        <AddedJourneyCard key={journey.id} journey={journey} />
      ))}
    </>
  );
};
