import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { contentTypes } from '@utils';
import './style.less';

function ContentIcon({ contentType, showText }) {
  const { t } = useTranslation();
  if (!contentType) return null;

  if (showText) {
    return (
      <span className="content-icon-with-text">
        <FontAwesomeIcon icon={contentTypes[contentType].icon} size="lg" />
        {t(contentTypes[contentType].label)}
      </span>
    );
  }
  return (
    <Tooltip placement="bottom" title={t(contentTypes[contentType].label)}>
      <FontAwesomeIcon icon={contentTypes[contentType].icon} size="lg" />
    </Tooltip>
  );
}

ContentIcon.propTypes = {
  contentType: string,
  showText: bool,
};

ContentIcon.defaultProps = {
  contentType: null,
  showText: false,
};

export default ContentIcon;
