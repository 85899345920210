import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPathwayWithRules } from '@pathways/redux/pathways/reducers';
import { usePageActions, useTitleUpdate } from '@hooks';
import ContentUpdatesWizard from './ContentUpdatesWizard';

function ContentUpdates({ match }) {
  const { t } = useTranslation();

  const { setTitle } = usePageActions({
    title: t('pathways:ProcedurePathways.contentUpdates.title'),
  });
  document.title = t('pathways:ProcedurePathways.contentUpdates.title');
  useTitleUpdate(setTitle, 'pathways:ProcedurePathways.contentUpdates.title');

  const { id: pathwayId } = match.params;

  const [pathwaysLoading, pathway] = useSelector(selectPathwayWithRules(pathwayId));

  const [initialData, setInitialData] = useState();

  useEffect(() => {
    setInitialData({
      contentUpdates: {
        selectedContent: [],
      },
      snapshotOptions: {
        createSnapshotWithUpdates: false,
        createSnapshotWithoutUpdates: false,
        createSnapshotWithoutUpdatesDescription: '',
        createSnapshotWithoutUpdatesName: '',
        createSnapshotWithUpdatesDescription: '',
        createSnapshotWithUpdatesName: '',
        makeCurrentlyShared: false,
      },
      propagateUpdates: {
        propagateUpdates: false,
      },
    });
  }, []);

  const onCompleted = useCallback(formData => {
    const { contentUpdates, snapshotOptions, propagateUpdates } = formData;
    console.log('onComplete contentUpdates', contentUpdates);
    console.log('onComplete snapshotOptions', snapshotOptions);
    console.log('onComplete propagateUpdates', propagateUpdates);
  }, []);

  return (
    <ContentUpdatesWizard
      initialData={initialData}
      loading={pathwaysLoading}
      onCompleted={onCompleted}
      pathway={pathway}
    />
  );
}

export default ContentUpdates;
