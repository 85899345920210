import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Spin } from 'antd';
import './style.less';

function ControlledDocumentModal({ title, url, visible, onClose }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <Modal
      className="controlled-document-modal"
      okText={t('common:ControlledDocumentModal.ok')}
      title={title}
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
    >
      {loading ? <Spin /> : null}
      <iframe
        src={url}
        title={title}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Modal>
  );
}

ControlledDocumentModal.propTypes = {
  title: string.isRequired,
  url: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

export default ControlledDocumentModal;
