import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Modal } from 'antd';
import { faInfoCircle, faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { ColumnGroup, Column } from '@cards/Card';
import IconButton from '@components/IconButton';
import SearchableTable from '@components/SearchableTable';
import {
  fetchOrganisation,
  getOrganisationInstructionalScreens,
  removeOrganisationInstructionalScreens,
} from '@redux/organisations/actions';
import {
  selectOrganisation,
  selectOrganisationInstructionalScreens,
} from '@redux/organisations/reducers';
import createColumns from './createColumns';
import './style.less';

function InstructionalScreens({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rowsToRemove, setRowsToRemove] = useState([]);
  const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const [loading, organisation] = useSelector(
    selectOrganisation(dashboardUserProfile.organisationId),
  );
  const [maxOrder, setMaxOrder] = useState(0);
  const columns = useMemo(() => createColumns(t, maxOrder, dispatch), [t, maxOrder, dispatch]);
  const instructionalScreens = useSelector(selectOrganisationInstructionalScreens);

  const sortedInstructionalScreens = useMemo(() => {
    return instructionalScreens
      .sort((a, b) => a._order - b._order)
      .map(screen => {
        return {
          key: screen.pk,
          text: screen.text,
          backgroundColour: screen.background_colour,
          textColour: screen.text_colour,
          pk: screen.pk,
          order: screen._order,
          image: screen.image,
          imageUrl: screen.image_url,
        };
      });
  }, [instructionalScreens]);

  useEffect(() => {
    if (instructionalScreens) {
      const maxOrder = instructionalScreens.reduce((acc, curr) => {
        if (curr._order > acc) {
          return curr._order;
        }
        return acc;
      }, 0);
      setMaxOrder(maxOrder);
    }
  }, [instructionalScreens]);

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  useEffect(() => {
    if (organisation?.organisation_slug) {
      dispatch(getOrganisationInstructionalScreens());
    }
  }, [dispatch, organisation]);

  const removeInstructionalScreens = useCallback(() => {
    const contentToRemovePrimaryKeys = rowsToRemove.map(row => {
      return row.pk;
    });
    dispatch(removeOrganisationInstructionalScreens(contentToRemovePrimaryKeys));
    setRowsToRemove([]);
  }, [dispatch, rowsToRemove]);

  const showDeleteWarningModal = useCallback(() => {
    Modal.confirm({
      title: t('organisations:Content.delete.title'),
      content: t('organisations:Content.delete.content'),
      okText: t('organisations:Content.delete.ok'),
      okType: 'danger',
      cancelText: t('organisations:Content.delete.cancel'),
      onOk: removeInstructionalScreens,
      onCancel() {
        return;
      },
    });
  }, [removeInstructionalScreens, t]);

  return (
    <>
      <ColumnGroup className="organisation-instructional-screens">
        <Column>
          <SearchableTable
            columns={columns}
            dataSource={sortedInstructionalScreens}
            extra={
              <div className="app-organisation-instructional-screens">
                <Popover
                  overlayClassName="app-organisation-instructional-screens-popover"
                  content={<p>{t('organisations:Branding.instructionalScreens.popover.body')}</p>}
                  placement="bottomRight"
                  title={t('organisations:Branding.instructionalScreens.popover.title')}
                  trigger="click"
                >
                  <IconButton
                    icon={faInfoCircle}
                    tooltip={t('common:buttons.info')}
                    onClick={() => {}}
                  />
                </Popover>
                <IconButton
                  disabled={rowsToRemove.length === 0}
                  icon={faMinusCircle}
                  tooltip={t('common:buttons.remove')}
                  onClick={showDeleteWarningModal}
                />
                <Button
                  className="add-btn"
                  disabled={instructionalScreens.length >= 3}
                  icon="plus"
                  onClick={() => history.push(`${match.path}/new`)}
                >
                  {t('common:buttons.add')}
                </Button>
              </div>
            }
            loading={loading}
            pagination={false}
            onRowClick={row => history.push(`${match.path}/${row.pk}/edit`)}
            onRowSelectionChange={(_, rows) => setRowsToRemove(rows)}
          />
        </Column>
      </ColumnGroup>
    </>
  );
}

export default InstructionalScreens;
