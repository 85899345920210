import React, { useEffect, useMemo } from 'react';
import { Card } from '@cards/Card';
import { Table, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HOSPITAL_ADMIN, SUPER_ADMIN } from '@constants';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import { loadSupportRequests } from '@redux/requests/actions';
import { isRequestUpdating } from '@redux/requests/reducer';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUsers } from '@redux/dashboardUsers/reducers';
import { selectUserHospital, selectUserRole } from '@authorisation/selectors';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospitals } from '@redux/hospitals/reducers';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import createColumnsHospitalAdmin from './createColumnsHospitalAdmin';
import createColumnsSuperAdmin from './createColumnsSuperAdmin';
import './styles.less';

const RequestListCard = ({ title, extra, description, action, requestSelector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const {
    [Permissions.AdministerAllHospitals]: canAdministerAllHospitals,
    [Permissions.ManagePatients]: canManagePatients,
  } = useHasPermissions(Permissions.AdministerAllHospitals, Permissions.ManagePatients);

  useEffect(() => {
    if (canManagePatients) dispatch(fetchAppUsers());
    if (canAdministerAllHospitals) dispatch(fetchHospitals());
    dispatch(fetchDashboardUsers());
    dispatch(loadSupportRequests());
  }, [canAdministerAllHospitals, canManagePatients, dispatch]);

  const dashboardUserHospital = useSelector(selectUserHospital);

  const [requestsLoading, requests] = useSelector(requestSelector(dashboardUserHospital));
  const [appUsersLoading, appUsers] = useSelector(selectAppUsers);
  const [dashboardUsersLoading, dashboardUsers] = useSelector(selectDashboardUsers);
  const [hospitalsLoading, hospitals] = useSelector(selectHospitals);
  const updating = useSelector(isRequestUpdating);

  const loading = requestsLoading || appUsersLoading || dashboardUsersLoading || hospitalsLoading;

  const openRequests = useMemo(
    () => requests.filter(req => req.status === 'created' || req.status === 'processing'),
    [requests],
  );
  const closedRequests = useMemo(() => requests.filter(req => req.status === 'completed'), [
    requests,
  ]);

  const [openColumns, closedColumns] = useMemo(() => {
    if (userRole === HOSPITAL_ADMIN)
      return [
        createColumnsHospitalAdmin(t, action, appUsers, dashboardUsers, dashboardUserHospital),
        createColumnsHospitalAdmin(t, undefined, appUsers, dashboardUsers, dashboardUserHospital),
      ];
    if (userRole === SUPER_ADMIN)
      return [
        createColumnsSuperAdmin(
          t,
          action,
          appUsers,
          dashboardUsers,
          dashboardUserHospital,
          hospitals,
        ),
        createColumnsSuperAdmin(
          t,
          undefined,
          appUsers,
          dashboardUsers,
          dashboardUserHospital,
          hospitals,
        ),
      ];
  }, [userRole, t, action, appUsers, dashboardUsers, dashboardUserHospital, hospitals]);

  return (
    <Card title={title} extra={extra} className="request-list-card" noPadding loading={loading}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('cards:RequestList.tabs.outstanding')} key="1">
          <div className="description">
            <b>{t('cards:RequestList.note')}</b>
            {description}
          </div>
          <Table
            columns={openColumns}
            dataSource={openRequests}
            loading={updating}
            pagination={{ position: 'bottom' }}
            rowKey="uuid"
            scroll={{ x: 1000 }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('cards:RequestList.tabs.closed')} key="2">
          <div className="description">
            <b>{t('cards:RequestList.note')}</b>
            {description}
          </div>
          <Table
            columns={closedColumns}
            dataSource={closedRequests}
            loading={updating}
            pagination={{ position: 'bottom' }}
            rowKey="uuid"
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default RequestListCard;
