import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { fetchDocumentPages } from '../../../redux/documents/actions';
import { selectDocumentPages } from '../../../redux/documents/reducers';
import { Card } from '../../Card';
import PagePreviewModal from './PagePreviewModal';
import './styles.less';

const pageSize = 10;

const createColumns = (t, setModalState) => [
  {
    title: t('cards:DocumentContent.columns.page'),
    dataIndex: 'source_page_number',
    key: 'source_page_number',
    render: pageNum => <span className="page-number">{pageNum}</span>,
    sorter: (a, b) => a.source_page_number - b.source_page_number,
    width: 110,
    align: 'left',
    fixed: 'left',
  },
  {
    title: t('cards:DocumentContent.columns.title'),
    dataIndex: 'translatedPageName',
    key: 'translatedPageName',
    render: translatedPageName => translatedPageName,
    sorter: (a, b) => a.translatedPageName.localeCompare(b.translatedPageName),
  },
  {
    title: t('cards:DocumentContent.columns.open'),
    key: 'open',
    render: page => (
      <FontAwesomeIcon
        icon={faExternalLink}
        className="clickable-icon"
        onClick={() => setModalState({ pageUrl: page.preview_url, title: page.name, shown: true })}
      />
    ),
    width: 100,
    align: 'right',
    fixed: 'right',
  },
];

const DocumentContentCard = ({ documentId, ...props }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({ pageUrl: null, shown: false, title: null });
  const dispatch = useDispatch();

  const columns = useMemo(() => createColumns(t, setModalState), [t]);

  useEffect(() => {
    dispatch(fetchDocumentPages(documentId));
  }, [dispatch, documentId]);

  const [loading, pages] = useSelector(selectDocumentPages(documentId));

  const dataSource = useMemo(() => {
    const extractNumFromPage = new RegExp('(^Page) ([0-9]+$)', 'i');
    return (
      pages &&
      pages.map(page => {
        const isPage = extractNumFromPage.exec(page.name);

        return {
          ...page,
          translatedPageName: isPage
            ? t('cards:DocumentContent.columns.pageTitle', { number: isPage[2] })
            : page.name,
        };
      })
    );
  }, [pages, t]);

  return (
    <>
      <Card.Half
        className="document-content-card"
        title={t('cards:DocumentContent.title')}
        noPadding
        loading={loading}
        {...props}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ position: 'bottom', pageSize }}
          rowKey="id"
        />
      </Card.Half>
      <PagePreviewModal
        url={modalState.pageUrl}
        visible={modalState.shown}
        title={modalState.title}
        handleCloseModal={() => setModalState({ shown: false, pageUrl: null, title: null })}
      />
    </>
  );
};

export default DocumentContentCard;
