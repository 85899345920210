import React from 'react';
import { shape, string } from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import IconButton from '@components/IconButton';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
// import './styles.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

function ReviewContent({ content }) {
  const { t } = useTranslation();

  return (
    <>
      <Card.Half
        extra={
          <a href={content.link} target="_blank" rel="noopener noreferrer">
            <IconButton icon={faExternalLink} tooltip={t('common:buttons.open')} />
          </a>
        }
        title={t('cards:DocumentContent.title')}
      >
        <Form {...formItemLayout}>
          <DetailRow label={t('cards:VideoWizard.title')} value={content.title} />
          <DetailRow label={t('cards:VideoWizard.description')} value={content.videoDescription} />
          <DetailRow label={t('cards:VideoWizard.link')} value={content.link} />
        </Form>
      </Card.Half>
    </>
  );
}

ReviewContent.propTypes = {
  content: shape({
    title: string,
    description: string,
    link: string,
  }).isRequired,
};

export default ReviewContent;
