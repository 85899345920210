import React, { useEffect } from 'react';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserAppAccessCard from '@cards/AppUsers/AppAccess';
import { matchType } from '../../propTypes';
import { setCurrentScreen, screens } from '../../analytics';

const AppUserAppAccessTab = ({ match }) => {
  useEffect(() => {
    setCurrentScreen(screens.APP_USER_APP_ACCESS);
  }, []);

  return (
    <ColumnGroup>
      <Column>
        <AppUserAppAccessCard appUserId={match.params.id} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

AppUserAppAccessTab.propTypes = {
  match: matchType.isRequired,
};

export default AppUserAppAccessTab;
