import {
  ITermsOfUseRefreshed,
  IUserAcceptancesFetched,
  IAppUserTermsOfUseRefreshed,
  IFetchUserAcceptances,
  IRefreshAppUserTermsOfUse,
} from './actions';
import { IState } from '../reducer';

type TermsCollection = {
  loading: boolean;
  tou: any[];
  privacy: any[];
};

const initialState = {
  currentUser: {
    tou: [] as any[],
    privacy: [] as any[],
  },
  appUsers: {} as { [key: string]: TermsCollection },
  acceptances: {} as { [key: string]: any },
  acceptancesLoading: false,
};

export default (
  state = initialState,
  action:
    | ITermsOfUseRefreshed
    | IUserAcceptancesFetched
    | IAppUserTermsOfUseRefreshed
    | IRefreshAppUserTermsOfUse
    | IFetchUserAcceptances,
): any => {
  switch (action.type) {
    case 'termsOfUse/termsOfUseRefreshed':
      return {
        ...state,
        currentUser: {
          tou: action.payload.termsOfUse,
          privacy: action.payload.privacyPolicies,
        },
      };

    case 'termsOfUse/userAcceptancesFetched':
      return {
        ...state,
        acceptances: {
          ...state.acceptances,
          [action.payload.userId]: {
            loading: false,
            ...action.payload.acceptances,
          },
        },
        acceptancesLoading: false,
      };
    case 'termsOfUse/refreshAppUserTermsOfUse':
      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          [action.payload.appUserId]: {
            ...(state.appUsers[action.payload.appUserId] || {}),
            loading: state.appUsers[action.payload.appUserId]?.loading === false ? false : true,
          },
        },
      };
    case 'termsOfUse/appUserTermsOfUseRefreshed':
      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          [action.payload.appUserId]: {
            loading: false,
            tou: action.payload.termsOfUse,
            privacy: action.payload.privacyPolicies,
          },
        },
      };
    case 'termsOfUse/fetchUserAcceptances':
      return {
        ...state,
        acceptances: {
          ...state.acceptances,
          [action.payload.id]: {
            ...(state.acceptances[action.payload.id] || {}),
            loading: state.acceptances[action.payload.id]?.loading === false ? false : true,
          },
        },
        acceptancesLoading: true,
      };
    default:
      return state;
  }
};

export const selectTermsAndConditions = (state: IState) => state.termsOfUse.currentUser.tou;

export const selectPrivacyPolicies = (state: IState) => state.termsOfUse.currentUser.privacy;

export const selectAcceptancesByIds = (ids: string[]) => (state: any) => {
  const userId = state.login.user.id;
  const allAcceptances = state.termsOfUse.acceptances[userId];
  if (!allAcceptances) {
    return [];
  }
  let results: any[] = [];
  for (const id of ids) {
    const acceptances = allAcceptances[id];
    if (!acceptances) {
      continue;
    }
    results = results.concat(
      acceptances.map((a: any) => ({
        ...a,
        version: {
          ...a.version,
          version_content: a.version_content,
        },
      })),
    );
  }
  return results;
};

export const selectAppUserTermsAndConditions = (appUserId: string) => (state: IState) =>
  state.termsOfUse.appUsers[appUserId]?.tou ?? [];

export const selectAppUserPrivacyPolicies = (appUserId: any) => (state: IState) =>
  state.termsOfUse.appUsers[appUserId]?.privacy ?? [];

export const selectAppUserAcceptancesByIds = (appUserId: string, acceptablesIds: string[]) => (
  state: IState,
) => {
  const allAcceptances = state.termsOfUse.acceptances[appUserId];

  if (!allAcceptances) {
    return [];
  }
  let results: any[] = [];
  for (const id of acceptablesIds) {
    const acceptances = allAcceptances[id];
    if (!acceptances) {
      continue;
    }
    results = results.concat(
      acceptances.map((a: any) => ({
        ...a,
        version: {
          ...a.version,
          version_content: a.version_content,
        },
      })),
    );
  }
  return results;
};
