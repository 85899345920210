import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import DocumentListCard from '@cards/Documents/List';
import NewContentDropdown from '@components/NewContentDropdown';

import { setCurrentScreen, screens } from '../../analytics';

export default ({ match, documentTranslationKey }) => {
  useEffect(() => {
    const docType = documentTranslationKey === 'videos' ? 'VIDEOS' : 'DOCUMENTS';
    const screenType = `CONTENT_${docType}_LIST`;
    setCurrentScreen(screens[screenType]);
  }, [documentTranslationKey]);

  const { t } = useTranslation();
  const primaryKey = documentTranslationKey === 'videos' ? 'VIDEO' : 'DOCUMENT';
  const { setTitle } = usePageActions({
    title: t(`${documentTranslationKey}:DocumentList.header`),
    actions: [<NewContentDropdown key="new-content-dropdown" primaryKey={primaryKey} />],
  });
  document.title = t(`${documentTranslationKey}:DocumentList.header`);
  useTitleUpdate(setTitle, `${documentTranslationKey}:DocumentList.header`);

  return (
    <Column>
      <DocumentListCard match={match} documentTranslationKey={documentTranslationKey} />
    </Column>
  );
};
