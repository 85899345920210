import { put, call, cps, take, takeLatest, getContext, select } from 'redux-saga/effects';
import { AdminClient as PIPClient } from '@liquid-state/pip-client';
import createIDMInviteClient from '@api/idmInviteClient';
import { message } from 'antd';
import { pipApiRoot } from 'settings';
import { getUserPool, doCognitoLogin, setPreferredMfaSettings } from '../login/saga';
import doCreateIDMService from '../doCreateIDMService';
import CognitoUser from '../customCognitoUser';
import {
  invalidMobile,
  usernameAlreadyExists,
  invitationSuccess,
  invitationFailed,
  registrationSuccess,
  unhandledRegistrationError,
} from './actions';
import {
  INVITATION_CODE_SUBMITTED,
  REGISTRATION_SUBMITTED,
  UNHANDLED_REGISTRATION_ERROR,
  USERNAME_ALREADY_EXISTS,
} from './types';
import i18n from '../../i18n';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { loginSubmitted, SESSION_ESTABLISHED, setMFAPreferenceSuccessful } from '../login/actions';
import { refreshTermsOfUse, acceptTermsOfUse } from '@redux/termsOfUse/actions';
import { selectAppDetails } from '@organisation/redux/selectors';
import { extractDashboardUserId } from '@redux/dashboardUsers/sagas';

export default function* registrationSaga() {
  yield takeLatest(INVITATION_CODE_SUBMITTED, onInvitationCodeSubmission);
}

function* onInvitationCodeSubmission({ payload: { invitationCode } }) {
  const history = yield getContext('history');
  const idmInviteClient = createIDMInviteClient();
  let userData;
  let temporaryJWT;
  try {
    const rawUserData = yield call(idmInviteClient.validate, invitationCode);
    userData = rawUserData.user;
    temporaryJWT = rawUserData.tokens['pip'];
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('registration:Invitation.validation.wrongCode'));
    yield put(invitationFailed());
    return;
  }
  const languages = [navigator.language];
  if (userData.profile.language) {
    languages.unshift(userData.profile.language);
  }
  yield put(refreshTermsOfUse(languages, temporaryJWT, userData.profile.hospitalId));

  yield put(invitationSuccess(userData.email));
  yield call(history.replace, '/auth/registration/details');

  let {
    payload: { password, phoneNumber, mfa },
  } = yield take(REGISTRATION_SUBMITTED);

  registrationLoop: while (true) {
    const result = yield call(
      doRegistration,
      temporaryJWT,
      invitationCode,
      userData,
      password,
      phoneNumber,
      mfa,
    );

    switch (result) {
      case 'SUCCESS':
        break registrationLoop;
      case 'INVALID_PHONE':
        yield put(invalidMobile());
        const {
          payload: { phoneNumber: newPhone },
        } = yield take(REGISTRATION_SUBMITTED);
        phoneNumber = newPhone;
        break;
      case USERNAME_ALREADY_EXISTS:
        yield put(usernameAlreadyExists(i18n.t('registration:Details.errors.userAlreadyExists')));
        break registrationLoop;
      case UNHANDLED_REGISTRATION_ERROR:
        yield put(unhandledRegistrationError(i18n.t('registration:Details.errors.generic')));
        break registrationLoop;
      default:
        break;
    }
  }
}

function* doRegistration(
  pipJWT,
  invitationCode,
  invitationJSON,
  password,
  phoneNumber,
  mfaPreference,
) {
  const userPool = getUserPool();
  const email = invitationJSON.email;
  const locale = invitationJSON.profile.language;

  try {
    yield cps(
      userPool.signUp.bind(userPool),
      email.replace('@', '*'),
      password,
      [
        new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        }),
        new CognitoUserAttribute({
          Name: 'phone_number',
          Value: phoneNumber,
        }),
        new CognitoUserAttribute({
          Name: 'locale',
          Value: locale,
        }),
      ],
      [],
    );
  } catch (e) {
    console.error(e);
    if (e.code === 'InvalidParameterException' && e.message.contains('phone number')) {
      return 'INVALID_PHONE';
    }
    if (e.code === 'UsernameExistsException') {
      return USERNAME_ALREADY_EXISTS;
    }
    return UNHANDLED_REGISTRATION_ERROR;
  }

  const cognitoUser = new CognitoUser({ Username: email.replace('@', '*'), Pool: userPool });
  const result = yield call(doCognitoLogin, cognitoUser, email, password);

  if (result.status === 'FAILURE') {
    // TODO: handle fail stuff
  }

  const sub = cognitoUser.signInUserSession.idToken.payload.sub;
  const idmInviteClient = createIDMInviteClient();
  const idmProfile = yield call(idmInviteClient.consume, invitationCode, sub);
  const { appToken } = yield select(selectAppDetails);
  // Create our "appUser" in pip using our current staff token acquired via registration.
  const pip = new PIPClient({ jwt: pipJWT }, { apiRoot: pipApiRoot });
  const pipApp = yield call(pip.getApp, appToken);
  yield call(pip.createAppUser, pipApp.uuid, sub);

  yield put(loginSubmitted({ email, password }));
  yield take(SESSION_ESTABLISHED);

  const service = yield doCreateIDMService();
  const updatedIdmProfile = { ...idmProfile.profile, phoneNumber };
  yield call(
    service.updateUserProfile,
    extractDashboardUserId(invitationJSON.url),
    updatedIdmProfile,
  );

  const allTermsAndConditions = yield select(state =>
    state.termsOfUse.currentUser.tou.concat(state.termsOfUse.currentUser.privacy),
  );
  for (const term of allTermsAndConditions) {
    yield put(acceptTermsOfUse(term.slug));
  }

  yield cps(cognitoUser.getSession.bind(cognitoUser));
  yield cps(cognitoUser.enableMFA.bind(cognitoUser));
  yield call(setPreferredMfaSettings, mfaPreference, cognitoUser);
  yield put(setMFAPreferenceSuccessful(mfaPreference));

  yield put(registrationSuccess());
  return 'SUCCESS';
}
