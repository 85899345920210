import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form } from 'antd';
import uuid from 'uuid/v4';
import moment from 'moment';
import DetailForm from '@components/DetailForm';
import { Card, Column } from '@cards/Card';
import { selectAppUser } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import {
  fetchAppUserJourneys,
  fetchAppUsersPathways,
  editAppUserJourney,
} from '../../../redux/appUserPathways/actions';
import {
  selectJourneysForAppUser,
  selectPathwaysForAppUserWithOriginals,
  selectAppUserPathwaysUpdating,
} from '../../../redux/appUserPathways/reducers';
import Pathways from './Pathways';
import './style.less';

function EditJourney({ form }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    params: { id: appUserId },
  } = match;
  const isJourneyUpdating = useSelector(selectAppUserPathwaysUpdating);
  const [pathwayTemplatesLoading, allPathways] = useSelector(selectPathways);
  const [pathwayTemplates, setPathwayTemplates] = useState([]);
  const [pathwaysForJourney, setPathwaysForJourney] = useState(null);
  const [appUserLoading, appUser] = useSelector(selectAppUser(appUserId));
  const [journeysLoading, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [appUserPathwaysLoading, appUserPathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [indexEventTypesLoading, indexEventTypes] = useSelector(selectIndexEvents);
  const { i18n, t } = useTranslation();

  const loading =
    appUserLoading ||
    journeysLoading ||
    appUserPathwaysLoading ||
    indexEventTypesLoading ||
    pathwayTemplatesLoading;
  const journey = journeys ? journeys[0] : null;

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchAppUserJourneys(appUserId));
    dispatch(fetchAppUsersPathways());
    dispatch(fetchIndexEvents());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allPathways?.length > 0)
      setPathwayTemplates(allPathways.filter(pathway => !pathway.isSnapshot));
  }, [allPathways]);

  useEffect(() => {
    if (!pathwayTemplatesLoading && !pathwaysForJourney && appUserPathways && journey) {
      setPathwaysForJourney(
        appUserPathways.filter(
          ({ journeyId: pathwayJourneyId }) => pathwayJourneyId === journey?.id,
        ) || [],
      );
    }
  }, [appUserPathways, journey, pathwayTemplatesLoading, pathwaysForJourney]);

  /**
   * this is needed to account for Index Events that are part of a journey but
   * don't have a value set currently. The pathways API only allows configuring
   * of Index Events that have a value so the Index Events with no values won't show
   * up here without it.
   */
  const indexEventTypesForJourney = useMemo(() => {
    const indexEventSlugs = new Set();
    (pathwaysForJourney || []).forEach(pathway => {
      if (pathway?.originalPathway?.indexEvents) {
        pathway.originalPathway.indexEvents.forEach(({ eventTypeSlug }) =>
          indexEventSlugs.add(eventTypeSlug),
        );
      }
    });

    return indexEventTypes.filter(({ slug }) => indexEventSlugs.has(slug));
  }, [indexEventTypes, pathwaysForJourney]);

  const indexEventDates = useMemo(
    () =>
      (journey?.indexEvents || []).reduce(
        (prev, indexEvent) => ({
          ...prev,
          [extractSlugFromTypeSlug(indexEvent.eventTypeSlug)]: indexEvent.value,
        }),
        {},
      ),
    [journey],
  );

  const handleSaveJourney = useCallback(() => {
    const indexEventDates = form.getFieldsValue();
    const { newPathways, updatedPathways } = pathwaysForJourney.reduce(
      (prev, pathway) =>
        pathway.isNewlyAdded
          ? { ...prev, newPathways: [...prev.newPathways, pathway] }
          : { ...prev, updatedPathways: [...prev.updatedPathways, pathway] },
      { newPathways: [], updatedPathways: [] },
    );

    dispatch(
      editAppUserJourney(appUserId, journey?.id, { indexEventDates, newPathways, updatedPathways }),
    );
  }, [appUserId, dispatch, form, journey, pathwaysForJourney]);

  const addPathwaysToJourney = useCallback(
    selectedIds => {
      const pathwayIdsToAdd = selectedIds.filter(
        selectedId =>
          !(pathwaysForJourney || []).some(
            ({ originalPathway }) => originalPathway.id === selectedId,
          ),
      );

      const pathwaysToAdd = pathwayIdsToAdd.map(selectedId => {
        const pathway = pathwayTemplates.find(({ id }) => selectedId === id);

        return { originalPathway: pathway, isNewlyAdded: true, id: uuid() };
      });

      setPathwaysForJourney([...(pathwaysForJourney || []), ...pathwaysToAdd]);
    },
    [pathwayTemplates, pathwaysForJourney, setPathwaysForJourney],
  );

  const deletePathwayFromJourney = useCallback(
    pathwayId => {
      setPathwaysForJourney(pathwaysForJourney.filter(({ id }) => id !== pathwayId));
    },
    [pathwaysForJourney],
  );

  const updatePathway = useCallback(
    (pathwayId, updatedFields) => {
      setPathwaysForJourney(
        pathwaysForJourney.map(pathway =>
          pathway.id === pathwayId ? { ...pathway, ...updatedFields } : pathway,
        ),
      );
    },
    [pathwaysForJourney],
  );

  const pathways = useMemo(
    () =>
      (pathwaysForJourney || []).map(({ id, originalPathway, isNewlyAdded, isActive }) => ({
        ...originalPathway,
        appUserPathwayId: id,
        isNewlyAdded,
        isAppUserPathwayActive: isActive,
      })),
    [pathwaysForJourney],
  );

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <Column>
      <Card
        className="edit-journey-details-card"
        title={t('cards:JourneyDetails.title')}
        loading={loading}
      >
        <DetailForm>
          <h4 className="index-event-heading">{t('cards:JourneyDetails.indexEvents')}</h4>
          {indexEventTypesForJourney.length
            ? indexEventTypesForJourney.map(indexEvent => (
                <Form.Item
                  key={indexEvent.id}
                  label={indexEvent.translatedNames[languageCode] || indexEvent.name}
                >
                  {form.getFieldDecorator(indexEvent.slug, {
                    initialValue: indexEventDates[indexEvent.slug]
                      ? moment(indexEventDates[indexEvent.slug])
                      : undefined,
                  })(<DatePicker format="LL" />)}
                </Form.Item>
              ))
            : t('cards:JourneyDetails.noIndexEvents')}
        </DetailForm>
        <Pathways
          pathways={pathways}
          pathwayTemplates={pathwayTemplates}
          addPathways={addPathwaysToJourney}
          removePathway={deletePathwayFromJourney}
          updatePathway={updatePathway}
        />
        <Button loading={isJourneyUpdating} type="primary" onClick={handleSaveJourney}>
          {t('cards:JourneyDetails.edit.save')}
        </Button>
      </Card>
    </Column>
  );
}

export default Form.create()(EditJourney);
