import {
  HOSPITAL_DETAILS_SUBMITTED,
  HOSPITAL_SOCIAL_MEDIA_SUBMITTED,
  APP_TERMS_CONDITION_SUBMITTED,
  APP_PRIVACY_POLICY_SUBMITTED,
  DASHBOARD_TERMS_CONDITION_SUBMITTED,
  DASHBOARD_PRIVACY_POLICY_SUBMITTED,
  ONBOARDING_COMPLETED,
} from './actions';

const initalState = {
  loading: false,
  error: false,
  details: {},
  socialMedia: [],
  app: {
    terms: {},
    privacy: {},
  },
  dashboard: {
    terms: {},
    privacy: {},
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case HOSPITAL_DETAILS_SUBMITTED:
      return {
        ...state,
        details: {
          address: action.payload.address,
          country: action.payload.country,
          hours: action.payload.hours,
          phone: action.payload.phone,
          state: action.payload.state,
          type: action.payload.type,
        },
      };
    case HOSPITAL_SOCIAL_MEDIA_SUBMITTED:
      return {
        ...state,
        socialMedia: action.payload.map(item => ({
          linkValue: item.linkValue,
          socialMedia: item.socialMedia,
        })),
      };
    case APP_TERMS_CONDITION_SUBMITTED:
      return {
        ...state,
        app: {
          ...state.app,
          terms: {
            description: action.payload.description,
            language: action.payload.language,
            title: action.payload.title,
            AppTermsPolicy: [...action.payload.AppTermsPolicy],
          },
        },
      };
    case APP_PRIVACY_POLICY_SUBMITTED:
      return {
        ...state,
        app: {
          ...state.app,
          privacy: {
            description: action.payload.description,
            language: action.payload.language,
            title: action.payload.title,
            AppPrivacyPolicy: [...action.payload.AppPrivacyPolicy],
          },
        },
      };
    case DASHBOARD_TERMS_CONDITION_SUBMITTED: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          terms: {
            description: action.payload.description,
            language: action.payload.language,
            title: action.payload.title,
            DashboardTermsPolicy: [...action.payload.DashboardTermsPolicy],
          },
        },
      };
    }
    case DASHBOARD_PRIVACY_POLICY_SUBMITTED: {
      return {
        ...state,
        loading: true,
        dashboard: {
          ...state.dashboard,
          privacy: {
            description: action.payload.description,
            language: action.payload.language,
            title: action.payload.title,
            DashboardPrivacyPolicy: [...action.payload.DashboardPrivacyPolicy],
          },
        },
      };
    }
    case ONBOARDING_COMPLETED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const isLoading = state => state.onboarding.loading;
