import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteContentMessages, fetchContentMessages } from '@redux/contentMessages/actions';
import { selectContentMessageDeleting } from '@redux/contentMessages/selectors';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { MessageSelectorContext } from '@pages/CannedMessages';
import createColumns from './createColumns';
import SearchableTable from '@components/SearchableTable';
import IconButton from '@components/IconButton';
import ContentRef from '@utils/contentRef';

const confirmDelete = (onOk, title) => {
  Modal.confirm({
    title,
    onOk,
    onCancel: () => {},
  });
};

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.body);
};

const MessageListCard = ({ showTitle, messageTranslationKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const selector = useContext(MessageSelectorContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const columns = useMemo(() => createColumns(t, messageTranslationKey), [
    t,
    messageTranslationKey,
  ]);

  useEffect(() => {
    dispatch(fetchContentMessages());
  }, [dispatch]);

  const [loading, messages] = useSelector(selector);

  return (
    <SearchableTable
      columns={columns}
      dataSource={messages}
      rowKey="content"
      extra={
        <>
          <IconButton
            disabled={!selectedRows.length}
            icon={faTrashAlt}
            tooltip={t('common:buttons.delete')}
            onClick={() =>
              confirmDelete(() => {
                const messageIds = selectedRows.map(row => ContentRef.fromString(row).contentId);
                dispatch(deleteContentMessages(messageIds, messageTranslationKey));
                return waitForStoreUpdate(store, selectContentMessageDeleting); // must return for modal to be dismissed
              }, t(`${messageTranslationKey}:MessagesList.confirmDelete`, { count: selectedRows.length }))
            }
          />
        </>
      }
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? t('cards:MessagesList.title') : null}
      onRowSelectionChange={(selectedRowKeys, selectedRows) => setSelectedRows(selectedRowKeys)}
    />
  );
};

export default MessageListCard;
