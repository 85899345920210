import React from 'react';
import { Switch, Route } from 'react-router-dom';
import IndexEventList from './List';
import IndexEventDetails from './Details';
import NewIndexEvent from './New';
import EditIndexEvent from './Edit';
import { matchType } from '../../../propTypes';
import './style.less';

function IndexEvents({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/new`} component={NewIndexEvent} />
      <Route path={`${match.path}/:id/edit`} component={EditIndexEvent} />
      <Route path={`${match.path}/:id`} component={IndexEventDetails} />
      <Route path={`${match.path}`} component={IndexEventList} />
    </Switch>
  );
}

IndexEvents.propTypes = {
  match: matchType.isRequired,
};

export default IndexEvents;
