import React, { createContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import DocumentList from './DocumentList';
import DocumentDetail from './DocumentDetail';
import NewDocument from './New';
import EditDocument from './Edit';

export const SelectorContext = createContext();

export default ({ match, documentTranslationKey, selector, children }) => {
  return (
    <SelectorContext.Provider value={selector}>
      <Switch>
        {children}
        <Route path={`${match.path}/new`} component={NewDocument} />
        <Route path={`${match.path}/:id/edit`} component={EditDocument} />
        <Route
          path={`${match.path}/:id`}
          render={props => (
            <DocumentDetail {...props} documentTranslationKey={documentTranslationKey} />
          )}
        />
        <Route
          path={match.path}
          render={props => (
            <DocumentList {...props} documentTranslationKey={documentTranslationKey} />
          )}
        />
      </Switch>
    </SelectorContext.Provider>
  );
};
