import { combineReducers } from 'redux';
import { IMessage } from './types';
import {
  IMessagesLoaded,
  ILoadMessages,
  IDeleteScheduledMessages,
  IDeleteScheduledMessagesFailed,
  IDeleteScheduledMessagesSuccess,
  ILoadMessagesFailed,
  ICreateScheduledMessage,
  ICreateScheduledMessageFailed,
  ICreateScheduledMessageSuccess,
} from './actions';

const loading = (
  state = false,
  action:
    | IMessagesLoaded
    | ILoadMessages
    | IDeleteScheduledMessages
    | IDeleteScheduledMessagesFailed
    | IDeleteScheduledMessagesSuccess
    | ILoadMessagesFailed
    | ICreateScheduledMessage
    | ICreateScheduledMessageFailed
    | ICreateScheduledMessageSuccess,
): boolean => {
  switch (action.type) {
    case 'messages/delete-many-scheduled':
    case 'messages/load':
    case 'messages/create-scheduled':
      return true;
    case 'messages/loaded':
    case 'messages/delete-scheduled-failed':
    case 'messages/delete-scheduled-success':
    case 'messages/load-failed':
    case 'messages/create-scheduled-failed':
    case 'messages/create-scheduled-success':
      return false;
    default:
      return state;
  }
};

function sent(state: IMessage[] = [], action: IMessagesLoaded): IMessage[] {
  switch (action.type) {
    case 'messages/loaded':
      return action.payload.sent;
    default:
      return state;
  }
}

function scheduled(
  state: IMessage[] = [],
  action: IMessagesLoaded | IDeleteScheduledMessagesSuccess | ICreateScheduledMessageSuccess,
): IMessage[] {
  switch (action.type) {
    case 'messages/create-scheduled-success':
      return [action.payload.newMessage, ...state];
    case 'messages/loaded':
      return action.payload.scheduled;
    case 'messages/delete-scheduled-success':
      return state.filter(({ id }) => !action.payload.messageIds.includes(id));
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  scheduled,
  sent,
});
