import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch, Button } from 'antd';
import {
  fetchAppUsers,
  activateAppUser,
  deactivateAppUser,
  resendAppUserInvite,
} from '@redux/appUsers/actions';
import {
  selectAppUser,
  selectAppUsersTogglingAccess,
  selectResendingAppUserInvite,
} from '@redux/appUsers/reducers';
import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import './styles.less';

const AppUserAppAccessCard = ({ appUserId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(appUserId));
  const resendingInvite = useSelector(selectResendingAppUserInvite);
  const togglingAccess = useSelector(selectAppUsersTogglingAccess);
  const isActive = appUser && appUser.isActive;

  const isCodeConsumed = useMemo(() => appUser?.invitationCodes.some(({ consumed }) => consumed), [
    appUser,
  ]);

  return (
    <Card.Half
      className="app-user-app-access-card"
      loading={loading}
      title={t('cards:PatientAppAccess.title')}
      {...props}
    >
      <DetailForm>
        <DetailRow
          label={
            isActive
              ? t('cards:PatientAppAccess.labels.deactivateAccount')
              : t('cards:PatientAppAccess.labels.activateAccount')
          }
          value={
            <Switch
              checked={isActive}
              loading={togglingAccess}
              onChange={value =>
                value === true
                  ? dispatch(activateAppUser(appUserId))
                  : dispatch(deactivateAppUser(appUserId))
              }
            />
          }
        />
      </DetailForm>
      {isCodeConsumed ? null : (
        <div className="buttons">
          <Button
            loading={resendingInvite}
            type="primary"
            onClick={() => dispatch(resendAppUserInvite(appUserId))}
          >
            {t('cards:PatientAppAccess.buttons.sendCode')}
          </Button>
        </div>
      )}
    </Card.Half>
  );
};

AppUserAppAccessCard.propTypes = {
  appUserId: string.isRequired,
};

AppUserAppAccessCard.defaultProps = {};

export default AppUserAppAccessCard;
