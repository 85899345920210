type Document = {
  name: string;
  description?: string;
  metadata?: {
    tags?: {
      label: string;
    }[];
  };
};

const searchDocuments = (value: Document, _: any, searchTerm: string) => {
  if (!searchTerm || searchTerm.length < 3) {
    return true;
  }

  const lowercaseTags = value.metadata?.tags?.map(tag => tag.label.toLocaleLowerCase()) || [];
  const name = value.name.toLowerCase();
  const description = value.description?.toLowerCase() || '';

  const splitTerms = searchTerm
    .split(' ')
    .map(t => t.toLocaleLowerCase())
    .filter(t => t.length > 0);
  for (let t of splitTerms) {
    const matchingTag = lowercaseTags.find(tag => tag.includes(t));
    if (!matchingTag && !name.includes(t) && !description.includes(t)) {
      return false;
    }
  }

  return true;
};

export default searchDocuments;
