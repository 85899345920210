import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagIcon from '@components/FlagIcon';
import SelectionModal from '@components/SelectionModal';

const createColumns = t => [
  {
    title: t('appuser:AppUserFlagModal.columns.flag'),
    dataIndex: 'colour',
    render: colour => <FlagIcon colour={colour} />,
  },
  {
    title: t('appuser:AppUserFlagModal.columns.name'),
    dataIndex: 'name',
  },
  {
    title: t('appuser:AppUserFlagModal.columns.description'),
    dataIndex: 'description',
  },
];

const FlagModal = ({ visible, onOk, onCancel, organisationFlags, selected }) => {
  const { t } = useTranslation();

  const [displayFlags, setDisplayFlags] = useState([]);
  const [selectedFlags, setSelectedFlags] = useState([]);

  useEffect(() => {
    setDisplayFlags(organisationFlags.filter(of => !of.isDisabled || selected.includes(of.uuid)));
    setSelectedFlags(organisationFlags.filter(of => selected.includes(of.uuid)).map(of => of.uuid));
  }, [organisationFlags, selected, setDisplayFlags, setSelectedFlags]);

  const onSubmit = () => onOk(selectedFlags);

  return (
    <SelectionModal
      columns={createColumns(t)}
      dataSource={displayFlags}
      displayKeys={['name', 'description']}
      visible={visible}
      title={t('appuser:AppUserFlagModal.title')}
      primaryKey="uuid"
      selectedRowKeys={selectedFlags}
      onSelectRowKeys={setSelectedFlags}
      onSubmit={onSubmit}
      onCancel={onCancel}
      addButtonText={t('common:buttons:ok')}
      isSelectionOptional
    />
  );
};

FlagModal.defaultProps = {
  organisationFlags: [],
  selected: [],
};

export default FlagModal;
