import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // createLanguageFilters,
  selectSourceIcon,
  selectSourceLegend,
} from '@utils/supportedLanguages';
// import LanguageIcon from '@components/LanguageIcon';

export default (t, defaultFilteredValue) => [
  // {
  //   title: t('cards:GroupList.columns.language.title'),
  //   dataIndex: 'language',
  //   defaultFilteredValue: defaultFilteredValue.language || [],
  //   key: 'language',
  //   render: language => <LanguageIcon language={language} />,
  //   width: 150,
  //   sorter: (a, b) => a.language > b.language,
  //   filters: createLanguageFilters(t),
  //   onFilter: (value, record) => record.language === value,
  // },
  {
    title: t('cards:GroupList.columns.source.title'),
    dataIndex: 'source',
    defaultFilteredValue: defaultFilteredValue.source || [],
    key: 'source',
    render: source => (
      <Tooltip placement="bottom" title={selectSourceLegend(source, t)}>
        <FontAwesomeIcon size="lg" icon={selectSourceIcon(source)} />
      </Tooltip>
    ),
    width: 130,
    sorter: (a, b) => a.source > b.source,
    filters: [
      {
        text: t('common:sources.global'),
        value: 'global',
      },
      {
        text: t('common:sources.country'),
        value: 'country',
      },
      {
        text: t('common:sources.hospital'),
        value: 'hospital',
      },
    ],
    onFilter: (value, record) => record.source === value,
  },
  {
    title: t('cards:GroupList.columns.title'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => a.name > b.name,
  },
];
