export const FETCH_GROUPS = 'FETCH_GROUPS';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const GROUPS_LOADED = 'GROUPS_LOADED';
export const ADD_USERS_TO_GROUPS = 'ADD_USERS_TO_GROUPS';
export const ADD_DOCUMENTS_TO_GROUPS = 'ADD_DOCUMENTS_TO_GROUPS';
export const ADD_FORMS_TO_GROUPS = 'ADD_FORMS_TO_GROUPS';
export const SEND_MESSAGE_TO_GROUPS = 'SEND_MESSAGE_TO_GROUPS';

export const fetchGroups = () => ({
  type: FETCH_GROUPS,
  payload: {},
});

export const loadGroups = () => ({
  type: LOAD_GROUPS,
  payload: {},
});

export const groupsLoaded = groups => ({
  type: GROUPS_LOADED,
  payload: {
    groups,
  },
});

export const addUsersToGroups = (userIds, groupIds) => ({
  type: ADD_USERS_TO_GROUPS,
  payload: { userIds, groupIds },
});

export const addDocumentsToGroups = (groupIds, formIds) => ({
  type: ADD_DOCUMENTS_TO_GROUPS,
  payload: { formIds, groupIds },
});

export const addFormsToGroups = (groupIds, documentIds) => ({
  type: ADD_FORMS_TO_GROUPS,
  payload: { documentIds, groupIds },
});

export const sendMessageToGroups = (groupIds, message) => ({
  type: SEND_MESSAGE_TO_GROUPS,
  payload: { groupIds, message },
});
