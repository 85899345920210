import React from 'react';
import { oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card } from '@cards/Card';
import { NOW } from './Compose';

function getDateSentMessage(t, dateSent, frequency, repeatEvery, when) {
  if (when === NOW) return t('messages:Wizard.timing.summary.now');
  if (!dateSent) return t('messages:Wizard.timing.summary.noTime');
  const dateSentMoment = moment(dateSent);

  if (frequency === 'repeat' && repeatEvery?.number && repeatEvery?.units) {
    return t('messages:Wizard.timing.summary.repeatingMessage', {
      date: dateSentMoment.format('LL'),
      time: dateSentMoment.format('LT'),
      repeatNumber: repeatEvery.number,
      repeatUnits: t(`common:timeIntervals.${repeatEvery.units}`),
    });
  }

  return t('messages:Wizard.timing.summary.nonrepeatingMessage', {
    date: dateSentMoment.format('LL'),
    time: dateSentMoment.format('LT'),
  });
}

function Summary({ dateSent, frequency, repeatEvery, repeatStops, title, when }) {
  const { t } = useTranslation();
  const stopDateMoment = moment(repeatStops ? repeatStops.stopDate : undefined);

  return (
    <Card title={title}>
      <p>{getDateSentMessage(t, dateSent, frequency, repeatEvery, when)}</p>
      {repeatStops && repeatStops.stop === 'on-date' ? (
        <p>{t('messages:Wizard.timing.summary.stopsOn', { date: stopDateMoment.format('LL') })}</p>
      ) : null}
    </Card>
  );
}

Summary.propTypes = {
  dateSent: oneOfType([string, shape({})]),
  frequency: string,
  repeatEvery: shape({ number: string, units: string }),
  repeatStops: shape({ stop: string, stopDate: string }),
  title: string.isRequired,
  when: string.isRequired,
};

export default Summary;
