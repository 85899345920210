import { useState, useCallback, useEffect } from 'react';

const comparator = searchTerm => value =>
  value && value.toLowerCase().includes(searchTerm.toLowerCase());

export default (initialData, filter, minLength = 0, initialSearchTerm = '') => {
  const [searchTerm, setTerm] = useState(initialSearchTerm);
  const [results, setResults] = useState(initialData);
  const clearSearch = useCallback(() => setTerm(''), [setTerm]);

  useEffect(() => {
    if (!searchTerm || searchTerm.length < minLength) {
      setResults(initialData);
      return;
    }

    let localResults = initialData;
    searchTerm
      .trim()
      .split(' ')
      .forEach(term => {
        const trimmedTerm = term.trim();
        const compareFunc = comparator(trimmedTerm);
        localResults = [...localResults.filter(value => filter(value, compareFunc, trimmedTerm))];
      });
    setResults(localResults);
  }, [searchTerm, initialData, filter, minLength]);

  return [
    results,
    {
      value: searchTerm,
      onChange: event => {
        const value = event.target.value;
        setTerm(value);
      },
      onSearch: setTerm,
    },
    clearSearch,
  ];
};
