import { call, getContext } from 'redux-saga/effects';
import createUbiquityClient, { createUbiquityV2Client } from '@api/ubiquityClient';

function* doCreateUbiquityClient() {
  const tokens = yield getContext('tokens');
  const client = yield call(createUbiquityClient, tokens);

  return client;
}

function* doCreateUbiquityV2Client() {
  const tokens = yield getContext('tokens');
  return yield call(createUbiquityV2Client, tokens);
}

export default doCreateUbiquityClient;

export { doCreateUbiquityV2Client };
