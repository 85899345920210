import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { checkingSession, hasValidSession } from '@redux/login/reducer';
import { logoColour as logo } from '@assets';

export default ({ children }) => {
  const loading = useSelector(checkingSession);
  const authenticated = useSelector(hasValidSession);
  const { pathname } = useLocation();

  if (loading) {
    return (
      <div className="loader">
        <img src={logo} alt="Loading" />
      </div>
    );
  }
  // Authenticated users go to /
  if (authenticated && pathname.startsWith('/auth')) {
    return <Redirect to="/" />;
  }
  // Unauthenticated users can only see /auth
  if (authenticated || pathname.startsWith('/auth')) {
    return children;
  }
  // Unauthenticated users go to /auth
  return <Redirect to="/auth" />;
};
