function createDataSource(t, getStageAction, stages, currentStageSlug) {
  const dataSource = [...stages]
    .reduce((acc, stage) => (stage.isAdhoc ? acc : [...acc, stage]), [])
    .sort(({ number: a }, { number: b }) => a - b);

  const currentStageIndex = dataSource.findIndex(stage => stage.slug === currentStageSlug);

  return [
    dataSource.map((stage, index) => ({
      ...stage,
      action: getStageAction(index, currentStageIndex, dataSource),
    })),
    currentStageIndex,
  ];
}

export default createDataSource;
