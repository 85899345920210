import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectContentReminders } from '@redux/contentMessages/selectors';
import { fetchContentMessages } from '@redux/contentMessages/actions';
import createColumns from './createMessageColumns';
import ContentModal from './Modal';
import { MESSAGE_REMINDER, whatTypeToTranslationKey } from '../../../../../../constants';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function SelectReminderModal({ defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, forms] = useSelector(selectContentReminders);
  const contentTypeTranslationKey = whatTypeToTranslationKey(MESSAGE_REMINDER);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue, contentTypeTranslationKey), [
    defaultFilteredValue,
    t,
    contentTypeTranslationKey,
  ]);

  useEffect(() => {
    dispatch(fetchContentMessages());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={forms}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.reminder')}
      filterFunc={filterFunc}
      onCancel={onCancel}
      onOk={data => {
        const { latestVersion, ...message } = data;
        onOk({ ...message, ...latestVersion });
      }}
    />
  );
}

SelectReminderModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectReminderModal;
