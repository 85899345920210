import React, { useEffect, useMemo } from 'react';
import { bool } from 'prop-types';
import { Form } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DetailRow from '@components/DetailRow';
import { fetchMessages } from '@redux/messages/actions';
import { Card } from '../../Card';
import { sentMessageType } from '../../../propTypes';
import './style.less';

function Timing({ loading, message }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sentAt = useMemo(() => moment(message.nextSendDatetime).format('LLLL'), [message]);

  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  return (
    <Card.Half
      className="message-timing-card"
      title={t('cards:SentMessageTiming.title')}
      loading={loading}
    >
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <DetailRow label={t('cards:SentMessageTiming.sendDate')} value={sentAt} />
        {message.isRecurring ? (
          <DetailRow
            label={t('cards:SentMessageTiming.rule')}
            value={t('cards:SentMessageTiming.ruleValue', {
              number: message.recurringOffset,
              unit: message.recurringUnit,
            })}
          />
        ) : null}
      </Form>
    </Card.Half>
  );
}

Timing.propTypes = {
  loading: bool,
  message: sentMessageType.isRequired,
};

Timing.defaultProps = {
  loading: false,
};

export default Timing;
