import { ConnectionCode } from './types';

export type ILoadConnectionCodes = ReturnType<typeof loadConnectionCodes>;
export const loadConnectionCodes = () => ({
  type: 'connectionCodes/load' as const,
  payload: {},
});

export type ILoadConnectionCodesSucceeded = ReturnType<typeof loadConnectionCodesSucceeded>;
export const loadConnectionCodesSucceeded = (connectionCodes: ConnectionCode[]) => ({
  type: 'connectionCodes/loadSucceeded' as const,
  payload: {
    connectionCodes,
  },
});

export type ILoadConnectionCodesFailed = ReturnType<typeof loadConnectionCodesFailed>;
export const loadConnectionCodesFailed = () => ({
  type: 'connectionCodes/loadFailed' as const,
  payload: {},
});

export type ICreateConnectionCode = ReturnType<typeof createConnectionCode>;
export const createConnectionCode = (pathwayIds: number[]) => ({
  type: 'connectionCodes/create' as const,
  payload: { pathwayIds },
});

export type ICreateConnectionCodeSuccess = ReturnType<typeof createConnectionCodeSucceeded>;
export const createConnectionCodeSucceeded = (connectionCode: ConnectionCode) => ({
  type: 'connectionCodes/createSucceeded' as const,
  payload: { connectionCode },
});

export type ICreateConnectionCodeFailed = ReturnType<typeof createConnectionCodeFailed>;
export const createConnectionCodeFailed = () => ({
  type: 'connectionCodes/createFailed' as const,
  payload: {},
});

export type IUpdateConnectionCodePathways = ReturnType<typeof updateConnectionCodePathways>;
export const updateConnectionCodePathways = (codeId: number, pathwayIds: number[]) => ({
  type: 'connectionCodes/updatePathways' as const,
  payload: { codeId, pathwayIds },
});

export type IUpdateConnectionCodePathwaysSuccess = ReturnType<
  typeof updateConnectionCodePathwaysSucceeded
>;
export const updateConnectionCodePathwaysSucceeded = (connectionCode: ConnectionCode) => ({
  type: 'connectionCodes/updatePathwaysSucceeded' as const,
  payload: { connectionCode },
});

export type IUpdateConnectionCodePathwaysFailed = ReturnType<
  typeof updateConnectionCodePathwaysFailed
>;
export const updateConnectionCodePathwaysFailed = () => ({
  type: 'connectionCodes/updatePathwaysFailed' as const,
  payload: {},
});

export type ISetConnectionCodeAsDefault = ReturnType<typeof setConnectionCodeAsDefault>;
export const setConnectionCodeAsDefault = (codeId: number) => ({
  type: 'connectionCodes/setDefault' as const,
  payload: { codeId },
});

export type ISetConnectionCodeAsDefaultSuccess = ReturnType<
  typeof setConnectionCodeAsDefaultSucceeded
>;
export const setConnectionCodeAsDefaultSucceeded = (modifiedConnectionCodes: ConnectionCode[]) => ({
  type: 'connectionCodes/setDefaultSucceeded' as const,
  payload: { modifiedConnectionCodes },
});

export type ISetConnectionCodeAsDefaultFailed = ReturnType<typeof setConnectionCodeAsDefaultFailed>;
export const setConnectionCodeAsDefaultFailed = () => ({
  type: 'connectionCodes/setDefaultFailed' as const,
  payload: {},
});

export type IToggleConnectionCodeIsDisabled = ReturnType<typeof toggleConnectionCodeIsDisabled>;
export const toggleConnectionCodeIsDisabled = (codeId: number) => ({
  type: 'connectionCodes/toggleDisabled' as const,
  payload: { codeId },
});

export type IToggleConnectionCodeIsDisabledSuccess = ReturnType<
  typeof toggleConnectionCodeIsDisabledSucceeded
>;
export const toggleConnectionCodeIsDisabledSucceeded = (connectionCode: ConnectionCode) => ({
  type: 'connectionCodes/toggleDisabledSucceeded' as const,
  payload: { connectionCode },
});

export type IToggleConnectionCodeIsDisabledFailed = ReturnType<
  typeof toggleConnectionCodeIsDisabledFailed
>;
export const toggleConnectionCodeIsDisabledFailed = () => ({
  type: 'connectionCodes/toggleDisabledFailed' as const,
  payload: {},
});
