import {
  put,
  call,
  select,
  takeEvery,
  takeLatest,
  getContext,
  delay,
  all,
} from 'redux-saga/effects';
import { message } from 'antd';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { HOSPITAL } from '@constants';
import { selectAppDetails } from '@organisation/redux/selectors';
import { VIDEO } from '@utils/contentTypes';
import ContentRef from '@utils/contentRef';
import { videoCreationEndpoint, videoExportStatusEndpoint } from 'settings';
import i18next from '../../i18n';
import {
  FETCH_DOCUMENTS,
  LOAD_DOCUMENTS_FAILED,
  FETCH_DOCUMENT_VERSIONS,
  FETCH_DOCUMENT_PAGES,
  PUBLISH_DOCUMENTS,
  UNPUBLISH_DOCUMENTS,
  IRawDocument,
  IDocument,
} from './types';
import {
  loadDocuments,
  createDocumentFailed,
  createDocumentSuccess,
  deleteDocumentsFailed,
  deleteDocumentsSuccess,
  documentsLoaded,
  fetchDocumentVersions,
  documentVersionsLoaded,
  documentPagesLoaded,
  IFetchDocumentPages,
  IFetchDocumentVersions,
  IDeleteDocuments,
  ILoadDocumentsFailed,
  ICreateDocument,
  IEditDocument,
  fetchDocumentPages,
  editDocumentSuccess,
  editDocumentFailed,
  loadDocumentsFailed,
  IDeleteDocument,
  changePublishedStatus,
  IPublishDocuments,
  IUnpublishDocuments,
  publishDocumentsSuccess,
  publishDocumentsFailed,
  unpublishDocumentsSuccess,
  unpublishDocumentsFailed,
  ICreateVideo,
  createVideoSuccess,
  IEditVideo,
  editVideoSuccess,
  createVideoFailed,
  editVideoFailed,
  IGetDocumentPreview,
  documentPreviewFailed,
  documentPreviewLoaded,
  // IFetchDocumentsByIds,
  // documentsByIdsLoaded,
} from './actions';
import { selectDocument } from './reducers';
import doCreateUbiquityClient from '../doCreateUbiquityClient';
// import { doCreateUbiquityV2Client } from '../doCreateUbiquityClient';
import { doDetermineOwnerForContent } from '../doDetermineOwnerIdForContent';
import takeFirst from '../takeFirst';
import { selectOrganisationSlug } from '../../organisation/redux/selectors';

export default function* root() {
  yield takeFirst(FETCH_DOCUMENTS, doFetchDocuments);
  yield takeLatest(LOAD_DOCUMENTS_FAILED, doLoadFailed);
  yield takeLatest(FETCH_DOCUMENT_VERSIONS, doFetchDocumentVersions);
  yield takeEvery(FETCH_DOCUMENT_PAGES, doFetchDocumentPages);
  yield takeEvery(PUBLISH_DOCUMENTS, doPublishDocuments);
  yield takeEvery(UNPUBLISH_DOCUMENTS, doUnpublishDocuments);
  yield takeEvery('documents/deleteOne', doDeleteDocument);
  yield takeEvery('documents/delete', doDeleteDocuments);
  yield takeEvery('documents/create', doCreateDocument);
  yield takeEvery('documents/edit', doEditDocument);
  yield takeEvery('videos/create', doCreateVideo);
  yield takeEvery('videos/edit', doEditVideo);
  yield takeEvery('documents/get-preview', doGetDocumentPreview);
  // yield takeEvery(FETCH_DOCUMENTS_BY_IDS, doFetchDocumentsByIds);
}

function createTagsFromMetadata(metadata: {
  language: string;
  source: string;
  hospitalId?: string;
}) {
  return [
    { term: metadata.language.toUpperCase(), label: metadata.language.toUpperCase() },
    { term: metadata.source.toUpperCase(), label: metadata.source.toUpperCase() },
    ...(metadata.hospitalId ? [{ term: metadata.hospitalId, label: 'HOSPITAL_ID' }] : []),
  ];
}

function* doDeleteDocuments({
  payload: { documentIds, documentTranslationKey },
}: IDeleteDocuments): any {
  try {
    const client = yield call(doCreateUbiquityClient);
    const { appId } = yield select(selectAppDetails);

    for (const documentId of documentIds) {
      yield call(client.deleteDocument, appId, documentId);
    }

    yield put(deleteDocumentsSuccess(documentIds));
    yield call(
      message.success,
      i18next.t(`${documentTranslationKey}:DocumentDetail.deleteSuccess`),
    );
  } catch (err) {
    console.error(err);
    yield put(deleteDocumentsFailed());
    yield call(message.error, i18next.t(`${documentTranslationKey}:DocumentDetail.deleteFailed`));
  }
}

function* doDeleteDocument({
  payload: { documentId, documentTranslationKey },
}: IDeleteDocument): any {
  try {
    const client = yield call(doCreateUbiquityClient);
    const history = yield getContext('history');
    const { appId } = yield select(selectAppDetails);

    yield call(client.deleteDocument, appId, documentId);

    yield put(deleteDocumentsSuccess([documentId]));
    yield call(history.goBack);
    yield call(
      message.success,
      i18next.t(`${documentTranslationKey}:DocumentDetail.deleteSuccess`),
    );
  } catch (err) {
    console.error(err);
    yield put(deleteDocumentsFailed());
    yield call(message.error, i18next.t(`${documentTranslationKey}:DocumentDetail.deleteFailed`));
  }
}

type PaginatedDocumentsListResult = {
  result: IRawDocument[];
  pagination: {
    num_pages: number;
    current_page: number;
  };
};

export const mapRawDocumentToDocument = (
  rawDocument: IRawDocument,
  organisationId: string,
): IDocument => {
  const contentRef = new ContentRef(organisationId, 'document', rawDocument.product_id);

  // Extract the latest version id from the page list url. This should be added to the data returned by Ubiqiuity
  const latestVersionRe = new RegExp('/versions/([0-9]+)/pages/', 'g');
  const latestVersionResult = latestVersionRe.exec(rawDocument.links.page_list);
  const latestVersionId = latestVersionResult ? Number.parseInt(latestVersionResult[1], 10) : 0;

  return {
    ...rawDocument,
    id: rawDocument.id,
    name: rawDocument.name,
    description:
      rawDocument?.latest_version?.description || rawDocument?.metadata?.custom?.description || '',
    latest_version: {
      ...rawDocument.latest_version,
      id: latestVersionId,
      name: rawDocument.latest_version.name.replace('Version ', ''),
    },
    published: rawDocument.availability !== 'unavailable',
    content: contentRef.toString(),
    audience_type: rawDocument.audience_type,
  };
};

function* doFetchDocuments(): any {
  try {
    yield put(loadDocuments());
    const client = yield call(doCreateUbiquityClient);
    const { appId } = yield select(selectAppDetails);
    const orgId = yield select(selectOrganisationSlug());

    let rawDocuments: IRawDocument[] = [];
    // load first page from Ubiquity API
    let nextDocuments: PaginatedDocumentsListResult = yield call(client.documents, appId, {
      page: 1,
    });
    rawDocuments = rawDocuments.concat(nextDocuments.result);
    // now parallelise all other pages
    const effects = [];
    for (let i = 2; i <= nextDocuments.pagination.num_pages; i++) {
      effects.push(call(client.documents, appId, { page: i }));
    }
    const docPages: PaginatedDocumentsListResult[] = yield all(effects);
    for (const page of docPages) {
      rawDocuments = rawDocuments.concat(page.result);
    }

    const documents = rawDocuments
      .map(d => mapRawDocumentToDocument(d, orgId))
      .sort((a, b) => (a.id > b.id ? -1 : 1));

    yield put(documentsLoaded(documents));
  } catch (err) {
    console.error(err);
    yield put(loadDocumentsFailed('documents'));
  }
}

// function* doFetchDocumentsByIds({ payload: { documentIds } }: IFetchDocumentsByIds): any {
//   console.log('documentids', documentIds);
//   try {
//     // const client = yield call(doCreateUbiquityV2Client);
//     // const { appToken } = yield select(selectAppDetails);
//     // const documents = client.documents(appToken);
//     // console.log('documents', documents);
//   } catch (err) {
//     console.error(err);
//   }
// }

function* doLoadFailed({ payload: { documentTranslationKey } }: ILoadDocumentsFailed) {
  yield call(message.error, i18next.t(`${documentTranslationKey}:failedLoad`));
}

function* doFetchDocumentVersions({ payload: { documentId, force } }: IFetchDocumentVersions): any {
  const existing = yield select(state => state.documents.versions[documentId]);
  if (existing && !force) {
    yield put(documentVersionsLoaded(documentId, existing));
    return;
  }

  const { appId } = yield select(selectAppDetails);
  const client = yield call(doCreateUbiquityClient);
  const versions = yield call(client.documentVersions, appId, documentId);
  yield put(documentVersionsLoaded(documentId, versions));
}

function* doFetchDocumentPages({ payload: { documentId, force } }: IFetchDocumentPages): any {
  try {
    const existing = yield select(state => state.documents.pages[documentId]);
    if (existing && existing.expires > Date.now() && !force) {
      yield put(documentPagesLoaded(documentId, existing.pages, 'cached'));
      return;
    }

    let [, document] = yield select(selectDocument(documentId));
    while (!document) {
      yield delay(1000);
      [, document] = yield select(selectDocument(documentId));
    }

    const client = yield call(doCreateUbiquityClient);
    const pages = yield call(client.pages, document);

    yield put(documentPagesLoaded(documentId, pages));
  } catch (err) {
    console.error(err);
    yield put(documentPagesLoaded(documentId, []));
    yield call(message.error, i18next.t('cards:DocumentContent.error'));
  }
}

function* doCreateDocument({ payload: { newDocument } }: ICreateDocument): any {
  try {
    const history = yield getContext('history');
    const client = yield call(doCreateUbiquityClient);
    const { appId } = yield select(selectAppDetails);
    const ownerId = yield call(doDetermineOwnerForContent);
    const createdDocument = yield call(
      client.createDocument,
      appId,
      newDocument.name,
      newDocument.audience_type,
      null,
      ownerId,
    );
    const {
      id: documentId,
      latest_version: { id: versionId, name: versionName },
    } = createdDocument;

    yield call(client.editDocumentVersion, appId, documentId, versionId, {
      description: newDocument.description,
    });

    if (newDocument.metadata.source === HOSPITAL) {
      const currentDashboardUser = yield select(selectCurrentDashboardUser);
      newDocument.metadata.hospitalId = currentDashboardUser.profile.hospitalId;
    }

    const tags = createTagsFromMetadata(newDocument.metadata);
    yield call(client.editDocument, appId, documentId, newDocument.name, { tags });

    yield call(
      doUploadDocumentVersion,
      documentId,
      versionId,
      versionName,
      newDocument.fileData.originFileObj,
    );

    yield call(client.publish, appId, documentId, versionId);
    if (newDocument.published) {
      yield call(doPublishDocumentVersion, documentId, versionId, newDocument.published);
    }

    const finalDocument: IRawDocument = yield call(client.document, appId, documentId);
    const orgId = yield select(selectOrganisationSlug());
    const mappedFinalDocument: IDocument = mapRawDocumentToDocument(finalDocument, orgId);

    yield put(createDocumentSuccess(mappedFinalDocument));
    yield call(history.push, '/content/documents');
    yield call(message.success, i18next.t('documents:Wizard.review.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18next.t('documents:Wizard.review.error'));
    yield put(createDocumentFailed());
  }
}

function* doEditDocument({ payload: { documentId, editedDocument } }: IEditDocument): any {
  try {
    const history = yield getContext('history');
    const client = yield call(doCreateUbiquityClient);
    const { appId } = yield select(selectAppDetails);

    const [, originalDocument]: [boolean, IDocument] = yield select(
      selectDocument(documentId.toString()),
    );

    if (editedDocument.metadata.source === HOSPITAL) {
      const currentDashboardUser = yield select(selectCurrentDashboardUser);
      editedDocument.metadata.hospitalId = currentDashboardUser.profile.hospitalId;
    }

    const tags = createTagsFromMetadata(editedDocument.metadata);
    yield call(client.editDocument, appId, documentId, editedDocument.name, { tags });

    const documentVersion = yield call(client.createDocumentVersion, appId, documentId, {
      name: editedDocument.name,
      description: editedDocument.description,
      ...(editedDocument.fileData.originFileObj
        ? { creationMode: 'empty' }
        : { creationMode: 'copy', createFromVersion: originalDocument.latest_version.id }),
    });

    const { id: versionId, name: versionName } = documentVersion;

    yield call(client.editDocumentVersion, appId, documentId, versionId, {
      description: editedDocument.description,
    });

    if (editedDocument.fileData.originFileObj) {
      yield call(
        doUploadDocumentVersion,
        documentId,
        versionId,
        versionName,
        editedDocument.fileData.originFileObj,
      );
      yield call(client.publish, appId, documentId, versionId);
    }

    yield call(doPublishDocumentVersion, documentId, versionId, editedDocument.published);
    const updatedDocument: IRawDocument = yield call(client.document, appId, documentId);
    const orgId = yield select(selectOrganisationSlug());
    const mappedUpdatedDocument: IDocument = mapRawDocumentToDocument(
      {
        ...updatedDocument,
        latest_version: {
          ...updatedDocument.latest_version,
          name: updatedDocument?.latest_version.name.replace('Version ', ''),
        },
      },
      orgId,
    );
    yield put(fetchDocumentVersions(`${documentId}`));
    const pages = yield call(client.pages, documentVersion);
    yield put(documentPagesLoaded(`${documentId}`, pages));
    yield put(editDocumentSuccess(documentId, mappedUpdatedDocument));
    yield call(history.goBack);
    yield call(message.success, i18next.t('documents:Wizard.review.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18next.t('documents:Wizard.review.error'));
    yield put(editDocumentFailed());
  }
}

function* doPublishDocumentVersion(documentId: number, versionId: unknown, publish: boolean): any {
  const client = yield call(doCreateUbiquityClient);
  const { appId } = yield select(selectAppDetails);

  // ubiquity availability == published status in dashboard
  yield call(client.setDocumentAvailability, appId, documentId, publish ? 'free' : 'unavailable');
}

function* doUploadDocumentVersion(
  documentId: number,
  versionId: number,
  versionName: string,
  file: File,
): any {
  const client = yield call(doCreateUbiquityClient);
  const { appId } = yield select(selectAppDetails);

  yield call(
    client.uploadDocumentVersion,
    appId,
    documentId,
    versionId,
    {
      fileName: file.name,
      issueName: versionName,
      // crocodoc == pdf for historical ubiquity reasons
      importType: 'crocodoc',
    },
    file,
  );
}

function* doPublishDocuments({ payload: { documentIds } }: IPublishDocuments): any {
  try {
    for (const documentId of documentIds) {
      yield call(doPublishDocumentVersion, documentId, undefined, true);
      yield put(changePublishedStatus(documentId, true));
    }

    yield put(publishDocumentsSuccess(documentIds));
    yield call(message.success, i18next.t('documents:DocumentDetail.publishSuccess'));
  } catch (err) {
    console.error(err);
    yield put(publishDocumentsFailed());
    yield call(message.error, i18next.t('documents:DocumentDetail.publishFailed'));
  }
}

function* doUnpublishDocuments({ payload: { documentIds } }: IUnpublishDocuments): any {
  try {
    for (const documentId of documentIds) {
      yield call(doPublishDocumentVersion, documentId, undefined, false);
      yield put(changePublishedStatus(documentId, false));
    }

    yield put(unpublishDocumentsSuccess(documentIds));
    yield call(message.success, i18next.t('documents:DocumentDetail.unpublishSuccess'));
  } catch (err) {
    console.error(err);
    yield put(unpublishDocumentsFailed());
    yield call(message.error, i18next.t('documents:DocumentDetail.unpublishFailed'));
  }
}

function* callVideoCreationService(postData: { [key: string]: any }): any {
  let exportDetails = undefined;
  // let ceAPIKey = undefined;
  try {
    const resp = yield fetch(videoCreationEndpoint, {
      method: 'POST',
      body: JSON.stringify(postData),
    });
    const data = yield call([resp, resp.json]);

    exportDetails = data['export_details'];
    // ceAPIKey = data['ce_api_key'];
  } catch (e) {
    console.error(e);
    return null;
  }
  let exportStatus = undefined;
  let detailsData = undefined;
  // const fd = new FormData();
  // fd.append('url', exportDetails['url']);
  while (exportStatus !== 'complete' && exportStatus !== 'failed') {
    yield delay(1000);
    const detailsResp = yield fetch(videoExportStatusEndpoint, {
      method: 'POST',
      body: JSON.stringify({ url: exportDetails['url'], ce_api_key: postData.ce_api_key }),
      headers: { 'Content-Type': 'application/json' },
    });
    detailsData = yield call([detailsResp, detailsResp.json]);
    exportStatus = detailsData['status'];
  }
  return detailsData;
}

function buildPostDataForVideo(
  videoDocument: any,
  existingCEDocumentUUID: any,
  existingUbiquityDocumentID: any,
  appId: number,
  companyId: number,
  solutionConfiguration: { [key: string]: any },
  hospitalId?: string,
) {
  const originalData = videoDocument;
  const sourceTag = originalData.metadata.source.toUpperCase();
  const languageTag = originalData.metadata.language.toUpperCase();
  const publishDateLabel = i18next.t('videos:Wizard.details.publishDate');
  const runTimeLabel = i18next.t('videos:Wizard.details.runTime');
  const video_details: string[] = [];
  if (videoDocument.videoData.publishDate) {
    video_details.push(`${publishDateLabel}: ${videoDocument.videoData.publishDate}`);
  }
  if (videoDocument.videoData.runTime) {
    video_details.push(`${runTimeLabel}: ${videoDocument.videoData.runTime}`);
  }
  const customMetadataContent = {
    heading: originalData.videoData.videoTitle,
    video_id: originalData.videoData.videoId,
    description: originalData.videoData.videoDescription,
    publishDate: videoDocument.videoData.publishDate,
    runTime: videoDocument.videoData.runTime,
  };
  const pageFields = { ...customMetadataContent, video_details };
  const templateName = solutionConfiguration.video_ce_details.template_name;
  const postData = {
    ...solutionConfiguration.video_ce_details,
    ce_api_key: solutionConfiguration.ce_api_key,
    ce_organisation_uuid: solutionConfiguration.ce_organisation_uuid,
    ce_doc_uuid: null,
    ce_doc_name: originalData.name,
    template_name: templateName,
    variables: {
      document: {
        custom_metadata: JSON.stringify({
          description: originalData.description,
          content: customMetadataContent,
          audience_type: originalData.audience_type,
        }),
        tags: `[{"term": "${sourceTag}","label": "${sourceTag}"},{"term": "${languageTag}","label": "${languageTag}"},{"term": "VIDEO","label": "VIDEO"} ${
          originalData.metadata.source === HOSPITAL
            ? `,{"term": "${hospitalId}", "label": "HOSPITAL_ID"}`
            : ''
        }]`,
      },
      pages: {
        'page-1': {
          title: 'Page 1',
          tags: '[]',
          fields: pageFields,
        },
      },
    },
    export_details: {
      theme_slug: solutionConfiguration.video_ubiquity_export_details.theme_slug,
      ubiquity_category_ids:
        solutionConfiguration.video_ubiquity_export_details.ubiquity_category_ids,
      ubiquity_company_id: companyId,
      ubiquity_app_id: appId,
      use_existing_document: false,
      ubiquity_existing_document_id: null,
    },
  };
  if (existingCEDocumentUUID) {
    postData.ce_doc_uuid = existingCEDocumentUUID;
    postData.export_details.use_existing_document = true;
    postData.export_details.ubiquity_existing_document_id = existingUbiquityDocumentID;
  }
  return postData;
}

function* doCreateVideo({ payload: { newDocument } }: ICreateVideo): any {
  const history = yield getContext('history');
  try {
    const currentDashboardUser = yield select(selectCurrentDashboardUser);
    const ownerId = yield call(doDetermineOwnerForContent);
    const { appId, companyId, solutionConfiguration } = yield select(selectAppDetails);
    const postData = buildPostDataForVideo(
      newDocument,
      null,
      null,
      appId,
      companyId,
      solutionConfiguration,
      currentDashboardUser.profile.hospitalId,
    );
    const exportDetails = yield call(callVideoCreationService, postData);

    if (exportDetails['status'] === 'complete') {
      const re = /documents\/(\d+)\/content/;
      const ubiquityDocumentId = parseInt(exportDetails['file'].match(re)[1]);
      const client = yield call(doCreateUbiquityClient);
      const finalDocument: IRawDocument = yield call(client.document, appId, ubiquityDocumentId);
      const orgId = yield select(selectOrganisationSlug());
      const mappedFinalDocument: IDocument = mapRawDocumentToDocument(finalDocument, orgId);

      yield call(
        client.editDocument,
        appId,
        ubiquityDocumentId,
        newDocument.name,
        undefined,
        ownerId,
      );

      const versions = yield call(client.documentVersions, appId, ubiquityDocumentId);

      yield call(
        doPublishDocumentVersion,
        ubiquityDocumentId,
        versions[0].id,
        newDocument.published,
      );

      yield put(
        createVideoSuccess({
          ...mappedFinalDocument,
        }),
      );

      const redirectUrl = ContentRef.fromString(mappedFinalDocument.content).toDashboardUrl(VIDEO);
      yield call(history.push, redirectUrl);
      yield call(message.success, i18next.t('videos:Wizard.review.success'));
    } else {
      console.error('Video export from CE to Ubiquity returned status: ', exportDetails['status']);
      yield call(message.error, i18next.t('videos:Wizard.review.error'));
      yield put(createVideoFailed());
    }
  } catch (err) {
    console.error(err);
    yield call(message.error, i18next.t('videos:Wizard.review.error'));
    yield put(createVideoFailed());
  }
}

function* doEditVideo({
  payload: { ceDocumentUUID, ubiquityDocumentId, editedDocument },
}: IEditVideo): any {
  const history = yield getContext('history');
  const { appId, companyId, solutionConfiguration } = yield select(selectAppDetails);
  try {
    const currentDashboardUser = yield select(selectCurrentDashboardUser);
    const postData = buildPostDataForVideo(
      editedDocument,
      ceDocumentUUID,
      ubiquityDocumentId,
      appId,
      companyId,
      solutionConfiguration,
      currentDashboardUser.profile.hospitalId,
    );
    const exportDetails = yield call(callVideoCreationService, postData);
    if (exportDetails['status'] === 'complete') {
      const client = yield call(doCreateUbiquityClient);
      const tags = createTagsFromMetadata(editedDocument.metadata);
      yield call(client.editDocument, appId, ubiquityDocumentId, editedDocument.name, {
        tags: [...tags, { term: VIDEO, label: VIDEO }],
      });

      const finalDocument: IRawDocument = yield call(client.document, appId, ubiquityDocumentId);
      const orgId = yield select(selectOrganisationSlug());
      const mappedFinalDocument = mapRawDocumentToDocument(finalDocument, orgId);

      yield call(
        doPublishDocumentVersion,
        ubiquityDocumentId,
        mappedFinalDocument.latest_version.id,
        editedDocument.published,
      );

      yield put(
        editVideoSuccess(ubiquityDocumentId, {
          ...mappedFinalDocument,
          description: finalDocument?.metadata?.custom?.description || '',
        }),
      );

      yield put(fetchDocumentPages(ubiquityDocumentId.toString(), '', true));

      const redirectUrl = ContentRef.fromString(mappedFinalDocument.content).toDashboardUrl(VIDEO);
      yield call(history.push, redirectUrl);
      yield call(message.success, i18next.t('videos:Wizard.review.success'));
    } else {
      console.error('Video export from CE to Ubiquity returned status: ', exportDetails['status']);
      yield call(message.error, i18next.t('videos:Wizard.review.error'));
      yield put(editVideoFailed());
    }
  } catch (err) {
    console.error(err);
    yield call(message.error, i18next.t('videos:Wizard.review.error'));
    yield put(editVideoFailed());
  }
}

function* doGetDocumentPreview({ payload: { documentId } }: IGetDocumentPreview): any {
  try {
    const client = yield call(doCreateUbiquityClient);
    const { appId } = yield select(selectAppDetails);
    const versions = yield call(client.documentVersions, appId, documentId);
    const versionDetails = yield call(client.documentVersion, appId, documentId, versions[0].id);
    const pages = yield call(client.pages, versionDetails);

    yield put(
      documentPreviewLoaded(
        pages.map(
          ({ latest_published_url }: { latest_published_url: string }) => latest_published_url,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(documentPreviewFailed());
  }
}
