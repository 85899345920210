import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../../cards/Card';
import { fetchEngagementChecks, deleteEngagementCheck } from '../../../redux/pathways/actions';
import { selectEngagementChecksByPathwayId } from '../../../redux/pathways/reducers';
import { selectSharedPathwayEngagementChecksByPathwayId } from '@pathways/redux/sharedPathways/reducers';
import createColumns from '@pathways/cards/Pathways/EngagementChecks/createColumns';
import './styles.less';

function EngagementChecksCard({
  pathwayId,
  actionButtonsVisible = true,
  disabled,
  isSharedPathway,
}) {
  const { t } = useTranslation();
  const { loading: engagementsLoading, engagements } = useSelector(
    selectEngagementChecksByPathwayId(pathwayId),
  );
  const {
    loading: sharedEngagementChecksLoading,
    engagements: sharedEngagementChecks,
  } = useSelector(selectSharedPathwayEngagementChecksByPathwayId(pathwayId));

  const [engagementChecks, setEngagementChecks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isSharedPathway) {
      setLoading(sharedEngagementChecksLoading);
      if (sharedEngagementChecks?.length > 0) {
        setEngagementChecks(sharedEngagementChecks);
      }
    } else {
      setLoading(engagementsLoading);
      if (engagements?.length > 0) {
        setEngagementChecks(engagements);
      }
    }
  }, [
    isSharedPathway,
    sharedEngagementChecks,
    sharedEngagementChecksLoading,
    engagements,
    engagementsLoading,
  ]);

  const [dataSource, setDataSource] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEngagementChecks(pathwayId, isSharedPathway));
  }, [dispatch, pathwayId, isSharedPathway]);

  useEffect(() => {
    setDataSource(
      engagementChecks?.map(check => ({
        ...check,
        onDelete: () => {
          dispatch(deleteEngagementCheck(pathwayId, check.id));
          setDataSource(
            engagementChecks?.filter(engagementCheck => engagementCheck.id !== check.id),
          );
        },
        onEdit: () =>
          history.push(
            `/procedure/pathways/${pathwayId}/edit-engagement-check/${check.id}${
              isSharedPathway ? '/shared' : ''
            }`,
          ),
        onView: () =>
          history.push(
            `/procedure/pathways/${pathwayId}/engagement-check/${check.id}${
              isSharedPathway ? '/shared' : ''
            }`,
          ),
      })),
    );
  }, [engagementChecks, dispatch, history, pathwayId, isSharedPathway]);

  const columns = useMemo(() => createColumns(t, actionButtonsVisible), [t, actionButtonsVisible]);
  return (
    <Card.Full
      className="pathway-stages-table-card engagement-checks"
      title={t('pathways:ProcedurePathways.engagementChecks.tableTitle')}
      extra={
        <>
          <Button
            icon="plus"
            type="dashed"
            onClick={() => history.push(`/procedure/pathways/${pathwayId}/add-engagement-check`)}
            disabled={disabled}
          >
            {t('pathways:ProcedurePathways.engagementChecks.addEngagementCheck')}
          </Button>
        </>
      }
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ position: 'bottom', pageSize: 10 }}
        locale={{ emptyText: t('pathways:ProcedurePathways.engagementChecks.noEngagementChecks') }}
        scroll={{ x: true }}
        loading={loading}
      />
    </Card.Full>
  );
}

export default EngagementChecksCard;
