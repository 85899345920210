import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, TimePicker } from 'antd';
import './style.less';

function DateTimePicker({ value, onChange }, ref) {
  const [valueMoment, setValueMoment] = useState(moment(value));

  useEffect(() => {
    setValueMoment(moment(value));
  }, [value]);

  const triggerChange = useCallback(
    timeMoment => {
      setValueMoment(timeMoment);
      onChange(timeMoment);
    },
    [onChange],
  );

  return (
    <div className="date-time-picker" ref={ref}>
      <DatePicker allowClear={false} format="l" value={valueMoment} onChange={triggerChange} />
      <TimePicker allowClear={false} format="HH:mm" value={valueMoment} onChange={triggerChange} />
    </div>
  );
}

export default forwardRef(DateTimePicker);
