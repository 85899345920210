import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faStar,
  faPresentation,
  faChalkboard,
} from '@fortawesome/pro-regular-svg-icons';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import HeaderButton from '@components/HeaderButton';
import EditOrganisation from './Edit';
import Branding from './Branding';
import SplashScreen from './SplashScreen';
import EditBranding from './EditBranding';
import EditSplashScreen from './EditSplashScreen';
import { hospital as organisationIcon } from '@assets/icons';
import Details from './Details';
import Content from './Content';
import InstructionalScreens from './InstructionalScreens';
import InstructionalScreen from './InstructionalScreen';
import EditInstructionalScreen from './EditInstructionalScreen';
import './style.less';

const routes = {
  edit: 'edit',
  details: 'details',
  content: 'public-content',
  branding: 'branding',
  splashScreen: 'splash-screen',
  instructionalScreens: 'instructional-screens',
};

const OrganisationDetails = ({ history, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const dashboardUserProfile = useSelector(state => state.login.user.profile);

  const { setTitle, setActions } = usePageActions({
    title: '',
    actions: [],
    showBack: false,
  });

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  const [, organisation] = useSelector(selectOrganisation());

  useEffect(() => {
    const name = organisation?.updated_organisation_name || organisation?.organisation_name || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle]);
  // }, [dashboardUserProfile, organisation, setTitle]);

  useEffect(() => {
    if (
      history.location.pathname.endsWith(routes.edit) ||
      !history.location.pathname.endsWith(routes.details)
    ) {
      setActions([]);
    } else {
      setActions([
        <HeaderButton
          key="edit-btn"
          tooltip={t('common:buttons.edit')}
          onClick={() => history.push(`${match.url}/${routes.edit}`)}
        >
          {t('common:buttons.edit')}
        </HeaderButton>,
      ]);
    }
  }, [match, history, t, setActions]);

  return (
    <Switch>
      <Route path={`${match.path}/${routes.edit}`} component={EditOrganisation} />,
      <Route path={`${match.path}/${routes.branding}/edit`} component={EditBranding} />,
      <Route path={`${match.path}/${routes.splashScreen}/edit`} component={EditSplashScreen} />,
      <Route
        path={`${match.path}/${routes.instructionalScreens}/new`}
        component={EditInstructionalScreen}
      />
      ,
      <Route
        path={`${match.path}/${routes.instructionalScreens}/:pk/edit`}
        component={EditInstructionalScreen}
      />
      ,{/* <Route */}
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.details}>
              <Icon component={organisationIcon} />
              {t('organisations:OrganisationDetails.menu.appOrganisationDetails')}
            </Menu.Item>
            <Menu.Item key={routes.content}>
              <FontAwesomeIcon icon={faFileAlt} />
              {t('organisations:OrganisationDetails.menu.appOrganisationContent')}
            </Menu.Item>
            <Menu.Item key={routes.branding}>
              <FontAwesomeIcon icon={faStar} />
              {t('organisations:OrganisationDetails.menu.appOrganisationBranding')}
            </Menu.Item>
            <Menu.Item key={routes.splashScreen}>
              <FontAwesomeIcon icon={faPresentation} />
              {t('organisations:OrganisationDetails.menu.appOrganisationSplashScreen')}
            </Menu.Item>
            <Menu.Item key={routes.instructionalScreens}>
              <FontAwesomeIcon icon={faChalkboard} />
              {t('organisations:OrganisationDetails.menu.appOrganisationInstructionalScreens')}
            </Menu.Item>
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.details}`} component={Details} />
          <Route path={`${match.path}/${routes.content}`} component={Content} />
          <Route path={`${match.path}/${routes.branding}`} component={Branding} />
          <Route path={`${match.path}/${routes.splashScreen}`} component={SplashScreen} />
          <Route
            path={`${match.path}/${routes.instructionalScreens}/:pk`}
            component={InstructionalScreen}
          />
          <Route
            path={`${match.path}/${routes.instructionalScreens}`}
            component={InstructionalScreens}
          />
          <Route
            path={match.path}
            render={() => <Redirect to={`${match.path}/${routes.details}`} />}
          />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
};

export default OrganisationDetails;
