import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import { ColumnGroup, Column, Card } from '@cards/Card';

import './style.less';

function Details() {
  const { t } = useTranslation();
  const { currentStepData, form, setCurrentStepData } = useWizard();

  const handleSetName = useCallback(
    ({ target: { value } }) => {
      setCurrentStepData({
        ...currentStepData,
        name: value,
      });
    },
    [setCurrentStepData, currentStepData],
  );

  const handleSetDescription = useCallback(
    ({ target: { value } }) => {
      setCurrentStepData({
        ...currentStepData,
        description: value,
      });
    },
    [setCurrentStepData, currentStepData],
  );

  return (
    <ColumnGroup className="details-step">
      <Column>
        <Card title={t('pathways:ProcedurePathways.engagementCheckWizard.detailsStep.title')}>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item
              label={t('pathways:ProcedurePathways.engagementCheckWizard.detailsStep.name')}
            >
              {form.getFieldDecorator('name', {
                initialValue: currentStepData.name,
                rules: [
                  {
                    required: true,
                    message: t(
                      'pathways:ProcedurePathways.engagementCheckWizard.detailsStep.required',
                    ),
                  },
                ],
              })(<Input onChange={handleSetName} />)}
            </Form.Item>
            <Form.Item
              label={t('pathways:ProcedurePathways.engagementCheckWizard.detailsStep.description')}
            >
              {form.getFieldDecorator('description', {
                initialValue: currentStepData.description,
              })(<Input.TextArea autoSize={{ minRows: 3 }} onChange={handleSetDescription} />)}
            </Form.Item>
          </Form>
        </Card>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

export default Details;
