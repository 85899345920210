import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Column, ColumnGroup } from '@cards/Card';
import DetailsCard from '@cards/Messages/Details';
import { deleteScheduledMessage } from '@redux/messages/actions';
import { selectScheduledMessage } from '@redux/messages/selectors';
import { usePageActions } from '@hooks';
import TimingCard from '@cards/Messages/Timing';
import AttachedContentCard from '@cards/Messages/AttachedContent';
import IndividualsCard from '@cards/Messages/Individuals';
import IconButton from '@components/IconButton';
import { waitForStoreUpdate } from '@utils';
import { matchType } from '../../propTypes';

function ScheduledDetails({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const selector = useCallback(selectScheduledMessage(match.params.id), [match.params.id]);
  const [loading, message] = useSelector(selector);
  const { setActions, setTitle } = usePageActions({ title: ' ', showBack: true });
  const onDelete = useCallback(() => {
    dispatch(deleteScheduledMessage(message.id));
    return waitForStoreUpdate(store, selector);
  }, [dispatch, message, selector, store]);
  const onDeleteClick = useCallback(
    () =>
      Modal.confirm({
        title: t('messages:Scheduled.confirmDelete'),
        onOk: onDelete,
        onCancel: () => {},
      }),
    [t, onDelete],
  );

  useEffect(() => {
    setActions([
      <IconButton
        key="delete-btn"
        icon={faTrashAlt}
        tooltip={t('common:buttons.delete')}
        onClick={onDeleteClick}
      />,
    ]);
    setTitle(message.title);
  }, [message, t, onDeleteClick, setActions, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard loading={loading} message={message} />
        {message?.payloadOptions?.data ? (
          <AttachedContentCard content={message.payloadOptions.data} loading={loading} />
        ) : null}
        <TimingCard loading={loading} message={message} />
      </Column>
      <Column>
        <IndividualsCard userIds={message.users} />
      </Column>
    </ColumnGroup>
  );
}

ScheduledDetails.propTypes = {
  match: matchType.isRequired,
};

export default ScheduledDetails;
