import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  CREATE_PATHWAY,
  CREATE_PATHWAY_FAILURE,
  CREATE_PATHWAY_SUCCESS,
  DELETE_PATHWAY,
  DELETE_PATHWAY_FAILURE,
  DELETE_PATHWAY_SUCCESS,
  DELETE_PATHWAYS,
  DELETE_PATHWAYS_FAILURE,
  DELETE_PATHWAYS_SUCCESS,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAY_SUCCESS,
  DUPLICATE_PATHWAYS,
  DUPLICATE_PATHWAYS_SUCCESS,
  EDIT_PATHWAY,
  EDIT_PATHWAY_SUCCESS,
  FETCH_PATHWAYS,
  FETCHING_PATHWAYS,
  FETCH_PATHWAYS_ERROR,
  LOAD_PATHWAYS,
  PUBLISH_PATHWAYS_SUCCESS,
  UNPUBLISH_PATHWAYS_SUCCESS,
  IPathway,
  EDIT_PATHWAY_FAILED,
  FETCH_ENGAGEMENT_CHECKS,
  FETCH_ENGAGEMENT_CHECKS_SUCCESS,
  FETCH_ENGAGEMENT_CHECKS_FAILED,
  ADD_ENGAGEMENT_CHECK,
  ADD_ENGAGEMENT_CHECK_SUCCESS,
  ADD_ENGAGEMENT_CHECK_FAILED,
  EDIT_ENGAGEMENT_CHECK,
  EDIT_ENGAGEMENT_CHECK_SUCCESS,
  EDIT_ENGAGEMENT_CHECK_FAILED,
  DELETE_ENGAGEMENT_CHECK,
  DELETE_ENGAGEMENT_CHECK_SUCCESS,
  DELETE_ENGAGEMENT_CHECK_FAILED,
  CREATE_PATHWAY_SNAPSHOT_SUCCESS,
  SHARE_PATHWAY_SNAPSHOT_SUCCESS,
  UNSHARE_PATHWAY_SNAPSHOT_SUCCESS,
  FETCH_PATHWAY_SNAPSHOTS_SUCCESS,
} from './types';
import {
  FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
  IRule,
} from '@pathways/redux/rules/types';
import { IState } from '@redux/reducer';
import { selectRule } from '@pathways/redux/rules/reducers';
import {
  ICreatePathwaySuccess,
  IDeletePathwaySuccess,
  IDeletePathwaysSuccess,
  IEditPathwaySuccess,
  IPublishPathwaysSuccess,
  IUnpublishPathwaysSuccess,
  ILoadPathways,
  IFetchPathwaysError,
  IFetchPathways,
  ICreatePathway,
  IDeletePathway,
  IDeletePathways,
  IDuplicatePathway,
  IDuplicatePathways,
  IEditPathway,
  IFetchingPathways,
  ICreatePathwayFailure,
  IDeletePathwayFailure,
  IDeletePathwaysFailure,
  IDuplicatePathwaySuccess,
  IDuplicatePathwaysSuccess,
  IEditPathwayFailed,
  IFetchEngagementChecks,
  IFetchEngagementChecksSuccess,
  IFetchEngagementChecksFailed,
  IAddEngagementCheck,
  IAddEngagementCheckSuccess,
  IAddEngagementCheckFailed,
  IEditEngagementCheck,
  IEditEngagementCheckSuccess,
  IEditEngagementCheckFailed,
  IDeleteEngagementCheck,
  IDeleteEngagementCheckSuccess,
  IDeleteEngagementCheckFailed,
  ICreatePathwaySnapshotSuccess,
  ISharePathwaySnapshotSuccess,
  IUnsharePathwaySnapshotSuccess,
  IFetchPathwaySnapshotsSuccess,
} from './actions';

import {
  IFetchSharedEngagementChecksSuccess,
  IUtiliseSharedPathwaySnapshotSuccess,
} from '../sharedPathways/actions';
import { IFetchPathwayEngagementCheckActionsWhatDetailSuccess } from '../rules/actions';

export interface IDataState {
  list: number[];
  byId: { [key: string]: IPathway };
}

function creating(
  state = false,
  action: ICreatePathway | ICreatePathwayFailure | ICreatePathwaySuccess,
) {
  switch (action.type) {
    case CREATE_PATHWAY:
      return true;
    case CREATE_PATHWAY_FAILURE:
    case CREATE_PATHWAY_SUCCESS:
      return false;
    default:
      return state;
  }
}

const initialDataState: IDataState = {
  list: [],
  byId: {},
};
function data(
  state = initialDataState,
  action:
    | ICreatePathwaySuccess
    | IDeletePathwaySuccess
    | IDeletePathwaysSuccess
    | IEditPathwaySuccess
    | ILoadPathways
    | IPublishPathwaysSuccess
    | IUnpublishPathwaysSuccess
    | IFetchEngagementChecks
    | IFetchEngagementChecksSuccess
    | IFetchEngagementChecksFailed
    | IAddEngagementCheck
    | IAddEngagementCheckSuccess
    | IAddEngagementCheckFailed
    | IEditEngagementCheck
    | IEditEngagementCheckSuccess
    | IEditEngagementCheckFailed
    | IDeleteEngagementCheck
    | IDeleteEngagementCheckSuccess
    | IDeleteEngagementCheckFailed
    | ICreatePathwaySnapshotSuccess
    | ISharePathwaySnapshotSuccess
    | IUnsharePathwaySnapshotSuccess
    | IFetchPathwaySnapshotsSuccess
    | IUtiliseSharedPathwaySnapshotSuccess
    | IDuplicatePathwaySuccess
    | IFetchSharedEngagementChecksSuccess
    | IFetchPathwayEngagementCheckActionsWhatDetailSuccess,
): IDataState {
  switch (action.type) {
    case CREATE_PATHWAY_SUCCESS: {
      const { pathway } = action.payload;
      return {
        list: [pathway.id, ...state.list],
        byId: { ...state.byId, [pathway.id]: pathway },
      };
    }
    case DELETE_PATHWAYS_SUCCESS:
      return {
        list: state.list.filter(id => !action.payload.pathwayIds.includes(id)),
        byId: action.payload.pathwayIds.reduce(
          (prev, id) => ({ ...prev, [id]: { ...state.byId[id], isDeleted: true } }),
          state.byId,
        ),
      };
    case DELETE_PATHWAY_SUCCESS:
      return {
        list: state.list.filter(id => id !== action.payload.pathwayId),
        byId: Object.keys(state.byId).reduce(
          (prev, id) =>
            Number(id) === action.payload.pathwayId
              ? { ...prev, [id]: { ...state.byId[id], isDeleted: true } }
              : { ...prev, [id]: state.byId[id] },
          {},
        ),
      };
    case EDIT_PATHWAY_SUCCESS: {
      const { pathway } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [pathway.id]: {
            ...state.byId[pathway.id],
            ...pathway,
          },
        },
      };
    }
    case PUBLISH_PATHWAYS_SUCCESS:
      return {
        ...state,
        byId: action.payload.pathwayIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isActive: true,
            },
          }),
          state.byId,
        ),
      };
    case UNPUBLISH_PATHWAYS_SUCCESS:
      return {
        ...state,
        byId: action.payload.pathwayIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isActive: false,
            },
          }),
          state.byId,
        ),
      };
    case LOAD_PATHWAYS:
      return action.payload.pathways.reduce(
        (acc, pathway) => {
          const existingPathway = acc.byId[pathway.id];
          if (!acc.list.includes(pathway.id)) {
            return {
              list: [...acc.list, pathway.id],
              byId: {
                ...acc.byId,
                [pathway.id]: {
                  ...(existingPathway || {}),
                  ...pathway,
                },
              },
            };
          } else if (existingPathway) {
            return {
              ...acc,
              byId: {
                ...acc.byId,
                [pathway.id]: {
                  ...existingPathway,
                  ...pathway,
                },
              },
            };
          }
          return acc;
        },
        {
          list: state.list,
          byId: state.byId,
        },
      );

    case FETCH_ENGAGEMENT_CHECKS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: true,
              engagements:
                state.byId[action.payload.pathwayId]?.engagementChecks?.engagements || [],
            },
          },
        },
      };
    case FETCH_ENGAGEMENT_CHECKS_SUCCESS:
      const existingEngagements =
        state.byId[action.payload.pathwayId]?.engagementChecks?.engagements || [];
      const newEngagements = action.payload.engagementChecks;
      const mergedEngagements = [
        ...existingEngagements,
        ...newEngagements.filter(
          newEngagement =>
            !existingEngagements.some(
              existingEngagement => existingEngagement.id === newEngagement.id,
            ),
        ),
      ];
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: mergedEngagements,
            },
          },
        },
      };
    case 'sharedPathways/fetchSharedEngagementChecksSuccess':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.sharedPathwayId]: {
            ...state.byId[action.payload.sharedPathwayId],
            engagementChecks: {
              loading: false,
              engagements:
                state.byId[action.payload.sharedPathwayId]?.engagementChecks?.engagements || [],
            },
          },
        },
      };
    case FETCH_ENGAGEMENT_CHECKS_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case ADD_ENGAGEMENT_CHECK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: true,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case ADD_ENGAGEMENT_CHECK_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case ADD_ENGAGEMENT_CHECK_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case EDIT_ENGAGEMENT_CHECK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: true,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case EDIT_ENGAGEMENT_CHECK_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements?.map(
                check => {
                  if (Number(check.id) === Number(action.payload.engagementCheck.id)) {
                    return action.payload.engagementCheck;
                  }
                  return check;
                },
              ),
            },
          },
        },
      };
    case EDIT_ENGAGEMENT_CHECK_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case DELETE_ENGAGEMENT_CHECK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: true,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case DELETE_ENGAGEMENT_CHECK_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[
                action.payload.pathwayId
              ].engagementChecks?.engagements?.filter(
                check => Number(check.id) !== Number(action.payload.engagementCheckId),
              ),
            },
          },
        },
      };
    case DELETE_ENGAGEMENT_CHECK_FAILED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              loading: false,
              engagements: state.byId[action.payload.pathwayId].engagementChecks?.engagements || [],
            },
          },
        },
      };
    case SHARE_PATHWAY_SNAPSHOT_SUCCESS:
      return {
        ...state,
        byId: Object.entries(state.byId).reduce(
          (acc, [id, snapshot]) => ({
            ...acc,
            [id]: {
              ...snapshot,
              isSharedSnapshot: Number(id) === action.payload.snapshotId,
            },
          }),
          {},
        ),
      };
    case UNSHARE_PATHWAY_SNAPSHOT_SUCCESS:
      return {
        ...state,
        byId: Object.entries(state.byId).reduce(
          (acc, [id, snapshot]) => ({
            ...acc,
            [id]: {
              ...snapshot,
              isSharedSnapshot: false,
            },
          }),
          {},
        ),
      };
    case CREATE_PATHWAY_SNAPSHOT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.snapshot.id]: action.payload.snapshot,
        },
      };
    case FETCH_PATHWAY_SNAPSHOTS_SUCCESS:
      return action.payload.snapshots.reduce(
        (acc, snapshot) => {
          const existingSnapshot = acc.byId[snapshot.id];
          if (!acc.list.includes(snapshot.id)) {
            return {
              list: [...acc.list, snapshot.id],
              byId: {
                ...acc.byId,
                [snapshot.id]: {
                  ...(existingSnapshot || {}),
                  ...snapshot,
                },
              },
            };
          } else if (existingSnapshot) {
            return {
              ...acc,
              byId: {
                ...acc.byId,
                [snapshot.id]: {
                  ...existingSnapshot,
                  ...snapshot,
                },
              },
            };
          }
          return acc;
        },
        {
          list: state.list,
          byId: state.byId,
        },
      );

    case 'sharedPathways/utiliseSharedPathwaySnapshotSuccess':
      return {
        ...state,
        list: [...state.list, action.payload.snapshot.id],
        byId: {
          ...state.byId,
          [action.payload.snapshot.id]: action.payload.snapshot,
        },
      };

    case DUPLICATE_PATHWAY_SUCCESS:
      return {
        list: [action.payload.duplicatedPathway.id, ...state.list],
        byId: {
          ...state.byId,
          [action.payload.duplicatedPathway.id]: action.payload.duplicatedPathway,
        },
      };

    case FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.pathwayId]: {
            ...state.byId[action.payload.pathwayId],
            engagementChecks: {
              ...state.byId[action.payload.pathwayId].engagementChecks,
              engagements: state.byId[action.payload.pathwayId].engagementChecks!.engagements.map(
                engagement => {
                  if (action.payload.details[engagement.id]) {
                    return {
                      ...engagement,
                      action: {
                        ...engagement.action,
                        whatDetail: {
                          ...engagement.action.whatDetail,
                          ...action.payload.details[engagement.id],
                        },
                      },
                    };
                  }
                  return engagement;
                },
              ),
            },
          },
        },
      };

    default:
      return state;
  }
}

function editing(state = false, action: IEditPathway | IEditPathwaySuccess | IEditPathwayFailed) {
  switch (action.type) {
    case EDIT_PATHWAY:
      return true;
    case EDIT_PATHWAY_FAILED:
    case EDIT_PATHWAY_SUCCESS:
      return false;
    default:
      return state;
  }
}

function error(state = false, action: IFetchPathwaysError | IFetchPathways) {
  switch (action.type) {
    case FETCH_PATHWAYS_ERROR:
      return action.payload.error;
    case FETCH_PATHWAYS:
      return false;
    default:
      return state;
  }
}

function duplicating(
  state = false,
  action:
    | IDuplicatePathway
    | IDuplicatePathways
    | IDuplicatePathwaySuccess
    | IDuplicatePathwaysSuccess,
) {
  switch (action.type) {
    case DUPLICATE_PATHWAY:
    case DUPLICATE_PATHWAYS:
      return true;
    case DUPLICATE_PATHWAY_SUCCESS:
    case DUPLICATE_PATHWAYS_SUCCESS:
      return false;
    default:
      return state;
  }
}

function loading(
  state = false,
  action:
    | IDeletePathway
    | IDeletePathways
    | IFetchingPathways
    | IDeletePathwayFailure
    | IDeletePathwaySuccess
    | IDeletePathwaysFailure
    | IDeletePathwaysSuccess
    | IFetchPathwaysError
    | ILoadPathways,
) {
  switch (action.type) {
    case DELETE_PATHWAY:
    case DELETE_PATHWAYS:
    case FETCHING_PATHWAYS:
      return true;
    case DELETE_PATHWAY_FAILURE:
    case DELETE_PATHWAY_SUCCESS:
    case DELETE_PATHWAYS_FAILURE:
    case DELETE_PATHWAYS_SUCCESS:
    case FETCH_PATHWAYS_ERROR:
    case LOAD_PATHWAYS:
      return false;
    default:
      return state;
  }
}

export default combineReducers({ creating, data, duplicating, editing, error, loading });

export const selectPathwayCreating = (state: IState) => state.pathways.creating;
export const selectPathwayEditing = (state: IState) => state.pathways.editing;
export const selectPathwaysLoading = (state: IState) => state.pathways.loading;
export const selectPathwaysDuplicating = (state: IState) => state.pathways.duplicating;

export const selectPathways = createSelector(
  (state: IState): [boolean, IPathway[]] => [
    state.pathways.loading,
    state.pathways.data.list.reduce((acc, id) => {
      const pathway = { ...state.pathways.data.byId[id] };
      if (pathway.isDeleted) return acc;

      pathway.stages = pathway.stages.map(stage => ({
        ...stage,
        rules: stage.rules.reduce((rules, { id }) => {
          const [, rule] = selectRule(id)(state);
          if (rule) {
            return [...rules, rule];
          }

          return rules;
        }, [] as IRule[]),
      }));

      pathway.indexEvents = pathway.indexEvents.map(indexEvent => ({
        ...indexEvent,
        rules: indexEvent.rules.reduce((rules, { id }) => {
          const [, rule] = selectRule(id)(state);
          if (rule) {
            return [...rules, rule];
          }

          return rules;
        }, [] as IRule[]),
      }));

      return [...acc, pathway];
    }, [] as IPathway[]),
  ],
  pathways => pathways,
);

export const selectPathwaysById = (ids: number[] = []) => (state: IState) => [
  state.pathways.loading,
  ids.map(id => state.pathways.data.byId[id]).filter(pathway => !!pathway),
];

export const selectPathwaysByRules = (filter: (rule: IRule | undefined) => boolean) => (
  state: IState,
): [boolean, IPathway[]] => {
  const [loading, pathways] = selectPathways(state);

  const result = pathways.filter(
    pathway =>
      pathway.stages.flatMap(stage => stage.rules.filter(filter)).some(Boolean) ||
      pathway.indexEvents.flatMap(indexEvent => indexEvent.rules.filter(filter)).some(Boolean),
  );

  return [loading, result];
};

export const selectPathway = (id: number) => (state: IState): [boolean, IPathway] => [
  state.pathways.loading,
  state.pathways.data.byId[id],
];

export const selectAppUserMainPathway = (ids: number[]) => (state: IState) => {
  const appUserPathways = ids.map(id => state.pathways.data.byId[id]).filter(Boolean);
  return [state.pathways.loading, appUserPathways[0]];
};

export const selectAllPathwaysById = (state: IState) => {
  return [state.pathways.loading, state.pathways.data.byId];
};

export const selectPathwayWithRules = (id: number) => (
  state: IState,
): [boolean, IPathway | undefined] => [
  state.pathways.loading,
  state.pathways.data.byId[id]
    ? {
        ...state.pathways.data.byId[id],
        indexEvents: state.pathways.data.byId[id].indexEvents?.map(indexEvent => ({
          ...indexEvent,
          rules: indexEvent.rules.map(indexEventRule => {
            const [, rule] = selectRule(indexEventRule.id)(state);
            return rule || indexEventRule;
          }),
        })),
        stages: state.pathways.data.byId[id].stages?.map(stage => ({
          ...stage,
          rules: stage.rules.map(stageRule => {
            const [, rule] = selectRule(stageRule.id)(state);
            return rule || stageRule;
          }),
        })),
      }
    : undefined,
];

export const selectEngagementCheck = (pathwayId: string, engagementCheckId: string) => (
  state: IState,
): any => {
  const pathway = state.pathways.data.byId[pathwayId];
  if (!pathway) return undefined;

  const engagementCheck = pathway.engagementChecks?.engagements?.find(
    ({ id }) => id === Number(engagementCheckId),
  );

  return engagementCheck;
};

export const selectEngagementChecksByPathwayId = (pathwayId: string) => (
  state: IState,
): { loading: boolean; engagements: any[] } => {
  const pathway = state.pathways.data.byId[pathwayId];

  return pathway?.engagementChecks || { loading: false, engagements: [] };
};

export const selectEngagementChecksLoading = (pathwayId: string) => (state: IState): boolean => {
  const pathway = state.pathways.data.byId[pathwayId];

  return pathway?.engagementChecks?.loading || false;
};

export const selectPathwaySnapshots = (pathwayId: string) => (state: IState): any[] => {
  const snapshots = Object.values(state.pathways.data.byId).filter(item => {
    return item.url?.includes(`/pathways/${pathwayId}/`) && item.url?.includes('snapshots');
  });

  return snapshots;
};
