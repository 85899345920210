import { Types } from './api';

export type KemtaiStaffMember = {
  userId: string;
  name: string;
};

export type ILoadStaffList = ReturnType<typeof loadStaffList>;
export const loadStaffList = (kemtaiAPIKey: string) => ({
  type: 'kemtai/load-staff-list' as const,
  payload: {
    kemtaiAPIKey,
  },
});

export type ILoadStaffListSuccess = ReturnType<typeof loadStaffListSuccess>;
export const loadStaffListSuccess = (staffList: Types.IKemtaiStaffMember[]) => ({
  type: 'kemtai/load-staff-list-success' as const,
  payload: {
    staffList,
  },
});

export type ILoadStaffListFailure = ReturnType<typeof loadStaffListFailure>;
export const loadStaffListFailure = () => ({
  type: 'kemtai/load-staff-list-failure' as const,
  payload: {},
});

export type ILoadPatientDetails = ReturnType<typeof loadPatientDetails>;
export const loadPatientDetails = (appUserId: string) => ({
  type: 'kemtai/load-patient-details' as const,
  payload: {
    appUserId,
  },
});

export type ILoadPatientDetailsSuccess = ReturnType<typeof loadPatientDetailsSuccess>;
export const loadPatientDetailsSuccess = (patient: any) => ({
  type: 'kemtai/load-patient-details-success' as const,
  payload: {
    patient,
  },
});

export type ILoadPatientDetailsFailure = ReturnType<typeof loadPatientDetailsFailure>;
export const loadPatientDetailsFailure = () => ({
  type: 'kemtai/load-patient-details-failure' as const,
  payload: {},
});

export type ICreatePatient = ReturnType<typeof createPatient>;
export const createPatient = (appUserId: string) => ({
  type: 'kemtai/create-patient' as const,
  payload: {
    appUserId,
  },
});

export type ICreatePatientSuccess = ReturnType<typeof createPatientSuccess>;
export const createPatientSuccess = (patient: any) => ({
  type: 'kemtai/create-patient-success' as const,
  payload: {
    patient,
  },
});

export type ICreatePatientFailure = ReturnType<typeof createPatientFailure>;
export const createPatientFailure = () => ({
  type: 'kemtai/create-patient-failure' as const,
  payload: {},
});

export type IViewPatientInKemtai = ReturnType<typeof viewPatientInKemtai>;
export const viewPatientInKemtai = (kemtaiPatient: any) => ({
  type: 'kemtai/view-patient-in-kemtai' as const,
  payload: {
    kemtaiPatient,
  },
});
