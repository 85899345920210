import {
  takeLatest,
  takeEvery,
  put,
  getContext,
  call /* , select */,
  select,
  delay,
} from 'redux-saga/effects';
import { message } from 'antd';
// import { v4 as uuid } from 'uuid';
// import slug from 'slug';
import { PIPObject } from '@api/pipClient';
import doCreatePipClient from '@redux/doCreatePipClient';
// import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { selectAppDetails } from '@organisation/redux/selectors';
import { IAppDetails } from '@organisation/redux/types';
// import { inviteDashboardUser } from '@redux/dashboardUsers/sagas';
// import { selectAppDetails } from '@organisation/redux/selectors';ß
import i18n from '../../i18n';
import {
  loadOrganisations,
  loadOrganisationsSuccess,
  loadOrganisationsFailed,
  IUpdateOrganisation,
  updateOrganisationSuccess,
  updateOrganisationFailed,
  createOrganisationFailed,
  createOrganisationSuccess,
  CreateOrganisation,
  EditOrganisation,
  editOrganisationFailed,
  editOrganisationSuccess,
  loadOrganisation,
  loadOrganisationSuccess,
  loadOrganisationFailed,
  getOrganisationPublicContentSuccess,
  ICreateOrganisationPublicContent,
  createOrganisationPublicContentSuccess,
  IRemoveOrganisationPublicContent,
  removeOrganisationPublicContentSuccess,
  IReorderOrganisationPublicContent,
  getOrganisationBrandingSuccess,
  ICreateOrganisationBranding,
  createOrganisationBrandingSuccess,
  IUpdateOrganisationBranding,
  updateOrganisationBrandingSuccess,
  getOrganisationInstructionalScreensSuccess,
  ICreateOrganisationInstructionalScreen,
  createOrganisationInstructionalScreenSuccess,
  IUpdateOrganisationInstructionalScreen,
  updateOrganisationInstructionalScreenSuccess,
  IRemoveOrganisationInstructionalScreens,
  removeOrganisationInstructionalScreenSuccess,
  IReorderOrganisationInstructionalScreens,
  IRemoveOrganisationImage,
} from './actions';
import {
  Organisation,
  FETCH_ORGANISATIONS,
  // IOrganisationInvites,
  CREATE_ORGANISATION,
  UPDATE_ORGANISATION,
  EDIT_ORGANISATION,
  ISolutionConfiguration,
  GET_ORGANISATION_PUBLIC_CONTENT,
  CREATE_ORGANISATION_PUBLIC_CONTENT,
  REMOVE_ORGANISATION_PUBLIC_CONTENT,
  REORDER_ORGANISATION_PUBLIC_CONTENT,
  GET_ORGANISATION_BRANDING,
  CREATE_ORGANISATION_BRANDING,
  UPDATE_ORGANISATION_BRANDING,
  GET_ORGANISATION_INSTRUCTIONAL_SCREENS,
  CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
  REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS,
  REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS,
  REMOVE_ORGANISATION_IMAGE,
} from './types';
import { doGetOrganisationObjectType, parseSolutionConfigurationDetails } from './utils';
import doEnsurePipObjectType from '@redux/doEnsurePipObjectType';
import doCreateIDMService from '@redux/doCreateIDMService';
import doCreatePEPHealthService from '@redux/doCreatePEPHealthService';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import OrganisationDirectoryClient, {
  IGetPublicContentResponse,
  PublicContent,
  IGetBrandingResponse,
  Branding,
  IGetInstructionalScreensResponse,
  InstructionalScreen,
} from '@liquid-state/directory-client';
import {
  selectOrganisationPublicContent,
  selectOrganisationInstructionalScreens,
} from './reducers';

export default function* root() {
  yield takeLatest(FETCH_ORGANISATIONS, doFetchOrganisations);
  yield takeEvery('organisation/fetch', doFetchOrganisation);
  yield takeEvery(CREATE_ORGANISATION, doCreateOrganisation);
  yield takeLatest(UPDATE_ORGANISATION, doUpdateOrganisation); ////// TODO: this is called by LS SuperAdmins managings Org
  yield takeEvery(EDIT_ORGANISATION, doEditOrganisation);
  yield takeLatest(GET_ORGANISATION_PUBLIC_CONTENT, doGetOrganisationPublicContent);
  yield takeLatest(CREATE_ORGANISATION_PUBLIC_CONTENT, doCreateOrganisationPublicContent);
  yield takeLatest(REMOVE_ORGANISATION_PUBLIC_CONTENT, doRemoveOrganisationPublicContent);
  yield takeLatest(REORDER_ORGANISATION_PUBLIC_CONTENT, doReorderOrganisationPublicContent);
  yield takeLatest(GET_ORGANISATION_BRANDING, doGetOrganisationBranding);
  yield takeLatest(CREATE_ORGANISATION_BRANDING, doCreateOrganisationBranding);
  yield takeLatest(UPDATE_ORGANISATION_BRANDING, doUpdateOrganisationBranding);
  yield takeLatest(GET_ORGANISATION_INSTRUCTIONAL_SCREENS, doGetOrganisationInstructionalScreens);
  yield takeLatest(
    CREATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
    doCreateOrganisationInstructionalScreen,
  );
  yield takeLatest(
    UPDATE_ORGANISATION_INSTRUCTIONAL_SCREEN,
    doUpdateOrganisationInstructionalScreen,
  );
  yield takeLatest(
    REMOVE_ORGANISATION_INSTRUCTIONAL_SCREENS,
    doRemoveOrganisationInstructionalScreens,
  );
  yield takeLatest(
    REORDER_ORGANISATION_INSTRUCTIONAL_SCREENS,
    doReorderOrganisationInstructionalScreens,
  );
  yield takeLatest(REMOVE_ORGANISATION_IMAGE, doRemoveOrganisationImage);
}

export function* doFetchOrganisations(): any {
  try {
    yield put(loadOrganisations());

    const service = yield call(doCreateIDMService);

    const [solution] = yield call(service.solutions);

    const organisations = solution.configurations.map(
      (solutionConfiguration: ISolutionConfiguration) =>
        parseSolutionConfigurationDetails(solutionConfiguration),
    );

    yield put(loadOrganisationsSuccess(organisations));
  } catch (err) {
    console.error(err);
    yield put(loadOrganisationsFailed());
  }
}

function* doFetchOrganisation(): any {
  try {
    yield put(loadOrganisation());

    // let userId = null;
    // while (!userId) {
    //   userId = yield select(state => state.login.user?.id);
    //   yield delay(500);
    // }

    // const {
    //   profile: { organisationId: organisationSlug },
    // } = yield select(selectCurrentDashboardUser);

    const { organisationSlug } = yield select(selectAppDetails);

    const client = yield call(doCreateOrganisationDirectoryClient);
    let data = null;
    const { results } = yield call(client.getOrganisationBySlug, organisationSlug, true);
    data = results[0];
    // const content = yield call(client.getPublicontent, { search: organisationSlug });
    if (!data) {
      yield put(loadOrganisationFailed());
      return;
    }
    yield put(loadOrganisationSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(loadOrganisationFailed());
  }
}

////// TODO: this is called by LS SuperAdmins managing Orgs
function* doCreateOrganisation({ payload: { organisation } }: CreateOrganisation): any {
  const history = yield getContext('history');
  try {
    const service = yield call(doCreatePEPHealthService);
    const result = yield call(
      service.createOrganisation,
      organisation.organisationName,
      organisation.language,
    );
    if (result) {
      yield put(createOrganisationSuccess(result));
      yield call(message.success, i18n.t('organisations:Wizard.createSuccess'));
      yield call(history.goBack);
    }
  } catch (e) {
    const err: any = e;
    console.error(err);
    yield put(createOrganisationFailed());
    const data = yield call(err.response.json.bind(err.response));
    if (data && !data.success && data.error === 'invalid_name') {
      yield call(message.error, i18n.t('organisations:Wizard.createFailedInvalidName'));
    } else {
      yield call(message.error, i18n.t('organisations:Wizard.createFailed'));
    }
  }
}

////// TODO: this is called by LS SuperAdmins managing Orgs
function* doUpdateOrganisation({
  payload: { organisation: editedOrganisation },
}: IUpdateOrganisation): any {
  try {
    const objectType = yield call(doGetOrganisationObjectType);
    const history = yield getContext('history');
    const pipClient = yield call(doCreatePipClient);
    const { appToken }: IAppDetails = yield select(selectAppDetails);

    yield call(doEnsurePipObjectType, pipClient, objectType, appToken);

    const [latest]: PIPObject<{ organisation: Organisation }>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    let organisation = latest?.json.organisation;

    // if (!organisation) {
    //   const currentDashboardUser = yield select(selectCurrentDashboardUser);
    //   organisation = {
    //     admin: {
    //       id: '',
    //       name: '',
    //       email: '',
    //     },
    //     contactDetails: {},
    //     content: [],
    //     defaultConnectionCode: '',
    //     country: 'Australia',
    //     created: new Date().toISOString(),
    //     language: 'en',
    //     name: currentDashboardUser.profile.organisationName,
    //     slug: currentDashboardUser.profile.organisationSlug,
    //     socialMedia: [],
    //     uuid: currentDashboardUser.profile.organisationId,
    //   };
    // }

    const updatedOrganisation = {
      ...organisation,
      ...editedOrganisation,
    };

    yield call(pipClient.createObject, objectType, { organisation: updatedOrganisation });

    yield put(updateOrganisationSuccess(updatedOrganisation));
    yield call(history.goBack);
    yield call(message.success, i18n.t('organisations:EditOrganisationDetails.successMessage'));
  } catch (err) {
    console.error(err);
    yield put(updateOrganisationFailed());
    yield call(message.error, i18n.t('organisations:EditOrganisationDetails.failedMessage'));
  }
}

export function* doEditOrganisation({
  payload: { id, updatedDetails, editOnly },
}: EditOrganisation): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const updatedOrg: Organisation = yield call(
      client.updateOrganisationDetails,
      id,
      updatedDetails,
    );

    // if (updatedDetails.content) {
    //   for (const content of updatedDetails.content) {
    //     yield call(client.createPublicContent, content);
    //   }
    // }

    // const [, currentOrg] = yield select(selectOrganisation());
    // yield take('organisation/load-success');

    // const updatedOrg: Organisation = { ...currentOrg, ...organisation };
    yield put(editOrganisationSuccess(updatedOrg));

    // TODO: this needs to be reworked so navigation and success and error messaging is done from the UI code
    if (!editOnly) {
      const history = yield getContext('history');
      yield call(history.goBack);
      yield call(message.success, i18n.t('organisations:Wizard.editSuccess'));
    }
    return;

    // const objectType = yield call(doGetOrganisationObjectType);
    // const history = yield getContext('history');
    // const pipClient = yield call(doCreatePipClient);

    // let [organisationObject]: { json: { organisation: IOrganisation } }[] = yield call(
    //   pipClient.getObjectsForType,
    //   objectType,
    //   'latest',
    // );

    // if (organisationObject) {
    //   if (organisation.organisationName) organisationObject.json.organisation.name = organisation.organisationName;
    //   if (organisation.content) organisationObject.json.organisation.content = organisation.content;

    //   yield call(pipClient.createObject, objectType, organisationObject.json);

    //   yield put(editOrganisationSuccess(organisationObject.json.organisation));

    //   // TODO: this needs to be reworked so navigation and success and error messaging is done from the UI code
    //   if (!editOnly) {
    //     yield call(history.goBack);
    //     yield call(message.success, i18n.t('organisations:Wizard.editSuccess'));
    //   }
    //   return;
    // }

    // throw new OrganisationNotFoundError();
  } catch (err) {
    console.error(err);
    yield put(editOrganisationFailed());
    yield call(message.error, i18n.t('organisations:Wizard.editFailed'));
  }
}

export function* doGetOrganisationPublicContent(): any {
  try {
    const { organisationSlug } = yield select(selectAppDetails);
    const client = yield call(doCreateOrganisationDirectoryClient);
    let page = 1;
    const content: IGetPublicContentResponse = yield call(client.getPublicContent, {
      organisation_slug: organisationSlug,
      page,
    });

    const results = content.results;
    let next = content.next;
    while (next) {
      page += 1;
      const nextContent: IGetPublicContentResponse = yield call(client.getPublicContent, {
        organisation_slug: organisationSlug,
        page,
      });
      results.push(...nextContent.results);
      next = nextContent.next;
    }

    yield put(getOrganisationPublicContentSuccess(content));
  } catch (err) {
    console.error(err);
  }
}

export function* doCreateOrganisationPublicContent({
  payload: { content },
}: ICreateOrganisationPublicContent): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const createdContent: PublicContent[] = [];

    for (const c of content) {
      try {
        const createdContentResponse: PublicContent = yield call(client.createPublicContent, c);
        createdContent.push(createdContentResponse);
      } catch (err) {
        console.error(err);
      }
    }

    yield put(createOrganisationPublicContentSuccess(createdContent));
    if (content.length > 30) {
      yield call(message.error, i18n.t('organisations:Content.message.createLimitExceeded'));
    } else if (content.length === createdContent.length) {
      yield call(message.success, i18n.t('organisations:Content.message.createSuccess'));
    } else if (createdContent.length > 0 && createdContent.length < content.length) {
      yield call(message.error, i18n.t('organisations:Content.message.createPartialFailure'));
    } else {
      yield call(message.error, i18n.t('organisations:Content.message.createFailure'));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* doRemoveOrganisationPublicContent({
  payload: { primaryKeys },
}: IRemoveOrganisationPublicContent): any {
  try {
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    const pksOfSuccessfullyDeleted: number[] = [];

    for (const pk of primaryKeys) {
      try {
        yield call(client.deletePublicContent, pk);
        pksOfSuccessfullyDeleted.push(pk);
      } catch (err) {
        console.error(err);
      }
    }

    yield put(removeOrganisationPublicContentSuccess(pksOfSuccessfullyDeleted));
    if (pksOfSuccessfullyDeleted.length === primaryKeys.length) {
      yield call(message.success, i18n.t('organisations:Content.message.removeSuccess'));
    } else if (
      pksOfSuccessfullyDeleted.length > 0 &&
      pksOfSuccessfullyDeleted.length < primaryKeys.length
    ) {
      yield call(message.error, i18n.t('organisations:Content.message.removePartialFailure'));
    } else {
      yield call(message.error, i18n.t('organisations:Content.message.removeFailed'));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* doReorderOrganisationPublicContent({
  payload: { options },
}: IReorderOrganisationPublicContent): any {
  try {
    const { primaryKey, newOrder } = options;
    const publicContent: PublicContent[] = yield select(selectOrganisationPublicContent);
    const matchingPublicContent = publicContent.find(content => content.pk === primaryKey);

    if (!matchingPublicContent) {
      throw new Error('No public content found with the provided primary key');
    }

    if (newOrder === matchingPublicContent._order) {
      return;
    }
    const isIncrement = matchingPublicContent && newOrder === matchingPublicContent._order + 1;

    const updatedPublicContent = publicContent.map(content => {
      if (content.pk === primaryKey) {
        return { ...content, _order: newOrder };
      }

      /*
        Performs swapping of the order of the public content object with the previous or next object
        If the public content object was incremented, decreases the order of the subsequent object
        If the public content object was decremented, increases the order of the previous object
      */
      if (isIncrement && content._order === newOrder) {
        return { ...content, _order: content._order - 1 };
      } else if (!isIncrement && content._order === newOrder) {
        return { ...content, _order: content._order + 1 };
      }

      return content;
    });

    const pks = updatedPublicContent.sort((a, b) => a._order - b._order).map(content => content.pk);
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    yield call(client.reorderPublicContent, pks);
    yield call(doGetOrganisationPublicContent);
  } catch (err) {
    console.error(err);
  }
}

export function* doRemoveOrganisationImage({
  payload: { imageId },
}: IRemoveOrganisationImage): any {
  try {
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    yield call(client.deleteImage, { image_id: imageId });
  } catch (err) {
    console.error(err);
  }
}

export function* doGetOrganisationBranding(): any {
  try {
    const { organisationSlug } = yield select(selectAppDetails);
    const client = yield call(doCreateOrganisationDirectoryClient);
    const branding: IGetBrandingResponse = yield call(client.getBranding, {
      organisation_slug: organisationSlug,
      page: 1,
    });
    if (branding.results.length) {
      yield put(getOrganisationBrandingSuccess(branding.results[0]));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* doCreateOrganisationBranding({
  payload: { branding, logo, banner, splashScreenImage },
}: ICreateOrganisationBranding): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const brandingWithImages = branding;

    if (logo) {
      brandingWithImages.image_logo = yield call(client.uploadImage, {
        file: logo,
      });
    }
    if (banner) {
      brandingWithImages.image_banner = yield call(client.uploadImage, {
        file: banner,
      });
    }
    if (splashScreenImage) {
      brandingWithImages.splash_screen_image = yield call(client.uploadImage, {
        file: splashScreenImage,
      });
    }
    if (logo || banner || splashScreenImage) {
      yield delay(2000); // wait extra time for Cloudflare to make new image(s) available
    }
    const createdBranding: Branding = yield call(client.createBranding, brandingWithImages);
    if (createdBranding) {
      yield put(createOrganisationBrandingSuccess(createdBranding));
      yield call(message.success, i18n.t('organisations:Branding.details.message.createSuccess'));
    }
  } catch (err) {
    yield call(message.error, i18n.t('organisations:Branding.details.message.createFailure'));
    console.error(err);
  }
}

export function* doUpdateOrganisationBranding({
  payload: { pk, branding, logo, banner, splashScreenImage },
}: IUpdateOrganisationBranding): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const brandingWithImages = branding;
    if (logo) {
      if (branding.image_logo) yield call(client.deleteImage, { image_id: branding.image_logo });
      brandingWithImages.image_logo = yield call(client.uploadImage, {
        file: logo,
      });
    }
    if (banner) {
      if (branding.image_banner)
        yield call(client.deleteImage, { image_id: branding.image_banner });
      brandingWithImages.image_banner = yield call(client.uploadImage, {
        file: banner,
      });
    }
    if (splashScreenImage) {
      if (branding.splash_screen_image)
        yield call(client.deleteImage, { image_id: branding.splash_screen_image });
      brandingWithImages.splash_screen_image = yield call(client.uploadImage, {
        file: splashScreenImage,
      });
    }
    if (logo || banner || splashScreenImage) {
      yield delay(2000); // wait extra time for Cloudflare to make new image(s) available
    }
    const updatedBranding: Branding = yield call(client.updateBranding, pk, brandingWithImages);
    if (updatedBranding) {
      yield put(updateOrganisationBrandingSuccess(updatedBranding));
      yield call(message.success, i18n.t('organisations:Branding.details.message.updateSuccess'));
    }
  } catch (err) {
    yield call(message.error, i18n.t('organisations:Branding.details.message.updateFailure'));
    console.error(err);
  }
}

export function* doGetOrganisationInstructionalScreens(): any {
  try {
    const { organisationSlug } = yield select(selectAppDetails);
    const client = yield call(doCreateOrganisationDirectoryClient);
    const instructionalScreens: IGetInstructionalScreensResponse = yield call(
      client.getInstructionalScreens,
      {
        organisation_slug: organisationSlug,
        page: 1,
      },
    );
    yield put(getOrganisationInstructionalScreensSuccess(instructionalScreens.results));
  } catch (err) {
    console.error(err);
  }
}

export function* doCreateOrganisationInstructionalScreen({
  payload: { instructionalScreen, image },
}: ICreateOrganisationInstructionalScreen): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const instructionalScreenWithImage = instructionalScreen;
    if (image) {
      const instructionalScreens: InstructionalScreen[] = yield select(
        selectOrganisationInstructionalScreens,
      );
      if (instructionalScreens.length >= 3) {
        yield call(
          message.error,
          i18n.t('organisations:Branding.instructionalScreens.message.maxInstructionalScreens'),
        );
        return;
      }
      instructionalScreenWithImage.image = yield call(client.uploadImage, {
        file: image,
      });
      yield delay(2000); // wait extra time for Cloudflare to make new image available
    }
    const createdInstructionalScreen: InstructionalScreen = yield call(
      client.createInstructionalScreen,
      instructionalScreenWithImage,
    );
    if (createdInstructionalScreen) {
      yield put(createOrganisationInstructionalScreenSuccess(createdInstructionalScreen));
      yield call(
        message.success,
        i18n.t('organisations:Branding.instructionalScreens.message.createSuccess'),
      );
    }
  } catch (err) {
    yield call(
      message.error,
      i18n.t('organisations:Branding.instructionalScreens.message.createFailure'),
    );
    console.error(err);
  }
}

export function* doUpdateOrganisationInstructionalScreen({
  payload: { pk, instructionalScreen, image },
}: IUpdateOrganisationInstructionalScreen): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);
    const instructionalScreenWithImage = instructionalScreen;
    if (image) {
      if (instructionalScreen.image)
        yield call(client.deleteImage, { image_id: instructionalScreen.image });
      instructionalScreenWithImage.image = yield call(client.uploadImage, {
        file: image,
      });
      yield delay(2000); // wait extra time for Cloudflare to make new image available
    }
    const updatedInstructionalScreen: InstructionalScreen = yield call(
      client.updateInstructionalScreen,
      pk,
      instructionalScreenWithImage,
    );
    if (updatedInstructionalScreen) {
      yield put(updateOrganisationInstructionalScreenSuccess(updatedInstructionalScreen));
      yield call(
        message.success,
        i18n.t('organisations:Branding.instructionalScreens.message.updateSuccess'),
      );
    }
  } catch (err) {
    yield call(
      message.error,
      i18n.t('organisations:Branding.instructionalScreens.message.updateFailure'),
    );
    console.error(err);
  }
}

export function* doRemoveOrganisationInstructionalScreens({
  payload: { pks },
}: IRemoveOrganisationInstructionalScreens): any {
  try {
    const client = yield call(doCreateOrganisationDirectoryClient);

    const pksOfSuccessfullyDeleted: number[] = [];
    for (const pk of pks) {
      try {
        yield call(client.deleteInstructionalScreen, pk);
        pksOfSuccessfullyDeleted.push(pk);
        yield put(removeOrganisationInstructionalScreenSuccess(pk));
      } catch (err) {
        console.error(err);
      }
    }

    if (pksOfSuccessfullyDeleted.length === pks.length) {
      yield call(
        message.success,
        i18n.t('organisations:Branding.instructionalScreens.message.removeSuccess'),
      );
    } else if (
      pksOfSuccessfullyDeleted.length > 0 &&
      pksOfSuccessfullyDeleted.length < pks.length
    ) {
      yield call(
        message.error,
        i18n.t('organisations:Branding.instructionalScreens.message.removePartialFailure'),
      );
    } else {
      yield call(
        message.error,
        i18n.t('organisations:Branding.instructionalScreens.message.removeFailure'),
      );
    }
  } catch (err) {
    yield call(message.error, i18n.t('organisations:InstructionalScreens.message.removeFailure'));
    console.error(err);
  }
}

export function* doReorderOrganisationInstructionalScreens({
  payload: { options },
}: IReorderOrganisationInstructionalScreens): any {
  try {
    const { primaryKey, newOrder } = options;
    const instructionalScreens: InstructionalScreen[] = yield select(
      selectOrganisationInstructionalScreens,
    );
    const matchingInstructionalScreen = instructionalScreens.find(
      screen => screen.pk === primaryKey,
    );

    if (!matchingInstructionalScreen) {
      throw new Error('No instructional screen found with the provided primary key');
    }

    if (newOrder === matchingInstructionalScreen._order) {
      return;
    }
    const isIncrement =
      matchingInstructionalScreen && newOrder === matchingInstructionalScreen._order + 1;

    const updatedInstructionalScreens = instructionalScreens.map(screen => {
      if (screen.pk === primaryKey) {
        return { ...screen, _order: newOrder };
      }

      /*
        Performs swapping of the order of the instructional screen object with the previous or next object
        If the instructional screen object was incremented, decreases the order of the subsequent object
        If the instructional screen object was decremented, increases the order of the previous object
      */
      if (isIncrement && screen._order === newOrder) {
        return { ...screen, _order: screen._order - 1 };
      } else if (!isIncrement && screen._order === newOrder) {
        return { ...screen, _order: screen._order + 1 };
      }

      return screen;
    });

    const pks = updatedInstructionalScreens
      .sort((a, b) => a._order - b._order)
      .map(screen => screen.pk);
    const client: OrganisationDirectoryClient = yield call(doCreateOrganisationDirectoryClient);
    yield call(client.reorderInstructionalScreens, pks);
    yield call(doGetOrganisationInstructionalScreens);
  } catch (err) {
    console.error(err);
  }
}
