function extractNewVersion(latestVersion: string) {
  if (!isNaN(Number(latestVersion))) return parseInt(latestVersion) + 1;

  const numbers = /Version\s([0-9]*)/.exec(latestVersion);
  if (numbers && numbers[1]) return parseInt(numbers[1]) + 1;

  return latestVersion;
}

export default extractNewVersion;
