import { Column, ColumnGroup, Card } from '@cards/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popover } from 'antd';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import IconButton from '@components/IconButton';
import { getOrganisationBranding } from '@redux/organisations/actions';
import { selectOrganisationBranding } from '@redux/organisations/reducers';
import { useTranslation } from 'react-i18next';
import ColourHexAndPreview from '@components/ColourHexAndPreview';
import ImageWithFallback from '@components/ImageWithFallback';
import Text from 'antd/lib/typography/Text';
import './style.less';

function Branding({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const branding = useSelector(selectOrganisationBranding);

  useEffect(() => {
    dispatch(getOrganisationBranding());
  }, [dispatch]);

  return (
    <ColumnGroup className="organisation-branding-details">
      <Column>
        <Card
          className="organisation-details-card"
          title={t('organisations:Branding.details.title')}
          extra={
            <>
              <Popover
                overlayClassName="app-organisation-branding-extra-popover"
                content={<p>{t('organisations:Branding.details.popover.body')}</p>}
                placement="bottomRight"
                title={t('organisations:Branding.details.popover.title')}
                trigger="click"
              >
                <IconButton
                  icon={faInfoCircle}
                  tooltip={t('common:buttons.info')}
                  onClick={() => {}}
                />
              </Popover>
              <Icon
                type="edit"
                onClick={() => history.push('/administration/organisation/branding/edit')}
              />
            </>
          }
        >
          {branding?.colour ||
          branding?.tagline ||
          branding?.description ||
          branding?.image_logo_url ||
          branding?.image_banner_url ? (
            <DetailForm>
              <DetailRow
                label={t('organisations:Branding.details.colour')}
                value={<ColourHexAndPreview hex={branding.colour} />}
              />
              <DetailRow
                label={t('organisations:Branding.details.tagline')}
                value={branding.tagline}
              />
              <DetailRow
                className="branding-description"
                label={t('organisations:Branding.details.description')}
                value={branding.description}
              />
              <DetailRow
                label={t('organisations:Branding.details.logo')}
                value={
                  branding?.image_logo_url ? (
                    <ImageWithFallback src={branding.image_logo_url} />
                  ) : (
                    <Text>{t('organisations:Branding.details.noLogo')}</Text>
                  )
                }
              />
              <DetailRow
                label={t('organisations:Branding.details.banner')}
                value={
                  branding?.image_banner_url ? (
                    <ImageWithFallback src={branding.image_banner_url} />
                  ) : (
                    <Text>{t('organisations:Branding.details.noBanner')}</Text>
                  )
                }
              />
            </DetailForm>
          ) : (
            <Text>{t('organisations:Branding.details.noBranding')}</Text>
          )}
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default Branding;
