import { call, getContext, select } from 'redux-saga/effects';
import { selectAppDetails } from '@organisation/redux/selectors';
import createPathwaysClient from '@api/pathwaysClient';

function* doCreatePathwaysClient() {
  const tokens = yield getContext('tokens');
  const jwt = yield call(tokens.get, 'pathways');
  const { appToken } = yield select(selectAppDetails);

  const pathwaysClient = yield call(createPathwaysClient, jwt, appToken);

  return pathwaysClient;
}

export default doCreatePathwaysClient;
