import { call, put, getContext, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../i18n';
import {
  loadIndexEvents,
  indexEventsLoaded,
  loadIndexEventsFailed,
  ICreateIndexEvent,
  createIndexEventFailed,
  createIndexEventSuccess,
  IEditIndexEvent,
  editIndexEventFailed,
  editIndexEventSuccess,
  IDeleteIndexEvents,
  deleteIndexEventsFailed,
  deleteIndexEventsSuccess,
  ISwitchIndexEventOrder,
  switchIndexEventOrderSuccess,
  switchIndexEventOrderFailed,
} from './actions';
import { IIndexEvent, IRawIndexEvent } from './types';
import takeFirst from '@redux/takeFirst';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { mapSnakeCaseIndexEventToCamelCase } from './utils';
import { selectIndexEvents } from './reducers';

export default function* root() {
  yield takeFirst('indexEvents/fetch', doFetchIndexEvents);
  yield takeEvery('indexEvents/createIndexEvent', doCreateIndexEvent);
  yield takeEvery('indexEvents/editIndexEvent', doEditIndexEvent);
  yield takeEvery('indexEvents/deleteIndexEvents', doDeleteIndexEvents);
  yield takeLatest('indexEvents/switchOrder', doSwitchIndexEventOrder);
}

export function* doFetchIndexEvents() {
  yield put(loadIndexEvents());

  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    let page = 1;
    let indexEvents: IIndexEvent[] = [];

    while (true) {
      const { next, results } = yield call(pathwaysClient.listIndexEventTypes, page);
      indexEvents = [
        ...indexEvents,
        ...results.map(
          (indexEvent: IRawIndexEvent): IIndexEvent =>
            mapSnakeCaseIndexEventToCamelCase(indexEvent),
        ),
      ];

      if (!next) break;
      page += 1;
    }

    yield put(indexEventsLoaded(indexEvents.sort((a, b) => a.order - b.order)));
  } catch (err) {
    console.error(err);
    yield put(loadIndexEventsFailed());
    yield call(message.error, i18n.t('cards:IndexEventsList.networkError'));
  }
}

function* doCreateIndexEvent({
  payload: { name, slug, translatedNames, disableNavigate },
}: ICreateIndexEvent) {
  try {
    const [, indexEvents]: [boolean, IIndexEvent[]] = yield select(selectIndexEvents);
    const numberOfIndexEvents = indexEvents?.length;
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const indexEvent: IRawIndexEvent = yield call(
      pathwaysClient.createIndexEventType,
      name,
      slug,
      translatedNames,
    );
    const editedIndexEvent = yield call(
      pathwaysClient.patchIndexEventType,
      indexEvent.id,
      indexEvent.name,
      indexEvent.slug,
      indexEvent.translated_names,
      numberOfIndexEvents + 1,
    );

    yield put(createIndexEventSuccess(mapSnakeCaseIndexEventToCamelCase(editedIndexEvent)));
    if (!disableNavigate) {
      yield call(history.push, '/administration/index-events');
      yield call(message.success, i18n.t('indexEvents:Wizard.create.success'));
    }
  } catch (err) {
    console.error(err);
    if (err.response?.body) {
      const errMessage = yield call(err.response.text.bind(err.response));

      if (errMessage?.includes(`An Index Event Type with slug \\"${slug}\\" already exists`)) {
        yield put(createIndexEventFailed());
        yield call(message.error, i18n.t('indexEvents:Wizard.create.alreadyExists'));
        return;
      }
    }
    yield put(createIndexEventFailed());
    yield call(message.error, i18n.t('indexEvents:Wizard.create.failed'));
  }
}

function* doEditIndexEvent({ payload: { indexEvent } }: IEditIndexEvent) {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);

    for (let [key, value] of Object.entries(indexEvent.translatedNames)) {
      if (!value || !value.length) {
        delete indexEvent.translatedNames[key];
      }
    }
    const editedIndexEvent = yield call(
      pathwaysClient.patchIndexEventType,
      indexEvent.id,
      indexEvent.name,
      indexEvent.slug,
      indexEvent.translatedNames,
      indexEvent.order,
    );

    yield put(editIndexEventSuccess(mapSnakeCaseIndexEventToCamelCase(editedIndexEvent)));
    yield call(history.push, `/administration/index-events/${indexEvent.id}`);
    yield call(message.success, i18n.t('indexEvents:Wizard.edit.success'));
  } catch (err) {
    console.error(err);
    yield put(editIndexEventFailed());
    yield call(message.error, i18n.t('indexEvents:Wizard.edit.failed'));
  }
}

function* doDeleteIndexEvents({ payload: { indexEventIds } }: IDeleteIndexEvents) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    // ensure all passed IDs are numbers
    const indexEventNumberIds = indexEventIds.map(id => Number(id));

    for (const indexEventId of indexEventNumberIds) {
      yield call(pathwaysClient.deleteIndexEventType, indexEventId);
    }

    yield put(deleteIndexEventsSuccess(indexEventNumberIds));
    yield call(message.success, i18n.t('indexEvents:List.delete.success'));
  } catch (err) {
    console.error(err);
    yield put(deleteIndexEventsFailed());
    yield call(message.error, i18n.t('indexEvents:List.delete.failed'));
  }
}

function* doSwitchIndexEventOrder({ payload: { id, order } }: ISwitchIndexEventOrder): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    let [, indexEvents]: [boolean, IIndexEvent[]] = yield select(selectIndexEvents);

    if (indexEvents.length === 0) {
      yield put(switchIndexEventOrderFailed());
      return;
    }

    const targetOrder = Math.max(1, order);

    indexEvents.sort((a, b) => a.order - b.order || a.id.toString().localeCompare(b.id.toString()));

    const targetEventIndex = indexEvents.findIndex(event => event.id === id);
    if (targetEventIndex === -1) {
      yield put(switchIndexEventOrderFailed());
      throw new Error('Target index event not found');
    }

    const [targetEvent] = indexEvents.splice(targetEventIndex, 1);
    const newIndex = Math.min(Math.max(targetOrder - 1, 0), indexEvents.length);
    indexEvents.splice(newIndex, 0, targetEvent);

    const updatedEvents = indexEvents.map((event, index) => ({
      ...event,
      newOrder: index + 1,
    }));

    const eventsToUpdate = updatedEvents.filter(event => event.order !== event.newOrder);

    for (const event of eventsToUpdate) {
      yield call(
        pathwaysClient.patchIndexEventType,
        event.id,
        undefined,
        undefined,
        undefined,
        event.newOrder,
      );
      yield put(editIndexEventSuccess({ ...event, order: event.newOrder }));
    }

    yield put(switchIndexEventOrderSuccess(id, newIndex + 1));
    yield call(message.success, i18n.t('indexEvents:List.order.success'));
  } catch (err) {
    console.error(err);
    yield put(switchIndexEventOrderFailed());
    yield call(message.error, i18n.t('indexEvents:List.order.failed'));
  }
}
