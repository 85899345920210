import { IState } from '../redux/reducer';

interface Metadata {
  language?: string;
  source?: string;
  tags?: {
    label: string;
  }[];
}

export const languageFromMetadata = (state: IState) => (record?: { metadata?: Metadata }) => {
  const fromTags = record?.metadata?.tags
    ?.map(t => t.label)
    .find(l => state.core.languages.list.includes(l.toLowerCase()));

  return fromTags ? fromTags : record?.metadata?.language;
};

export const sourceFromMetadata = (state: IState) => (record?: { metadata?: Metadata }) => {
  const fromTags = record?.metadata?.tags
    ?.map(t => t.label)
    .find(l => state.core.owners.list.includes(l.toLowerCase()));
  return fromTags ? fromTags : record?.metadata?.source;
};
