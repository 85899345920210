import { call, getContext, select } from 'redux-saga/effects';
import UISAdminClient from '@liquid-state/uis-client/dist/';
import { selectAppDetails } from '@organisation/redux/selectors';
import { uisApiRoot } from 'settings';

function* doCreateUISAdminClient() {
  const tokenPool = yield getContext('tokens');
  const jwt = yield call(tokenPool.get, 'uis');
  const { appToken } = yield select(selectAppDetails);

  return new UISAdminClient(appToken, jwt, { baseUrl: uisApiRoot });
}

export default doCreateUISAdminClient;
