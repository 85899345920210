import React from 'react';
import { Column, ColumnGroup } from '@cards/Card';
import EveregCard from '@cards/AppUsers/Evereg';

function TermsAndConditions() {
  return (
    <ColumnGroup>
      <Column>
        <EveregCard />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

export default TermsAndConditions;
