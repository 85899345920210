import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { Column } from '@cards/Card';
import WeblinksListCard from '@cards/Weblinks/List';
import NewContentDropdown from '@components/NewContentDropdown';
import { WEBLINK } from '@constants';

function List() {
  const { t } = useTranslation();
  usePageActions({
    actions: [<NewContentDropdown key={WEBLINK} primaryKey={WEBLINK} />],
    title: t('weblinks:List.header'),
  });

  document.title = t('weblinks:List.header');

  return (
    <Column>
      <WeblinksListCard />
    </Column>
  );
}

export default List;
