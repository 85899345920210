import React from 'react';
import { bool } from 'prop-types';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';

function UploadButton({ disabled }) {
  const { t } = useTranslation();
  return (
    <div className="upload-button">
      <Icon height="3em" type="cloud-upload" width="3em" />
      {disabled ? null : <p>{t('documents:Wizard.content.upload.help')}</p>}
      <p className="supported-upload">{t('documents:Wizard.content.upload.supported')}</p>
    </div>
  );
}

UploadButton.propTypes = {
  disabled: bool,
};

UploadButton.defaultProps = {
  disabled: false,
};

export default UploadButton;
