import React, { useMemo } from 'react';
import { string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from 'antd';
import {
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
} from '@constants';
import { hospitalAdmin, hospitalUser, superAdmin, supportAdmin, systemAdmin } from '@assets/icons';

function getDashboardUserTypeIcon(userType) {
  switch (userType) {
    case HOSPITAL_ADMIN:
      return hospitalAdmin;
    case HOSPITAL_USER:
      return hospitalUser;
    case SUPER_ADMIN:
      return superAdmin;
    case SUPPORT_ADMIN:
      return supportAdmin;
    case SYSTEM_ADMIN:
      return systemAdmin;
    default:
      return null;
  }
}

function getDashboardUserTypeLabel(userType) {
  switch (userType) {
    case HOSPITAL_ADMIN:
      return 'common:userTypes.hospitalAdmin';
    case HOSPITAL_USER:
      return 'common:userTypes.hospitalUser';
    case SUPER_ADMIN:
      return 'common:userTypes.superAdmin';
    case SUPPORT_ADMIN:
      return 'common:userTypes.supportAdmin';
    case SYSTEM_ADMIN:
      return 'common:userTypes.systemAdmin';
    default:
      return null;
  }
}

function DashboardUserTypeIcon({ userType, label, tooltip }) {
  const { t } = useTranslation();
  const icon = useMemo(() => getDashboardUserTypeIcon(userType), [userType]);
  const labelString = useMemo(() => getDashboardUserTypeLabel(userType), [userType]);

  if (!icon) return null;

  if (tooltip) {
    return (
      <Tooltip title={t(labelString)}>
        <Icon component={icon} /> {label ? t(labelString) : null}
      </Tooltip>
    );
  }

  return (
    <>
      <Icon component={icon} /> {label ? t(labelString) : null}
    </>
  );
}

DashboardUserTypeIcon.propTypes = {
  userType: string,
  label: bool,
  tooltip: bool,
};

DashboardUserTypeIcon.defaultProps = {
  userType: undefined,
  label: true,
  tooltip: false,
};

export default DashboardUserTypeIcon;

export { getDashboardUserTypeIcon, getDashboardUserTypeLabel, DashboardUserTypeIcon };
