import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { getTranslatedName } from '@utils';
import './style.less';

function SelectIndexEventModal({ selectedIndexEvent, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [loading, indexEvents] = useSelector(selectIndexEvents);

  useEffect(() => {
    if (selectedIndexEvent) {
      setSelected(selectedIndexEvent);
    }
  }, [selectedIndexEvent]);

  useEffect(() => {
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  return (
    <Modal
      className="choose-content-modal"
      title={t('pathways:ProcedureRules.wizard.timing.selectIndexEvent')}
      visible
      width={800}
      onCancel={onCancel}
      onOk={() => onOk(null, [selected])}
    >
      <Table
        columns={[
          {
            title: t('cards:IndexEventList.columns.name'),
            dataIndex: 'name',
            key: 'name',
            width: 250,
            ellipsis: true,
            sorter: (a, b) => a.name > b.name,
            render: (text, record) => getTranslatedName(record.translatedNames, text),
          },
        ]}
        dataSource={indexEvents}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected ? [selected.id] : [],
          type: 'radio',
          onSelect: record => setSelected(record),
        }}
      />
    </Modal>
  );
}

SelectIndexEventModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectIndexEventModal;
