import { call, getContext } from 'redux-saga/effects';
import createOrganisationDirectoryClient from '@api/organisationDirectoryClient';

function* doCreateOrganisationDirectoryClient(): any {
  const tokens = yield getContext('tokens');
  const client = yield call(createOrganisationDirectoryClient, tokens);

  return client;
}

export default doCreateOrganisationDirectoryClient;
