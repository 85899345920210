import kemtai from './kemtai/locales';

export default {
  en: {
    ...kemtai.en,
  },
  es: {
    ...kemtai.es,
  },
  de: {
    ...kemtai.de,
  },
  cs: {
    ...kemtai.cs,
  },
  ja: {
    ...kemtai.ja,
  },
  sk: {
    ...kemtai.sk,
  },
};
