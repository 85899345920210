import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import LanguageSelect from '@components/LanguageSelect';

const nameForbiddenChars = /[!@$%^&*()_+|~=`\\#{}[\]:";'<>?,./]/;

function OrganisationDetails({ editing }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  const validateName = (_, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    if (value.length > 48) {
      callback(t('organisations:Wizard.details.errors.tooLong'));
      return;
    }
    if (nameForbiddenChars.test(value)) {
      callback(t('organisations:Wizard.details.errors.invalidName'));
      return;
    }
    callback();
  };

  return (
    <Card className="hospital-wizard-card" title={t('organisations:Wizard.details.titles.details')}>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <Form.Item label={t('organisations:Wizard.details.labels.name')}>
          {form.getFieldDecorator('hospitalName', {
            initialValue: currentStepData.hospitalName,
            rules: [
              {
                required: true,
                message: t('organisations:Wizard.details.errors.organisationName'),
              },
              {
                validator: validateName,
              },
            ],
          })(
            <Input placeholder={t('organisations:Wizard.details.placeholders.organisationName')} />,
          )}
        </Form.Item>
        <Form.Item label={t('common:Wizard.details.language.label')}>
          {form.getFieldDecorator('language', {
            initialValue: currentStepData.language || 'en',
            rules: [
              {
                required: true,
                message: t('common:Wizard.details.language.required'),
              },
            ],
          })(<LanguageSelect />)}
        </Form.Item>
      </Form>

      {/* Disabled the invitation of an Org Admin at creation time for now */}
      {/* {editing ? null : (
        <>
          <h3>{t('organisations:Wizard.details.titles.admin')}</h3>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item label={t('organisations:Wizard.details.labels.firstName')}>
              {form.getFieldDecorator('firstName', {
                initialValue: currentStepData.firstName,
                rules: [
                  { required: false, message: t('organisations:Wizard.details.errors.contactName') },
                ],
              })(<Input placeholder={t('organisations:Wizard.details.placeholders.contactName')} />)}
            </Form.Item>
            <Form.Item label={t('organisations:Wizard.details.labels.lastName')}>
              {form.getFieldDecorator('lastName', {
                initialValue: currentStepData.lastName,
                rules: [
                  { required: false, message: t('organisations:Wizard.details.errors.contactName') },
                ],
              })(<Input placeholder={t('organisations:Wizard.details.placeholders.contactName')} />)}
            </Form.Item>
            <Form.Item label={t('organisations:Wizard.details.labels.email')}>
              {form.getFieldDecorator('email', {
                initialValue: currentStepData.email,
                rules: [
                  {
                    type: 'email',
                    required: false,
                    message: t('organisations:Wizard.details.errors.email'),
                  },
                ],
              })(<Input placeholder={t('organisations:Wizard.details.placeholders.email')} />)}
            </Form.Item>
          </Form>
        </>
      )} */}
    </Card>
  );
}

OrganisationDetails.propTypes = {
  editing: bool,
};

OrganisationDetails.defaultProps = {
  editing: false,
};

export default OrganisationDetails;
