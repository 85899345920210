import React from 'react';
import { arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { Card } from '@cards/Card';
import { appUserType, groupType } from '../../../../propTypes';

function Audience({ groups, individuals }) {
  const { t } = useTranslation();

  return (
    <Card className="message-wizard-review-audience" title={t('messages:Wizard.audience.stepName')}>
      {groups.length ? (
        <Table
          columns={[
            {
              dataIndex: 'name',
              key: 'groups',
              title: t('messages:Wizard.audience.groups.column'),
            },
          ]}
          dataSource={groups}
          pagination={false}
          rowKey="id"
        />
      ) : null}
      {individuals.length ? (
        <Table
          columns={[
            {
              dataIndex: 'firstName',
              key: 'individuals',
              render: (_, record) => record.displayName || record.id,
              title: t('messages:Wizard.audience.individuals.column'),
            },
          ]}
          dataSource={individuals}
          pagination={false}
          rowKey="id"
        />
      ) : null}
    </Card>
  );
}

Audience.propTypes = {
  groups: arrayOf(groupType),
  individuals: arrayOf(appUserType),
};

Audience.defaultProps = {
  groups: [],
  individuals: [],
};

export default Audience;
