import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Column, ColumnGroup } from '@cards/Card';
import ViewControlledDocuments from '@components/ViewControlledDocuments';
import ControlledDocumentAcceptanceHistory from '@components/ControlledDocumentAcceptanceHistory';
import {
  selectAppUserPrivacyPolicies,
  selectAppUserAcceptancesByIds,
} from '@redux/termsOfUse/reducers';
import { refreshAppUserTermsOfUse, fetchAppUserAcceptances } from '@redux/termsOfUse/actions';
import { setCurrentScreen, screens } from '../../analytics';

function PrivacyPolicy() {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_PRIVACY_POLICY);
  }, []);

  useEffect(() => {
    dispatch(refreshAppUserTermsOfUse(match.params.id));
    dispatch(fetchAppUserAcceptances(match.params.id));
  }, [dispatch, match.params.id]);
  const isLoading = useSelector(state => state.termsOfUse.appUsers[match.params.id]?.loading);
  const acceptancesLoading = useSelector(
    state => state.termsOfUse.acceptances[match.params.id]?.loading,
  );
  const privacyPolicies = useSelector(selectAppUserPrivacyPolicies(match.params.id));
  const privacyPoliciesIds = useMemo(() => {
    const ids = new Set();

    privacyPolicies.forEach(terms => ids.add(terms.slug));

    return Array.from(ids);
  }, [privacyPolicies]);

  const acceptances = useSelector(
    selectAppUserAcceptancesByIds(match.params.id, privacyPoliciesIds),
  );

  const acceptancesWithType = useMemo(
    () =>
      acceptances.map(acceptance => {
        return {
          ...acceptance,
          type: t(
            `common:source.${acceptance.version.url.includes('-global') ? 'global' : 'hospital'}`,
          ),
        };
      }),
    [acceptances, t],
  );

  return (
    <ColumnGroup>
      <Column>
        <ViewControlledDocuments
          loading={isLoading}
          controlledDocuments={privacyPolicies}
          title={t('myProfile:PrivacyPolicy.title')}
        />
      </Column>
      <Column style={{ width: '50%' }}>
        <ControlledDocumentAcceptanceHistory
          acceptances={acceptancesWithType}
          loading={isLoading || acceptancesLoading}
        />
      </Column>
    </ColumnGroup>
  );
}

export default PrivacyPolicy;
