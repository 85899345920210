import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import RuleTag from '@components/RuleTag';
import { fetchDocuments } from '@redux/documents/actions';
import { selectDocuments } from '@redux/documents/reducers';
import { fetchForms } from '@redux/forms/actions';
import { selectForms } from '@redux/forms/reducers';
import { fetchContentMessages } from '@redux/contentMessages/actions';
import { selectAllContentMessages } from '@redux/contentMessages/selectors';
import './style.less';

const { Column, ColumnGroup } = Table;

function getColumnHeading(time, isDate, t) {
  if (isDate) return moment(time).format('L');

  const days = Number(time);

  if (days > 0)
    return t('cards:AppUserIndexEventTriggeredRules.timing.daysAfter', { numberOfDays: days });
  if (days < 0)
    return t('cards:AppUserIndexEventTriggeredRules.timing.daysBefore', {
      numberOfDays: Math.abs(days),
    });

  return t('cards:AppUserIndexEventTriggeredRules.timing.onDay');
}

function IndexEventTriggeredRules({ rulesByDate, todaysDate, indexEventsByDate, triggeredRules }) {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchForms());
    dispatch(fetchContentMessages());
  }, [dispatch]);

  const [documentsLoading] = useSelector(selectDocuments);
  const [formsLoading] = useSelector(selectForms);
  const [cannedMessagesLoading] = useSelector(selectAllContentMessages);

  const loading = documentsLoading || formsLoading || cannedMessagesLoading;

  const orderedDates = useMemo(() => {
    const allDates = Array.from(
      new Set([...Object.keys(rulesByDate), ...Object.keys(indexEventsByDate)]),
    );

    return allDates.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
  }, [rulesByDate, indexEventsByDate]);

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <Table
      loading={loading}
      dataSource={[rulesByDate]}
      rowKey={record => 'key'}
      pagination={false}
      scroll={{ x: true }}
    >
      {orderedDates.map(date => (
        <ColumnGroup key={date} title={getColumnHeading(date, !!todaysDate, t)}>
          <Column
            className={date === todaysDate ? 'today-column' : ''}
            title={
              indexEventsByDate[date]
                ? indexEventsByDate[date].map(indexEvent => (
                    <div key={indexEvent.id}>
                      <FontAwesomeIcon icon={faCalendarStar} />{' '}
                      {t('cards:AppUserIndexEventTriggeredRules.indexEventAbbreviation')}{' '}
                      {indexEvent.translatedNames[languageCode] || indexEvent.name}
                    </div>
                  ))
                : ''
            }
            render={() =>
              rulesByDate[date]?.length ? (
                <div key={`rule-${date}`}>
                  {rulesByDate[date].map(rule => (
                    <div key={rule.id}>
                      <RuleTag
                        rule={rule}
                        triggered={rule.isTriggered}
                        key={rule.id}
                        isIndexEventRule
                      />
                    </div>
                  ))}
                </div>
              ) : (
                t('cards:AppUserIndexEventTriggeredRules.nothing')
              )
            }
          />
        </ColumnGroup>
      ))}
    </Table>
  );
}

export default IndexEventTriggeredRules;
