import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import Details from './Details';
// import Pathways from './Pathways';
import ReviewDetails from './Review/Details';
// import ReviewPathways from './Review/Pathways';
import './style.less';

function OrganisationWizard({ editing, initialData, loading, submitting, onCompleted }) {
  const { t } = useTranslation();
  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('organisations:Wizard.steps.details')}>
        <ColumnGroup>
          <Column>
            <Details editing={editing} />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      {/* <WizardItem stepKey="pathways" title={t('organisations:Wizard.steps.pathways')}>
        <ColumnGroup>
          <Column>
            <Pathways />
          </Column>
        </ColumnGroup>
      </WizardItem> */}
      <WizardItem stepKey="review" title={t('organisations:Wizard.steps.review')}>
        <ColumnGroup>
          <Column>
            <ReviewDetails editing={editing} />
          </Column>
          <Column></Column>
        </ColumnGroup>
        {/* <ColumnGroup>
          <Column>
            <ReviewPathways />
          </Column>
        </ColumnGroup> */}
      </WizardItem>
    </Wizard>
  );
}

OrganisationWizard.propTypes = {
  editing: bool,
  initialData: shape({}),
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
};

OrganisationWizard.defaultProps = {
  editing: false,
  loading: false,
  submitting: false,
};

export default OrganisationWizard;
