import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import Details from '@components/Wizard/Details';
import WizardItem from '@components/Wizard/Item';
import { Column } from '@cards/Card';
import Content from './Content';
import Audience from './Audience';
import Timing from './Timing';
import Review from './Review';
import './style.less';

function RuleWizard({ initialData, loading, submitting, onCompleted }) {
  const { t } = useTranslation();

  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('common:Wizard.details.stepName')}>
        <Details />
      </WizardItem>
      <WizardItem stepKey="content" title={t('pathways:ProcedureRules.wizard.content.stepName')}>
        <Column>
          <Content />
        </Column>
      </WizardItem>
      <WizardItem stepKey="audience" title={t('pathways:ProcedureRules.wizard.audience.stepName')}>
        <Column>
          <Audience />
        </Column>
      </WizardItem>
      <WizardItem stepKey="timing" title={t('pathways:ProcedureRules.wizard.timing.stepName')}>
        <Column>
          <Timing />
        </Column>
      </WizardItem>
      <WizardItem stepKey="review" title={t('pathways:ProcedureRules.wizard.review.stepName')}>
        <Column>
          <Review />
        </Column>
      </WizardItem>
    </Wizard>
  );
}

RuleWizard.propTypes = {
  initialData: shape({}),
  loading: bool,
  submitting: bool.isRequired,
  onCompleted: func.isRequired,
};

RuleWizard.defaultProps = {
  initialData: undefined,
  loading: false,
};

export default RuleWizard;
