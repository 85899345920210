import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ConnectionCodesList from './List';
// import DashboardUserDetails from './Details';
// import EditDashboardUser from './Edit';
// import NewDashboardUser from './New';
import { matchType } from '../../../propTypes';
// import './style.less';

function ConnectionCodes({ match }) {
  return (
    <Switch>
      {/* <Route path={`${match.path}/new`} component={NewDashboardUser} /> */}
      {/* <Route path={`${match.path}/:id/edit`} component={EditDashboardUser} /> */}
      {/* <Route path={`${match.path}/:id`} component={DashboardUserDetails} /> */}
      <Route path={`${match.path}`} component={ConnectionCodesList} />
    </Switch>
  );
}

ConnectionCodes.propTypes = {
  match: matchType.isRequired,
};

export default ConnectionCodes;
