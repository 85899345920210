import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import {
  getOrganisationInstructionalScreens,
  createOrganisationInstructionalScreen,
  updateOrganisationInstructionalScreen,
  removeOrganisationImage,
} from '@redux/organisations/actions';
import {
  selectOrganisationInstructionalScreenByPk,
  selectBrandingLoading,
} from '@redux/organisations/reducers';
import { Input, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import ImageUpload from '@components/ImageUpload';
import './style.less';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('organisations:EditOrganisationDetails.exitTitle'),
    content: t('organisations:EditOrganisationDetails.exitContent'),
    okText: t('organisations:EditOrganisationDetails.okText'),
    cancelText: t('organisations:EditOrganisationDetails.cancelText'),
    onOk: history.goBack,
  });
};

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

function EditInstructionalScreen({
  history,
  match,
  form: {
    getFieldDecorator,
    validateFields,
    getFieldError,
    setFieldsValue,
    setFields,
    getFieldsValue,
    getFieldValue,
  },
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const instructionalScreen = useSelector(
    selectOrganisationInstructionalScreenByPk(Number(match.params.pk)),
  );
  const [imageList, setImageList] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const brandingLoading = useSelector(selectBrandingLoading);
  const [isBrandingLoading, setIsBrandingLoading] = useState(brandingLoading);

  useEffect(() => {
    if (submitting && !isBrandingLoading) history.goBack();
  }, [isBrandingLoading, history, submitting]);

  useEffect(() => {
    setIsBrandingLoading(brandingLoading);
  }, [brandingLoading]);

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  useEffect(() => {
    dispatch(fetchOrganisation());
    dispatch(getOrganisationInstructionalScreens());
  }, [dispatch]);

  useEffect(() => {
    if (instructionalScreen) {
      setFieldsValue({
        text: instructionalScreen.text,
        backgroundColour: instructionalScreen.background_colour,
        textColour: instructionalScreen.text_colour,
      });
    }
  }, [instructionalScreen, setFieldsValue]);

  const [, organisation] = useSelector(selectOrganisation(dashboardUserProfile.organisationId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: true,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = (organisation && organisation.name) || '';
    setTitle(name);
    document.title = name;
  }, [organisation, setTitle]);

  const handleSubmit = e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        setSubmitting(true);

        if (removeImage) {
          dispatch(removeOrganisationImage(instructionalScreen.image));
        }

        const { text, textColour, backgroundColour } = values;
        if (instructionalScreen?.pk) {
          dispatch(
            updateOrganisationInstructionalScreen(
              instructionalScreen.pk,
              {
                text,
                text_colour: textColour,
                background_colour: backgroundColour,
                image: removeImage ? null : instructionalScreen.image,
              },
              imageList[0]?.originFileObj,
            ),
          );
        } else {
          dispatch(
            createOrganisationInstructionalScreen(
              {
                text,
                text_colour: textColour,
                background_colour: backgroundColour,
              },
              imageList[0]?.originFileObj,
            ),
          );
        }
      } else {
        setSubmitting(false);
        setIsBrandingLoading(false);
      }
    });
  };

  const validateText = (rule, value, callback) => {
    const { text } = getFieldsValue();

    if (!text && !imageList.length && (!instructionalScreen.image_url || removeImage)) {
      callback(t('organisations:Branding.instructionalScreens.textRequired'));
      return;
    }

    callback();
  };

  const validateTextColour = (rule, value, callback) => {
    const { text, textColour } = getFieldsValue();

    if (text && !textColour) {
      callback(t('organisations:Branding.instructionalScreens.textColourRequired'));
      return;
    }

    callback();
  };

  const validateBackgroundColour = (rule, value, callback) => {
    const { backgroundColour } = getFieldsValue();

    if (!backgroundColour) {
      callback(t('organisations:Branding.instructionalScreens.backgroundColourRequired'));
      return;
    }

    callback();
  };

  const validateImage = (rule, value, callback) => {
    const { text } = getFieldsValue();

    if (!text && !imageList.length && (!instructionalScreen.image_url || removeImage)) {
      callback(t('organisations:Branding.instructionalScreens.imageRequired'));
      return;
    }

    callback();
  };

  const textValue = getFieldValue('text');
  useEffect(() => {
    if (imageList.length) {
      setFields({
        image: {
          error: undefined,
        },
        text: {
          error: undefined,
          value: textValue,
        },
      });
    }

    if (textValue) {
      setFields({
        image: {
          error: undefined,
        },
      });
    }
  }, [imageList, setFields, textValue]);

  return (
    <ColumnGroup className="edit-organisation-instructional-screen">
      <Column>
        <Card
          className="organisation-details-form-card"
          title={
            instructionalScreen
              ? t('organisations:Branding.instructionalScreens.edit.updateTitle')
              : t('organisations:Branding.instructionalScreens.edit.createTitle')
          }
        >
          <Form {...detailsLayout} onSubmit={handleSubmit}>
            <Form.Item label={t('organisations:Branding.instructionalScreens.text')}>
              <div className="instructional-screen-field-container">
                {getFieldDecorator('text', {
                  rules: [
                    {
                      validator: validateText,
                    },
                  ],
                })(
                  <Input
                    type="text"
                    allowClear
                    // 230 characters was chosen by testing the maximum number of characters
                    // that fit on a small screen in the mobile app
                    maxLength={230}
                  />,
                )}
                {!getFieldError('text') && (
                  <Text className="help-text">
                    {t('organisations:Branding.instructionalScreens.textHelpText')}
                  </Text>
                )}
              </div>
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('organisations:Branding.instructionalScreens.textColour')}
            >
              <div className="instructional-screen-field-container">
                {getFieldDecorator('textColour', {
                  rules: [
                    {
                      validator: validateTextColour,
                    },
                  ],
                })(<Input type="text" placeholder="#000000" allowClear />)}
                {!getFieldError('textColour') && (
                  <Text className="help-text">
                    {t('organisations:Branding.instructionalScreens.textColourHelpText')}
                  </Text>
                )}
              </div>
              <Input
                style={{ width: '25%' }}
                type="color"
                value={getFieldValue('textColour') || '#ffffff'}
                onChange={e => setFieldsValue({ textColour: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="details-item"
              label={t('organisations:Branding.instructionalScreens.backgroundColour')}
            >
              <div className="instructional-screen-field-container">
                {getFieldDecorator('backgroundColour', {
                  rules: [
                    {
                      validator: validateBackgroundColour,
                    },
                  ],
                })(<Input type="text" placeholder="#ffffff" allowClear />)}
                {!getFieldError('backgroundColour') && (
                  <Text className="help-text">
                    {t('organisations:Branding.instructionalScreens.backgroundColourHelpText')}
                  </Text>
                )}
              </div>
              <Input
                style={{ width: '25%' }}
                type="color"
                value={getFieldValue('backgroundColour') || '#ffffff'}
                onChange={e => setFieldsValue({ backgroundColour: e.target.value })}
              />
            </Form.Item>
            <Form.Item label={t('organisations:Branding.instructionalScreens.image')}>
              <div className="instructional-screen-field-container">
                {getFieldDecorator('image', {
                  rules: [
                    {
                      validator: validateImage,
                    },
                  ],
                })(
                  <ImageUpload
                    imageList={imageList}
                    setImageList={setImageList}
                    setRemove={setRemoveImage}
                    imageUrl={instructionalScreen?.image_url}
                  />,
                )}
                {!getFieldError('image') && (
                  <Text className="help-text">
                    {t('organisations:Branding.instructionalScreens.imageHelpText')}
                  </Text>
                )}
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: '10px' }}
                loading={isBrandingLoading}
                onClick={() => setIsBrandingLoading(true)}
              >
                {t('organisations:Branding.instructionalScreens.edit.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

const WrappedEditInstructionalScreen = Form.create()(EditInstructionalScreen);
export default WrappedEditInstructionalScreen;
