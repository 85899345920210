import { call, getContext } from 'redux-saga/effects';

import KemtaiService, {
  KemtaiClient,
  IKemtaiClient,
} from '@liquid-state/pephealth-client/dist/kemtai';
import * as Types from '@liquid-state/pephealth-client/dist/kemtai/types';

import { pepHealthApiRoot } from 'settings';

export async function createKemtaiClient(jwt: string) {
  try {
    const client = await new KemtaiClient(jwt, { baseUrl: pepHealthApiRoot });
    return client;
  } catch (err) {
    console.error(err);
  }
}

export { Types };

export default function* createKemtaiService(): any {
  const tokens = yield getContext('tokens');
  const token = yield call(tokens.get, 'pephealth');
  const client: IKemtaiClient = yield call(createKemtaiClient, token);
  const service = new KemtaiService(client);
  return service;
}
