import React, { useCallback, useEffect } from 'react';
import { /* useDispatch, useStore, */ useSelector } from 'react-redux';
// import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
// import { Modal } from 'antd';
// import { useTranslation } from 'react-i18next';
import { Column, ColumnGroup } from '@cards/Card';
import { usePageActions } from '@hooks';
// import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
// import { waitForStoreUpdate } from '@utils';
import DetailsCard from '../../cards/IndexEvents/Details';
// import { deleteIndexEvents } from '../../redux/indexEvents/actions';
import { selectIndexEvent /* selectIndexEventDeleting */ } from '../../redux/indexEvents/reducers';
import { historyType, matchType } from '../../../propTypes';

function Details({ history, match }) {
  // const { t } = useTranslation();
  // const store = useStore();
  // const dispatch = useDispatch();
  const selector = useCallback(selectIndexEvent(match.params.id), [match]);
  const [loading, indexEvent] = useSelector(selector);

  // const onDelete = useCallback(
  //   () =>
  //     Modal.confirm({
  //       title: t('indexEvents:Details.delete.confirm'),
  //       onOk: () => {
  //         dispatch(deleteIndexEvents([match.params.id]));
  //         return waitForStoreUpdate(store, selectIndexEventDeleting).then(() =>
  //           history.push('/administration/index-events'),
  //         );
  //       },
  //       onCancel: () => {},
  //     }),
  //   [dispatch, history, match, store, t],
  // );

  const { setTitle } = usePageActions({
    actions: [
      // <IconButton
      //   key="delete-btn"
      //   icon={faTrashAlt}
      //   size="large"
      //   tooltip={t('common:buttons.delete')}
      //   onClick={onDelete}
      // />,
      <EditButton key="edit-index-event" onClick={() => history.push(`${match.url}/edit`)} />,
    ],
    showBack: true,
    title: ' ',
  });

  useEffect(() => {
    setTitle(indexEvent?.name);
  }, [indexEvent, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard loading={loading} indexEventId={match.params.id} />
      </Column>
      <Column />
    </ColumnGroup>
  );
}

Details.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default Details;
