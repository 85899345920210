import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePageActions } from '@hooks';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import HeaderButton from '@components/HeaderButton';
// import Branding from './Branding';
import { mobile } from '@assets/icons';
import Details from './Details';
import { loadOrgConfig } from '@integrations/redux/actions';
import { selectOrgConfig } from '@integrations/redux/reducers';
import KemtaiCreateConfig from '@integrations/kemtai/pages/CreateOrganisationConfig';
import KemtaiEditConfig from '@integrations/kemtai/pages/EditOrganisationConfig';
import './style.less';

const routes = {
  kemtai: 'kemtai',
  edit: 'edit',
  details: 'details',
  content: 'public-content',
  branding: 'branding',
  splashScreen: 'splash-screen',
  instructionalScreens: 'instructional-screens',
};

const Integrations = ({ history, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, organisation] = useSelector(selectOrganisation());
  const [, kemtaiOrgConfig] = useSelector(selectOrgConfig('kemtai'));

  const { setTitle, setActions } = usePageActions({
    title: '',
    actions: [],
    showBack: false,
  });

  useEffect(() => {
    dispatch(fetchOrganisation());
    dispatch(loadOrgConfig('kemtai'));
  }, [dispatch]);

  useEffect(() => {
    // TODO
    // dispatch(fetchOrgConfigs());
  }, [dispatch]);

  useEffect(() => {
    const name = organisation?.updated_organisation_name || organisation?.organisation_name || '';
    setTitle(name + ' - integrations');
    document.title = name;
  }, [organisation, setTitle, kemtaiOrgConfig]);

  useEffect(() => {
    if (
      history.location.pathname.endsWith(routes.edit) ||
      !history.location.pathname.endsWith(routes.details)
    ) {
      setActions([]);
    } else {
      setActions([
        <HeaderButton
          key="edit-btn"
          tooltip={t('common:buttons.edit')}
          onClick={() => history.push(`${match.url}/${routes.edit}`)}
        >
          {t('common:buttons.edit')}
        </HeaderButton>,
      ]);
    }
  }, [match, history, t, setActions]);

  return (
    <Switch>
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.kemtai}>
              <FontAwesomeIcon size="sm" icon={mobile} />
              {t('kemtai:menu.kemtai')}
            </Menu.Item>
            {/* <Menu.Item key={routes.branding}>
              <FontAwesomeIcon size="sm" icon={mobile} />
              branding
            </Menu.Item> */}
          </SubNavMenu>
        }
      >
        <Switch>
          {kemtaiOrgConfig ? (
            <Route path={`${match.path}/${routes.kemtai}`} component={KemtaiEditConfig} />
          ) : (
            <Route path={`${match.path}/${routes.kemtai}`} component={KemtaiCreateConfig} />
          )}
          <Route path={`${match.path}/${routes.details}`} component={Details} />
          {/* <Route path={`${match.path}/${routes.branding}`} component={Branding} /> */}
          <Route
            path={match.path}
            render={() => <Redirect to={`${match.path}/${routes.kemtai}`} />}
          />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
};

export default Integrations;
