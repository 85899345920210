import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Table } from 'antd';
import { selectGroups } from '@redux/groups/reducer';
import { fetchGroups } from '@redux/groups/actions';
import { useSearch } from '@hooks';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

function SelectGroupsModal({ audienceData, defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, groups] = useSelector(selectGroups);
  const [selected, setSelected] = useState([]);
  const [searchResults, searchProps] = useSearch(groups, filterFunc);
  const selectedRowKeys = useMemo(() => selected.map(({ id }) => id), [selected]);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue), [t, defaultFilteredValue]);

  useEffect(() => {
    if (audienceData) {
      setSelected(audienceData);
    }
  }, [audienceData]);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return (
    <Modal
      className="choose-content-modal"
      title={t('pathways:ProcedureRules.wizard.audience.selectGroups')}
      visible
      width={800}
      onCancel={onCancel}
      onOk={() => onOk(selected)}
    >
      <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onSelect: (record, selected, selectedRows) => setSelected(selectedRows),
        }}
      />
    </Modal>
  );
}

SelectGroupsModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectGroupsModal;
