import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Details from './Details';
import Edit from './Edit';
import List from './List';
import New from './New';

function Weblinks({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/:weblinkId/edit`} component={Edit} />
      <Route path={`${match.path}/new`} component={New} />
      <Route path={`${match.path}/:weblinkId`} component={Details} />
      <Route path={match.path} component={List} />
      <Redirect to={match.path} />
    </Switch>
  );
}

export default Weblinks;
