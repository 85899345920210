import { DASHBOARD_PRIVACY_POLICY_SUBMITTED, onbardingSuccess } from './actions';
import { takeLatest, call, put, getContext, delay } from 'redux-saga/effects';

export default function* onboardingRegistration() {
  yield takeLatest(DASHBOARD_PRIVACY_POLICY_SUBMITTED, onOnboardingComplete);
}

function* onOnboardingComplete() {
  const history = yield getContext('history');
  yield delay(2000);
  yield put(onbardingSuccess());
  yield call(history.replace, '/');
}
