import { AuthenticationHelper, CognitoUser } from "amazon-cognito-identity-js";

export default class CustomCognitoUser extends CognitoUser {
	static fromStandardUser = (user) => {
		return new CustomCognitoUser({ Username: user.username, Pool: user.pool, Storage: user.storage });
	}

  authenticateUserInternal = (dataAuthenticate, authenticationHelper, callback) => {
    const challengeName = dataAuthenticate.ChallengeName;
		const challengeParameters = dataAuthenticate.ChallengeParameters;

		if (challengeName === 'EMAIL_OTP') {
			this.Session = dataAuthenticate.Session;
			return callback.mfaRequired(challengeName, challengeParameters);
		}

    return super.authenticateUserInternal(dataAuthenticate, authenticationHelper, callback);
  }

  sendMFACode = (confirmationCode, callback, mfaType, clientMetadata) => {
		const challengeResponses = {};
		challengeResponses.USERNAME = this.username;
		challengeResponses.SMS_MFA_CODE = confirmationCode;
		const mfaTypeSelection = mfaType || 'SMS_MFA';
		if (mfaTypeSelection === 'SOFTWARE_TOKEN_MFA') {
			challengeResponses.SOFTWARE_TOKEN_MFA_CODE = confirmationCode;
		}
    if (mfaTypeSelection === "EMAIL_OTP") {
      challengeResponses.EMAIL_OTP_CODE = confirmationCode;
    }

		if (this.deviceKey != null) {
			challengeResponses.DEVICE_KEY = this.deviceKey;
		}

		const jsonReq = {
			ChallengeName: mfaTypeSelection,
			ChallengeResponses: challengeResponses,
			ClientId: this.pool.getClientId(),
			Session: this.Session,
			ClientMetadata: clientMetadata,
		};
		if (this.getUserContextData()) {
			jsonReq.UserContextData = this.getUserContextData();
		}

		this.client.request(
			'RespondToAuthChallenge',
			jsonReq,
			(err, dataAuthenticate) => {
				if (err) {
					return callback.onFailure(err);
				}

				const challengeName = dataAuthenticate.ChallengeName;

				if (challengeName === 'DEVICE_SRP_AUTH') {
					this.getDeviceResponse(callback);
					return undefined;
				}

				this.signInUserSession = this.getCognitoUserSession(
					dataAuthenticate.AuthenticationResult
				);
				this.cacheTokens();

				if (dataAuthenticate.AuthenticationResult.NewDeviceMetadata == null) {
					return callback.onSuccess(this.signInUserSession);
				}

				const authenticationHelper = new AuthenticationHelper(
					this.pool.getUserPoolId().split('_')[1]
				);
				authenticationHelper.generateHashDevice(
					dataAuthenticate.AuthenticationResult.NewDeviceMetadata
						.DeviceGroupKey,
					dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceKey,
					errGenHash => {
						if (errGenHash) {
							return callback.onFailure(errGenHash);
						}

						const deviceSecretVerifierConfig = {
							Salt: Buffer.from(
								authenticationHelper.getSaltDevices(),
								'hex'
							).toString('base64'),
							PasswordVerifier: Buffer.from(
								authenticationHelper.getVerifierDevices(),
								'hex'
							).toString('base64'),
						};

						this.verifierDevices = deviceSecretVerifierConfig.PasswordVerifier;
						this.deviceGroupKey =
							dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceGroupKey;
						this.randomPassword = authenticationHelper.getRandomPassword();

						this.client.request(
							'ConfirmDevice',
							{
								DeviceKey:
									dataAuthenticate.AuthenticationResult.NewDeviceMetadata
										.DeviceKey,
								AccessToken: this.signInUserSession
									.getAccessToken()
									.getJwtToken(),
								DeviceSecretVerifierConfig: deviceSecretVerifierConfig,
								DeviceName: navigator.userAgent,
							},
							(errConfirm, dataConfirm) => {
								if (errConfirm) {
									return callback.onFailure(errConfirm);
								}

								this.deviceKey =
									dataAuthenticate.AuthenticationResult.NewDeviceMetadata.DeviceKey;
								this.cacheDeviceKeyAndPassword();
								if (dataConfirm.UserConfirmationNecessary === true) {
									return callback.onSuccess(
										this.signInUserSession,
										dataConfirm.UserConfirmationNecessary
									);
								}
								return callback.onSuccess(this.signInUserSession);
							}
						);
						return undefined;
					}
				);
				return undefined;
			}
		);
	}

	setUserMfaPreference(smsMfaSettings, softwareTokenMfaSettings, emailMfaSettings, callback) {
		if (this.signInUserSession == null || !this.signInUserSession.isValid()) {
			return callback(new Error('User is not authenticated'), null);
		}

		this.client.request(
			'SetUserMFAPreference',
			{
				SMSMfaSettings: smsMfaSettings,
				SoftwareTokenMfaSettings: softwareTokenMfaSettings,
				EmailMfaSettings: emailMfaSettings,
				AccessToken: this.signInUserSession.getAccessToken().getJwtToken(),
			},
			err => {
				if (err) {
					return callback(err, null);
				}
				return callback(null, 'SUCCESS');
			}
		);
		return undefined;
	}
}