import React from 'react';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { WEBSITE, WEB_FORM } from '@constants';
import WeblinkTypeIcon from '@components/WeblinkTypeIcon';
import './style.less';

export default (t, defaultFilteredValue) => [
  {
    dataIndex: 'type',
    filters: [
      {
        text: t('common:contentTypes.singular.website'),
        value: WEBSITE,
      },
      {
        text: t('common:contentTypes.singular.webform'),
        value: WEB_FORM,
      },
    ],
    title: t('cards:WeblinkList.columns.type.title'),
    width: 150,
    onFilter: (value, record) => value === record.type,
    render: type => <WeblinkTypeIcon showText type={type} />,
    sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
  },
  {
    dataIndex: 'name',
    title: t('cards:WeblinkList.columns.name.title'),
  },
  {
    className: 'published-column',
    dataIndex: 'is_available',
    title: t('cards:WeblinkList.columns.published.title'),
    render: published => <PublishedStatusIcon published={published} />,
  },
  {
    title: t('cards:WeblinkList.columns.audience.title'),
    key: 'uuid',
    dataIndex: 'audience_type',
    render: audienceType => {
      if (audienceType) return t(`cards:WeblinkList.columns.audience.${audienceType}`);
      return '';
    },
  },
];
