import { useTranslation } from 'react-i18next';

/* As we create our custom data structures we have established a fairly consistent pattern
for translatable data, such as descriptions, display_names etc.
The data for these keys can either be:
  * A single string which is not translated
  * An object inside of which are keys corresponding to language codes, 
and the string to be displayed for each language.
This hook returns a function which can be used to return the correct string for one of these translatable
keys regardless of which form it takes, and accounts for the current dashboard language.
This defaults to english and then "Missing Translation" if it cannot resolve a value.
*/
const useTranslatableData = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return data => {
    if (typeof data === 'string') {
      return data;
    }
    let targetLanguage = language;
    if (language.includes('-')) {
      targetLanguage = language.split('-')[0];
    }
    const translatedName = data[targetLanguage] ?? data['en'] ?? 'Missing Translation';
    return translatedName;
  };
};

export default useTranslatableData;
