export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const LOGIN_SUBMITTED = 'LOGIN_SUBMITTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
export const AUTHENTICATION_SUBMITTED = 'AUTHENTICATION_SUBMITTED';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const SESSION_ESTABLISHED = 'SESSION_ESTABLISHED';
export const SET_MFA_PREFERENCE_SUCCESSFUL = 'SET_MFA_PREFERENCE_SUCCESSFUL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';

export const GO_TO_PLATFORM_APP = 'GO_TO_PLATFORM_APP';

export const loginRequired = () => ({
  type: LOGIN_REQUIRED,
  payload: {},
});

export const loginSubmitted = ({ email, password }) => ({
  type: LOGIN_SUBMITTED,
  payload: {
    email,
    password,
  },
});

export const loginFailed = () => ({
  type: LOGIN_FAILED,
  payload: {},
});

export const sessionEstablished = (id, userData) => ({
  type: SESSION_ESTABLISHED,
  payload: {
    id,
    data: userData,
  },
});

export const authenticationRequired = (challengeType, codeDestination) => ({
  type: AUTHENTICATION_REQUIRED,
  payload: {
    challengeType,
    codeDestination,
  },
});

export const authenticationSubmitted = ({ code }) => ({
  type: AUTHENTICATION_SUBMITTED,
  payload: {
    code,
  },
});

export const authenticationFailed = () => ({
  type: AUTHENTICATION_FAILED,
  payload: {},
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const logoutSuccesful = () => ({
  type: LOGOUT_SUCCESSFUL,
  payload: {},
});

export const goToPlatformApp = platformAppId => ({
  type: GO_TO_PLATFORM_APP,
  payload: { platformAppId },
});

export const setMFAPreferenceSuccessful = mfaPreference => ({
  type: SET_MFA_PREFERENCE_SUCCESSFUL,
  payload: { mfaPreference },
});
