import React from 'react';
import { ColumnGroup, Column } from '@cards/Card';

import AppAdminDetailsCard from './AppAdminDetailsCard';

import './styles.less';

export default ({
  match: {
    params: { id },
  },
}) => {
  return (
    <ColumnGroup>
      <Column>
        <AppAdminDetailsCard hospitalId={id} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};
