import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchAppUsers } from '@redux/appUsers/actions';
import {
  fetchAppUsersPathways,
  setAppUserPathwayStage,
} from '@pathways/redux/appUserPathways/actions';
import { Card } from '@cards/Card';
import StageSteps from '@components/StageSteps';
import createDataSource from './createDataSource';

const StageActionButton = ({ label, loading, onClick }) => (
  <Button loading={loading} type="link" onClick={onClick}>
    {label}
  </Button>
);

const columns = [
  {
    title: 'Title',
    key: 'name',
  },
  {
    title: 'Action',
    key: 'action',
  },
];

// Only currently supports PROCEDURE PATHWAYS
const AppUserPathwayStages = ({ appUserId, appUserPathway, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  const currentStageSlug = useMemo(() => {
    return appUserPathway ? appUserPathway.currentStageSlug : '';
  }, [appUserPathway]);

  const transitionPathwayToStage = useCallback(
    stageSlug => dispatch(setAppUserPathwayStage(appUserId, stageSlug, appUserPathway?.id)),
    [dispatch, appUserId, appUserPathway],
  );

  const getStageAction = useCallback(
    (index, currentStageIndex, stages) => {
      if (index === currentStageIndex && currentStageIndex + 1 !== stages.length) {
        return (
          <StageActionButton
            label={t('cards:PatientPathwayStages.next')}
            loading={false}
            onClick={() => transitionPathwayToStage(stages[currentStageIndex + 1].slug)}
          />
        );
      }

      if (currentStageIndex > 0 && index === currentStageIndex - 1) {
        return (
          <StageActionButton
            label={t('cards:PatientPathwayStages.revert')}
            loading={false}
            onClick={() => transitionPathwayToStage(stages[currentStageIndex - 1].slug)}
          />
        );
      }

      return null;
    },
    [t, transitionPathwayToStage],
  );

  const [dataSource, currentIndex] = useMemo(
    () =>
      appUserPathway?.originalPathway && appUserPathway.originalPathway.stages
        ? createDataSource(
            t,
            getStageAction,
            appUserPathway.originalPathway.stages,
            currentStageSlug,
          )
        : [[]],
    [t, getStageAction, appUserPathway, currentStageSlug],
  );

  return (
    <Card
      {...props}
      title={appUserPathway?.originalPathway?.name || ''}
      noPadding
      loading={!appUserPathway}
    >
      <StageSteps columns={columns} current={currentIndex} dataSource={dataSource} />
    </Card>
  );
};

AppUserPathwayStages.propTypes = {
  appUserId: string.isRequired,
  pathwayId: number.isRequired,
};

AppUserPathwayStages.defaultProps = {};

export default AppUserPathwayStages;
