import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { editFlag, fetchFlags } from '@redux/flags/actions';
import { selectFlagEditing, selectFlag } from '@redux/flags/reducers';
import { matchType } from '../../../propTypes';
import FlagWizard from './Wizard';

function EditFlag({
  match: {
    params: { id },
  },
}) {
  const [loading, flag] = useSelector(selectFlag(id));
  const editing = useSelector(selectFlagEditing);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({ showClose: true, title: flag?.name || ' ' });

  useEffect(() => {
    if (flag) {
      setTitle(flag.name);
    }
  }, [flag, setTitle]);

  useEffect(() => {
    dispatch(fetchFlags());
  }, [dispatch]);

  const initialData = useMemo(() => {
    if (!flag) return {};
    return flag;
  }, [flag]);

  const onSubmit = useCallback(
    data => {
      dispatch(editFlag(flag.uuid, data));
    },
    [dispatch, flag],
  );

  if (loading || !flag) return null;

  return (
    <FlagWizard
      initialData={initialData}
      submitting={editing}
      title={t('flags:Wizard.editDetailsTitle')}
      onSubmit={onSubmit}
      isEditing={true}
    />
  );
}

EditFlag.propTypes = {
  match: matchType.isRequired,
};

export default EditFlag;
