import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Form, InputNumber } from 'antd';
import { Card, ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import { ALL_ENGAGED, AT_LEAST_N, ZERO } from '@constants';
import Text from 'antd/lib/typography/Text';

import './style.less';

function CheckType() {
  const { t } = useTranslation();
  const { currentStepData, form, setCurrentStepData, formData } = useWizard();

  const handleSelection = useCallback(
    e => {
      setCurrentStepData({ ...currentStepData, checkType: e.target.value });
      form.setFieldsValue({ checkType: e.target.value });
    },
    [setCurrentStepData, currentStepData, form],
  );

  const handleNumberChange = useCallback(
    value => {
      setCurrentStepData({ ...currentStepData, numberOfContents: value });
      form.setFieldsValue({ numberOfContents: value });
    },
    [setCurrentStepData, currentStepData, form],
  );

  return (
    <ColumnGroup className="check-type-step">
      <Column>
        <Card title={t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.title')}>
          <Text className="check-type-info">
            {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.description')}
          </Text>
          <Form>
            <Form.Item>
              {form.getFieldDecorator('checkType', {
                initialValue: currentStepData.checkType,
                rules: [
                  {
                    required: true,
                    message: t(
                      'pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.required',
                    ),
                  },
                ],
              })(
                <Radio.Group onChange={handleSelection}>
                  <Radio value={ALL_ENGAGED}>
                    {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.all')}
                  </Radio>
                  <Radio value={AT_LEAST_N}>
                    {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.atLeast', {
                      count: currentStepData.numberOfContents || 'N',
                    })}
                  </Radio>
                  {form.getFieldValue('checkType') === AT_LEAST_N && (
                    <Form.Item>
                      {form.getFieldDecorator('numberOfContents', {
                        initialValue: currentStepData.numberOfContents,
                        rules: [
                          {
                            required: true,
                            message: t(
                              'pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.required',
                            ),
                          },
                        ],
                      })(
                        <InputNumber
                          onChange={handleNumberChange}
                          min={1}
                          max={formData?.contentToCheckEngagement?.selectedContent?.length - 1}
                        />,
                      )}
                    </Form.Item>
                  )}
                  <Radio value={ZERO}>
                    {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.zero')}
                  </Radio>
                </Radio.Group>,
              )}
            </Form.Item>
          </Form>
        </Card>
      </Column>
      <Column>
        <Card
          className="explanation-card"
          title={t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.title')}
        >
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.explanation1')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.explanation2')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.explanation3')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
          <p>
            {t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.explanation4')
              .split('*')
              .map((part, index) => (index % 2 === 1 ? <strong>{part}</strong> : part))}
          </p>
        </Card>
      </Column>
    </ColumnGroup>
  );
}

export default CheckType;
