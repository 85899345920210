import { IContentMessage } from './types';

export type IFetchContentMessages = ReturnType<typeof fetchContentMessages>;
export const fetchContentMessages = () => ({
  type: 'content-messages/fetch' as const,
  payload: {},
});

export type ILoadContentMessages = ReturnType<typeof loadContentMessages>;
export const loadContentMessages = () => ({
  type: 'content-messages/load' as const,
  payload: {},
});

export type IContentMessagesLoaded = ReturnType<typeof contentMessagesLoaded>;
export const contentMessagesLoaded = (messages: IContentMessage[]) => ({
  type: 'content-messages/loaded' as const,
  payload: {
    messages,
  },
});

export type ICreateContentMessage = ReturnType<typeof createContentMessage>;
export const createContentMessage = (
  message: IContentMessage,
  type: string,
  messageTranslationKey: string,
  path: string,
) => ({
  type: 'content-messages/create' as const,
  payload: {
    message,
    type,
    messageTranslationKey,
    path,
  },
});

export type ICreateContentMessageSuccess = ReturnType<typeof createContentMessageSuccess>;
export const createContentMessageSuccess = (message: IContentMessage) => ({
  type: 'content-messages/create-success' as const,
  payload: { message },
});

export type ICreateContentMessageFailed = ReturnType<typeof createContentMessageFailed>;
export const createContentMessageFailed = () => ({
  type: 'content-messages/create-failed' as const,
  payload: {},
});

export type IUpdateContentMessage = ReturnType<typeof updateContentMessage>;
export const updateContentMessage = (
  message: IContentMessage,
  type: string,
  messageTranslationKey: string,
  id: string,
) => ({
  type: 'content-messages/update' as const,
  payload: {
    message,
    type,
    messageTranslationKey,
    id,
  },
});

export type IUpdateContentMessageSuccess = ReturnType<typeof updateContentMessageSuccess>;
export const updateContentMessageSuccess = (message: IContentMessage) => ({
  type: 'content-messages/update-success' as const,
  payload: { message },
});

export type IUpdateContentMessageFailed = ReturnType<typeof updateContentMessageFailed>;
export const updateContentMessageFailed = () => ({
  type: 'content-messages/update-failed' as const,
  payload: {},
});

export type IDeleteContentMessages = ReturnType<typeof deleteContentMessages>;
export const deleteContentMessages = (messageIds: string[], messageTranslationKey: string) => ({
  type: 'content-messages/delete' as const,
  payload: {
    messageIds,
    messageTranslationKey,
  },
});

export type IDeleteContentMessagesSuccess = ReturnType<typeof deleteContentMessagesSuccess>;
export const deleteContentMessagesSuccess = (messageIds: string[]) => ({
  type: 'content-messages/delete-success' as const,
  payload: { messageIds },
});

export type IDeleteContentMessagesFailed = ReturnType<typeof deleteContentMessagesFailed>;
export const deleteContentMessagesFailed = () => ({
  type: 'content-messages/delete-failed' as const,
  payload: {},
});

export type IPublishContentMessage = ReturnType<typeof publishContentMessage>;
export const publishContentMessage = (id: string, translationKey: string) => ({
  type: 'content-messages/publish' as const,
  payload: { id, translationKey },
});

export type IPublishContentMessageFailed = ReturnType<typeof publishContentMessageFailed>;
export const publishContentMessageFailed = () => ({
  type: 'content-messages/publish-failed' as const,
  payload: {},
});

export type IPublishContentMessageSuccess = ReturnType<typeof publishContentMessageSuccess>;
export const publishContentMessageSuccess = (id: string) => ({
  type: 'content-messages/publish-success' as const,
  payload: { id },
});

export type IUnpublishContentMessage = ReturnType<typeof unpublishContentMessage>;
export const unpublishContentMessage = (id: string, translationKey: string) => ({
  type: 'content-messages/unpublish' as const,
  payload: { id, translationKey },
});

export type IUnpublishContentMessageFailed = ReturnType<typeof unpublishContentMessageFailed>;
export const unpublishContentMessageFailed = () => ({
  type: 'content-messages/unpublish-failed' as const,
  payload: {},
});

export type IUnpublishContentMessageSuccess = ReturnType<typeof unpublishContentMessageSuccess>;
export const unpublishContentMessageSuccess = (id: string) => ({
  type: 'content-messages/unpublish-success' as const,
  payload: { id },
});
