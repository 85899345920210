import { put, call, getContext, race, take, select, spawn } from 'redux-saga/effects';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';
import createPipClient, { PIPObject } from '@api/pipClient';
import { IFormSubmissionRaw, IFormSubmission } from './../types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import i18n from '../../../i18n';
import { createRegistrationCode, mapUISUserToAppUser } from '../utils';
import {
  inviteAppUserSuccess,
  IInviteAppUser,
  ISubmitFormAsAppUser,
  fetchAppUsers,
  submitFormAsAppUserSuccess,
  IUpdateAppUser,
  updateAppUserSuccess,
  updateAppUserFailed,
  IUpdateAppUserFlags,
} from '../actions';
import { selectAppUser } from '../reducers';

import {
  INVITE_APP_USER_WITH_PATHWAYS_FAILED,
  INVITE_APP_USER_WITH_PATHWAYS_SUCCESS,
} from '@pathways/redux/appUserPathways/types';
import { selectAppDetails } from '@organisation/redux/selectors';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import doEnsurePipObjectType from '@redux/doEnsurePipObjectType';
import { selectForm } from '@redux/forms/reducers';
import { Channel, channel } from 'redux-saga';
import { fetchHospital } from '@redux/hospitals/actions';
import { IHospital } from '@redux/hospitals/types';
import ContentRef from '@utils/contentRef';
import { fetchFlags } from '@redux/flags/actions';
import doCreatePipClient from '@redux/doCreatePipClient';

export function* fetchHospitalNameAsync(chan: Channel<string>) {
  yield put(fetchHospital());
  const {
    payload: { hospital },
  }: { payload: { hospital: IHospital } } = yield take('hospital/load-success');
  chan.put(hospital.name);
}

export function* doInviteAppUser({ payload: { appUser, journeys } }: IInviteAppUser): any {
  const history = yield getContext('history');
  const dashboardUserProfile: {
    hospitalId?: string;
  } = yield select(state => state.login.user.profile);

  try {
    // Go fetch the hospital name we'll need for the invite later, without blocking.
    const hospitalNameChannel = channel<string>();
    yield spawn(fetchHospitalNameAsync, hospitalNameChannel);

    const appUserProfile = {
      ...appUser,
      email: appUser.email.toLowerCase(),
      hospitalId: dashboardUserProfile.hospitalId,
    };

    const uisClient = yield call(doCreateUISAdminClient);

    // create App User in UIS
    const newUisUser = yield call(uisClient.createAppUser, appUserProfile);
    const newAppUser = mapUISUserToAppUser(newUisUser);
    const hospitalName = yield take(hospitalNameChannel);

    yield call(uisClient.createUserRegistrationCode, newAppUser.url, createRegistrationCode(), {
      hospitalName,
    });

    yield put(inviteAppUserSuccess(newAppUser.uuid, newAppUser, journeys));

    // this will need to be moved to keeps pathways stuff seperate
    const {
      failure,
      // success
    } = yield race({
      succes: take(INVITE_APP_USER_WITH_PATHWAYS_SUCCESS),
      failure: take(INVITE_APP_USER_WITH_PATHWAYS_FAILED),
    });

    const { appToken } = yield select(selectAppDetails);
    const pipClient = yield call(doCreatePipClient);
    const appDetails = yield call(pipClient.getApp, appToken);
    yield call(pipClient.createAppUser, appDetails['uuid'], newAppUser.ids.pip, 'app-user');

    if (failure) {
      throw new Error('Failed to add pathways to app user');
    }

    yield call(history.push, '/patients/individuals');
  } catch (err) {
    console.error(err);
  }
}

export function* doDeleteAppUsers() {
  yield call(message.success, i18n.t('patients:deleteConfirmation'));
}

export function* doSubmitAppUserForm({
  payload: { formId, formData, appUserUISId, type },
}: ISubmitFormAsAppUser): any {
  try {
    yield put(fetchAppUsers());
    const [, appUser] = yield select(selectAppUser(appUserUISId));

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const { appToken, organisationSlug } = yield select(selectAppDetails);
    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);
    const pipAppuser = appUserResponse.results[0];
    const [, form] = yield select(selectForm(formId));

    // submit new form data as app user
    const formDataObjectType = `${appToken}-form-${formId}-data`;
    yield call(doEnsurePipObjectType, pipClient, formDataObjectType, appToken);
    const formSubmissionData = yield call(
      pipClient.createObject,
      formDataObjectType,
      formData,
      pipAppuser.uuid,
    );

    const ref = ContentRef.fromString(form.content);
    const newVersion = {
      created: new Date().toISOString(),
      formId,
      organisationId: ref.organisationId,
      distributorId: organisationSlug,
      uuid: uuid(),
      versions: {
        formVersion: form.latestVersion.number,
        submissionVersion: formSubmissionData.version,
      },
    };

    const formSubmissionObjectType = `${appToken}-form-submissions`;

    yield call(doEnsurePipObjectType, pipClient, formSubmissionObjectType, appToken);

    // submit new form submission as app user
    const [latest]: [
      PIPObject<{
        submissions: IFormSubmissionRaw[];
      }>,
    ] = yield call(
      pipClient.getObjectsForType,
      formSubmissionObjectType,
      'latest',
      pipAppuser.uuid,
    );
    const latestSubmissions =
      latest && latest.json && latest.json.submissions ? latest.json.submissions : [];
    const newSubmissions = [...latestSubmissions, newVersion] as IFormSubmissionRaw[];

    yield call(
      pipClient.createObject,
      `${appToken}-form-submissions`,
      { submissions: newSubmissions },
      pipAppuser.uuid,
    );

    switch (type) {
      case FORM:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitFormSuccess'));
        break;
      case QUESTIONNAIRE:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitQuestionnaireSuccess'));
        break;
      case TASK:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitTaskSuccess'));
        break;
    }

    yield put(
      submitFormAsAppUserSuccess(
        appUserUISId,
        newSubmissions.map(sub => ({
          created: sub.created,
          formId: sub.formId,
          uuid: sub.uuid,
          versions: sub.versions,
        })) as IFormSubmission[],
      ),
    );
  } catch (err) {
    console.error(err);

    switch (type) {
      case FORM:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitFormFailed'));
        break;
      case QUESTIONNAIRE:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitQuestionnaireFailed'));
        break;
      case TASK:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitTaskFailed'));
        break;
    }
  }
}

export function* doUpdateAppUser({ payload: { appUserId, profile } }: IUpdateAppUser): any {
  try {
    const uisClient = yield call(doCreateUISAdminClient);

    const updatedUisUser = yield call(uisClient.updateAppUser, appUserId, { profile });

    const updatedAppUser = mapUISUserToAppUser(updatedUisUser);

    yield put(updateAppUserSuccess(appUserId, updatedAppUser));

    const history = yield getContext('history');
    yield call(history.goBack);
    yield call(message.success, i18n.t('patients:EditPatient.successMessage'));
  } catch (err) {
    console.error(err);
    yield call(message.warning, i18n.t('patients:EditPatient.failedMessage'));
    yield put(updateAppUserFailed());
  }
}

export function* doUpdateAppUserFlags({ payload: { appUserId, flags } }: IUpdateAppUserFlags): any {
  try {
    const uisClient = yield call(doCreateUISAdminClient);

    const updatedUisUser = yield call(uisClient.updateAppUser, appUserId, { flags });

    const updatedAppUser = mapUISUserToAppUser(updatedUisUser);

    yield put(updateAppUserSuccess(appUserId, updatedAppUser));
    // Refresh the app user counts per flag.
    yield put(fetchFlags());
    yield call(message.success, i18n.t('patients:EditPatient.successMessage'));
  } catch (err) {
    console.error(err);
    yield call(message.warning, i18n.t('patients:EditPatient.failedMessage'));
    yield put(updateAppUserFailed());
  }
}
