import React from 'react';
import { bool, func } from 'prop-types';
import { Button } from 'antd';
import './style.less';

function ReorderButton({ downDisabled, upDisabled, onClickDown, onClickUp }) {
  return (
    <div className="reorder-button-container">
      <Button disabled={upDisabled} icon="caret-up" onClick={onClickUp} />
      <Button disabled={downDisabled} icon="caret-down" onClick={onClickDown} />
    </div>
  );
}

ReorderButton.propTypes = {
  downDisabled: bool,
  upDisabled: bool,
  onClickDown: func.isRequired,
  onClickUp: func.isRequired,
};

ReorderButton.defaultProps = {
  downDisabled: false,
  upDisabled: false,
};

export default ReorderButton;
