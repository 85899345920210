import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETE,
  CHANGE_PASSWORD_ERROR,
  EDIT_MY_PROFILE,
  EDIT_MY_PROFILE_ERROR,
  EDIT_MY_PROFILE_SUCCESS,
} from './types';
import {
  IChangePassword,
  IChangePasswordComplete,
  IChangePasswordError,
  IEditMyProfile,
  IEditMyProfileError,
  IEditMyProfileSuccess,
} from './actions';
import { combineReducers } from 'redux';

const loading = (
  state: boolean = false,
  action:
    | IChangePassword
    | IChangePasswordComplete
    | IChangePasswordError
    | IEditMyProfile
    | IEditMyProfileSuccess
    | IEditMyProfileError,
): boolean => {
  switch (action.type) {
    case CHANGE_PASSWORD:
    case EDIT_MY_PROFILE:
      return true;
    case CHANGE_PASSWORD_COMPLETE:
    case CHANGE_PASSWORD_ERROR:
    case EDIT_MY_PROFILE_SUCCESS:
    case EDIT_MY_PROFILE_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
});

export interface IMyProfileState {
  myProfile: {
    loading: boolean;
  };
}

export const isLoading = (state: IMyProfileState): boolean => state.myProfile.loading;
