import { call, put, select, take } from 'redux-saga/effects';
import { message } from 'antd';
// import { selectPermissionsForUser } from '@authorisation/selectors';
// import { Permissions } from '@authorisation/constants';
import i18n from '../../../../i18n';
import {
  IFetchRulesWhatDetail,
  fetchingRules,
  loadRules,
  fetchRulesWhatDetail,
  fetchRulesWhatDetailSuccess,
  IFetchPathwayRulesWhatDetail,
  fetchPathwayEngagementCheckActionsWhatDetailSuccess,
  fetchSharedPathwayEngagementCheckActionsWhatDetailSuccess,
} from '../actions';
import { fetchSharedRulesWhatDetailSuccess } from '../../sharedPathways/actions';
import doCreatePathwaysClient from '../../../../redux/doCreatePathwaysClient';
import doCreateUbiquityClient, { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
// import { fetchPathways } from '../../pathways/actions';
// import { selectPathways } from '../../pathways/reducers';
// import { LOAD_PATHWAYS, IPathway } from '../../pathways/types';
import { mapRawRule } from '../utils';
// import { doDetermineOwnerForPathway } from '../../pathways/sagas/utils';
import { IRawRule, IRule } from '../types';
import { FETCH_ENGAGEMENT_CHECKS_SUCCESS, IPathway, IStage } from '@pathways/redux/pathways/types';
import {
  selectEngagementChecksByPathwayId,
  selectPathway,
} from '@pathways/redux/pathways/reducers';
import {
  selectSharedPathway,
  selectSharedPathwayEngagementChecksByPathwayId,
} from '@pathways/redux/sharedPathways/reducers';
import { whatTypesToContentType, getUnderlyingContentType } from '@constants';
import { selectAppToken } from '@redux/appTokens/reducers';
import { saveAppToken } from '@redux/appTokens/actions';
import ContentRef from '@utils/contentRef';

function* handleFetchAppToken(orgSlug: string): any {
  let appToken = null;
  const selectorAppToken = yield select(selectAppToken(orgSlug));
  if (selectorAppToken) {
    appToken = selectorAppToken;
  } else {
    try {
      const directoryClient: OrganisationDirectoryClient = yield call(
        doCreateOrganisationDirectoryClient,
      );
      const response = yield call(directoryClient.getOrganisationBySlug, orgSlug, true);
      appToken = response.results[0]?.ubiquity_app_token;
      if (appToken) {
        yield put(saveAppToken(orgSlug, appToken));
      }
    } catch (error) {
      console.error('Error getting Organisation details from Directory: ', error);
    }
  }
  return appToken;
}

export function* doFetchPathwayRulesWhatDetail({
  payload: { pathwayId, isSharedPathway },
}: IFetchPathwayRulesWhatDetail): any {
  try {
    let pathway: IPathway | null = null;
    if (isSharedPathway) {
      pathway = yield select(selectSharedPathway(pathwayId.toString()));
    } else {
      const [, selectorPathway] = yield select(selectPathway(pathwayId));
      pathway = selectorPathway;
    }
    if (!pathway?.stages.length) return;

    const stagesRules = pathway.stages.flatMap((stage: IStage) => stage.rules) || [];
    const indexEventsRules = pathway.indexEvents.flatMap(ie => ie.rules) || [];
    if (isSharedPathway) {
      yield take('sharedPathways/fetchSharedEngagementChecksSuccess');
    } else {
      yield take(FETCH_ENGAGEMENT_CHECKS_SUCCESS);
    }
    const { engagements } = yield select(selectEngagementChecksByPathwayId(pathwayId.toString()));
    const { engagements: sharedEngagementChecks } = yield select(
      selectSharedPathwayEngagementChecksByPathwayId(pathwayId.toString()),
    );
    let engagementChecks;
    if (isSharedPathway) {
      engagementChecks = sharedEngagementChecks;
    } else {
      engagementChecks = engagements;
    }
    const engagementChecksActionAsRules = engagementChecks.map((engagementCheck: any) => {
      const action = engagementCheck.action;
      return {
        engagementCheckId: engagementCheck.id,
        what: action.what,
        whatDetail: action.whatDetail,
      };
    });
    const rules = [...stagesRules, ...indexEventsRules, ...engagementChecksActionAsRules];

    const contentRefs = rules
      .map((rule: IRule) => {
        if (rule.whatDetail?.content) {
          return rule.whatDetail.content;
        }
        return undefined;
      })
      .filter((contentRef: string | undefined) => contentRef !== undefined);
    const uniqueContentRefs: string[] = [];
    for (const contentRef of contentRefs) {
      if (!uniqueContentRefs.includes(contentRef)) {
        uniqueContentRefs.push(contentRef);
      }
    }
    for (const contentRef of uniqueContentRefs) {
      const orgSlug = contentRef.split('/')[0];
      yield call(handleFetchAppToken, orgSlug);
    }

    yield put(
      fetchRulesWhatDetail(
        rules.map(rule => ({
          rule,
          pathwayId,
          sharedPathwayId: isSharedPathway ? pathwayId : undefined,
        })),
      ),
    );
  } catch (err) {
    console.error(err);
  }
}

export function* doFetchRulesWhatDetail({ payload: { options } }: IFetchRulesWhatDetail): any {
  const rulesContent: { [key: number]: { [key: string]: any } } = {};
  const pathwayEngagementActionsContent: {
    [key: number]: { [key: number]: { [key: string]: any } };
  } = {};
  const sharedPathwayEngagementActionsContent: {
    [key: number]: { [key: number]: { [key: string]: any } };
  } = {};
  const sharedRulesContent: { [key: number]: { [key: number]: { [key: string]: any } } } = {};

  for (const option of options) {
    const { rule, sharedPathwayId, pathwayId } = option;

    if (!rule.whatDetail?.content) {
      console.error('No content property for rule', rule.name);
      continue;
    }

    let appToken = null;
    const contentRef = ContentRef.fromString(rule.whatDetail.content);
    const selectorAppToken = yield select(selectAppToken(contentRef.organisationId));
    if (selectorAppToken) {
      appToken = selectorAppToken;
    } else {
      try {
        const directoryClient: OrganisationDirectoryClient = yield call(
          doCreateOrganisationDirectoryClient,
        );
        const response = yield call(
          directoryClient.getOrganisationBySlug,
          contentRef.organisationId,
          true,
        );
        appToken = response.results[0]?.ubiquity_app_token;
        if (appToken) {
          yield put(saveAppToken(contentRef.organisationId, appToken));
        }
      } catch (error) {
        console.error('Error getting Organisation details from Directory: ', error);
      }
    }
    if (!appToken) {
      console.error('No app token found for rule', rule.name);
      continue;
    }

    const contentType = `${getUnderlyingContentType(
      whatTypesToContentType[rule.what as keyof typeof whatTypesToContentType],
    )?.toLowerCase()}s`;
    const ubiquityContentTypes = ['forms', 'messages', 'weblinks', 'documents'];
    if (!contentType || !ubiquityContentTypes.includes(contentType)) continue;

    let content: any;
    if (contentType === 'documents') {
      try {
        const client = yield call(doCreateUbiquityClient);
        content = yield call(
          client.documentLatestVersionPublicDetails,
          appToken,
          contentRef.contentId,
        );
      } catch (error) {
        console.error('Could not fetch document details from Ubiquity v1 API', error);
      }
    } else {
      try {
        const client = yield call(doCreateUbiquityV2Client);
        const contentClient = client[contentType](appToken);
        content = yield call(contentClient.get, contentRef.contentId);
      } catch (error) {
        console.error('Could not fetch content details from Ubiquity v2 API', error);
      }
    }

    if (!content) {
      continue;
    }

    if (sharedPathwayId) {
      if (rule.engagementCheckId) {
        if (!sharedPathwayEngagementActionsContent[sharedPathwayId]) {
          sharedPathwayEngagementActionsContent[sharedPathwayId] = {};
        }
        sharedPathwayEngagementActionsContent[sharedPathwayId][rule.engagementCheckId] = {
          ...content,
          engagementCheckId: rule.engagementCheckId,
        };
      } else {
        if (!sharedRulesContent[sharedPathwayId]) {
          sharedRulesContent[sharedPathwayId] = {};
        }
        sharedRulesContent[sharedPathwayId][rule.id] = content;
      }
    } else if (pathwayId && rule.engagementCheckId) {
      if (!pathwayEngagementActionsContent[pathwayId]) {
        pathwayEngagementActionsContent[pathwayId] = {};
      }
      pathwayEngagementActionsContent[pathwayId][rule.engagementCheckId] = {
        ...content,
        engagementCheckId: rule.engagementCheckId,
      };
    } else {
      rulesContent[rule.id] = content;
    }
  }

  if (Object.keys(rulesContent).length > 0) {
    yield put(fetchRulesWhatDetailSuccess(rulesContent));
  }
  if (Object.keys(sharedRulesContent).length > 0) {
    for (const sharedPathwayId in sharedRulesContent) {
      yield put(
        fetchSharedRulesWhatDetailSuccess(
          Number(sharedPathwayId),
          sharedRulesContent[sharedPathwayId],
        ),
      );
    }
  }
  if (Object.keys(pathwayEngagementActionsContent).length > 0) {
    for (const pathwayId in pathwayEngagementActionsContent) {
      yield put(
        fetchPathwayEngagementCheckActionsWhatDetailSuccess(
          Number(pathwayId),
          pathwayEngagementActionsContent[pathwayId],
        ),
      );
    }
  }
  if (Object.keys(sharedPathwayEngagementActionsContent).length > 0) {
    for (const sharedPathwayId in sharedPathwayEngagementActionsContent) {
      yield put(
        fetchSharedPathwayEngagementCheckActionsWhatDetailSuccess(
          Number(sharedPathwayId),
          sharedPathwayEngagementActionsContent[sharedPathwayId],
        ),
      );
    }
  }
}

export function* doFetchRules(): any {
  yield put(fetchingRules());
  let rules: IRule[] = [];
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    // const permissions = yield select(selectPermissionsForUser);
    // const ownerId = yield call(doDetermineOwnerForPathway);
    const ownerId = undefined;

    let page = 1;

    while (true) {
      const { next, results }: { next: string; results: IRawRule[] } = yield call(
        pathwaysClient.listRules,
        page,
        ownerId,
      );
      rules = [...rules, ...results.map(rule => mapRawRule(rule))];

      if (!next) break;
      page += 1;
    }

    // if (permissions.includes(Permissions.ManagePatients)) {
    //   let [loading, pathways] = yield select(selectPathways);

    //   if (loading || pathways.length === 0) {
    //     yield put(fetchPathways());
    //     yield take(LOAD_PATHWAYS);
    //     [loading, pathways] = yield select(selectPathways);
    //   }

    //   const addedRuleIds: { [key: string]: boolean } = rules.reduce(
    //     (addedIds, rule) => ({ ...addedIds, [rule.id]: true }),
    //     {},
    //   );

    //   pathways.forEach((pathway: IPathway) => {
    //     pathway.indexEvents.forEach(indexEvent => {
    //       indexEvent.rules.forEach(rule => {
    //         if (!addedRuleIds[rule.id]) {
    //           addedRuleIds[rule.id] = true;
    //           rules.push(rule);
    //         }
    //       });
    //     });
    //     pathway.stages.forEach(stage => {
    //       stage.rules.forEach(rule => {
    //         if (!addedRuleIds[rule.id]) {
    //           addedRuleIds[rule.id] = true;
    //           rules.push(rule);
    //         }
    //       });
    //     });
    //   });
    // }

    yield put(loadRules(rules));
  } catch (err) {
    yield call(message.error, i18n.t('cards:ProcedureRuleList.networkError'));
    yield put(loadRules([]));
  }
}
