import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@components/IconButton';
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';

const MessageButton = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      key="send-message"
      tooltip={t('common:buttons.sendMessage')}
      icon={faCommentAltLines}
      size="large"
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default MessageButton;
