import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { fetchContentMessages } from '@redux/contentMessages/actions';
import { selectContentMessage } from '@redux/contentMessages/selectors';
import { Card } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import ContentIcon from '@components/ContentIcon';
import LanguageIcon from '@components/LanguageIcon';
import SourceIcon from '@components/SourceIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { messageTypeToText, NOTIFICATION_ONLY, OPEN_CONTENT, OPEN_LINK } from '@constants';
import './style.less';
import { getBasicContentDetails } from '@utils/contentDetails';

const MessageDetailCard = ({
  messageId,
  onChange,
  title,
  messageTranslationKey,
  payloadContent,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContentMessages());
  }, [dispatch]);

  const [loading, message] = useSelector(selectContentMessage(messageId));

  const payloadContentDetails = payloadContent ? getBasicContentDetails(payloadContent) : undefined;

  return (
    <Card.Half className="message-details" noPadding loading={loading} title={t(title)} {...props}>
      {message === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="vertical"
          wrapperCol={{ span: 16 }}
          className="detail-form"
        >
          <DetailRow
            label={t(`common:contentType.${message.latestVersion?.metadata.type}`)}
            value={<ContentIcon contentType={message.latestVersion?.metadata.type} showText />}
          />
          <DetailRow label={t('cards:MessageDetail.labels.title')} value={message.name} />
          <DetailRow
            label={t('cards:MessageDetail.labels.description')}
            value={message.latestVersion.body}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.version')}
            value={message.latestVersion?.name}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.status')}
            value={<PublishedStatusIcon published={message.published} showText />}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.created')}
            value={moment(message.created).format('LL')}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.source')}
            // defaulting to Hospital cause all content is owner by the organisation it appears in currently
            value={<SourceIcon source={message.source || 'hospital'} showText />}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.language')}
            value={
              <LanguageIcon language={message.latestVersion?.metadata?.language || 'en'} showText />
            }
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.actions')}
            value={t(
              messageTypeToText[message.latestVersion?.payload?.action || NOTIFICATION_ONLY],
            )}
          />
          {message?.latestVersion?.payload.action === OPEN_LINK &&
          message?.latestVersion?.payload?.data ? (
            <DetailRow
              label={t('cards:MessageDetail.labels.url')}
              value={message.latestVersion?.payload?.data}
            />
          ) : null}
          {message?.latestVersion?.payload?.action === OPEN_CONTENT && payloadContentDetails ? (
            <>
              <DetailRow
                label={t('cards:SentMessageDetail.contentType')}
                value={<ContentIcon contentType={payloadContentDetails.content_type} showText />}
              />
              <DetailRow
                label={t('cards:SentMessageDetail.contentTitle')}
                value={
                  <Link
                    to={payloadContentDetails.content_ref.toDashboardUrl(
                      payloadContentDetails.content_type,
                    )}
                  >
                    {payloadContentDetails.content_name}
                  </Link>
                }
              />
            </>
          ) : null}
        </Form>
      )}
    </Card.Half>
  );
};

export default MessageDetailCard;
