import { IReduxAction } from '@redux/types';
import { IAppUser } from '@redux/appUsers/types';
import { DashboardUser } from '@redux/dashboardUsers/types';
import {
  LOAD_SUPPORT_REQUESTS,
  LOAD_SUPPORT_REQUESTS_SUCCESS,
  LOAD_SUPPORT_REQUESTS_FAILED,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED,
  IRequest,
  RequestType,
} from './types';

export type ILoadSupportRequests = IReduxAction<typeof LOAD_SUPPORT_REQUESTS>;
export const loadSupportRequests = (): ILoadSupportRequests => ({
  type: LOAD_SUPPORT_REQUESTS,
  payload: {},
});

export type ILoadSupportRequestsSuccess = IReduxAction<
  typeof LOAD_SUPPORT_REQUESTS_SUCCESS,
  { requests: IRequest[] }
>;
export const loadSupportRequestsSuccess = (requests: IRequest[]): ILoadSupportRequestsSuccess => ({
  type: LOAD_SUPPORT_REQUESTS_SUCCESS,
  payload: { requests },
});

export type ILoadSupportRequestsFailed = IReduxAction<typeof LOAD_SUPPORT_REQUESTS_FAILED>;
export const loadSupportRequestsFailed = (): ILoadSupportRequestsFailed => ({
  type: LOAD_SUPPORT_REQUESTS_FAILED,
  payload: {},
});

export type ActionSupportRequest = IReduxAction<
  typeof UPDATE_SUPPORT_REQUEST_ASSIGNEE,
  { request: IRequest; action: 'transition' | 'completed' }
>;
export const actionSupportRequest = (
  request: IRequest,
  action: 'transition' | 'completed',
): ActionSupportRequest => ({
  type: UPDATE_SUPPORT_REQUEST_ASSIGNEE,
  payload: { request, action },
});

export type ActionSupportRequestSuccess = IReduxAction<
  typeof UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS,
  { request: IRequest }
>;
export const actionSupportRequestSuccess = (request: IRequest): ActionSupportRequestSuccess => ({
  type: UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS,
  payload: { request },
});

export type ActionSupportRequestFailed = IReduxAction<
  typeof UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED,
  {}
>;
export const actionSupportRequestFailed = (): ActionSupportRequestFailed => ({
  type: UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED,
  payload: {},
});

export type SubmitGDPRRequestForCurrentUser = ReturnType<typeof submitGDPRRequestForCurrentUser>;
export const submitGDPRRequestForCurrentUser = (type: RequestType) => ({
  type: 'requests/GDPR-for-current-user' as const,
  payload: {
    type,
  },
});

export type SubmitGDPRRequestForAppUser = ReturnType<typeof submitGDPRRequestForAppUser>;
export const submitGDPRRequestForAppUser = (type: RequestType, user: IAppUser) => ({
  type: 'requests/GDPR-for-app-user' as const,
  payload: {
    type,
    user,
  },
});

export type SubmitGDPRRequestForDashboardUser = ReturnType<
  typeof submitGDPRRequestForDashboardUser
>;
export const submitGDPRRequestForDashboardUser = (type: RequestType, user: DashboardUser) => ({
  type: 'requests/GDPR-for-dashboard-user' as const,
  payload: {
    type,
    user,
  },
});

export type SubmitGDPRRequestFailed = ReturnType<typeof submitGDPRRequestFailed>;
export const submitGDPRRequestFailed = () => ({
  type: 'requests/GDPR-failed' as const,
  payload: {},
});

export type SubmitGDPRRequestSuccess = ReturnType<typeof submitGDPRRequestSuccess>;
export const submitGDPRRequestSuccess = (request: IRequest) => ({
  type: 'requests/GDPR-success' as const,
  payload: { request },
});

export type FetchGDPRContacts = ReturnType<typeof fetchGDPRContacts>;
export const fetchGDPRContacts = () => ({
  type: 'requests/fetch-gdpr-contacts' as const,
  payload: {},
});

export type LoadGDPRContacts = ReturnType<typeof loadGDPRContacts>;
export const loadGDPRContacts = () => ({
  type: 'requests/load-gdpr-contacts' as const,
  payload: {},
});

export type LoadGDPRContactsFailed = ReturnType<typeof loadGDPRContactsFailed>;
export const loadGDPRContactsFailed = () => ({
  type: 'requests/load-gdpr-contacts-failed' as const,
  payload: {},
});

export type GDPRContactsLoaded = ReturnType<typeof gdprContactsLoaded>;
export const gdprContactsLoaded = (contacts: string[], language: string) => ({
  type: 'requests/gdpr-contacts-loaded' as const,
  payload: { contacts, language },
});

export type SaveGDPRContact = ReturnType<typeof saveGDPRContact>;
export const saveGDPRContact = (email: string, language: string) => ({
  type: 'requests/save-GDPR-contact' as const,
  payload: { email, language },
});

export type SaveGDPRContactSuccess = ReturnType<typeof saveGDPRContactSuccess>;
export const saveGDPRContactSuccess = (email: string, language: string) => ({
  type: 'requests/save-GDPR-contact-success' as const,
  payload: { email, language },
});

export type SaveGDPRContactFailed = ReturnType<typeof saveGDPRContactFailed>;
export const saveGDPRContactFailed = () => ({
  type: 'requests/save-GDPR-contact-failed' as const,
  payload: {},
});
