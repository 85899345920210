import React from 'react';
import { Icon, Modal, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contentTypes, contentTypesList } from '@utils';

const confirmDuplicateModal = (t, onOk) =>
  Modal.confirm({
    cancelText: t('pathways:ProcedureRules.duplicateRule.cancel'),
    okText: t('pathways:ProcedureRules.duplicateRule.ok'),
    title: t('pathways:ProcedureRules.duplicateRule.title'),
    icon: <Icon type="exclamation-circle" theme="filled" />,
    width: 550,
    className: 'procedure-pathways-modal',
    onOk: () => onOk(),
    content: (
      <>
        <p>{t('pathways:ProcedureRules.duplicateRule.helpText1')}</p>
        <Table
          bordered
          columns={[
            {
              title: <b>{t('pathways:ProcedureRules.duplicateRule.what')}</b>,
              key: 'what-column',
              dataIndex: 'what',
            },
            {
              title: <b>{t('pathways:ProcedureRules.duplicateRule.action')}</b>,
              key: 'action-column',
              dataIndex: 'action',
            },
          ]}
          dataSource={[
            {
              what: (
                <p>
                  <b>{t('pathways:ProcedureRules.duplicateRule.whatGroups')}</b>
                </p>
              ),
              action: t('pathways:ProcedureRules.duplicateRule.duplicateAction'),
              key: 'duplicate-action',
            },
            {
              what: (
                <div>
                  <p>
                    <b>{t('pathways:ProcedureRules.duplicateRule.whatContent')}</b>
                  </p>
                  {contentTypesList.map(type => (
                    <p key={type}>
                      <FontAwesomeIcon icon={contentTypes[type].icon} />{' '}
                      {t(contentTypes[type].label)}
                    </p>
                  ))}
                </div>
              ),
              action: t('pathways:ProcedureRules.duplicateRule.referenceAction'),
              key: 'reference-action',
            },
          ]}
          pagination={false}
        />
        <p>{t('pathways:ProcedureRules.duplicateRule.helpText2')}</p>
      </>
    ),
  });

export default confirmDuplicateModal;
