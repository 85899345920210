import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { trackMainMenu } from '../../analytics';
import { useEvaluatePermissions } from '@authorisation/hooks';

const SubMenuItem = ({
  name,
  path,
  icon,
  children,
  onClick = null,
  exact = false,
  permissions = [],
}) => {
  const [active, setActive] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setActive(exact ? path === pathname : pathname.startsWith(path));
  }, [pathname, path, exact]);

  const permissionsOk = useEvaluatePermissions(...permissions);
  if (!permissionsOk) {
    return null;
  }

  return (
    <>
      <div
        className={cn('sub-menu-item', { active })}
        onClick={() => {
          trackMainMenu(name);
          if (onClick) {
            onClick();
          } else if (pathname !== path) {
            history.push(path);
          }
        }}
      >
        <div className="icon">{icon}</div>
        <span className="content">{children}</span>
      </div>
    </>
  );
};

SubMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  permissions: PropTypes.array,
};

export default SubMenuItem;
