import { combineReducers } from 'redux';
import {
  IFetchSharedPathwaySnapshots,
  IFetchSharedPathwaySnapshotsSuccess,
  IFetchSharedPathwaySnapshotsFailed,
  IFetchSharedRulesWhatDetailSuccess,
  IFetchSharedEngagementChecks,
  IFetchSharedEngagementChecksSuccess,
  IFetchSharedEngagementChecksFailed,
} from './actions';
import { FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS } from '../rules/types';
import { IFetchSharedPathwayEngagementCheckActionsWhatDetailSuccess } from '../rules/actions';
import { IPathwaySnapshot } from '../pathways/types';
import { IState } from '@redux/reducer';

function loading(
  state = false,
  action:
    | IFetchSharedPathwaySnapshots
    | IFetchSharedPathwaySnapshotsSuccess
    | IFetchSharedPathwaySnapshotsFailed,
): boolean {
  switch (action.type) {
    case 'sharedPathways/fetchSharedPathwaySnapshots':
      return true;
    case 'sharedPathways/fetchSharedPathwaySnapshotsSuccess':
    case 'sharedPathways/fetchSharedPathwaySnapshotsFailed':
      return false;
    default:
      return state;
  }
}

function sharedPathways(
  state: { [key: string]: IPathwaySnapshot } = {},
  action:
    | IFetchSharedPathwaySnapshotsSuccess
    | IFetchSharedRulesWhatDetailSuccess
    | IFetchSharedEngagementChecks
    | IFetchSharedEngagementChecksSuccess
    | IFetchSharedEngagementChecksFailed
    | IFetchSharedPathwayEngagementCheckActionsWhatDetailSuccess,
): { [key: string]: IPathwaySnapshot } {
  switch (action.type) {
    case 'sharedPathways/fetchSharedPathwaySnapshotsSuccess':
      return {
        ...state,
        ...action.payload.snapshots.reduce((acc: any, snapshot: any) => {
          acc[snapshot.id] = {
            ...state[snapshot.id],
            ...snapshot,
          };
          return acc;
        }, {}),
      };
    case 'sharedPathways/fetchSharedRulesWhatDetailSuccess':
      return {
        ...state,
        [action.payload.sharedPathwayId]: {
          ...state[action.payload.sharedPathwayId],
          stages: state[action.payload.sharedPathwayId].stages.map((stage: any) => ({
            ...stage,
            rules: stage.rules.map((rule: any) => {
              if (action.payload.rules[rule.id]) {
                return {
                  ...rule,
                  whatDetail: {
                    ...rule.whatDetail,
                    ...action.payload.rules[rule.id],
                  },
                };
              }
              return rule;
            }),
          })),
          indexEvents: state[action.payload.sharedPathwayId].indexEvents.map((indexEvent: any) => ({
            ...indexEvent,
            rules: indexEvent.rules.map((rule: any) => {
              if (action.payload.rules[rule.id]) {
                return {
                  ...rule,
                  whatDetail: {
                    ...rule.whatDetail,
                    ...action.payload.rules[rule.id],
                  },
                };
              }
              return rule;
            }),
          })),
        },
      };
    case 'sharedPathways/fetchSharedEngagementChecks':
      return {
        ...state,
        [action.payload.sharedPathwayId]: {
          ...state[action.payload.sharedPathwayId],
          engagementChecks: {
            engagements: state[action.payload.sharedPathwayId]?.engagementChecks?.engagements || [],
            loading: true,
          },
        },
      };
    case 'sharedPathways/fetchSharedEngagementChecksSuccess':
      return {
        ...state,
        [action.payload.sharedPathwayId]: {
          ...state[action.payload.sharedPathwayId],
          engagementChecks: {
            engagements: action.payload.engagementChecks,
            loading: false,
          },
        },
      };
    case 'sharedPathways/fetchSharedEngagementChecksFailed':
      return {
        ...state,
        [action.payload.sharedPathwayId]: {
          ...state[action.payload.sharedPathwayId],
          engagementChecks: {
            engagements: state[action.payload.sharedPathwayId]?.engagementChecks?.engagements || [],
            loading: false,
          },
        },
      };
    case FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.payload.sharedPathwayId]: {
          ...state[action.payload.sharedPathwayId],
          engagementChecks: {
            engagements:
              state[action.payload.sharedPathwayId]?.engagementChecks?.engagements.map(
                (engagement: any) => {
                  if (engagement.id === action.payload.details[engagement.id]?.engagementCheckId) {
                    return {
                      ...engagement,
                      action: {
                        ...engagement.action,
                        whatDetail: {
                          ...engagement.action.whatDetail,
                          ...action.payload.details[engagement.id],
                        },
                      },
                    };
                  }
                  return engagement;
                },
              ) || [],
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  sharedPathways,
});

export const selectSharedPathways = (
  state: IState,
): { loading: boolean; sharedPathways: IPathwaySnapshot[] } => ({
  loading: state.sharedPathways.loading,
  sharedPathways: Object.values(state.sharedPathways.sharedPathways),
});

export const selectSharedPathway = (id: string) => (state: IState): IPathwaySnapshot | undefined =>
  state.sharedPathways.sharedPathways[id];

export const selectSharedPathwaysLoading = (state: IState): boolean => state.sharedPathways.loading;

export const selectSharedPathwayEngagementChecksByPathwayId = (id: string) => (
  state: IState,
): { engagements: any[]; loading: boolean } => {
  const pathway = state.sharedPathways.sharedPathways[id];
  return pathway?.engagementChecks || { engagements: [], loading: false };
};

export const selectSharedPathwayEngagementCheckById = (pathwayId: string, engagementId: string) => (
  state: IState,
): any => {
  const pathway = state.sharedPathways.sharedPathways[pathwayId];
  return pathway?.engagementChecks?.engagements.find(
    (engagement: any) => engagement.id === Number(engagementId),
  );
};

export const selectSharedPathwayEngagementChecksLoading = (pathwayId: string) => (
  state: IState,
): boolean => {
  const pathway = state.sharedPathways.sharedPathways[pathwayId];
  return pathway?.engagementChecks?.loading || false;
};
