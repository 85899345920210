import React from 'react';
import { Form, Radio, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { isLoading } from '@redux/myProfile/reducers';
import { editMyProfile } from '@redux/myProfile/actions';

const EditMyProfile = ({ form: { getFieldDecorator, validateFieldsAndScroll } }) => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentDashboardUser);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      dispatch(editMyProfile(values));
    });
  };

  return (
    <ColumnGroup>
      <Column>
        <Card title={t('myProfile:EditMyProfile.title')} className="edit-my-profile">
          <Form onSubmit={onSubmit}>
            <Form.Item label={t('registration:Details.mfa.label')}>
              <p>{t('registration:Details.mfa.help')}</p>
              {getFieldDecorator('mfaPreference', {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: user.preferredMfaSetting,
              })(
                <Radio.Group>
                  <Radio value="SMS_MFA">{t('registration:Details.mfa.sms')}</Radio>
                  <Radio value="EMAIL_OTP">{t('registration:Details.mfa.email')}</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Button
              type="primary"
              onClick={onSubmit}
              htmlType="submit"
              loading={loading}
              className="save-button"
            >
              {t('common:buttons.save')}
            </Button>
          </Form>
        </Card>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default Form.create()(EditMyProfile);
