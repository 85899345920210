import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import ContentItems from './ContentItems';
import CheckType from './CheckType';
import Action from './Action';
import Review from './Review';
import Timing from './Timing';
import Occurrences from './Occurrences';
import Details from './Details';

function EngagementCheckWizard({ initialData, loading, submitting, onCompleted, pathway }) {
  const { t } = useTranslation();

  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem
        stepKey="contentToCheckEngagement"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.contentItemsStep.title')}
      >
        <ContentItems pathway={pathway} />
      </WizardItem>
      <WizardItem
        stepKey="typeOfCheck"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.checkTypeStep.title')}
      >
        <CheckType />
      </WizardItem>
      <WizardItem
        stepKey="timing"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.timingStep.title')}
      >
        <Timing stages={pathway?.stages} />
      </WizardItem>
      <WizardItem
        stepKey="action"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.actionStep.title')}
      >
        <Action />
      </WizardItem>
      <WizardItem
        stepKey="occurrences"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.occurrencesStep.title')}
      >
        <Occurrences pathway={pathway} />
      </WizardItem>
      <WizardItem
        stepKey="details"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.detailsStep.title')}
      >
        <Details />
      </WizardItem>
      <WizardItem
        stepKey="review"
        title={t('pathways:ProcedurePathways.engagementCheckWizard.review')}
      >
        <Review pathway={pathway} />
      </WizardItem>
    </Wizard>
  );
}

EngagementCheckWizard.propTypes = {
  initialData: shape({}),
  loading: bool,
  submitting: bool.isRequired,
  onCompleted: func.isRequired,
};

EngagementCheckWizard.defaultProps = {
  initialData: undefined,
  loading: false,
};

export default EngagementCheckWizard;
