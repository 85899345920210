import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button, Icon, Steps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';

const { Step } = Steps;

function WizardMenu({ currentStep, submitting, loading, steps, onNextStep, onPreviousStep }) {
  return (
    <div className="wizard-menu">
      <Steps current={currentStep} direction="vertical">
        {steps.map(({ title, stepKey, ...props }, index) => (
          <Step {...props} title={title} key={index} />
        ))}
      </Steps>
      <div className="wizard-menu-buttons">
        <div className="left">
          <Button
            block
            disabled={currentStep === 0 || submitting || loading}
            icon="left"
            size="small"
            type="primary"
            onClick={onPreviousStep}
          />
        </div>
        <div className="right">
          {currentStep + 1 === steps.length ? (
            <Button
              block
              className="ant-btn-icon-only"
              disabled={submitting || loading}
              size="small"
              type="primary"
              onClick={onNextStep}
            >
              {submitting ? <Icon type="loading" /> : <FontAwesomeIcon icon={faSave} />}
            </Button>
          ) : (
            <Button
              block
              icon="right"
              size="small"
              type="primary"
              onClick={onNextStep}
              disabled={submitting || loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

WizardMenu.propTypes = {
  currentStep: number.isRequired,
  loading: bool.isRequired,
  submitting: bool.isRequired,
  steps: arrayOf(shape({ title: string })).isRequired,
  onNextStep: func.isRequired,
  onPreviousStep: func.isRequired,
};

export default WizardMenu;
