import React, { useEffect } from 'react';
import { string } from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { useContentRefFromUrl } from '@hooks/useContentRefs';
import { Column, ColumnGroup } from '@cards/Card';
import WeblinkDetailsCard from '@cards/Weblinks/Details';
import WeblinkContentCard from '@cards/Weblinks/Details/Content';
import { historyType, matchType } from '../../propTypes';
import EditButton from '@components/EditButton';
import WeblinkPathwaysCard from '@pathways/cards/WeblinkPathways';
import useIsContentOwnedByCurrentOrganisation from '@hooks/useIsContentOwnedByCurrentOrganisation';

function WeblinkDetails({ history, match }) {
  const [, weblink] = useContentRefFromUrl(match.params.weblinkId);

  const isOwnedContent = useIsContentOwnedByCurrentOrganisation(weblink);

  const { setTitle } = usePageActions({
    actions: [
      <EditButton
        key="edit"
        onClick={() => history.push(`${match.url}/edit`)}
        disabled={!isOwnedContent}
      />,
    ],
    showBack: true,
  });

  useEffect(() => {
    if (weblink) {
      setTitle(weblink.name);
    }
  }, [setTitle, weblink]);

  return (
    <>
      <ColumnGroup>
        <Column>
          <WeblinkDetailsCard weblinkId={weblink?.uuid} />
          <WeblinkPathwaysCard weblinkId={weblink?.uuid} />
        </Column>
        <Column>
          <WeblinkContentCard weblinkId={weblink?.uuid} />
        </Column>
      </ColumnGroup>
    </>
  );
}

WeblinkDetails.propTypes = {
  documentTranslationKey: string,
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default WeblinkDetails;
