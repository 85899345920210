import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserDetails from '@cards/AppUsers/Details';
import AppUserKemtaiCard from '@integrations/kemtai/cards/AppUserKemtaiCard';
import AppUserPathwayStages from '@pathways/cards/AppUsers/PathwayStages';
import ClinicalChecklistCard from '@cards/AppUsers/ClinicalChecklist';
import AppUserFlagsCard from '@cards/AppUsers/Flags';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import {
  fetchAppUsersPathways,
  fetchAppUserJourneys,
} from '@pathways/redux/appUserPathways/actions';
import { selectJourneysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import JourneyOverview from '@pathways/cards/Journeys/Overview';
import { matchType } from '../../propTypes';
import { setCurrentScreen, screens } from '../../analytics';

function AppUserOverviewTab({ match }) {
  const dispatch = useDispatch();
  const appUserId = match.params.id;

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_OVERVIEW);
  }, []);

  const [loading, appUser] = useSelector(selectAppUser(match.params.id));

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchAppUserJourneys(appUserId));
  }, [appUserId, dispatch]);

  const [, journeys] = useSelector(selectJourneysForAppUser(appUser));

  // only supporting 1 journey for now
  const journeyId = journeys?.[0]?.id;

  return (
    <ColumnGroup>
      <Column>
        <AppUserDetails appUserId={match.params.id} />
        <JourneyOverview appUserId={match.params.id} journeyId={journeyId} />
        <ColumnGroup key={`app-cards-group`}>
          <Column>
            <AppUserFlagsCard appUserId={match.params.id} />
          </Column>
          <Column>
            <AppUserKemtaiCard appUserId={match.params.id} />
          </Column>
        </ColumnGroup>
        {appUser &&
          appUser.pathways &&
          appUser.pathways.map(pathway => (
            <AppUserPathwayStages
              key={pathway.id}
              loading={loading}
              appUserId={match.params.id}
              pathwayId={pathway.originalPathway}
              appUserPathwayId={pathway.id}
            />
          ))}
        <ClinicalChecklistCard appUser={appUser} />
      </Column>
    </ColumnGroup>
  );
}

AppUserOverviewTab.propTypes = {
  match: matchType.isRequired,
};

export default AppUserOverviewTab;
