import { PEPHealthService } from '@liquid-state/pephealth-client';
import doCreatePEPHealthClient from './doCreatePEPHealthClient';

function* doCreatePEPHealthService(): any {
  const client = yield doCreatePEPHealthClient();
  const service = new PEPHealthService(client);

  return service;
}

export default doCreatePEPHealthService;
