export type IRefreshTermsOfUse = ReturnType<typeof refreshTermsOfUse>;
export const refreshTermsOfUse = (
  languages: string[],
  jwtToken: string,
  hospitalId?: string,
  userIsAppUser: boolean = false,
) => ({
  type: 'termsOfUse/refreshTermsOfUse' as const,
  payload: {
    languages,
    jwtToken,
    hospitalId,
    userIsAppUser,
  },
});

export type ITermsOfUseRefreshed = ReturnType<typeof termsOfUseRefreshed>;
export const termsOfUseRefreshed = (termsOfUse: any[], privacyPolicies: any[]) => ({
  type: 'termsOfUse/termsOfUseRefreshed' as const,
  payload: {
    termsOfUse,
    privacyPolicies,
  },
});

export type IRefreshAppUserTermsOfUse = ReturnType<typeof refreshAppUserTermsOfUse>;
export const refreshAppUserTermsOfUse = (appUserId: string) => ({
  type: 'termsOfUse/refreshAppUserTermsOfUse' as const,
  payload: {
    appUserId,
  },
});

export type IAppUserTermsOfUseRefreshed = ReturnType<typeof appUserTermsOfUseRefreshed>;
export const appUserTermsOfUseRefreshed = (
  appUserId: string,
  termsOfUse: any[],
  privacyPolicies: any[],
) => ({
  type: 'termsOfUse/appUserTermsOfUseRefreshed' as const,
  payload: {
    appUserId,
    termsOfUse,
    privacyPolicies,
  },
});

export type IAcceptTermsOfUse = ReturnType<typeof acceptTermsOfUse>;
export const acceptTermsOfUse = (id: string) => ({
  type: 'termsOfUse/acceptTermsOfUse' as const,
  payload: {
    id,
  },
});

export type IFetchUserAcceptances = ReturnType<typeof fetchUserAcceptances>;
export const fetchUserAcceptances = (
  id: string,
  hospitalId: string,
  acceptanceType = 'dashboard',
  pipId?: string,
) => ({
  type: 'termsOfUse/fetchUserAcceptances' as const,
  payload: {
    id,
    hospitalId,
    acceptanceType,
    pipId,
  },
});

export type IFetchAppUserAcceptances = ReturnType<typeof fetchAppUserAcceptances>;
export const fetchAppUserAcceptances = (id: string) => ({
  type: 'termsOfUse/fetchAppUserAcceptances' as const,
  payload: {
    id,
  },
});

export type IUserAcceptancesFetched = ReturnType<typeof userAcceptancesFetched>;
export const userAcceptancesFetched = (userId: string, acceptances: any) => ({
  type: 'termsOfUse/userAcceptancesFetched' as const,
  payload: {
    userId,
    acceptances,
  },
});
