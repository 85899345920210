import { combineReducers } from 'redux';
import {
  IHospital,
  // IHospitalInvites,
  LOAD_HOSPITALS,
  LOAD_HOSPITALS_SUCCESS,
  LOAD_HOSPITALS_FAILED,
  CREATE_HOSPITAL,
  CREATE_HOSPITAL_FAILED,
  CREATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILED,
  EDIT_HOSPITAL,
  EDIT_HOSPITAL_FAILED,
  EDIT_HOSPITAL_SUCCESS,
  IAdminHospital,
} from './types';
import {
  ILoadHospitals,
  ILoadHospitalsSuccess,
  ILoadHospitalsFailed,
  CreateHospital,
  CreateHospitalFailed,
  CreateHospitalSuccess,
  IUpdateHospital,
  IUpdateHospitalSuccess,
  IUpdateHospitalFailed,
  EditHospital,
  EditHospitalFailed,
  EditHospitalSuccess,
  ILoadHospital,
  ILoadHospitalFailed,
  ILoadHospitalSuccess,
} from './actions';
import { IState } from '@redux/reducer';

function creating(
  state = false,
  action: CreateHospital | CreateHospitalFailed | CreateHospitalSuccess,
) {
  switch (action.type) {
    case CREATE_HOSPITAL:
      return true;
    case CREATE_HOSPITAL_FAILED:
    case CREATE_HOSPITAL_SUCCESS:
      return false;
    default:
      return state;
  }
}

function editing(state = false, action: EditHospital | EditHospitalFailed | EditHospitalSuccess) {
  switch (action.type) {
    case EDIT_HOSPITAL:
      return true;
    case EDIT_HOSPITAL_FAILED:
    case EDIT_HOSPITAL_SUCCESS:
      return false;
    default:
      return state;
  }
}

const loading = (
  state: boolean = false,
  action:
    | ILoadHospitals
    | ILoadHospitalsSuccess
    | ILoadHospitalsFailed
    | ILoadHospital
    | ILoadHospitalFailed
    | ILoadHospitalSuccess,
): boolean => {
  switch (action.type) {
    case LOAD_HOSPITALS:
    case 'hospital/load':
      return true;
    case LOAD_HOSPITALS_SUCCESS:
    case LOAD_HOSPITALS_FAILED:
    case 'hospital/load-failed':
    case 'hospital/load-success':
      return false;
    default:
      return state;
  }
};

const updating = (
  state: boolean = false,
  action: IUpdateHospital | IUpdateHospitalSuccess | IUpdateHospitalFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_HOSPITAL:
      return true;
    case UPDATE_HOSPITAL_SUCCESS:
    case UPDATE_HOSPITAL_FAILED:
      return false;
    default:
      return state;
  }
};

const hospital = (
  state: IHospital | null = null,
  action: IUpdateHospitalSuccess | EditHospitalSuccess | ILoadHospitalSuccess,
): IHospital | null => {
  switch (action.type) {
    case UPDATE_HOSPITAL_SUCCESS:
    case EDIT_HOSPITAL_SUCCESS:
    case 'hospital/load-success':
      return action.payload.hospital;
    default:
      return state;
  }
};

function list(state: IAdminHospital[] = [], action: ILoadHospitalsSuccess) {
  switch (action.type) {
    case LOAD_HOSPITALS_SUCCESS:
      return action.payload.hospitals;
    default:
      return state;
  }
}

// This functionality was mocked so removed it for now
// const invites = (state: IHospitalInvites = {}, action: ILoadHospitalsSuccess): IHospitalInvites => {
//   switch (action.type) {
//     case LOAD_HOSPITALS_SUCCESS:
//       return action.payload.invites;
//     default:
//       return state;
//   }
// };

export default combineReducers({
  creating,
  editing,
  hospital,
  list,
  loading,
  updating,
});

export const selectHospital = () => (state: IState): [boolean, IHospital | null] => [
  state.hospitals.loading,
  state.hospitals.hospital,
];

export const selectHospitals = (state: IState): [boolean, IAdminHospital[]] => [
  state.hospitals.loading,
  state.hospitals.list,
];

export const selectHospitalFromList = (hospitalId: number) => (
  state: IState,
): [boolean, IAdminHospital | undefined] => [
  state.hospitals.loading,
  state.hospitals.list.find(hospital => hospital.id === Number(hospitalId)),
];

// TODO: use this once hospitals list working again
// export const selectHospitalsInAlphabeticalOrder = (
//   state: IState,
// ): [boolean, IHospital[]] => [
//   state.hospitals.loading,
//   state.hospitals.hospitals.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
// ];
export const selectHospitalsInAlphabeticalOrder = (state: IState): [boolean, IAdminHospital[]] => [
  state.hospitals.loading,
  state.hospitals.list,
];

// TODO: use this once hospitals list working again
// export const selectHospitalsById = (
//   state: IState,
// ): [boolean, { [key: string]: IHospital }] => [
//   state.hospitals.loading,
//   state.hospitals.hospitals.reduce(
//     (hospitalsById, hospital) => ({ ...hospitalsById, [hospital.uuid]: hospital }),
//     {},
//   ),
// ];
export const selectHospitalsById = (): [boolean, { [key: string]: IHospital }] => [false, {}];

// This functionality was mocked so removed it for now
// export const selectHospitalInvites = (state: IState): [boolean, IHospitalInvites] => [
//   state.hospitals.loading,
//   state.hospitals.invites,
// ];

export const selectHospitalCreating = (state: IState): boolean => state.hospitals.creating;
export const selectHospitalUpdating = (state: IState): boolean => state.hospitals.updating;
export const selectHospitalEditing = (state: IState): boolean => state.hospitals.editing;
