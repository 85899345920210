import { DashboardUser } from '@redux/dashboardUsers/types';

export const FETCH_HOSPITALS = 'FETCH_HOSPITALS';
export const LOAD_HOSPITALS = 'LOAD_HOSPITALS';
export const LOAD_HOSPITALS_SUCCESS = 'LOAD_HOSPITALS_SUCCESS';
export const LOAD_HOSPITALS_FAILED = 'LOAD_HOSPITALS_FAILED';
export const CREATE_HOSPITAL = 'CREATE_HOSPITAL';
export const CREATE_HOSPITAL_SUCCESS = 'CREATE_HOSPITAL_SUCCESS';
export const CREATE_HOSPITAL_FAILED = 'CREATE_HOSPITAL_FAILED';
export const EDIT_HOSPITAL = 'EDIT_HOSPITAL';
export const EDIT_HOSPITAL_SUCCESS = 'EDIT_HOSPITAL_SUCCESS';
export const EDIT_HOSPITAL_FAILED = 'EDIT_HOSPITAL_FAILED';
export const UPDATE_HOSPITAL = 'UPDATE_HOSPITAL';
export const UPDATE_HOSPITAL_SUCCESS = 'UPDATE_HOSPITAL_SUCCESS';
export const UPDATE_HOSPITAL_FAILED = 'UPDATE_HOSPITAL_FAILED';

export type IHospital = {
  uuid: string;
  slug: string;
  country?: string;
  language: string;
  name: string;
  created: string;
  contactDetails: {
    email?: string;
    openHours?: string;
    phone?: string;
    address?: string;
    addressSecondLine?: string;
    type?: 'public' | 'private';
  };
  socialMedia: {
    type: 'facebook' | 'twitter' | 'website';
    link: string;
  }[];
  content?: { [key: string]: any }[];
  defaultConnectionCode: string;
  admin: {
    id: string;
    name: string;
    email: string;
  };
};

export type IAdminHospital = {
  appToken: string;
  hospitalId: string;
  id: number;
  name: string;
  organisationSlug: string;
  slug: string;
  ubiquity: {
    appId: number;
    organisationId: number;
    organisationSlug: string;
  };
  language: string;
};

export type ISolutionConfiguration = {
  body: {
    app_token: string;
    hospital_id: string;
    organisation_slug: string;
    ubiquity_app_id: number;
    ubiquity_organisation_id: number;
    ubiquity_organisation_slug: string;
    language: string;
  };
  organisation: {
    pk: number;
    slug: string;
    name: string;
  };
  solution_name: string;
};

export type IHospitalInvites = {
  [key: string]: number;
};

export type ICreateHospitalData = {
  email: string;
  firstName: string;
  hospitalName: string;
  language: string;
  lastName: string;
  pathways: number[];
  hospitalPathways?: number[];
  content?: { [key: string]: any }[];
  users?: DashboardUser[];
};

export type EditHospitalData = {
  email?: string;
  firstName?: string;
  hospitalName?: string;
  language?: string;
  lastName?: string;
  pathways?: number[];
  hospitalPathways?: number[];
  content?: { [key: string]: any }[];
  users?: DashboardUser[];
};
