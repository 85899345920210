import { CreateDashboardUserData, DashboardUser, EditDashboardUserData } from './types';

export type FetchDashboardUsers = ReturnType<typeof fetchDashboardUsers>;
export const fetchDashboardUsers = () => ({
  type: 'dashboardUsers/fetch' as const,
  payload: {},
});

export type LoadDashboardUsers = ReturnType<typeof loadDashboardUsers>;
export const loadDashboardUsers = () => ({
  type: 'dashboardUsers/load' as const,
  payload: {},
});

export type LoadDashboardUsersFailed = ReturnType<typeof loadDashboardUsersFailed>;
export const loadDashboardUsersFailed = () => ({
  type: 'dashboardUsers/loadFailed' as const,
  payload: {},
});

export type DashboardUsersLoaded = ReturnType<typeof dashboardUsersLoaded>;
export const dashboardUsersLoaded = (dashboardUsers: DashboardUser[]) => ({
  type: 'dashboardUsers/loaded' as const,
  payload: {
    dashboardUsers,
  },
});

export type ToggleDashboardUserActivation = ReturnType<typeof toggleDashboardUserActivation>;
export const toggleDashboardUserActivation = (
  id: string,
  currentActivatedStatus: boolean,
  name: string,
) => ({
  type: 'dashboardUsers/toggleActivation' as const,
  payload: { id, currentActivatedStatus, name },
});

export type ToggleDashboardUserActivationSuccess = ReturnType<
  typeof toggleDashboardUserActivationSuccess
>;
export const toggleDashboardUserActivationSuccess = (id: string, activated: boolean) => ({
  type: 'dashboardUsers/toggleActivationSuccess' as const,
  payload: { id, activated },
});

export type ToggleDashboardUserActivationFailed = ReturnType<
  typeof toggleDashboardUserActivationFailed
>;
export const toggleDashboardUserActivationFailed = () => ({
  type: 'dashboardUsers/toggleActivationFailed' as const,
  payload: {},
});

export type EditDashboardUser = ReturnType<typeof editDashboardUser>;
export const editDashboardUser = (id: string, editedUser: EditDashboardUserData) => ({
  type: 'dashboardUsers/edit' as const,
  payload: { id, editedUser },
});

export type EditDashboardUserSuccess = ReturnType<typeof editDashboardUserSuccess>;
export const editDashboardUserSuccess = (id: string, editedUser: EditDashboardUserData) => ({
  type: 'dashboardUsers/editSuccess' as const,
  payload: { id, editedUser },
});

export type EditDashboardUserFailed = ReturnType<typeof editDashboardUserFailed>;
export const editDashboardUserFailed = () => ({
  type: 'dashboardUsers/editFailed' as const,
  payload: {},
});

export type CreateDashboardUser = ReturnType<typeof createDashboardUser>;
export const createDashboardUser = (dashboardUser: CreateDashboardUserData) => ({
  type: 'dashboardUsers/create' as const,
  payload: { dashboardUser },
});

export type CreateDashboardUserSuccess = ReturnType<typeof createDashboardUserSuccess>;
export const createDashboardUserSuccess = (dashboardUser: DashboardUser) => ({
  type: 'dashboardUsers/createSuccess' as const,
  payload: { dashboardUser },
});

export type CreateDashboardUserFailed = ReturnType<typeof createDashboardUserFailed>;
export const createDashboardUserFailed = () => ({
  type: 'dashboardUsers/createFailed' as const,
  payload: {},
});

export type ResendDashboardUserInvite = ReturnType<typeof resendDashboardUserInvite>;
export const resendDashboardUserInvite = (id: string) => ({
  type: 'dashboardUsers/resendInvite' as const,
  payload: { id },
});

export type ResendDashboardUserInviteSuccess = ReturnType<typeof resendDashboardUserInviteSuccess>;
export const resendDashboardUserInviteSuccess = (userId: string, inviteId: string) => ({
  type: 'dashboardUsers/resendInviteSuccess' as const,
  payload: {
    userId,
    inviteId,
  },
});

export type ResendDashboardUserInviteFailed = ReturnType<typeof resendDashboardUserInviteFailed>;
export const resendDashboardUserInviteFailed = () => ({
  type: 'dashboardUsers/resendInviteFailed' as const,
  payload: {},
});
