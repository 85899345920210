import React, { useState } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Card } from '@cards/Card';
import ControlledDocumentModal from '@components/ControlledDocumentModal';
import './style.less';

function ViewControlledDocuments({ controlledDocuments, title, loading }) {
  const { t } = useTranslation();
  const [controlledDocumentModalData, setControlledDocumentModalData] = useState(false);

  return (
    <>
      <Card className="view-controlled-documents-card" title={title} loading={loading}>
        {controlledDocuments.map(controlledDocument => (
          <Button
            block
            key={controlledDocument.latest_version.id}
            size="large"
            onClick={() =>
              setControlledDocumentModalData({
                title: controlledDocument.latest_version.version_content.display_name,
                url: controlledDocument.latest_version.version_content.data.url,
              })
            }
          >
            {t('cards:ViewControlledDocuments.read', {
              documentName: controlledDocument.latest_version.version_content.display_name,
            })}
          </Button>
        ))}
      </Card>
      {controlledDocumentModalData ? (
        <ControlledDocumentModal
          title={controlledDocumentModalData.title}
          url={controlledDocumentModalData.url}
          visible={true}
          onClose={() => setControlledDocumentModalData(false)}
        />
      ) : null}
    </>
  );
}

ViewControlledDocuments.propTypes = {
  controlledDocuments: arrayOf(shape({})),
  title: string.isRequired,
  loading: bool,
};

ViewControlledDocuments.defaultProps = {
  controlledDocuments: [],
  loading: false,
};

export default ViewControlledDocuments;
