import { Modal } from 'antd';

const confirmModal = (title, onOk) =>
  Modal.confirm({
    title,
    wrapClassName: 'procedure-pathways-modal',
    onOk: () => onOk(),
    onCancel: () => {},
  });

export default confirmModal;
