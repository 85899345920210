import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import OrganisationDirectoryClient from '@liquid-state/directory-client';
import doCreateOrganisationDirectoryClient from '@redux/doCreateOrganisationDirectoryClient';
import { selectAppToken } from '@redux/appTokens/reducers';
import ContentRef from '@utils/contentRef';
import { saveAppToken } from '@redux/appTokens/actions';
import doCreateUbiquityClient, { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import { loadContentSuccess } from './actions';
import { selectContent } from './reducers';
import { mapRawDocumentToDocument } from '@redux/documents/sagas';
import { mapRawContentMessageToContentMessage } from '@redux/contentMessages/utils';
import { parseRawForm } from '@redux/forms/utils';

export default function*() {
  yield takeEvery<any>('core/load-content', doLoadContent);
}

function* doLoadContent({ payload: { refs } }: { payload: { refs: string[] } }): any {
  try {
    // 1. Get existing content that is already loaded
    const existingContent = yield select(selectContent(refs));

    // 2. create the list of refs to fetch
    const toFetch = refs
      .filter(r => existingContent[r] === undefined)
      .map(r => ContentRef.fromString(r))
      .filter(c => c.organisationId !== 'undefined'); // specifically ensure we don't crash if the ContentRef has no Org ID

    // 3. prepopulate all the app tokens we need
    const orgs = new Set<string>();
    for (const ref of toFetch) {
      orgs.add(ref.organisationId);
    }

    const directoryClient: OrganisationDirectoryClient = yield call(
      doCreateOrganisationDirectoryClient,
    );
    const appTokens: { [key: string]: string } = {};
    for (const orgId of Array.from(orgs)) {
      const selectorAppToken = yield select(selectAppToken(orgId));
      if (selectorAppToken) {
        appTokens[orgId] = selectorAppToken;
      } else {
        const response = yield call(directoryClient.getOrganisationBySlug, orgId, true);
        const appToken = response.results[0]?.ubiquity_app_token;
        yield put(saveAppToken(orgId, appToken));
        appTokens[orgId] = appToken;
      }
    }

    //4. Fetch all content refs.
    const fetchedContent: { ref: ContentRef; content: any }[] = yield all(
      toFetch.map(ref => call(doFetchContentItem, ref, appTokens[ref.organisationId])),
    );

    //5. Convert fetched content into a map between references and content data
    const fetchedContentByRef = Object.fromEntries(
      fetchedContent.map(result => [result.ref, result.content]),
    );

    const allContentIncludingExisting = {
      ...fetchedContentByRef,
      ...existingContent,
    };

    // 6. Store resulting content.
    // if (fetchedContent.length) {
    //   debugger;
    // }
    yield put(loadContentSuccess(allContentIncludingExisting));
  } catch (e) {
    console.error(e);
    // Just catch the error for now and let the dashboard continue functioning instead of cancelling the saga
  }
}

function* doFetchContentItem(ref: ContentRef, appToken: string): any {
  let content;
  if (ref.contentType === 'document') {
    try {
      const client = yield call(doCreateUbiquityClient);
      const rawContent = yield call(
        client.documentLatestVersionPublicDetails,
        appToken,
        ref.contentId,
      );
      content = mapRawDocumentToDocument(rawContent, ref.organisationId);
    } catch (error) {
      console.error('Could not fetch document details from Ubiquity v1 API', error);
    }
  } else {
    try {
      const contentTypeToV2Type = { form: 'forms', message: 'messages', weblink: 'weblinks' };
      const v2Type = contentTypeToV2Type[ref.contentType as keyof typeof contentTypeToV2Type];
      const client = yield call(doCreateUbiquityV2Client);
      const contentClient = client[v2Type](appToken);
      const rawContent = yield call(contentClient.get, ref.contentId);
      if (ref.contentType === 'form') {
        content = parseRawForm(rawContent, ref.organisationId);
      } else if (ref.contentType === 'message') {
        content = mapRawContentMessageToContentMessage(rawContent, ref.organisationId);
      } else {
        // Weblink is unmapped except to add the content ref to it.
        content = rawContent;
        content.content = ref.toString();
      }
    } catch (error) {
      console.error('Could not fetch content details from Ubiquity v2 API', error);
    }
  }

  return { content, ref };
}
