import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { INDEX_EVENT } from '@constants';
import useWizard from '@components/Wizard/useWizard';
import { Card } from '@cards/Card';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import AddRulesToIndexEvent from './AddRulesToIndexEvent';

const filters = [INDEX_EVENT];

function IndexEventRules() {
  const { i18n, t } = useTranslation();
  const [indexEventRules, setindexEventRules] = useState([]);
  const { currentStepData, form, formData } = useWizard();
  const updateIndexEventData = useCallback(
    data => {
      setindexEventRules(data);
      form.setFieldsValue({ indexEvents: data });
    },
    [form],
  );

  useEffect(() => {
    if (currentStepData && currentStepData.indexEvents) {
      updateIndexEventData(
        formData.indexEvents.indexEventTypes.map(({ id, ...indexEvent }) => {
          const currentStepIe = currentStepData.indexEvents.find(
            ({ eventTypeSlug }) =>
              extractSlugFromTypeSlug(eventTypeSlug) === extractSlugFromTypeSlug(indexEvent.slug),
          );
          return {
            ...indexEvent,
            eventTypeSlug: indexEvent.slug,
            rules: [],
            ...(currentStepIe || {}),
          };
        }),
      );
    } else {
      updateIndexEventData(
        formData.indexEvents.indexEventTypes.map(({ id, ...indexEvent }) => ({
          ...indexEvent,
          eventTypeSlug: indexEvent.slug,
          rules: [],
        })),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addRules = useCallback(
    stageIndex => rules => {
      updateIndexEventData([
        ...indexEventRules.slice(0, stageIndex),
        { ...indexEventRules[stageIndex], rules: [...indexEventRules[stageIndex].rules, ...rules] },
        ...indexEventRules.slice(stageIndex + 1),
      ]);
    },
    [indexEventRules, updateIndexEventData],
  );

  const removeRule = useCallback(
    stageIndex => ruleId =>
      updateIndexEventData([
        ...indexEventRules.slice(0, stageIndex),
        {
          ...indexEventRules[stageIndex],
          rules: indexEventRules[stageIndex].rules.filter(({ id }) => id !== ruleId),
        },
        ...indexEventRules.slice(stageIndex + 1),
      ]),
    [indexEventRules, updateIndexEventData],
  );

  return (
    <>
      {form.getFieldDecorator('indexEvents')(<span />)}
      {indexEventRules.map(({ name, translatedNames, rules, slug }, index) => (
        <AddRulesToIndexEvent
          defaultFilteredValue={{ language: [formData.details.language] }}
          filters={filters}
          key={`add-rules-${slug}`}
          selectedRules={rules}
          title={translatedNames[i18n.language] || name}
          addRules={addRules(index)}
          removeRule={removeRule(index)}
        />
      ))}
      {indexEventRules.length === 0 ? (
        <Card.Half>
          <Alert
            description={t('pathways:ProcedurePathways.wizard.indexEventRules.noRules.description')}
            message={t('pathways:ProcedurePathways.wizard.indexEventRules.noRules.title')}
            showIcon
            type="info"
          />
        </Card.Half>
      ) : null}
    </>
  );
}

export default IndexEventRules;
