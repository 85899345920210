import { IDMInvitationClient, IIDMInviteClient } from '@liquid-state/idm-client';
import { idmApiRoot } from 'settings';

function createIDMClient(): IIDMInviteClient {
  const client = new IDMInvitationClient({ baseUrl: idmApiRoot });

  return client;
}

export default createIDMClient;
