import { select } from 'redux-saga/effects';
import { selectAppDetails } from '@organisation/redux/selectors';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { IAppDetails } from '@organisation/redux/types';
import { IAdminHospital, ISolutionConfiguration } from './types';

export function* doGetHospitalObjectType(): any {
  const { appToken }: IAppDetails = yield select(selectAppDetails);
  const {
    profile: { hospitalId },
  } = yield select(selectCurrentDashboardUser);

  return `${appToken}-hospital-${hospitalId}`;
}

export const parseSolutionConfigurationDetails = (
  solutionConfiguration: ISolutionConfiguration,
): IAdminHospital => ({
  appToken: solutionConfiguration.body.app_token,
  hospitalId: solutionConfiguration.body.organisation_slug,
  id: solutionConfiguration.organisation.pk,
  name: solutionConfiguration.organisation.name,
  organisationSlug: solutionConfiguration.body.organisation_slug,
  slug: solutionConfiguration.organisation.slug,
  ubiquity: {
    appId: solutionConfiguration.body.ubiquity_app_id,
    organisationId: solutionConfiguration.body.ubiquity_organisation_id,
    organisationSlug: solutionConfiguration.body.ubiquity_organisation_slug,
  },
  language: solutionConfiguration.body.language || 'en',
});
