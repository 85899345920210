import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { Form } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DetailRow from '@components/DetailRow';
import LanguageIcon from '@components/LanguageIcon';
import ContentIcon from '@components/ContentIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { messageTypeToText, NOTIFICATION_ONLY, OPEN_CONTENT, OPEN_LINK } from '@constants';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { Card } from '../../Card';
import { sentMessageType } from '../../../propTypes';

import './styles.less';

function MessageDetails({ loading, message }) {
  const { t } = useTranslation();
  const sentAt = useMemo(() => {
    if (!message.lastSentDatetime) return '';
    const sentMoment = moment(message.lastSentDatetime);
    return `${sentMoment.format('L')} ${sentMoment.format('LT')}`;
  }, [message]);

  console.log('message', message);
  return (
    <Card.Half
      title={t('cards:SentMessageDetail.title')}
      loading={loading}
      className="message-details-card"
    >
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <DetailRow
          label={t('cards:SentMessageDetail.type')}
          value={<ContentIcon contentType={message?.metadata?.type?.toUpperCase()} showText />}
        />
        <DetailRow label={t('cards:SentMessageDetail.messageTitle')} value={message.name} />
        <DetailRow
          label={t('cards:SentMessageDetail.body')}
          value={message.content?.split('\n').map(line => (
            <p>{line}</p>
          ))}
        />
        {message.published !== undefined ? (
          <DetailRow
            label={t('cards:SentMessageDetail.status')}
            value={
              message.published ? (
                <>
                  <FontAwesomeIcon size="lg" className="published-check" icon={faCheckCircle} />{' '}
                  {t('cards:SentMessageDetail.published')}
                </>
              ) : (
                <>
                  <FontAwesomeIcon size="lg" className="published-cross" icon={faTimesCircle} />{' '}
                  {t('cards:SentMessageDetail.unpublished')}
                </>
              )
            }
          />
        ) : null}
        {message.lastSentDatetime ? (
          <DetailRow label={t('cards:SentMessageDetail.sentAt')} value={sentAt} />
        ) : null}
        <DetailRow
          label={t('cards:SentMessageDetail.language')}
          value={<LanguageIcon language={message?.metadata?.language} showText />}
        />
        <DetailRow
          label={t('cards:SentMessageDetail.actions')}
          value={t(messageTypeToText[message.payloadOptions?.action || NOTIFICATION_ONLY])}
        />
        {message?.payloadOptions?.action === OPEN_LINK && message?.payloadOptions?.data ? (
          <DetailRow
            label={t('cards:SentMessageDetail.url')}
            value={message.payloadOptions?.data}
          />
        ) : null}
        {message?.payloadOptions?.action === OPEN_CONTENT ? (
          <>
            <DetailRow
              label={t('cards:SentMessageDetail.contentType')}
              value={
                <ContentIcon
                  contentType={
                    message.payloadOptions?.data?.metadata?.type ||
                    message.payloadOptions?.data?.type
                  }
                  showText
                />
              }
            />
            <DetailRow
              label={t('cards:SentMessageDetail.contentTitle')}
              value={message?.payloadOptions?.data.title || message?.payloadOptions?.data.name}
            />
          </>
        ) : null}
      </Form>
    </Card.Half>
  );
}

MessageDetails.propTypes = {
  loading: bool,
  message: sentMessageType.isRequired,
};

MessageDetails.defaultProps = {
  loading: false,
};

export default MessageDetails;
