import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderButton from '@components/HeaderButton';
import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import FlagsListCard from '@cards/Flags/List';
import { historyType, matchType } from '../../../propTypes';

function ListDisabled({ history, match }) {
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('flags:List.titleDisabled'),
    actions: [
      <HeaderButton
        key="flags-view-enabled"
        tooltip={t('flags:List.viewEnabled')}
        onClick={() => history.push('/administration/flags/')}
      >
        {t('flags:List.viewEnabled')}
      </HeaderButton>,
      <HeaderButton
        key="flags-new"
        tooltip={t('flags:List.new')}
        onClick={() => history.push(`/administration/flags/new`)}
      >
        {t('flags:List.new')}
      </HeaderButton>,
    ],
  });
  document.title = t('flags:List.titleDisabled');
  useTitleUpdate(setTitle, 'flags:List.titleDisabled');

  return (
    <Column>
      <FlagsListCard disabled={true} />
    </Column>
  );
}

ListDisabled.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default ListDisabled;
