import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FlagList from './List';
import FlagListDisabled from './ListDisabled';
import FlagDetails from './Details';
import NewFlag from './New';
import EditFlag from './Edit';
import { matchType } from '../../../propTypes';
import './style.less';

function Flags({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/new`} component={NewFlag} />
      <Route path={`${match.path}/disabled`} component={FlagListDisabled} />
      <Route path={`${match.path}/:id/edit`} component={EditFlag} />
      <Route path={`${match.path}/:id`} component={FlagDetails} />
      <Route path={`${match.path}`} component={FlagList} />
    </Switch>
  );
}

Flags.propTypes = {
  match: matchType.isRequired,
};

export default Flags;
