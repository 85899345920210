import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SentList from './SentList';
import ScheduledList from './ScheduledList';
import ScheduledDetails from './ScheduledDetails';
import MessageDetails from './Details';
import NewMessage from './New';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/new/:content?`} component={NewMessage} />
    <Route path={`${match.path}/sent/:id`} component={MessageDetails} />
    <Route path={`${match.path}/sent`} component={SentList} />
    <Route path={`${match.path}/scheduled/:id`} component={ScheduledDetails} />
    <Route path={`${match.path}/scheduled`} component={ScheduledList} />
    <Route path={`${match.path}`} render={() => <Redirect to={`${match.path}/sent`} />} />
  </Switch>
);
