import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Progress, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectPathwaysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import { appUserType } from '../../propTypes';
import './styles.less';

export function SingleAppUserPathwayProgress({ appUserPathwayData }) {
  const { t } = useTranslation();
  const history = useHistory();
  if (!appUserPathwayData.pathway) {
    return null;
  }
  return (
    <div
      class="pathway-progress"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(
          `/patients/individuals/${appUserPathwayData.appUserId}/journey/pathway/${appUserPathwayData.appUserPathwayId}`,
        );
      }}
    >
      <p>
        <strong>{appUserPathwayData.pathway.name}</strong>:{' '}
        {appUserPathwayData.currentStage
          ? appUserPathwayData.currentStage.name
          : t('cards:PatientList.pathwayProgress.noCurrentStage')}
      </p>
      <span>{t('cards:PatientList.pathwayProgress.viewDetails')}</span>
      {/* <Button
            type="link"
            onClick={() => {
            }}
          >
            {t('cards:PatientList.pathwayProgress.viewDetails')}
          </Button> */}
      <Progress percent={appUserPathwayData.progress} showInfo={false} />
    </div>
  );
}

function AppUserPathwaysProgress({ appUser }) {
  const { t } = useTranslation();
  const [, appUserPathways] = useSelector(selectPathwaysForAppUser(appUser));
  const pathways = useSelector(state =>
    appUserPathways && appUserPathways.length
      ? appUserPathways.map(({ id, originalPathway, currentStageSlug }) => {
          const pathway = state.pathways.data.byId[originalPathway];
          const currentStage = pathway?.stages.find(stage => stage.slug === currentStageSlug);
          return {
            appUserId: appUser.uuid,
            appUserPathwayId: id,
            pathway,
            currentStage,
            progress: currentStage
              ? parseInt(((currentStage.number / pathway.stages.length) * 100).toFixed(0))
              : 0,
          };
        })
      : [],
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const onToggleExpand = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  if (pathways && pathways.length > 2 && !isExpanded) {
    return (
      <>
        <SingleAppUserPathwayProgress appUserPathwayData={pathways[0]} />
        <SingleAppUserPathwayProgress appUserPathwayData={pathways[1]} />
        <Button
          type="link"
          onClick={e => {
            onToggleExpand(e);
          }}
        >
          {t('cards:PatientList.pathwayProgress.morePathways', { number: pathways.length - 2 })}
        </Button>
      </>
    );
  } else {
    return (
      <>
        {pathways
          ? pathways.map(appUserPathwayData => (
              <SingleAppUserPathwayProgress appUserPathwayData={appUserPathwayData} />
            ))
          : null}
        {isExpanded ? (
          <Button
            type="link"
            onClick={e => {
              onToggleExpand(e);
            }}
          >
            {t('cards:PatientList.pathwayProgress.collapseMorePathways')}
          </Button>
        ) : null}
      </>
    );
  }
}

AppUserPathwaysProgress.propTypes = {
  appUser: appUserType,
};

export default AppUserPathwaysProgress;
