import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { submitFormAsAppUser } from '@redux/appUsers/actions';
import { fetchForms } from '@redux/forms/actions';
import { selectAppUser, selectAppUserFormSubmitting } from '@redux/appUsers/reducers';
import { FormPreviewModalById } from '@components/FormPreviewModal';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { actionJourneyEntry, fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { selectPathwaysForAppUserWithOriginals } from '@pathways/redux/appUserPathways/reducers';
import {
  selectContentForms,
  selectContentQuestionnaires,
  selectContentTasks,
} from '@redux/forms/reducers';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import { FEATURE_FORM, FEATURE_QUESTIONNAIRE } from '@constants';
import useContentRefs from '@hooks/useContentRefs';
import SingleSelectModal from '@components/SingleSelectModal';

const VISIBLE_MODAL = {
  SELECT: 'select',
  SUBMIT: 'submit',
};

const filterFunc = (value, comparator) => comparator(value.form?.name);

const SubmitFormModals = ({
  appUserId,
  formType,
  visibleModal,
  setVisibleModal,
  selectedForm,
  setSelectedForm,
  type,
  readOnly,
  journeyEntry,
  setSelectedContent,
  audienceTypesToFilter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();

  const submitting = useSelector(selectAppUserFormSubmitting);

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchPathways());
    dispatch(fetchAppUsersPathways());
  }, [dispatch, appUserId]);

  const orgContentSelector = {
    [FORM]: selectContentForms,
    [QUESTIONNAIRE]: selectContentQuestionnaires,
    [TASK]: selectContentTasks,
  }[formType];

  const pathwaysWhatType = {
    [FORM]: FEATURE_FORM,
    [QUESTIONNAIRE]: FEATURE_QUESTIONNAIRE,
    [TASK]: FEATURE_QUESTIONNAIRE,
  }[formType];

  const [, orgContent] = useSelector(orgContentSelector);
  const [, appUser] = useSelector(selectAppUser(appUserId));
  const [, auPathways] = useSelector(selectPathwaysForAppUserWithOriginals(appUser));

  const pathwayFormRefs = useMemo(() => {
    if (!auPathways) {
      return [];
    }
    // For each pathway, select all the rules which are relevant, and then find the contentRef in each, and include the pathway so we can associate it with a source.
    return auPathways
      .flatMap(pathway => {
        return [
          ...pathway.originalPathway.indexEvents.flatMap(ie => {
            return ie.rules
              .filter(rule => rule.what === pathwaysWhatType)
              .map(rule => [rule.whatDetail?.content, pathway]);
          }),
          ...pathway.originalPathway.stages.flatMap(stage => {
            return stage.rules
              .filter(rule => rule.what === pathwaysWhatType)
              .map(rule => [rule.whatDetail?.content, pathway]);
          }),
        ];
        // Eliminate nulls and undefined.
      })
      .filter(([contentRef]) => contentRef);
  }, [auPathways, pathwaysWhatType]);

  // Store the references between pathways and content references for later.
  const refToPathwayMap = pathwayFormRefs.reduce((acc, [ref, pathway]) => {
    return {
      ...acc,
      [ref]: pathway.originalPathway?.name,
    };
  }, {});

  const pathwayForms = useContentRefs(pathwayFormRefs.map(([ref]) => ref));

  // combine all the content forms, and pathway forms together without duplicates
  // combining `source`s from duplicates if they're different from the original
  const allForms = Object.values(
    [
      ...orgContent.map(form => ({
        form,
        source: t('common:modals.SelectContent.myContent'),
        key: form.uuid,
      })),
      ...Object.entries(pathwayForms).map(([key, form]) => ({
        form,
        source: refToPathwayMap[key],
        key: form.uuid,
      })),
    ].reduce((acc, { form, source, key }) => {
      if (acc[form.content]) {
        const existingSources = acc[form.content].source.split(', ');
        if (!existingSources.includes(source)) {
          acc[form.content].source += `, ${source}`;
        }
      } else {
        acc[form.content] = { form, source, key };
      }
      return acc;
    }, {}),
  );

  const formTableColumns = [
    {
      title: t('common:modals.SelectContent.columns.title'),
      dataIndex: '',
      key: 'title',
      width: 150,
      render: ({ form }) => form.name,
    },
    {
      title: t('patients:IndividualDetail.formSubmissions.selectFormModal.source'),
      dataIndex: '',
      key: 'source',
      width: 150,
      filters: [...new Set(allForms.flatMap(({ source }) => source?.split(', ')))].map(source => ({
        text: source,
        value: source,
      })),
      render: ({ source }) => source,
      onFilter: (value, record) => record.source.includes(value),
    },
  ];

  return (
    <>
      <SingleSelectModal
        title={t(
          `patients:IndividualDetail.formSubmissions.selectFormModal.title.${formType.toLowerCase()}`,
        )}
        visible={visibleModal === VISIBLE_MODAL.SELECT}
        columns={formTableColumns}
        dataSource={allForms.filter(
          form => !audienceTypesToFilter?.includes(form.form.audienceType),
        )}
        onSubmit={(_, selectedRows) => {
          setSelectedForm(selectedRows[0].form);
          setVisibleModal(VISIBLE_MODAL.SUBMIT);
        }}
        onCancel={() => {
          setSelectedForm(undefined);
          setVisibleModal(undefined);
        }}
        filterFunc={filterFunc}
      />
      <FormPreviewModalById
        hideCloseFooter
        formId={selectedForm?.uuid}
        name={selectedForm?.name}
        visible={visibleModal === VISIBLE_MODAL.SUBMIT}
        handleCloseModal={() => {
          setSelectedForm(undefined);
          if (setSelectedContent) setSelectedContent(undefined);
          setVisibleModal(undefined);
        }}
        submitting={submitting}
        onSubmit={async form => {
          const formData = form.formData;

          dispatch(submitFormAsAppUser(selectedForm.uuid, formData, appUserId, type));
          await waitForStoreUpdate(store, selectAppUserFormSubmitting);
          if (journeyEntry && !journeyEntry.is_actioned) {
            dispatch(actionJourneyEntry(appUser?.id, journeyEntry.journeyId, journeyEntry.id));
          }
          if (setSelectedContent) setSelectedContent(undefined);
          setVisibleModal(undefined);
        }}
      >
        <Button type="primary" htmlType="submit" disabled={readOnly}>
          {t('cards:PatientFormList.submitButton')}
        </Button>
      </FormPreviewModalById>
    </>
  );
};

export { SubmitFormModals, VISIBLE_MODAL };
