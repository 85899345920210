import React, { useCallback, useEffect, useMemo } from 'react';
import { arrayOf, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUsersByIds } from '@redux/appUsers/reducers';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => comparator(value.firstName) || comparator(value.lastName);

function IndividualsCard({ userIds }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selector = useCallback(selectAppUsersByIds(userIds));
  const [loading, users] = useSelector(selector);

  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={users}
      loading={loading}
      scroll={{ x: true }}
      title={t('cards:SentMessageIndividuals.title')}
      filterFunc={filterFunc}
      onRowClick={row => history.push(`/patients/individuals/${row.id}`)}
    />
  );
}

IndividualsCard.propTypes = {
  userIds: arrayOf(string),
};

IndividualsCard.defaultProps = {
  userIds: [],
};

export default IndividualsCard;
