import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { editIndexEvent, fetchIndexEvents } from '../../redux/indexEvents/actions';
import { selectIndexEventEditing, selectIndexEvent } from '../../redux/indexEvents/reducers';
import { matchType } from '../../../propTypes';
import IndexEventWizard from './Wizard';

function EditIndexEvent({
  match: {
    params: { id },
  },
}) {
  const [loading, indexEvent] = useSelector(selectIndexEvent(id));
  const editing = useSelector(selectIndexEventEditing);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setTitle } = usePageActions({ showClose: true, title: indexEvent?.name || ' ' });

  useEffect(() => {
    if (indexEvent) {
      setTitle(indexEvent.name);
    }
  }, [indexEvent, setTitle]);

  useEffect(() => {
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  const initialData = useMemo(() => {
    if (!indexEvent) return {};

    return {
      en: indexEvent.name,
      ...indexEvent.translatedNames,
    };
  }, [indexEvent]);

  const onSubmit = useCallback(
    names => {
      const { en: name, ...translatedNames } = names;
      dispatch(
        editIndexEvent({
          ...indexEvent,
          name,
          translatedNames,
        }),
      );
    },
    [dispatch, indexEvent],
  );

  if (loading || !indexEvent) return null;

  return (
    <IndexEventWizard
      initialData={initialData}
      submitting={editing}
      title={t('indexEvents:Wizard.details')}
      onSubmit={onSubmit}
    />
  );
}

EditIndexEvent.propTypes = {
  match: matchType.isRequired,
};

export default EditIndexEvent;
