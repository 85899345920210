import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { loadConnectionCodes } from '@redux/connectionCodes/actions';
import { selectConnectionCodes } from '@redux/connectionCodes/reducers';
import { selectAllPathwaysById } from '@pathways/redux/pathways/reducers';
import SearchableTable from '@components/SearchableTable';
import IconButton from '@components/IconButton';
import { faCheckCircle, faEdit, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import createColumns from './createColumns';
import './style.less';

const confirmModal = (title, onOk) =>
  Modal.confirm({
    title,
    // wrapClassName: 'procedure-pathways-modal',
    onOk: () => onOk(),
    onCancel: () => {},
  });

const filterFunc = (value, comparator) => {
  return comparator(value.code);
};

const ConnectionCodeListCard = ({
  showTitle,
  isLoadingPathways,
  handleSelectionChanged,
  handleEdit,
  handleSetAsDefault,
  handleToggleDisabled,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const setSelectedRowIds = useState([])[1];
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadConnectionCodes());
  }, [dispatch]);

  const pathwaysById = useSelector(selectAllPathwaysById)[1];
  const [codesLoading, codes] = useSelector(selectConnectionCodes);

  const onRowSelectionChange = useCallback(
    (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowIds(selectedRowKeys);
      handleSelectionChanged(selectedRowKeys);
    },
    [setSelectedRows, setSelectedRowIds, handleSelectionChanged],
  );

  const onEdit = () => {
    handleEdit(selectedRows[0].pathwayIds);
  };

  const onSetAsDefault = () => {
    console.log('Setting as default');
    confirmModal(t('cards:ConnectionCodesList.modals.confirmSetAsDefault'), handleSetAsDefault);
  };

  const onToggleDisabled = () => {
    console.log('Toggling disabled');
    const message = selectedRows[0].isDisabled
      ? t('cards:ConnectionCodesList.modals.confirmEnable')
      : t('cards:ConnectionCodesList.modals.confirmDisable');
    confirmModal(message, handleToggleDisabled);
  };

  const columns = useMemo(() => createColumns(t, pathwaysById, isLoadingPathways), [
    t,
    pathwaysById,
    isLoadingPathways,
  ]);

  return (
    <SearchableTable
      className="connection-codes-list"
      columns={columns}
      dataSource={codes}
      filterFunc={filterFunc}
      loading={codesLoading}
      hasRoutedPagination
      title={showTitle ? <h3>{t('cards:ConnectionCodesList.title')}</h3> : null}
      onRowSelectionChange={onRowSelectionChange}
      extra={
        <>
          <IconButton
            disabled={selectedRows.length !== 1}
            icon={faEdit}
            size="large"
            tooltip={t('common:buttons.edit')}
            onClick={() => onEdit()}
          />
          <IconButton
            disabled={selectedRows.length !== 1 || selectedRows[0].isDefault}
            icon={faCheckCircle}
            size="large"
            tooltip={t('common:buttons.makeDefault')}
            onClick={() => onSetAsDefault()}
          />
          <IconButton
            disabled={selectedRows.length !== 1}
            icon={faTimesCircle}
            size="large"
            tooltip={t('common:buttons.enableDisable')}
            onClick={() => onToggleDisabled()}
          />
        </>
      }
    />
  );
};

export default ConnectionCodeListCard;
