import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { fetchDashboardUsers } from '@redux/dashboardUsers/actions';
import { selectDashboardUserById } from '@redux/dashboardUsers/reducers';
import UserDetailsCard from './Card';

function DashboardUserDetails({ dashboardUserId }) {
  const dispatch = useDispatch();
  const [loading, dashboardUser] = useSelector(selectDashboardUserById(dashboardUserId));

  useEffect(() => {
    dispatch(fetchDashboardUsers());
  }, [dispatch]);

  return <UserDetailsCard details={dashboardUser} loading={loading} />;
}

DashboardUserDetails.propTypes = {
  dashboardUserId: string.isRequired,
};

export default DashboardUserDetails;
