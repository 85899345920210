import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { fetchDashboardUsers, resendDashboardUserInvite } from '@redux/dashboardUsers/actions';
import {
  selectDashboardUserById,
  selectDashboardUserResendingInvite,
  selectDashboardUserActivating,
} from '@redux/dashboardUsers/reducers';
import UserAccessCard from './Card';

function DashboardUserDashboardAccess({ dashboardUserId }) {
  const dispatch = useDispatch();
  const [loading, dashboardUser] = useSelector(selectDashboardUserById(dashboardUserId));
  const activating = useSelector(selectDashboardUserActivating);
  const resending = useSelector(selectDashboardUserResendingInvite);

  useEffect(() => {
    dispatch(fetchDashboardUsers());
  }, [dispatch]);

  return (
    <UserAccessCard
      activating={activating}
      dashboardUserId={dashboardUserId}
      hasAccess={dashboardUser?.activated}
      loading={loading}
      resending={resending}
      resendInvite={() => dispatch(resendDashboardUserInvite(dashboardUserId))}
      userName={dashboardUser?.name}
      userEmail={dashboardUser?.email}
      userUUID={dashboardUser?.uuid}
    />
  );
}

DashboardUserDashboardAccess.propTypes = {
  dashboardUserId: string.isRequired,
};

export default DashboardUserDashboardAccess;
