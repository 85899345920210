import { call, select } from 'redux-saga/effects';
import { selectAppDetails } from '@organisation/redux/selectors';
import { IMessagingClient } from '@liquid-state/messaging-client/dist/types';
import createMessagingClient from '@api/messagingClient';
import { IAppDetails } from '@organisation/redux/types';

function* doCreateMessagingClient(): any {
  const { appId, solutionConfiguration }: IAppDetails = yield select(selectAppDetails);

  const client: IMessagingClient = yield call(
    createMessagingClient,
    appId,
    solutionConfiguration.ubiquity_api_key,
  );

  return client;
}

export default doCreateMessagingClient;
