export const HOSPITAL_DETAILS_SUBMITTED = 'HOSPITAL_DETAILS_SUBMITTED';
export const HOSPITAL_SOCIAL_MEDIA_SUBMITTED = 'HOSPITAL_SOCIAL_MEDIA_SUBMITTED';
export const APP_TERMS_CONDITION_SUBMITTED = 'APP_TERMS_CONDITION_SUBMITTED';
export const APP_PRIVACY_POLICY_SUBMITTED = 'APP_PRIVACY_POLICY_SUBMITTED';
export const DASHBOARD_TERMS_CONDITION_SUBMITTED = 'DASHBOARD_TERMS_CONDITION_SUBMITTED';
export const DASHBOARD_PRIVACY_POLICY_SUBMITTED = 'DASHBOARD_PRIVACY_POLICY_SUBMITTED';
export const ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED';

export const detailsSubmitted = details => ({
  type: HOSPITAL_DETAILS_SUBMITTED,
  payload: {
    ...details,
  },
});

export const socialMediaSubmitted = media => ({
  type: HOSPITAL_SOCIAL_MEDIA_SUBMITTED,
  payload: media,
});

export const appTermsSubmitted = terms => ({
  type: APP_TERMS_CONDITION_SUBMITTED,
  payload: { ...terms },
});

export const appPrivacySubmitted = policy => ({
  type: APP_PRIVACY_POLICY_SUBMITTED,
  payload: { ...policy },
});

export const dashboardTermsSubmitted = terms => ({
  type: DASHBOARD_TERMS_CONDITION_SUBMITTED,
  payload: { ...terms },
});

export const dashboardPrivacySubmitted = policy => ({
  type: DASHBOARD_PRIVACY_POLICY_SUBMITTED,
  payload: { ...policy },
});

export const onbardingSuccess = () => ({
  type: ONBOARDING_COMPLETED,
  payload: {},
});
