import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import { setCurrentScreen, screens } from '../../../analytics';
import { fetchSharedPathwaySnapshots } from '@pathways/redux/sharedPathways/actions';
import { selectSharedPathways } from '@pathways/redux/sharedPathways/reducers';
import SharedPathwaysListCard from '@pathways/cards/Pathways/SharedPathwaysList';

function SharedPathwaysList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, sharedPathways } = useSelector(selectSharedPathways);

  const { setTitle } = usePageActions({
    title: t('pathways:SharedPathways.header'),
  });
  document.title = t('pathways:SharedPathways.header');
  useTitleUpdate(setTitle, 'pathways:SharedPathways.header');

  useEffect(() => {
    setCurrentScreen(screens.SHARED_PATHWAYS_LIST);
  }, []);

  useEffect(() => {
    dispatch(fetchSharedPathwaySnapshots());
  }, [dispatch]);

  return (
    <Column>
      <SharedPathwaysListCard sharedPathways={sharedPathways} loading={loading} />
    </Column>
  );
}

export default SharedPathwaysList;
