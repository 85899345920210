import { call, getContext } from 'redux-saga/effects';
import createPipClient from '@api/pipClient';

function* doCreatePipClient(): any {
  const tokens = yield getContext('tokens');
  const client = yield call(createPipClient, tokens);

  return client;
}

export default doCreatePipClient;
