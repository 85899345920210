import { IIndexEvent, IRawIndexEvent } from './types';

export const mapSnakeCaseIndexEventToCamelCase = ({
  id,
  name,
  order_index: order,
  slug,
  translated_names: translatedNames,
}: IRawIndexEvent): IIndexEvent => ({
  id,
  name,
  order,
  slug,
  translatedNames,
});

export const extractSlugFromTypeSlug = (eventTypeSlug: string): string => {
  const [match, slug] = /(.*)(-[A-Z0-9]{6})$/.exec(eventTypeSlug) || [];

  return match ? slug : eventTypeSlug;
};
