import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProcedureRuleDetailsCard from '@pathways/cards/Pathways/RuleDetails';
import useWizard from '@components/Wizard/useWizard';
import { INDEX_EVENT_DELAY, INDEX_EVENT } from '@constants';

function Review() {
  const [ruleData, setRuleData] = useState(false);
  const { formData } = useWizard();
  const { t } = useTranslation();

  useEffect(() => {
    setRuleData(
      Object.entries(formData).reduce((prev, [key, data]) => {
        if (data.days || data.whenDetail) {
          return {
            ...prev,
            when: data.when === INDEX_EVENT_DELAY ? INDEX_EVENT : data.when,
            whenDetail: { ...data.whenDetail, days: data.days },
          };
        }

        return { ...prev, ...data };
      }, {}),
    );
  }, [formData]);

  if (!ruleData) return null;

  return (
    <ProcedureRuleDetailsCard
      ruleData={ruleData}
      title={t('pathways:ProcedureRules.wizard.review.header')}
    />
  );
}

export default Review;
