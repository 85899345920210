import { IState } from '@redux/reducer';

export const selectAppDetails = (state: IState) => state.organisation?.appDetails;

export const selectOrganisationSlug = () => (state: IState) => {
  return state?.organisation.appDetails.organisationSlug;
};

export const selectOrganisationLanguage = (state: IState) => {
  const appDetails = selectAppDetails(state);
  return appDetails?.solutionConfiguration?.language;
};
