import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contentTypes, documentTypes } from '@utils/contentTypes';
import DocumentPublishedStatusColumn from '@cards/Documents/List/PublishedStatusColumn';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import Reorderer from '@components/Reorderer';
import { reorderOrganisationPublicContent } from '@redux/organisations/actions';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';

const createColumns = (t, dataSourceContentTypes, maxOrder, dispatch) => [
  {
    className: 'language-column',
    dataIndex: 'language',
    filters: createLanguageFilters(t),
    key: 'language',
    onFilter: (value, record) => {
      const lang = record.language || '';
      return lang.toLowerCase() === value;
    },
    render: language => <LanguageIcon language={language} />,
    title: t('cards:DocumentList.columns.language.title'),
    width: 100,
  },
  {
    title: t('common:modals.SelectContent.columns.contentType'),
    dataIndex: 'metadata.type',
    key: 'metadata.type',
    render: type => (
      <span className="fa-icon-with-text">
        <FontAwesomeIcon icon={contentTypes[type]?.icon} />
        {t(contentTypes[type]?.label)}
      </span>
    ),
    width: 180,
    sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
    filters: dataSourceContentTypes.map(type => ({
      text: t(contentTypes[type]?.label),
      value: type,
    })),
    onFilter: (value, record) => record.type === value,
  },
  {
    title: t('common:modals.SelectContent.columns.title'),
    dataIndex: 'name',
    key: 'title',
    width: 300,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    className: 'published-column',
    key: 'published',
    title: t('common:modals.SelectContent.columns.published'),
    render: ({ id, published, is_available, metadata: { type } }) => {
      if (documentTypes.includes(type)) {
        return <DocumentPublishedStatusColumn documentId={id} />;
      }
      return <PublishedStatusIcon published={published || is_available} />;
    },
    sorter: (a, b) => {
      return a.published > b.published;
    },
    width: 110,
  },
  {
    title: t('common:modals.SelectContent.columns.order'),
    dataIndex: 'order',
    key: 'order',
    width: 115,
    onCell: () => {
      return {
        onClick: event => {
          event.stopPropagation();
        },
      };
    },
    render: (order, { pk }) => {
      return (
        <Reorderer
          pk={pk}
          order={order}
          maxOrder={maxOrder}
          reorderer={options => dispatch(reorderOrganisationPublicContent(options))}
        />
      );
    },
  },
];

export default createColumns;
